import React, {useState} from 'react';
import { List, Datagrid, EditButton, ShowButton, BulkDeleteWithConfirmButton, CardActions, FormDataConsumer, CreateButton, ExportButton, Toolbar, TextField } from 'react-admin';
import { Fragment } from 'react';
import { FField } from './../../fields/FField';

const PostBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);  
const NoneActions = props => (
    <CardActions />
);
const YesActions=(state, basePath, props)=>{
    const [valorl, setValorl] = useState(0);
    Object.size = function(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };
    let largoob=Object.size(state.data);
    //console.log(largoob)
    //setValorl(largoob)
    if(largoob>=1){
        return(
            <CardActions />
        )
    }
    else{
        return  <CreateButton basePath={state.basePath} style={{paddingTop:"13px", marginRight:"20px"}}/> 
    }
    
}
           

export const ParametrosList = (props) =>{
    const [registros, setRegistros] = useState('');
    let idproyecto=localStorage.getItem("id_project");
    if(idproyecto){
        let urlser = window.location.hostname;
    let urlAppjs;

    switch (urlser) {
    case 'localhost':
        urlAppjs = "http://localhost:8000";
    break;
    case 'pims.soluciones.software':
        urlAppjs = "https://pims-backend.soluciones.software";
    break;
    default:
        urlAppjs = "https://dynatest-backend.soluciones.software";
    break;
    }
    fetch(urlAppjs+"/parametros_grupos.json?perPage=40&proyecto="+idproyecto)
    .then((response) => {
        return response.json()
    })
    .then((response) => {
        let largo=response.length;
        setRegistros(largo)
    })
    }
    console.log(registros)
    if(registros && registros>0){
        return(
      
            <List {...props}  perPage={25} tittle="Lista de Parámetros de Evaluación" sort={{ field: 'updatedAt', order: 'DESC' }} actions={<YesActions/>}   bulkActionButtons={<PostBulkActionButtons />}>
                <Datagrid>                        
                   
                   <TextField label="Proyecto" source="proyectoNombre"/>
      
                    <ShowButton />
                     <EditButton /> 
                 </Datagrid>
             </List>   
            
          )

    }
    else{
        return(
      
            <List {...props}  perPage={25} tittle="Lista de Parámetros de Evaluación" sort={{ field: 'updatedAt', order: 'DESC' }}   bulkActionButtons={<PostBulkActionButtons />}>
                <Datagrid>                        
                   
                   <TextField label="Proyecto" source="proyectoNombre"/>
      
                    <ShowButton />
                     <EditButton /> 
                 </Datagrid>
             </List>   
            
          )
    }
    
}
{/* <WithProps>{({record,...props})=><ParametrosList record={record}/>}</WithProps> */}
const WithProps = ({children,...props}) => children(props);
import React from 'react';
import { TextInput, required, minLength, maxLength } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;
/**
 * Atributos requeridos
 * id
 * label
 * source
 * reference
 * operation
 * validate
 */

class UniqueProject extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fetch: [],
            error: false,
            oldValue : null,
            reference : "proyectos",
            id : this.props.source
        }
        
        this.unique  = this.unique.bind(this);
    }
    componentWillMount() {
        fetch(urlAppjs + "/"+this.state.reference+".json?pagination=false",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.state.fetch = response;
            });
    }
    
    
    unique() {
        if (document.getElementById(this.props.source)) {
            var texto = document.getElementById(this.props.source).value;
            if (this.state.oldValue == null) {
                this.state.oldValue = texto.toLowerCase().trim().toString();
            }
            var finding = this.state.fetch.find(data => data[this.props.source].toLowerCase().trim().toString() == texto.toLowerCase().trim().toString());
            
            switch (this.props.operation) {
                case "edit":
                    if (typeof finding === 'object'){
                        if(finding.codigo.toLowerCase().trim().toString() == this.state.oldValue) {
                            this.state.error = false;
                         } else {
                            this.state.error = true;
                         }
                    } else {
                        this.state.error = false;
                    }
                    
                break;
                
                default:
                    if (typeof finding === 'object') {
                        this.state.error = true;
                    } else {
                        this.state.error = false;
                    }
                    break;
            }
        }
        if (this.state.error == true) {
            return ("El dato digitado ya se encuentra en uso.");
        }
    }
   
         
    render() {

        return (
            <TextInput
                id={this.props.source}
                label={this.props.label}
                source={this.props.source}
                fullWidth
                onChange={this.props.onChange}
                validate={[
                    this.unique,
                    required(), 
                    minLength(2), 
                    maxLength(4)
                ]}
                formClassName={this.props.formClassName}
            />
        )
    }
}

export default UniqueProject;
import React from 'react';
import { ReferenceField,ReferenceInput,SelectInput,Filter,BooleanField,TextField,List, Datagrid, EditButton, ShowButton } from 'react-admin';
import {  BulkDeleteWithConfirmButton,NumberField  } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

const InvetarioFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" alwaysOn>                
            <SelectInput optionText="fullCodigos" />                
        </ReferenceInput>
    </Filter>
);



export const EstacionInventarioList = props => (
    <List title="Lista Estación de conteo." {...props} sort={{ field: 'id', order: 'DESC' }} filters={<InvetarioFilter/>}  bulkActionButtons={<PostBulkActionButtons />} >
         <Datagrid >
            <TextField source="nombre"/>
            <ReferenceField label="Unidad Funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false}>                
                    <TextField source="fullCodigos" />                
            </ReferenceField>
            {/* <ReferenceField label="Unidad Funcional" source="segmento" reference="segmentos" linkType={false}>                
                   <TextField source="tramo_.unidadFuncional_.codigo" />                
            </ReferenceField> */}
            
            <ReferenceField label="Segmento" source="segmento" reference="segmentos" fullWidth linkType={false}>
                    <TextField source="codigo" />
             </ReferenceField>
             <NumberField label="Año" source="years" fullWidth linkType={false}/>
             <BooleanField label="Operativa" source="operativa" />
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
import React from 'react';
import { TextField, ReferenceField} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from '../../../config/config'; 
import loader from '../../../loader10.gif';
let urlAppjs = config.UrlServer;

class VisualizacionModal extends React.Component{
    constructor(props){
        super(props)
        this.state = { datosCarga: [], parametros : [], estado: 1}  
    }
    componentDidMount(){
        
            // fetch(urlAppjs+"/buscarIndicadoresVisualizacion/"+this.props.ufuncional+"/"+this.props.indicador+"/"+this.props.tramo)
            // .then((response) => {
            //     return response.json()
            // })
            // .then((response) => {
            //     this.setState({ datosCarga: response.resultado })
            //     //console.log(grupo1)
            // })
        
        let idproyecto=localStorage.getItem('id_project');
    fetch(urlAppjs+"/parametros_grupos?proyecto="+idproyecto)
        .then((response) => {
            return response.json()
        })
        .then((response) => {
            let resultado=[];
            if(response){
                resultado=response['hydra:member'][0]
                this.setState({ parametros: resultado })
                //console.log(resultado)
            }
            
        })
    }
    componentWillReceiveProps(){
        this.setState({ datosCarga: []})
        this.setState({ estado: 1})
        fetch(urlAppjs+"/buscarIndicadoresVisualizacion/"+this.props.ufuncional+"/"+this.props.indicador+"/"+this.props.tramo)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.setState({ datosCarga: response.resultado })
                if(response.cantidad<=0){
                this.setState({ estado: 0 })
                }else{
                    this.setState({ estado: 1 })
                }
                //console.log(grupo1)
            })
    }
    formatoabscisa(valor){
        if(this.props.indicador==="1" || this.props.indicador==="16"){
        let valor2=valor-parseInt(valor);
        valor2=parseFloat(valor2).toFixed(1);
            return "K"+parseInt(valor)+"+"+valor2.replace("0.", "")+"00";
        }
        else if(this.props.indicador==="2" || this.props.indicador==="4" || this.props.indicador==="5"){
            let valor2=valor-parseInt(valor);
            valor2=parseFloat(valor2).toFixed(2);
                return "K"+parseInt(valor)+"+"+valor2.replace("0.", "")+"0";
        }
        else{
            let valor2=valor-parseInt(valor);
            valor2=parseFloat(valor2).toFixed(3);
                return "K"+parseInt(valor)+"+"+valor2.replace("0.", "");
        }
    }
    formatovalores(valor, decimal){
        let value =parseFloat(valor).toFixed(decimal).replace(".", ",");
        return value;
    }
    ColorPCI(valor){
        if(valor>=86){
            return "rgb(0, 176, 80)"
        }else if(valor>=71 && valor<86){
            return "rgb(146, 208, 80)"
        }else if(valor>=56 && valor<71){
            return "rgb(255, 255, 0)"
        }else if(valor>=41 && valor<56){
            return "rgb(255, 0, 0)"
        }else if(valor>=26 && valor<41){
            return "rgb(192, 0, 0)"
        }else if(valor>=11 && valor<26){
            return "rgb(148, 54, 52)"
        }else if(valor>=0 && valor<11){
            return "rgb(166, 166, 166)"
        }
    }
    render(){
        const { datosCarga } = this.state;
        const { parametros } = this.state;
        const { estado } = this.state;
        let indicador=this.props.indicador;
        let tramo=this.props.tramo;
        let ufunc=this.props.ufuncional;
        let valorpromedio=3;
        let valorpuntual=1;
        let indicadores=this.props.indicador;
        //console.log(datosCarga)
        if(parametros && parametros.evaluacion && parametros.evaluacion[0] && parametros.evaluacion[0].valormaxMedio){
            if(this.props.indicador==="1"){valorpromedio=parametros.evaluacion[0].valormaxMedio; valorpuntual=parametros.evaluacion[0].valormaxPuntual;}
            else if(this.props.indicador==="2"){valorpromedio=parametros.evaluacion[1].valormaxMedio; valorpuntual=parametros.evaluacion[1].valormaxPuntual;}
            else if(this.props.indicador==="3"){valorpromedio=parametros.evaluacion[2].valormaxMedio; valorpuntual=parametros.evaluacion[2].valormaxPuntual;}
            else if(this.props.indicador==="4"){valorpromedio=parametros.evaluacion[3].valormaxMedio; valorpuntual=parametros.evaluacion[3].valormaxPuntual;}
            else if(this.props.indicador==="6"){valorpromedio=parametros.evaluacion[5].valormaxMedio; valorpuntual=parametros.evaluacion[5].valormaxPuntual;}
            else if(this.props.indicador==="7"){valorpromedio=parametros.evaluacion[6].valormaxMedio; valorpuntual=parametros.evaluacion[6].valormaxPuntual;}
            else if(this.props.indicador==="21"){valorpromedio=parametros.evaluacion[20].valormaxMedio; valorpuntual=parametros.evaluacion[20].valormaxPuntual;}
        }
        if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && (indicadores==="1" || indicadores==="2" || indicadores==="4" || indicadores==="5")){
            let registros=datosCarga;
            let segmentos=[];
            let promedioI=[];
            let promedioD=[];
            let promedioC=[];
            let promedioCalzada=[];
            let cumplimientoI=[];
            let cumplimientoD=[];
            let cumplimientoIndicador=[];
            let cumplimientoPromCalzada=[]
            let cumplimientoPuntCalzada=[]
            let aux=-1;
            let datosprueba=[];
            if(registros){
                registros.map((data, i)=>{
                    if(data.sg_codigo!=aux){
                        segmentos.push(data.sg_codigo);
                        aux=data.sg_codigo;
                    }
                })
                registros.map((data, i)=>{
                    for(let i=0; i<segmentos.length;i++){
                        if(!datosprueba[i]){
                            datosprueba[i]={
                                iriCizq: [],
                                iriCder: [],
                                cumplimientoI: [],
                                cumplimientoD: [],
                                cumplimientoC: [],
                                abscisaini: [],
                                abscisafin: [],
                                eventoCizq: [],
                                singularidadCizq: [],
                                eventoCder: [],
                                singularidadCder: [],
                                fecha: [],
                                estado: [],
                                medidopor: []
                            };
                        }
                    if(data.sg_codigo===segmentos[i]){
                        datosprueba[i]['iriCizq'].push(data.iricizq);
                        datosprueba[i]['iriCder'].push(data.iricder);
                        datosprueba[i]['cumplimientoI'].push(data.cumplimientoizq);
                        datosprueba[i]['cumplimientoD'].push(data.cumplimientocder);
                        datosprueba[i]['cumplimientoC'].push(data.cumpcal);
                        datosprueba[i]['abscisaini'].push(data.abscisa_inicial);
                        datosprueba[i]['abscisafin'].push(data.abscisa_final);
                        datosprueba[i]['eventoCizq'].push(data.eventoizq);
                        datosprueba[i]['singularidadCizq'].push(data.singizq);
                        datosprueba[i]['eventoCder'].push(data.eventocder);
                        datosprueba[i]['singularidadCder'].push(data.singcder);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                    }
                })

                for(let i=0; i<datosprueba.length; i++){
                    let promedioIzq=0;
                    let promedioDer=0;
                    let cumplimientoder=0;
                    let cumplimientoizq=0;
                    let cumplimientoCal=0;
                    for(let j=0; j<datosprueba[i]['iriCizq'].length; j++){
                        promedioIzq+=parseFloat(datosprueba[i]['iriCizq'][j]);
                    }
                    for(let j=0; j<datosprueba[i]['iriCder'].length; j++){
                        promedioDer+=parseFloat(datosprueba[i]['iriCder'][j]);
                    }
                    for( let j=0; j<datosprueba[i]['cumplimientoI'].length; j++){
                        if(datosprueba[i]['cumplimientoI'][j]==="No Cumple"){
                        cumplimientoizq++}
                    }
                    for(let j=0; j<datosprueba[i]['cumplimientoD'].length; j++){
                        if(datosprueba[i]['cumplimientoD'][j]==="No Cumple"){
                            cumplimientoder++}
                    }
                    for(let j=0; j<datosprueba[i]['cumplimientoC'].length; j++){
                        if(datosprueba[i]['cumplimientoC'][j]==="No Cumple"){
                            cumplimientoCal++}
                    }
                    promedioI[i]=promedioIzq/(datosprueba[i]['iriCizq'].length);
                    promedioD[i]=promedioDer/(datosprueba[i]['iriCder'].length);
                    promedioCalzada[i]=Math.max(promedioI[i],promedioD[i]);
                    if(promedioCalzada[i]>valorpromedio){
                        cumplimientoPromCalzada[i]="No Cumple"
                        }
                        else{
                        cumplimientoPromCalzada[i]="Cumple"
                        }
                    
                    cumplimientoI[i]=cumplimientoizq;
                    cumplimientoD[i]=cumplimientoder;
                    promedioC[i]=cumplimientoCal;
                    if(promedioC[i]>0){
                        cumplimientoPuntCalzada[i]="No Cumple"
                        }
                        else{
                        cumplimientoPuntCalzada[i]="Cumple"
                        }
                    if(promedioCalzada[i]>valorpromedio || promedioC[i]>0){
                        cumplimientoIndicador[i]="No Cumple"
                        }
                        else{
                        cumplimientoIndicador[i]="Cumple"
                        }
                }
            }
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
        if(indicadores==="1" || indicadores==="2" || indicadores==="4"){
        return(
            <Table  aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                        <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                        <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                        <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                        <TableCell style={{textAlign: "center"}} >Promedio carril I</TableCell>
                        <TableCell style={{textAlign: "center"}} >Incumplimientos puntuales carril I</TableCell>
                        <TableCell style={{textAlign: "center"}} >Promedio carril D</TableCell>
                        <TableCell style={{textAlign: "center"}} >Incumplimientos puntuales carril D</TableCell>
                        {this.props.indicador==="1" &&
                        <TableCell style={{textAlign: "center"}} >Valor Medio IRI (mm/m)</TableCell>
                        }
                        {this.props.indicador==="2" &&
                        <TableCell style={{textAlign: "center"}} >Valor Medio Ahuellamiento (mm)</TableCell>
                        }
                        {this.props.indicador==="4" &&
                        <TableCell style={{textAlign: "center"}} >Valor Medio CFT</TableCell>
                        }
                        <TableCell style={{textAlign: "center"}} >Cumplimiento valor promedio</TableCell>
                        <TableCell style={{textAlign: "center"}} >Cumplimiento valor puntual</TableCell>
                        <TableCell style={{textAlign: "center"}} >Cumplimiento del indicador en el segmento</TableCell>
                        <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                    </TableRow>
                </TableHead>
                {promedioI.map((row, i)=>(
                    <TableBody>
                    <TableRow>
                        <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(row, 2)}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{cumplimientoI[i]}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(promedioD[i], 2)}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{cumplimientoD[i]}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(promedioCalzada[i], 2)}</TableCell>
                        {cumplimientoPromCalzada[i]==="No Cumple" &&
                            <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoPromCalzada[i]}</TableCell>
                        }
                        {cumplimientoPromCalzada[i]!="No Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{cumplimientoPromCalzada[i]}</TableCell>
                        }
                        {cumplimientoPuntCalzada[i]==="No Cumple" &&
                            <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoPuntCalzada[i]}</TableCell>
                        }
                        {cumplimientoPuntCalzada[i]!="No Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{cumplimientoPuntCalzada[i]}</TableCell>
                        }
                        {cumplimientoIndicador[i]==="No Cumple" &&
                            <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoIndicador[i]}</TableCell>
                        }
                        {cumplimientoIndicador[i]!="No Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{cumplimientoIndicador[i]}</TableCell>
                        }

                        <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                    </TableRow>
                    <TableRow style={{display: "none"}} id={"detalles_"+i}>
                    <TableCell colSpan="13">
                        <Table  aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                            <TableCell style={{textAlign: "center"}} colSpan="2">ABSCISA</TableCell>
                                            <TableCell style={{textAlign: "center"}} colspan="4">CARRIL IZQUIERDO (DESCENDENTE)</TableCell>
                                            <TableCell style={{textAlign: "center"}} colspan="4">CARRIL DERECHO (ASCENDENTE)</TableCell>
                                            <TableCell style={{textAlign: "center"}} rowSpan="2">Cumplimiento Indicador Calzada</TableCell>
                                </TableRow>
                                <TableRow>
                                <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                                <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                                {this.props.indicador==="1" &&
                                <TableCell style={{textAlign: "center"}}>IRI (mm/m)</TableCell>
                                }
                                {this.props.indicador==="2" &&
                                <TableCell style={{textAlign: "center"}}>Ahuellamiento (mm)</TableCell>
                                }
                                {this.props.indicador==="4" &&
                                <TableCell style={{textAlign: "center"}}>CFT</TableCell>
                                }
                                <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                                <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                                <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                                {this.props.indicador==="1" &&
                                <TableCell style={{textAlign: "center"}}>IRI (mm/m)</TableCell>
                                }
                                {this.props.indicador==="2" &&
                                <TableCell style={{textAlign: "center"}}>Ahuellamiento (mm)</TableCell>
                                }
                                {this.props.indicador==="4" &&
                                <TableCell style={{textAlign: "center"}}>CFT</TableCell>
                                }
                                <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                                <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                                <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                                <TableCell style={{textAlign: "center"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {datosprueba[i]['cumplimientoI'].map((data, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                        {datosprueba[i]['cumplimientoI'][j]==="No Cumple" &&
                                            <TableCell style={{color: "red", textAlign: "left"}}>{datosprueba[i]['cumplimientoI'][j]}</TableCell>
                                        }
                                        {datosprueba[i]['cumplimientoI'][j]!="No Cumple" &&
                                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['cumplimientoI'][j]}</TableCell>
                                        }
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>
                                        {datosprueba[i]['cumplimientoD'][j]==="No Cumple" &&
                                            <TableCell style={{color: "red", textAlign: "left"}}>{datosprueba[i]['cumplimientoD'][j]}</TableCell>
                                        }
                                        {datosprueba[i]['cumplimientoD'][j]!="No Cumple" &&
                                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['cumplimientoD'][j]}</TableCell>
                                        }
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                        {datosprueba[i]['cumplimientoC'][j]==="No Cumple" &&
                                        <TableCell style={{color: "red"}}>{datosprueba[i]['cumplimientoC'][j]}</TableCell>
                                        }
                                        {datosprueba[i]['cumplimientoC'][j]!="No Cumple" &&
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['cumplimientoC'][j]}</TableCell>
                                        }
                                    </TableRow>
                                ))
                                }
                            </TableBody>                            
                        </Table>
                    </TableCell>
                </TableRow>
                </TableBody>
                ))}
                
            </Table>
        )}else if(this.props.indicador==="5"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >Incumplimientos puntuales carril I</TableCell>
                            <TableCell style={{textAlign: "center"}} >Incumplimientos puntuales carril D</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento del indicador en el segmento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {promedioI.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{cumplimientoI[i]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{cumplimientoD[i]}</TableCell>
                            {cumplimientoPuntCalzada[i]==="No Cumple" &&
                                <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoPuntCalzada[i]}</TableCell>
                            }
                            {cumplimientoPuntCalzada[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{cumplimientoPuntCalzada[i]}</TableCell>
                            }
    
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="13">
                            <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}} colSpan="2">ABSCISA</TableCell>
                                        <TableCell style={{textAlign: "center"}} colspan="4">CARRIL IZQUIERDO (DESCENDENTE)</TableCell>
                                        <TableCell style={{textAlign: "center"}} colspan="4">CARRIL DERECHO (ASCENDENTE)</TableCell>
                                        <TableCell style={{textAlign: "center"}} rowSpan="2">Cumplimiento Indicador Calzada</TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                                    <TableCell style={{textAlign: "center"}}>MTD (mm) ASTM E1845</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                                    <TableCell style={{textAlign: "center"}}>MTD (mm) ASTM E1845</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                                    <TableCell style={{textAlign: "center"}}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datosprueba[i]['cumplimientoI'].map((data, j)=>(
                                        <TableRow>
                                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                            {datosprueba[i]['cumplimientoI'][j]==="No Cumple" &&
                                                <TableCell style={{color: "red"}}>{datosprueba[i]['cumplimientoI'][j]}</TableCell>
                                            }
                                            {datosprueba[i]['cumplimientoI'][j]!="No Cumple" &&
                                                <TableCell style={{textAlign: "left"}}>{datosprueba[i]['cumplimientoI'][j]}</TableCell>
                                            }
                                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>
                                            {datosprueba[i]['cumplimientoD'][j]==="No Cumple" &&
                                                <TableCell style={{color: "red", textAlign: "left"}}>{datosprueba[i]['cumplimientoD'][j]}</TableCell>
                                            }
                                            {datosprueba[i]['cumplimientoD'][j]!="No Cumple" &&
                                                <TableCell style={{textAlign: "left"}}>{datosprueba[i]['cumplimientoD'][j]}</TableCell>
                                            }
                                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                            {datosprueba[i]['cumplimientoC'][j]==="No Cumple" &&
                                            <TableCell style={{color: "red", textAlign: "left"}}>{datosprueba[i]['cumplimientoC'][j]}</TableCell>
                                            }
                                            {datosprueba[i]['cumplimientoC'][j]!="No Cumple" &&
                                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['cumplimientoC'][j]}</TableCell>
                                            }
                                        </TableRow>
                                    ))
                                    }
                                </TableBody>                            
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
    
            )}else{
                return (
                    <div className="Reload2">
                        <img src={loader} />
                    </div>
                    )
            }
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && (indicadores==="16")){
            let registros=datosCarga;
            let segmentos=[];
            let promedioI=[];
            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            indicador: [],
                            abscisaini: [],
                            abscisafin: [],
                            observaciones: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['indicador'].push(data.iricizq);
                    datosprueba[i]['abscisaini'].push(data.abini);
                    datosprueba[i]['abscisafin'].push(data.abfin);
                    datosprueba[i]['observaciones'].push(data.observaciones);
                    datosprueba[i]['fecha'].push(data.fecha);
                    datosprueba[i]['estado'].push(data.estado);
                    datosprueba[i]['medidopor'].push(data.medido_por);
                }
                }
            })
            return(
                <Table  aria-label="simple table" style={{width: "70%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >PCI (%)</TableCell>
                            <TableCell style={{textAlign: "center"}} >Código de Color</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {segmentos.map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{datosprueba[i]['indicador']}</TableCell>
                            <TableCell style={{textAlign: "right", backgroundColor: this.ColorPCI(datosprueba[i]['indicador'])}}></TableCell>
                        </TableRow>
                    ))
                    }
                        </TableBody>                            
                    </Table>
            )
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && (indicadores==="3")){
            let registros=datosCarga;
            let segmentos=[];
            let AreaCalzada=[];
            let AreaAfectadaTotal=[];
            let AreaAfectadaPorcentaje=[];
            let cumplimiento=[];
            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            abscisa: [],
                            tipofisura: [],
                            severidad: [],
                            largo: [],
                            ancho: [],
                            area: [],
                            areaverdadera: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['abscisa'].push(data.abscisa);
                    datosprueba[i]['tipofisura'].push(data.tipofisura);
                    datosprueba[i]['severidad'].push(data.severidad);
                    datosprueba[i]['largo'].push(data.largo);
                    datosprueba[i]['ancho'].push(data.ancho);
                    datosprueba[i]['area'].push(data.area);
                    datosprueba[i]['areaverdadera'].push(data.area_verdadera);
                    datosprueba[i]['fecha'].push(data.fecha);
                    datosprueba[i]['estado'].push(data.estado);
                    datosprueba[i]['medidopor'].push(data.medido_por);
                }
                }
            })
            //console.log(datosprueba)
            for(let i=0; i<datosprueba.length; i++){
                let areaAfectada=0;
                let areaAfectadaPorc=0;
                let sumaArea=0;
                let areaCumplimiento="No Cumple";
                for(let j=0; j<datosprueba[i]['area'].length; j++){
                    if(datosprueba[i]['ancho'][j]=="0.6" && datosprueba[i]['severidad'][j]=="3"){
                    sumaArea+=parseFloat(datosprueba[i]['area'][j]);
                    }
                }
                areaAfectadaPorc=(sumaArea/datosprueba[i]['areaverdadera'][0])*100;
                if(parseFloat(areaAfectadaPorc)<parseFloat(valorpuntual)){
                    areaCumplimiento="Cumple"
                }else{
                    areaCumplimiento="No Cumple"
                }
                cumplimiento.push(areaCumplimiento);
                AreaAfectadaPorcentaje.push(parseFloat(areaAfectadaPorc).toFixed(2));
                AreaCalzada.push(datosprueba[i]['areaverdadera'][0])
                AreaAfectadaTotal.push(sumaArea);

            }
            
            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >Área calzada (m²)</TableCell>
                            <TableCell style={{textAlign: "center"}} >Área afectada (m²)</TableCell>
                            <TableCell style={{textAlign: "center"}} >Área afectada (%)</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento indicador</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{AreaCalzada[i]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(AreaAfectadaTotal[i], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(AreaAfectadaPorcentaje[i], 2)}</TableCell>
                            {cumplimiento[i]==="No Cumple" &&
                                <TableCell style={{textAlign: "left", color: "red"}}>{cumplimiento[i]}</TableCell>
                            }
                            {cumplimiento[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{cumplimiento[i]}</TableCell>
                            }
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="9">
                            <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}}>Abscisa</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Tipo de fisura</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Severidad</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Largo (m)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Ancho (m)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Área (m²)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {datosprueba[i]['area'].map((data, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['tipofisura'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['severidad'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
            )
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && (indicadores==="6")){
            let registros=datosCarga;
            let segmentos=[];
            let cumplimientoCalzada=[]
            let incumplimientos=[];
            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            ancho: [],
                            largo: [],
                            area: [],
                            profundidad: [],
                            observaciones: [],
                            carril: [],
                            abscisa: [],
                            fecha: [],
                            estado: [],
                            medidopor: [] 
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['abscisa'].push(data.abscisa);
                    datosprueba[i]['ancho'].push(data.ancho);
                    datosprueba[i]['largo'].push(data.largo);
                    datosprueba[i]['area'].push(data.area);
                    datosprueba[i]['profundidad'].push(data.profundidad);
                    datosprueba[i]['observaciones'].push(data.obs);
                    datosprueba[i]['carril'].push(data.carril);
                    datosprueba[i]['fecha'].push(data.fecha);
                    datosprueba[i]['estado'].push(data.estado);
                    datosprueba[i]['medidopor'].push(data.medido_por);
                }
                }
            })
            //console.log(datosprueba)
            for(let i=0; i<datosprueba.length; i++){
                let cumplimiento="No Cumple";
                let sumaIncumplimientosCizq=0;
                let sumaIncumplimientosCder=0;
                for(let j=0; j<datosprueba[i]['ancho'].length; j++){
                    let area=datosprueba[i]['area'][j];
                    let profundidad=datosprueba[i]['profundidad'][j];
                    let carril=datosprueba[i]['carril'][j];
                    if(parseFloat(area)>0.05 && parseFloat(profundidad)>2.5 && carril==="Izquierdo"){
                        sumaIncumplimientosCizq++;
                    }
                    if(parseFloat(area)>0.05 && parseFloat(profundidad)>2.5 && carril==="Derecho"){
                        sumaIncumplimientosCder++;
                    }
                }
                if(sumaIncumplimientosCder+sumaIncumplimientosCizq>valorpuntual){
                    cumplimiento="No Cumple"
                }
                else{
                    cumplimiento="Cumple"
                }
                cumplimientoCalzada.push(cumplimiento);
                incumplimientos.push(sumaIncumplimientosCder+sumaIncumplimientosCizq);
            }
            
            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >Incumplimientos puntuales Calzada</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento del indicador en el segmento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{incumplimientos[i]}</TableCell>
                            {cumplimientoCalzada[i]==="No Cumple" &&
                                <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoCalzada[i]}</TableCell>
                            }
                            {cumplimientoCalzada[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{cumplimientoCalzada[i]}</TableCell>
                            }
    
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="7">
                        <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Ancho (cm)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Largo (cm)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Área (m²)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Profundidad (cm)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Carril</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {datosprueba[i]['abscisa'].map((row, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['profundidad'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['carril'][j]}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
    
            )
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && (indicadores==="7")){
            let registros=datosCarga;
            let segmentos=[];
            let cumplimientoCalzada=[]
            let AreaVerdaderaCal=[];
            let AreaAfect=[];
            let AreaPorc=[];
            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            ancho: [],
                            largo: [],
                            area: [],
                            profundidad: [],
                            observaciones: [],
                            carril: [],
                            abscisa: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                            areaverdadera: []
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['abscisa'].push(data.abscisa);
                    datosprueba[i]['ancho'].push(data.ancho);
                    datosprueba[i]['largo'].push(data.largo);
                    datosprueba[i]['area'].push(data.area);
                    datosprueba[i]['profundidad'].push(data.profundidad);
                    datosprueba[i]['observaciones'].push(data.obs);
                    datosprueba[i]['carril'].push(data.carril);
                    datosprueba[i]['areaverdadera'].push(data.area_verdadera);
                    datosprueba[i]['fecha'].push(data.fecha);
                    datosprueba[i]['estado'].push(data.estado);
                    datosprueba[i]['medidopor'].push(data.medido_por);
                }
                }
            })
            //console.log(datosprueba)
            for(let i=0; i<datosprueba.length; i++){
                let cumplimiento="No Cumple";
                let sumaAreasCizq=0;
                let sumaAreasCder=0;
                let SumaIncumplimiento=0;
                for(let j=0; j<datosprueba[i]['ancho'].length; j++){
                    let area=datosprueba[i]['area'][j];
                    let profundidad=datosprueba[i]['profundidad'][j];
                    let carril=datosprueba[i]['carril'][j];
                    if(carril==="Izquierdo" && parseFloat(profundidad)>=2){
                        sumaAreasCizq+=parseFloat(datosprueba[i]['area'][j]);
                    }
                    if(carril==="Derecho" && parseFloat(profundidad)>=2){
                        sumaAreasCder+=parseFloat(datosprueba[i]['area'][j]);
                        
                    }
                }
                
                let areaVerdad=datosprueba[i]['areaverdadera'][0]
                let areaAfectada=(sumaAreasCder+sumaAreasCizq).toFixed(2);
                //console.log(sumaAreasCizq+" + "+sumaAreasCder+" = "+areaAfectada)
                let porcentaje=parseFloat((areaAfectada/areaVerdad)*100).toFixed(2);
                //console.log("valor punt = "+valorpuntual)
                if(porcentaje<valorpuntual){
                    cumplimiento="Cumple"
                }else{
                    cumplimiento="No Cumple"
                }
                
                cumplimientoCalzada.push(cumplimiento);
                AreaVerdaderaCal.push(areaVerdad);
                AreaAfect.push(areaAfectada);
                AreaPorc.push(porcentaje);
            }
            
            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >Área verdadera</TableCell>
                            <TableCell style={{textAlign: "center"}} >Área afectada (m²)</TableCell>
                            <TableCell style={{textAlign: "center"}} >Área afectada (%)</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento del indicador en el segmento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{AreaVerdaderaCal[i]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(AreaAfect[i], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(AreaPorc[i], 2)}</TableCell>
                            {cumplimientoCalzada[i]==="No Cumple" &&
                                <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoCalzada[i]}</TableCell>
                            }
                            {cumplimientoCalzada[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{cumplimientoCalzada[i]}</TableCell>
                            }
    
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="9">
                        <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Ancho (cm)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Largo (cm)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Área (m²)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Profundidad (cm)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Carril</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {datosprueba[i]['abscisa'].map((row, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['profundidad'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                        <TableCell style={{textAlign: "left"}}>{datosprueba[i]['carril'][j]}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
    
            )
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && (indicadores==="10")){
            let registros=datosCarga;
            let segmentos=[];
            let cumplimientoSegmento=[];
            let cumplimientoPuntual=[];
            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            elemento: [],
                            retroPrincipal : [],
                            retroSecundario: [],
                            legibilidad: [],
                            localizacion: [],
                            estabilidad: [],
                            estadoLimpieza: [],
                            observaciones: [],
                            retroElemento: [],
                            fecha: [],
                            estado: [],
                            medidopor: []                            
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['elemento'].push(data.elementoSenalizacion);
                    datosprueba[i]['retroPrincipal'].push(data.retroPrincipal);
                    datosprueba[i]['retroSecundario'].push(data.retroSecundario);
                    datosprueba[i]['legibilidad'].push(data.legibilidad);
                    datosprueba[i]['localizacion'].push(data.localizacion);
                    datosprueba[i]['estabilidad'].push(data.estabilidad);
                    datosprueba[i]['estadoLimpieza'].push(data.estadoLimpieza);
                    datosprueba[i]['observaciones'].push(data.observaciones);
                    datosprueba[i]['retroElemento'].push(data.retroElemento);
                    datosprueba[i]['fecha'].push(data.fecha);
                    datosprueba[i]['estado'].push(data.estado);
                    datosprueba[i]['medidopor'].push(data.medido_por);
                }
                }
            })
            //console.log(datosprueba)
            for(let i=0; i<datosprueba.length; i++){
                let cumplimiento="No Cumple";
                let cuenta=0;
                let cuenta2=0;
                for(let j=0; j<datosprueba[i]['elemento'].length; j++){
                    let retro_elemento=parseFloat(datosprueba[i]['retroElemento'][j])*0.8;
                    if(parseFloat(datosprueba[i]['retroPrincipal'][j])<=retro_elemento){
                        cuenta++;
                    }
                    if(parseFloat(datosprueba[i]['retroSecundario'][j])<=retro_elemento){
                        cuenta++;
                    }
                    let suma=parseFloat(datosprueba[i]['legibilidad'][j]) + parseFloat(datosprueba[i]['localizacion'][j]) + parseFloat(datosprueba[i]['estabilidad'][j]) + parseFloat(datosprueba[i]['estadoLimpieza'][j]);
                    if(suma!=4){
                        cuenta++;
                    }
                    if(cuenta>0){
                        cuenta2++;
                    }
                }
                if(cuenta<1){
                    cumplimiento="Cumple"
                }else{
                    cumplimiento="No Cumple"
                }
                cumplimientoSegmento.push(cumplimiento);
                cumplimientoPuntual.push(cuenta2);
            }
            
            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >Incumplimientos Puntuales</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento del indicador en el segmento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{cumplimientoPuntual[i]}</TableCell>
                            {cumplimientoSegmento[i]==="No Cumple" &&
                                <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoSegmento[i]}</TableCell>
                            }
                            {cumplimientoSegmento[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{cumplimientoSegmento[i]}</TableCell>
                            }
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="8">
                            <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}} >Elemento</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Retroreflectividad (cd/lux/m2) color principal</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Retroreflectividad (cd/lux/m2) color secundario</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Legibilidad de la señal</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Localización</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Estabilidad</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Estado de limpieza</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {datosprueba[i]['elemento'].map((row, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['elemento'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['retroPrincipal'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['retroSecundario'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['legibilidad'][j] ? "Adecuada": "Inadecuada"}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['localizacion'][j] ? "Adecuada": "Inadecuada"}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['estabilidad'][j] ? "Adecuada": "Inadecuada"}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['estadoLimpieza'][j] ? "Adecuada": "Inadecuada"}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
    
            )
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && (indicadores==="11")){
            let registros=datosCarga;
            let segmentos=[];
            let cumplimientoSegmento=[];
            let cumplimientoPuntual=[];
            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            abscisa: [],
                            lineaBlancaDerecho: [],
                            lineaBlancaIzquierdo: [],
                            lineaAmarilla: [],
                            delineadorPiso: [],
                            tipoEquipo: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                            cumplimiento: []
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['abscisa'].push(data.abscisa);
                    datosprueba[i]['lineaBlancaDerecho'].push(data.lineaBlancaDerecho);
                    datosprueba[i]['lineaBlancaIzquierdo'].push(data.lineaBlancaIzquierdo);
                    datosprueba[i]['lineaAmarilla'].push(data.lineaAmarilla);
                    datosprueba[i]['delineadorPiso'].push(data.delineadorPiso);
                    datosprueba[i]['tipoEquipo'].push(data.tipoEquipo);
                    datosprueba[i]['fecha'].push(data.fecha);
                    datosprueba[i]['estado'].push(data.estado);
                    datosprueba[i]['medidopor'].push(data.medido_por);
                }
                }
            })
            //console.log(datosprueba)
            for(let i=0; i<datosprueba.length; i++){
                let cumplimiento="No Cumple";
                let cuenta=0;
                let valorlineablancaderecho=0;
                let valorlineablancaizquierdo=0;
                let valorlineaamarilla=0;
                let cuentaTemporal=0;
                let cuentaTemporal2=0;
                for(let j=0; j<datosprueba[i]['abscisa'].length; j++){
                    cuentaTemporal=0;
                    if(parseFloat(datosprueba[i]['tipoEquipo'][j])===30){
                        valorlineablancaderecho=140;
                        valorlineablancaizquierdo=140;
                        valorlineaamarilla=120;
                    }else{
                        valorlineablancaderecho=160;
                        valorlineablancaizquierdo=160;
                        valorlineaamarilla=140;
                    }
                    if(parseFloat(datosprueba[i]['lineaBlancaDerecho'][j])<valorlineablancaderecho){
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if(parseFloat(datosprueba[i]['lineaBlancaIzquierdo'][j])<valorlineablancaizquierdo){
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if(parseFloat(datosprueba[i]['lineaAmarilla'][j])<valorlineaamarilla){
                        cuenta++;
                        cuentaTemporal++;
                    }if(datosprueba[i]['delineadorPiso'][j]!="1"){
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if(cuentaTemporal<1){
                        datosprueba[i]['cumplimiento'][j]="Cumple"
                    }else{
                        cuentaTemporal2++;
                        datosprueba[i]['cumplimiento'][j]="No Cumple"
                    }
                }
                
                if(cuenta<1){
                    cumplimiento="Cumple"
                }else{
                    cumplimiento="No Cumple"
                }
                
                cumplimientoSegmento.push(cumplimiento);
                cumplimientoPuntual.push(cuentaTemporal2);
            }
            
            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >Incumplimientos Puntuales</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento del indicador en el segmento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{cumplimientoPuntual[i]}</TableCell>
                            {cumplimientoSegmento[i]==="No Cumple" &&
                                <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoSegmento[i]}</TableCell>
                            }
                            {cumplimientoSegmento[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{cumplimientoSegmento[i]}</TableCell>
                            }
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="8">
                            <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Medida línea blanca borde derecho</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Medida línea blanca borde izquierdo</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Medida línea amarilla</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Cumplimiento delineadores de piso</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Tipo de equipo con geometría (cm)</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Cumplimiento del Indicador</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {datosprueba[i]['abscisa'].map((row, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['lineaBlancaDerecho'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['lineaBlancaIzquierdo'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['lineaAmarilla'][j]}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['delineadorPiso'][j] ? "Sí": "No"}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{j<= 0 ? datosprueba[i]['tipoEquipo'][j]: ""}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
    
            )
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && indicadores==="15"){
            let registros=datosCarga;
            let segmentos=[];
            let cumplimientoCalzada=[]
            let promedioCarrilI=[];
            let promedioCarrilD=[];
            let incumplimientosPuntuales=[];

            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            carrilIzq: [],
                            carrilDer: [],
                            abscisa: [],
                            valormedio: [],
                            niveltrafico: []
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['abscisa'].push(data.abscisa);
                    datosprueba[i]['carrilIzq'].push(data.carrilIzquierdo);
                    datosprueba[i]['carrilDer'].push(data.carrilDerecho);
                    datosprueba[i]['valormedio'].push(data.valorMedio);
                    datosprueba[i]['niveltrafico'].push(data.nivelTrafico);
                }
                }
            })
            //console.log(datosprueba)
            for(let i=0; i<datosprueba.length; i++){
                let cumplimiento="No Cumple";
                let cumplimientoIzq=0;
                let cumplimientoDer=0;
                let averageizq=0;
                let averageder=0;
                let cuenta=0;
                let valormedioTemp=datosprueba[i]['valormedio'][0];
                for(let j=0; j<datosprueba[i]['abscisa'].length; j++){
                    cuenta++;
                    averageizq+=parseFloat(datosprueba[i]['carrilIzq'][j]);
                    averageder+=parseFloat(datosprueba[i]['carrilDer'][j]);
                    if(parseFloat(datosprueba[i]['carrilIzq'][j])>valormedioTemp){
                        cumplimientoIzq++;
                    }
                    if(parseFloat(datosprueba[i]['carrilDer'][j])>valormedioTemp){
                        cumplimientoDer++;
                    }
                }
                if((cumplimientoIzq+cumplimientoDer)<=0){
                    cumplimiento="Cumple"
                }
                let promediotempizq=averageizq/cuenta;
                let promediotempder=averageder/cuenta;
                promedioCarrilI.push(promediotempizq.toFixed(2));
                promedioCarrilD.push(promediotempder.toFixed(2));
                incumplimientosPuntuales.push(cumplimientoIzq+cumplimientoDer);
                cumplimientoCalzada.push(cumplimiento);  
                console.log(datosprueba[i]['carrilIzq'])
            }
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Promedio carril Izquierdo</TableCell>
                            <TableCell style={{textAlign: "center"}} >Promedio carril Derecho</TableCell>
                            <TableCell style={{textAlign: "center"}} >Incumplimientos puntuales</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento del indicador en el segmento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{promedioCarrilI[i]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{promedioCarrilD[i]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{incumplimientosPuntuales[i]}</TableCell>
                            {cumplimientoCalzada[i]==="No Cumple" &&
                                <TableCell style={{textAlign: "right", color: "red"}}>{cumplimientoCalzada[i]}</TableCell>
                            }
                            {cumplimientoCalzada[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "right"}}>{cumplimientoCalzada[i]}</TableCell>
                            }
    
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="6">
                            <Table  aria-label="simple table" style={{width: "50%"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}} >Nivel de Tráfico: {datosprueba[i]['niveltrafico'][0]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Carril Izquierdo</TableCell>
                                        <TableCell style={{textAlign: "center"}}>Carril Derecho</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {datosprueba[i]['abscisa'].map((row, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['carrilIzq'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['carrilDer'][j], 2)}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
    
            )
        }else if(indicador && tramo && ufunc && datosCarga && datosCarga.length>0 && indicadores==="21"){
            let registros=datosCarga;
            let segmentos=[];
            let cumplimientoSegmento=[];
            let incumplimientosPuntuales=[];
            let aux=-1;
            let datosprueba=[];
            //console.log(registros)
            registros.map((data, i)=>{
                if(data.sg_codigo!=aux){
                    segmentos.push(data.sg_codigo);
                    aux=data.sg_codigo;
                }
            })
            registros.map((data, i)=>{
                for(let i=0; i<segmentos.length;i++){
                    if(!datosprueba[i]){
                        datosprueba[i]={
                            indicador: [],
                            abscisa: [],
                            observaciones: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                        };
                    }
                if(data.sg_codigo===segmentos[i]){
                    datosprueba[i]['indicador'].push(data.indicador);
                    datosprueba[i]['abscisa'].push(data.abscisa);
                    datosprueba[i]['observaciones'].push(data.observaciones);
                    datosprueba[i]['fecha'].push(data.fecha);
                    datosprueba[i]['estado'].push(data.estado);
                    datosprueba[i]['medidopor'].push(data.medido_por);
                }
                }
            })
            for(let i=0; i<datosprueba.length; i++){
                let cumplimiento="No Cumple";
                let cuenta=0;
                for(let j=0; j<datosprueba[i]['abscisa'].length; j++){
                    if(parseFloat(datosprueba[i]['indicador'][j])>valorpuntual){
                        cuenta++;
                    }
                }
                if(cuenta===0){
                    cumplimiento="Cumple";
                }
                cumplimientoSegmento.push(cumplimiento);
                incumplimientosPuntuales.push(cuenta);
            }
            
            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles=(valor, valor2)=>{
                //alert("Prueba"+valor);
                //console.log(valor)
                let det=document.getElementById('detalles_'+valor)
                let most =document.getElementById('mostrar_'+valor)
                if(det){
                    let disp=det.style.display;
                    if(disp!="none"){
                        det.style.display="none"
                    }
                    else{
                        det.style.display=""
                    }
                }
                if(most){
                    let html=most.innerHTML;
                    if(html!="Mostrar"){
                        most.innerHTML="Mostrar"
                    }
                    else{
                        most.innerHTML="Ocultar"
                    }
                }
    
                //console.log(valor2)
            }
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >SEGMENTO</TableCell>
                            <TableCell style={{textAlign: "center"}} >Fecha de evaluación</TableCell>
                            <TableCell style={{textAlign: "center"}} >Estado</TableCell>
                            <TableCell style={{textAlign: "center"}} >Medido por</TableCell>
                            <TableCell style={{textAlign: "center"}} >Incumplimientos Puntuales</TableCell>
                            <TableCell style={{textAlign: "center"}} >Cumplimiento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i)=>(
                        <TableBody>
                        <TableRow>
                            <TableCell style={{textAlign: "left"}}>{segmentos[i]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['fecha'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['estado'][0]}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{datosprueba[i]['medidopor'][0]}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{incumplimientosPuntuales[i]}</TableCell>
                            {cumplimientoSegmento[i]==="No Cumple" && 
                                <TableCell style={{textAlign: "left", color: "red"}}>{cumplimientoSegmento[i]}</TableCell>
                            }
                            {cumplimientoSegmento[i]!="No Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{cumplimientoSegmento[i]}</TableCell>
                            }
    
                            <TableCell style={{textAlign: "right"}}><span id={"mostrar_"+i}style={{color: "blue", cursor: "pointer"}} onClick={(evt)=> MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                        </TableRow>
                        <TableRow style={{display: "none"}} id={"detalles_"+i}>
                        <TableCell colSpan="6">
                            <Table  aria-label="simple table" style={{width: "50%"}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{textAlign: "center"}}>Abscisa</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Escalonamiento (mm)</TableCell>
                                    <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                                </TableRow>
                            </TableHead>
                                <TableBody>
                                {datosprueba[i]['abscisa'].map((row, j)=>(
                                    <TableRow>
                                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(datosprueba[i]['indicador'][j], 2)}</TableCell>
                                        <TableCell style={{textAlign: "right"}}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableCell>
                    </TableRow>
                    </TableBody>
                    ))}
                    
                </Table>
            )
        }else if(indicadores==="17"){
            return(
                <div>
                    <h2>No se tiene visualización para este indicador.</h2>
                </div>
            )
        }
        else if(estado===0){
            return (
                <div>
                    <h2>No se encontraron datos.</h2>
                </div>
                )
        }else{
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
                )
        }
    }
}
export default VisualizacionModal;
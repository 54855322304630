
import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


export const AtributosList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid >
            <TextField label="Roles" source="nombre" />
            <TextField label="Tipo de rol" source="tipoRol"  />
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
import React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput
 } from 'react-admin';
import EditButtonPims from '../EditButton';
import {  WithPermissions,BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import { PField } from './../../fields/PField';
import { PRField } from './../../fields/PRField';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const TramoFilter = (props) => (
    <Filter {...props}>
        {/* <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" 
                sort={{ field: 'codigo', order: 'ASC' }} alwaysOn>
            <SelectInput optionText="fullCode" />
        </ReferenceInput> */}
        <ReferenceInput 
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
    </Filter>
);

const PostBulkActionButtons = props => {

    var tramos = localStorage.getItem("tramos");

    return(

    <WithPermissions
            render={({ permissions }) => (
                 "1" === tramos                 
        ? 
        <Fragment>
            <BulkDeleteWithConfirmButton {...props}/>
        </Fragment>
        : null
        )}
    />

    )};
    const PostActions =({basePath,
        currentSort,    
        exporter,    
        filterValues,   
        resource,    
        total})=>{
    var tramos = localStorage.getItem("tramos");
    return(
            <WithPermissions
            render={({ permissions }) => (
                "1" === tramos   ||    "2" === tramos           
        ? 
        <CardActions>
            <CreateButton basePath={basePath} />
            <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        </CardActions>
        

        :  <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
    />
        )}
        />
        
    )
};

export const TramoList = props => (
    <List {...props} sort={{ field: "updatedAt", order: 'DESC' }} filters={<TramoFilter/>} actions={<PostActions/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>                                    
            <TextField label="Código Tramo" source="codigo" />  
            <PField label="PR inicio" source="pInicio"/>
            <PField label="PR fin" source="pFin"/>    
            <PRField label="Progresiva Inicio" source="abInicio"/>
            <PRField label="Progresiva Fin" source="abFin"/>
            <NumberField label="Longitud (km)"  source="fullResta" sortable={false}/>                      
            <ShowButton />
            {
                localStorage.getItem("segmento") === "1" || localStorage.getItem("segmento") === "2" ? <EditButton /> : null
            }   
        </Datagrid>
    </List>
);
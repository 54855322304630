import React , {useState, Fragment} from 'react';
import {FormDataConsumer, NumberInput,SelectInput, ReferenceInput, AutocompleteArrayInput, ReferenceArrayInput, 
        TextInput, number, AutocompleteInput, REDUX_FORM_NAME 
} from 'react-admin';
import { Edit } from './../Edit';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
} from 'react-admin';
import { change } from 'redux-form';
import { SimpleForm } from './../SimpleForm';



export const Contacto_emergenciaEdit =  (props) =>{

    const [departamentoss , setDepartamento]= useState('');

    const ValidarDepartamento = (value)  => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';        
        // for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(value) 
    }

    const Departamento = { departamento: departamentoss };

    const [grupo , setGrupo]= useState('');

    const ValidarGrupo = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setGrupo(value) 
    }
    return (
    <Edit title="Editar Contacto" {...props} >
        <SimpleForm submitOnEnter={false} redirect="list" >            
            <Grid container spacing={24}>
            <Grid item xs={4}>
            <ReferenceInput
                            label="Proyecto"
                            source="proyecto"
                            reference="proyectos"
                            disabled
                            fullWidth
                            validate={[required(), ValidarDepartamento]}
                            filterToQuery={searchText => ({ nombre: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid> 
                <Grid item xs={4}>
                <SelectInput id="grupoIngreso" label="Grupo" source="grupo" allowEmpty emptyValue="" fullWidth choices={[
                        { id: 'Alcaldía', name: 'Alcaldía' },
                        { id: 'Bomberos', name: 'Bomberos' },
                        { id: 'Centro educativo', name: 'Centro educativo' },
                        { id: 'Defensa civil', name: 'Defensa civil' },
                        { id: 'Ejército', name: 'Ejército' },
                        { id: 'Emisora radial', name: 'Emisora radial' },
                        { id: 'Interventoría', name: 'Interventoría' },
                        { id: 'Maquinaria', name: 'Maquinaria' },
                        { id: 'Otros', name: 'Otros' },
                        { id: 'POB-Administrativa', name: 'POB-Administrativa' },
                        { id: 'POB-Ambulancia', name: 'POB-Ambulancia' },
                        { id: 'POB-Carro taller', name: 'POB-Carro taller' },
                        { id: 'POB-Grúas', name: 'POB-Grúas' },
                        { id: 'POB-Peajes', name: 'POB-Peajes' },
                        { id: 'POB-Radio Operación', name: 'POB-Radio Operación' },
                        { id: 'POB-Supervisión vial', name: 'POB-Supervisión vial' },
                        { id: 'Policía', name: 'Policía' },
                        { id: 'Servicios médicos', name: 'Servicios médicos' },
                        { id: 'Talleres', name: 'Talleres' },
                    ]}  validate={[required()]} validate={ValidarGrupo}/>
                         
                </Grid>   
                <Grid item xs={4}>
                    {grupo === 'Servicios médicos' &&
                        <NumberInput source="nivel" fullWidth  validate={[minValue(1), maxValue(5)]}/> 
                            
                    }
                   
                </Grid>             
                
                
                <Grid item xs={4}>
                    <SelectInput label="Tipo de identificación" source="identificacion" allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'CC' },
                            { id: 'NIT', name: 'NIT' },
                            { id: 'Otro', name: 'Otro' },
                            
                        ]} fullWidth/> 
                </Grid>
                <Grid item xs={4}>
                    <NumberInput label="Número Identificación" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10), minValue(11111)]}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="Nombres" source="nombre" fullWidth validate={[ minLength(2), maxLength(100)]}/>
                </Grid>                
                <Grid item xs={4}>
                    <TextInput label="Apellidos"source="apellido" fullWidth validate={[ minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={4}>
                     <TextInput source="cargo" fullWidth validate={[minLength(1), maxLength(20)]}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="Dirección" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                </Grid> 
                {/* <Grid item xs={4}>
                    
                </Grid> */}
                <Grid item xs={8}>
                <FormDataConsumer>
                    {
                        ({ formData, dispatch, ...rest }) => (
                            <Fragment>
                                <Grid container spacing={24}>
                                    <Grid item xs={6}>
                                        <ReferenceInput  
                                            id="departamento" 
                                            value="" 
                                            label="Provincia" 
                                            source="departamento" 
                                            reference="departamentos"
                                            fullWidth 
                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                            onChange ={value => dispatch(
                                                            change(REDUX_FORM_NAME, 'municipio', null)
                                                        )}
                                            validate={[required(), ValidarDepartamento]}
                                        >
                                            <AutocompleteInput options={{fullWidth: true}}  optionText="nombre" fullWidth />
                                        </ReferenceInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReferenceArrayInput 
                                            label="Distrito" 
                                            source="municipio" 
                                            reference="municipios" 
                                            validate={[required()]} 
                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                            filter={{
                                                departamento : departamentoss
                                            }}
                                        >
                                            <AutocompleteArrayInput options={{fullWidth: true}} optionText="nombre" fullWidth/>
                                        </ReferenceArrayInput>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ) 
                    }
                    </FormDataConsumer>                       
                </Grid>
                
                                
                <Grid item xs={6}>
                    <TextInput label="Teléfono 1"   source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]}/> 
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Teléfono 2"  source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput label="Correo electrónico" source="correo" fullWidth validate={[maxLength(100)]}/>
                </Grid> 
                <Grid item xs={12}>
                    <TextInput source="observaciones" fullWidth  validate={[maxLength(200)]}/>
                </Grid>
                
            </Grid>
        </SimpleForm>
    </Edit>
)};
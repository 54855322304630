import React , {useState} from 'react';
import { ArrayInput, SimpleFormIterator,TextInput,ImageInput,ImageField, ReferenceInput, SelectInput,required} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { ButtonCarousel } from './ButtonCarouselPeru';
import config from '../../config/config';


export const ImagenesInventario = ({type, defaultTipoImagen}) => {
        
        /*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        
        setImagenesOpen(value);
        
    }
    let urlAppjs = config.UrlServer;
    if(type === 'edit'){
        return(
            <Grid container spacing={24}>                         
                <Grid item xs={8}>  
                    <ArrayInput label="" source="images" validate={ImagenesEdit} defaultValue={defaultTipoImagen} >
                        <SimpleFormIterator disableAdd disableRemove >
                            <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                <ImageField source="src" title="title" />
                            </ImageInput>                           
                            <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }} >
                                <SelectInput  optionText="nombre"  />
                            </ReferenceInput>                
                        </SimpleFormIterator>
                    </ArrayInput>    
                    
                </Grid>     
                <Grid item xs={4}>  
                    <ButtonCarousel ImagenesOpen={imagenesOpen}   />           
                </Grid>                     
            </Grid>  
        )
    }else if(type === 'create'){
        return(
            <Grid container spacing={24}>                         
                <Grid item xs={10}>  
                    <ArrayInput label="" source="images"  fullWidth
                        defaultValue={defaultTipoImagen}
                    >
                        <SimpleFormIterator disableAdd disableRemove >
                            <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                <ImageField source="src" title="title" />
                            </ImageInput>
                            <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }} >
                                <SelectInput  optionText="nombre"  />
                            </ReferenceInput>    
                        </SimpleFormIterator>
                    </ArrayInput>                   
                </Grid>                        
            </Grid> 
        )
    }   
   
};

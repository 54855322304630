import React from 'react';
import { TextInput, SimpleForm,NumberInput} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Coloramiento from './color/coloramiento';

import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import UniqueUF from './validacion/validacionColores';
import { required } from 'react-admin';
    

const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_field: { display: 'inline-block', marginLeft: 32, width: '30%' }    
};
export const ColorCreate = withStyles(styles)(({ classes, ...props }) => {
    const local = localStorage.getItem('id_project2');
     function ValidaLetra (value){
        if (typeof value !== 'string') return ''
        return value[0].toUpperCase()+ value.slice(1);
    }
    // function mayus(value) {
    //     let e=[]
    //     e.value = value.toUpperCase();
    // }
    
    return(
        <PimsCreate {...props} title="Crear Diagrama colorimétrico">
            <SimpleForm redirect="list">
            <h3 style={{marginLeft: '40%'}}>Parametros Área de Color</h3>
                   <hr fullWidth></hr>
                <UniqueUF source="color" label="Color" validate={[required()]} format={ValidaLetra} />  
                <TextInput style={{display:"none"}} source="proyecto" defaultValue={local}/>
                <Coloramiento/>
        </SimpleForm>
        </PimsCreate>
)});
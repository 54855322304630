import React , {useState} from 'react';
import {  Toolbar,CardActions,NumberField, ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, 
          TabbedForm , ListButton,EditButton, TextInput, FormTab, FunctionField,SelectField
    } from 'react-admin';
import { GMapField } from '../../fields/GMapField';
import { PRField } from './../../fields/PRField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import  {styles}  from './../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../images/ButtonCarousel';
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data}) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath}/>
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>
        
    </Toolbar>
));

const defensachoices =
    [
        { id:  '1', name: 'Terminal de inicio abatida y esviada y Terminal final abatida' },
        { id:  '2', name: 'Terminal de inicio abatida y esviada y Terminal final abatida y esviada' },
        { id:  '3', name: 'Terminal de inicio abatida y esviada y Terminal final cola de pez' },
        { id:  '4', name: 'Terminal de inicio abatida y esviada y Terminal final enterrada en talud' },
        { id:  '5', name: 'Terminal de inicio abatida y Terminal final abatida' },
        { id:  '6', name: 'Terminal de inicio abatida y Terminal final abatida y esviada' },
        { id:  '7', name: 'Terminal de inicio abatida y Terminal final cola de pez' },
        { id:  '8', name: 'Terminal de inicio abatida y Terminal final enterrada en talud' },
        { id:  '9', name: 'Terminal de inicio cola de pez y Terminal final abatida' },
        { id: '10', name: 'Terminal de inicio cola de pez y Terminal final abatida y esviada' },
        { id: '11', name: 'Terminal de inicio cola de pez y Terminal final cola de pez' },
        { id: '12', name: 'Terminal de inicio cola de pez y Terminal final enterrada en talud' },
        { id: '13', name: 'Terminal de inicio enterrada en talud y Terminal final abatida' },
        { id: '14', name: 'Terminal de inicio enterrada en talud y Terminal final abatida y esviada' },
        { id: '15', name: 'Terminal de inicio enterrada en talud y Terminal final cola de pez' },
        { id: '16', name: 'Terminal de inicio enterrada en talud y Terminal final enterrada en talud' },
    ]

export const SeguridadShow = withStyles(styles)(({ classes, record, ...props }) => {

    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }
    return(
    <ShowController {...props} >
        {controllerProps =>
            <ShowView {...props} {...controllerProps} title="Información Sistemas de Contención Vehicular" actions={<ButtonCarousel/>}>
                <TabbedForm toolbar={false}>
                    <FormTab label="IDENTIFICACIÓN">     
                        <TextField label="Código" source="fullCodigo" fullWidth formClassName={classes.grid_cont12} />             
                        <ReferenceField label="Elemento" source="tipoElemento" reference="tipo_elementos" linkType={false}fullWidth formClassName={classes.grid_cont4s} >
                            <TextField  source="nombre"  />
                        </ReferenceField>
                        <TextField source="margen"  fullWidth formClassName={classes.grid_cont4s}/> 
                        <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}} />
                        <span></span>      
                    {/* ------- LOCALIZACIÓN --------- */}
                        <Typography variant="b" component="b">Localización</Typography>
                        <Divider fullWidth formClassName={classes.grid_cont12}/>
                        <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField>
                        <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        {/* <PField label="PR Inicio" source="pInicio" />
                        <PField label="PR Fin"  source="pFin" /> */}                    
                        <PRField label="Abscisa Inicio" source="abInicio"fullWidth formClassName={classes.grid_cont4s} />
                        <PRField label="Abscisa Fin" source="abFin" fullWidth formClassName={classes.grid_cont4s}/>
                        <NumberField label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                        <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                        <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/> 
                        <span></span>                  
                        <Typography variant="b" component="b">Coordenadas Finales</Typography>
                        <Divider fullWidth style={{width:'300%' , marginTop :'10px'}} /> 
                        <NumberField label="Latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        <NumberField label="Longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        <span></span>    
                        <GMapField style={{width : "100%" , }}
                            defaultZoom={12}                                    
                            defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                            source="ubicaciones"
                            multipleMarkers="true"
                            googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                            icon= {imagen} 
                        /> 
                </FormTab>
            <FormTab  label="CARACTERÍSTICAS">            
                {/* PRTP PERFILES DE PUENTES */}
                {/** MATERIAL */}
                <TextField label="Material" source="material" fullWidth  formClassName={classes.grid_cont4s}/>                    
                

                {/** Tipo de perfil */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/DEFMT" &&
                    <TextField fullWidth label="Tipo de perfil" source="perfil" formClassName={classes.grid_cont4s}/>                        
                }
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/PRTP" &&
                    <TextField fullWidth label="Tipo de perfil" source="perfil" formClassName={classes.grid_cont4s}/>                        
                }


                {/** Tipo de defensa */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/DEFMT" && 
                    // <SelectField fullWidth label="Tipo de defensa" source="defensa" cohices={defensachoices} formClassName={classes.grid_cont4s}/>                  
                    <SelectField source="defensa" choices={defensachoices}  formClassName={classes.grid_cont4s} fullWidth/>
                }


                {/** Tipo de tope */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/TPVH" &&
                    <TextField fullWidth label="Tipo de tope" source="tope" formClassName={classes.grid_cont4s}/>                          
                }


                {/** seccion */}      
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/TPVH" &&
                    <TextField fullWidth label="Sección" source="seccion" formClassName={classes.grid_cont4s}/>                        
                }   


                {/** Salto de linea para tope vehicular */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/TPVH" &&  <br/> }         


                {/** Cantidad de Elementos */}
                {controllerProps.record && controllerProps.record.tipoElemento   !== "/tipo_elementos/DEFMT"  
                                        && controllerProps.record.tipoElemento   !== "/tipo_elementos/PRTP"  
                                        && controllerProps.record.tipoElemento   !== "/tipo_elementos/CAPT" 
                                        && controllerProps.record.tipoElemento   !== "/tipo_elementos/TPVH" &&
                    <NumberField label="Cantidad de elementos" source="cant_elem" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                        
                } 


                {/** Salto de linea para pretiles de puente */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/PRTP" &&  <br/> }


                {/** Numero de Postes */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/DEFMT" && 
                    <NumberField label="No. Postes" source="postes"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                 
                }
                {/** Numero de Captafaros */}
                {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/TPVH" &&
                                           controllerProps.record.tipoElemento !== "/tipo_elementos/CERC" &&
                                           controllerProps.record.tipoElemento !== "/tipo_elementos/BORD" &&
                    <NumberField label="No. Captafaros" source="captafaro" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                  
                }
                {/** salto de linea para captafaros */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/CAPT" &&  <br/> }
                

                {/** largo (demas elementos) */}
                {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/TPVH" 
                                        && controllerProps.record.tipoElemento !== "/tipo_elementos/DEFMT"
                                        && controllerProps.record.tipoElemento !== "/tipo_elementos/PRTP" &&
                    <NumberField label="Largo (m)" source="cant_largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s} />                  
                }
                   {/** Ancho (mm) */}    
                   {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/PRTP" &&
                    <NumberField label="largo (m)" source="ca_largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s} />
                }  
                {/** Ancho (mm) */}    
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/PRTP" &&
                    <NumberField label="Ancho (m)" source="ca_ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s} />
                }  
                {/** Altura (mm) */}    
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/PRTP" &&
                    <NumberField label="Altura (m)" source="ca_altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s} />
                }    

                {/** largo (defensa) */}
                {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                    <NumberField label="Largo (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s} />                  
                }
                {/** Calibre (mm) */}    
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/PRTP" &&
                    <NumberField label="Calibre (m)" source="calibre_mm" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s} />
                }   
                {/** Ancho */}
                {controllerProps.record && controllerProps.record.tipoElemento  !== "/tipo_elementos/DEFMT" 
                                        && controllerProps.record.tipoElemento  !== "/tipo_elementos/PRTP" 
                                        && controllerProps.record.tipoElemento  !== "/tipo_elementos/TPVH" &&
                    <NumberField label="Ancho (m)" source="cant_ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                      
                } 


                {/** diametro base */}
                {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" &&
                    <NumberField label="Diametro Base" source="diametro" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s} />                  
                }


                {/** Altura */}
                {controllerProps.record && controllerProps.record.tipoElemento  !== "/tipo_elementos/DEFMT" 
                                        && controllerProps.record.tipoElemento  !== "/tipo_elementos/PRTP" &&
                    <NumberField label="Altura (m)" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4s}/>
                }


                {/** Salto de linea para tope vehicular */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/TPVH" &&  <br/> }


                {/** Cantidad de topes*/}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/TPVH" &&
                    <NumberField label="Cantidad de Topes" source="topes" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                    
                }


                {/** Longitud de Tramo */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/TPVH" &&
                    <NumberField label="Longitud del tramo (m)" source="tramos" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                    
                }

                
                {/**Numero de Unidades */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/DEFMT" && 
                    <NumberField label="No. Unidades" source="unidades" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                
                }


                {/**Numero de Terminales */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/DEFMT" && 
                    <NumberField label="No. Terminales" source="terminales" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                
                }

                {/**Numero de Tornillos */}
                {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/DEFMT" && 
                    <NumberField label="No. Tornillos" source="tornillos" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>                
                }

                
            </FormTab>
            <FormTab  label="Detalles">
                <Divider />
                <TextField fullWidth label="Condición del elemento" source="condicionElemento" formClassName={classes.grid_cont4s}/>
                               
                {controllerProps.record && controllerProps.record.condicionElemento  === "Reemplazo" &&
                    <TextField fullWidth label="Motivo de reemplazo" source="motivoReemplazo" formClassName={classes.grid_cont4s}/> 
                }
                
                {controllerProps.record && controllerProps.record.condicionElemento  === "Reemplazo" &&
                    <ReferenceField 
                        label="Elemento que reemplazo"
                        source="elementoReemplazo_id"
                        reference="seguridads" 
                        linkType={false}
                        fullWidth 
                        formClassName={classes.grid_cont4s} 
                        >
                        <TextField  source="smartCode"  />
                    </ReferenceField>
                    // <FunctionField 
                    //     label="Elemento de Reemplazo"
                    //     source="elementoReemplazo_id"
                    //     render={
                    //         record => `
                    //                     ${record.unidadFuncional_.fullCodigos}::SEG
                    //                     ${record.tramo_.fullCodigos}::
                    //                     ${record.segmento_.fullCodigos}::
                    //                     ${record.tipoElemento_.id.replace("/tipo_elementos/", "")}::
                    //                     ${record.elementoReemplazo_id}
                    //                     `
                    //     }
                    //     formClassName={classes.grid_cont4s}
                    //     />
                    }
                    <br/>
                    <SelectField label="Estado" source="estado" choices={[
                                { id: "Activo", name: 'Activo' },
                                { id: "Inactivo", name: 'Inactivo' },
                            ]} fullWidth 
                            formClassName={classes.grid_cont4_f}
                            />
                <br />
                <DateField label="Fecha de instalación" source="fechaInstalacion" formClassName={classes.grid_cont4s}/> 

                <TextField fullWidth label="Proveedor" source="proveedor_.razonSocial" formClassName={classes.grid_cont4}/>              
                
                <NumberField label="Garantía (meses)" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>              

                {/* <TextField fullWidth source="elementos"/> */}
                 <br />
                {/* <BooleanField label="Status" source="status" fullWidth formClassName={classes.grid_cont4s} /> */}
                <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont6} />
                <TextField source="observaciones" fullWidth formClassName={classes.grid_cont12}/>
                <span></span>   
            </FormTab> 
            <FormTab label="AuditorÍa">
                <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont4s}/> 
                <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont4s}/>
                <span></span>             
                <DateField source="updatedAt" label="Fecha actualización" formClassName={classes.grid_cont4s}/>
                <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont4s}/>
                <span></span>    
            </FormTab>
        </TabbedForm>
        </ShowView>
        }
    </ShowController>
    )});
import React from 'react';
import { WithPermissions,ListButton, TabbedShowLayout,ReferenceField, EditButton, Tab, TextField, DateField, CardActions,ReferenceArrayField, 
         ShowController, ShowView, NumberField
} from 'react-admin';
import { ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { Show } from './../Show';
import { FormDataConsumer } from 'ra-core';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const PostActions =({basePath,data,
 })=>{
var contemergencia = localStorage.getItem("contemergencia");
return(
        <WithPermissions
        render={({ permissions }) => (
            "1" === contemergencia   ||    "2" === contemergencia           
    ? 
    <CardActions style={cardActionStyle}>
        
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
        {/* Add your custom actions
        <Button color="primary" onClick={customAction}>Custom Action</Button> k*/}
    </CardActions>
    

    :  <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} record={data} />
        </CardActions>
    )}
    />
    
)
};

export const Contacto_emergenciaShow = props => (
    <ShowController title="Información segmento flexible" {...props}>
        {controllerProps =>
        <ShowView {...props} {...controllerProps} title="Información Contacto" actions={<PostActions/>}>
                <TabbedShowLayout>
                    <Tab label="Información básica">
                    <ReferenceField label="Proyecto" source="proyecto" reference="proyectos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <br/><br/>
                    <TextField source="grupo" />
                    {controllerProps.record && controllerProps.record.grupo  === "Servicios médicos" &&
                        <TextField label="Nivel" source="fullNivell" />
                    }
                    
                     <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.grupo === 'Servicios médicos' && (
                        <TextField label="Nivel" source="fullNivell" />
                        )
                    }
                    </FormDataConsumer>                
                    <span></span>
                    
                    <TextField label="Tipo de identificación" source="identificacion" />
                    <NumberField label="Número Identificación" source="numero_identificacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} />
                    <TextField source="empresa" />

                    <TextField label="Nombres" source="nombre" />
                    <TextField label="Apellidos" source="apellido" />
                    <TextField source="cargo" />

                    <TextField label="Dirección" source="direccion" />
                    <TextField label="Provincia" source="departamento_.nombre"/>
                    {/* <TextField label="Municipio" source="municipio_.nombre"/> */}                    
                    <ReferenceArrayField label="Distrito" reference="municipios" source="municipio">
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
                </ReferenceArrayField>               
                    <TextField label="Teléfono 1" source="telefono_1" />
                    <TextField label="Teléfono 2" source="telefono_2" />
                    <br /> <br /> <br /> <br /> 
                    <TextField label="Correo electrónico" source="correo" />  
                    <br /> <br /> 
                    <TextField source="observaciones" />
                    
                    </Tab>
                    <Tab label="Auditoría">
                        <DateField source="createdAt" label="Fecha creación"/> 
                        <TextField source="createdBy" label="Creado por"/>
                        <span></span>               
                        <DateField source="updatedAt" label="Fecha actualización"/>
                        <TextField source="updatedBy" label="Actualizado por"/>
                        <span></span>   
                    </Tab>
                </TabbedShowLayout>
            </ShowView>
        }
    </ShowController>
);
import React, {useState} from 'react';
import {  FormTab, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput, SimpleFormIterator, ArrayInput, Query, Loading, Error, ReferenceField, TextField, ArrayField, Datagrid} from 'react-admin';
import { TabbedForm } from './../TabbedForm';
import { Edit } from './../Edit';

import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FInput } from './../../inputs/FInput';
import {
    required,
} from 'react-admin';
import  {abValidationInicio,
         ValorMaximoAbsCreate,
         latMininima,
         lngMininima,


        }  from './../validacionesInputs';
import config from '../../config/config';
let urlAppjs = config.UrlServer;



const styles = {
    ocultar: {display:"none",    },
    grid_cont2:{display: 'inline-block', width: '20%', marginLeft: '10px' },
    grid_cont3:{display: 'inline-block', width: '24%', marginLeft: '10px' },
    grid_cont4:{display: 'inline-block', width: '32%', marginLeft: '10px' },
    grid_cont6:{display: 'inline-block', width: '49%', marginLeft: '10px' },
    grid_cont12:{display: 'inline-block', width: '99%', marginLeft: '10px' },
    "div:empty":{display:'none'}
};

export const DisponibilidadEdit = withStyles(styles)(({ classes, ...props}) => {
    const [estado, setEstado] = useState('');
    const [abValidaini, setAbValidaini] = useState('');
    const [abValidafin, setAbValidafin] = useState('');
    const [unidad , setUnidad]= useState('');
    const [longitudafect, setLongitud] = useState('');
    const [causa, setCausa] = useState('');
    const [longcl, setLongcl] = useState('');
    const [elementoaf, setElementoaf] = useState('');
    const [volumenderr, setVolumenderr] = useState('');
    const [condicionesesp, setCondicionesesp] = useState('');
    const [tiempodespeje, setTiempodespeje] = useState('');
    const [unidadfun, setUnidadfun] = useState('');
    const [abingini, setAbingini] = useState('');
    const [abingfin, setAbingfin] = useState('');
    const [segmentosafectados, setSegmentosafectados]= useState('');
    const [horasen, setHoras] = useState('');
    const [proyectos, setProyecto] = useState( window.localStorage.getItem('id_project2'));
    const [diasdespeje, setDiasdespeje] =useState('');
    const [horadespeje, setHoradespeje] =useState('');
    const [indicadoro4, setIndicadoro4] =useState('');
    const [diasconcierre, setDiasconcierre] =useState('');
    const [indicadore17, setDndicadore17] =useState('');
    const [segmentosCierre, setSegmentosCierre] =useState([]);
    const [tiemposervicio, setTiemposervicio] =useState([]);    
    
    const ValidarEvento=(value) =>{    
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; }                
        setEstado(valor);
        console.log("estado="+estado+".");
    }
    var today = new Date();
var horaact=today.getHours();
var minact=today.getMinutes();
if(horaact.toString().length<=1){
    horaact="0"+horaact;
}

if(minact.toString().length<=1){
    minact="0"+minact;
}
//console.log(horaact.length+" AND "+minact.length)
//console.log(typeof minact);
var hora=horaact+":"+minact;
const HoraSenal =(value) =>{     
    /*let aux = value.split(':');
    let hora2 = parseInt(aux[0]);
    let minut2 = parseInt(aux[1]);
    let horacomp2 = (hora2*60) + minut2;
    let hora1 = parseInt(horaact);
    let minut1 = parseInt(minact);
    let horacomp1 = (hora1*60) + minut1;
    let res1 = horacomp1 - horacomp2;
    let horasenal=parseInt(res1/60);
    let minutsenal=parseInt(res1%60);
    if(horasenal.toString().length<=1){
        horasenal="0"+horasenal;
    }
    
    if(minutsenal.toString().length<=1){
        minutsenal="0"+minutsenal;
    }
    */
    if(document.getElementById('fecha_senal') && document.getElementById('Fechareport') && document.getElementById('hora_senal') && document.getElementById('Horardeporte')){
    let fecha_senalizacion=document.getElementById('fecha_senal').value;
    let fecha_reporte=document.getElementById('Fechareport').value;
    let tiempo_senalizacion=document.getElementById('hora_senal').value;
    let tiempo_reporte=document.getElementById('Horardeporte').value;
    let tiemposplit=tiempo_reporte.split(':');
    let hora_reporte=tiemposplit[0];
    let minuto_reporte=tiemposplit[1];
    if(hora_reporte.toString().length<=1){
        hora_reporte="0"+hora_reporte;
    }
    tiempo_reporte=hora_reporte+":"+minuto_reporte;
    let fecha_inicial=fecha_reporte+"T"+tiempo_reporte;
    let fecha_final=fecha_senalizacion+"T"+tiempo_senalizacion;
    let resultado=RestarFechas(fecha_inicial, fecha_final);
    //console.log(resultado);
        setHoras(resultado);
    }
}
    const ValidarUnidad = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';
    
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setUnidad(valor) 
    }
    const validarUnifun = (value) =>{
        if(document.getElementById('ufuncional')){
        let unidadactual=document.getElementById('ufuncional').value;
       if(unidadactual!=""){
        let idunidadactual=unidadactual.split('/');
        if(value && idunidadactual[2]!=unidadfun){
                let sperador1 = value.split('/');
                console.log(urlAppjs);
                console.log(sperador1[2]);
                setUnidadfun(sperador1[2]);
                fetch(urlAppjs+ '/unidad_funcionals/' + sperador1[2])
                .then((response) => {
                    console.log("REST");
                    return response.json();
                })
                .then((response) =>{
                    setAbValidaini(response['abInicio']);
                    setAbValidafin(response['abFin']);
                })
        }
    }}
    }
    const validarAbini =(value) =>{            
        //console.log("El valor es = "+value+"La abs = "+abValidaini);
        if(value && value.search("[\\K\\+]")!=-1)
        {   
            
            let sperador1 = value.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena = separador2[0] + '.' + separador2[1]
            let absfin = document.getElementById('abfin').value;
            cadena=Number.parseFloat(cadena)
            if(cadena < abValidaini){
                return 'La Abscisa inicial debe estar entre '+abValidaini+' y '+abValidafin
            } 
            else if(cadena >abValidafin){
                return 'La Abscisa inicial debe estar entre '+abValidaini+' y '+abValidafin
            }
            else if(absfin && absfin.search("[\\K\\+]")!=-1)
                {
                let sperador1 = absfin.split('K');
                let separador2 = sperador1[1].split('+');
                let cadena2 = separador2[0] + '.' + separador2[1];
                cadena2=Number.parseFloat(cadena2)
            
    
                    if(cadena2<=cadena){
                        
                        return 'La Abscisa Inicial debe ser menor a la Abscisa Final';
                    }
                }  
        }
    };
    const validarAbfin =(value) =>{            
        //console.log(abValidafin);
        if(value && value.search("[\\K\\+]")!=-1)
        {   
            
            let sperador1 = value.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena = separador2[0] + '.' + separador2[1]
            let absini = document.getElementById('abin').value;
            cadena=Number.parseFloat(cadena)
            if(cadena > abValidafin){
                return 'La Abscisa Final debe estar entre '+abValidaini+" y "+abValidafin
            }  
            if(cadena < abValidaini){
                return 'La Abscisa Final debe estar entre '+abValidaini+" y "+abValidafin
            }
            if(absini && absini.search("[\\K\\+]")!=-1)
            {
            let sperador1 = absini.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena2 = separador2[0] + '.' + separador2[1];
            cadena2=Number.parseFloat(cadena2)
                if(cadena2>=cadena){
                    return 'La Abscisa Final debe ser mayor a la Abscisa inicial';
                }
            } 
        }
    };
    const validarLongitudafect=(value) =>{
        let abini=document.getElementById('abin').value
        let abfin=document.getElementById('abfin').value
        if(abfin!="" && abini!=""){
        abini=abini.replace('K', '');
        abini=abini.replace('+', '.');
        abfin=abfin.replace('K', '');
        abfin=abfin.replace('+', '.');
        let res =(abfin - abini).toFixed(2)+"km";
        setLongitud(res);}
    }
    const validarMaxl=(value)=>{
        console.log(props)
        let largo = value.length;
        if(largo>40){
            return "Máximo 40 caracteres";
        }
    }  
    const obtenerdominio=()=>{
        return urlAppjs;
    }
    const validarCausa=(value)=>{
        let lenghtObject = Object.keys(value);
        let valor = '';
    
        for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; }                
        setCausa(valor) 
    }     
    const validarLongcl=(value)=>{
        if(!Number.isInteger(value) || value<0){
                return "El número debe ser  un entero positivo";
            }
        else if(value>300){
            setLongcl("No Cumple");
        }
        else if(value<=300){
            setLongcl("Cumple");
        }
    }
    const validarVolder=(value)=>{
        if(!Number.isInteger(value) || value<0){
            return "El Número Debe Ser  Un Entero Positivo";
        }
        else{
            setVolumenderr(value);
        }
    }
    const validarElemento=(value)=>{
        let lenghtObject = Object.keys(value);
        let valor = '';
    
        for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; } 
        setElementoaf(valor);
    }
    const validarCondicionesesp=(value)=>{
        let lenghtObject = Object.keys(value);
        let valor = '';
    
        for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; } 
        setCondicionesesp(valor)
        console.log(valor);
    }
    const valorTiempodespeje=()=>{
       let aux="";
        if(condicionesesp==="Disposición de Nuevos Dispositivos de Contención" && volumenderr>2000){
            aux="1 semana";
        }
        else if(condicionesesp==="Mayores Dificultades" && volumenderr>2000){
            aux="1 mes";
        }
        else if(volumenderr>200 && volumenderr<=2000 && elementoaf==="Calzada"){
            aux="24";
        }
        else if(volumenderr>200 && volumenderr<=2000 && elementoaf==="Bermas"){
            aux="24";
        }
        else if(volumenderr<=200 && elementoaf==="Calzada"){
            aux="4";
        }
        else if(volumenderr<=200 && elementoaf==="Bermas"){
            aux="24";
        }
       setTiempodespeje(aux);
       /*let valorC = document.getElementById("tiempodespejemax");
       if (valorC) {
            valorC.value = aux;
       }*/

    }
    const validarTipoincidente=(value)=>{
        let largo = value.length;
        if(largo>40){
            return "Máximo 40 caracteres";
        }
    } 
    const validarTipovehiculootro=(value)=>{
        let largo = value.length;
        if(largo>20){
            return "Máximo 20 caracteres";
        }
    }
    const validarCodigocausa =(value)=>{
        if(value<90 || value>506){
            return "El código debe estar entre 090 y 506"
        }
    }
    const validarCausaProbable =(value)=>{
        let largo = value.length;
        if(largo>200){
            return "Máximo 200 caracteres";
        }
    }
    const validarClasePolicia=(value)=>{
        let largo = value.length;
        if(largo>20){
            return "Máximo 20 caracteres";
        }
    }
    const validarcantidadheridos =(value)=>{
        if(value<0 || value>100){
            return "La cantidad debe estar entre 0 y 100";
        }
    }
    const validartipoheridos =(value)=>{
        let largo = value.length;
        if(largo>20){
            return "Máximo 20 caracteres";
        }
    }
    const validargeneroheridos =(value)=>{
        let largo = value.length;
        if(largo>10){
            return "Máximo 10 caracteres";
        }
    }
    const validaredadheridos=(value)=>{
        if(value<0 || value>122){
            return "La Edad  debe estar entre 0 y 122";
        }
    }
    const validartipovehiculo=(value)=>{
        let largo = value.length;
        if(largo>15){
            return "Máximo 15 caracteres";
        }
    }
    const validarobservaciones=(value)=>{
        let largo = value.length;
        if(largo>200){
            return "Máximo 200 caracteres";
        }
    } 
    const validarsegmentosafect=(value)=>{
        let abini=document.getElementById('abin').value
        let abfin=document.getElementById('abfin').value
        let safect=document.getElementById('segmentosAfect').value;
        if(abfin!="" && abini!=""){
        abini=abini.replace('K', '');
        abini=abini.replace('+', '.');
        abfin=abfin.replace('K', '');
        abfin=abfin.replace('+', '.');
        //console.log("abini ="+abini+" abingini= "+abingini)    
        if(abini!=abingini || abfin!=abingfin || safect==""){
            setAbingini(abini);
            setAbingfin(abfin);
            fetch( obtenerdominio()+"/disponibilidadViaSeg/"+unidadfun+"/"+abini+"/"+abfin)
                    .then((response)=>{
                        return response.json();
                    })
                    .then((response)=>{
                        let largo=response.codigoseg.length;
                        let valor ="";
                        let valor2=[];
                        for (let i=0; i<largo; i++){
                            valor=valor+response.codigoseg[i]+"; ";
                            valor2[i]="segmentos/"+response.segid[i];
                        }
                        setSegmentosafectados(valor);
                        setSegmentosCierre(valor2);
                    })    
                }
        }
    }    
    const validardespeje=(value)=>{
    let despejeh="";
    let despejem="";
    if(document.getElementById('hora_despeje')){
        let aux=document.getElementById('hora_despeje').value;
        let aux2=aux.split(':');
        despejeh =aux2[0];
        despejem=aux2[1];
        if(despejeh.toString().length<=1){
            despejeh="0"+despejeh;
        }
        
        if(despejem.toString().length<=1){
            despejem="0"+despejem;
        }
    }
    if(document.getElementById('fecha_despeje')){
    let fecha_despeje=new Date(document.getElementById('fecha_despeje').value+"T"+despejeh+":"+despejem);
    let fecha_senalizacion=new Date(document.getElementById('fecha_senal').value+"T"+document.getElementById('hora_senal').value);
    let fecha_despeje_off=new Date( fecha_despeje.getTime() + Math.abs(fecha_despeje.getTimezoneOffset()*60000) );
    let fecha_senalizacion_off=new Date( fecha_senalizacion.getTime() + Math.abs(fecha_senalizacion.getTimezoneOffset()*60000) );
    let difference=fecha_senalizacion_off.getTime()-fecha_despeje_off.getTime();
    
    let differencedays=difference/(1000*3600*24);
    let differencehours=(difference/(1000*3600))%24;
    let differencehours2=(difference/(1000*3600));
    let differenceminutes=(difference/(1000*60))%60;
    let horas_res=Math.abs(parseInt(differencehours));
    let minutos_res=Math.abs(parseInt(differenceminutes));
    if(horas_res.toString().length<=1){
        horas_res="0"+horas_res;
    }
    
    if(minutos_res.toString().length<=1){
        minutos_res="0"+minutos_res;
    }
    setDiasdespeje(Math.abs(parseInt(differencedays)));
        //document.getElementById('dias_despejef').value=Math.abs(parseInt(differencedays));
    setHoradespeje(horas_res+":"+minutos_res);
        //document.getElementById('tiempo_deespejef').value=Math.abs(parseInt(differencehours))+":"+Math.abs(parseInt(differenceminutes));
    //console.log(differencehours+":"+differenceminutes);
    let tiempomax=document.getElementById('tiempodespejemax').value;
    let horasmax=0;
    let aux1="";
    if(tiempomax=== '1 semana'){
        horasmax=168;
    }
    else if(tiempomax=== '1 mes'){
        horasmax=730;
    }
    else{
        horasmax=parseInt(tiempomax);
    }
    if(Math.abs(differencehours2)>horasmax){
        aux1="No Cumple";
    }else{
        aux1="Cumple"
    }
    setIndicadoro4(aux1);   
    //document.getElementById('indicaddoro4').value=aux1;
    }
}
const validardiascierre=(value)=>{
    let fecha_puestaservicio=new Date(document.getElementById('fecha_puestaservicio').value);
    let fecha_reporte=new Date(document.getElementById('Fechareport').value);
    let fecha_puestaservicio_off=new Date( fecha_puestaservicio.getTime() + Math.abs(fecha_puestaservicio.getTimezoneOffset()*60000) );
    let fecha_reporte_off=new Date( fecha_reporte.getTime() + Math.abs(fecha_reporte.getTimezoneOffset()*60000) );
    let difference=fecha_reporte_off.getTime()-fecha_puestaservicio_off.getTime();
    let differencedays=difference/(1000*3600*24);
    setDiasconcierre(Math.abs(parseInt(differencedays)));
    let aux1="";
    if(Math.abs(parseInt(differencedays))>=1){
        aux1="No Cumple"
    }
    else{
        aux1="Cumple"
    }
    setDndicadore17(aux1);
}
const validararray=(value)=>{
    console.log("Valor =")
    console.log(value)
    setTiemposervicio("5:30");
}
const RestarFechas=(fecha1, fecha2)=>{
    let fecha_inicial=new Date(fecha1);
    let fecha_final=new Date(fecha2);
    let fecha_inicial_off=new Date( fecha_inicial.getTime() + Math.abs(fecha_inicial.getTimezoneOffset()*60000) );
    let fecha_final_off=new Date( fecha_final.getTime() + Math.abs(fecha_final.getTimezoneOffset()*60000) );
    let difference=fecha_final_off.getTime()-fecha_inicial_off.getTime();
    
    //let differencedays=difference/(1000*3600*24);
    //let differencehours=(difference/(1000*3600))%24;
    let differencehours2=(difference/(1000*3600));
    let differenceminutes=(difference/(1000*60))%60;
    let hora_resultado=Math.abs(parseInt(differencehours2));
    let minuto_resultado=Math.abs(parseInt(differenceminutes));
    if(hora_resultado.toString().length<=1){
        hora_resultado="0"+hora_resultado;
    }
    
    if(minuto_resultado.toString().length<=1){
        minuto_resultado="0"+minuto_resultado;
    }
    let resultado =hora_resultado+":"+minuto_resultado;

    return resultado
}
const timeFormatter= v =>{
    if(v && typeof v!= 'undefined'){
    console.log(v)
    let timeformat=v.split(':');
    let horaformat=timeformat[0];
    let minutoformat=timeformat[1];
    if(parseInt(horaformat)>24){
        horaformat=23;
    }
    if(horaformat.length<=1){
        horaformat="0"+horaformat;
    }
    if(parseInt(minutoformat)>60){
        minutoformat=59;
    }
    if(minutoformat.length<=1){
        minutoformat="0"+minutoformat;
    }
    let tiempofinal = horaformat+":"+minutoformat;
    return tiempofinal;
}
else{
    return v;
}
}

return(
<Edit {...props} title="Editar Disponibilidad de Vía" >
           <TabbedForm submitOnEnter={false} redirect="list"> 
                <FormTab label="IDENTIFICACIÓN">
                            
                            <TextInput label="Consecutivo" fullWidth source="consecutivo" disabled formClassName={classes.grid_cont3} validate={[required()]}/>

                        
                        
                            <DateInput label="Fecha" fullWidth source="fecha" disabled validate={[required()]} formClassName={classes.grid_cont3}/>
                        
                        
                            <SelectInput label="Tipo de Evento" fullWidth source="tipoEvento" choices={[
                                {id: 'Incidente', name: 'Incidente'},
                                {id: 'Accidente', name: 'Accidente'},
                                {id: 'Cierre', name: 'Cierre'}
                            ]} validate={[required(), ValidarEvento]} formClassName={classes.grid_cont3}/>
                        
                        {estado === "Cierre"  &&
                            
                            <SelectInput label="Tipo de Cierre"  fullWidth source="tipoCierre" choices={[
                                {id: 'Calzada', name: 'Calzada'},
                                {id: 'Carril', name: 'Carril'}
                            ]} formClassName={classes.grid_cont3}/>
                            
                        }
                        
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12}>Localización</Typography>
                        
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                        
                            <ReferenceInput label="Unidad Funcional" source="unidadFuncional" id="ufuncional" reference="unidad_funcionals" validate={[required(), ValidarUnidad, validarUnifun]} formClassName={classes.grid_cont4} fullWidth>
                                <SelectInput  optionText="codigo" fullWidth />
                            </ReferenceInput>
                        
                        {unidad &&
                         <PRInput label="Progresiva Inicial"  source="abscisaininum" id="abin" fullWidth formClassName={classes.grid_cont4} validate={[required(), ValorMaximoAbsCreate, abValidationInicio, validarAbini, validarLongitudafect, validarsegmentosafect]}/>
                        }
                        
                        {unidad &&
                        <NumberInput source="abscisaIni" id="abinihid" formClassName={classes.ocultar}  defaultValue={abingini}/>
                        }
                        {unidad &&
                        <NumberInput source="abscisaFin" id="abfinhid" formClassName={classes.ocultar}  defaultValue={abingfin}/>
                        }
                        
                        {unidad &&
                         <PRInput label="Progresiva Final"  source="abscisafinnum" id="abfin" fullWidth formClassName={classes.grid_cont4} validate={[required()], validarAbfin, ValorMaximoAbsCreate, validarAbfin, validarLongitudafect, validarsegmentosafect}/>
                        }
                        {!unidad &&
                            <div formClassName={classes.grid_cont8}></div>
                        }
                        
                            <SelectInput label="Margen"  fullWidth source="margen" choices={[
                                {id: 'Izquierda', name: 'Izquierda'},
                                {id: 'Derecha', name: 'Derecha'}
                            ]} validate={[required()]} formClassName={classes.grid_cont6}/>

                         <TextInput label="Lugar"  source="lugar" fullWidth formClassName={classes.grid_cont6} validate={[required(), validarMaxl]} maxLength={40}/>
                        
                            <TextInput label="Longitud Afectada (km)" source="longitudAfect" fullWidth formClassName={classes.grid_cont3} disabled validate={[required()]} format={v =>{ return longitudafect}}/>                        
                        
                        <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                            if(typeof formData != 'undefined' && formData.longitudAfect && longitudafect){
                                formData.longitudAfect=longitudafect
                            }
                        }}
                        </FormDataConsumer>
                        {unidad &&
                        
                            <TextInput label="Segmentos Afectados" disabled format={v =>{ return segmentosafectados}} source="segmentosAfect" fullWidth validate={[required()]} multiline formClassName={classes.grid_cont3}/>
                        }
                        {unidad &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                            if(typeof formData != 'undefined'){
                            formData.segmentosAfect=segmentosafectados
                            }
                        }}
                        </FormDataConsumer>
                        
                        }
                        
                        {                        
                    <Query type="GET_ONE" resource="proyectos" payload={{ id: proyectos}} formClassName={classes.ocultar}>
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    // 
                                    <Grid item xs={3} className={classes.ocultar}>
                                        <TextInput  id="lngMin" style={{display : 'none'}} defaultValue={data.longitud1} source="longituds" fullWidth />
                                        <TextInput id="latMin"  style={{display : 'none'}}   defaultValue={data.latitud1} source="latituds" fullWidth />
                                        <TextInput  id="lngMax" style={{display : 'none'}} defaultValue={data.longitud2} source="longituds2" fullWidth />
                                        <TextInput id="latMax"  style={{display : 'none'}}  defaultValue={data.latitud2} source="latituds2" fullWidth />                            
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                            <NumberInput label="Latitud" source="latitud" fullWidth formClassName={classes.grid_cont3} validate={[required(), latMininima]}/>                              
                            <NumberInput label="Longitud" source="longitud" fullWidth formClassName={classes.grid_cont3} validate={[required(), lngMininima]}/>                        
                    
                    
                </FormTab>
                <FormTab label="DETALLES">
                            <DateInput label="Fecha de Reporte" source="fechaReporte" fullWidth id="Fechareport" formClassName={classes.grid_cont4} validate={[required()]}/>
                            <FInput label="Hora de Reporte" source="horareport" id="Horardeporte"  fullWidth formClassName={classes.grid_cont4} validate={[required(), HoraSenal]} format={timeFormatter}/>
                            <DateInput label="Fecha de Señalización" source="fechaSenal" id="fecha_senal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} disabled/>
                            <FInput label="Hora de Señalización" source="horasenalizacion" id="hora_senal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} disabled/>
                            <FInput label="Tiempo de Señalización" source="tiempSenal" id="tiempsenal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} disabled format={v=> { return horasen;}}/>
                        <div formClassName={classes.grid_cont4}></div>
                            <SelectInput label="Fuente del Reporte"  fullWidth source="fuenteRep" choices={[
                                    {id: 'Llamada', name: 'Llamada'},
                                    {id: 'Email', name: 'Email'},
                                    {id:'Recorredor', name:'Recorredor'}
                                ]} formClassName={classes.grid_cont4} validate={[required()]}/>
                            <SelectInput label="Causa"  fullWidth source="causa" choices={[
                                    {id: 'Accidente', name: 'Accidente'},
                                    {id: 'Derrumbe', name: 'Derrumbe'},
                                    {id:'Trabajos en vía', name:'Trabajos en vía'},
                                    {id:'Manifestación', name:'Manifestación'},
                                    {id:'Orden público', name:'Orden público'},
                                    {id:'Otro', name:'Otro'}
                                ]} formClassName={classes.grid_cont4} validate={[required(), validarCausa]}/>
                            <SelectInput label="Elemento Afectado"  fullWidth source="elementoAfect" choices={[
                                    {id: 'Calzada', name: 'Calzada'},
                                    {id: 'Puente', name: 'Puente'},
                                    {id:'Túnel', name:'Túnel'},
                                    {id:'Bermas', name:'Bermas'}
                                ]} formClassName={classes.grid_cont4} validate={[required(), validarElemento]}/>                        
                            <TextInput label="Observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                            <Typography variant="b" component="b" fullWidth formClassName={classes.grid_cont12}>Asociar Servicios</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                            <ArrayInput label="" source="servicios" fullWidth>
                                <SimpleFormIterator>
                                    <SelectInput label="Directorio"  fullWidth source="serviciosDirectorio" choices={[
                                        {id: 'Emergencias', name: 'Emergencias'},
                                        {id: 'Proveedores', name: 'Proveedores'},
                                        {id:'Servidores Públicos', name:'Servidores Públicos'}
                                    ]} formClassName={classes.grid_cont12} validate={[required()]}/>
                                    <NumberInput source="tipoServicio" className={classes.ocultar} formClassName={classes.ocultar}/>
                                    <FormDataConsumer className={classes.ocultar}>
                                                    {({formData, scopedFormData, dispatch, ...rest}) => {
                                                        if(typeof scopedFormData !='undefined'){
                                                            if(scopedFormData.serviciosDirectorio === 'Emergencias'){
                                                                scopedFormData.tipoServicio=1;
                                                            }
                                                            else if(scopedFormData.serviciosDirectorio === 'Proveedores'){
                                                                scopedFormData.tipoServicio=2;
                                                            }
                                                            else if(scopedFormData.serviciosDirectorio === 'Servidores Públicos'){
                                                                scopedFormData.tipoServicio=3;
                                                            }
                                                        }
                                                }}
                                            </FormDataConsumer>
                                    {/* <FormDataConsumer>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Emergencias' &&
                                            <NumberInput source={getSource("tipoServicio")} defaultValue={1} className={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Proveedores' &&
                                            <NumberInput source={getSource("tipoServicio")} defaultValue={2} className={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === 'Servidores Públicos' &&
                                            <NumberInput source={getSource("tipoServicio")} defaultValue={3} className={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer> */}
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Emergencias' &&
                                                <SelectInput label="Grupo" source={getSource('serviciosGrupoEmergencias')} fullWidth choices={[
                                                    { id: 'POB-Supervisión vial', name: 'POB-Supervisión vial' },
                                                    { id: 'POB-Administrativa', name: 'POB-Administrativa' },
                                                    { id: 'POB-Radio Operación', name: 'POB-Radio Operación' },
                                                    { id: 'POB-Grúas', name: 'POB-Grúas' },
                                                    { id: 'POB-Carro taller', name: 'POB-Carro taller' },
                                                    { id: 'POB-Ambulancia', name: 'POB-Ambulancia' },
                                                    { id: 'POB-Peajes', name: 'POB-Peajes' },
                                                    { id: 'Interventoría', name: 'Interventoría' },
                                                    { id: 'Servicios médicos', name: 'Servicios médicos' },
                                                    { id: 'Policía', name: 'Policía' },
                                                    { id: 'Ejército', name: 'Ejército' },
                                                    { id: 'Defensa civil', name: 'Defensa civil' },
                                                    { id: 'Bomberos', name: 'Bomberos' },
                                                    { id: 'Centro educativo', name: 'Centro educativo' },
                                                    { id: 'Alcaldía', name: 'Alcaldía' },
                                                    { id: 'Emisora radial', name: 'Emisora radial' },
                                                    { id: 'Talleres', name: 'Talleres' },
                                                    { id: 'Maquinaria', name: 'Maquinaria' },
                                                    { id: 'Otros', name: 'Otros' },
                                                ]}  validate={[required()]}/>
                                            )
                                        }
                                    </FormDataConsumer>  
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Proveedores' &&
                                            <ReferenceInput label="Sistema" source={getSource('serviciosSistemaProveedores')} reference="sistemas" validate={[required()]}>
                                            <SelectInput  optionText="nombre" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer> 
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === 'Servidores Públicos' &&
                                            <SelectInput label="Grupo" source={getSource('serviciosGrupoServicios')} choices={[
                                                { id: 'Acueducto', name: 'Acueducto' },
                                                { id: 'Gas natural', name: 'Gas natural' },
                                                { id: 'Energía', name: 'Energía' },
                                                { id: 'Telecomunicaciones', name: 'Telecomunicaciones' },
                                                { id: 'Aseo', name: 'Aseo' },
                                                { id: 'Otros', name: 'Otros' },
                                            ]} fullWidth validate={[required()]}/>
                                            )
                                        }
                                    </FormDataConsumer> 
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosGrupoEmergencias &&
                                            <ReferenceInput label="Elemento" source={getSource('serviciosElementoEmergencias')} reference="contacto_emergencias" filter={{grupo: scopedFormData.serviciosGrupoEmergencias}} validate={[required()]} formClassName={classes.grid_cont4}>
                                                <SelectInput  optionText="empresa" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosSistemaProveedores &&
                                            <ReferenceInput label="Elemento" source={getSource('serviciosElementoProveedores')} reference="proveedors" filter={{sistema: scopedFormData.serviciosSistemaProveedores}} validate={[required()]} formClassName={classes.grid_cont4}>
                                                <SelectInput  optionText="razonSocial" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosGrupoServicios &&
                                            <ReferenceInput label="Elemento" source={getSource('serviciosElementoServicios')} reference="servicios_publicos" filter={{grupo: scopedFormData.serviciosGrupoServicios}} validate={[required()]} formClassName={classes.grid_cont4}>
                                                <SelectInput  optionText="empresa" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                </FormTab>
                <FormTab label="CARACTERÍSTICAS">
                {(estado==="Incidente" && causa==="Trabajos en vía") &&
                            <NumberInput label="Longitud de Cola (m)" source="longitudCola" fullWidth validate={[required(), validarLongcl]} formClassName={classes.grid_cont6}/>
                }
                {(estado==="Incidente" && causa==="Trabajos en vía") &&
                            <TextInput label="Indicador O2" source="indicadorO2" fullWidth validate={[required()]} disabled format={v =>{ return longcl}} formClassName={classes.grid_cont6}/>
                }
                {(estado==="Incidente" && causa==="Trabajos en vía") &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                             if(typeof formData != 'undefined'){
                            formData.indicadorO2=longcl
                             }
                        }}
                        </FormDataConsumer>
                }       
                {(estado==="Incidente" && causa==="Trabajos en vía") &&
                            <TextInput label="Observaciones" source="observacionesTrabajos" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                }
                {estado==="Incidente" && causa==="Derrumbe" &&                    
                            <NumberInput label="Volumen del Derrumbe (m3)" source="volumenDerrumbe" fullWidth validate={[required(), validarVolder, valorTiempodespeje]} formClassName={classes.grid_cont3}/>                        
                }
                {estado==="Incidente" && causa==="Derrumbe" &&                    
                            <TextInput label="Elemento Afectado" fullWidth validate={[required()]}  disabled defaultValue={elementoaf} formClassName={classes.grid_cont3}/>
                }
                {estado==="Incidente" && causa==="Derrumbe" && volumenderr>2000 &&
                            <SelectInput label="Condiciones Especiales" fullWidth validate={[required(), validarCondicionesesp]} source="condicionesEsp" choices={[
                                {id:'Disposición de Nuevos Dispositivos de Contención', name:'Disposición de Nuevos Dispositivos de Contención'},
                                {id:'Mayores Dificultades', name:'Mayores Dificultades'}
                            ]} formClassName={classes.grid_cont3}/>
                }   
                {estado==="Incidente" && causa==="Derrumbe" &&        
                            <TextInput label="Tiempo de Despeje Máximo (h)" id="tiempodespejemax" fullWidth  validate={[required()]} source="tiempoDespeje" disabled  format={v=> { return tiempodespeje;}} formClassName={classes.grid_cont3}/>
                }
                {estado==="Incidente" && causa==="Derrumbe" &&                    
                        <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                                    if(typeof formData != 'undefined'){
                                   formData.tiempoDespeje=tiempodespeje
                                    }
                        }}
                        </FormDataConsumer>
                }
                {estado==="Incidente" && causa==="Derrumbe" &&                    
                    <TextInput label="Observaciones" source="observacionesDerrumbe" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                }
                {estado==="Incidente" && causa==="Otro" &&
                            <TextInput label="Tipo de Incidente" fullWidth validate={[required(), validarTipoincidente]} source="tipoIncidenteOtro" formClassName={classes.grid_cont6}/>
                }
                {estado==="Incidente" && causa==="Otro" &&
                            <TextInput label="Tipo de Vehículo" fullWidth validate={[required(), validarTipovehiculootro]} source="tipoVehiculoOtro" formClassName={classes.grid_cont6}/>
                }
                {estado==="Incidente" && causa==="Otro" &&
                            <TextInput label="Observaciones" source="observacionesOtro" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline />
                }

                {estado==="Accidente" &&
                            <SelectInput label="Tipo de Accidente" fullWidth source="tipoAccidente" choices={[
                                {id:'Simple', name:'Simple'},
                                {id:'Heridos', name:'Heridos'},
                                {id:'Muertos', name:'Muertos'}
                            ]} validate={[required()]} formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12} fullWidth>Juicio de la Policía</Typography>
                }
                {estado==="Accidente" &&
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                            <NumberInput label="Código Causa" source="codigoCausa" fullWidth validate={[required(), validarCodigocausa]} formClassName={classes.grid_cont4}/>                        
                }
                {estado==="Accidente" &&
                            <TextInput label="Causa Probable" source="causaProbable" validate={[required(), validarCausaProbable]} fullWidth formClassName={classes.grid_cont4}/>
                }
                {estado==="Accidente" &&
                            <TextInput label="Clase" source="clasePolicia" validate={[required(), validarClasePolicia]} fullWidth formClassName={classes.grid_cont4}/>
                }
                {estado==="Accidente" &&
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12} fullWidth>Heridos</Typography>
                }
                {estado==="Accidente" &&
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                            <ArrayInput label="" source="heridos" fullWidth>
                                <SimpleFormIterator>
                                    <NumberInput label="Cantidad" source="cantidadHeridos" validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3}  fullWidth/>
                                    <TextInput label="Tipo" source="tipoHeridos" validate={[required(), validartipoheridos]} formClassName={classes.grid_cont3}  fullWidth/>
                                    <TextInput label="Género" source="generoHeridos" validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}  fullWidth/>
                                    <NumberInput label="Edad" source="edadHeridos" validate={[required(), validaredadheridos]} formClassName={classes.grid_cont2}  fullWidth/>
                                </SimpleFormIterator>
                            </ArrayInput>
                }
                {estado==="Accidente" &&
                            <Typography variant="b" component="b">Víctimas Fatales</Typography>
                }
                {estado==="Accidente" &&
                            <Divider fullWidth/>
                }
                {estado==="Accidente" &&
                        <ArrayInput label="" source="victimas" fullWidth>
                            <SimpleFormIterator>
                                <NumberInput label="Cantidad" source="cantidadVictimasf" validate={[required(), validarcantidadheridos]} fullWidth formClassName={classes.grid_cont3}/>
                                <TextInput label="Tipo" source="tipoVictimasf" validate={[required(), validartipoheridos]} fullWidth formClassName={classes.grid_cont3}/>
                                <TextInput label="Género" source="generoVictimasf" validate={[required(), validargeneroheridos]} fullWidth formClassName={classes.grid_cont3}/>
                                <NumberInput label="Edad" source="edadVictimasf" validate={[required(), validaredadheridos]} fullWidth formClassName={classes.grid_cont2}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                }
                {estado==="Accidente" &&
                        <Typography variant="b" component="b" formClassName={classes.grid_cont12} fullWidth>Vehículos Implicados</Typography>
                }
                {estado==="Accidente" &&
                        <Divider fullWidth formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                        <ArrayInput label="" source="vehiculos" fullWidth>
                            <SimpleFormIterator>
                                <NumberInput label="Cantidad" source="cantidadVehiculos" fullWidth validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Categoría" source="categoriaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Tipo" source="tipoVehiculos" fullWidth validate={[required(), validartipovehiculo]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Marca" source="marcaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2}/>
                                <TextInput label="Línea" source="lineaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Modelo" source="modeloVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Placa" source="placaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Color" source="colorVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                }
                {estado==="Accidente" &&
                            <TextInput label="Observaciones" source="observacionesAccidente" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                }
                    
                </FormTab>
                {((estado==="Incidente" && causa==="Derrumbe") || (estado==="Incidente" && causa==="Otro" ) || (estado==="Accidente") || (estado === "Cierre")) &&

                
                <FormTab label="FINALIZAR">
                    {estado==="Incidente" && causa==="Derrumbe" &&       
                        <DateInput label="Fecha de Despeje" source="fechaDespeje" id="fecha_despeje" disabled format={v =>{ return today}} formClassName={classes.grid_cont6} fullWidth/>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                            if(typeof formData != 'undefined'){
                            formData.fechaDespeje=today
                            }
                        }}
                        </FormDataConsumer>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                                <FInput label="Hora de Despeje" source="horaDespeje" id="hora_despeje" disabled format={v =>{ return hora}} formClassName={classes.grid_cont6} fullWidth/>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                            if(typeof formData != 'undefined'){
                            formData.horaDespeje=hora
                            }
                        }}
                        </FormDataConsumer>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                                <NumberInput label="Días de Despeje" id="dias_despejef" disabled source="diasDespeje" format={v =>{ return diasdespeje}} formClassName={classes.grid_cont4} fullWidth/>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                            if(typeof formData != 'undefined'){
                            formData.diasDespeje=diasdespeje
                            }
                        }}
                        </FormDataConsumer>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                                <FInput label="Tiempo de Despeje (h:mm)" id="tiempo_deespejef" disabled source="tiempoDespejeFin" format={v =>{ return horadespeje}} formClassName={classes.grid_cont4} fullWidth/>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                             if(typeof formData != 'undefined'){
                            formData.tiempoDespejeFin=horadespeje
                             }
                        }}
                        </FormDataConsumer>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                                <TextInput label="Inicador O4" disabled id="indicaddoro4" source="indicadorO4" format={v =>{ return indicadoro4}} formClassName={classes.grid_cont4} fullWidth/>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                        {({formData, dispatch, ...rest}) => {
                             if(typeof formData != 'undefined'){
                            formData.indicadorO4=indicadoro4
                             }
                        }}
                        </FormDataConsumer>
                    }
                    {estado==="Incidente" && causa==="Derrumbe" &&
                                <TextInput label="Observaciones" source="observacionesDespeje" validate={[required(), validarobservaciones, validardespeje]} multiline formClassName={classes.grid_cont12} fullWidth/>
                    }
                    {estado==="Incidente" && causa==="Otro" &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <ArrayInput label="" source="servicios" fullWidth validate={[validararray]} defaultValue={[{tiempoServicio:tiemposervicio}]}>
                                    <SimpleFormIterator disableAdd disableRemove >
                                        
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.tipoServicio === 1 &&
                                                <ReferenceInput label="Elemento" source={getSource("serviciosElementoEmergencias")} reference="contacto_emergencias" fullWidth>
                                                    <SelectInput  optionText="empresa"  disabled/>
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.tipoServicio === 2 &&
                                                <ReferenceInput label="Elemento" source={getSource("serviciosElementoProveedores")} reference="contacto_emergencias" fullWidth>
                                                    <SelectInput  optionText="razonSocial"  disabled/>
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.tipoServicio === 3 &&
                                                <ReferenceInput label="Elemento" source={getSource("serviciosElementoServicios")} reference="contacto_emergencias" fullWidth>
                                                    <SelectInput  optionText="empresa"  disabled/>
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && 
                                                <DateInput label="Fecha Finalización" source={getSource("fechaFinalizacion")}  validate={[required()]} fullWidth/>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && 
                                                <FInput label="Hora Finalización" source={getSource("horaFinalizacion")}  fullWidth format={timeFormatter}/>
                                            )
                                        }
                                        </FormDataConsumer>
                                            <FInput label="Tiempo Servicio" source="tiempoServicio"  disabled fullWidth/>                                        
                                            <FormDataConsumer className={classes.ocultar}>
                                                    {({formData, scopedFormData, dispatch, ...rest}) => {
                                                    
                                                    if(typeof formData != 'undefined' && scopedFormData.fechaFinalizacion && scopedFormData.horaFinalizacion){
                                                            let ffinal=scopedFormData.fechaFinalizacion;
                                                            if(ffinal){
                                                                let aux=ffinal.split('T');
                                                                ffinal=aux[0];
                                                            }
                                                            let hfinal=scopedFormData.horaFinalizacion;
                                                            if(hfinal){
                                                                let aux=hfinal.split(':')
                                                                if(aux[0]<=9){
                                                                    hfinal="0"+hfinal;
                                                                }
                                                            }
                                                            let fechareporte=formData.fechaReporte;  
                                                            if(fechareporte){
                                                                let aux=fechareporte.split('T');
                                                                fechareporte=aux[0];
                                                            }                                       
                                                        let fecha_tiemposervicio=new Date(ffinal+"T"+hfinal);
                                                        let fecha_reporte=new Date(fechareporte+"T"+formData.horareport);
                                                        let fecha_tiemposervicio_off=new Date( fecha_tiemposervicio.getTime() + Math.abs(fecha_tiemposervicio.getTimezoneOffset()*60000) );
                                                        let fecha_reporte_off=new Date( fecha_reporte.getTime() + Math.abs(fecha_reporte.getTimezoneOffset()*60000) );
                                                        let difference=fecha_reporte_off.getTime()-fecha_tiemposervicio_off.getTime();
                                                        let differenhours=difference/(1000*3600);     
                                                        let differenminutes=(difference/(1000*60))%60;
                                                        console.log(fecha_reporte_off+" // "+ffinal+"T"+hfinal);

                                                        scopedFormData.tiempoServicio=Math.abs(parseInt(differenhours))+":"+Math.abs(parseInt(differenminutes));
                                                    }
                                                }}
                                            </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput label="Observaciones" source="observacionesFinalizacion" formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} fullWidth multiline/>
                            </Grid>
                        </Grid>
                    }
                    {estado==="Accidente" &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <ArrayInput label="" source="servicios" fullWidth validate={[validararray]} defaultValue={[{tiempoServicio:tiemposervicio}]}>
                                    <SimpleFormIterator disableAdd disableRemove >
                                        <NumberInput source="tipoServicio" className={classes.ocultar}/>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.tipoServicio === 1 &&
                                                <ReferenceInput label="Elemento" source={getSource("serviciosElementoEmergencias")} reference="contacto_emergencias" fullWidth>
                                                    <SelectInput  optionText="empresa"  disabled/>
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.tipoServicio === 2 &&
                                                <ReferenceInput label="Elemento" source={getSource("serviciosElementoProveedores")} reference="contacto_emergencias" fullWidth>
                                                    <SelectInput  optionText="razonSocial"  disabled/>
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.tipoServicio === 3 &&
                                                <ReferenceInput label="Elemento" source={getSource("serviciosElementoServicios")} reference="contacto_emergencias" fullWidth>
                                                    <SelectInput  optionText="empresa"  disabled/>
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                                                <DateInput label="Fecha de Llegada" source={getSource("fechaLlegada")}  validate={[required()]} fullWidth/>
                                            )
                                        }
                                        </FormDataConsumer>
                                        <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                                                <FInput label="Hora de Llegada" source={getSource("horaLlegada")}  fullWidth format={timeFormatter}/>
                                            )
                                        }
                                        </FormDataConsumer>
                                            <FInput label="Tiempo de Respuesta" source="tiempoRespuesta"  disabled fullWidth/>                                        
                                            <FormDataConsumer className={classes.ocultar}>
                                                    {({formData, scopedFormData, dispatch, ...rest}) => {
                                                    
                                                    if(typeof formData != 'undefined' && typeof scopedFormData != 'undefined' && scopedFormData.fechaLlegada && scopedFormData.horaLlegada){
                                                            let ffinal=scopedFormData.fechaLlegada;
                                                            if(ffinal){
                                                                let aux=ffinal.split('T');
                                                                ffinal=aux[0];
                                                            }
                                                            let hfinal=scopedFormData.horaLlegada;
                                                            if(hfinal){
                                                                let aux=hfinal.split(':')
                                                                if(aux[0]<=9){
                                                                    hfinal="0"+hfinal;
                                                                }
                                                            }
                                                            let fechareporte=formData.fechaReporte;  
                                                            if(fechareporte){
                                                                let aux=fechareporte.split('T');
                                                                fechareporte=aux[0];
                                                            }                                       
                                                        let fecha_tiemposervicio=new Date(ffinal+"T"+hfinal);
                                                        let fecha_reporte=new Date(fechareporte+"T"+formData.horareport);
                                                        let fecha_tiemposervicio_off=new Date( fecha_tiemposervicio.getTime() + Math.abs(fecha_tiemposervicio.getTimezoneOffset()*60000) );
                                                        let fecha_reporte_off=new Date( fecha_reporte.getTime() + Math.abs(fecha_reporte.getTimezoneOffset()*60000) );
                                                        let difference=fecha_reporte_off.getTime()-fecha_tiemposervicio_off.getTime();
                                                        let differenhours=difference/(1000*3600);     
                                                        let differenminutes=(difference/(1000*60))%60;
                                                        console.log(fecha_reporte_off+" // "+ffinal+"T"+hfinal);

                                                        scopedFormData.tiempoRespuesta=Math.abs(parseInt(differenhours))+":"+Math.abs(parseInt(differenminutes));
                                                    }
                                                }}
                                            </FormDataConsumer>
                                            <TextInput label="Indicador O5" source="indicadorO5" disabled fullWidth/>
                                            <FormDataConsumer className={classes.ocultar}>
                                                {({formData, dispatch, scopedFormData, ...rest}) => {
                                                 if(typeof formData != 'undefined' && typeof scopedFormData != 'undefined' && scopedFormData.tiempoRespuesta){
                                                    let aux=scopedFormData.tiempoRespuesta.split(':');
                                                    let horaresp=aux[0];
                                                    let minutosresp=aux[1];
                                                    if(scopedFormData.serviciosElementoEmergencias==="Ambulancia"){
                                                        if((minutosresp>30 && horaresp==0) || horaresp>=1){
                                                            scopedFormData.indicadorO5="No Cumple"
                                                        }
                                                    }
                                                    else if((horaresp>1) || (horaresp==1 && minutosresp>0)){
                                                        scopedFormData.indicadorO5="No Cumple"
                                                    }
                                                    else{
                                                        scopedFormData.indicadorO5="Cumple"
                                                    }
                                                    
                                                    }
                                                }}
                                             </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput label="Observaciones" source="observacionesLlegada" validate={[required(), validarobservaciones]} multiline fullWidth/>
                            </Grid>
                        </Grid>
                    }
                    {estado === "Cierre"  &&
                        <Grid container spacing={24}>
                            <Grid item xs={4}>
                                <DateInput label="Fecha de Puesta en Servicio" id="fecha_puestaservicio" disabled format={v =>{ return today}} source="fechaPuestaServicio" fullWidth/>
                                <FormDataConsumer className={classes.ocultar}>
                                {({formData, dispatch, ...rest}) => {
                                if(typeof formData != 'undefined'){
                                formData.fechaPuestaServicio=today
                                }
                                }}
                            </FormDataConsumer>
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="No. Días Con Cierre" disabled source="diasCierre" fullWidth format={v =>{ return diasconcierre}}/>
                            </Grid>
                                <FormDataConsumer className={classes.ocultar}>
                                {({formData, dispatch, ...rest}) => {
                                if(typeof formData != 'undefined'){
                                formData.diasCierre=diasconcierre
                                }
                                }}
                            </FormDataConsumer>
                            <Grid item xs={4}>
                                <TextInput label="Indicador E17" disabled source="indicadorE17" fullWidth format={v =>{ return indicadore17}}/>
                            </Grid>
                                <FormDataConsumer className={classes.ocultar}>
                                {({formData, dispatch, ...rest}) => {
                                if(typeof formData != 'undefined'){
                                formData.indicadorE17=indicadore17
                                }
                                }}
                            </FormDataConsumer>
                            <Grid item xs={12}>
                            <Typography  variant="b" component="b">No. Segmentos con cierre</Typography>
                            </Grid>         
                                <Query type="GET_MANY" resource="segmentos" payload={{ids: segmentosCierre}}>
                                {({ data, loading, error }) => {
                                if (loading) { return <Loading/>; }
                                if (error) { return <p>ERROR {error.message}</p>; }
                                    return(
                                        <Grid item xs={12}>
                                        <table>
                                            <tr><td>{"Segmentos Afectados"}</td></tr>
                                        {data.map(segmento=>
                                        <tr>
                                            <td>{segmento.fullCodigo}</td>
                                        </tr>
                                        )} 
                                        </table>
                                        </Grid>
                                    )
                                }}
                                </Query>
                            <Grid item xs={12}>
                                <TextInput label="Observaciones" validate={[required(), validarobservaciones, validardiascierre]} source="observacionesCierre" fullWidth multiline/>
                            </Grid>
                        </Grid>
                    }
                </FormTab>
                }
            </TabbedForm>
</Edit>
);
})
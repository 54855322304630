import React from 'react';
import { ReferenceField, TabbedShowLayout, Tab, TextField, DateField, NumberField, FormTab, TabbedForm
} from 'react-admin';
import { Show } from './../../Show';
import { DField } from './../../../fields/DField';
import { EField } from './../../../fields/EField';
import Divider from '@material-ui/core/Divider';
import  {styles}  from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';

export const EjesShow = withStyles(styles)(({ classes, record, ...props }) => { 

return (
  <Show title="Información Eje Equivalente" {...props}>
    <TabbedForm>
      <FormTab label="iNFORMACIÓN BÁSICA">
        <ReferenceField label="Inventario" source="estacion_conteo" reference="estacion_conteos" fullWidth linkType={false} formClassName={classes.grid_cont3s}>
          <TextField source="nombre" />
        </ReferenceField>
        <DateField label="Fecha medición" source="date" fullWidth formClassName={classes.grid_cont3}/>
        <br/>
        <TextField label="Consultor" source="consultor" fullWidth formClassName={classes.grid_cont3s} />
        <TextField label="Informe" source="informe" fullWidth formClassName={classes.grid_cont3} />
        <br/>

        <b>Resumen de Resultados</b>
        <Divider fullWidth/>

        <NumberField label="Factor direccional" source="direccional" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} formClassName={classes.grid_cont3s}/>
        <NumberField label="Factor Carril" source="carril" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} formClassName={classes.grid_cont3}/>
        <NumberField label="TPD" source="tpd" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
        
      </FormTab>
      <FormTab label="Categoría">
        <h3 style={{ marginBottom: 0 }} formClassName={classes.grid_cont3s}>Autos</h3>
        <NumberField label="Factor Daño" source="auto_dano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <NumberField label="Volumen" source="auto_volumen" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <br/>
        <h3 style={{ marginBottom: 0 }} formClassName={classes.grid_cont3s}>Buses</h3>
        <NumberField label="Factor Daño" source="buses_dano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <NumberField label="Volumen" source="buses_volumen" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <br/>
        <h3 style={{ marginBottom: 0 }} formClassName={classes.grid_cont3s}>C2-P</h3>
        <NumberField label="Factor Daño" source="c2_p_dano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <NumberField label="Volumen" source="c2_p_volumen" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <br/>
        <h3 style={{ marginBottom: 0 }} formClassName={classes.grid_cont3s}>C2-G</h3>
        <NumberField label="Factor Daño" source="c2_g_dano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <NumberField label="Volumen" source="c2_g_volumen" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <br/>
        <h3 style={{ marginBottom: 0 }} formClassName={classes.grid_cont3s}>C3-C4</h3>
        <NumberField label="Factor Daño" source="c3_dano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <NumberField label="Volumen" source="c3_volumen" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <br/>
        <h3 style={{ marginBottom: 0 }} formClassName={classes.grid_cont3s}>C5</h3>
        <NumberField label="Factor Daño" source="c5_dano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <NumberField label="Volumen" source="c5_volumen" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <br/>
        <h3 style={{ marginBottom: 0 }} formClassName={classes.grid_cont3s}>C5</h3>
        <NumberField label="Factor Daño" source="c5_1_dano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <NumberField label="Volumen" source="c5_1_volumen" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
        <span></span>
      </FormTab>
      <FormTab label="Auditoria">
        <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont3}/>
        <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont3}/>
        <br/>
        <DateField source="updatedAt" label="Fecha actualización"formClassName={classes.grid_cont3} />
        <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont3}/>
      </FormTab>
    </TabbedForm>
  </Show>
)});
import React, {useState} from 'react';
import { SimpleForm, TabbedForm, FormTab, SelectInput, DisabledInput, TextInput, ReferenceInput, DateInput, ArrayInput, SimpleFormIterator, AutocompleteInput} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { Edit } from './../Edit';
import  {styles}  from './../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Parametros from './parametros';
import { required, minLength, maxLength } from 'react-admin';

export const ParametrosEdit = withStyles(styles)(({ classes, record, ...props }) => {
    const [tipoParametro, setTipoParametro] = useState('');
const validarTipoParametro=(value)=>{
setTipoParametro(value);
}
let idproyecto=localStorage.getItem("id_project2");
    return(
        <Edit  title="Editar Parámetros de Evaluación" {...props}  undoable={false}>
         <SimpleForm submitOnEnter={false} redirect="list" > 
         <Typography component="b" variant="b" style={{textAlign: "center"}} formClassName={classes.grid_cont12} fullWidth>Parámetros de Evaluación</Typography>
        <Divider formClassName={classes.grid_cont12} fullWidth/>
         <SelectInput label="Indicadores que apliquen a:" choices={[
             {id:"All", name:"Todos los Parámetros"},
             {id:"PFLEX", name:"Indicadores exclusivos para Pavimento Flexible"},
             {id:"PRIG", name:"Indicadores exclusivos para Pavimento Rígido"},
             {id:"PRIGYFLEX", name:"Indicadores exclusivos para Pavimento Rigido y Flexible"},
             {id:"PUENTYEST", name:"Indicadores exclusivos de Puentes y Estructuras"},
             {id:"TUN", name:"Indicadores exclusivos para Túneles"},
             {id:"OPER", name:"Indicadores Operacionales"},

         ]} formClassName={classes.grid_cont12} fullWidth validate={validarTipoParametro} defaultValue="All"/>
         <WithProps>{({record,...props})=>
            <Parametros aplicaind={tipoParametro} record={record} tipo="editar"/>
                    }
                </WithProps>
         </SimpleForm>
        </Edit>
    )
})
const WithProps = ({children,...props}) => children(props);
import React, { useState, Fragment } from 'react';
import { FormTab, NumberInput, ReferenceInput, ArrayInput, SimpleFormIterator, SelectInput, FormDataConsumer, 
    DateInput, AutocompleteInput
} from 'react-admin';
import { TabbedForm, BooleanInput, TextInput } from 'react-admin';
import { PimsElementoActions } from './../PimsElementoActions';
import { latMininima, lngMininima, validarDosDecimales, abValidationInicio, abValidationFin, Entero, isFechaInvalida
} from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { required, maxLength, minValue, maxValue } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { ImagenesInventario } from '../../images/ImagenesInventario';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';




const tipoElemento = { sistema: 'PTS' };
export const ElementoContencionCreate = withStyles(styles)(({ classes, ...props }) => {

    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }

    const Unidades = { unidadFuncional: unidads };

    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }

    const Tramoss = { tramo: tramos };

    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        setSegmento(value)
    }


    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/


    const [estados, setEstado] = useState('');

    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)

    }

    const [elemento, setElemento] = useState('-');
    const ValidarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor);
    }
/*OPCIONES PARA TIPO DE IMÁGENES*/
   const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
   const OpcionTipoImagen = (value) =>{    
        setDefaultTipoImagen([
            {
                tipoImagen : '/tipo_images/1',
            },
            {
                tipoImagen : '/tipo_images/7',
            },
            {
                tipoImagen : '/tipo_images/8',
            },
            {
                tipoImagen : '/tipo_images/9',
            },
            {
                tipoImagen : '/tipo_images/5',
            },
            {
                tipoImagen : '/tipo_images/6',
            }
        ])
   }


    return (
        <PimsCreate  {...props} title="Crear Elemento contención" actions={<PimsElementoActions />}>
            <TabbedForm redirect="list">
                <FormTab label="IDENTIFICACIÓN">
                    <CoordenadasProyecto/>
                    <ReferenceInput 
                        label="Tipo de Elemento" 
                        source="tipoElemento" 
                        reference="tipo_elementos" 
                        sort={{ field: 'codigo', order: 'ASC' }}
                        filter={{id : '/tipo_elementos/CONT'}}
                        filterToQuery={searchText => ({ nombre: searchText })} 
                        onChange={ValidarElemento}
                        validate={[required(),OpcionTipoImagen]}
                    >
                    
                        <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
                    </ReferenceInput>
                    <SelectInput label="Margen" source="margen" validate={[required()]}
                        choices={[
                            { id: 'Derecha', name: 'Derecha' },
                            { id: 'Izquierda', name: 'Izquierda' },
                        ]}
                        fullWidth formClassName={classes.grid_cont6_f} />
                    <SelectInput label="Tipo" source="tipo" validate={[required()]} sort={{ field: 'name', order: 'asc' }}
                        choices={[
                            { id: "Anclajes en roca", name: "Anclajes en roca" },
                            { id: "Cercados y redes de contención", name: "Cercados y redes de contención" },
                            { id: "Escudo contra caída de rocas", name: "Escudo contra caída de rocas" },
                            { id: "Muro anclado", name: "Muro anclado" },
                            { id: "Muro de concreto reforzado", name: "Muro de concreto reforzado" },
                            { id: "Muro de encofrado", name: "Muro de encofrado" },
                            { id: "Muro de gaviones", name: "Muro de gaviones" },
                            { id: "Muro de gravedad en concreto", name: "Muro de gravedad en concreto" },
                            { id: "Muro de tierra reforzada", name: "Muro de tierra reforzada" },
                            { id: "Muros de contención", name: "Muros de contención" },
                            { id: "Pantallas ancladas", name: "Pantallas ancladas" },
                            { id: "Pilotes y caissons", name: "Pilotes y caissons" },
                            { id: "Reconformación", name: "Reconformación" },
                            { id: "Revestimiento flexible", name: "Revestimiento flexible" },
                            { id: "Revestimiento vegetal", name: "Revestimiento vegetal" },
                            { id: "Suelo empernado", name: "Suelo empernado" },
                            { id: "Otros", name: "Otros" },
                        ]}
                        fullWidth formClassName={classes.grid_cont6_s} />
                        <br/>
                    <SelectInput label="Funcionalidad" source="funcionalidad" validate={[required()]}
                        choices={[
                            { id: "Control de cauces", name: "Control de cauces" },
                            { id: "Estabilidad de banca", name: "Estabilidad de banca" },
                            { id: "Estabilidad de taludes", name: "Estabilidad de taludes" },
                            { id: "Protección de infraestructura", name: "Protección de infraestructura" },
                            { id: "Otros", name: "Otros" }
                        ]}
                        fullWidth formClassName={classes.grid_cont6_f} />
                    <TextInput source="tipoElemento" className={classes.ocultar} reference="tipo_elementos" validate={[required()]} />
                    <Typography variant="b" component="b">Localización</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceInput
                                label="Unidad Funcional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                formClassName={classes.grid_cont4_f}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        {unidads &&
                                <ReferenceInput
                                    label="Tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    fullWidth
                                    formClassName={classes.grid_cont4_s}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                        }
                        {tramos &&
                                <ReferenceInput
                                    label="Segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    fullWidth
                                    formClassName={classes.grid_cont4_s}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                        }
                    {/* ELEMENTOS OCULTOS */}
                    {segmentos &&
                        <AbsSegmento segmento={segmentos}  />
                    }
                    {/* ----------------- */}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas iniciales</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4_f} />}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4_s} />}
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4_s} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, required()]} formClassName={classes.grid_cont4_f} />}
                    {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[required(), latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont4_s} />}
                    {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[required(), lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4_s} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="Características">
                    <NumberInput label="Largo (m)" source="largo" validate={[minValue(0), required(), maxValue(200), validarDosDecimales]} fullWidth formClassName={classes.grid_cont4_f} />
                    <NumberInput label="Ancho (m)" source="ancho" validate={[minValue(0), maxValue(50), validarDosDecimales]} fullWidth formClassName={classes.grid_cont4_s} />
                    <NumberInput label="Altura  (m)" source="altura" validate={[minValue(0), maxValue(100), validarDosDecimales]} fullWidth formClassName={classes.grid_cont4_s} />
                    <BooleanInput label="Estudios de estabilidad del sitio" source="estudioEstabilidad" fullWidth formClassName={classes.grid_cont12} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.estudioEstabilidad &&
                            <Fragment>
                                <TextInput label="Consultor" source="consultor" validate={[maxLength(30)]} className={classes.grid_cont6_f} fullWidth />
                                <DateInput label="Fecha del estudio" source="fechaEstudio" validate={[isFechaInvalida]} className={classes.grid_cont6_s} fullWidth />
                                <TextInput label="Código de estudio" source="codigoEstudio" validate={[maxLength(10)]} className={classes.grid_cont6_f} fullWidth />
                                <TextInput label="Nombre del estudio" source="nombreEstudio" validate={[maxLength(50)]} className={classes.grid_cont6_s} fullWidth />
                            </Fragment>
                        )}
                    </FormDataConsumer>
                </FormTab>
                
                {/* ------------ DETALLES ------------- */}
                <FormTab label="Detalles">
                    <SelectInput label="Condición del elemento" source="estado"
                        choices={[
                            { id: "Existente", name: "Existente" },
                            { id: "Reemplazo", name: "Reemplazo" },
                            { id: "Nuevo", name: "Nuevo" },
                        ]}
                        validate={[required()]} onChange={ValidarEstado} fullWidth formClassName={classes.grid_cont4_f} />
                    {estados === "Reemplazo" &&
                        <SelectInput label="Motivo reemplazo" source="motivoReemplazo"
                            choices={[
                                { id: "Instalación", name: 'Instalación' },
                                { id: "Reemplazo programado", name: 'Reemplazo programado' },
                                { id: "Accidente", name: 'Accidente' },
                                { id: "Vandalismo", name: 'Vandalismo' },
                                { id: "Otro", name: 'Otro' },
                            ]}
                            validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {estados === "Reemplazo" &&
                        // <ElementosCreados tabla="elemento_contencions" tipo="create" fullWidth formClassName={classes.grid_cont4_s} />
                        <ReferenceInput
                        label="Reemplazo"
                        source="elementoReemplazo"
                        reference="elemento_contencions"
                        filter={{
                            tipoElemento : elemento,
                            status : 1,
                            // unidadFuncional: unidads,
                            // tramo: tramos,
                            // segmento : segmentos
                        }}
                        fullWidth
                        formClassName={classes.grid_cont4}
                    >
                        <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigo" />
                    </ReferenceInput>
                    }
                    <br/>
                    <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    <div></div>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <DateInput label="Fecha de instalación" source="fechaInstalacion" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont4_f} />

                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput 
                                label="Proveedor" 
                                source="proveedor" 
                                reference="proveedors" 
                                filter={{ sistema: 'PTS' }} 
                                sort={{ field: 'razonSocial', order: 'ASC' }}
                                filterToQuery={searchText => ({ razonSocial: searchText })} 
                                validate={[required()]}
                            >
                                <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                            </ReferenceInput>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="Garantía  (meses)" source="garantia" fullWidth validate={[required(), minValue(0), Entero]} formClassName={classes.grid_cont4_s} />
                            
                        </Grid>
                    </Grid>                    
                    <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont6_f} />
                    <TextInput source="observaciones" validate={[required()]} fullWidth formClassName={classes.grid_cont12} />
                </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
});
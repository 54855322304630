import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {BulkDeleteWithConfirmButton, List, Datagrid, TextField, EditButton, ShowButton, ReferenceInput, 
    Filter,  FormDataConsumer,SelectInput, AutocompleteInput
} from 'react-admin';
import { Fragment } from 'react';
import { PRField } from './../../../fields/PRField';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const styles = {
    ocultar: {
        display:"none",
    },
};
const FiltroSenalizacion =withStyles(styles)(
    ({classes, ...props})=>{
    return(
        <Filter {...props}>
            <ReferenceInput 
                label="Elemento" 
                source="tipoElementoSenal" 
                reference="tipo_elemento_senals" 
                alwaysOn
                sort={{ field: 'nombre', order: 'ASC' }}
                filterToQuery={searchText => ({ nombre: searchText })} 
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
            </ReferenceInput>
           
            <FormDataConsumer className={classes.ocultar} alwaysOn>
            {
                ({ formData, ...rest }) => (
                    <ReferenceInput label="Tipo de elemento" source="tipoSenal"  reference="tipo_senals" filter={{tipoElementoSenal: formData.tipoElementoSenal}} {...rest}>
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                    )
            }
            </FormDataConsumer>                        
            <ReferenceInput label="Tipo de elemento" source="tipoSenal"  reference="tipo_senals" alwaysOn>
                <SelectInput  optionText="nombre" fullWidth />
            </ReferenceInput>                     
            
            <SelectInput label="Estado" source="estados" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]}  fullWidth alwaysOn
            />
            
        </Filter>
)});

export const ElementoSenalizacionList =(props) =>(
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} title="Lista Elemento Señal" filters={<FiltroSenalizacion/>} bulkActionButtons={<PostBulkActionButtons />} filter={{tipoElemento: {sistema: "DRE"}, proyecto: {id:1}}}>
        <Datagrid>
            <TextField label="Código" source="fullCodigo" sortable sortBy="id"/>
            <TextField label="Elemento" source="tipoElementoSenal_.nombre" sortable={false}/>
            {/* <ReferenceField label="senal" source="senal" reference="senals">
                <TextField source="nombre" />
            </ReferenceField> */}
            <TextField label="Tipo de elemento" source="tipoSenal_.nombre" sortable={false}/>
            <TextField label="Modelo" source="senal_.nombre" sortable={false}/>
            <TextField source="material" />
            <PRField label="Progresiva Inicio" source="abInicio" />
            <TextField source="margen" />
            <TextField label="Estado" source="estados" />
            <ShowButton />
            <EditButton />                           
        </Datagrid>
    </List>
);
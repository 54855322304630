import React, {useState, Fragment} from 'react';
import { SimpleForm, FormTab, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput, SimpleFormIterator, ArrayInput, Query, Loading, Error, Datagrid} from 'react-admin';
import { TabbedForm } from './../TabbedForm';
import { PimsCreate } from './../Create';
import { PInput } from './../../inputs/PInput';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FInput } from './../../inputs/FInput';
import Parametros from './parametros';
import {
    required,

} from 'react-admin';
import  {abValidationInicio,
         ValorMaximoAbsCreate,
         latMininima,
         lngMininima,
         
        }  from './../validacionesInputs';
import {Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';

export const ParametrosCreate = withStyles(styles)(({ classes, ...props }) => {
const [tipoParametro, setTipoParametro] = useState('');
const validarTipoParametro=(value)=>{
setTipoParametro(value);
}
let idproyecto=localStorage.getItem("id_project2");
    return(
        <PimsCreate  {...props} title="Crear Parámetros de Evaluación">
         <SimpleForm submitOnEnter={false} redirect="list" > 
         <Typography component="b" variant="b" style={{textAlign: "center"}} formClassName={classes.grid_cont12} fullWidth>Parámetros de Evaluación</Typography>
        <Divider formClassName={classes.grid_cont12} fullWidth/>
         <SelectInput label="Indicadores que apliquen a:" choices={[
             {id:"All", name:"Todos los Parámetros"},
             {id:"PFLEX", name:"Indicadores exclusivos para Pavimento Flexible"},
             {id:"PRIG", name:"Indicadores exclusivos para Pavimento Rígido"},
             {id:"PRIGYFLEX", name:"Indicadores exclusivos para Pavimento Rigido y Flexible"},
             {id:"PUENTYEST", name:"Indicadores exclusivos de Puentes y Estructuras"},
             {id:"TUN", name:"Indicadores exclusivos para Túneles"},
             {id:"OPER", name:"Indicadores Operacionales"},

         ]} formClassName={classes.grid_cont12} fullWidth validate={validarTipoParametro} defaultValue="All"/>
         <Parametros aplicaind={tipoParametro} tipo="crear"/>
         <TextInput source="proyecto" defaultValue={idproyecto} formClassName={classes.ocultar}/>
         </SimpleForm>
        </PimsCreate>
    )
})
import React, { useState, Fragment } from 'react';
import { TabbedForm, FormTab, TextInput, DateInput, SelectInput, FormDataConsumer,
     NumberInput, ReferenceInput, DisabledInput, ArrayInput, SimpleFormIterator, AutocompleteInput,BooleanInput
 } from 'react-admin';
import { PercentageInput } from './../../../../inputs/PercentageInput';
import { ImagenesInventario } from '../../../images/ImagenesInventario';
import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import { SegmentoActions } from './../SegmentoActions';

import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import Grid from '@material-ui/core/Grid';


import Divider from '@material-ui/core/Divider';

import { required, minValue, maxValue, minLength, maxLength } from 'react-admin';
import { Entero, isFechaInvalida } from '../../../validacionesInputs';

//MOSTRAR IMAGEN
const showBtnImage =() =>{
    let btn = document.getElementById("Valbtn");        
    btn.style.display = "inline-flex";
}

//ESCONDER IMAGEN
const HideBtnImage =() =>{ 
    let btn = document.getElementById("Valbtn");    
    btn.style.display = "none";    
}

const styles = {
    first_field: {display: 'inline-block', width: '30%' },
    observaciones_field: {display: 'inline-block', width: '40%'},
    last_filed: {display: 'inline-block', marginLeft: 32, width: '30%' },   
    separador: {marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0}, 
    subtittle: {marginTop: '15px',marginBottom: '15px'},     
    tabla:  {display: 'inline-block', width: '30%', padding: '5px'}       
};
 
export const ElementoAdoquinCreate = withStyles(styles)(({ classes, ...props }) => {
    
    //----------------------------------------------
    //-----------------VALIDACIÓNES-----------------
    //----------------------------------------------
    let defecto = 0;

    //DETALLES
    //------------------->   

    //MOSTRAR/OCULTAR CAMPO RAZON
    const [estado, setEstado] = useState('');

    const Validationrazon =(value) =>{        
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setEstado(valor)        
    }

    //ESAL
    let Esal10 = 0;
    const value10 = (value) =>{
        if(value){
            Esal10 = value;
        }
    }

    const value20 = (value) =>{
        if(Esal10 !== 0){
            if(value <= Esal10){            
                return "Debe ser mayor a 'ESAL (Proyección 10 años)'"
            }
        }
    }

    //GEOMETRIA
    //------------------->   

    //HOOKS VALOR AUTOMATICO AREA DE CARRILES(LARGO CALZADA) Y AREA VERDADERA CARRILES
    const [LargoCal, setLargoCal] = useState(1);
    const [AnchoCal, setAnchoCal] = useState(1);
    const [NumCarr, setNumCarr] = useState(1);
    const [AnchoCarr, setAnchoCarr] = useState(1);    

    //CAMBIAR EL VALOR AREA DE CARRILES (LARGO)
    const ValDetallesCalzada = (value) =>{   
        if(value){
            //Si ingresa un decimal VAlidar solo 2     
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }                            
                    }                                                            
                }                
            }

            setLargoCal(value) 
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }

        }        
    }

    //VALIDAR NUMERO CON DECIMALES
    const valNumDecimals = (value) =>{    
        if(value){            
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }                            
                    }                                                            
                }                
            }
        } 
    }

    //CAMBIAR EL VALOR AREA DE CARRILES (ANCHO)
    const ValDetallesCalzadaAncho = (value) =>{    
        if(value){
            setAnchoCal(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }
        }
    }    

    //VALOR AUTOMATICO AREA VERDADERA CARRILES(NUMERO CARRILES)    
    const ValNumeroCarriles = (value) =>{    
        if (value){
            setNumCarr(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }
        }        
    }

    //ASIGNAR VALOR AUTOMATICO VERDADERA AREA CARRILES (ANCHO CARRIL)
    const ValAnchoCarril = (value) =>{ 
        if (value){
            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return "El valor ingresado solo puede tener 1 decimal"; }       
                }                
            }
            setAnchoCarr(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }
        }
    }

    //OCULTAR/MOSTRAR ANCHO DEL SEPARADOR
    const [anchosep, setAnchosep] = useState('');

    const ValidationConSeparador = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setAnchosep(valor)  
    }

    //VALIDACION PENDIENTE 0-20
    const valPendiente20 = (value) =>{
        if(value){
            let longitud20 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud20[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return "El valor ingresado debe ser entero" }
            }  
            //VALIDAR MINIMO 0
            if(parseInt(longitud20[0]) < 0){ return "Debe ser al menos 0"; }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud20[0]) > 20){ return "Debe ser 20 o menos"; }            
        }        
    }

    //VALIDACION PENDIENTE 0-5  
    const valPendiente5 = (value) =>{
        if(value){
            let longitud5 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud5[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return "El valor ingresado debe ser entero" }
            }            
            //VALIDAR MINIMO 0
            if(parseInt(longitud5[0]) < 0){ return "Debe ser al menos 0"; }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud5[0]) > 5){ return "Debe ser 5 o menos"; }            
        }
    }

    //ESTRUCTURA
    //-------------------> 

    let espensor1 = 0, valEspensor1;
    let espensor2 = 0, valEspensor2;
    let espensor3 = 0, valEspensor3;
    let espensor4 = 0, valEspensor4;
    let espensor5 = 0, valEspensor5;
    let espensor6 = 0, valEspensor6;
    let espensor7 = 0, valEspensor7;
    const [espensortotal, setEspensortotal] = useState(defecto);

    //VALOR ESPENSOR
    const ValEspensor = (value) => {
        
        if(value){      
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }
            //ESPENSOR 1
            valEspensor1 = document.getElementById('tablaSegmentos[0].estructuraEspesor');
            valEspensor2 = document.getElementById('tablaSegmentos[1].estructuraEspesor');
            valEspensor3 = document.getElementById('tablaSegmentos[2].estructuraEspesor');
            valEspensor4 = document.getElementById('tablaSegmentos[3].estructuraEspesor');
            valEspensor5 = document.getElementById('tablaSegmentos[4].estructuraEspesor');
            valEspensor6 = document.getElementById('tablaSegmentos[5].estructuraEspesor');
            valEspensor7 = document.getElementById('tablaSegmentos[6].estructuraEspesor');

            //ESPENSOR 1
            if(valEspensor1) { if(valEspensor1.value !== ''){ espensor1 = valEspensor1.value } }
            //ESPENSOR 2
            if(valEspensor2) { if(valEspensor2.value !== ''){ espensor2 = valEspensor2.value } }
            //ESPENSOR 2
            if(valEspensor3) { if(valEspensor3.value !== ''){ espensor3 = valEspensor3.value } }
            //ESPENSOR 2
            if(valEspensor4) { if(valEspensor4.value !== ''){ espensor4 = valEspensor4.value } }
            //ESPENSOR 2
            if(valEspensor5) { if(valEspensor5.value !== ''){ espensor5 = valEspensor5.value } }
            //ESPENSOR 2
            if(valEspensor6) { if(valEspensor6.value !== ''){ espensor6 = valEspensor6.value } }
            //ESPENSOR 2
            if(valEspensor7) { if(valEspensor7.value !== ''){ espensor7 = valEspensor7.value } }
            
            setEspensortotal(parseInt(espensor1) + parseInt(espensor2) + parseInt(espensor3) + parseInt(espensor4) + parseInt(espensor5) + parseInt(espensor6) + parseInt(espensor7));                  

        }  else {
            setEspensortotal(0);
        }
    }

    //VALOR MODULO
    const ValModulo = (value) =>{
        if(value){  
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }          
        } 
    }

    //VALOR ESTRUCTURA
    const ValEstructura = (value) =>{
        if(value){
            if(value !== 0){
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if(!pattern.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }            
                }                
            }
        } 
    }

    //VALIDAR NUMERO ENTERO
    const valentero = (value) =>{
        if(value){
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }
        }
    }
    
    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum1Decimal = (value) =>{    
        if(value){
            if(typeof value === 'string'){
                value = parseFloat(value.replace(",", "."));        
            }   
            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return "El valor ingresado solo puede tener 1 decimal"; }       
                }                
            }
        } 
    }

    const valNum2Decimales = (value) => {
        if(value){
            //Si ingresa un decimal VAlidar solo 2     
            if(value !== 0){
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1,2})$");
                    if(!pattern.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }            
                }                
            }            
        }        
    }

    //VALIDAR ESPECIFICACIÓN
    const [material , setMaterial]= useState('');
    const ValidarEspecificacion = (value)  => { 
        let lenghtObject = Object.keys(value);
        let valor = '';
        
        if(value){
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            var filtroGra = valor.split('/materials/');  
            setMaterial(filtroGra[1]);
        }
        
    } 

    const [condicionberma, setCondicionberma] = useState(true);
    const validarCondicionBerma = (value) => {

        if(value){
            if (value === 'Inexistente'){
                setCondicionberma(false);
            }else{
                setCondicionberma(true);
            }
        }

    }
    const [arrayMax, setArrayMax] = useState(false);
    const arrayCount5 = (value) => {
        if(typeof value !== 'undefined'){
            //console.log(value);
            //console.log(value.length > 0);
            if(value.length > 0) {
                //console.log(value.length);
                if("estructuraEspesor" in value[value.length -1]) {
                    if(value[value.length -1].estructuraEspesor > 0) {
                        return "El Espesor en la capa "+value.length+" (la última), debe ser 0";
                    }
                }
                if (value.length > 4) {
                    //console.log("mas de 4");
                    setArrayMax(true);
                } else {
                    //console.log("menos o igual a 4");
                    setArrayMax(false);
                }
            } else {
                return "Mínimo 1 capa"
            }
        } else {
            return "Mínimo 1 capa"
        } 
    }
    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = () =>{
        setDefaultTipoImagen([
            {
                tipoImagen : '/tipo_images/1',
            },
            {
                tipoImagen : '/tipo_images/10',
            },
            {
                tipoImagen : '/tipo_images/11',
            },
            {
                tipoImagen : '/tipo_images/12',
            },
            {
                tipoImagen : '/tipo_images/5',
            },
            {
                tipoImagen : '/tipo_images/6',
            }
        ])
    }

    return(
    <PimsCreate {...props} title="Crear Segmento Adoquín" actions={<SegmentoActions/>}>
        <TabbedForm redirect="list">
            <FormTab label="Detalles" onClick={showBtnImage}>
            <FormDataConsumer >
                    {({formData, dispatch, ...rest}) => (
                        <Fragment>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="Unidad Funcional" 
                                        source="unidadFuncional" 
                                        reference="unidad_funcionals" 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "tramo", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                <ReferenceInput 
                                        label="Tramo" 
                                        source="tramo" 
                                        reference="tramos" 
                                        filter={{unidadFuncional: formData.unidadFuncional}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "segmento", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="Segmento" 
                                        source="segmento" 
                                        reference="segmentos" 
                                        filter={{tramo: formData.tramo}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required(), OpcionTipoImagen]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </Fragment>
                        
                    )}
                </FormDataConsumer>
                <div></div>
                <ArrayInput source="detallesSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                        {                        
                            estadoDetalles: null,
                            elementoMantenimiento: false,
                            estadoRazon: null,
                            dateInicioOperacion: null,
                            proveedor: null,
                            garantiaMeses: null,
                            esalTime: null,
                            nivelTrafico: null,
                            esalTime10: null,
                            esalTime20: null,
                            detallesObservaciones: null
                        }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <SelectInput source="estadoDetalles" label="Estado" choices={[
                            { id: 'Activo', name: 'Activo' },
                            { id: 'Inactivo', name: 'Inactivo' },                    
                        ]} formClassName={classes.first_field} validate={[required()]} onChange={Validationrazon}/>                                
                        { estado ===  "Inactivo" &&            
                            <TextInput source="estadoRazon" label="Razón" formClassName={classes.last_filed} validate={[required()]}/>
                        }
                        <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="elementoMantenimiento" formClassName={classes.last_filed}/>
                        <div ></div>     
                        <DateInput source="dateInicioOperacion" label="Fecha De Inicio Operación" formClassName={classes.first_field} validate={[required(), isFechaInvalida]} />                         
                        <ReferenceInput 
                            label="Proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{sistema: 'CLZ', suministros: 1}} 
                            sort={{ field: 'razonSocial', order: 'ASC' }}
                            formClassName={classes.last_filed}
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                            validate={[required()]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                        </ReferenceInput>
                        <NumberInput source="garantiaMeses" label="Garantía (meses)" validate={[minValue(0), Entero]} formClassName={classes.last_filed}/>
                        <div className={classes.subtittle}><strong>Tráfico</strong></div>                
                        <Divider fullWidth />
                        <NumberInput source="esalTime" label="ESAL (Año)" formClassName={classes.first_field} validate={[required(),minValue(0), Entero]}/>
                        <SelectInput source="nivelTrafico" label="Nivel de tráfico" choices={[
                            { id: 'NT1', name: 'NT1' },
                            { id: 'NT2', name: 'NT2' },
                            { id: 'NT3', name: 'NT3' },
                        ]} formClassName={classes.last_filed} validate={[required()]} />  
                        <div></div>                
                        <NumberInput source="esalTime10" label="ESAL (Proyección 10 Años)" formClassName={classes.first_field} validate={[minValue(0), value10, Entero]}/>
                        <NumberInput source="esalTime20" label="ESAL (Proyección 20 Años)" formClassName={classes.last_filed} validate={[minValue(0), value20, Entero]}/>
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesObservaciones" label="Observaciones" formClassName={classes.observaciones_field} validate={[minLength(0), maxLength(200)]} fullWidth/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Geometría" onClick={HideBtnImage} redirect="list">
                <ArrayInput source="geometriaSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                        {
                            geometriaLargo: null,
                            geometriaAncho: null,
                            geometriaNumCarriles: null,
                            geometriaAnchoCarril: null,
                            geometriaAnchoBerma: null,
                            geometriaTipoBerma: null,
                            geometriaCondicionBerma: null,
                            aux1: 0,
                            aux2: 0,
                            geometriaLongitudinal: null,
                            geometriaLongitudinalMa: null,
                            geometriaLongitudinalMi: null,
                            geometriaTransversal: null,
                            geometriaRadioMi	: null,
                            geometriaRadioMa: null,
                            geometriaRadioCurvatura: null,
                            geometriaVelDiseno: null,
                            geometriaSeparador: null,
                            geometriaSeparadorAncho: null,
                            geometriaCondicionesGeometrica: null,
                            geometriaAlineamiento: null,
                            geometriaTipoTerreno: null,
                            geometriaAdoLargo: null,
                            geometriaAdoAncho: null,
                            geometriaAdoAlto: null,
                        }                    
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <div className={classes.subtittle}><strong>Detalles de Calzada</strong></div>
                        <Divider fullWidth />
                        <NumberInput 
                            id="largom"
                            source="geometriaLargo" 
                            label="Largo (m)" 
                            formClassName={classes.first_field} 
                            validate={[required(), minValue(0), maxValue(1999.99), ValDetallesCalzada]}
                        />
                        <NumberInput 
                            id="anchom"
                            source="geometriaAncho" 
                            label="Ancho De La Calzada (m)" 
                            formClassName={classes.last_filed} 
                            validate={[required(), minValue(3), maxValue(40), ValDetallesCalzadaAncho]}
                        />
                        <div className={classes.subtittle}><strong>Detalles de Carriles</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="geometriaNumCarriles" label="Número de carriles" formClassName={classes.first_field} validate={[required(), minValue(1), maxValue(5), ValNumeroCarriles, Entero]}/>
                        <NumberInput 
                            id="anchoCar"
                            source="geometriaAnchoCarril" 
                            label="Ancho por carril (m)" 
                            formClassName={classes.last_filed} 
                            validate={[required(), minValue(1), maxValue(5), ValAnchoCarril]}
                        />
                        <div></div>
                        <SelectInput source="geometriaTipoBerma" label="Tipo De Berma" choices={[
                            { id: 'Berma Cuneta', name: 'Berma Cuneta' },
                            { id: 'Flexible', name: 'Flexible' },
                            { id: 'Inexistente', name: 'Inexistente' },
                            { id: 'Rígida', name: 'Rígida' },
                        ]} formClassName={classes.first_field} validate={[validarCondicionBerma]}/>
                        {condicionberma  &&(
                            <SelectInput source="geometriaCondicionBerma" label="Condición De Berma" choices={[
                                { id: 'Bueno', name: 'Bueno' },
                                { id: 'Deficiente', name: 'Deficiente' },               
                                { id: 'Regular', name: 'Regular' },
                            ]} formClassName={classes.last_filed} /> 
                        )} 
                        {condicionberma  &&(
                            <NumberInput source="geometriaAnchoBerma" label="Ancho De Berma (m)" formClassName={classes.last_filed} validate={[required(), minValue(0), maxValue(10), valNum1Decimal]}/>

                        )} 
                        <div></div>
                        <DisabledInput 
                            id="AreaCarrilesAC" 
                            source="aux1" 
                            label="Área De Carriles (m²)" 
                            formClassName={classes.first_field} 
                            className="align-number"
                            defaultValue ={0}
                        />                
                        <NumberInput 
                            formClassName={classes.last_filed} 
                            source="areaCarrilesVerdadera" 
                            label="Área Verdadera Carriles (m²)" 
                            id="AreaVerdaderaAC" 
                            className="align-number"
                            validate={[required()]}
                        />
                        <div className={classes.subtittle}><strong>Propiedades geométricas</strong></div>
                        <Divider fullWidth />
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinal" label="Pendiente Longitudinal" formClassName={classes.first_field} validate={[valPendiente20]}/>
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinalMa" label="Pendiente Longitudinal Máxima" formClassName={classes.last_filed} validate={[valPendiente20]}/>
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinalMi" label="Pendiente Longitudinal Mínima" formClassName={classes.last_filed} validate={[valPendiente20]}/>
                        {/* -----------RADIO--------- */}
                        <PercentageInput className="Longitudes_segmentos" source="geometriaTransversal" label="Pendiente Transversal" formClassName={classes.first_field} validate={[valPendiente5]}/>
                        <NumberInput source="geometriaRadioMi" label="Radio Mínimo (m)" formClassName={classes.last_filed} validate={[minValue(0), maxValue(5), Entero]}/>
                        <NumberInput source="geometriaRadioMa" label="Radio Máximo (m)" formClassName={classes.last_filed} validate={[minValue(0), maxValue(5000), Entero]}/>
                        {/* -------------------- */}
                        <NumberInput source="geometriaRadioCurvatura" label="Radio De Curvatura (°/km)" formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(5000), valNum1Decimal]} />
                        <NumberInput source="geometriaVelDiseno" label="Velocidad De Diseño (km/h)" formClassName={classes.last_filed} validate={[required(), minValue(10), maxValue(150), valentero]} />
                        <div></div>
                        <SelectInput source="geometriaSeparador" label="Con Separador" choices={[
                            { id: 'Si', name: 'Si' },
                            { id: 'No', name: 'No' },                    
                        ]} formClassName={classes.first_field} onChange={ValidationConSeparador} validate={[required()]}/>
                        { anchosep ===  "Si" &&   
                            <NumberInput source="geometriaSeparadorAncho" label="Ancho Separador (m)" formClassName={classes.last_filed} validate={[required(), minValue(0), maxValue(50)]}/>
                        }                               
                        <div></div>
                        <SelectInput source="geometriaCondicionesGeometrica" label="Condiciones Geométricas" choices={[
                            { id: 'Bueno', name: 'Bueno' },
                            { id: 'Deficiente', name: 'Deficiente' },
                            { id: 'Regular', name: 'Regular' },
                        ]} formClassName={classes.first_field} />
                        <SelectInput source="geometriaAlineamiento" label="Alineamiento" choices={[
                            { id: 'Curvo', name: 'Curvo' },
                            { id: 'Predominantemente Curvo', name: 'Predominantemente Curvo' },
                            { id: 'Predominantemente Recto', name: 'Predominantemente Recto' },
                            { id: 'Recto', name: 'Recto' },
                        ]} formClassName={classes.last_filed} />
                        <SelectInput source="geometriaTipoTerreno" label="Tipo de Terreno" choices={[
                            { id: 'Escarpado', name: 'Escarpado' },
                            { id: 'Montañoso', name: 'Montañoso' },
                            { id: 'Ondulado', name: 'Ondulado' },
                            { id: 'Plano', name: 'Plano' },
                        ]} formClassName={classes.last_filed} />
                        <div className={classes.subtittle}><strong>Dimensiones del Adoquín típico</strong></div>
                        <NumberInput source="geometriaAdoLargo" label="Largo (m)" validate={[required(), minValue(0), maxValue(10), valNum2Decimales]} formClassName={classes.first_field}/> 
                        <NumberInput source="geometriaAdoAncho" label="Ancho (m)" validate={[required(), minValue(0), maxValue(10), valNum2Decimales]} formClassName={classes.last_filed}/> 
                        <NumberInput source="geometriaAdoAlto" label="Alto (m)" validate={[required(), valNum2Decimales]} formClassName={classes.last_filed}/> 
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesGeometria" label="Observaciones" validate={[minLength(0), maxLength(200)]}/>   
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Estructura" onClick={HideBtnImage}>
                <div className={classes.subtittle}><strong>Información Estructura</strong></div>
                <Divider fullWidth />
                <ArrayInput 
                    source="tablaSegmentos" 
                    style={{width: '100%'}} 
                    label=" " 
                    validate={required(), arrayCount5}
                >
                    <SimpleFormIterator disableAdd={arrayMax}>
                        <NumberInput 
                            source="estructuraEspesor" 
                            label="Espesor (mm)" 
                            validate={[ValEspensor, required(), minValue(0), maxValue(20000), Entero]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        />                          
                        <NumberInput 
                            source="estructuraModulo" 
                            label="Módulo (MPa)" 
                            validate={[required(), minValue(0), maxValue(100000), ValModulo, Entero]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        />   
                        <NumberInput 
                            source="estructuraDs" 
                            label="Desviación Estándar (Std)" 
                            validate={[required(), minValue(0.01), maxValue(4), valNumDecimals]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}} 
                            className="Longitudes_segmentos"
                        />                       
                        <ReferenceInput 
                            label="Material" 
                            source="material"  
                            reference="materials" 
                            filterToQuery={searchText => ({ material: searchText })} 
                            sort={{field : 'material', order : 'asc'}}
                            onChange={ValidarEspecificacion}
                            validate={[required()]} 
                            fullWidth 
                            formClassName={classes.tabla} 
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="material"  />
                        </ReferenceInput>  
                                             
                        <ReferenceInput 
                            label="Especificación" 
                            source="material" 
                            reference="materials"
                            formClassName={classes.tabla} style={{width: '100%'}}
                        >
                            <SelectInput  optionText="especificacion" disabled fullWidth />
                        </ReferenceInput>                               
                          
                        <ReferenceInput 
                            label="Granulometría" 
                            source="granulometria" 
                            reference="granulometrias" 
                            filterToQuery={searchText => ({ granulometria: searchText })} 
                            filter={{material: material}} 
                            sort={{field : 'granulometria', order : 'asc'}}
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="granulometria" fullWidth  />
                        </ReferenceInput>   
                    </SimpleFormIterator>
                </ArrayInput>
                <div></div><br/>                                               
                <DisabledInput 
                    label="Espesor Total (mm)" 
                    formClassName={classes.first_field} 
                    defaultValue ={espensortotal}
                    className="align-number"
                />                
                <ArrayInput source="EstructuraSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                    {
                        estructuraNumDiseno: null,
                        estructuraFecha: null,
                        estructuraNumero: null,
                        estructuraNumeroFecha: null,
                        estructuraCbr: null,
                        estructuraCbrFecha: null,
                        estructuraFechaConst: null,
                        estructuraFechaRehab: null,
                        detallesEstructura: null
                    }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <div className={classes.subtittle}><strong>Capacidad estructural</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="estructuraNumDiseno" label="Número Estructural de Diseño" formClassName={classes.first_field} validate={[required(), minValue(0.1), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraFecha" label="Fecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraNumero" label="Número Estructural Efectivo" formClassName={classes.first_field} validate={[required(), minValue(0.1), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraNumeroFecha" label="Fecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <PercentageInput source="estructuraCbr" label="CBR (%)" className="align-number" formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(100), valNum1Decimal]}/>
                        <DateInput source="estructuraCbrFecha" label="Fecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div className={classes.subtittle}><strong>Fechas importantes</strong></div>
                        <Divider fullWidth />
                        <DateInput source="estructuraFechaConst" label="Fecha de Construcción" formClassName={classes.first_field} validate={[required(),isFechaInvalida]} /> 
                        <DateInput source="estructuraFechaRehab" label="Fecha de Última Rehabilitación" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <Divider fullWidth formClassName={classes.separador} />
                        <TextInput source="detallesEstructura" label="Observaciones" validate={[minLength(0), maxLength(200)]} formClassName={classes.observaciones_field} fullWidth/>   
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="FOTOGRAFÍAS">
                <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
            </FormTab>
        </TabbedForm>
    </PimsCreate>
    )
});
import React , {useState} from 'react';
import Button from '@material-ui/core/Button';
import {Filter,List,SimpleForm,  Datagrid, ReferenceInput, Toolbar, CardActions, SelectInput, TextField, NumberField, EditButton, ShowButton } from 'react-admin';
import {  BulkDeleteWithConfirmButton, required } from 'react-admin';
import { Fragment } from 'react';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import  {styles}  from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import config from '../../config/config';
let urlAppjs = config.UrlServer;


const años = [{ id: '2000', name: '2000' },
    { id: '2001', name: '2001' },
    { id: '2002', name: '2002' },
    { id: '2003', name: '2003' },
    { id: '2004', name: '2004' },
    { id: '2005', name: '2005' },
    { id: '2006', name: '2006' },
    { id: '2007', name: '2007' },
    { id: '2008', name: '2008' },
    { id: '2009', name: '2009' },
    { id: '2010', name: '2010' },
    { id: '2011', name: '2011' },
    { id: '2012', name: '2012' },
    { id: '2013', name: '2013' },
    { id: '2014', name: '2014' },
    { id: '2015', name: '2015' },
    { id: '2016', name: '2016' },
    { id: '2017', name: '2017' },
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
    { id: '2024', name: '2024' },
    { id: '2025', name: '2025' },
    { id: '2026', name: '2026' },
    { id: '2027', name: '2027' },
    { id: '2028', name: '2028' },
    { id: '2029', name: '2029' },
    { id: '2030', name: '2030' }];


function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

    const [unidadSave, setUnidadSave] = useState('');
    const saveUnidad =(value) =>{  
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; } 
        setUnidadSave(valor)
    }

    const [analisis, setAnalisis] = useState('');
    const saveParAnalisis =(value) =>{  
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; } 
        setAnalisis(valor)
    }

    const handleListItemClick2 = props => {
        if (unidadSave == "") {
            alert("Debe seleccionar la Unidad Funcional");
            return;
        }
        if (analisis == "") {
            alert("Debe seleccionar el parámetro de analisis");
            return;
        }
        const data = new FormData();
        data.append('unidadSave', unidadSave);
        data.append('analisis', analisis);

        fetch(urlAppjs +'/generarPERS/', {
            method: 'POST',
            body: data
        })
        .then(res=> res.json())
        .then(datos => {
            if (datos == "Sin datos") {
                alert("No se encontraron datos.");
            } else {
                const URL = urlAppjs +"/Pers/"+datos
                window.location.href = URL;
                onClose(false);
                setTimeout(function(){ window.location.reload(); }, 3000);
            }
        });
      };

    const CustomToolbar = ({ basePath, data,...props}) => (
        <Toolbar {...props} >
            <Button variant="secondary" id="generarInforme" onClick={handleListItemClick2} style={{backgroundColor: '#3f51b5', color:'#fff'}}>
                <AddIcon/> Generar Informe
            </Button>
        </Toolbar>
    );  

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'}>
      <DialogTitle id="simple-dialog-title">Crear Informe PERS</DialogTitle>
      <SimpleForm toolbar={<CustomToolbar />}>
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <ReferenceInput source="unidadSave" label="Código Unidad Funcional" reference="unidad_funcionals" validate={[required()]} alwaysOn onChange={saveUnidad}>
                        <SelectInput optionText="codigo" fullWidth /> 
                    </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                    <ReferenceInput source="parAnalisis" label="Parámetros de analisis" reference="parametros_analises" validate={[required()]} alwaysOn onChange={saveParAnalisis}>
                        <SelectInput optionText="plan" fullWidth /> 
                    </ReferenceInput>
                </Grid>
                {/* <Grid item xs={6}>
                    <SelectInput label="Año" source="yearSave" choices={años}alwaysOn fullWidth validate={[required()]} onChange={saveYear}/>
                </Grid> */}
            </Grid>            
      </SimpleForm>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};



const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const FiltroSeguridad= (props) => (
    <Filter {...props}>
       <ReferenceInput label="Ruta" reference="unidad_funcionals" source="unidad_funcional" alwaysOn>
             <SelectInput optionText="codigo" fullWidth/> 
        </ReferenceInput>
        <SelectInput label="Año" source="year" choices={años}alwaysOn fullWidth/> 
    </Filter>
);

const PostEditActions = ({ basePath, data, resource }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return(
        <CardActions>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <AddIcon/> Crear Informe
            </Button>
            <SimpleDialog open={open} onClose={handleClose} />
        </CardActions>
    );
    
};
export const ExportPERSList = props => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    const UrlField = ({ record, source }) => 
        <a href={urlAppjs+"/"+record[source]} target="_blank" style={{textDecoration:"none"}}>
            <Button variant="outlined" color="primary">Descargar</Button>
        </a>;

    return (
        <List title="Lista Pers"{...props}  actions={<PostEditActions />} filters={<FiltroSeguridad/>} bulkActionButtons={<PostBulkActionButtons />}>
            <Datagrid>
                <TextField label="Unidad Funcional" source="unidadFuncional" sortable={false} />
                <NumberField label="Año"  source="year" sortable={false}/>
                <TextField label="Versión" source="version" />
                <UrlField label="Documento" source="document"/>
            </Datagrid>
        </List>
    );
};

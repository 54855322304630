import React, { Fragment, useState } from 'react';
import { NumberField, List, Datagrid, BooleanField, TextField, EditButton, ShowButton, 
    AutocompleteInput, Filter, ReferenceInput, SelectInput, WithPermissions, BulkDeleteWithConfirmButton, CardActions, 
    CreateButton, ExportButton, ReferenceArrayField, SingleFieldList, ChipField 
} from 'react-admin';
import LinkToRuta from './LinkToRuta';




const ProveedorFilter = (props) => {

    const [departamento, setDepartamento] = useState('-');

    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(valor)
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                label="Departamento"
                source="departamento"
                reference="departamentos"
                onChange={ValidarDepartamento}
                alwaysOn
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: "nombre", order: "ASC" }}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>

            <ReferenceInput
                label="Municipios"
                source="municipio"
                reference="municipios"
                alwaysOn
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: "nombre", order: "ASC" }}
                filter={{ departamento: departamento }}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>
            <ReferenceInput label="Sistema" source="sistema" reference="sistemas" sort={{ field: 'nombre', order: 'ASC' }} alwaysOn sortable={false}>
                <SelectInput optionText="nombre" />
            </ReferenceInput>

            
        </Filter>

    )
};


const PostBulkActionButtons = props => {

    var emergencia = localStorage.getItem("contemergencia");

    return (

        <WithPermissions
            render={({ permissions }) => (
                "1" == emergencia
                    ?
                    <Fragment>
                        <BulkDeleteWithConfirmButton {...props} />
                    </Fragment>
                    : null
            )}
        />

    )
};

const PostActions = ({ basePath,
    currentSort,
    exporter,
    filterValues,
    resource,
    total }) => {
    var proveedores = localStorage.getItem("proveedores");
    return (
        <WithPermissions
            render={({ permissions }) => (
                "1" == proveedores || "2" == proveedores
                    ?
                    <CardActions>
                        <CreateButton basePath={basePath} />
                        <ExportButton
                            disabled={total === 0}
                            resource={resource}
                            sort={currentSort}
                            filter={filterValues}
                            exporter={exporter}
                        />
                        <LinkToRuta />
                    </CardActions>


                    : <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filter={filterValues}
                        exporter={exporter}
                    />
            )}
        />


    )
};


export const ProveedorList = props => (
    <List {...props} filters={<ProveedorFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} actions={<PostActions />} title="Lista de Proveedores" bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
            <ReferenceArrayField label="Municipio" reference="municipios" source="municipio" sortable={false}>
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
            </ReferenceArrayField>>
            {/* <ReferenceField source="sistema" reference="sistemas" linkType={false}>
                <TextField source="nombre" />
            </ReferenceField>          */}
            <TextField label="Sistema" source="sistemaNombre" sortBy="nombreSistema" />
            <TextField label="Empresa" source="razonSocial" />
            <NumberField label="Número de Identificación" source="numeroIdentificacion" sortable={false} />
            <BooleanField source="mantenimiento" sortable={false} />
            <BooleanField source="suministros" sortable={false} />



            <ShowButton />
            {
                localStorage.getItem("proveedores") == "1" || localStorage.getItem("proveedores") == "2" ? <EditButton /> : null
            }

        </Datagrid>
    </List>
);
import React, { Fragment, useState } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, SelectInput, 
    AutocompleteInput, BulkDeleteWithConfirmButton  
} from 'react-admin';
import { PField } from './../../../fields/PField';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

const FiltroAlrededor = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
       <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Segmento" 
            source="segmento" 
            reference="segmentos" 
            alwaysOn 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        {/* <SelectInput 
            fullWidth 
            label="Estado" 
            source="estados"   
            sort={{field : 'name' , order :'ASC'}} 
            alwaysOn  
            choices={[
                {id : 'Existente' , name : 'Existente'},
                {id : 'Nuevo' , name : 'Nuevo'},
                {id : 'Reemplazo' , name : 'Reemplazo'},
            ]}
            resettable
        /> */}
        <SelectInput  label="Tipo de Elemento" source="tipo" allowEmpty emptyValue=""
            choices={[
                {id : "Anclajes en roca", name: "Anclajes en roca"},
                {id : "Cercados y redes de contención", name: "Cercados y redes de contención"},
                {id : "Escudo contra caída de rocas", name: "Escudo contra caída de rocas"},
                {id : "Muro anclado", name: "Muro anclado"},
                {id : "Muro de concreto reforzado", name: "Muro de concreto reforzado"},
                {id : "Muro de encofrado", name: "Muro de encofrado"},
                {id : "Muro de gaviones", name: "Muro de gaviones"},
                {id : "Muro de gravedad en concreto", name: "Muro de gravedad en concreto"},
                {id : "Muro de tierra reforzada", name: "Muro de tierra reforzada"},
                {id : "Muros de contención", name: "Muros de contención"},
                {id : "Otros", name: "Otros"},
                {id : "Pantallas ancladas", name: "Pantallas ancladas"},
                {id : "Pilotes y caissons", name: "Pilotes y caissons"},
                {id : "Reconformación", name: "Reconformación"},
                {id : "Revestimiento flexible", name: "Revestimiento flexible"},
                {id : "Revestimiento vegetal", name: "Revestimiento vegetal"},
                {id : "Suelo empernado", name: "Suelo empernado"},
            ]}
        alwaysOn/>
         <SelectInput label="Estado" source="estados" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]}  fullWidth alwaysOn
            />
            
    </Filter>
    )
};

export const ElementoContencionList = props => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} title="Lista Elementos Contención" filters={<FiltroAlrededor/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
            <TextField label="Código" source="fullCodigo" sortable={false}/>
            <TextField source="tipo" sortable/>          
            <PField label="Abscisa inicial" source="abInicio"/>
            <TextField source="margen" sortable/>
            <TextField label="Estado" source="estados" sortable/>
            <ShowButton />
            <EditButton />                           
        </Datagrid>
    </List>
);

import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField,SelectField,NumberField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


export const ReparacionPersList = props => (
    <List {...props} title="Reparaciones PERS"  sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid >
            <SelectField label="Tipo de reparación" source='tipoReparacion' choices={[
                {id:'PFLEX', name:'Flexible'},
                { id:'PRIGD', name:'Rígido'}
            ]}/>
            <TextField label="Nombre" source="nombre" />
            <TextField label="Unidad" source="unidad" />        
            <NumberField label="Costo" source="costo" />  
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
import React, {useState} from 'react';
import { TextInput, ReferenceInput, SelectInput, FormDataConsumer, Query, AutocompleteInput } from 'react-admin';
import { PimsCreate } from './../Create';
import { PInput } from './../../inputs/PInput';
import { SimpleForm } from './../SimpleForm';
import { PRInput } from './../../inputs/PRInput';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import  {abValidationInicio, prValidationInicio,abValidationFin, prValidationFin,validarLogicaPrFinCreate, ValorMaximoPrCreate, validarLogicaAbsFinCreate, ValorMaximoAbsCreate}  from './../validacionesInputs';

import {
    required,
    minLength,
    maxLength,
} from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const styles = {
    ocultar: {
        display:"none",
    },

};
export const TramoCreate = withStyles(styles)(({ classes, ...props }) =>{
    const [unidad , setRutas]= useState('');

    const ValidarUnidadFuncional = (value)  => {      
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                  
        setRutas(valor) 

    }
    const [prValidador, setPrValidador] = useState('');
    const [AbValidador, setAbValidador] = useState('');

/*VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/
    const NoSobreponerAbs = () =>{
        
        // unidad  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdnidadFuncional = unidad.substr(19);        
        let abInicioA = document.getElementById('ingresoAbInicio');
        let abFinA = document.getElementById('ingresoAbFin'); 
        if(abInicioA && abFinA)
        {
            abInicioA = abInicioA.value
            abFinA = abFinA.value
            abInicioA = abInicioA.substr(1, abInicioA.lastIndexOf('+')-1)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
            abFinA = abFinA.substr(1, abFinA.lastIndexOf('+')-1)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
            fetch(urlAppjs+'/validar/rango/Tramos/abs/Crear/'+IdnidadFuncional+'/'+abInicioA +'/'+ abFinA+'/0')
            .then(function(response) {
                
                return response.text();
            })
            .then(function(myJson) {  
                setAbValidador(myJson)   
                // setContador(1)
                if(AbValidador === '1'){
            
                    return 'Rango no válido'
                }                
            })
        }        
        if(AbValidador === '1'){
        
            return 'Rango no válido'
        }
    }

    const MansajeValidadorAb = () =>{
        if(AbValidador === '1'){
        
            return 'Rango no válido'
        }
    }

    const NoSobreponerPr = () =>{
        // unidad  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdRuta = unidad.substr(19);      
        let abInicioA = document.getElementById('ingresoPrInicio');
        let abFinA = document.getElementById('ingresoPrFin'); 

        abInicioA = abInicioA.value
        abFinA = abFinA.value
        abInicioA = abInicioA.substr(2, abInicioA.lastIndexOf('+')-2)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
        abFinA = abFinA.substr(2, abFinA.lastIndexOf('+')-2)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
        fetch(urlAppjs+'/validar/rango/Tramo/pr/Crear/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/0')
        .then(function(response) {
            
            return response.text();
        })
        .then(function(myJson) {  
            setPrValidador(myJson)   
            // setContador(1)
            if(AbValidador === '1'){
        
                return 'Rango no válido'
            }                
        })
        if(prValidador === '1'){
        
            return 'Rango no válido'
        }
    }

    const MansajeValidadorPb = () =>{
        if(prValidador === '1'){
        
            return 'Rango de Pr no válido'
        }
    }
/* FIN DE VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/

        return(
        <PimsCreate  title="Crear Tramo" {...props}>
            <SimpleForm   submitOnEnter={false} redirect="list">            
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        
                        <ReferenceInput 
                            label="Unidad Funcional" 
                            source="unidadFuncional"  
                            reference="unidad_funcionals" 
                            filterToQuery={searchText => ({ codigo: searchText })} 
                            sort={{field : 'codigo', order : 'asc'}}
                            onChange={ValidarUnidadFuncional}
                            validate={[required()]}
                            fullWidth 
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                        </ReferenceInput>
                    </Grid>
                    <TextInput label="Código" source="CODIGAAS"  id="codigo" style={{display : 'none'}} defaultValue={AbValidador}  fullWidth disabled />
                    <TextInput label="Código" source="PrValidador"  id="codigo" style={{display : 'none'}} defaultValue={prValidador} fullWidth disabled />
                    <Grid item xs={6}>
                        <TextInput label="Código Tramo" source="codigo" id="codigo" fullWidth validate={[required(), minLength(2), maxLength(4)]}/>
                    </Grid>     
                     <FormDataConsumer>
                        {({ formData, ...rest }) =>(unidad &&
                        <Grid item xs={6}>
                            <PRInput label="Progresiva inicial" id="ingresoAbInicio" source="abInicio" onChange={NoSobreponerAbs} fullWidth validate={[abValidationInicio,required(),ValorMaximoAbsCreate,MansajeValidadorAb]}/>
                        </Grid>
                            )
                        }
                    </FormDataConsumer>  
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (unidad &&
                        <Grid item xs={6}>
                            <PRInput label="Progresiva final" id="ingresoAbFin" source="abFin" onChange={NoSobreponerAbs} fullWidth validate={[abValidationFin,required(),ValorMaximoAbsCreate,validarLogicaAbsFinCreate,MansajeValidadorAb]}/>
                        </Grid> 
                            )
                        }
                    </FormDataConsumer>                
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>(unidad &&
                                <Grid item xs={6}>
                                    <PInput label="PR inicio" source="pInicio"  id="ingresoPrInicio" fullWidth onChange={NoSobreponerPr} validate={[prValidationInicio,required(),ValorMaximoPrCreate,MansajeValidadorPb]}/>
                                </Grid>
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>(unidad &&
                                <Grid item xs={6}>
                                    <PInput  label="PR fin" source="pFin"   id="ingresoPrFin" fullWidth onChange={NoSobreponerPr} validate={[prValidationFin,required(),ValorMaximoPrCreate,validarLogicaPrFinCreate,MansajeValidadorPb]}/>
                                </Grid>
                            )
                        }
                    </FormDataConsumer>                                           
                    {                                    
                        <Query type="GET_ONE" resource="unidad_funcionals" payload={{ id: unidad}} >
                            {({ data, loading, error }) => {                                                
                                if( data){
                                    return (
                                        <Grid item xs={3}>
                                            <TextInput  id="IdprInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.PInicio} source="pInicios" fullWidth />
                                            <TextInput id="IdprFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.PFin} source="pFins" fullWidth />
                                            <TextInput  id="IdAbInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.abInicios} source="pAbI" fullWidth />
                                            <TextInput id="IdAbFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.abFins} source="pAbF" fullWidth />
                                        </Grid>
                                    )
                                }
                                return <div></div>;                                                
                            }}
                        </Query>                            
                    }      
                    <Grid item xs={12}>                    
                        <b>Información Tramo</b>                    
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />                    
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="inicio"  fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="final"  fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                    </Grid>                      
                </Grid>
            </SimpleForm>
        </PimsCreate>
        )
    }
);
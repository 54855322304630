import React, {useState, Fragment} from 'react';
import {  TabbedShowLayout, TabbedForm ,Toolbar, Tab, Datagrid, ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, FormTab, ArrayField,ChipField,SingleFieldList,NumberField,ListButton,EditButton, SimpleForm} from 'react-admin';
import { Show } from './../../Show';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import  {styles}  from './../../../EstilosGrid';
import { PRField } from './../../../fields/PRField';
import VistaDatosShow from './visualizacion';
import EvaluacionPorSegmento from './EvaluacionPorSegmento'
import { render } from 'react-dom';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar>
));

export const CargarInformacionShow = withStyles(styles)(({ classes, ...props }) => {
    
    return(
<ShowController {...props} title="Disoinibilidad de Vía">
{controllerProps =>
        <Show title="Mostrar Evaluación"  {...props}>
            <SimpleForm submitOnEnter={false} toolbar={<CustomToolbar/>}>   
            <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Selección del Indicador</Typography>
            <ReferenceField label="Indicador" source="indicador" reference="parametros"  fullWidth formClassName={classes.grid_cont12} linkType={false}>
                <TextField source="codigoCompleto" />
            </ReferenceField>
            <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Detalles</Typography>
            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" formClassName={classes.grid_cont3_f} fullWidth linkType={false}>
                <TextField  options={{ fullWidth: true }} source="fullCodigos" fullWidth/>
            </ReferenceField>
            
            <ReferenceField label="Tramo" source="tramo" reference="tramos" fullWidth formClassName={classes.grid_cont3_s} linkType={false}>
                <TextField options={{ fullWidth: true }} source="fullCodigos" />
            </ReferenceField>   

            <PRField label="Abscisa inicial" id="ingresoAbInicio" source="abiniFloat" fullWidth  formClassName={classes.grid_cont3_s}/>
            <PRField label="Abscisa Final" id="ingresoAbFin" source="abfinFloat" fullWidth  formClassName={classes.grid_cont3_s}/>
            <DateField label="Fecha de Medición" id="datefield" source="fechaMedicion" formClassName={classes.grid_cont3_f} fullWidth/>
            <TextField label="Medido Por" source="medidoPor" formClassName={classes.grid_cont3_s} fullWidth />
            <TextField label="Estado" source="estado" formClassName={classes.grid_cont3_s} fullWidth />
            <TextField label="Tipo de medición" source="tipoMedicion" formClassName={classes.grid_cont3_s} />
            {controllerProps.record && controllerProps.record.tipoMedicion==="De Corrección" &&
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Rango de Corrección</Typography>
            }
            {controllerProps.record && controllerProps.record.tipoMedicion==="De Corrección" &&
                <PRField label="Abscisa inicial"  source="abiniCorreccionFloat" fullWidth   formClassName={classes.grid_cont3_f} />
            }
            {controllerProps.record && controllerProps.record.tipoMedicion==="De Corrección" &&
                <PRField label="Abscisa Final"  source="abfinCorreccionFloat" fullWidth  formClassName={classes.grid_cont3_s}/>
            }
            <Divider fullWidth/>
            <TabbedForm save={false} submitOnEnter={false} saving={false} className="Evaluaciones-tabs">
            <FormTab label="Indicador Por Segmento">
            <WithProps>{({record,...props})=>
               <EvaluacionPorSegmento record={record}/>}
            </WithProps>
            </FormTab>
            <FormTab label="Resumen Registros">
            <WithProps>{({record,...props})=>
               <VistaDatosShow record={record}/>}
            </WithProps>
            </FormTab>
            </TabbedForm>
            </SimpleForm>
        </Show>
}
</ShowController>
)
})
const WithProps = ({children,...props}) => children(props);
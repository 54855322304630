import React, {useState} from 'react';
import { FormTab,NumberInput, TextInput,required,  ReferenceInput, SelectInput ,  Query, AutocompleteInput
} from 'react-admin';
import { Edit } from './../Edit';
import { TabbedForm } from './../TabbedForm';
import Grid from '@material-ui/core/Grid';
import config from '../../config/config';
let urlAppjs = config.UrlServer;


const contrasena =(value,allvalues)=>{

    let clave1 = document.getElementById("clave1").value;
    let clave2 = document.getElementById("clave2").value;

    if(clave2 !== null && clave2 !== undefined  && clave2 !== '')
    {
        if (clave1 != clave2) 
        {
           return "La contraseña no coinciden";
           console.log('contraseña no coinciden');
        }else{
            console.log('contraseña iguales');
        }
    }
}



const validarEmail=(elemento)=>{

    var texto = document.getElementById("emails").value;
    var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    
    if (!regex.test(texto)) {
       return ("Correo Invalido");
    } else {
        console.log("Correo valido") ;
    }
  
  }


export const UsuarioEdit = props => {
    const[rol, setRol] = useState('');
    const ValidarAdminRol = (value) =>{
        setRol(value)
    }
return(
<Edit title="Editar Usuario" {...props}  undoable={false}>
    <TabbedForm submitOnEnter={false} redirect="list" >
        <FormTab label="Identificación">
        {
                localStorage.getItem("asigclaves") == "1" ?
            <Grid container spacing={24}>
                <Grid item xs={6}>
                    <TextInput label="Nombres" source="nombres" fullWidth validate={[required()]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Apellidos" source="apellidos" fullWidth validate={[required()]}/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput label="Tipo Documento" source="tipo_documento" choices={[
                        {id:'Cédula Ciudadanía',name:'Cédula Ciudadanía'},
                        {id:'Cédula Extranjería',name:'Cédula Extranjería'},
                        {id:'NIT',name:'NIT'},
                    ]} fullWidth validate={[required()]}/>
                </Grid>
                <Grid item xs={6}>
                    <NumberInput label="Identificación" source="identificacion" fullWidth validate={[required()]}/>
                </Grid>
                <Grid item xs={8}>
                    <TextInput label="Correo electrónico" source="username" id="emails" fullWidth validate={[validarEmail]} validate={[required()]}/>
                </Grid>
                <Grid item xs={4}>
                    <ReferenceInput 
                            label="Rol" 
                            source="admin"  
                            reference="atributos" 
                            filterToQuery={searchText => ({ nombre: searchText })} 
                            sort={{field : 'nombre', order : 'asc'}}
                            alwaysOn
                            fullWidth 
                            validate={[required(), ValidarAdminRol]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  id='adminRol'/>
                        </ReferenceInput>  
                </Grid>
                {                                    
                    <Query type="GET_ONE" resource="atributos" payload={{ id: rol}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput  style={{display:'none'}} label="NombreRol" defaultValue={data.nombre} source="adminRol" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                }                                 
                
                <Grid item xs={6}>
                    <TextInput type='password' label="Contraseña" source="prueba" id="clave1" fullWidth />
                </Grid>
                <Grid item xs={6}>{}
                    <TextInput  type='password'  label="Repita Contraseña" source="plainPassword" id="clave2" fullWidth validate={[contrasena]}/>   
                </Grid>
            </Grid>
        
              : <Grid container spacing={24}>
              <Grid item xs={6}>
                  <TextInput label="Nombre" source="nombres" fullWidth/>
              </Grid>
              <Grid item xs={6}>
                  <TextInput label="Apellido" source="apellidos" fullWidth/>
              </Grid>
              <Grid item xs={6}>
                  <SelectInput label="Tipo Documento" source="tipo_documento" choices={[
                      {id:'Cédula Ciudadanía',name:'Cédula Ciudadanía'},
                      {id:'Cédula Extranjería',name:'Cédula Extranjería'},
                      {id:'NIT',name:'NIT'},
                  ]} fullWidth/>
              </Grid>
              <Grid item xs={6}>
                  <NumberInput label="Identificación" source="identificacion" fullWidth/>
              </Grid>
              <Grid item xs={8}>
                  <TextInput label="Correo electrónico" source="username" fullWidth/>
              </Grid>
              <Grid item xs={4}>
                    <ReferenceInput 
                            label="Rol" 
                            source="admin"  
                            reference="atributos" 
                            filterToQuery={searchText => ({ nombre: searchText })} 
                            sort={{field : 'nombre', order : 'asc'}}
                            alwaysOn
                            fullWidth 
                            validate={[required(), ValidarAdminRol]}
                        >
                            <AutocompleteInput  fullWidth optionText="nombre"  id='adminRol'/>
                        </ReferenceInput>
              </Grid>
                {                                    
                    <Query type="GET_ONE" resource="atributos" payload={{ id: rol}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput  style={{display:'none'}} label="NombreRol" defaultValue={data.nombre} source="adminRol" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
          </Grid>
         }
        </FormTab>
    </TabbedForm>
       
    </Edit>
)};
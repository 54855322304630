import React from 'react';
import { TextInput,minValue, DateInput,SelectInput,ReferenceInput, NumberInput,BooleanInput ,FormTab,TabbedForm} from 'react-admin';
import { PimsCreate } from './../Create';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@material-ui/core/Grid';
import {
    required,
    minLength,
    maxLength,
    
    maxValue
} from 'react-admin';


const validarEnteroNT =(value) =>{
    if (!/^([0-9]+)*$/.test(value))
    {
      return "Debe ser un número entero";
    }
  }
  const choices = [
    { id: 'Costado derecho', name: 'Costado derecho' },
    { id: 'Costado izquierdo', name: 'Costado izquierdo' },
    
];
const choices1 = [
    { id: 'Aletas', name: 'Aletas' },
    { id: 'Apoyos', name: 'Apoyos' },
    { id: 'Estribos', name: 'Estribos' },
    { id: 'Pilas', name: 'Pilas' },
    
];
const choices2 = [
    { id: 'Accesos peatonales', name: 'Accesos peatonales' },
    { id: 'Barandas', name: 'Barandas' },
    { id: 'Estribos', name: 'Estribos' },
    { id: 'Cordón Superior', name: 'Cordón Superior' },
    { id: 'Pasamanos', name: 'Pasamanos' },
    { id: 'Tablero', name: 'Tablero' },
    { id: 'Vigas', name: 'Vigas' },
    
];

export const CalificacionCreate = props => (
    <PimsCreate  {...props}>
        
        <TabbedForm submitOnEnter={false} redirect="list">
      <FormTab label="Detalles">
            <Grid container spacing={24}>   
            <Grid item xs={12}>
                  <h3 style={{marginBottom: 0}}>Detalles de inspección</h3>                    
                  <hr></hr>
              </Grid>
              <Grid item xs={4}>
              <ReferenceInput label="Puente" source="puentes" reference="puentes" validate={required()} fullWidth>                
                   <SelectInput optionText="fullCodea" />                
                 </ReferenceInput>  
              </Grid>             
              <Grid item xs={4}>
            <DateInput label="Fecha de inicio de inspección" source="fecha1" fullWidth/>
              </Grid>
              <Grid item xs={4}>
              <NumberInput label="Consecutivo de inspección" source="consecutivo" fullWidth  validate={[required(),minValue(0),maxValue(99999999),validarEnteroNT]}/>
              </Grid>
              <Grid item xs={4}>
                <TextInput label="Inspector" source="inspector" fullWidth validate={[required(),minLength(0),maxLength(40)]}/>
              </Grid>
              <Grid item xs={6}>
            <DateInput label="Fecha de reporte de inspección" source="fecha2" fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextInput label="Nombre de reporte de inspección" source="nombre_inspector" fullWidth validate={[required(),minLength(0),maxLength(80)]}/>
              </Grid>
              
                </Grid>
              </FormTab>
              <FormTab label="Componentes">
              <Grid container spacing={24}>  
              <Grid item xs={12}>
                  <h3 style={{marginBottom: 0}}>Calificación de Componentes</h3>                    
                  <hr></hr>
              </Grid> 
              <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Componente</TableCell>
            <TableCell align="right">Presencia de daños</TableCell>
            <TableCell align="right">Aplica</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Elemento</TableCell>
            <TableCell align="right">Fotografías</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
        <TableRow >
            <TableCell component="th"  rowSpan={5}>
            Superficie del puente
              </TableCell>
              <TableCell align="right">Grietas</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol1" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num1"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel31" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
          
            <TableRow >
            <TableCell component="th">
            Dañada
              </TableCell>
             
              <TableCell align="right"><BooleanInput label="" source="bol2" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num2"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel1" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Desconchados
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol3" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num3"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel2" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Total - suma
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol4" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num4"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel3" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            %Área afectada
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol5" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num5"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel4" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={4}>
            Juntas de expansión
              </TableCell>
              <TableCell align="right">Obstrucción del sello</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol6" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num6"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel5" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
          
            <TableRow >
            <TableCell component="th">
            Ruptura del sello
              </TableCell>
             
              <TableCell align="right"><BooleanInput label="" source="bol7" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num7"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel6" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Ausencia del sello
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol8" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num8"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel7" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            No funcional
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol9" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num9"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel8" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={3}>
            Andenes/Bordillos
              </TableCell>
              <TableCell align="right">Pérdidas</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol10" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num10"/></TableCell>
              <TableCell >                           
                 <SelectInput label="" source="sel9" choices={choices}  fullWidth/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Grietas
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol11" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num11"/></TableCell>
              <TableCell align="right">  <SelectInput label="" source="sel10" choices={choices}  fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Desconchados
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol12" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num12"/></TableCell>
              <TableCell align="right">  <SelectInput label="" source="sel11" choices={choices}  fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={4}>
            Barreras de seguridad/barandillas de puentes/pretiles metálicos
              </TableCell>
              <TableCell align="right">Rotura o daños</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol13" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num13"/></TableCell>
              <TableCell >                           
                 <SelectInput label="" source="sel12" choices={choices}  fullWidth/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Conexiones en mal estado
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol14" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num14"/></TableCell>
              <TableCell align="right">  <SelectInput label="" source="sel13" choices={choices}  fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Protecciones en mal estado
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol15" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num15"/></TableCell>
              <TableCell align="right">  <SelectInput label="" source="sel14" choices={choices}  fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Grietas >5mm o desconchados >10mm
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol16" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num16"/></TableCell>
              <TableCell align="right">  <SelectInput label="" source="sel15" choices={choices}  fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={7}>
            Subestructura
              </TableCell>
              <TableCell align="right">Desconchados</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol17" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num17"/></TableCell>
              <TableCell >                           
                   <SelectInput  choices={choices1}  label=""  source="sel16" fullWidth/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Grietas
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol18" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num18"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel17" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Erosión
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol19" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num19"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel18" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Deterioros en apoyos
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol20" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num20"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel19" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Elemento con descalce o mal estado
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol21" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num21"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel20" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Deterioros en rótulas
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol22" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num22"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel21" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Daños estructurales
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol23" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num23"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel22" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={2}>
            Drenaje
              </TableCell>
              <TableCell align="right">Superficie erosionada</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol24" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num24"/></TableCell>
              <TableCell >                           
              <TextInput label="" source="sel23" validate={[minLength(0),maxLength(20)]}/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Daños Daño estructural
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol25" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num25"/></TableCell>
              <TableCell align="right"> <TextInput label="" source="sel24" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={6}>
            Puente en general
              </TableCell>
              <TableCell align="right">Armadura descubierta</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol26" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num26"/></TableCell>
              <TableCell >                           
              <SelectInput  choices={choices2}  label=""  source="sel25" fullWidth/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Daños estructurales
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol27" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num27"/></TableCell>
              <TableCell align="right">   <SelectInput  choices={choices2}  label=""  source="sel26" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Concretos metálicos en mal estado
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol28" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num28"/></TableCell>
              <TableCell align="right">   <SelectInput  choices={choices2}  label=""  source="sel27" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Elementos de unión en mal estado
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol29" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num29"/></TableCell>
              <TableCell align="right">   <SelectInput  choices={choices2}  label=""  source="sel28" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Superficie con corrosión
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol30" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num30"/></TableCell>
              <TableCell align="right">   <SelectInput  choices={choices2}  label=""  source="sel29" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Pintura en malas condiciones
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol31" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num31"/></TableCell>
              <TableCell align="right">   <SelectInput  choices={choices2}  label=""  source="sel30" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            
            
          
        </TableBody>
      </Table> 
            </Grid>
            </FormTab>
            </TabbedForm>
       
    </PimsCreate>
);
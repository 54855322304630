import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import React , {useState}from 'react';
import { NumberInput, ReferenceInput,ArrayInput, SimpleFormIterator, ImageInput,ImageField,
        SelectInput, FormDataConsumer,  DateInput, AutocompleteInput,
        TextInput, BooleanInput,Toolbar,SaveButton, minLength, maxLength
    } from 'react-admin';
import { Edit } from './../../Edit';
import { PRInput } from '../../../inputs/PRInput';
import Grid from '@material-ui/core/Grid';
import {abValidationInicio, abValidationFin, validarUnDecimal,Entero,latMininima, lngMininima} from '../../validacionesInputs';
import { withStyles } from '@material-ui/core';
import {
    required,
    FormTab,
    TabbedForm
} from 'react-admin';
import { minValue } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BackButton from '../../../BotonCancelar';
import  {styles}  from './../../../EstilosGrid';
import ElementosCreados from './../../ElementosCreados';
import { ButtonCarousel } from './../../images/ButtonCarouselPeru';



const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <BackButton />
    </Toolbar>
));;
//------------------ VALIDACIÓN DE DECIMALES -------------

    const payload = {
        pagination: { page: 1, perPage: 10 },
   sort: { field: 'id', order: 'DESC' },
       
     }; 



export const ElementoSenalizacionEdit = withStyles(styles)(({ classes, ...props }) =>{
    const [unidads , setUnidad]= useState('');
    const [opciones, setOpcion] = useState([{id:'' , name : 'Cargando...'}]);
    const ValidarUnidad = (value)  => {
                        
        setUnidad(value) 
    }


const Unidades = { unidadFuncional: unidads };

    const [tramos , setTramo]= useState('');

    const ValidarTramo = (value)  => {              
        setTramo(value) 
        
    }


const Tramoss = { tramo: tramos };

    const [segmentos, setSegmento] = useState('');
/* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */     
    const ValidarSegmento =(value) =>{              
        setSegmento(value) 
        let idSegmento = value;
        idSegmento = idSegmento.split('/');
      
    }
  
const [elemento , setElemento]= useState('');
    const ValidarElemento =(value) =>{                      
    setElemento(value); 
    // if(contador === 0)
    // {
    //     fetch(urlAppjs+ '/elementoSenal/')
    //     .then((response) => {
    //         console.log("REST");
    //         return response.json()
    //     })
    //     .then((response) => {
    //         setOpcion(response);    
    //         console.log(response)
    //     })
    // }
    }
/* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

const [tipoSenal, setTipoSenal] = useState('');
const ValidarTipoSenal =(value) =>{          
    setTipoSenal(value)                
}

/*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray , setLatitudArray]= useState('');
    const LatitudMinimaArray =(value) =>{           
        setLatitudArray(value) 
    }
    const [longitudMinimaArray , setLongitudArray]= useState('');
    const LongitudMinimaArray =(value) =>{           
        setLongitudArray(value) 
    }
    const [longitudMaximaArray , setLongitudMaxdArray]= useState('');
    const LongitudMaximaArray =(value) =>{           
        setLongitudMaxdArray(value) 
    }
    const [latitudMaximaArray , setLatitudMaxdArray]= useState('');
    const LatitudMaximaArray =(value) =>{           
        setLatitudMaxdArray(value) 
}
/*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/ 

    const choicess = [
        { id: 'Amarillo', name: 'Amarillo' },
        { id: 'Amarillo fluorescente', name: 'Amarillo fluorescente' },
        { id: 'Amarillo-verde fluorescente', name: 'Amarillo-verde fluorescente' },
        { id: 'Azul', name: 'Azul' },
        { id: 'Blanco', name: 'Blanco' },
        { id: 'Marrón', name: 'Marrón' },
        { id: 'Naranja', name: 'Naranja' },
        { id: 'Naranja fluorescente', name: 'Naranja fluorescente' },
        { id: 'Negro', name: 'Negro' },
        { id: 'No aplica', name: 'No aplica' },
        { id: 'Rojo', name: 'Rojo' },
        { id: 'Verde', name: 'Verde' },        
    ];

    const choice = [
        { id: 'Amarillo', name: 'Amarillo' },
        { id: 'Amarillo fluorescente', name: 'Amarillo fluorescente' },
        { id: 'Amarillo-verde fluorescente', name: 'Amarillo-verde fluorescente' },
        { id: 'Azul', name: 'Azul' },
        { id: 'Blanco', name: 'Blanco' },
        { id: 'Marrón', name: 'Marrón' },
        { id: 'Naranja', name: 'Naranja' },
        { id: 'Naranja fluorescente', name: 'Naranja fluorescente' },
        { id: 'No aplica', name: 'No aplica' },
        { id: 'Rojo', name: 'Rojo' },
        { id: 'Verde', name: 'Verde' },
        // { id: 'Negro', name: 'Negro' },
    ];



/*VALIDACIÓN DE IMAGENES PREDETERMINADAS */
    const[images , setImagen] = useState('')
    const Imagenes = (value) =>{
        if(value !== undefined){
            let valor = value.split('/')
            valor = valor[2]
            setImagen('/imagenes/senalesColombia/senales_informativas/'+valor+'.png')              
        }                 
        
    }
    const Imagenes1 = (value) =>{
        if(value !== undefined){
            let valor = value.split('/')
            valor = valor[2]
            setImagen('/imagenes/senalesPeru/'+valor+'.png')              
        }                 
        
    }

    const Imagenes2 = (value) =>{
        if(value !== undefined){
            let valor = value.split('/')
            valor = valor[2]
            setImagen('/imagenes/senalesPeru/TiposSenal/'+valor+'.png')              
        }                 
        
    }

/*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }

/*VALIDACIÓN DE DIÁMETRO EN FORMA DE TRABLERO*/
    const [FormaTablero,setFormaTablero] = useState('');
    const ValidarFormaTablero = (value) =>{
        setFormaTablero(value);        
    }

/* FIN DE VALIDACIÓN DE IMAGENES PREDETERMINADAS */
    return(
    <Edit {...props}  title="Editar Elemento Señal">
         <TabbedForm redirect="list" toolbar={<CustomToolbar />}>
             {/* ------ ELEMENTO SEÑAL ------ */}
             <FormTab label="IDENTIFICACIÓN" redirect="list" > 
                <CoordenadasProyecto/>      
                <Grid container spacing ={24}>
                    <Grid item xs={12}>
                        <ReferenceInput 
                            label="Elemento" 
                            source="tipoElementoSenal" 
                            reference="tipo_elemento_senals" 
                            validate={[required(), ValidarElemento, Imagenes1]} 
                            disabled
                        >
                            <SelectInput  optionText="nombreToLower" fullWidth />
                        </ReferenceInput>
                    </Grid>                                   
                    <Grid item xs={6}>
                        <SelectInput  label="Margen" source="margen" allowEmpty emptyValue=""  validate={[required()]} fullWidth choices={[
                                { id: 'Derecha', name: 'Derecha' },
                                { id: 'Izquierda', name: 'Izquierda' },
                                { id: 'Centro', name: 'Centro' },
                            ]} />
                    </Grid>
                    <Grid item xs={6}>                             
                    {elemento &&
                        <ReferenceInput 
                            label="Tipo de elemento" 
                            source="tipoSenal" 
                            fullWidth 
                            reference="tipo_senals" 
                            validate={[required(),  Imagenes2, ValidarTipoSenal]} 
                            sort={{ field: 'nombre', order: 'ASC' }} 
                            filter={{tipoElementoSenal:elemento}}
                            >
                            <SelectInput  optionText="nombre"  />
                        </ReferenceInput>         
                    }   
                    </Grid>                             
                    <TextInput source="tipoSenal" className={classes.ocultar} />  
                    <Grid item xs={4}>
                        {elemento === '/tipo_elemento_senals/SV' && tipoSenal &&
                            
                            <ReferenceInput 
                                label="Código de modelo"
                                source="senal" 
                                reference="senals" 
                                validate={[required(), Imagenes]} 
                                filter={{ tipoSenal: tipoSenal }}
                                filterToQuery={searchText => ({ id: searchText })}
                            >
                                <AutocompleteInput optionText="ids" options={{fullWidth: true}} />
                            </ReferenceInput>
                           
                        }
                        </Grid>
                    <Grid item xs={8}> 
                        {elemento=== '/tipo_elemento_senals/SV' && 
                            <ReferenceInput 
                            label="Modelo"
                            source="senal" 
                            reference="senals" 
                            validate={[required(), Imagenes]} 
                            filter={{ tipoSenal: tipoSenal }}
                            filterToQuery={searchText => ({ nombre: searchText })}
                            >
                                <AutocompleteInput optionText="nombre" options={{fullWidth: true}} />
                            </ReferenceInput>                                
                        }   
                    </Grid>   
                </Grid>      
                            {/* ---------- LOCALIZACIÓN --------- */}
                        <Grid container spacing={24}> 
                        <Grid item xs={12}>
                        <Typography variant="h3" component="h3" style={{marginTop: '10px'}}>Localización</Typography>
                        <span> </span>
                        <Divider fullWidth style={{marginTop: '10px'}} />
                                </Grid>   
                                <Grid item xs={4}>
                            <ReferenceInput 
                                label="Unidad Funcional" 
                                source="unidadFuncional"  
                                reference="unidad_funcionals" 
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{field : 'codigo', order : 'asc'}}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                disabled
                            >
                                <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>     
                            <ReferenceInput 
                                label="Tramo" 
                                source="tramo" 
                                reference="tramos" 
                                filter={Unidades} 
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{ field: 'codigo', order: 'ASC' ,}}
                                validate={[required(), ValidarTramo]}
                                formClassName={classes.grid_cont4}
                                disabled
                            >
                                <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                            </ReferenceInput>                           
                        </Grid>   
                        <Grid item xs={4}> 
                            <ReferenceInput 
                                label="Segmento" 
                                source="segmento" 
                                reference="segmentos" 
                                sort={{ field: 'codigo', order: 'ASC' }}
                                filter={Tramoss}  
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                validate={[required(), ValidarSegmento]}
                                formClassName={classes.grid_cont4}
                                disabled
                            >
                            
                                <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                            </ReferenceInput>
                        </Grid>
                        
                    </Grid>
                    {segmentos && <AbsSegmento segmento={segmentos}/>}
   
                    {segmentos &&                
                        <Grid container spacing={24}>  
                            <Grid item xs={12}>
                            {segmentos && elemento === '/tipo_elemento_senals/SV' && 
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas</Typography>

                            }
                            {segmentos && elemento !== '/tipo_elemento_senals/SV' && 
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas inicales</Typography>
                            }                    
                                <Divider fullWidth style={{marginTop : '10px'}} />  
                            </Grid>
                        </Grid>                
                    }                                           
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio,required()]}  formClassName={classes.grid_cont4s}/>}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial"  fullWidth validate={[required(), latMininima,LatitudMinimaArray ]} formClassName={classes.grid_cont4}/>}     
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial"  fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4}/>}
                    {segmentos &&  elemento !== '/tipo_elemento_senals/SV' &&               
                        <Grid container spacing={24}>  
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas</Typography>                    
                                <Divider fullWidth style={{marginTop : '10px'}}/>  
                            </Grid>
                        </Grid>                 
                    } 
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin,required()]} formClassName={classes.grid_cont4s}/>}
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' &&<NumberInput label="Latitud" source="latitudFinal"  fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont4}/>}     
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' &&<NumberInput label="Longitud" source="longitudFinal"  fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4}/>}    
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' &&                                       
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >                            
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }  
                        {segmentos && elemento === '/tipo_elemento_senals/SV' &&                                       
                            <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                                defaultValue={[
                                    {
                                        lat: latitudMinimaArray,
                                        lng: longitudMinimaArray
                                    }
                                ]}
                            >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth/>
                            </SimpleFormIterator>
                        </ArrayInput>
                        }                                              
            </FormTab>
                        
            {/* -------------- CARACTERÍSTICAS ---------- */}
            <FormTab label="Características" redirect="list" >  
            <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => ( formData !== null && formData.tipoSenal === '/tipo_senals/SMV'  &&
                        <Grid item xs={12}>
                             <Typography variant="p" component="p">
                                    Módulo no diponible para tipo de señal "Mensaje variable"
                            </Typography>
                        </Grid>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => ( formData !== null && formData.tipoSenal === '/tipo_senals/BYC'  &&
                        <Grid item xs={12}>
                             <Typography variant="p" component="p">
                                    Módulo no diponible para tipo de señal "Banderas y carteles"
                            </Typography>
                        </Grid>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => ( formData !== null && formData.tipoSenal === '/tipo_senals/SOS'  &&
                        <Grid item xs={12}>
                             <Typography variant="p" component="p">
                                    Módulo no diponible para tipo de señal "Postes SOS y otros"
                            </Typography>
                        </Grid>
                        )
                    }
                </FormDataConsumer>
                    <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => ( formData !== null && formData.tipoSenal !== '/tipo_senals/SMV'  
                                                    && formData.tipoSenal !== '/tipo_senals/BYC'  &&
                                                    formData.tipoSenal !== '/tipo_senals/SOS'  &&
                        
                    <FormDataConsumer>
                    {
                        ({ formData, ...rest }) => ( formData !== null &&  formData.tipoElementoSenal === '/tipo_elemento_senals/SV'  && 
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <SelectInput fullWidth label="Material de placa" source="material" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'Acero galvanizado', name: 'Acero galvanizado' },
                                        { id: 'Aluminio', name: 'Aluminio' },
                                    ]}  validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput  source="tipoPapel" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'Alta amarillo', name: 'Alta amarillo' },
                                        { id: 'Alta blanco', name: 'Alta blanco' },
                                        { id: 'Diamante amarillo verde fluorescente', name: 'Diamante amarillo verde fluorescente' },
                                        { id: 'Diamante azul', name: 'Diamante azul' },
                                        { id: 'Diamante blanco', name: 'Diamante blanco' },
                                        { id: 'Diamante marrón', name: 'Diamante marrón' },
                                        { id: 'Diamante rojo', name: 'Diamante rojo' },
                                        { id: 'Diamante verde', name: 'Diamante verde' },
                                        { id: 'Ingeniería amarillo', name: 'Ingeniería amarillo' },
                                        { id: 'Ingeniería blanco', name: 'Ingeniería blanco' },
                                        { id: 'T11 amarillo 700', name: 'T11 amarillo 700' },
                                    ]}  validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput  label="Tipo de lámina" source="tipoLamina" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'I', name: 'I' },
                                        { id: 'II', name: 'II' },
                                        { id: 'III', name: 'III' },
                                        { id: 'IV', name: 'IV' },
                                        { id: 'V', name: 'V' },
                                        { id: 'VI', name: 'VI' },
                                        { id: 'VII', name: 'VII' },
                                        { id: 'VIII', name: 'VIII' },
                                        { id: 'IX', name: 'IX' },
                                        { id: 'X', name: 'X' },
                                        { id: 'XI', name: 'XI' },

                                    ]}  validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput  label="Forma del tablero" source="tipoTablero" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'Circular', name: 'Circular' },
                                        { id: 'Cuadrada', name: 'Cuadrada' },
                                        { id: 'Rectangular', name: 'Rectangular' },
                                        { id: 'Otro', name: 'Otro' },
                                    ]}  validate={[required(), ValidarFormaTablero]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput  label="Dimensiones del tablero" source="dimensionTablero" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: '80 x 80 cm', name: '80 x 80 cm' },
                                        { id: '100 x 100 cm', name: '100 x 100 cm' },
                                        { id: '120 x 120 cm', name: '120 x 120 cm' },
                                        { id: '140 x 140 cm', name: '140 x 140 cm' },
                                        { id: 'Otra', name: 'Otra'},

                                    ]}  validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput  label="Tipo de soporte" source="tipoSoporte" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'Bandera', name: 'Bandera' },
                                        { id: 'Pasacalle', name: 'Pasacalle' },
                                        { id: 'Vástago', name: 'Vástago' },

                                    ]}  validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput  label="Velocidad asociada a la dimensión" source="velocidadDimension" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: '< 70 km/h', name: '< 70 km/h' },
                                        { id: '< 90 km/h', name: '< 90 km/h' },
                                        { id: '< 100 km/h', name: '< 100 km/h' },
                                        { id: '> 100 km/h', name: '> 100 km/h' },

                                    ]}  validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Alto (m)" source="altura" validate={[required(), minValue(0, "Debe ser al menos 0"), validarUnDecimal]} fullWidth/>
                                </Grid>
                                <Grid item xs={4}>
                                    {FormaTablero === 'Circular' &&                                                        
                                        <NumberInput label="Diámetro (m)" source="area" validate={[required(), minValue(0, "Debe ser al menos 0"), validarUnDecimal]} fullWidth/>                                                        
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Valor límite" source="valorLimite" fullWidth/>
                                    <Typography className={classes.infoAdicional} variant="body2">Aplica si es señal SR-30, SR-31, SR-32,SR-33,SP-38</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="Color principal" source="color" allowEmpty emptyValue=""   sort={{field:'name', order:'ASC'}} fullWidth choices={choice}  />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Retro-reflectividad color principal (cd/lux/m²)" source="retroflectividadInicial" fullWidth  validate={[minValue(0), Entero]}/>                                
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput label="Color secundario" source="colorSecundario" allowEmpty emptyValue=""  sort={{field:'name', order:'ASC'}}fullWidth choices={choicess}  />
                                </Grid>
                                
                                <FormDataConsumer>
                                    { ({ formData, ...rest }) => ( formData !== null && formData.colorSecundario !== 'No aplica' &&  
                                        <Grid item xs={6}>
                                            <NumberInput label="Retro-reflectividad color secundario (cd/lux/m²)" source="retroflectividadFinal" fullWidth  validate={[minValue(0), Entero]}/>                                
                                        </Grid>   
                                    )
                                    }                  
                                </FormDataConsumer>
                                        </Grid>
                        ) 
                    }
                </FormDataConsumer>  
                        )
                    }
                    </FormDataConsumer>

                
               
                {/* --------- VALIDACIÓN DE SEÑAL HORIZONTAL --------------- */}
                
                <FormDataConsumer>
                    {    
                        ({ formData, ...rest }) => ( formData !== null && formData.tipoElementoSenal === '/tipo_elemento_senals/SH' &&
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                <SelectInput  source="material" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'Cintas preformadas', name: 'Cintas preformadas' },
                                        { id: 'Pinturas', name: 'Pinturas' },
                                        { id: 'Plásticos en frio', name: 'Plásticos en frio' },
                                        { id: 'Termoplásticas', name: 'Termoplásticas' },
                                    
                                    ]}   validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput  source="color" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'Amarilla', name: 'Amarilla' },
                                        { id: 'Blanca', name: 'Blanca' },
                                    
                                    ]}  validate={[required()]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Número de tachas"   fullWidth source="numeroTachas" validate={[required(), Entero]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Longitud (m)"  source="longitud" validate={[required() , validarUnDecimal , minValue(0)]} fullWidth />
                                </Grid>
                                 <Grid item xs={4}>
                                    <NumberInput label="Ancho (m)"  source="ancho" validate={[required() , validarUnDecimal , minValue(0)]} fullWidth />
                                </Grid>
                            
                            </Grid>  

                        ) 
                    }
                </FormDataConsumer> 
                <FormDataConsumer>
                    { ({ formData, ...rest }) => ( formData !== null && formData.tipoElementoSenal === '/tipo_elemento_senals/DEM'  &&
                        <Grid container spacing={24}> 
                            <Grid item xs={6}>
                                <SelectInput  source="material" allowEmpty emptyValue=""   fullWidth choices={[
                                        { id: 'Cintas preformadas', name: 'Cintas preformadas' },
                                        { id: 'Pinturas', name: 'Pinturas' },
                                        { id: 'Plásticos en frio', name: 'Plásticos en frio' },
                                        { id: 'Termoplásticas', name: 'Termoplásticas' },
                                    
                                    ]}   validate={[required()]}/>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput  source="color" allowEmpty emptyValue=""   fullWidth choices={[
                                    { id: 'Amarilla', name: 'Amarilla' },
                                    { id: 'Blanca', name: 'Blanca' },
                                
                                ]}  validate={[required()]}/>
                            </Grid>   
                            <Grid item xs={4}>
                                <NumberInput label="Longitud (m)"  source="longitud" validate={[required() , validarUnDecimal , minValue(0)]} fullWidth />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Ancho (m)"  source="ancho" validate={[required() , validarUnDecimal , minValue(0)]} fullWidth />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Área (m²)"  source="area" validate={[required() , validarUnDecimal , minValue(0)]} fullWidth />
                            </Grid>
                        </Grid>
                    )
                    }                        
                </FormDataConsumer>
                <FormDataConsumer>
                    { ({ formData, ...rest }) => ( formData !== null && formData.tipoElementoSenal === '/tipo_elemento_senals/OTR'  &&
                        <Grid container spacing={24}>    
                            <Grid item xs={6}>
                                <NumberInput  source="cantidad" validate={[required() , minValue(0), Entero]} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <NumberInput label="Espaciamiento promedio (m)"  source="espaciamientoPromedio" validate={[minValue(0), validarUnDecimal]} fullWidth />
                            </Grid>
                            
                        </Grid>
                    )
                    }                        
                </FormDataConsumer>                     
            </FormTab>   
             {/* ------ DETALLES  ------ */}
             <FormTab label="Detalles" redirect="list" >       
             <Grid container spacing={24}>   
                <Grid item xs={4}>                    
                    <FormDataConsumer>
                        { ({ formData, ...rest }) => ( formData !== null && formData.tipoElementoSenal === '/tipo_elemento_senals/SV' &&  
                                
                                     <SelectInput source="estado" choices={[
                                        { id: "Existente", name: 'Existente' },
                                        { id: "Reemplazo", name: 'Reemplazo' },                                        
                                        { id: "Nuevo", name: 'Nuevo' },
                                    ]}  fullWidth validate={[required()]}
                                    />
                                   
                                
                        )
                        }                  
                     </FormDataConsumer>
                     <FormDataConsumer>
                            { ({ formData, ...rest }) => ( formData !== null && formData.tipoElementoSenal !== '/tipo_elemento_senals/SV'   &&     
                                
                                    <SelectInput source="estado" choices={[
                                            { id: "Bueno", name: 'Bueno' },
                                            { id: "Malo", name: 'Malo' },
                                            { id: "Nuevo", name: 'Nuevo' },
                                            { id: "Regular", name: 'Regular' },                                         
                                        ]}  fullWidth validate={[required()]}
                                    />
                                 
                               

                            )
                            }
                        </FormDataConsumer>
                        </Grid> 
                     <Grid item xs={4}>  
                        <FormDataConsumer>
                            { ({ formData, ...rest }) => ( formData !== null && formData.tipoElementoSenal === '/tipo_elemento_senals/SV' && formData.estado === "Reemplazo" &&   
                                  
                                <SelectInput source="motivoReemplazo" choices={[
                                    { id: "Accidente", name: 'Accidente' },
                                    { id: "Instalación", name: 'Instalación' },
                                    { id: "Otro", name: 'Otro' },
                                    { id: "Reemplazo programado", name: 'Reemplazo programado' },
                                    { id: "Vandalismo", name: 'Vandalismo' },
                                ]} fullWidth validate={[required()]}
                                />
                            )
                            }                  
                        </FormDataConsumer> 
                        <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (  formData !== null && formData.estado === 'Nuevo'   && formData.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                     
                                        <SelectInput label="Condición" source="condicion" choices={[
                                            { id: "Instalación", name: 'Instalación' },
                                            { id: "Mantenimiento", name: 'Mantenimiento' },                                         
                                        ]}  fullWidth validate={[required()]}
                                        />  
                                    )
                                }
                        </FormDataConsumer> 
                    </Grid>            
                    <Grid item xs={4}>
                        <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (  formData !== null && formData.estado === "Reemplazo" &&
                                
                            <ElementosCreados 
                                tabla="elemento_senalizacions" 
                                tipo="editar" 
                                elementos={formData.tipoElementoSenal} 
                            />
                                
                             )
                        }
                        </FormDataConsumer>
                    </Grid>
                    {/* ------------------ VALIDACIÓN DE ESTADO -----------------  */}
                    <Grid item xs={4}>
                        <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4} />
                        </Grid>
                        <br/>
                        <Grid item xs={4}>
                            </Grid>
                        <Grid item xs={4}>
                        </Grid>                  
                    <Grid item xs={4}>
                        <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (  
                                <DateInput label="Fecha de instalación" source="fechaInstalacion" fullWidth validate={[required()]}/>
                            )
                        }
                        </FormDataConsumer>
                            
                    </Grid>
                    <Grid item xs={4}>
                        <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => ( 
                                
                                <ReferenceInput 
                                    label="Proveedor" 
                                    source="proveedor" 
                                    reference="proveedors" 
                                    filter={{ 
                                        proyecto: props.proyecto, 
                                        suministros: 1, sistema: 'SEN' 
                                    }}
                                    sort={{ field: 'razonSocial', order: 'ASC' }}
                                    filterToQuery={searchText => ({ razonSocial: searchText })} 
                                    validate={[required()]}
                                >
                                    <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                                </ReferenceInput>
                            )
                        }
                        </FormDataConsumer>          
                    </Grid>    
                    <Grid item xs={4}>
                        <FormDataConsumer>
                            {
                            ({ formData, ...rest }) => (  
                                <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required(), minValue(0), Entero]}/>
                                )
                            }
                        </FormDataConsumer> 
                    </Grid>
                    <Grid item xs={6}>
                        <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="observaciones" fullWidth validate={[required() , minLength(5), maxLength(200, "Máximo 200 caracteres")]}/>
                    </Grid>
                </Grid>        
            </FormTab>  
            <FormTab label="FOTOGRAFÍAS">
                <Grid container spacing={24}>                         
                    <Grid item xs={8}>  
                        <ArrayInput label="" source="images" validate={ImagenesEdit} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>                           
                                <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                    <SelectInput  optionText="nombre"  />
                                </ReferenceInput>                          
                            </SimpleFormIterator>
                        </ArrayInput>    
                        
                    </Grid>     
                    <ButtonCarousel ImagenesOpen={imagenesOpen}   />                                
                </Grid>                   
            </FormTab>
        </TabbedForm>
    </Edit>

)});
import React, { useState } from 'react';
import {
    Toolbar, CardActions, Tab, NumberField, ShowController, ShowView, TextField, DateField,
    ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, ArrayField, FormDataConsumer,
    SingleFieldList, FormTab, RichTextField, ReferenceArrayField, Datagrid
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { PRField } from './../../../fields/PRField';
import AddFlujoCajaDetalles  from './../localHelpers/AddFlujoCajaDetalles';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
let urlAppjs = config.UrlServer;


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

clearTimeout();


export const FlujoCajaShow = withStyles(styles)(({ classes, record, ...props }) => {
    return (
        <ShowController {...props} title="Información Flujo de Caja">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Flujo de Caja" actions={<PostShowActions />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="INFORMACION">
                            <ReferenceField
                                label="Unidad Funcional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField
                                label="Nombre de Politicas"
                                source="nombrePolitica"
                                reference="politicas_nombres"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>
                            <TextField 
                                label="Tasa de Interés" 
                                source="tasaInteres"
                                formClassName={classes.grid_cont3}
                            />   

                            <br/>

                            {controllerProps.record &&
                                <AddFlujoCajaDetalles 
                                    parentProps={controllerProps.record} 
                                />
                            }
                        </FormTab>
                        <FormTab label="AuditorÍa">
                            <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
});
import React from 'react';
import NumberFormat from 'react-number-format';


export default function currencyFormatter(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat  
            {...other}
            getInputRef={inputRef}
            prefix="$"
            thousandSeparator='.'
            decimalSeparator=','
            decimalScale={0}
            fixedDecimalScale={true}
            allowNegative={false}        
        />
      );
}
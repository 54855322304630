import React, {useState} from 'react';
import {  TabbedShowLayout ,Toolbar, Tab, Datagrid, ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, FormTab, ArrayField,ChipField,SingleFieldList,NumberField,ListButton,EditButton  } from 'react-admin';
import { Show } from './../Show';
import { PRField } from './../../fields/PRField';
import { FField } from './../../fields/FField';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AsociarServicios from './serviciosAsociados';



const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar>
));

const styles = {
   ocultar: {display:"none",    },
   grid_cont2:{display: 'inline-block!important', width: '20%!important', marginLeft: '10px!important' },
   grid_cont3:{display: 'inline-block!important', width: '24%!important', marginLeft: '10px!important' },
   grid_cont4:{width: '32%!important', marginLeft: '10px!important' },
   grid_cont6:{display: 'inline-block!important', width: '49%!important', marginLeft: '10px!important' },
   grid_cont12:{display: 'inline-block!important', width: '99%!important', marginLeft: '10px!important', wordBreak: 'break-all' },
   "& div:empty":{display:'none'},
   grid_cont1:{display: 'inline-block!important', width: '18%!important', marginLeft: '10px!important' },
};
export const DisponibilidadShow = withStyles(styles)(({ classes, ...props }) => {
     return(
<ShowController {...props} title="Disoinibilidad de Vía">
{controllerProps =>
     <Show title="Información de Disponibilidad de Vía"  {...props} {...controllerProps}>
        <TabbedShowLayout>
        <Tab label="IDENTIFICACIÓN">
            <TextField label="Consecutivo" fullWidth source="consecutivo" className={classes.grid_cont3}/>
            <DateField label="Fecha" fullWidth source="fecha" className={classes.grid_cont3}/>
            <TextField label="Tipo de Evento" fullWidth source="tipoEvento" className={classes.grid_cont3}/>
            {controllerProps.record && controllerProps.record.tipoEvento==="Cierre" &&
            <TextField label="Tipo de Cierre"  fullWidth source="tipoCierre" className={classes.grid_cont3}/>
            }
            {controllerProps.record && controllerProps.record.tipoEvento==="Cierre" &&
            <span></span>
            }
            {controllerProps.record && controllerProps.record.tipoEvento==="Cierre" &&
            <span></span>
            }
            <Typography formClassName={classes.grid_cont12} variant="b" component="b">Localización</Typography>
            <span></span>
            <span></span>
            <Divider fullWidth className={classes.grid_cont12}/>
            <TextField label="Unidad Funcional" source="unidadcodigo" className={classes.grid_cont4}/> 
            <PRField label="Progresiva Inicial"  source="abscisaininum" fullWidth className={classes.grid_cont4}/>
            <PRField label="Progresiva Final"  source="abscisafinnum" fullWidth className={classes.grid_cont4}/>
            <TextField label="Margen"  fullWidth source="margen" className={classes.grid_cont6} />
            <TextField label="Lugar"  source="lugar" fullWidth className={classes.grid_cont6} />
            <TextField label="Longitud Afectada (km)" source="longitudAfect" fullWidth className={classes.grid_cont3}/>
            <TextField label="Segmentos Afectados" source="segmentosAfect" fullWidth/>
            <NumberField label="Latitud" source="latitud" fullWidth className={classes.grid_cont3} options={{ maximumFractionDigits: 2 }} />
            <NumberField label="Longitud" source="longitud" fullWidth className={classes.grid_cont1} options={{ maximumFractionDigits: 2 }}/>  
        </Tab>
        <Tab label="DETALLES">
            <DateField label="Fecha de Reporte" source="fechaReporte" fullWidth className={classes.grid_cont4}/>        
            <FField label="Hora de Reporte" source="horareport" id="horadereporte" fullWidth className={classes.grid_cont4}/>
            <DateField label="Fecha de Señalización" source="fechaSenal" fullWidth className={classes.grid_cont4}/>
            <FField label="Hora de Señalización" source="horasenalizacion" fullWidth className={classes.grid_cont4} />
            <FField label="Tiempo de Señalización" source="tiempSenal" fullWidth className={classes.grid_cont4}/>
            <div className={classes.grid_cont4}></div>
            <TextField label="Fuente del Reporte"  fullWidth source="fuenteRep" className={classes.grid_cont4} />
            <TextField label="Causa"  fullWidth source="causa" />
            <TextField label="Elemento Afectado"  fullWidth source="elementoAfect" className={classes.grid_cont4} />
            <TextField label="Observaciones" source="observaciones" fullWidth className={classes.grid_cont12} multiline/>
            <Typography className={classes.grid_cont12} variant="b" component="b">Asociar Servicios</Typography>
            <Divider fullWidth className={classes.grid_cont12}/>
            {controllerProps.record && controllerProps.record.servicios &&    
               <AsociarServicios {...controllerProps.record}/>
               // <ArrayField label="" source="servicios" fullwidth>
               //    <Datagrid>
               //       <TextField label="Directorio"  fullWidth source="serviciosDirectorio"/>
                     
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
               //          <TextField label="Grupo" source='serviciosGrupoEmergencias' fullWidth/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
               //          <TextField label="Grupo" source='serviciosGrupoEmergencias' fullWidth/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
               //          <TextField label="Grupo" source='serviciosGrupoEmergencias' fullWidth/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
               //          <ReferenceField label="Sistema" source='serviciosSistemaProveedores' reference="sistemas">
               //             <TextField  source="nombre" fullWidth />
               //          </ReferenceField>
               //       }                                            
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[2].tipoServicio ===2 &&
               //          <ReferenceField label="Sistema" source='serviciosSistemaProveedores' reference="sistemas">
               //             <TextField  source="nombre" fullWidth />
               //          </ReferenceField>
               //       }                                            
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
               //          <ReferenceField label="Sistema" source='serviciosSistemaProveedores' reference="sistemas" >
               //             <TextField  source="nombre" fullWidth />
               //          </ReferenceField>
               //       }              
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
               //          <TextField label="Grupo" source='serviciosGrupoServicios'/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
               //          <TextField label="Grupo" source='serviciosGrupoServicios'/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
               //          <TextField label="Grupo" source='serviciosGrupoServicios'/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoEmergencias' reference="contacto_emergencias" linkType={false}>
               //             <TextField  source="empresa" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoEmergencias' reference="contacto_emergencias" linkType={false}>
               //             <TextField  source="empresa" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoEmergencias' reference="contacto_emergencias" linkType={false}>
               //             <TextField  source="empresa" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoProveedores' reference="proveedors" linkType={false}>
               //             <TextField  source="razonSocial" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===2 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoProveedores' reference="proveedors" linkType={false}>
               //             <TextField  source="razonSocial" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoProveedores' reference="proveedors" linkType={false}>
               //             <TextField  source="razonSocial" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
               //       <ReferenceField label="Elemento" source='serviciosElementoServicios' reference="servicios_publicos" linkType={false}>
               //          <TextField  source="empresa" fullWidth />
               //       </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
               //       <ReferenceField label="Elemento" source='serviciosElementoServicios' reference="servicios_publicos" linkType={false}>
               //          <TextField  source="empresa" fullWidth />
               //       </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
               //       <ReferenceField label="Elemento" source='serviciosElementoServicios' reference="servicios_publicos" linkType={false}>
               //          <TextField  source="empresa" fullWidth />
               //       </ReferenceField>
               //       }
               //    </Datagrid>
               // </ArrayField>
            }
        </Tab>
        <Tab label="CARACTERÍSTICAS" >
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Trabajos en vía") &&
            <NumberField label="Longitud de Cola (m)" source="longitudCola" fullWidth className={classes.grid_cont6}/>
        }
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Trabajos en vía") &&
            <TextField label="Indicador O2" source="indicadorO2" fullWidth className={classes.grid_cont6}/>
        }
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Trabajos en vía") &&
            <TextField label="Observaciones" source="observacionesTrabajos" fullWidth className={classes.grid_cont12} multiline/>
        }
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe") &&
            <NumberField label="Volumen del Derrumbe (m3)" source="volumenDerrumbe" fullWidth />
        }    
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe") &&    
            <TextField label="Elemento Afectado" fullWidth source="elementoAfect"/>
        }                        
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe") && controllerProps.record.volumenDerrumbe>2000 &&
                       
            <TextField label="Condiciones Especiales" fullWidth source="condicionesEsp" />             
        }
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe") &&    
            <TextField label="Tiempo de Despeje Máximo (h)" fullWidth source="tiempoDespeje"/>
        }                
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe") &&
            <TextField label="Observaciones" source="observacionesDerrumbe" fullWidth className={classes.grid_cont12} multiline/>
        }         
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro") &&
            <TextField label="Tipo de Incidente" fullWidth source="tipoIncidenteOtro" />
        }        
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro") &&    
            <TextField label="Tipo de Vehículo" fullWidth source="tipoVehiculoOtro" />
        }
        {controllerProps.record &&(controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro") &&
            <TextField label="Observaciones" source="observacionesOtro" fullWidth className={classes.grid_cont12} multiline/>
        }      
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
            <TextField label="Tipo de Accidente" fullWidth source="tipoAccidente" />
        }            
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <span></span>
        } 
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <Typography formClassName={classes.grid_cont12} variant="b" component="b">Juicio de la Policía</Typography>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
            <NumberField label="Código Causa" source="codigoCausa" fullWidth />
        }                        
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
            <TextField label="Causa Probable" source="causaProbable" fullWidth/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
            <TextField label="Clase" source="clasePolicia" fullWidth/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0 &&
           <Typography formClassName={classes.grid_cont12} variant="b" component="b">Heridos</Typography>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
            <ArrayField label="" source="heridos" fullwidth>
            <Datagrid>
                <NumberField label="Cantidad" source="cantidadHeridos" />
                <TextField label="Tipo" source="tipoHeridos" />
                <TextField label="Género" source="generoHeridos"/>
                <NumberField label="Edad" source="edadHeridos" />
            </Datagrid>
        </ArrayField>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.heridos.length >0  &&
           <span></span>
        }
        
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <Typography formClassName={classes.grid_cont12} variant="b" component="b">Víctimas Fatales</Typography>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
        <ArrayField label="" source="victimas" fullwidth>
        <Datagrid>
            <NumberField label="Cantidad" source="cantidadVictimasf" fullWidth formClassName={classes.grid_cont4}/>
            <TextField label="Tipo" source="tipoVictimasf"  fullWidth formClassName={classes.grid_cont4}/>
            <TextField label="Género" source="generoVictimasf"  fullWidth formClassName={classes.grid_cont4}/>
            <NumberField label="Edad" source="edadVictimasf"  fullWidth formClassName={classes.grid_cont4}/>
        </Datagrid>
    </ArrayField>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.victimas.length >0  &&
           <span></span>
        }
        
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0  &&
           <Typography formClassName={classes.grid_cont12} variant="b" component="b">Vehículos Implicados</Typography>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0  &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0  &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0  &&
        <ArrayField label="" source="vehiculos" fullwidth>
        <Datagrid>
            <NumberField label="Cantidad" source="cantidadVehiculos" fullWidth />
            <TextField label="Categoría" source="categoriaVehiculos" fullWidth />
            <TextField label="Tipo" source="tipoVehiculos" fullWidth />
            <TextField label="Marca" source="marcaVehiculos" fullWidth />
            <TextField label="Línea" source="lineaVehiculos" fullWidth />
            <TextField label="Modelo" source="modeloVehiculos" fullWidth />
            <TextField label="Placa" source="placaVehiculos" fullWidth />
            <TextField label="Color" source="colorVehiculos" fullWidth />
        </Datagrid>
    </ArrayField>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0 &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" && controllerProps.record.vehiculos.length >0 &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
        <TextField label="Observaciones" source="observacionesAccidente" fullWidth className={classes.grid_cont12} multiline/>
        }
        </Tab>
        {controllerProps.record && ((controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe") || (controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro") || (controllerProps.record.tipoEvento==="Accidente")|| (controllerProps.record.tipoEvento==="Cierre"))  &&
        <Tab label="FINALIZAR">
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe" &&
            <DateField label="Fecha de Despeje" source="fechaDespeje" id="fecha_despeje" className={classes.grid_cont6}/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe" &&            
            <FField label="Hora de Despeje" source="horaDespeje" id="hora_despeje" className={classes.grid_cont6}/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe" &&
            <NumberField label="Días de Despeje" id="dias_despejef" source="diasDespeje" className={classes.grid_cont4}/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe" &&
            <FField label="Tiempo de Despeje (h:mm)" id="tiempo_deespejef"  source="tiempoDespejeFin" className={classes.grid_cont4}/>
         }   
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe" &&
            <TextField label="Inicador O4"  id="indicaddoro4" source="indicadorO4" className={classes.grid_cont4}/>
         }   
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Derrumbe" &&
            <TextField label="Observaciones" source="observacionesDespeje" multiline className={classes.grid_cont12}/>
         }
       
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro" &&
                        
            <ArrayField label="" source="servicios" fullWidth >
               <Datagrid>
               {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
                  <ReferenceField label="Elemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
                  <ReferenceField label="Elemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
                  <ReferenceField label="Elemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
                  <ReferenceField label="Elemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="razonSocial"  />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===2 &&
                  <ReferenceField label="Elemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="razonSocial"  />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
                  <ReferenceField label="Elemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="razonSocial"  />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
                  <ReferenceField label="Elemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
                  <ReferenceField label="Elemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
                  <ReferenceField label="Elemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[0] &&
                  <DateField label="Fecha Finalización" source="fechaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && 
                  <DateField label="Fecha Finalización" source="fechaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && 
                  <DateField label="Fecha Finalización" source="fechaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[0] &&
                  <FField label="Hora Finalización" source="horaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[1] &&
                  <FField label="Hora Finalización" source="horaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && 
                  <FField label="Hora Finalización" source="horaFinalizacion"  fullWidth/>
               }
               <FField label="Tiempo Servicio" source="tiempoServicio" fullWidth/>
               </Datagrid>
            </ArrayField>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro" &&
         <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro" &&
            <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Incidente" && controllerProps.record.causa==="Otro" &&
            <TextField label="Observaciones" source="observacionesFinalizacion" className={classes.grid_cont12} fullWidth multiline/>
         }
            
         
         {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
           <ArrayField label="" source="servicios" fullWidth >
           <Datagrid>
           {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
              <ReferenceField label="Elemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
              <ReferenceField label="Elemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
              <ReferenceField label="Elemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
              <ReferenceField label="Elemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth>
              <TextField  source="razonSocial"  />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===2 &&
              <ReferenceField label="Elemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth>
              <TextField  source="razonSocial"  />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
              <ReferenceField label="Elemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth>
              <TextField  source="razonSocial"  />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
              <ReferenceField label="Elemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
              <ReferenceField label="Elemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
              <ReferenceField label="Elemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           <DateField label="Fecha de Llegada" source="fechaLlegada"  fullWidth/>
           <FField label="Hora de Llegada" source="horaLlegada"  fullWidth/>
           <FField label="Tiempo Servicio" source="tiempoRespuesta" fullWidth/>
           <TextField label="Indicador O5" source="indicadorO5" fullWidth/>
           </Datagrid>
        </ArrayField>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
         <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
         <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Accidente" &&
            <TextField label="Observaciones" source="observacionesLlegada" multiline className={classes.grid_cont12}/>
         }         
         {controllerProps.record && controllerProps.record.tipoEvento==="Cierre"  &&
            <DateField label="Fecha de Puesta en Servicio" id="fecha_puestaservicio" source="fechaPuestaServicio" fullWidth/>                   
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Cierre"  &&
            <NumberField label="No. Días Con Cierre"  source="diasCierre" fullWidth/>                
         }            
         {controllerProps.record && controllerProps.record.tipoEvento==="Cierre"  &&
            <TextField label="Indicador E17" source="indicadorE17" fullWidth/>               
         }            
         {controllerProps.record && controllerProps.record.tipoEvento==="Cierre"  &&
            <Typography  variant="b" component="b">No. Segmentos con cierre</Typography>                          
         }
         {controllerProps.record && controllerProps.record.tipoEvento==="Cierre"  &&
            <TextField label="Observaciones"  source="observacionesCierre" fullWidth multiline className={classes.grid_cont12}/>
         }
         </Tab>
         }
        <Tab label="AUDITORIA">
            <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4}/> 
            <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4}/>
            <span></span>               
            <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4}/>
            <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4}/>
        </Tab>
        </TabbedShowLayout>
    </Show>
}
</ShowController>
     )
                    });
import React from 'react';
import {NumberField, Filter,ReferenceInput,SelectInput,ReferenceField,List,AutocompleteInput, Datagrid, EditButton, ShowButton,TextField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
// import { ConActions } from './ConActions';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


const FiltroRol = (props) => (
    <Filter {...props}>
        <ReferenceInput 
            label="Rol" 
            source="admin"  
            reference="atributos" 
            filterToQuery={searchText => ({ nombre: searchText })} 
            sort={{field : 'nombre', order : 'asc'}}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
        </ReferenceInput>
    </Filter>
);

export const UsuarioList = props => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={<FiltroRol/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
            <TextField label="Nombres" source="nombres"/>
            <TextField label="Apellidos" source="apellidos"/>
            <TextField source="tipo_documento"  allowEmpty emptyValue=""  sortable={false} />
            <NumberField label="Identificación" source="identificacion" sortable={false} />
            <TextField label="Correo electrónico" source="username" sortable={false}/>
            <ReferenceField label="Rol" source="admin" reference="atributos" linkType={false} >
                <TextField source="adminNombre" />
            </ReferenceField>
            {/* <TextField label="Rol" source="adminNombre"/> */}
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
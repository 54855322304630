import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const TransitoList = props => (
    <List {...props}  bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>                        
            <TextField label="Provincia" source="municipio_.departamento_.nombre" />
            <TextField label="Distrito" source="municipio_.nombre" />
            <TextField label="Codigo Identificación" source="identificacion" />
            <TextField label="Nombre" source="nombre"/>
            <TextField label="Rutas" source="ruta_.codigo"/>
            <TextField label="Unidad Funcional" source="unidadfuncional_.codigo" />
            <TextField label="Tramos" source="tramo_.codigo" />
            <TextField label="Segmentos" source="tiposegmento_.nombre" />
            <ShowButton />
            <EditButton />  
                
        </Datagrid>
    </List>
);
import React , {useState} from 'react';
import { 
  TabbedForm, 
  TextField, DateField, 
  FormTab,
  ArrayField,
  Query, 
  Error,
  SimpleFormIterator,
  NumberInput, 
  Datagrid,
  NumberField
} from 'react-admin';
import { Show } from './../../Show';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Typography from '@material-ui/core/Typography';
import  {styles}  from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);




export const ModeloCostosPersShow = withStyles(styles)(({ classes, record, ...props }) => {

  const ModeloCostos = ({ record }) => (
    <Query type='GET_ONE' resource='modelo_costos_pers' payload={{ id: record.id }}>
        {({ data, loading, error }) => {
            // if (loading) { return <Loading />; }
            if (error) { return <Error />; }
            if(data !== null){


            return(
              <div>
              <Table style={{width : '95%'}} formClassName={classes.grid_cont6}>
                <TableHead  >
                    <TableRow >
                        <TableCell style={{fontWeight:' bolder', color : 'black', fontSize: '1.1em'}} >Variable</TableCell>
                        <TableCell style={{fontWeight:' bolder', color : 'black', fontSize: '1.1em'}}>Flexible</TableCell>
                        <TableCell style={{fontWeight:' bolder', color : 'black', fontSize: '1.1em'}}>Rígido</TableCell>
                    </TableRow>
                </TableHead>       
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            a
                        </TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableAFlex}</TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableARigd}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            b
                        </TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableBFlex}</TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableBRigd}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            x
                        </TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableXFlex}</TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableXRigd}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            y
                        </TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableYFlex}</TableCell>
                        <TableCell style={{textAlign : 'right'}}>{data.variableYRigd}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
              </div>
            )}
            else return<div></div>
        }}
    </Query>
  );

  const ModeloCostosGrafica = ({ record }) => (
    <Query type='GET_ONE' resource='modelo_costos_pers' payload={{ id: record.id }}>
        {({ data, loading, error }) => {
          let valoresFlex = [];
          let valores = []; 
            // if (loading) { return <Loading />; }
            if (error) { return <Error />; }
            if(data !== null){
              let arrayDatosFlex= [];
              let arrayDatosRig = [];


              data.calculo.map((item,i) =>{
                  let valor = item.costoReparaciones;
                  let valorFlex = item.costoReparacionesFlex;
                  if(item.costoReparaciones !== null){
                    arrayDatosRig.push(valor);
                  }else if(i === 10){
                    arrayDatosRig.push(0);
                  }     
              })
              
              if(arrayDatosRig !== undefined ){
                let arrayOrganizado = arrayDatosRig.sort(function(a, b){return b-a});
                valores = arrayOrganizado;          
              }  
              // let valoresFlex = data.calculoFlex
              data.calculoFlex.map((item,i) =>{
                // let valor = item.costoReparaciones;
                let valorFlex = item.costoReparacionesFlex;
                if(item.costoReparaciones !== null){
                  arrayDatosFlex.push(valorFlex);
                }else if(i === 10){
                  arrayDatosFlex.push(0);
                }     
            })
            if(arrayDatosFlex !== undefined ){
              let arrayOrganizado = arrayDatosFlex.sort(function(a, b){return b-a});
              valoresFlex = arrayOrganizado;          
            }

            const options = {
              title: {
                  text: 'Reparaciones menores'
                },
              
                yAxis: {
                  title: {
                    text: 'Costo reparaciones  (miles $)'
                  }
                },
              
                xAxis: {
                  title:{
                    text:'Valor de PCI'
                  },
                  accessibility: {
                    rangeDescription: 'Range: 10 to 100'
                  },
                  categories: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100']
            
                },
              
                legend: {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'middle'
                },
              
                plotOptions: {
                  series: {
                    label: {
                      connectorAllowed: false
                    },
                    pointStart: 0
                  }
                },
              
                series: [{
                  name: 'Flexible',
                  data: valoresFlex
                  },
                  {name: 'Rígido',
                  data: valores}
                ]
              };
            
            return(
              <div>
                <HighchartsReact highcharts={Highcharts}  options={options} />
              </div>
            )}
            else return<div></div>
        }}
    </Query>
  );
    return(
    <Show  {...props}  title='Modelo de costos PERS' bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedForm toolbar={false}>            
            <FormTab  label="Identificación">
                <ModeloCostos fullWidth formClassName={classes.grid_cont12s}/>  
                  
                <Typography fullWidth formClassName={classes.grid_cont6s} style={{textAlign : 'center'}} variant="h4" component="h4">
                  Flexible
                </Typography>  
                
                <Typography fullWidth formClassName={classes.grid_cont6} style={{textAlign : 'center'}} variant="h4" component="h4">
                  Rígido
                </Typography>
                <ArrayField label='' source='calculoFlex' 
                  fullWidth 
                  formClassName={classes.grid_cont6s} 
                  
                >
                  <Datagrid>
                    <NumberField label='PCI' source='pci'/>
                    <NumberField label='Costo Rep' source='costoRepFlex'/>
                    <NumberField label='Costo Reparaciones' source='costoReparacionesFlex'/>
                  </Datagrid>
                </ArrayField>            
                <ArrayField label='' source='calculo' 
                  fullWidth 
                  formClassName={classes.grid_cont6} 
                  
                >
                  <Datagrid>
                    <NumberField label='PCI' source='pci'/>
                    <NumberField label='Costo Rep' source='costoRep'/>
                    <NumberField label='Costo Reparaciones' source='costoReparaciones'/>
                  </Datagrid>
                </ArrayField>  
               <ModeloCostosGrafica/>                  
            </FormTab> 
            <FormTab  label="Auditoría">   
                <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont6s}/> 
                <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont6s}/>
                <div></div>               
                <DateField source="updatedAt" label="Fecha actualización" formClassName={classes.grid_cont6s}/>
                <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont6s}/>
                <div></div>    
            </FormTab> 
        </TabbedForm>
    </Show>
)});
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';


class Ejes extends React.Component{

    constructor(props){
        super(props)
        this.state = { titulos: [], record: []}
    }

    componentDidMount(){
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })  
       // console.log(this.props.record) 

    }
    // leerMeses (data, anio) {
    //     if (data.ano === anio) {
    //     const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    //     return Object.keys(data).map((key, i) => {
    //       if(keysValidation.includes(key)) {
    //         return <TableCell key={i} align="right" fullWidth style={{textAlign:'right'}}>{data[key] ? data[key] : " "}</TableCell>
    //       }})
    //     }
    //   };

    render(){
        const { titulos } = this.state;
        const {record} = this.state;
        //console.log(this.state)
        let titulos2 =["Autos","Buses","C2-P","C2-G","C3-C4","C5",">C5"];
       
        let ejes=this.props.record
        //console.log(ejes);
        
            
    if(ejes){
            return(
                
                <React.Fragment style={{width: "100%"}}>
                   <Table aria-label="simple table" fullWidth  style={{width: "100%"}}>
                        <TableHead>
              <TableRow>
                <TableCell align="right" >Categoría</TableCell>
                <TableCell align="right" >Volumen</TableCell>
                <TableCell align="right" >Distribución porcentual</TableCell>
                <TableCell align="right" >Factor</TableCell>
                <TableCell align="right" >ESAL</TableCell>
              </TableRow>
            </TableHead>

                        <TableBody>
                        <TableRow > 
                       <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>Autos</h3><br/> 
                        </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span > {ejes.aut1} </span></TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth > <span>{parseFloat(ejes.aut1 / ejes.tpd * 100).toFixed(2).replace('.',',')}% </span></TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span > {parseFloat(ejes.aut2).toFixed(1).replace('.',',')} </span></TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span > {parseInt(ejes.aut1 * ejes.aut2)}</span></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>Buses</h3><br/>    
                        </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{ejes.bus1}</span></TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseFloat(ejes.bus1 / ejes.tpd * 100).toFixed(2).replace('.',',')}%</span></TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseFloat(ejes.bus2).toFixed(1).replace('.',',')}</span></TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth><span>{parseInt(ejes.bus1 * ejes.bus2)}</span></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>C<sub style={{fontSize: 'smaller'}}>2</sub>P</h3><br/>    
                        </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{ejes.c1volumen}</span> </TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth><span>{parseFloat(ejes.c1volumen / ejes.tpd * 100).toFixed(2).replace('.',',')}%</span></TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{parseFloat(ejes.c2factor).toFixed(1).replace('.',',')}</span> </TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{parseInt(ejes.c1volumen * ejes.c2factor)}</span></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>C<sub style={{fontSize: 'smaller'}}>2</sub>G</h3><br/>    
                        </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{ejes.cgvolumen}</span></TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseFloat(ejes.cgvolumen / ejes.tpd * 100).toFixed(2).replace('.',',')}%</span></TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{parseFloat(ejes.cgfactor).toFixed(1).replace('.',',')}</span></TableCell>  
                         <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseInt(ejes.cgvolumen * ejes.cgfactor)}</span></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>C<sub style={{fontSize: 'smaller'}}>3</sub>yC<sub style={{fontSize: 'smaller'}}>4</sub></h3><br/>    
                        </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{ejes.c3volumen}</span></TableCell> 
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseFloat(ejes.c3volumen / ejes.tpd * 100).toFixed(2).replace('.',',')}%</span></TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{parseFloat(ejes.c3factor).toFixed(1).replace('.',',')}</span></TableCell>   
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseInt(ejes.c3volumen * ejes.c3factor)}</span></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>C<sub style={{fontSize: 'smaller'}}>5</sub></h3><br/>    
                        </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{ejes.c5volumen}</span></TableCell>  
                         <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseFloat(ejes.c5volumen / ejes.tpd * 100).toFixed(2).replace('.',',')}%</span></TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{parseFloat(ejes.c5factor).toFixed(1).replace('.',',')}</span></TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseInt(ejes.c5volumen * ejes.c5factor)}</span></TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>>C<sub style={{fontSize: 'smaller'}}>5</sub></h3><br/>    
                        </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{ejes.mayorc5volumen}</span></TableCell>  
                    <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span> {parseFloat(ejes.mayorc5volumen / ejes.tpd * 100).toFixed(2).replace('.',',')}%</span> </TableCell>
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{parseFloat(ejes.mayorc5factor).toFixed(1).replace('.',',')}</span></TableCell>  
                        <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{parseInt(ejes.mayorc5volumen * ejes.mayorc5factor)}</span></TableCell>
                        
                    </TableRow>
                    <TableRow>
                    <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>Total</h3><br/> 
                        </TableCell>
                    <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{ejes.tpd}</span></TableCell>
            <TableCell  align="right" style={{textAlign:'right'}} fullWidth><span>{(ejes.aut1 / ejes.tpd * 100 + ejes.bus1 / ejes.tpd * 100 + ejes.c1volumen / ejes.tpd * 100 + ejes.cgvolumen / ejes.tpd * 100 + ejes.c3volumen / ejes.tpd * 100 + ejes.c5volumen / ejes.tpd * 100 + ejes.mayorc5volumen / ejes.tpd * 100).toFixed(1).replace('.',',')}%</span></TableCell>
                    <TableCell  align="right" style={{textAlign:'right'}} fullWidth><span>{parseFloat(ejes.aut2 + ejes.bus2 + ejes.c2factor + ejes.cgfactor + ejes.c3factor + ejes.c5factor + ejes.mayorc5factor).toFixed(2).replace('.',',')}</span></TableCell>
            <TableCell  align="right" style={{textAlign:'right'}} fullWidth><span>{parseInt(ejes.aut1 * ejes.aut2 + ejes.bus1 * ejes.bus2 + ejes.c1volumen * ejes.c2factor + ejes.cgvolumen * ejes.cgfactor + ejes.c3volumen * ejes.c3factor + ejes.c5volumen * ejes.c5factor + ejes.mayorc5volumen * ejes.mayorc5factor)}</span></TableCell>
                    </TableRow>
                    </TableBody>   
                        </Table>
                </React.Fragment>
             
            )

    }
    else{
        return (
            <React.Fragment></React.Fragment>
        )
    }
}
}

export default Ejes;
import React, {useState} from 'react';
import {
  withGoogleMap,
  withScriptjs,
  Marker,
  GoogleMap,
  Polygon,
  InfoWindow
} from "react-google-maps";
import config from '../config/config';
let urlAppjs = config.UrlFront;
// const { compose, withProps, withStateHandlers } = require("recompose");

export const MapWithAMarker = withScriptjs(withGoogleMap(

  props => {
    const {        
        ubicaciones,
    //   prf,
    //   url,
        defaultCenter,
    //   redirect,
    //   positions,
    //   info,
    //   demarcacion
        info
    } = props;

    
    
  const [ windows , setWindow] = useState(false); 
  const [ markerKey , setMarkerKey] = useState(''); 

  const ShowWindow = (i) =>{
    setWindow(true);
    setMarkerKey(i)
    // debugger
  }

  const HiddenWindow = () =>{
    setWindow(false);
  }
  let redirects = [];
//   let posicion1 = [ demarcacion[0] , demarcacion[1] ,demarcacion[2] ,demarcacion[3]]
  

  let pathCoordinates = '';
  if(info !== undefined)
  {
     pathCoordinates = info.map((mrk, i) => {     
      if(mrk.icon !== '/imagenes/senalesColombia/defecto')
      {
        return({            
          ubicaciones: mrk.ubicaciones,          
          elemento :  mrk.elemento,
          icon : new window.google.maps.MarkerImage(
            mrk.icon,
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new window.google.maps.Size(45, 45),            
          ),
          codigo : mrk.codigo,
          abInicio : mrk.abInicio,
          tipoElemento: mrk.tipoElemento,
          unidadFuncional : mrk.unidadFuncional,
          tramo: mrk.tramo,
          segmento: mrk.segmento,
        })
      }else{
        return({            
          ubicaciones: mrk.ubicaciones,          
          elemento :  mrk.elemento,
          icon : mrk.icon
        })
      }               
    })   
    
  }
  
  
  
    // let urlAppjs1 = urlAppjs + "elemento_senalizacion_perus/%2Felemento_senalizacion_perus%2F" ;

    const putMarkers = () => { 
      
      if(pathCoordinates !== undefined && pathCoordinates !== '')
      {
        return(          
          pathCoordinates.map((mrk, i) => {
              let abInicio = 'K'+mrk.abInicio;
              return(
                <div>
                  <Marker 
                      key={`mrk${i}`}
                      position={mrk.ubicaciones}
                      // icon={false}
                      icon={mrk.icon}
                     onClick={ ()=> {ShowWindow(i)} }
                  >
                  
                  {windows && markerKey === i &&
                  <div id='mapaProyect'>
                    
                   <InfoWindow key={`mrk${i}`} id='mapaProyect' className='mapeos' onCloseClick={HiddenWindow}> 
                      <div >
                        {/* <h3>{mrk.nombre.nom}</h3> */}
                        <p><strong>Código: </strong>{mrk.codigo}</p>
                        <p><strong>Elemento: </strong>{mrk.elemento}</p>
                        <p><strong>Unidad Funcional: </strong>{mrk.unidadFuncional}</p>
                        <p><strong>Tramo: </strong>{mrk.tramo}</p>
                        <p><strong>Segmento: </strong>{mrk.segmento}</p>
                        <p><strong>Abscisa Inicio: </strong>{mrk.abInicio}</p>
                        
                        {/* <a href={urlAppjs1 + redirect[i] + '/show'} target="_blank">Ver más...</a> */}
                      </div>                                                
                    </InfoWindow> 
                    </div>
                    
                    }
                    
                  </Marker>                                        
                </div>
              )
              
          })
      )

      }else{
        
        return(
        <Marker 
          key={`mrk`}
        // position={mrk.position}
          icon={false}
          // onClick={ ()=> {ShowWindow(i)} }
        />
        )
      }
        
    }

    return (
        <GoogleMap
          defaultZoom={9}
          defaultCenter={defaultCenter}
        >
        
          {/* <Polygon
            path={posicion1}
            key={1}
            options={{
                fillColor: "#000",
                fillOpacity: 0.2,
                strokeColor: "#000",
                strokeOpacity: 1, 
                strokeWeight: 1
            }}
          /> */}
          
          {putMarkers()}
        </GoogleMap>
        )

  }
  ));

  export default MapWithAMarker;
  
// class MapWithAMarker1 extends React.Component{
// 	state = {
//     position : null,
//     positions : []
// 	}

// 	handleToggleOpen(item){
// 		this.setState({
// 			position : {
// 				lat : item.lat,
// 				lng : item.lng
// 			}			
// 		})
//   }
  
    

// 	render(){  
// 		return (
//       <GoogleMap
//         defaultZoom={12}
//         defaultCenter={ {lat: 4.756704, lng: -74.031106}}
//       >
// 				{this.props.positions.map((item , i)  => ( 
// 					<Marker
//               key={`mrk${i}`}
// 					    position={item}
// 					    onClick={() => this.handleToggleOpen(item)}
// 					/>
// 				))}				
// 				{this.state.position && 
// 					 <InfoWindow position={this.state.position}>
// 					     <h1>{this.props.location.venue.name}</h1>
// 					 </InfoWindow>
// 				 }
// 			</GoogleMap>
// 		)
// 	}	
// }



//   export const MapWithAMarker = withScriptjs(withGoogleMap( 
    
//       <MapWithAMarker1/>
    

//   ))
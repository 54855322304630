import React from 'react';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import config from './config/config';
let urlAppjs = config.UrlServer;


class PimsAuthProvider extends React.Component{
    state = {
        url: null,
        //user: ''
    };
    constructor(props){
        super(props);
        this.state.url      = props.url;
        this.handleEvent    = this.handleEvent.bind(this);
        this.login          = this.login.bind(this);
        this.logout         = this.logout.bind(this);
        this.error          = this.error.bind(this);
        this.check          = this.check.bind(this);
        this.getPermissions = this.getPermissions.bind(this);

    }
    handleEvent(type, params){
        switch (type) {
            case AUTH_LOGIN: return this.login(params);
            case AUTH_LOGOUT: return this.logout(params);
            case AUTH_ERROR: return this.error(params);
            case AUTH_CHECK: return this.check(params);
            case AUTH_GET_PERMISSIONS: return this.getPermissions(params);
            default: return Promise.reject();
        }
    }
    login(params){
        
        document.getElementById('btn-login').style.display = 'none';
        document.getElementById('loader-btn-login').style.display = 'block';
        const { username, password } = params;
        const request = new Request(
            this.state.url+'/authentication_token', {
                    method: 'POST',
                    body: JSON.stringify({ username, password }),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                }
                
            );
        return fetch(request)
            .then(response => {
                    if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
                    return response.json();
                }
            )
            .then(({ token, user }) => {
                    const permisos = JSON.parse(user);  
                    const user2 = permisos['admin']['id'];
                    const user3 = permisos['username'];
                           
                    fetch(urlAppjs+"/atributos/"+user2+".json")
                    .then(function(response) {
                        return response.json();
                    })
                    .then(function(myJson) {
                        
                        const user1 =myJson;
                        
                        const usuarios=user1['usuario'];
                        const perfiles=user1['perfiles'];
                        const asigclaves=user1['asigclaves'];
                        const proyecto=user1['proyecto'];
                        const rutas=user1['rutas'];
                        const unifuncionales=user1['unifuncionales'];
                        const tramos=user1['tramos'];
                        const segmento=user1['segmento'];
                        const contemergencia=user1['contemergencia'];
                        const proveedores=user1['proveedores'];
                        const serpublicos=user1['serpublicos'];
                        const materiales=user1['materiales'];
                        
                        localStorage.setItem('ucalpro',user3);
                        localStorage.setItem('proveedores',proveedores);
                        localStorage.setItem('usuarios',usuarios);
                        localStorage.setItem('perfiles',perfiles);
                        localStorage.setItem('asigclaves',asigclaves);
                        localStorage.setItem('proyecto',proyecto);
                        localStorage.setItem('rutas',rutas);
                        localStorage.setItem('unifuncionales',unifuncionales);
                        localStorage.setItem('tramos',tramos);
                        localStorage.setItem('contemergencia',contemergencia);
                        localStorage.setItem('serpublicos',serpublicos);
                        localStorage.setItem('segmento',segmento);     
                        localStorage.setItem('materiales',materiales);     
                        localStorage.setItem('atributos_valores',Object.values(user1));     
                        localStorage.setItem('atributos_keys',Object.keys(user1));     
                        localStorage.removeItem('msj-login-error');
                        localStorage.removeItem('msj-login-error-msj');                   
                        
                    });
                    localStorage.setItem('token', token);
                    this.getPermissions(permisos['admin']);
                    // this.setState({user: JSON.parse(user)});
                    return Promise.resolve(user);
                   
                }
                
            ).catch(function(error) {
                
                localStorage.setItem('msj-login-error',true);
                console.log(error)
                // MENSAJES DE ERROR
                if(error.message === 'Unauthorized'){
                    localStorage.setItem('msj-login-error-msj','usuario y/o contraseña incorrecta');                                          
                }

                if(error.message === 'Internal Server Error'){
                    localStorage.setItem('msj-login-error-msj','Hubo un problema con la petición, por favor intente nuevamente');                                          
                }
                
                if(error.message === 'Failed to fetch'){
                    localStorage.setItem('msj-login-error-msj','Hubo un problema con la petición, por favor intente nuevamente');                                          
                }

                
                document.getElementById('loader-btn-login').style.display = 'none';
                document.getElementById('btn-login').style.display = 'block';
                document.getElementById('content-msj').style.display = 'block';                                
                
            });      
    }
    logout(params){
        localStorage.removeItem('token');
        localStorage.removeItem('usuarios');
        localStorage.removeItem('perfiles');
        localStorage.removeItem('asigclaves');
        localStorage.removeItem('proyecto');
        localStorage.removeItem('rutas');
        localStorage.removeItem('unifuncionales');
        localStorage.removeItem('tramos');
        localStorage.removeItem('contemergencia');
        localStorage.removeItem('serpublicos');
        localStorage.removeItem('segmento');
        localStorage.removeItem('proveedores');
        localStorage.removeItem('proyectoId');
        localStorage.removeItem('id_project');
        localStorage.removeItem('ucalpro');
        localStorage.removeItem('materiales');
        localStorage.removeItem('atributos_valores');
        localStorage.removeItem('atributos_keys');
        localStorage.removeItem('codigoProyecto');
        localStorage.removeItem('nombreProyecto');

        // this.setState({user: null});
        return Promise.resolve();
    }
    error(params){
        console.log("error en AuthProvider", params)
        console.log("hasResponseCode? ", params.hasOwnProperty("response"))
        if(params.hasOwnProperty("response")){
            const status  = params.response.status;
            if (status === 401 || status === 403) {
                let actualDir = window.location.href.split("#")[1]
                if(actualDir != "/login") {
                    this.logout(params)
                    window.location.reload(true);
                }
            }
        }
    }

    check(params){        

        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
        
    }
    getPermissions(params){

        // if(this.state.user){
        //     console.log("Los permisos son... "+this.state.user.roles);
        //     return Promise.resolve(this.state.user.roles);
        // }else{
        //     return Promise.reject(params); 
        // },
        //  let prueba = params[];
        const object1  = params;
        var x=Object.values(object1);
        var primero = x[0];
        
         return "hola mundo"+primero[0];
        
    }

}

export default PimsAuthProvider;


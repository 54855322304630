let urlser = window.location.hostname;
let urlAppjs = "https://dynatest-backend.soluciones.software";
let urlAppFront = "https://dynatest.soluciones.software/#/";
let country = "CO";
switch (urlser) {
  case 'localhost':
    urlAppjs = "http://localhost:8000";
    // GET PORT FRONT
    let port = window.location.port;
    urlAppFront = "http://localhost:"+port+"/#/";
    country = "CO";
break;
  case 'pims.soluciones.software':
    urlAppjs = "https://pims-backend.soluciones.software";
    urlAppFront = "https://pims.soluciones.software/#/";
    country = "PE";
    break;
  case 'iris-pe.soluciones.software':
    urlAppjs = "https://iris-pe-be.soluciones.software";
    urlAppFront = "https://iris-pe.soluciones.software/#/";
    country = "PE";
    break;
  case 'iris-co.soluciones.software':
    urlAppjs = "http://iris-co-be.soluciones.software";
    urlAppFront = "http://iris-co.soluciones.software/#/";
    country = "CO";
    break;
  case 'iris-test.soluciones.software':
    urlAppjs = "http://iris-test-be.soluciones.software";
    urlAppFront = "http://iris-test.soluciones.software/#/";
    country = "CO";
    break;
}

const config = {
    UrlServer: urlAppjs,
    UrlFront: urlAppFront,
    Country: country
}

export default config;

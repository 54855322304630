import React, {useState} from 'react';
import { SelectInput,Filter,ReferenceInput,List, Datagrid, TextField, EditButton, ShowButton, AutocompleteInput,
         ReferenceField, BooleanField } 
         from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import { PRField } from './../../fields/PRField';
import Button from '@material-ui/core/Button';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const FiltroSeguridad= (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' 
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <SelectInput 
            label="Elementos" 
            source="tipoElementoDesc"  
            sort={{field : 'name' , order :'ASC'}} 
            alwaysOn 
            resettable
            choices={[
                {id : 'Barrera de concreto de pendiente simple' , name : 'Barrera de concreto de pendiente simple '},
                {id : 'Barrera de perfil F' ,                     name : 'Barrera de perfil F '},
                {id : 'Barrera Jersey' ,                          name : 'Barrera Jersey '},
                {id : 'Bordillo' ,                                name : 'Bordillo '},
                {id : 'Captafaros' ,                              name : 'Captafaros '},
                {id : 'Cerramiento provisional' ,                 name : 'Cerramiento provisional '},
                {id : 'Defensa' ,                                 name : 'Defensa '},
                {id : 'Pretiles de Puentes' ,                     name : 'Pretiles de Puentes '},
                {id : 'Tope Vehicular' ,                          name : 'Tope Vehicular  '},   
            ]}
        />
        <SelectInput label="Estado" source="estado" alwaysOn sort={{field : 'name' , order :'ASC'}}
                                resettable  choices={[
                                { id: "Activo", name: 'Activo' },
                                { id: "Inactivo", name: 'Inactivo' },
                            ]} fullWidth
                            
                            />
        {/* <SelectInput 
            fullWidth 
            label="Status" 
            source="status"   
            alwaysOn  
            choices={[
                {id : true , name : 'Activo'},
                {id : false , name : 'Inactivo'}
            ]}
        /> */}
        
    </Filter>
    )
};

export const SeguridadList = props => {

return(
    <List title="Lista Sistemas de Contención Vehicular " {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={<FiltroSeguridad/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
            <TextField label="Código del elemento" source="smartCode" sortBy="smartCode"/>
            <TextField label="Desc. de Elementos" fullWidth source="tipoElementoDesc" sortBy="tipoElementoDesc"/>
            <PRField label="Progresiva" fullWidth source="abInicio"/>
            <TextField fullWidth label="Margen" source="margen"/>
            <TextField fullWidth label="Estado" source="estado" />
            {/* <BooleanField label="¿Activo?" source="status"/> */}
        <ShowButton />
        <EditButton />  
               
        </Datagrid>
    </List>
)};
import React ,{useState} from 'react';
import {  TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { Edit } from './../Edit';
import {ValorMaximoPrEditFin, ValorMaximoPrEditInicio , validarLogicaPrFinEdit} from './../validacionesInputs'
import { PInput } from './../../inputs/PInput';
import Divider from '@material-ui/core/Divider';
import {required,minLength,maxLength} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { SimpleForm } from './../SimpleForm';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

export const RutaEdit = props => {
    const  [acceso, setAcceso] = useState(0);

const [codigoIngreso , setCodigoIgreso] =useState('')
const  [pInicio, setPinicio] = useState('');
const  [pFin, setPfin] = useState('');
const  [prInicio, setPrinicio] = useState('');
const  [prFin, setPrfin] = useState('');

const ErrorRuta = (value) =>{    
    setCodigoIgreso(value)        
}   




const PrInicial = () =>{
    let value = document.getElementById('ingresoPrInicio');
    if(value){
        value = value.value
        value = value.split('PR'); 
        value =value[1].split('+');
        value = value[0] +'.'+ value[1];    
        setPinicio(value);
        
    }  
}

const PrFinal = () =>{
       
}

const CodigoRuta = () =>{
    
    if(codigoIngreso !== '' && acceso === 0){
        fetch(urlAppjs+"/CodigoRutaEdit/"+codigoIngreso)
        .then(function(response){
            return response.json()
        })
        .then(function(myJson){
            
            let prInicio=myJson[0]
            let prFin=myJson[0]
            
            if(prInicio !== undefined){
                
                prInicio=prInicio['pFin']
                // prFin=prFin['pFin']
                setPrinicio(prInicio)
                // setPrfin(prFin)                
            }                           
            // if(codigoIngreso !== 0 && prInicio > prFin ){
            //     setAcceso(0)
                
            // }else if(codigoIngreso !== 0 && prInicio <= prFin && prInicio !== null ){                
                setAcceso(1)
                
            // }else if((prInicio === null && prFin === null) || (prInicio === null)){
            //     setAcceso(0)
               
            // } 
            
        })
    }   
}

const Validacion = () =>{
    if(pInicio < prInicio){
        return 'Debe ser mayor a ' + prInicio;
    }
}
    return(
    <Edit title="Editar Ruta" {...props} >
        <SimpleForm submitOnEnter={false} redirect="list">
            <Grid container spacing={24}>                
                <Grid item xs={12}>
                    <ReferenceInput label="Proyecto" source="proyecto" disabled reference="proyectos" validate={[required()]}>
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid>                
                <Grid item xs={6}>
                    <TextInput label="Ruta" source="codigo" validate={[ErrorRuta, CodigoRuta]} fullWidth id="codigo" fullWidth disabled/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput label="Categoría" source="categoriaRuta" choices={[
                            { id: 1, name: 'Primer orden' },
                            { id: 2, name: 'Segundo orden' },
                            { id: 3, name: 'Tercer orden' },
                        ]}  fullWidth validate={[required()]}                   
                    />
                </Grid>                
                <Grid item xs={6}>
                    <PInput label="PR inicio" id="ingresoPrInicio" source="pInicio" fullWidth validate={[required(), ValorMaximoPrEditInicio]} onChange={PrInicial}/>
                </Grid>
                <Grid item xs={6}>
                    <PInput label="PR fin" id="ingresoPrFin" source="pFin" fullWidth validate={[required(),ValorMaximoPrEditFin, validarLogicaPrFinEdit]} />
                </Grid>
                <Grid item xs={12}>                    
                    <b>Informacion MTC</b>                  
                </Grid>
                <Grid item xs={12}>
                    <Divider />                    
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="tramo"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Provincia" source="sector"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="origen"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="destino" fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
)};
import React from 'react';
import { Datagrid,Toolbar,WithPermissions,ListButton, ArrayField, EditButton,  TextField, DateField, 
    CardActions,TabbedForm,FormTab, NumberField
} from 'react-admin';
import { Show } from './../Show';
import { GMapInput } from '../../inputs/GMapInput';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import Typography from '@material-ui/core/Typography';
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const PostActions =({basePath,data,
})=>{
var proyecto1 = localStorage.getItem("proyecto");
return(
        <WithPermissions
        render={({ permissions }) => (
            "1" === proyecto1   ||    "2" === proyecto1           
    ? 
    <CardActions style={cardActionStyle}>
        
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
        {/* Add your custom actions
        <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
    

    :  <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} record={data} />
        </CardActions>
    )}
    />
    
)
};
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar>
));

export const ProyectoShow = withStyles(styles)(({ classes, ...props }) => (
    <Show title="Información Proyecto" actions={<PostActions/>} {...props}>
        <TabbedForm toolbar={<CustomToolbar />}>
            <FormTab label="Información básica">
                <TextField label="Código Proyecto" source="codigo"fullWidth formClassName={classes.grid_cont4} />
                <TextField source="nombre" fullWidth formClassName={classes.grid_cont4}/>
                <TextField source="concesionario" fullWidth formClassName={classes.grid_cont4}/>
                
                <Typography variant="h3" component="h3">Coordenadas mínimas</Typography>

                <Divider fullWidth style={{marginTop : '10px'}}/>
                    <NumberField label="Latitud" source="latitud1" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>            
                    <NumberField  label="Longitud" source="longitud1" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                        
                      <div></div>
                    <Typography variant="h3" component="h3">Coordenadas máximas</Typography>
                    <Divider fullWidth style={{marginTop : '10px'}} />
                    
                    <NumberField label="Latitud" source="latitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/> 
                    <NumberField label="Longitud" source="longitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>    
                    <div></div> 
                    <Typography variant="h3" component="h3">Altura</Typography>
                    <Divider fullWidth  style={{marginTop : '10px'}}/>    
                    <NumberField  label="Altura mínima (m.s.n.m)" source="alturaIni" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/> 
                    <NumberField  label="Altura máxima (m.s.n.m)" source="alturaFin" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>  

                    <GMapInput
                        defaultZoom={8}                                    
                        defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                        source="ubicaciones"
                        multipleMarkers="true"
                        googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                        icon={true}    
                    /> 
            </FormTab>
            <FormTab label="Auditoría" >
                <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4}/> 
                <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4}/>
                <span></span>               
                <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4}/>
                <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4}/>
                <span></span>   
            </FormTab>
        </TabbedForm>
    </Show>
));
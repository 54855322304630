import React from 'react';
import { WithPermissions,ListButton, TabbedShowLayout, EditButton, Tab, TextField, DateField, CardActions, NumberField 
} from 'react-admin';
import { Show } from './../Show';
import { PRField } from './../../fields/PRField';

import Divider from '@material-ui/core/Divider';
import { PField } from './../../fields/PField';




const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const PostActions =({basePath,data,
    currentSort,    
    exporter,    
    filterValues,   
    resource,    
    total})=>{
var tramos = localStorage.getItem("tramos");
return(
        <WithPermissions
        render={({ permissions }) => (
            "1" === tramos   ||    "2" === tramos           
    ? 
    <CardActions style={cardActionStyle}>
        
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
        {/* Add your custom actions
        <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
    

    :  <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} record={data} />
        </CardActions>
    )}
    />
    
)
};

export const TramoShow = props => (
    <Show title="Información Tramo" actions={<PostActions/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Información básica">
                <TextField label="Código Tramo" source="fullCode" /> 
                <span></span>     
                <span></span>                               
                <PRField label="Progresiva Inicio" source="abInicio"/>
                <PRField label="Progresiva Fin" source="abFin"/>
                <NumberField label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
                <PField label="PR inicio" source="pInicio"/>
                <PField label="PR fin" source="pFin"/>
                <span></span> 
                <b>Información  Tramo </b> 
                <span></span>     
                <span></span>                
                <Divider /> 
                <Divider />
                <Divider />
                <TextField source="inicio" />
                <TextField source="final" />
                <span></span> 
            </Tab>
            <Tab label="AuditorÍa">
            <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <span></span>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <span></span>    
            </Tab>
        </TabbedShowLayout>
    </Show>
);
import React  from 'react';
import config from './config/config';

let urlAppjs = config.UrlServer;


/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    
    let conteo = file.length;
    for (var i = 0; i < conteo; i++) {
        
    const reader = new FileReader();
    reader.readAsDataURL(file[i].rawFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    }

});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {

    // if ((type === 'CREATE' || type ==='UPDATE' ) && resource === 'modelo_costos_pers' ) {
    //     let arrayConcatenado = params.data.calculoFlex.concat(params.data.calculoRigido);
    //     debugger
    //     return requestHandler(type, resource, {
    //         ...params,
    //         data: {
    //             ...params.data, 
    //             calculo: [...params.data.calculoFlex, ...params.data.calculoRigido],
    //         },
    //     }) 
    // }

    if ((type === 'CREATE' ) && params.data.images ) {
        // VALIDACIÖN PARA LA CARGA DE IMAGENES
        //------------------------------------------>   
        // if (params.data.images[0] && params.data.images.length) {
            // only freshly dropped pictures are instance of File
            let urlAppjs = config.UrlServer;
            let conteo = params.data.images.length;
            let formerPictures1 = [];
            let url = [];
            let newPictures = []
            let tipoImagen = [];
            // let tipoImagenA = [];
            
            
                for (var i = 0; i < conteo; i++) { 
                    
                    if(params.data.images[i].images){
                        newPictures.push(params.data.images[i].images.filter(p => p.rawFile instanceof File))
                        tipoImagen.push(params.data.images[i].tipoImagen);    
                        url.push(params.data.images[i].url);                        
                        const formerPictures = params.data.images[i].images.filter(p => !(p.rawFile instanceof File)); 
                        
                        if(formerPictures[i])  
                        {
                            let src = {
                                src : formerPictures[i].src
                            }
                            formerPictures1.push(src)
                        }
                        // debugger
                    }else{
                        // tipoImagen.push(params.data.images[i].tipoImagen); 
                        // const formerPictures = params.data.imagesA[i].images.filter(p => !(p.rawFile instanceof File)); 
                        
                            let src = {
                                src : 'HTTTP:PORDEFECTO,',
                                tipoImagen : params.data.images[i].tipoImagen,
                                elemento : resource,
                                url : urlAppjs
                            }
                            formerPictures1.push(src)
                        // debugger
                    }
                                                                    
                }
                
                let reparaciones=[];
                if(resource === 'puentes'  ){
                    for(let i=0; i<17; i++){
                        reparaciones[i]={tipoReparacion: params.data['tipoReparacion_'+i], aplica: params.data['aplica_'+i], date: params.data['date_'+i], tipo: params.data['tipo_'+i], cant: params.data['cant_'+i], years: params.data['years_'+i], precio: params.data['precio_'+i]}
                    }
                }
                
            
            return Promise.all(newPictures.map(convertFileToBase64))
                .then(base64Pictures => base64Pictures.map((picture64, index) => ({
                    
                    src: picture64,
                    image: picture64,
                    elemento: resource,
                    tipoImagen: tipoImagen[index],
                    url : urlAppjs,
                })))
                
                .then(transformedNewPictures => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        images: [...transformedNewPictures, ...formerPictures1],
                        reparaciones : reparaciones
                    },
                }));
        // } 
    }

    // if ((type === 'GET' ) &&  resource ==='segmentos' ) {
    //     // VALIDACIÖN PARA LA CARGA DE IMAGENES
    //     //------------------------------------------>   
    //     // if (params.data.images[0] && params.data.images.length) {
    //         // only freshly dropped pictures are instance of File
    //         let conteo = params;
    //         debugger
    // }
    // if ((type === 'UPDATE') && params.data.imagess ) {
    //     debugger
    // }

    if ((type === 'UPDATE') && params.data.imagess ) {
        // debugger
        // VALIDACIÖN PARA LA CARGA DE IMAGENES
        //------------------------------------------>   
        // if (params.data.images[0] && params.data.images.length) {
            // only freshly dropped pictures are instance of File
            let urlAppjs = config.UrlServer;
            let conteo = params.data.imagess.length;
            let url = [];
            let formerPictures1 = []
            let newPictures = []
            let tipoImagen = [];
                for (var i = 0; i < conteo; i++) { 
                    if(params.data.imagess[i].images)
                    {
                        url.push(params.data.imagess[i].url);
                        newPictures.push(params.data.imagess[i].images.filter(p => p.rawFile instanceof File))
                        tipoImagen.push(params.data.imagess[i].tipoImagen);
                    }else{
                            
                            const formerPictures = params.data.imagess.filter(p => !(p.rawFile instanceof File)); 
                            if(formerPictures[i])  
                            {
                                let src = {
                                    src : formerPictures[i].src,
                                    tipoImagen : formerPictures[i].tipoImagen,
                                    elemento : resource,
                                    url : urlAppjs,
                                   
                                }
                
                                formerPictures1.push(src)

                            }   
                    }                        
                                                                 
                }
            
                let reparaciones=[];
                if(resource === 'puentes'  ){
                    for(let i=0; i<17; i++){
                        reparaciones[i]={tipoReparacion: params.data['tipoReparacion_'+i], aplica: params.data['aplica_'+i], date: params.data['date_'+i], tipo: params.data['tipo_'+i], cant: params.data['cant_'+i], years: params.data['years_'+i], precio: params.data['precio_'+i]}
                    }
                }
            
            return Promise.all(newPictures.map(convertFileToBase64))
                .then(base64Pictures => base64Pictures.map((picture64, index) => ({
                    
                    src: picture64,
                    image: picture64,
                    elemento: resource,
                    tipoImagen: tipoImagen[index],
                    url : urlAppjs,
                    
                })))
                
                .then(transformedNewPictures => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        images: [...transformedNewPictures, ...formerPictures1],
                        reparaciones : reparaciones
                    },
                }));
        // }
    } else if ((type === 'UPDATE') && params.data.images ) {
        // debugger
        // VALIDACIÖN PARA LA CARGA DE IMAGENES
        //------------------------------------------>   
        // if (params.data.images[0] && params.data.images.length) {
            // only freshly dropped pictures are instance of File
            let urlAppjs = config.UrlServer;
            let conteo = params.data.images.length;
            let url = [];
            let formerPictures1 = []
            let newPictures = []
            let tipoImagen = [];
            
            
                for (var i = 0; i < conteo; i++) { 
                    if(params.data.images[i].images)
                    {
                        url.push(params.data.images[i].url);
                        newPictures.push(params.data.images[i].images.filter(p => p.rawFile instanceof File))
                        tipoImagen.push(params.data.images[i].tipoImagen);
                    }else{
                            
                            const formerPictures = params.data.images.filter(p => !(p.rawFile instanceof File)); 
                            if(formerPictures[i])  
                            {
                                let src = {
                                    src : formerPictures[i].src,
                                    tipoImagen : formerPictures[i].tipoImagen,
                                    elemento : resource,
                                    url : urlAppjs,
                                   
                                }
                
                                formerPictures1.push(src)

                            }   
                    }                        
                                                                 
                }
            
                let reparaciones=[];
                if(resource === 'puentes'  ){
                    for(let i=0; i<17; i++){
                        reparaciones[i]={tipoReparacion: params.data['tipoReparacion_'+i], aplica: params.data['aplica_'+i], date: params.data['date_'+i], tipo: params.data['tipo_'+i], cant: params.data['cant_'+i], years: params.data['years_'+i], precio: params.data['precio_'+i]}
                    }
                }
            
            return Promise.all(newPictures.map(convertFileToBase64))
                .then(base64Pictures => base64Pictures.map((picture64, index) => ({
                    
                    src: picture64,
                    image: picture64,
                    elemento: resource,
                    tipoImagen: tipoImagen[index],
                    url : urlAppjs,
                    
                })))
                
                .then(transformedNewPictures => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        images: [...transformedNewPictures, ...formerPictures1],
                        reparaciones : reparaciones
                    },
                }));
        // }
    } 
    
    

    // //VALIDACION PARA FILTROSS MULTIPLES
    // if (type === 'GET_LIST' && resource === 'elemento_senalizacion_perus') {
    //     // VALIDACIÖN PARA LA CARGA DE IMAGENES
    //     //------------------------------------------>   
    //         let filter = [];
    //         let conteo1 = params;


    //         if(conteo1.filter.unidadFuncional !== undefined)
    //         {
                
    //             conteo1.filter.unidadFuncional.map(item =>{
    //                 let fil = {
    //                     unidadFuncional: item
    //                 }
    //                 filter.push(fil)
                    
    //             })                
    //         }
    //         if(conteo1.filter.tramo !== undefined)
    //         {
    //             conteo1.filter.tramo.map(item =>{
    //                 let filU = {
    //                     tramo: item
    //                 }
    //                 filter.push(filU)
                    
    //             })                
    //         }
    //         if(conteo1.filter.segmento !== undefined)
    //         {   
                
    //             conteo1.filter.segmento.map(item =>{
    //                 let filT = {
    //                     segmento: item
    //                 }
    //                 filter.push(filT)
                    
    //             })                
    //         }

            
            
    //         if(filter !== undefined && filter !== '' && filter && filter !== null)
    //         {   
    //             return requestHandler(type, resource, {
    //                 // 'unidades', 'unidades1', 'unidades2 ',
    //                 ...params,
    //                 filter: {                        
    //                     filter: filter,                       
    //                 },
    //             })
    //         }else{
                
    //             return requestHandler(type, resource, {
    //                 // 'unidades', 'unidades1', 'unidades2 ',
    //                 ...params,
    //                 filter: {                        
    //                     ...params,
    //                 },
    //             })
    //         }
              
    // }


    // VALIDACIÓN PARA ACTUALIZAR LOS MUNICIPIOS
    //---------------------------------------------->
    if (type === 'UPDATE' && params.data.municipioArray) {
        
        const Municipios = params.data.municipioArray;

        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,
                municipio: Municipios,
            },
        })        

    }

    if (type === 'CREATE' && (resource === 'contacto_emergencias' || resource === 'proveedors' || resource === 'servicios_publicos')) {

        const departamento = params.data.departamento;
        const sistemaNombre = params.data.sistemaNombre    
        let Municipios;
        
        if(departamento === '/departamentos/05') { Municipios = params.data.municipio1}
        if(departamento === '/departamentos/08') { Municipios = params.data.municipio2}
        if(departamento === '/departamentos/11') { Municipios = params.data.municipio3}
        if(departamento === '/departamentos/13') { Municipios = params.data.municipio4}
        if(departamento === '/departamentos/15') { Municipios = params.data.municipio5}
        if(departamento === '/departamentos/17') { Municipios = params.data.municipio6}
        if(departamento === '/departamentos/18') { Municipios = params.data.municipio7}
        if(departamento === '/departamentos/19') { Municipios = params.data.municipio8}
        if(departamento === '/departamentos/20') { Municipios = params.data.municipio9}
        if(departamento === '/departamentos/23') { Municipios = params.data.municipio10}
        if(departamento === '/departamentos/25') { Municipios = params.data.municipio11}
        if(departamento === '/departamentos/27') { Municipios = params.data.municipio12}
        if(departamento === '/departamentos/41') { Municipios = params.data.municipio13}
        if(departamento === '/departamentos/44') { Municipios = params.data.municipio14}
        if(departamento === '/departamentos/47') { Municipios = params.data.municipio15}
        if(departamento === '/departamentos/50') { Municipios = params.data.municipio16}
        if(departamento === '/departamentos/52') { Municipios = params.data.municipio17}
        if(departamento === '/departamentos/54') { Municipios = params.data.municipio18}
        if(departamento === '/departamentos/63') { Municipios = params.data.municipio19}
        if(departamento === '/departamentos/66') { Municipios = params.data.municipio20}
        if(departamento === '/departamentos/68') { Municipios = params.data.municipio21}
        if(departamento === '/departamentos/70') { Municipios = params.data.municipio22}
        if(departamento === '/departamentos/73') { Municipios = params.data.municipio23}
        if(departamento === '/departamentos/76') { Municipios = params.data.municipio24}
        if(departamento === '/departamentos/81') { Municipios = params.data.municipio25}
        if(departamento === '/departamentos/85') { Municipios = params.data.municipio26}
        if(departamento === '/departamentos/86') { Municipios = params.data.municipio27}
        if(departamento === '/departamentos/88') { Municipios = params.data.municipio28}
        if(departamento === '/departamentos/91') { Municipios = params.data.municipio29}
        if(departamento === '/departamentos/94') { Municipios = params.data.municipio30}
        if(departamento === '/departamentos/95') { Municipios = params.data.municipio31}
        if(departamento === '/departamentos/97') { Municipios = params.data.municipio32}
        if(departamento === '/departamentos/99') { Municipios = params.data.municipio33}

        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,
                municipio: Municipios,
                nombreSistema: sistemaNombre,
            },
        }) 

    }


    // AGREGAR INFORMACION LISTAR INVENTARIOS SEGMENTO
    //-------------------------------------------------->
    if ((type === 'CREATE' || type === 'UPDATE') && params.data.detallesSegmentos) {
        
        const datos = params.data.detallesSegmentos;        
        const esal1 = datos[0].esal_time;
        const esal2 = datos[0].esal_time_10;
        const nivel = datos[0].nivel_trafico;
        const date = datos[0].date_inicio_operacion;
        
        // 
        
        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                esal_time: esal1,
                esal_time_10: esal2,
                nivel_trafico: nivel,
                date_inicio_operacion: date,
            },
        })        

    }

    

    //RECODIFICACION DE DATOS PARA EVALUACION SEGURIDAD VIAL
    //--------------------------------------------------------->
    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'evaluacion_seguridads') {

        const svGrupo = [];

        //DATOS DEL GRUPO 1
        if(params.data.gp1_length){
            let dato1 = {
                preguntas: "/preguntas/1",
                calificacion: params.data.condicion_decoloracion,
            }
            let dato2 = {
                preguntas: "/preguntas/2",
                calificacion: params.data.condicion_retroreflectividad,
            }
            svGrupo.push(dato1);
            svGrupo.push(dato2);
            for (let index = 2; index < params.data.gp1_length; index++) {
            
                let dato = {
                    preguntas: params.data['gp1_id_'+index],
                    calificacion: params.data['gp1_res_'+index],
                }

                svGrupo.push(dato);
                
            }
        }

        //DATOS DEL GRUPO 2
        if(params.data.gp2_length){
            for (let index = 0; index < params.data.gp2_length; index++) {
            
                let dato = {
                    preguntas: params.data['gp2_id_'+index],
                    calificacion: params.data['gp2_res_'+index],
                }

                svGrupo.push(dato);
                
            }
        
        }

        //DATOS DEL GRUPO 3
        if(params.data.gp3_length){
            for (let index = 0; index < params.data.gp3_length; index++) {
            
                let dato = {
                    preguntas: params.data['gp3_id_'+index],
                    calificacion: params.data['gp3_res_'+index],
                }

                svGrupo.push(dato);
                
            }
        }

        const svColorRetroreflectividad = [];
        
        //DATOS RETROREFLECTIVIDAD
        if(params.data.rt_length){
            for (let index = 0; index < params.data.rt_length; index++) {
                let valorretro;
                if(params.data['rt_color_'+index]==="-"){
                    valorretro=-1;
                }
                else{
                    if(params.data['rt_color_'+index] && typeof(params.data['rt_color_'+index])==="string"){
                    valorretro=parseInt(params.data['rt_color_'+index])
                    }
                    else{
                    valorretro=params.data['rt_color_'+index]
                    }
                }
                let datoR = {
                    colores: params.data['rt_color_id_'+index],
                    valor: valorretro,
                }
               
                svColorRetroreflectividad.push(datoR);                
            }
        }

        const svColorDecoloracion = [];
        
        //DATOS DECOLORACION
        if(params.data.dec_length){
            for (let index = 0; index < params.data.dec_length; index++) {
                let vx, vy;
                if(params.data['dec_colorx_'+index]==="-"){
                    vx=-1;
                }
                else{
                    if(typeof(params.data['dec_colorx_'+index])==="string"){
                    vx=parseFloat(params.data['dec_colorx_'+index].replace(',', '.'))
                    }
                    else{
                    vx=params.data['dec_colorx_'+index]
                    }
                }
                if(params.data['dec_colory_'+index]==="-"){
                    vy=-1;
                }
                else{
                    if(typeof(params.data['dec_colory_'+index])==="string"){
                    vy=parseFloat(params.data['dec_colory_'+index].replace(',', '.'))
                    }
                    else{
                    vy=params.data['dec_colory_'+index]
                    }
                }
                let datoD = {
                    colores: params.data['dec_color_id_'+index],
                    valorx: vx,
                    valory: vy
                }
               
                svColorDecoloracion.push(datoD);                
            }
        }
        let global="";
        //if(params.data.medicionGlobal && (params.data.medicionGlobal!="Cumple" && params.data.medicionGlobal!="No Cumple")){
        if(params.data.medicion_global && params.data.medicion_global!=""){            
            global=params.data.medicion_global;
        }
        // else if(params.data.calificacionG1d==="Cumple" && params.data.calificacionG2d==="Cumple" && params.data.calificacionG3d==="Cumple" && params.data.condicion_retroreflectividad==="Cumple" && params.data.condicion_decoloracion==="Cumple")
        // {
        //     global="Cumple"
        // }
        else if(params.data.gp1_calificacion && params.data.gp2_calificacion && params.data.gp3_calificacion)
         {
            let valordecoloración=0;
            let valorretroreflectividad=0;
            if(params.data.condicion_retroreflectividad==="No Cumple" && params.data.retroreflectividad_peso && params.data.retroreflectividad_peso!=""){
                valorretroreflectividad=params.data.retroreflectividad_peso
            }
            if(params.data.condicion_decoloracion==="No Cumple" && params.data.decoloracion_peso && params.data.decoloracion_peso!=""){
                valordecoloración=params.data.decoloracion_peso
            }
            let valor= parseFloat(params.data.gp1_calificacion)+parseFloat(params.data.gp2_calificacion)+parseFloat(params.data.gp3_calificacion)+parseFloat(valorretroreflectividad)+parseFloat(valordecoloración);
            if(valor<params.data.medicion_global_peso){
            global="Cumple"
            }else{
            global="No Cumple"
            }
         }
        else{
            global="No Cumple"
        }


        return requestHandler(type, resource, {
            ...params,
            data: {                                
                elemento_senalizacion: params.data.elemento_senalizacion,
                fecha: params.data.fecha,
                medicion_global: global,
                unidadFuncional:params.data.unidadFuncional,
                tramo: params.data.tramo,
                segmento: params.data.segmento,
                tipoElementoSenal: params.data.tipoElementoSenal,
                tipoSenal: params.data.tipoSenal,
                nombre: params.data.nombre,
                calificacionG1: params.data.calificacionG1d,
                calificacionG2: params.data.calificacionG2d,
                calificacionG3: params.data.calificacionG3d,
                svGrupo: svGrupo,
                retroreflectividad: [{
                    calificacion: params.data.condicion_retroreflectividad,
                    svColor: svColorRetroreflectividad
                }],
                decoloracion: [{
                    calificacion: params.data.condicion_decoloracion,
                    svColor: svColorDecoloracion
                }]
            }
        })
    }
    //AGREGAR CAMPOS PARA ORDENAMIENTO EN SEGMENTOS

    if((type === 'CREATE' || type === 'UPDATE') && resource === 'segmentos' )  
    {
        const segmentoNombre = params.data.segmentoCodigo
        const calzadaNombre = params.data.calzadaCodigo
        const superficieNombre = params.data.superficieCodigo
        const codigoR = params.data.codigoRR
        
        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                segmentoNombre: segmentoNombre,
                calzadaNombre: calzadaNombre,
                superficieNombre: superficieNombre,
                codigo : codigoR,
            },
        }) 
    }
 // CLONAR ALTERNATIVAS
 if((type === 'CREATE' ) && resource === 'trayectorias' )  
 {
     return requestHandler(type, resource, {
         ...params,
         data: {
            ahuellamiento_limite: params.data.ahuellamiento_limite ,
            ahuellamiento_mejora: params.data.ahuellamiento_mejora ,
            espesor: params.data.espesor,
            espesorcap1: params.data.espesorcap1,
            espesorcap2: params.data.espesorcap2,
            espesorcap3: params.data.espesorcap3,
            espesorcap4: params.data.espesorcap4,
            flexible: params.data.flexible,
            friccion_limite: params.data.friccion_limite,
            friccion_mejora: params.data.friccion_mejora,
            iri_limite: params.data.iri_limite,
            iri_mejora: params.data.iri_mejora,
            material: params.data.material,
            material1: params.data.material1,
            material2: params.data.material2,
            material3: params.data.material3,
            nombre: params.data.nombre,
            pci_limite: params.data.pci_limite,
            pci_mejora: params.data.pci_mejora,
            precio: params.data.precio,
            preciocar: params.data.preciocar,
            rigido: params.data.rigido,
            trafico: params.data.trafico,
            unidad_funcional: params.data.unidad_funcional,
            vida: params.data.vida
         },
     }) 
 } 
    //AGREGAR ADMINROL EN USUARIOS

    if((type === 'CREATE' ) && resource === 'usuarios' )  
    {
        const nombreAdmin = params.data.adminRol
        
        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                adminNombre: nombreAdmin,
    
            },
        }) 
    }

  

    //AGREGAR NOMBRE DEL MATEIAL PARA EL ORDENNAMIENTO

    if((type === 'CREATE' || type === 'UPDATE') && resource === 'granulometrias' )  
    {
        const  nombreMaterial = params.data.materialNombre
        
        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                nombreMaterial: nombreMaterial,
            },
        }) 
    }

    //AGREGARUbicaciones a proyecto

    if(type === 'UPDATE' && resource === 'proyectos' )  
    {
        const  ubicaciona = params.data.ubicaciona
        
        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                ubicaciones: ubicaciona,

            },
        }) 
    }
    if((type === 'UPDATE' || type === 'CREATE') && resource === 'parametros_grupos' )  
    {
        let evaluacion=[];
        let aplica=0;
        if(params.data.largoparametro){
            for(let i=0; i<params.data.largoparametro; i++){
                if(params.data["aplica_"+i] ===true){
                    aplica=1;
                }
                else{
                    aplica=0;
                }                    
                
                evaluacion[i]={
                    //"@id": params.data["id_"+i],
                    id:params.data["id_"+i],
                    originId:params.data["id_"+i],
                    aplica: aplica, 
                    frecuenciaMedicion: params.data["frecuenciaMedicion_"+i], 
                    tiempoCorreccion: params.data["tiempoCorreccion_"+i], 
                    valormaxPuntual: params.data["valormaxPuntual_"+i], 
                    valormaxMedio: params.data["valormaxMedio_"+i], 
                    campoAdicional: params.data["campoAdicional_"+i], 
                    valorAlerta: params.data["valorAlerta_"+i],
                    datosminModelacion: params.data["datosminModelacion_"+i],
                    tiempoAntesAlertaMedicion: params.data["tiempoAntesAlertaMedicion_"+i],
                    parametro: "/parametros/"+params.data["idparametro_"+i],
                }
                
            }
        }
        let proyecto=params.data["proyecto"];
        return requestHandler(type, resource, {
            ...params,
            data: {
                proyecto: proyecto,  
                evaluacion: evaluacion
            },
        }) 
    }
    if((type === 'UPDATE' || type === 'CREATE') && resource === 'puentes' )  
    {
        let reparaciones=[];
        for(let i=0; i<17; i++){
            reparaciones[i]={tipoReparacion: params.data['tipoReparacion_'+i], aplica: params.data['aplica_'+i], date: params.data['date_'+i], tipo: params.data['tipo_'+i], cant: params.data['cant_'+i], years: params.data['years_'+i], precio: params.data['precio_'+i]}
        }

        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                reparaciones: reparaciones,

            },
        }) 
    }
    if((type === 'UPDATE' || type === 'CREATE') && resource === 'parametros_analises' )  
    {
        let analisis=[];
        let total = params.data['years2'] - params.data['years1']
        for(let i=0; i<= total; i++){
            analisis[i]={precio: params.data['precio_'+i], precio2: params.data['precio2_'+i], total: params.data['total_'+i], tipoPrecio: params.data['tipoPrecio'+i]}
        }

        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                analisis: analisis,

            },
        }) 
    }

    // Descarte de elementos no admitidos en POLITICAS DE MANTENIMIENTO
    // if (type === 'CREATE' && resource === 'politicas_mantenimientos') {
    //     for (var i=0; i < params.data.politicasElementos.length; i++) {
    //         if (!params.data.politicasElementos[i].seRealiza) {
    //             params.data.politicasElementos.splice(i, 1);
    //         }
            
    //     }
    //     let respuesta = params.data.politicasElementos;
    //     console.log(params.data);
    //     return requestHandler(type, resource, {
    //                 ...params,
    //                 data: {
    //                     ...params.data,
    //                     politicasElementos : respuesta                
    //                 },
    //             }) 
    // }
 


    
    // transferencia de campos politicas_elementos
    if(type === 'UPDATE' && resource === 'politicas_mantenimientos' )  
    {
        
        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                politicasElementos              : params.data.politicasElementos2,
                politicasElementoPeriodicos     : params.data.politicasElementoPeriodicos2,
                politicasElementoRehabilitacions: params.data.politicasElementoRehabilitacions2,
                politicasElementoCambios        : params.data.politicasElementoCambios2
            },
        }) 
    }

    // transferencia de campos politicas_elementos
    if(type === 'CREATE' && resource === 'politicas_mantenimiento_particulars' )  
    {
        
        // console.log(params.data.politicasParticularesItems);
        var particularItems = [];
        params.data.politicasParticularesItems.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                particularItems.push(item)
            }
        })
        params.data.politicasParticularesItems = particularItems;

        var politicasElementoParticulars = [];
        params.data.politicasElementoParticulars.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoParticulars.push(item)
            }
        })
        params.data.politicasElementoParticulars = politicasElementoParticulars;

        var politicasElementoPeriodicoParticulars = []; 
        params.data.politicasElementoPeriodicoParticulars.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoPeriodicoParticulars.push(item)
            }
        })
        params.data.politicasElementoPeriodicoParticulars = politicasElementoPeriodicoParticulars;

        var politicasElementoRehabilitacionParticulars = [];
        params.data.politicasElementoRehabilitacionParticulars.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoRehabilitacionParticulars.push(item)
            }
        })
        params.data.politicasElementoRehabilitacionParticulars = politicasElementoRehabilitacionParticulars;

        var politicasElementoCambioParticulars = [];
        params.data.politicasElementoCambioParticulars.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoCambioParticulars.push(item)
            }
        })
        params.data.politicasElementoCambioParticulars = politicasElementoCambioParticulars;
        
       
    }

    // transferencia de campos politicas_elementos
    if(type === 'UPDATE' && resource === 'politicas_mantenimiento_particulars' )  
    {
        // console.log(params.data.politicasParticularesItems);
        var particularItems = [];
        params.data.politicasParticularesItems2.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                particularItems.push(item)
            }
        })
        params.data.politicasParticularesItems = particularItems;

        var politicasElementoParticulars = [];
        params.data.politicasElementoParticulars2.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoParticulars.push(item)
            }
        })
        params.data.politicasElementoParticulars = politicasElementoParticulars;

        var politicasElementoPeriodicoParticulars = []; 
        params.data.politicasElementoPeriodicoParticulars2.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoPeriodicoParticulars.push(item)
            }
        })
        params.data.politicasElementoPeriodicoParticulars = politicasElementoPeriodicoParticulars;

        var politicasElementoRehabilitacionParticulars = [];
        params.data.politicasElementoRehabilitacionParticulars2.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoRehabilitacionParticulars.push(item)
            }
        })
        params.data.politicasElementoRehabilitacionParticulars = politicasElementoRehabilitacionParticulars;

        var politicasElementoCambioParticulars = [];
        params.data.politicasElementoCambioParticulars2.map((item, index) => {
            if (item.hasOwnProperty("seRealiza")) {
                politicasElementoCambioParticulars.push(item)
            }
        })
        params.data.politicasElementoCambioParticulars = politicasElementoCambioParticulars;

        var politicasParticularesItems = [];
        params.data.politicasParticularesItems2.map((item, index) => {
            if (item.hasOwnProperty("seRealiza") && item.seRealiza == true) {
                politicasParticularesItems.push(item)
            }
        })
        params.data.politicasParticularesItems = politicasParticularesItems;

        delete params.data.politicasParticularesItems2;        
        delete params.data.politicasElementoParticulars2;
        delete params.data.politicasElementoPeriodicoParticulars2;
        delete params.data.politicasElementoRehabilitacionParticulars2;
        delete params.data.politicasElementoCambioParticulars2;

    }

    if(type === 'CREATE' && resource === 'deterioro_pers' )  
    {
        let reparacionLocalizada = params.data.reparacionLocalizada;
        let conteo = params.data.reparacionLocalizada.length;
        let arrayEnvio = [];
        // for (var i = 0; i < conteo; i++) { 
            reparacionLocalizada.map((item , index) =>{
                item.tipoDeterioros.map((items, j)=>{
                    let datos={
                        tipoSuperficie : reparacionLocalizada[index].tipoSuperficie,
                        unidad :items.unidad,
                        nivel :items.nivel,
                        nombre :items.nombre,
                        tipoReparacion :items.tipoReparacion,
                        costo :items.costo,
                    }
                    arrayEnvio.push(datos); 
                })                                 
            })                                                   
        // }
        return requestHandler(type, resource, {
            ...params,
            data: {
                ...params.data,                
                reparacionLocalizada: [...arrayEnvio],
            },
        }) 
    }

    if(type === 'DELETE_MANY' && resource === 'flujo_cajas' )  {
        params.ids.map( item => {
            // console.log(item.split("/")[2]);
            fetch(urlAppjs + '/matriz/borrar/NULL/' + item.split("/")[2],
                {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
        });
        // debugger
                    
    }

    // pasar el array de tipo de imagenes al source correspondiente en elemento_drenajes
    // console.log(type, resource);
    // if(type === 'UPDATE' && resource === 'elemento_drenajes' )  
    // {
    //     debugger
    //     return requestHandler(type, resource, {
    //         ...params,
    //         data: {
    //             ...params.data,                
    //             images : params.data.images2
    //         },
    //     }) 
    // }

    
    // RECURSOS Y PARAMETROS POR DEFECTO
    //-------------------------------------->
    return requestHandler(type, resource, params);

    
};

export default addUploadFeature;
import React, {useState} from 'react';
import { ReferenceInput,ArrayInput,SimpleFormIterator, AutocompleteInput ,BooleanInput,TabbedForm,FormTab, TextInput, NumberInput, SelectInput} from 'react-admin';
import { PimsCreate } from './../../Create';
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { abValidationInicio,validateDecima, validarDecimales,validarAB, abValidationFin, validarDosDecimales, validarPorcentaje } from '../../../../src/resources/validacionesInputs';
import { DInput } from '../../../inputs/DInput';
import { withStyles } from '@material-ui/core';
import { PRInput } from './../../../inputs/PRInput';
import AbsSegmento from '../../AbsSegmento';
import { latMininima, lngMininima,Entero } from '../../validacionesInputs';
import {
  required,
  minLength,
  maxLength,
  minValue,
  FormDataConsumer,
  maxValue
} from 'react-admin';



export const PeajesinventarioEdit = withStyles(styles)(({ classes, ...props }) => {

  const [tabla, setTabla] = useState('flexible_detalles');
  /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  const [elemento, setElemento] = useState('');
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');
  const ValidarSegmento = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(value)
  }
  /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [estados, setEstado] = useState('');
  const ValidarEstado = (value) => {

   // let lenghtObject = Object.keys(value);
    // let valor = '';

   // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setEstado(value)

  }
  //const Unidades = { unidadFuncional: unidads };
  
  const [unidads, setUnidad] = useState('');
  const ValidarUnidad = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [segmentos, setSegmento] = useState('');
  
  const entero = () => {
    let numero = "";
    if (document.getElementById('num1')) {
      numero = document.getElementById('num1').value;
      if (numero % 1 == 0) {
        console.log(numero);
      } else {
        return "El valor ingresado debe ser un Entero"
      }
    }
  
  
  }
  const entero1 = () => {
    let numero = "";
    var x = "";
    var y ="";
    var prueba = "";
    if (document.getElementById('num2')) {
      numero = document.getElementById('num2').value;
      if (numero % 1 == 0) {
        console.log(numero);
      } else {
        return "El valor ingresado debe ser un Entero"
      }
      x = document.getElementById('num2').value;
      y = document.getElementById('num1').value;
      prueba = parseInt(x) + parseInt(y)
      document.getElementById('num3').value = prueba;
    }
  
  }
  const styles = {
    ocultar: { display: "none" },
  
  };
return(
  <Edit title="Editar Inventario Peaje" {...props} redirect="list">
    <TabbedForm redirect="list">
      <FormTab label="Identificación">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Detalles</h3>
            <hr></hr>
          </Grid>
          <Grid item xs={4}>
            <TextInput label="Nombre de  Peaje" source="nombre" fullWidth validate={[required()]} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="Código" source="codigo" fullWidth validate={[required()]} />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput label="Estado" source="estado" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="N° Estaciones manuales" source="estacion_manual" id="num1" fullWidth validate={[required(), minLength(0), maxLength(11), minValue(0), maxValue(99999999999), entero]} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="N° Estaciones automáticas" source="estacion_automatica" id="num2" fullWidth validate={[required(), minLength(0), maxLength(11), minValue(0), maxValue(99999999999), entero1]} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="Total Estaciones" id="num3" fullWidth disabled />
          </Grid>
                     {/* ------- LOCALIZACIÓN --------- */}
            <Grid item xs={12}>
              <h3 style={{ marginBottom: 0 }}>Localización</h3>
              <hr></hr>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="Unidad Funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
            </Grid>

            {unidads &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="Tramo"
                  source="tramo"
                  alwaysOn
                  reference="tramos"
                  filter={{ unidadFuncional: unidads }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  sort={{ field: 'codigo', order: 'ASC', }}
                  validate={[required(), ValidarTramo]}
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
              </Grid>
            }
            {tramos &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="Segmento"
                  source="segmento"
                  reference="segmentos"
                  sort={{ field: 'codigo', order: 'ASC' }}
                  filter={{ tramo: tramos }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  validate={[required(),ValidarSegmento]}
                  formClassName={classes.grid_cont4}
                >

                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                {/* <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbinicio" id="IdAbInicio" />
                </ReferenceInput>
                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbfin" id="IdAbFin" />
                </ReferenceInput> */}
              </Grid>
            }

          </Grid>
          {segmentos && <AbsSegmento segmento={segmentos} />}
          {segmentos &&
            <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />
            </Grid>
          </Grid>
          }
          {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4s} />}
          {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos &&
           
          
            <ArrayInput label="" source="ubicaciones" style={{
              display:
                "none"
            }}
              defaultValue={[
                {
                  lat: latitudMinimaArray,
                  lng: longitudMinimaArray
                },
                {
                  lat: latitudMaximaArray,
                  lng: longitudMaximaArray
                }
              ]}
            >
              <SimpleFormIterator disableAdd disableRemove >
                <NumberInput label="Latitud" source="lat" fullWidth />
                <DInput label="Longitud" source="lng" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          }
      </FormTab>
    </TabbedForm>
  </Edit>

)
       });

import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField, CardActions, EditButton,ListButton, SelectField,WithPermissions } from 'react-admin';
import { Show } from './../Show';
import { PField } from './../../fields/PField';


import Divider from '@material-ui/core/Divider';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const PostActions =({basePath,data,
    currentSort,    
    exporter,    
    filterValues,   
    resource,    
    total})=>{
var rutas = localStorage.getItem("rutas");
return(
        <WithPermissions
        render={({ permissions }) => (
            "1" === rutas   ||    "2" === rutas           
    ? 
    <CardActions style={cardActionStyle}>
        
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
        {/* Add your custom actions
        <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
    

    :  <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} record={data} />
        </CardActions>
    )}
    />
    
)
};
export const RutaShow = props => (
    <Show  title="Información Ruta" actions={<PostActions/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Información básica">
                {/* <ReferenceField source="proyecto" reference="proyectos" linkType="show"><TextField source="nombre" /></ReferenceField>                 */}
                <TextField label="Código Ruta" source="fullCode" />
                <SelectField source="categoriaRuta" choices={[
                    { id: 1, name: 'Primer orden' },
                    { id: 2, name: 'Segundo orden' },
                    { id: 3, name: 'Tercer orden' },
                ]} />
                {/* <TextField label="Categoría" source="categoriaRuta" /> */}
                <span></span>
                <PField label="PR inicio" source="pInicio"/>
                <PField label="PR fin" source="pFin"/>
                <span></span>
                <b>Informacion MTC</b>
                <span></span>
                <span></span>                        
                <Divider /> 
                <Divider /> 
                <Divider /> 
                <TextField source="tramo" />
                <TextField label="Provincia" source="sector" /> 
                <span></span>   
                <TextField source="origen" /> 
                <TextField source="destino" /> 
                <span></span>
            </Tab>
            <Tab label="AuditorÍa">
            <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <span></span>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <span></span>    
            </Tab>
        </TabbedShowLayout>
    </Show>
);
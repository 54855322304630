import React from 'react';
import { ReferenceField,BooleanField,TextField,List, Datagrid, EditButton, ShowButton,DateField } from 'react-admin';


export const PeajescolajeList = props => (
    <List title="Cola de Peaje" {...props} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid >
        <ReferenceField label="Peaje" source="inventario_peajes" reference="inventariopeajes" linkType={false}>
                        <TextField source="nombre" />
            </ReferenceField>
            <TextField label="Consecutivo de Campaña" source="consecutivo"/>
             <DateField label="Fecha medición" source="date" />
             <TextField label="Tiempo de Medición" source="tiempo"/> 
             <BooleanField label="Indicador O3" source="indicador"/>
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
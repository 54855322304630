import React, {useState, Fragment} from 'react';

import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, SelectInput,
    AutocompleteInput, BulkDeleteWithConfirmButton
} from 'react-admin';

import { PRField } from './../../../fields/PRField';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


const FiltroAlrededor =(props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>

        <ReferenceInput 
            label="Elemento" 
            source="tipoElemento" 
            reference="tipo_elementos" 
            filter={{sistema: "ALR"}} 
            sort={{
                field: "nombre",
                order: "ASC"
            }}
            alwaysOn
        >
            <SelectInput optionText="nombre"/>
        </ReferenceInput>

        <SelectInput label="Estado" source="estados" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]}  fullWidth alwaysOn
            />
    </Filter>
    )
};

export const ElementoAlrededorList = props => (
    <List 
        {...props} 
        title="Lista Elemento Alrededores" 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        filters={<FiltroAlrededor/>} 
        bulkActionButtons={<PostBulkActionButtons />}
    >
        <Datagrid>
            <TextField label="Código" source="fullCodigo" sortable={false}/>
            <ReferenceField 
                label="Elemento" 
                source="tipoElemento" 
                sortable={false} 
                reference="tipo_elementos" 
                linkType={false}
            >
                <TextField source="nombre" />
            </ReferenceField>            
            <PRField label="Progresiva inicial" source="abInicio"/>
            <TextField source="margen"/>
            <TextField label="Tipo" source="tipoServicio"/>
            <TextField label="Estado" source="estados" sortable/>
            <ShowButton />
            <EditButton />                           
        </Datagrid>
    </List>
);
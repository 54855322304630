import React from 'react';
import { SelectInput,List,ReferenceInput, Datagrid, TextField, EditButton, ShowButton,  
    Filter, NotFound, AutocompleteInput
} from 'react-admin';
import { Fragment } from 'react';
import {  WithPermissions,BulkDeleteWithConfirmButton  } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

// var ids=localStorage.getItem('id_project');
 
const PostActions =({basePath,
        currentSort,    
        exporter,    
        filterValues,   
        resource,    
        total})=>{
    var proyecto1 = localStorage.getItem("proyecto");
    return(
            <WithPermissions
            render={({ permissions }) => (
                "1" === proyecto1   ||    "2" === proyecto1           
        ? 
        <CardActions>
            <CreateButton basePath={basePath} />
            <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        </CardActions>
        

        :  <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
    />
        )}
        />
        
    )
};

// var proyecto1 =localStorage.getItem("proyecto");

const PostBulkActionButtons = props => {

    var proyecto1 = localStorage.getItem("proyecto");

    return(

    <WithPermissions
            render={({ permissions }) => (
                 "1" === proyecto1                
        ? 
        <Fragment>
            <BulkDeleteWithConfirmButton {...props}/>
        </Fragment>
        : null
        )}
    />

    )};

const FiltroProyecto = (props) => (
    <Filter {...props}>
        <ReferenceInput 
            label="Proyecto" 
            source="id"  
            reference="proyectos" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
    </Filter>
);





export const ProyectoList = props => {

    if( localStorage.getItem("proyecto") === "1" || localStorage.getItem("proyecto") === "2" ){
        return(
        <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} actions={<PostActions/>} bulkActionButtons={<PostBulkActionButtons />} filters={<FiltroProyecto/>} >
            <Datagrid>
                <TextField label="Código Proyecto" source="codigo" />                  
                <TextField source="nombre" />
                <TextField source="concesionario" />
                <ShowButton/>
                {
                    localStorage.getItem("proyecto") === "1" || localStorage.getItem("proyecto") === "2" ? <EditButton /> : null
                }            
            </Datagrid>
        </List>
        )
    }else
    {
        return <NotFound></NotFound>
    } 
};

import React, {useState} from 'react';
import { SimpleForm,  ReferenceInput, SelectInput, DateInput,TextInput, AutocompleteInput
 } from 'react-admin';
import { PimsCreate } from './../Create';
import {
    required,

} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import BackButton from './../../BotonCancelar';
import config from '../../config/config';
let urlAppjs = config.UrlFront;

const LinkCancelar = () =>{
    let redirect ="proveedors"    
   return  <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{width: '0.8em' , paddingRight:'8px'}}/>CANCELAR</a>;
}  

const styles={

    img:{
        height: 128,
    },
    number:{
        textAlign: 'right',
    },
    ocultar:{
        display:'none'
    }
}



const CustomToolbar =  ( ({basePath, data,...props}) => 
    <Toolbar {...props} >
        <SaveButton  
            label="GUARDAR"
            redirect='/proveedors/'
            submitOnEnter={true} />
        <SaveButton style={{marginLeft : '15px' , marginRight : '50px'}}
            label="GUARDAR Y Calificar otro proveedor"
            redirect={false}
            submitOnEnter={true}
        />
        <BackButton />
    </Toolbar>
); 

const fecha = new Date();

export const ProveedorCalificacionCreate = withStyles(styles)( ({classes, ...props }) =>{

    return(
    <PimsCreate {...props} title="Calificar Proveedor" actions={false} >
        <SimpleForm submitOnEnter={false} toolbar={<CustomToolbar/>} >      
            <Grid container spacing={24}>                
                <Grid item xs={4}>
                   <ReferenceInput 
                    label="Proveedor" 
                    source="proveedor" 
                    reference="proveedors" 
                    validate={[required()]}
                    fullWidth 
                    filterToQuery={searchText => ({ razonSocial: searchText })} 
                    sort={{field: "razonSocial", order: "ASC"}}
                >
                        <AutocompleteInput optionText="razonSocial"/>
                   </ReferenceInput>
                </Grid> 
                <Grid item xs={2}>
                    <SelectInput label="Calificación" fullWidth source="calificacion" choices={[
                        {id : 1 , name : 1},
                        {id : 2 , name : 2},
                        {id : 3 , name : 3},
                        {id : 4 , name : 4},
                        {id : 5 , name : 5},
                    ]} validate={[required()]} />
                </Grid>
                <Grid item xs={2}>
                    <DateInput label="Fecha de calificación" source="fechaCalificacion" fullWidth validate={[required()]} defaultValue={fecha} disabled/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="Usario" source="usuario_id"  fullWidth validate={[required()]} defaultValue={window.localStorage.getItem('ucalpro')} disabled />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="span" component="span">Evalúe el proveedor en una calificación de 1 a 5, donde 5 significa un nivel "muy alto" y 1 un nivel "muy bajo"</Typography> 
                </Grid>
            </Grid>
        </SimpleForm>
    </PimsCreate>
)});

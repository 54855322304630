
import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField,SelectField,NumberField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


export const DeterioroPersList = props => (
    <List {...props} title="Deterioro PERS"  sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid >
            <SelectField label="Tipo de reparación" source='tipoSuperficie' choices={[
                {id:'PFLEX', name:'Flexible'},
                { id:'PRIGD', name:'Rígido'}
            ]}/>
            <TextField label="Nombre" source="nombre" />
            <TextField label="Unidad" source="unidad" />        
            <TextField label="Tipo de reparaciones severidad media" source="tipoReparacionMedia_.nombre" />
            <TextField label="Tipo de reparaciones severidad alta" source="tipoReparacionAlta_.nombre" />                    
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
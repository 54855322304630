import React, { useState } from 'react';
import { List, Datagrid,Toolbar,SelectInput,BooleanInput, ReferenceInput, TextField,SimpleForm, EditButton, CardActions,RadioButtonGroupInput,ShowButton,Loading} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import config from '../../../../config/config';
import Highcharts from 'highcharts';
import Typography from '@material-ui/core/Typography';
import HighchartsReact from 'highcharts-react-official';
let urlAppjs = config.UrlServer;
class Tasas extends React.Component{


    constructor(props){
        super(props)
        this.state = {estacion: [],record: [],tipo: "",value: '',titulos2:[],years:[],nombre: '' };
        this.fetchEstacion= this.fetchEstacion.bind(this);
      }
      fetchEstacion(years1, years2,nombres){
        //console.log(years1,years2)
        fetch(urlAppjs+"/tablatasas/"+years1+"/"+years2+"/"+nombres)
        .then((response) => {
          return response.json()
        })
        .then((estacion) => {
          //this.setState({ estacion: estacion.resultado })
          const estaciontmp = estacion.resultado;
          this.setState({
            estacion: estaciontmp,
          });
          //this.state.estacion = estacion.resultado;
        })
        
      }
      componentDidMount (){
        this.setState({ tipo: this.props.tipo  })
        this.setState({ record: this.props.record });
        this.setState({nombre:this.props.record.nombre})
    }
    componentWillReceiveProps() {
      if(this.props.years1 != this.props.years2 && parseInt(this.props.years2) > parseInt(this.props.years1)){
      this.fetchEstacion(this.props.years1, this.props.years2,this.props.record.nombre);
      }
      //console.log(this.props.record)
    }
      
    MiprimeraFuncion(newArray,nuevoArray,anios,anios1){
      let miprimerResultado =  ( (((newArray / nuevoArray) **(1 / (anios - anios1))) -1) *100).toFixed(3).replace('.',',')
        if(miprimerResultado  === 'NaN' || miprimerResultado === null || miprimerResultado === undefined ){
          return '0'
        }
        else{
    return miprimerResultado
  }
}


render(){
  const {tipo} = this.props;
  
  const { estacion } = this.state;
  
  const { record } = this.props;
  const {titulos} = this.state;
  /**if(!estacion ||  typeof estacion != 'object' || estacion.length <= 0)
   {
     this.forceUpdate();
    }**/
        if(tipo==='tipo' && estacion &&  typeof estacion === 'object' && estacion.length > 0){
        Array.prototype.unique=function(a){
          return function(){return this.filter(a)}}(function(a,b,c){return c.indexOf(a,b+1)<0
        });
        // console.log(estacion);
        let aniosRF = [];
        estacion.map((reporte) => {
          aniosRF.push(reporte.years)
        })
        aniosRF = aniosRF.unique();
        const titulos2 = ['Autos', 'Buses','C2-p','C2-g','C3-C4','C5','>C5','Total']     
        // const cellMeses = meses.map((mes, i) => <TableCell key={i} align="right" component="th" >{mes} </TableCell>);
    // console.log(record);


    const miArray2 = []

    // posición 0 = para autos y posición 1 para buses
    const newArray = [
      {
        0 : 0,
        1: 0
      },
      {
        0 : 0,
        1: 0
      },
      {
        0 : 0,
        1: 0
      },
      {
        0 : 0,
        1: 0
      },
      {
        0 : 0,
        1: 0
      },
      {
        0 : 0,
        1: 0
      },
      {
        0 : 0,
        1: 0
      },
      {
        0 : 0,
        1: 0
      },
    ]
    estacion.map((data, i) =>{

      if(i === 0 ){
        newArray[0][0] = parseInt(data.aut1)
        newArray[1][0] = parseInt(data.bus1)
        newArray[2][0] = parseInt(data.c1volumen)
        newArray[3][0] = parseInt(data.cgvolumen)
        newArray[4][0] = parseInt(data.c3volumen)
        newArray[5][0] = parseInt(data.c5volumen)
        newArray[6][0] = parseInt(data.mayorc5volumen)
      }if(i === estacion.length-1){
        newArray[0][1] = parseInt(data.aut1)
        newArray[1][1] = parseInt(data.bus1)
        newArray[2][1] = parseInt(data.c1volumen)
        newArray[3][1] = parseInt(data.cgvolumen)
        newArray[4][1] = parseInt(data.c3volumen)
        newArray[5][1] = parseInt(data.c5volumen)
        newArray[6][1] = parseInt(data.mayorc5volumen)      
      }


      let sumTotal = 0
      sumTotal += 
      parseInt(data.aut1) + 
      parseInt(data.bus1) + 
      parseInt(data.c1volumen) +
      parseInt(data.cgvolumen)+
      parseInt(data.c3volumen) +  
      parseInt(data.c5volumen) + 
      parseInt(data.mayorc5volumen)

      miArray2.push({
        0:data.aut1,
        1:data.bus1,
        2:data.c1volumen,
        3:data.cgvolumen,
        4:data.c3volumen,
        5:data.c5volumen,
        6:data.mayorc5volumen,
        7: sumTotal
      })
    })
    //console.log(miArray2);

    
    //console.log(newArray[7][0] )
    // console.log(miArray2[0])
    if(miArray2.length > 0){
      newArray[7][0] = miArray2[0][7]
      newArray[7][1] = miArray2[miArray2.length -1][7]
    }

    // let enero = []
    //         let aut1 = []
    //         let bus1 = []
    //         let c1volumen = []
    //         let cgvolumen = []
    //         let c3volumen = []
    //         let c5volumen = []
    //         let mayorc5volumen = []
            // if(estacion && typeof estacion === 'object' && estacion.length > 0 || estacion !== NaN){
            // estacion.map((data,i)=>{
            //   aut1.push(parseInt(data.aut1))
            //   bus1.push(parseInt(data.bus1))
            //   c1volumen.push(parseInt(data.c1volumen))
            //   cgvolumen.push(parseInt(data.cgvolumen))
            //   c3volumen.push(parseInt(data.c3volumen))
            //   c5volumen.push(parseInt(data.c5volumen))
            //   mayorc5volumen.push(parseInt(data.mayorc5volumen))
            // })
          // }
          //console.log(estacion)
    let ano=[]
    estacion.map((data,i) => { 
      ano.push(data.years)
    })
    //console.log(ano)
    //console.log(ano)
      //console.log(aut1,bus1,c1volumen,cgvolumen,c3volumen,c3volumen,c5volumen,mayorc5volumen)
      let tasas=[]
      // for (let i=0;i<aut1.length;i++){
      // let dataTemporal = []  
      // dataTemporal.push(aut1[i])
      // if(bus1[i]){dataTemporal.push(bus1[i])}
      // if(c1volumen[i]){dataTemporal.push(c1volumen[i])}
      // if(cgvolumen[i]){dataTemporal.push(cgvolumen[i])}
      // if(c3volumen[i]){dataTemporal.push(c3volumen[i])}
      // if(c5volumen[i]){dataTemporal.push(c5volumen[i])}
      
      
     titulos2.map((data,i)=>{
        let opcion = {};
        opcion.name = titulos2[i];
        let datos = [];
        for(let j=0; j < miArray2.length; j++) {
          datos.push(parseInt(miArray2[j][i], 10));
        }
        opcion.data = datos;
        tasas.push(opcion);
      });
      // console.log(record)
    //}
    // console.log(tasas)
    // console.log(aut1)
      const options = {
 
        title: {
          text: 'Tasa de crecimiento'
      },
    
      subtitle: {
          text: 'Datos total'
      },
    
      yAxis: {
          title: {
              text: 'Valores'
          }
      },
      xAxis:{
        categories:ano
      },
      series:tasas

        // {
        //   name:'Buses',
        //   data: [bus1]
        // },
        // {
        //   name:'C2-P',
        //   data: [c1volumen]
        // },
        // {
        //   name:'C2-G',
        //   data: [cgvolumen]
        // },
        // {
        //   name:'C3-C4',
        //   data: [c3volumen]
        // },
        // {
        //   name:'C5',
        //   data: [c5volumen]
        // },
        // {
        //   name:'>C5',
        //   data: [mayorc5volumen]
        // }
      //]
       
    }
    
  
    return(
        <React.Fragment>

<Table aria-label="simple table" fullWidth  style={{width: "100%"}}>

                  <TableHead>
                    <TableRow style={{color:'#fff'}} >
                        <TableCell align="right" component="th" >Categoría  </TableCell>
                         {ano.map(anio => ( 
                    <TableCell key={anio} align="right" component="th" fullWidth>
                  {anio}<br/>
                </TableCell>
                      
                     ))} 
                        <TableCell align="right" component="th" >Tasa de crecimiento  </TableCell>
                        <br></br>   
                        </TableRow>
                        </TableHead>       
            <TableBody>
            {  titulos2.map((data, i) => (
                        <TableRow > 
                       <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>{titulos2[i]}</h3><br/>    
                        </TableCell>
                        {
                          ano.map((data,key)=> {
                          return<TableCell align="right" fullWidth style={{textAlign:'right'}}> <span> {miArray2[key][i]}</span></TableCell>

                          })
                        }
                        {/* <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span> {(newArray[i][1] ) !== 0 ? ( (((newArray[i][1] / newArray[i][0]) **(1 / (aniosRF[aniosRF.length -1] - aniosRF[0]))) -1) *100).toFixed(3).replace('.',',')  : ""}% </span></TableCell>    */}
                      <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span> {this.MiprimeraFuncion(newArray[i][1], newArray[i][0], aniosRF[aniosRF.length -1],  aniosRF[0])}% </span></TableCell>   
                    </TableRow>
                     )
                    )} 
                    
                    </TableBody>
                        </Table>
                        <br/>
                        <HighchartsReact highcharts={Highcharts} options={options} />
        </React.Fragment>
    )
            }
            else if(tipo==='tipo' && !estacion &&  !typeof estacion === 'object' && estacion.length > 0){
              return  <Loading />
            }
            else{

              return (   <div style={{textAlign : "center"}}>
              <Typography variant="h2" component="h2">
                  Sin Resultados.
              </Typography>
          </div>)
            }
      

            }
           
                
          }

export default Tasas;
import React , {useState} from 'react';
import { minValue, TextInput, ArrayInput, SimpleFormIterator, NumberInput, NotFound} from 'react-admin';
import { Edit } from './../Edit';
import { SimpleForm } from './../SimpleForm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles} from '@material-ui/core';
import { validatLatitud, validarLongitud,  Entero , AlturaMayor, CoordenadasMaximasLat, CoordenadasMaximasLng  } from './../validacionesInputs';
import {
    required,
    minLength,
    maxLength,
    maxValue
} from 'react-admin';
import Divider from '@material-ui/core/Divider';
import UniqueProject from './../componentes/UniqueProject';


const styles={
    img:{
        height: 128,
    },
    number:{
        textAlign: 'right',
    },
    ocultar:{
        display:'none'
    }
}


export const ProyectoEdit = withStyles(styles)( ({classes, ...props }) =>{
    const [latMax , setLatMax]= useState('');
    const LatMax =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLatMax(value) 
    }

    const [lngMax , setLngMax]= useState('');
    const LngMax =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLngMax(value) 
    }

    const [lngMin , setLngMin]= useState('');
    const LngMin =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLngMin(value) 
    }

    const [latMin , setLatMin]= useState('');
    const LatMin =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLatMin(value) 
    }

    return(
    <Edit title="Editar Proyecto" {...props} >
        <SimpleForm submitOnEnter={false} redirect="list"  >            
            <Grid container spacing={24}>
                <Grid item xs={2}>
                <UniqueProject 
                        id="codigo" 
                        source="codigo" 
                        reference="proyectos"
                        label="Código Proyecto" 
                        operation="edit"
                        fullWidth 
                        validate={[required(), minLength(2), maxLength(10)]}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextInput source="nombre" fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                </Grid>                
                <Grid item xs={12}>
                    <TextInput source="concesionario" fullWidth validate={[required(), minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">Coordenadas mínimas</Typography>
                    <Divider style={{marginTop : '10px' }} />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput label="Latitud"  id="LatitudInicial" source="latitud1"  fullWidth validate={[required(), LatMin , validatLatitud, maxValue(90), minValue(-90) ]}/>
                </Grid> 
                <Grid item xs={6}>
                    <NumberInput  label="Longitud" id="LongitudInicial" source="longitud1"  fullWidth validate={[required(), LngMin, validarLongitud, maxValue(180), minValue(-180) ]}/> 
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">Coordenadas máximas</Typography>
                    <Divider style={{marginTop : '10px' }} />
                </Grid>        
                <Grid item xs={6}>
                    <NumberInput label="Latitud" source="latitud2"  fullWidth validate={[CoordenadasMaximasLat, required(), LatMax, validatLatitud,maxValue(90), minValue(-90) ]}/> 
                </Grid>
                <Grid item xs={6}>
                    <NumberInput label="Longitud" source="longitud2"  fullWidth validate={[CoordenadasMaximasLng, required(),  LngMax, validarLongitud, maxValue(180), minValue(-180)]}/>
                </Grid> 
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">Altura</Typography>
                    <Divider style={{marginTop : '10px' }} />
                </Grid> 
                <Grid item xs={6}>
                    <NumberInput  label="Altura mínima (m.s.n.m)" id="alturaIni"  source="alturaIni"  fullWidth validate={[ minValue(-450,'Debe ser al menos -450'),maxValue(8700), Entero]}/> 
                </Grid>   
                <Grid item xs={6}>
                    <NumberInput label="Altura máxima (m.s.n.m)" source="alturaFin"  fullWidth validate={[ minValue(-450,'Debe ser al menos -450'),maxValue(8700), Entero , AlturaMayor]}/>
                </Grid>
                
                {/* className={classes.ocultar} */}
                <Grid item xs={12}>
                    <ArrayInput label="Agregar ubicación" source="ubicaciona" className={classes.ocultar}
                        defaultValue={[
                            {
                                lat: latMax,
                                lng: lngMax
                            },
                            {
                                lat: latMax,
                                lng: lngMin
                            },
                            {
                                lat: latMin,
                                lng: lngMin
                            },
                            {
                                lat: latMin,
                                lng: lngMax
                            }
                            ]}
                    >
                        <SimpleFormIterator disableAdd disableRemove >
                                <TextInput label="Latitud" source="lat" fullWidth validate={[required()]}/>
                                <TextInput label="Longitud" source="lng" fullWidth validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
)});
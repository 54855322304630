import React,{ useState } from 'react';
import { TextField,NumberField,required } from 'react-admin';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
// import loader from './../../../../../loader10.gif';

class Coloramiento extends React.Component{


    constructor(props){
        super(props)
        this.state = {record: [],edit: "",value: '' };
        this.array = {
            valoresx1 : 0,valoresx2 : 0,valoresx3 : 0,valoresx4 : 0, maximo: 0,minimo: 0,maximo1: 0,minimo1: 0,
        }
        this.validatenum = this.validatenum.bind(this);
    }

    componentDidMount(){
        this.setState({ record: this.props.record })
        //this.setState({ tipo: this.props.edit })

    }

    // rectay(event){
    //     for(let i=0; i<4; i++){
    //         //$x=($a2-$a1)/($b1-$b2);
    //         //$y=($b1*$x)+$a1;
    //     let j=i-1; //j=1 i=2
    //         if(j<0){
    //             let j=3;
    //         }
    //         let y =(b[i]*x)+a[i];
    //        let putosy,[i]=y;
    //         console.log( "X-> "+x+"\n");
    //         }
    //     }

    validatenum  (valor) {
        let n = valor;
        if (n <= 1) {
          console.log(n)
        } else if(n > 0){
          console.log(n)
        }
        else{
        return "El numero ingresado debe ser menor a 1 y mayor a 0"
        }
      }

    render(){

        // const { colores } = this.state;
        const { record } = this.state;
        const {edit} = this.state;
        const recta=()=>{
        let a = [];
        let b= [];
        let puntosx=[]
        let puntosy=[]
        for(let i=0; i<4; i++){
        let valor_a=document.getElementById('valora_'+i)
        let valor_b=document.getElementById('valorb_'+i)
        if(valor_a && valor_b && valor_a.value && valor_b.value){
            a.push(valor_a.value)
            b.push(valor_b.value)
        }
        }
        if(a.length===4 && b.length===4){
            let x=0;
            let y=0;
            let j=0;
            for(let i=0; i<4; i++){
                //$x=($a2-$a1)/($b1-$b2);
                //$y=($b1*$x)+$a1;
            let j=i-1; //j=1 i=2
                if(j<0){
                    j=3;
                }
                x=(a[i]-a[j])/(b[j]-b[i]);

                y =(b[i]*x)+a[i];
                puntosx[i] =x;
                puntosy[i] =y;

                }
                console.log(puntosx)
                console.log(puntosy)
        this.array.maximo = Math.max(...puntosx)
        this.array.minimo = Math.min(...puntosy)
        this.array.minimo1 = Math.min(...puntosx)
        this.array.maximo1 = Math.max(...puntosy)
            }
         this.array.valoresx1="x="+puntosx[0]+" y="+puntosy[0];
         this.array.valoresx2="x="+puntosx[1]+" y="+puntosy[1];
         this.array.valoresx3="x="+puntosx[2]+" y="+puntosy[2];
         this.array.valoresx4="x="+puntosx[3]+" y="+puntosy[3];


         //this.array.maximo = Math.max(...myArray);
          // 99
         //var minValue = Math.min(...myArray); // 1

        }

        let colores2=["Recta Superior","Recta Derecha","Recta Inferior","Recta Izquierda"];

      
        let colores=this.props.record
        console.log(this.props.record)

            //
if(colores){


    return(
        <React.Fragment>
            <div style={{display: 'flex'}}>
                {
                    colores2.map((data, i) => (
                        <div style={{margin: '0px 50px'}}>
                            <div style={{textAlign: 'center',margin:'25px', marginTop: '15px'}}>
                            {colores2[i]}
                            </div>
                            <div>
                                <p style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}> {colores['valora_'+i]}</p>
                                <p style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}> {colores['valorb_'+i]}</p>
                                
                            </div>
                        </div>
                    ))
                }
            </div>
            <span></span>
            <span></span>
            <hr></hr>
            <div style={{display: 'flex', justifyContent: 'center',margin: '0px 20px', marginTop: '10px'}}>
            <span  style={{display: 'flex', alignItems: 'flex-end', marginRight: '50px', marginBottom: '15px'}}><strong>Punto 1:</strong> {colores.p1} </span>
                <span  style={{display: 'flex', alignItems: 'flex-end', marginRight: '50px', marginBottom: '15px'}}><strong>Punto 2:</strong>{colores.p2} </span>
                <span  style={{display: 'flex', alignItems: 'flex-end', marginRight: '50px', marginBottom: '15px'}}><strong>Punto 3:</strong>{colores.p3} </span>
                <span style={{display: 'flex', alignItems: 'flex-end', marginRight: '50px', marginBottom: '15px'}}><strong>Punto 4:</strong>{colores.p4} </span>
            </div>
            <hr/>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
            <span style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}><strong>Valor Mínimo x:</strong>{colores.min1} </span>
            <span style={{margin: '0px 30px'}}></span>
            <span style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}><strong>Valor Mínimo y:</strong>{colores.min} </span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
            <span style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}><strong>Valor Máximo x:</strong>{colores.max} </span>
            <span style={{margin: '0px 30px'}}></span>
             <span style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}><strong>Valor Máximo y:</strong>{colores.max1} </span>
             </div>
             <hr></hr>
             <h3 style={{marginLeft: '40%'}}>Valores Retro-Reflectividad</h3>
             
             <div style={{display: 'flex', justifyContent: 'flex-rigth',margin: '0px 50px', marginTop: '10px'}}>
             <p style={{ justifyContent: "center", marginRight: "10px" }}>Valor Mímino de Cumplimento</p >
             </div>
            <span style={{ display: "flex", justifyContent: "flex-end", marginRight: "75%" }}>{colores.valor}</span>
   


            <TextField source="dec_length" label=" " style={{display: 'none'}}/>   
        </React.Fragment>
    )
}
else{
    return(
    <React.Fragment></React.Fragment>
    )
}
}
}


export default Coloramiento;
import React, { Fragment, useState } from 'react';

import {
    List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter,
    ReferenceInput, SelectInput, NumberField, BulkDeleteWithConfirmButton, AutocompleteInput
} from 'react-admin';

import { PRField } from './../../../fields/PRField';


const FiltroDreanje = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="Unidad Funcional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="Tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>

            <ReferenceInput
                label="Segmento"
                source="segmento"
                reference="segmentos"
                alwaysOn
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={{ tramo: tramo }}
                filterToQuery={searchText => ({ codigo: searchText })}
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="Elemento"
                source="tipoElemento"
                reference="tipo_elementos"
                filter={{ sistema: "DRE" }}
                sort={{ field: 'name', order: 'ASC' }}
                filterToQuery={searchText => ({ nombre: searchText })}
                alwaysOn
                resettable
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
            </ReferenceInput>
            <SelectInput
                fullWidth
                label="Estado"
                source="estados"
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
                choices={[
                    { id: "Activo", name: "Activo" },
                    { id: "Inactivo", name: "Inactivo" },
                ]}
                resettable
            />
            {/* <SelectInput 
            fullWidth 
            label="Status" 
            source="status"   
            sort={{field : 'name' , order :'ASC'}} 
            alwaysOn  
            choices={[
                {id : 0 , name : 'Activo'},
                {id : 1 , name : 'inactivo'}
            ]}
            resettable
        /> */}
        </Filter>
    )
};
const ConfirmarBorrar = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
    </Fragment>
);


 
export const ElementoDrenajeList = props => (
    <List {...props}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        title="Lista Elementos Drenajes"
        filters={<FiltroDreanje />}
        bulkActionButtons={<ConfirmarBorrar />}
    > 
        <Datagrid>
            <TextField label="Código" source="smartCode" sortable />
            <ReferenceField source="tipoElemento" reference="tipo_elementos" linkType={false}>
                <TextField source="nombre" />
            </ReferenceField>
            <PRField label="Abscisa Inicial" source="abInicio" sortable />
            <PRField label="Abscisa Final" source="abFin" sortable />
            <NumberField label="Longitud (km)" source="fullResta" sortable={false} />
            <TextField label="Estado" source="estados" sortable />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
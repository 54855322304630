import React from 'react';
import { TextInput, required } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

class UniqueEmail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            email: [],
            error: false
        }

        this.validarEmail = this.validarEmail.bind(this);
        this.uniqueEmail  = this.uniqueEmail.bind(this);
    }
    componentWillMount() {
        fetch(urlAppjs + "/usuarios.json?pagination=false",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.state.email = response;
            });
    }
    validarEmail() {
        if (document.getElementById("emails")) {
            var texto = document.getElementById("emails").value;
            var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if (!regex.test(texto)) {
                return ("Correo Invalido");
            }
        }
    }
    
    uniqueEmail() {
        if (document.getElementById("emails")) {
            var texto = document.getElementById("emails").value;
            var find = this.state.email.find(data => data.username == texto);
            if (typeof find === 'object') {
                this.state.error = true;
            } else {
                this.state.error = false;
            }
            // for (var i = 0; i < this.state.email.length; i++) {
            //     if (this.state.email[i].username == texto) {
            //         this.state.error = true;
            //         break;
            //     } else {
            //         this.state.error = false;
            //     }
            // }
        }
        if (this.state.error == true) {
            return ("El email ya se encuentra en uso.");
        }
    }
   
         
    render() {

        return (
            <TextInput
                id="emails"
                label="Correo electrónico"
                source="username"
                fullWidth
                validate={[required(), this.validarEmail, this.uniqueEmail]}
                autoComplete="new-password"
            />
        )
    }
}

export default UniqueEmail;
import React  from 'react';
import { TextInput, SelectInput,NumberInput,minValue,maxValue } from 'react-admin';
import { withStyles, Grid } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import { SimpleForm } from './../../../SimpleForm';
import { styles } from './../../../../EstilosGrid';
import { required, maxLength } from 'react-admin';
import { abValidationInicio,Entero,validarUnDecimal,validateDecima,validarDecimales,validarAB, abValidationFin, validarDosDecimales, validarPorcentaje, validarDecimales2 } from '../../../validacionesInputs';
import UniqueMaterial from './../../../componentes/UniqueMaterial'
import { useState } from 'react';





export const MaterialesCreate = withStyles(styles)(({ classes, ...props }) => {
    const styles = {
        first_field: { width: '100%' }    
    };
    
    const choises2 = [
        { id: '1', name: 'Adoquín en arcilla' },
        { id: '2', name: 'Adoquines de concreto' },
        { id: '3', name: 'Afirmado' },
        { id: '4', name: 'Base asfáltica' },
        { id: '5', name: 'Base de concreto hidráulico' },
        { id: '6', name: 'Base estabilizada - otros' },
        { id: '7', name: 'Base estabilizada con asfalto espumado' },
        { id: '8', name: 'Base estabilizada con emulsión asfáltica' },
        { id: '9', name: 'Base granular' },
        { id: '10', name: 'Base tratada con cemento' },
        { id: '11', name: 'Concreto hidráulico' },
        { id: '12', name: 'Lechada ásfaltica' },
        { id: '13', name: 'Mezcla abierta en caliente' },
        { id: '14', name: 'Mezcla abierta en frío' },
        { id: '15', name: 'Mezcla asfáltica modificada con grano de caucho' },
        { id: '16', name: 'Mezcla asfáltica modificada con polímeros' },
        { id: '17', name: 'Mezcla densa en caliente' },
        { id: '18', name: 'Mezcla densa en frío' },
        { id: '19', name: 'Mezcla drenante' },
        { id: '20', name: 'Microaglomerado en caliente' },
        { id: '21', name: 'Sub base asfalto espumado' },
        { id: '22', name: 'Sub-base estabilizada - otros' },
        { id: '23', name: 'Sub base estabilizada con asfalto' },
        { id: '24', name: 'Sub base estabilizada con cemento' },
        { id: '25', name: 'Sub-base granular' },
        { id: '26', name: 'Subrazante estabilizada con cal' },
        { id: '27', name: 'Subrazante estabilizada con cemento' },
        { id: '28', name: 'Subrazante estabilizada con geomalla' },
        { id: '29', name: 'Subrazante estabilizada con geotextil' },
        { id: '30', name: 'Subrazante fina' },
        { id: '31', name: 'Subrazante granular' },
        { id: '32', name: 'Suelo cemento' },
    ];
    const optionRenderer = choice => `${choice.id}`;
    const choises3 = [
        { id: '1', name: 'Otros' },
        { id: '2', name: 'Otros' },
        { id: '3', name: 'No ligado' },
        { id: '4', name: 'Bituminoso' },
        { id: '5', name: 'Cementado' },
        { id: '6', name: 'No ligado' },
        { id: '7', name: 'Bituminoso' },
        { id: '8', name: 'Bituminoso' },
        { id: '9', name: 'No ligado' },
        { id: '10', name: 'Cementado' },
        { id: '11', name: 'Cementado' },
        { id: '12', name: 'Bituminoso' },
        { id: '13', name: 'Bituminoso' },
        { id: '14', name: 'Bituminoso' },
        { id: '15', name: 'Bituminoso' },
        { id: '16', name: 'Bituminoso' },
        { id: '17', name: 'Bituminoso' },
        { id: '18', name: 'Bituminoso' },
        { id: '19', name: 'Bituminoso' },
        { id: '20', name: 'Bituminoso' },
        { id: '21', name: 'No ligado' },
        { id: '22', name: 'No ligado' },
        { id: '23', name: 'No ligado' },
        { id: '24', name: 'No ligado' },
        { id: '25', name: 'No ligado' },
        { id: '26', name: 'No ligado' },
        { id: '27', name: 'No ligado' },
        { id: '28', name: 'No ligado' },
        { id: '29', name: 'No ligado' },
        { id: '30', name: 'No ligado' },
        { id: '31', name: 'No ligado' },
        { id: '32', name: 'No ligado' }
    ];
    const [especificacion, setEspecificacion] = useState('');
    const validateEspecificacion = (value) =>{
        let  especificacion = [
            "",
            "",
            "INV-311-13",
            "INV-450-13",
            "INV-505-13",
            "INV-13",
            "",
            "INV-340-13",
            "INV-330-13",
            "INV-351-13",
            "INV-500-13",
            "INV-433-13",
            "INV-451-13",
            "INV-441-13",
            "INV-413-13",
            "INV-414-13",
            "INV-450-13",
            "INV-440-13",
            "INV-453-13",
            "INV-452-13",
            "",
            "",
            "",
            "",
            "INV-320-13",
            "INV-236-13",
            "INV-235-13",
            "INV-233-13",
            "INV-232-13",
            "",
            "",
            "INV-350-13"
            
        ];
        setEspecificacion(especificacion[parseInt(value)-1]);
    } 
    


    return(
    <PimsCreate {...props} title="Crear Material">
        <SimpleForm redirect="list" >
                    <UniqueMaterial 
                        label="Código de material" 
                        source="material" 
                        operation="default"
                        style={{width: '100%'}} 
                        fullWidth 
                        className={classes.first_field} 
                        validate={[required(), maxLength(70)]}
                        formClassName={classes.grid_cont4}
                    />
                    <SelectInput source="tipo" label="Tipo de Material" choices={choises2} fullWidth validate={[required(),validateEspecificacion]}  formClassName={classes.grid_cont4}/>
                    <SelectInput label="Comportamiento" source="tipo" fullWidth validate={[required()]} disabled choices={choises3}  formClassName={classes.grid_cont3}/>
                    <TextInput source="especificacion" label="Especificación" fullWidth validate={[maxLength(20)]} defaultValue={especificacion}  formClassName={classes.grid_cont4}/>  
                    <NumberInput label="Módulo teórico (MPa)" source="refmodulus" fullWidth validate={[required(),minValue(0) ,maxValue(100000)]} formClassName={classes.grid_cont4} />
                    <NumberInput label="Módulo mínimo (MPa)" source="minmodulus" fullWidth validate={[required(),minValue(0) ,maxValue(100000)]} formClassName={classes.grid_cont3}/>
                    <NumberInput label="Desviación estandar" source="stdfactor" fullWidth validate={[required(),minValue(0) ,validarUnDecimal]} formClassName={classes.grid_cont4}/>
                    <NumberInput label="Módulo de poisson" source="poissons"fullWidth validate={[required(),validarUnDecimal]} formClassName={classes.grid_cont4}/>
                    {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
                    <TextInput label="Observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont4}/>
                                
        </SimpleForm>
    </PimsCreate>
)});
import React from 'react';
import { TextField, ReferenceField} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from '../../../config/config'; 
import loader from '../../../loader10.gif';
let urlAppjs = config.UrlServer;

class VistaDatosShow extends React.Component{
    constructor(props){
        super(props)
        this.state = { datosCarga: [], record: []}
       
    }
    componentDidMount(){
        this.setState({ record: this.props.record });
        let abini=parseFloat(this.props.record.abini); 
        let abfin=parseFloat(this.props.record.abfin)+0.1;
        let idcarga=this.props.record.cargaInformacionId;
        //console.log(abini + " " + abfin);
        if(this.props.record.indicador && this.props.record.indicador==="/parametros/1"){
            fetch(urlAppjs+"/indicadores4_g_e1s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.setState({ datosCarga: response })
                //console.log(grupo1)
            })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/2"){
                fetch(urlAppjs+"/indicadores4_g_e2s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/3"){
                fetch(urlAppjs+"/indicadores4_g_e3s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/4"){
                fetch(urlAppjs+"/indicadores4_g_e4s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/5"){
                fetch(urlAppjs+"/indicadores4_g_e5s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/6"){
                fetch(urlAppjs+"/indicadores4_g_e6s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/7"){
                fetch(urlAppjs+"/indicadores4_g_e7s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/10"){
                fetch(urlAppjs+"/indicadores4_g_e11s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/11"){
                fetch(urlAppjs+"/indicadores4_g_e12s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/15"){
                fetch(urlAppjs+"/indicadores4_g_e16s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/16"){
                fetch(urlAppjs+"/indicadorespcis?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/17"){
                fetch(urlAppjs+"/indicadoresastms?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }else if(this.props.record.indicador && this.props.record.indicador==="/parametros/21"){
                fetch(urlAppjs+"/indicadores4_g_e22s?cargaInformacionId="+idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
            }
    }
    formatoabscisa(valor){
    if(this.props.record.indicador==="/parametros/1" || this.props.record.indicador==="/parametros/16"){
    let valor2=valor-parseInt(valor);
    valor2=parseFloat(valor2).toFixed(1);
        return "K"+parseInt(valor)+"+"+valor2.replace("0.", "")+"00";
    }
    else if(this.props.record.indicador==="/parametros/2" || this.props.record.indicador==="/parametros/4"|| this.props.record.indicador==="/parametros/5"){
        let valor2=valor-parseInt(valor);
        valor2=parseFloat(valor2).toFixed(2);
            return "K"+parseInt(valor)+"+"+valor2.replace("0.", "")+"0";
    }
    else{
        let valor2=valor-parseInt(valor);
        valor2=parseFloat(valor2).toFixed(3);
            return "K"+parseInt(valor)+"+"+valor2.replace("0.", "");
        }
    }
    formatovalores(valor, decimal){
        let value =parseFloat(valor).toFixed(decimal).replace(".", ",");
        return value;
    }
    render(){
        const { datosCarga } = this.state;
        //console.log(datosCarga);
if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/1"){


        return(
            <Table  aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{textAlign: "center"}} colSpan="2">ABSCISA</TableCell>
                        <TableCell style={{textAlign: "center"}} colspan="4">CARRIL IZQUIERDO (DESCENDENTE)</TableCell>
                        <TableCell style={{textAlign: "center"}} colspan="4">CARRIL DERECHO (ASCENDENTE)</TableCell>
                        <TableCell style={{textAlign: "center"}} rowSpan="2">Cumplimiento Indicador Calzada</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                        <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                        <TableCell style={{textAlign: "center"}}>IRI (mm/m)</TableCell>
                        <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                        <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                        <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                        <TableCell style={{textAlign: "center"}}>IRI (mm/m)</TableCell>
                        <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                        <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                        <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                        <TableCell style={{textAlign: "center"}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {datosCarga['hydra:member'].map((row, i)=>(
                    <TableRow>
                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abini'])}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abfin'])}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['iriCizq'], 1)}</TableCell>
                        {row['cumplimientoCizq']==="No Cumple" &&
                        <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                        }
                        {row['cumplimientoCizq']==="Cumple" &&
                        <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                        }
                        {row['cumplimientoCizq']==="No Aplica" &&
                        <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                        }
                        <TableCell style={{textAlign: "left"}}>{row['eventoCizq']}</TableCell>
                        <TableCell style={{textAlign: "left"}}>{row['singularidadCizq']}</TableCell>
                        <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['iriCder'], 1)}</TableCell>
                        {row['cumplimientoCder']==="No Cumple" &&
                        <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                        }
                        {row['cumplimientoCder']==="Cumple" &&
                        <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                        }
                        {row['cumplimientoCder']==="No Aplica" &&
                        <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                        }
                        <TableCell style={{textAlign: "left"}}>{row['eventoCder']}</TableCell>
                        <TableCell style={{textAlign: "left"}}>{row['singularidadCder']}</TableCell>
                        {row['cumplimientoCalzada']==="No Cumple" &&
                        <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                        }
                        {row['cumplimientoCalzada']==="Cumple" &&
                        <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                        }
                        {row['cumplimientoCalzada']==="No Aplica" &&
                        <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                        }
                    </TableRow>
                ))}
                </TableBody>
            </Table>

        )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/2"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} colSpan="2">ABSCISA</TableCell>
                            <TableCell style={{textAlign: "center"}} colspan="4">CARRIL IZQUIERDO (DESCENDENTE)</TableCell>
                            <TableCell style={{textAlign: "center"}} colspan="4">CARRIL DERECHO (ASCENDENTE)</TableCell>
                            <TableCell style={{textAlign: "center"}} rowSpan="2">Cumplimiento Indicador Calzada</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                            <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                            <TableCell style={{textAlign: "center"}}>Ahuellamiento (mm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                            <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                            <TableCell style={{textAlign: "center"}}>Ahuellamiento (mm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                            <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                            <TableCell style={{textAlign: "center"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abini'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abfin'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['ahuellamientoCizq'], 1)}</TableCell>
                            {row['cumplimientoCizq']==="No Cumple" &&
                            <TableCell style={{color: "red"}}>{row['cumplimientoCizq']}</TableCell>
                            }
                            {row['cumplimientoCizq']==="Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                            }
                            {row['cumplimientoCizq']==="No Aplica" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                            }
                            <TableCell style={{textAlign: "left"}}>{row['eventoCizq']}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['singularidadCizq']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['ahuellamientoCder'], 1)}</TableCell>
                            {row['cumplimientoCder']==="No Cumple" &&
                            <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                            }
                            {row['cumplimientoCder']==="Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                            }
                            {row['cumplimientoCder']==="No Aplica" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                            }
                            <TableCell style={{textAlign: "left"}}>{row['eventoCder']}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['singularidadCder']}</TableCell>
                            {row['cumplimientoCalzada']==="No Cumple" &&
                            <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                            }
                            {row['cumplimientoCalzada']==="Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                            }
                            {row['cumplimientoCalzada']==="No Aplica" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                            }
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
    
            )
            }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/3"){


                return(
                    <Table  aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{textAlign: "center"}}>Abscisa</TableCell>
                                <TableCell style={{textAlign: "center"}}>Tipo de fisura</TableCell>
                                <TableCell style={{textAlign: "center"}}>Severidad</TableCell>
                                <TableCell style={{textAlign: "center"}}>Largo (m)</TableCell>
                                <TableCell style={{textAlign: "center"}}>Ancho (m)</TableCell>
                                <TableCell style={{textAlign: "center"}}>Área (m²)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {datosCarga['hydra:member'].map((row, i)=>(
                            <TableRow>
                                <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{textAlign: "left"}}>{row['tipoFisura']}</TableCell>
                                <TableCell style={{textAlign: "right"}}>{row['severidad']}</TableCell>
                                <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['largo'], 2)}</TableCell>
                                <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['ancho'], 2)}</TableCell>
                                <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['area'], 2)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
        
                )
            }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/4"){
                return(
                    <Table  aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{textAlign: "center"}} colSpan="2">ABSCISA</TableCell>
                                <TableCell style={{textAlign: "center"}} colspan="4">CARRIL IZQUIERDO (DESCENDENTE)</TableCell>
                                <TableCell style={{textAlign: "center"}} colspan="4">CARRIL DERECHO (ASCENDENTE)</TableCell>
                                <TableCell style={{textAlign: "center"}} rowSpan="2">Cumplimiento Indicador Calzada</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                                <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                                <TableCell style={{textAlign: "center"}}>CFT</TableCell>
                                <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                                <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                                <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                                <TableCell style={{textAlign: "center"}}>CFT</TableCell>
                                <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                                <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                                <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                                <TableCell style={{textAlign: "center"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {datosCarga['hydra:member'].map((row, i)=>(
                            <TableRow>
                                <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abini'])}</TableCell>
                                <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abfin'])}</TableCell>
                                <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['cftCizq'], 2)}</TableCell>
                                {row['cumplimientoCizq']==="No Cumple" &&
                                <TableCell style={{color: "red"}}>{row['cumplimientoCizq']}</TableCell>
                                }
                                {row['cumplimientoCizq']==="Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                                }
                                {row['cumplimientoCizq']==="No Aplica" &&
                                <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                                }
                                <TableCell style={{textAlign: "left"}}>{row['eventoCizq']}</TableCell>
                                <TableCell style={{textAlign: "left"}}>{row['singularidadCizq']}</TableCell>
                                <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['cftCder'], 2)}</TableCell>
                                {row['cumplimientoCder']==="No Cumple" &&
                                <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                                }
                                {row['cumplimientoCder']==="Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                                }
                                {row['cumplimientoCder']==="No Aplica" &&
                                <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                                }
                                <TableCell style={{textAlign: "left"}}>{row['eventoCder']}</TableCell>
                                <TableCell style={{textAlign: "left"}}>{row['singularidadCder']}</TableCell>
                                {row['cumplimientoCalzada']==="No Cumple" &&
                                <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                                }
                                {row['cumplimientoCalzada']==="Cumple" &&
                                <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                                }
                                {row['cumplimientoCalzada']==="No Aplica" &&
                                <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                                }
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
        
                )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/5"){


            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} colSpan="2">ABSCISA</TableCell>
                            <TableCell style={{textAlign: "center"}} colspan="4">CARRIL IZQUIERDO (DESCENDENTE)</TableCell>
                            <TableCell style={{textAlign: "center"}} colspan="4">CARRIL DERECHO (ASCENDENTE)</TableCell>
                            <TableCell style={{textAlign: "center"}} rowSpan="2">Cumplimiento Indicador Calzada</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                            <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                            <TableCell style={{textAlign: "center"}}>MTD (mm) ASTM E1845</TableCell>
                            <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                            <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                            <TableCell style={{textAlign: "center"}}>MTD (mm) ASTM E1845</TableCell>
                            <TableCell style={{textAlign: "center"}}>Cumplimiento indicador puntual</TableCell>
                            <TableCell style={{textAlign: "center"}}>Evento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Singularidad</TableCell>
                            <TableCell style={{textAlign: "center"}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abini'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abfin'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['mtdCizq'], 2)}</TableCell>
                            {row['cumplimientoCizq']==="No Cumple" &&
                            <TableCell style={{color: "red"}}>{row['cumplimientoCizq']}</TableCell>
                            }
                            {row['cumplimientoCizq']==="Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                            }
                            {row['cumplimientoCizq']==="No Aplica" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCizq']}</TableCell>
                            }
                            <TableCell style={{textAlign: "left"}}>{row['eventoCizq']}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['singularidadCizq']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['mtdCder'], 2)}</TableCell>
                            {row['cumplimientoCder']==="No Cumple" &&
                            <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                            }
                            {row['cumplimientoCder']==="Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                            }
                            {row['cumplimientoCder']==="No Aplica" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCder']}</TableCell>
                            }
                            <TableCell style={{textAlign: "left"}}>{row['eventoCder']}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['singularidadCder']}</TableCell>
                            {row['cumplimientoCalzada']==="No Cumple" &&
                            <TableCell style={{color: "red", textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                            }
                            {row['cumplimientoCalzada']==="Cumple" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                            }
                            {row['cumplimientoCalzada']==="No Aplica" &&
                            <TableCell style={{textAlign: "left"}}>{row['cumplimientoCalzada']}</TableCell>
                            }
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
    
            )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/6"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                            <TableCell style={{textAlign: "center"}}>Ancho (cm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Largo (cm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Área (m²)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Profundidad (cm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                            <TableCell style={{textAlign: "center"}}>Carril</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['ancho'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['largo'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['area'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['profundidad'], 2)}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['observaciones']}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['carril']}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/7"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                            <TableCell style={{textAlign: "center"}}>Ancho (cm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Largo (cm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Área (m²)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Profundidad (cm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                            <TableCell style={{textAlign: "center"}}>Carril</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['ancho'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['largo'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['area'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['profundidad'], 2)}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['observaciones']}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['carril']}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/10"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >Elemento</TableCell>
                            <TableCell style={{textAlign: "center"}}>Retroreflectividad (cd/lux/m2) color principal</TableCell>
                            <TableCell style={{textAlign: "center"}}>Retroreflectividad (cd/lux/m2) color secundario</TableCell>
                            <TableCell style={{textAlign: "center"}}>Legibilidad de la señal</TableCell>
                            <TableCell style={{textAlign: "center"}}>Localización</TableCell>
                            <TableCell style={{textAlign: "center"}}>Estabilidad</TableCell>
                            <TableCell style={{textAlign: "center"}}>Estado de limpieza</TableCell>
                            <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{row['elementoSenalizacion']['fullCodigo']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['retroPrincipal']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['retroSecundario']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['legibilidad'] ? "Adecuada": "Inadecuada"}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['localizacion'] ? "Adecuada": "Inadecuada"}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['estabilidad'] ? "Adecuada": "Inadecuada"}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['estadoLimpieza'] ? "Adecuada": "Inadecuada"}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['observaciones']}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/11"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                            <TableCell style={{textAlign: "center"}}>Medida línea blanca borde derecho</TableCell>
                            <TableCell style={{textAlign: "center"}}>Medida línea blanca borde izquierdo</TableCell>
                            <TableCell style={{textAlign: "center"}}>Medida línea amarilla</TableCell>
                            <TableCell style={{textAlign: "center"}}>Cumplimiento delineadores de piso</TableCell>
                            <TableCell style={{textAlign: "center"}}>Tipo de equipo con geometría (cm)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['lineaBlancaDerecho']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['lineaBlancaIzquierdo']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['lineaAmarilla']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['delineadorPiso'] ? "Sí": "No"}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{i<= 0 ? row['tipoEquipo']: ""}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/15"){
            return(
                <Table  aria-label="simple table" style={{width: "50%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >Nivel de Tráfico: {datosCarga['hydra:member'][0]['nivelTrafico']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} >Abscisa</TableCell>
                            <TableCell style={{textAlign: "center"}}>Carril Izquierdo</TableCell>
                            <TableCell style={{textAlign: "center"}}>Carril Derecho</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['carrilIzquierdo'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['carrilDerecho'], 2)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/16"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}} colSpan="2">ABSCISA</TableCell>
                            <TableCell style={{textAlign: "center"}} rowSpan="2">PCI (%)</TableCell>
                            <TableCell style={{textAlign: "center"}} rowSpan="2">Observaciones</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                            <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abini'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abfin'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['pci']}</TableCell>
                            <TableCell style={{textAlign: "left"}}>{row['observaciones']}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
    
            )
        }
        else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/17"){
            return(
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}}>Abscisa Inicio</TableCell>
                            <TableCell style={{textAlign: "center"}}>Abscisa Final</TableCell>
                            <TableCell style={{textAlign: "center"}}>Código ASTM</TableCell>
                            <TableCell style={{textAlign: "center"}}>Alto</TableCell>
                            <TableCell style={{textAlign: "center"}}>Medio</TableCell>
                            <TableCell style={{textAlign: "center"}}>Bajo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abini'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abfin'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['codigo']}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['alto'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['medio'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['bajo'], 2)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
    
            )
        }else if(datosCarga['hydra:member'] && this.props.record.indicador==="/parametros/21"){
            return(
                <Table  aria-label="simple table" style={{width: "40%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{textAlign: "center"}}>Abscisa</TableCell>
                            <TableCell style={{textAlign: "center"}}>Escalonamiento (mm)</TableCell>
                            <TableCell style={{textAlign: "center"}}>Observaciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {datosCarga['hydra:member'].map((row, i)=>(
                        <TableRow>
                            <TableCell style={{textAlign: "right"}}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{this.formatovalores(row['escalonamiento'], 2)}</TableCell>
                            <TableCell style={{textAlign: "right"}}>{row['observaciones']}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
    
            )
        }
    else{
return (
    <div className="Reload2">
                    <img src={loader} />
                </div>
)
        }
    }
}

export default VistaDatosShow;
import React, {useState} from 'react';
import { SimpleForm,NumberInput, ArrayInput , SimpleFormIterator,minValue, maxValue} from 'react-admin';
import { Edit } from './../../Edit';
import { withStyles, Grid , Typography} from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import {ComponenteDeterioroPers} from '../componentes/ComponeteDeterioroPers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


export const ModeloCostosPersEdit = withStyles(styles)(({ classes, ...props }) => {
    const pci = [100,90,80,70,60,50,40,30,20,10,0];

    const  [variableAreaSegmento, setAreaSegmento] = useState();
    const ValorAreaSegmento = (value) =>(
        setAreaSegmento(value)
    )

//FUNCIONES PARA VARIABLE A FLEXIBLE
    const  [variableAFlex, setVariableAFlex] = useState();    
    const ValorAFlex = (value) =>(
        setVariableAFlex(value)
    )

    const  [variableBFlex, setVariableBFlex] = useState();
    const ValorBFlex = (value) =>(
        setVariableBFlex(value)
    )

    const  [variableXFlex, setVariableXFlex] = useState();    
    const ValorXFlex = (value) =>(
        setVariableXFlex(value)
    )

    const  [variableYFlex, setVariableYFlex] = useState();
    const ValorYFlex = (value) =>(
        setVariableYFlex(value)
    )


//FUNCIONES PARA VARIABLE A RIGIDO
    const  [variableARigd, setVariableARigd] = useState();    
    const ValorARigd = (value) =>(
        setVariableARigd(value)
    )

    const  [variableBRigd, setVariableB] = useState();
    const ValorABigd = (value) =>(
        setVariableB(value)
    )

    const  [variableXRigd, setVariableXRigd] = useState();    
    const ValorXRigd = (value) =>(
        setVariableXRigd(value)
    )

    const  [variableYRigd, setVariableY] = useState();
    const ValorYRigd = (value) =>(
        setVariableY(value)
    )

    const  [reparacionesConstante, setReparacionesConstante] = useState();    
    const  [resultadoCalculado, setResultadoCalculado] = useState([1,2,3]);    
    
    let costoReparaciones = [];
    let resultadoCalculo = []; 
    let costoReparacionesFlex= [];
    let resultadoCalculoFlex = []; 
    let envioPciDesc = [];
    // let calculoRigido = [];
    let calculoFlex = [];
    let envioCalculo = [];
    let calculoRigido = [];

    
    const TablaCostos = () =>{  
        
                
        let costoReparaciones = [];
        let resultadoCalculo = []; 
        
        const options = {
            chart: {
              type: "spline"
            },
            title: {
              text: "My chart"
            },
            series: [
              {
                name: "Valor de PCI",
                data: costoReparaciones
                
              }
            ],
            xAxis: {
                reversed: false,
                title: {
                  enabled: true,
                  text: 'Costo reparaciones  (miles $)'
                },
            }
            
        };

        
        return(
            <Grid item xs={6} style={{borderRight : '1px solid black'}}>
            <Grid container spacing={24} >
                <Grid item xs={12}>
                    <Typography variant="h6"  style={{textAlign : 'center'}}>RÍGIDO</Typography>
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={4}>   
                            <Typography variant="h6" >PCI</Typography>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={4}>   
                            <Typography variant="h6" >Costo rep</Typography>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={4}>   
                            <Typography variant="h6" gutterBottom>Costo reparaciones</Typography>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item xs={4}>       
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                        {pci.map((item,index) =>{ 
                            resultadoCalculo.push(variableARigd*Math.pow(item, -variableBRigd)*variableAreaSegmento) 
                            resultadoCalculo.map((items) =>{                            
                                if(index === 10)
                                {   
                                    if(item > variableXRigd || items < variableYRigd){
                                        costoReparaciones.push(0)
                                        // costoReparacionesA.push(0)
                                    }else{
                                        costoReparaciones.push(items)
                                        // costoReparacionesA.push(items)
                                    }
                                }                           
                            }) 
                            return(<Typography variant="h6" gutterBottom style={{textAlign : 'right'}}>{item}</Typography>)})
                        }
                        </Grid>  
                    </Grid>     
                </Grid>      
                <Grid item xs={4}>    
                    <Grid container spacing={24}>
                        <Grid item xs={12}>       
                            {resultadoCalculo.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed()}</Typography>)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={12}>   
                            {costoReparaciones.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed(2)}</Typography>)}
                        </Grid>
                    </Grid>                    
                </Grid>
                
            </Grid>
        </Grid>           
        )                      
    }

    const TablaCostosFlexible = () =>{  
        
                
        let costoReparaciones = [];
        let resultadoCalculo = []; 
        
        const options = {
            chart: {
              type: "spline"
            },
            title: {
              text: "My chart"
            },
            series: [
              {
                name: "Valor de PCI",
                data: costoReparaciones
                
              }
            ],
            xAxis: {
                reversed: false,
                title: {
                  enabled: true,
                  text: 'Costo reparaciones  (miles $)'
                },
            }
            
        };

        
        return(
            <Grid item xs={6} >
            <Grid container spacing={24} >                
                <Grid item xs={12}>
                    <Typography variant="h6"  style={{textAlign : 'center'}}>FLEXIBLE</Typography>
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={4}>   
                            <Typography variant="h6" gutterBottom>PCI</Typography>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={4}>   
                            <Typography variant="h6" gutterBottom>Costo rep</Typography>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={4}>   
                            <Typography variant="h6" gutterBottom>Costo reparaciones</Typography>
                        </Grid>
                    </Grid>                    
                </Grid>
                <Grid item xs={4}>       
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                        {pci.map((item,index) =>{ 
                            resultadoCalculo.push(variableAFlex*Math.pow(item, -variableBFlex)*variableAreaSegmento) 
                            resultadoCalculo.map((items) =>{                            
                                if(index === 10)
                                {   
                                    if(item > variableXFlex || items < variableYFlex){
                                        costoReparaciones.push(0)
                                        // costoReparacionesA.push(0)
                                    }else{
                                        costoReparaciones.push(items)
                                        // costoReparacionesA.push(items)
                                    }
                                }                           
                            }) 
                            return(<Typography variant="h6" style={{textAlign : 'right'}}  gutterBottom>{item}</Typography>)})
                        }
                        </Grid>  
                    </Grid>     
                </Grid>      
                <Grid item xs={4}>    
                    <Grid container spacing={24}>
                        <Grid item xs={12}>       
                            {resultadoCalculo.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed()}</Typography>)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>   
                    <Grid container spacing={24}>
                        <Grid item xs={12}>   
                            {costoReparaciones.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed(2)}</Typography>)}
                        </Grid>
                    </Grid>                    
                </Grid>
            </Grid>
        </Grid>
 
        )                      
    }
    
    return (
        <Edit {...props} undoable={false} title='Editar Deterioro PERS' >
            <SimpleForm redirect="list" >
                <Grid container spacing={24}>
                    <Grid item xs={12}>                       
                        <NumberInput label="Área del segmento m²" source='areaSegmento' validate={[ValorAreaSegmento]}/>
                    </Grid>
                    <Grid item xs={4} style={{marginTop: '30px'}}>
                        <Typography variant="h5" gutterBottom>
                            Costo de reparación = a*PCI-b*ÁREA SECCIÓN (m2)
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Costo = 0 si PCI es mayor a x
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Costo = 0 si costo reparación del segmento es menor a y
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>   
                        <Table>
                            <TableHead  >
                                <TableRow >
                                    <TableCell style={{fontWeight:' bolder', color : 'black', fontSize: '1.1em'}} >Variable</TableCell>
                                    <TableCell style={{fontWeight:' bolder', color : 'black', fontSize: '1.1em'}}>Flexible</TableCell>
                                    <TableCell style={{fontWeight:' bolder', color : 'black', fontSize: '1.1em'}}>Rígido</TableCell>
                                </TableRow>
                            </TableHead>       
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        a
                                    </TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableAFlex'  validate={[ValorAFlex,minValue(0)]}/></TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableARigd' validate={[ValorARigd,minValue(0)]}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        b
                                    </TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableBFlex' validate={[ValorBFlex,minValue(0),maxValue(1)]}/></TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableBRigd' validate={[ValorABigd,minValue(0),maxValue(1)]}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        x
                                    </TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableXFlex' validate={[ValorXFlex,minValue(0),maxValue(100)]}/></TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableXRigd' validate={[ValorXRigd,minValue(0),maxValue(100)]}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        y
                                    </TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableYFlex' validate={[ValorYFlex,minValue(0)]}/></TableCell>
                                    <TableCell align="right"><NumberInput label='' source='variableYRigd' validate={[ValorYRigd,minValue(0)]}/></TableCell>
                                </TableRow>
                            </TableBody>         
                        </Table>         
                    </Grid>
                    <Grid item xs={6} style={{borderRight : '1px solid black'}}>
                <Grid container spacing={24} >
                    <Grid item xs={12}>
                        <Typography variant="h6"  style={{textAlign : 'center'}}>RÍGIDO</Typography>
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={4}>   
                                <Typography variant="h6" >PCI</Typography>
                            </Grid>
                        </Grid>                    
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={4}>   
                                <Typography variant="h6" >Costo rep</Typography>
                            </Grid>
                        </Grid>                    
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={4}>   
                                <Typography variant="h6" gutterBottom>Costo reparaciones</Typography>
                            </Grid>
                        </Grid>                    
                    </Grid>
                    <Grid item xs={4}>       
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                            {pci.map((item,index) =>{ 
                                 let envioPci = {
                                    pci : item
                                }
                                envioPciDesc.push(envioPci);
                                resultadoCalculo.push(variableARigd*Math.pow(item, -variableBRigd)*variableAreaSegmento) 
                                resultadoCalculo.map((items , i) =>{                            
                                    if(index === 10)
                                    {   
                                        if(item > variableXRigd || items < variableYRigd){
                                            costoReparaciones.push(0)
                                            // costoReparacionesA.push(0)
                                            let datos ={
                                                pci : pci[i],
                                                costoReparaciones : 0,
                                                costoRep : parseInt(items.toFixed()),
                                            }
                                            calculoRigido.push(datos);   
                                        }else{
                                            costoReparaciones.push(items)                                            
                                            let datos ={
                                                pci : pci[i],
                                                costoReparaciones : items,
                                                costoRep : parseInt(items.toFixed()),
                                            }
                                            calculoRigido.push(datos);
                                        }
                                    }                            
                                }) 
                                return(<Typography variant="h6" gutterBottom style={{textAlign : 'right'}}>{item}</Typography>)})
                            }
                            </Grid>  
                        </Grid>     
                    </Grid>      
                    <Grid item xs={4}>    
                        <Grid container spacing={24}>
                            <Grid item xs={12}>       
                                {resultadoCalculo.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed()}</Typography>)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={12}>   
                                {costoReparaciones.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed(2)}</Typography>)}
                            </Grid>
                        </Grid>                    
                    </Grid>
                    
                </Grid>
            </Grid> 
            

            <Grid item xs={6} >
                <Grid container spacing={24} >                
                    <Grid item xs={12}>
                        <Typography variant="h6"  style={{textAlign : 'center'}}>FLEXIBLE</Typography>
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={4}>   
                                <Typography variant="h6" gutterBottom>PCI</Typography>
                            </Grid>
                        </Grid>                    
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={4}>   
                                <Typography variant="h6" gutterBottom>Costo rep</Typography>
                            </Grid>
                        </Grid>                    
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={4}>   
                                <Typography variant="h6" gutterBottom>Costo reparaciones</Typography>
                            </Grid>
                        </Grid>                    
                    </Grid>
                    <Grid item xs={4}>       
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                            {pci.map((item,index) =>{ 
                                resultadoCalculoFlex.push(variableAFlex*Math.pow(item, -variableBFlex)*variableAreaSegmento) 
                                resultadoCalculoFlex.map((items, i) =>{                            
                                    if(index === 10)
                                    {   
                                        if(item > variableXRigd || items < variableYRigd){
                                            costoReparacionesFlex.push(0)
                                            // costoReparacionesA.push(0)
                                            let datos ={
                                                pci : pci[i],
                                                costoReparacionesFlex : 0,
                                                costoRepFlex : parseInt(items.toFixed()),
                                            }
                                            calculoRigido.push(datos);   
                                        }else{
                                            costoReparacionesFlex.push(items)                                            
                                            let datos ={
                                                pci : pci[i],
                                                costoReparacionesFlex : items,
                                                costoRepFlex : parseInt(items.toFixed()),
                                            }
                                            calculoRigido.push(datos);
                                        }
                                    }                           
                                }) 
                                return(<Typography variant="h6" style={{textAlign : 'right'}}  gutterBottom>{item}</Typography>)})
                            }
                            </Grid>  
                        </Grid>     
                    </Grid>      
                    <Grid item xs={4}>    
                        <Grid container spacing={24}>
                            <Grid item xs={12}>       
                                {resultadoCalculoFlex.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed()}</Typography>)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>   
                        <Grid container spacing={24}>
                            <Grid item xs={12}>   
                                {costoReparacionesFlex.map(item => <Typography variant="h6" style={{textAlign : 'right'}} gutterBottom>{item.toFixed(2)}</Typography>)}
                            </Grid>
                        </Grid>                    
                    </Grid>
                </Grid>
            </Grid>
            </Grid> 
            <ArrayInput source="calculoFlex" style={{display : 'none'}} defaultValue={calculoFlex}>
                <SimpleFormIterator>
                    <NumberInput source="pci" />
                    <NumberInput source="costoReparacionesFlex" />
                    <NumberInput source="costoRepFlex" />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="calculoRigido" style={{display : 'none'}} defaultValue={calculoRigido}>
                <SimpleFormIterator>
                    <NumberInput source="pci" />
                    <NumberInput source="costoReparaciones" />
                    <NumberInput source="costoRep" />
                </SimpleFormIterator>
            </ArrayInput> 
            </SimpleForm>
        </Edit>
    )
});
import React, {useState} from 'react';
import {  FormTab  ,required, TextInput, NumberInput, ReferenceInput, SelectInput, 
          AutocompleteInput,Query 
    } from 'react-admin';
import { PimsCreate } from '../Create';
import Grid from '@material-ui/core/Grid';
import {Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import { TabbedForm } from './../TabbedForm';
import UniqueEmail from './../componentes/UniqueEmail';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const contrasena =()=>{

        let clave1 = document.getElementById("clave1").value;
        let clave2 = document.getElementById("clave2").value;

        
        if (clave1 !== clave2) 
        {
           return "La contraseña no coinciden";
          
        }
        // else{
        //     console.log('contraseña iguales');
        // }

}



export const UsuarioCreate = props => {
    const [emailExist, setEmailExist] = useState(false);

// const validarEmail=()=>{
//     var texto = document.getElementById("emails").value;
//     var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
//     if (!regex.test(texto)) {
//        return ("Correo Invalido");
//     } 
//     else {
//         fetch(urlAppjs+"/usuarios?username="+texto,
//             {headers:{
//                 'authorization': 'Bearer '+localStorage.getItem('token')
//             }
//             })
//             .then((response) => {
//                 return response.json()
//             })
//             .then((response) => {
//                 //AMendez@dynatest.com
//                 if (response["hydra:member"].length != 0) {
//                     setEmailExist(true)
//                     //fetchResponse("El e-mail ya se encuentra en uso")
//                 } else {
//                     setEmailExist(false)
//                 }
//             });
//     }
//   }
// const compobarEmail = () => {
//     if (emailExist == true) {
//         return "El e-mail ya se encuentra en uso";
//     } 
// }
    const[rol, setRol] = useState('');
    const ValidarAdminRol = (value) =>{
        setRol(value)
    }
    return(
    <PimsCreate title="Crear Usuario" {...props}>
         <TabbedForm submitOnEnter={false} redirect="list" >        
            <FormTab label="Identificación">        
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        <TextInput label="Nombres" source="nombres" fullWidth validate={[required()]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="Apellidos" source="apellidos" fullWidth validate={[required()]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput label="Tipo Documento" source="tipo_documento" sort={{ field: 'name', order: 'ASC' }} choices={[
                            {id:'Cédula Ciudadanía',name:'Cédula Ciudadanía'},
                            {id:'Cédula Extranjería',name:'Cédula Extranjería'},
                            {id:'NIT',name:'NIT'},
                        ]} fullWidth validate={[required()]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="Identificación" source="identificacion" fullWidth validate={[required()]}/>
                    </Grid>
                    <Grid item xs={8}>
                        {/* <TextInput 
                            label="Correo electrónico" 
                            source="username" 
                            id="emails" 
                            fullWidth 
                            onBlur={compobarEmail}
                            validate={[validarEmail, required()]}
                            autoComplete="new-password"
                        /> */}
                        <UniqueEmail />
                    </Grid>                    
                    <Grid item xs={4}>
                        <ReferenceInput 
                            label="Rol" 
                            source="admin"  
                            reference="atributos" 
                            filterToQuery={searchText => ({ nombre: searchText })} 
                            sort={{field : 'nombre', order : 'asc'}}
                            alwaysOn
                            fullWidth 
                            validate={[required(), ValidarAdminRol]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  id='adminRol'/>
                        </ReferenceInput>
                    </Grid>                                        
                    {                                    
                        <Query type="GET_ONE" resource="atributos" payload={{ id: rol}} >
                            {({ data, loading, error }) => {                                                
                                if( data){
                                    return <TextInput  style={{display:'none'}} label="NombreRol" defaultValue={data.nombre} source="adminRol" fullWidth validate={[required()]}/>;
                                }
                                return <div></div>;                                                
                            }}
                        </Query>                            
                    }                                                
                    <Grid item xs={6}>
                        <TextInput  type='password' label="Contraseña" autoComplete="new-password"  source="password" id="clave1" fullWidth validate={[required()]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput  type='password' label="Repita Contraseña" autoComplete="new-password" source="plainPassword" id="clave2" fullWidth validate={[contrasena,required()]}/>   
                    </Grid>                    
                                       
                </Grid>
            </FormTab>  
        </TabbedForm>
    </PimsCreate>
)};
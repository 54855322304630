import React, {useState, Fragment} from 'react';
import { NumberInput,SelectInput,ReferenceInput, TextInput,FormDataConsumer, ReferenceArrayInput, AutocompleteInput,
    AutocompleteArrayInput, required, minLength, maxLength, minValue, maxValue, email, number, REDUX_FORM_NAME 
} from 'react-admin';
import { change } from 'redux-form';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';
 
import Grid from '@material-ui/core/Grid';



export const Contacto_emergenciaCreate= (props) => {
    const [departamentoss , setDepartamento]= useState('');

    const ValidarDepartamento = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(value) 
    }

    return(
    <PimsCreate title="Crear Contacto" {...props}>
        <SimpleForm submitOnEnter={false} redirect="list">      
            <Grid container spacing={24}>
            <Grid item xs={4}>
                    <ReferenceInput label="Proyecto" source="proyecto" reference="proyectos" validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                        <SelectInput  optionText="nombre" disabled fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    <SelectInput id="grupoIngreso" label="Grupo" source="grupo" allowEmpty emptyValue="" fullWidth choices={[
                        { id: 'Alcaldía', name: 'Alcaldía' },
                        { id: 'Bomberos', name: 'Bomberos' },
                        { id: 'Centro educativo', name: 'Centro educativo' },
                        { id: 'Defensa civil', name: 'Defensa civil' },
                        { id: 'Ejército', name: 'Ejército' },
                        { id: 'Emisora radial', name: 'Emisora radial' },
                        { id: 'Interventoría', name: 'Interventoría' },
                        { id: 'Maquinaria', name: 'Maquinaria' },
                        { id: 'Otros', name: 'Otros' },
                        { id: 'POB-Administrativa', name: 'POB-Administrativa' },
                        { id: 'POB-Ambulancia', name: 'POB-Ambulancia' },
                        { id: 'POB-Carro taller', name: 'POB-Carro taller' },
                        { id: 'POB-Grúas', name: 'POB-Grúas' },
                        { id: 'POB-Peajes', name: 'POB-Peajes' },
                        { id: 'POB-Radio Operación', name: 'POB-Radio Operación' },
                        { id: 'POB-Supervisión vial', name: 'POB-Supervisión vial' },
                        { id: 'Policía', name: 'Policía' },
                        { id: 'Servicios médicos', name: 'Servicios médicos' },
                        { id: 'Talleres', name: 'Talleres' },
                    ]}  validate={[required()]}/>
                
                </Grid>         
                <Grid item xs={4}>
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (  formData != null && formData.grupo === 'Servicios médicos' &&
                                <NumberInput source="nivel" fullWidth  validate={[minValue(1), maxValue(5)]}/> 
                            )
                        }
                    </FormDataConsumer>
                </Grid>        
                
                <Grid item xs={4}>
                <SelectInput label="Tipo de dentificación"  source="identificacion" allowEmpty emptyValue=""  validate={[required()]} fullWidth choices={[
                        { id: 'CC', name: 'CC' },
                        { id: 'NIT', name: 'NIT' },
                        { id: 'Otro', name: 'Otro' },
                        
                    ]} />
                </Grid>
                <Grid item xs={4}>
                    <NumberInput label="Número de Identificación" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10),minValue(11111),maxValue(99999999999)]}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="Nombres" source="nombre" fullWidth validate={[ minLength(2), maxLength(100)]}/>
                </Grid>                
                <Grid item xs={4}>
                    <TextInput label="Apellidos" source="apellido" fullWidth validate={[minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="cargo" fullWidth validate={[ minLength(1), maxLength(20)]}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="Dirección" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                </Grid>    
                {/* <Grid item xs={4}>
                    <ReferenceInput 
                        label="Departamento" 
                        source="departamento" 
                        reference="departamentos" 
                        filterToQuery={searchText => ({ nombre: searchText })} 
                        validate={[required(), ValidarDepartamento]}
                    >
                        <AutocompleteInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid> */}
                <Grid item xs={8}>
                <FormDataConsumer>
                    {
                        ({ formData, dispatch, ...rest }) => (
                            <Fragment>
                                <Grid container spacing={24}>
                                    <Grid item xs={6}>
                                        <ReferenceInput  
                                            id="departamento" 
                                            value="" 
                                            label="Departamento" 
                                            source="departamento" 
                                            reference="departamentos"
                                            fullWidth 
                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                            onChange ={value => dispatch(
                                                            change(REDUX_FORM_NAME, 'municipio', null)
                                                        )}
                                            validate={[required(), ValidarDepartamento]}
                                        >
                                            <AutocompleteInput options={{fullWidth: true}}  optionText="nombre" fullWidth />
                                        </ReferenceInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                         {/* MUNICIPIOS POR DEPARTAMENTO */}                    
                    {
                        departamentoss === '/departamentos/05' && (
                            <ReferenceArrayInput label="Municipio" source="municipio1" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/05'}} fullWidth>
                                <AutocompleteArrayInput  optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/08' && (
                            <ReferenceArrayInput label="Municipio" source="municipio2" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/08'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/11' && (
                            <ReferenceArrayInput label="Municipio" source="municipio3" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/11'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/13' && (
                            <ReferenceArrayInput label="Municipio" source="municipio4" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/13'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/15' && (
                            <ReferenceArrayInput label="Municipio" source="municipio5" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/15'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/17' && (
                            <ReferenceArrayInput label="Municipio" source="municipio6" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/17'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/18' && (
                            <ReferenceArrayInput label="Municipio" source="municipio7" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/18'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/19' && (
                            <ReferenceArrayInput label="Municipio" source="municipio8" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/19'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/20' && (
                            <ReferenceArrayInput label="Municipio" source="municipio9" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/20'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/23' && (
                            <ReferenceArrayInput label="Municipio" source="municipio10" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/23'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }                    
                    {
                        departamentoss === '/departamentos/25' && (
                            <ReferenceArrayInput label="Municipio" source="municipio11" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/25'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>                            
                        )
                    }
                    {
                        departamentoss === '/departamentos/27' && (
                            <ReferenceArrayInput label="Municipio" source="municipio12" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/27'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>                            
                        )
                    }
                    {
                        departamentoss === '/departamentos/41' && (
                            <ReferenceArrayInput label="Municipio" source="municipio13" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/41'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>                            
                        )
                    }
                    {
                        departamentoss === '/departamentos/44' && (
                            <ReferenceArrayInput label="Municipio" source="municipio14" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/44'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/47' && (
                            <ReferenceArrayInput label="Municipio" source="municipio15" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/47'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>  
                        )
                    }
                    {
                        departamentoss === '/departamentos/50' && (
                            <ReferenceArrayInput label="Municipio" source="municipio16" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/50'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/52' && (
                            <ReferenceArrayInput label="Municipio" source="municipio17" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/52'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/54' && (
                            <ReferenceArrayInput label="Municipio" source="municipio18" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/54'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/63' && (
                            <ReferenceArrayInput label="Municipio" source="municipio19" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/63'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/66' && (
                            <ReferenceArrayInput label="Municipio" source="municipio20" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/66'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/68' && (
                            <ReferenceArrayInput label="Municipio" source="municipio21" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/68'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>  
                        )
                    }
                    {
                        departamentoss === '/departamentos/70' && (
                            <ReferenceArrayInput label="Municipio" source="municipio22" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/70'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/73' && (
                            <ReferenceArrayInput label="Municipio" source="municipio23" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/73'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/76' && (
                            <ReferenceArrayInput label="Municipio" source="municipio24" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/76'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/81' && (
                            <ReferenceArrayInput label="Municipio" source="municipio25" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/81'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/85' && (
                            <ReferenceArrayInput label="Municipio" source="municipio26" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/85'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/86' && (
                            <ReferenceArrayInput label="Municipio" source="municipio27" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/86'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/88' && (
                            <ReferenceArrayInput label="Municipio" source="municipio28" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/88'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/91' && (
                            <ReferenceArrayInput label="Municipio" source="municipio29" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/91'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/94' && (
                            <ReferenceArrayInput label="Municipio" source="municipio30" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/94'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/95' && (
                            <ReferenceArrayInput label="Municipio" source="municipio31" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/95'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/97' && (
                            <ReferenceArrayInput label="Municipio" source="municipio32" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/97'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/99' && (
                            <ReferenceArrayInput label="Municipio" source="municipio33" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/99'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ) 
                    }
                    </FormDataConsumer>  
                </Grid>           
                <Grid item xs={6}>
                    <TextInput label="Teléfono 1"   source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]}/> 
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Teléfono 2"  source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]}/>
                </Grid>
                               
                
                <Grid item xs={12}>
                    <TextInput label="Correo electrónico" source="correo" fullWidth validate={[ minLength(2), email("Debe ser un e-mail"), maxLength(100, "No mas de 100 caracteres")]}/>
                </Grid> 
                <Grid item xs={12}>
                    <TextInput source="observaciones" fullWidth validate={[minLength(2), maxLength(200, "No mas de 200 caracteres")]}/>
                </Grid>
                
            </Grid>
        </SimpleForm>
    </PimsCreate>
)};
import React, { useState } from 'react';
import {
    Toolbar, CardActions, Tab, NumberField, ShowController, ShowView, TextField, DateField,
    ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, ArrayField, FormDataConsumer,
    SingleFieldList, FormTab, RichTextField, ReferenceArrayField, Datagrid
} from 'react-admin';
import ShowElements from './../ShowElements';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
let urlAppjs = config.UrlServer;


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const PoliticasMantenimientoShow = withStyles(styles)(({ classes, record, ...props }) => {

    return (
        <ShowController {...props} title="Información Política de Mantenimiento">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Política de Mantenimiento" actions={<PostShowActions />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="INFORMACION">
                            {/* <TextField
                                label="Nombre de política"
                                source="nombre"
                                fullWidth
                                

                            /> */}
                            <ReferenceField
                                label="Nombre de la Política"
                                source="nombre"
                                reference="politicas_nombres"
                                linkType={false}
                                formClassName={classes.grid_cont4}
                                fullWidth
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>
                            <ReferenceField
                                label="Sistema"
                                source="sistema"
                                reference="sistemas"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont4}
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>
                            {controllerProps.record &&
                                <ShowElements politica={controllerProps.record.id} sistema={controllerProps.record.sistema} />
                            }
                            

                        </FormTab>
                        <FormTab label="AuditorÍa">
                            <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
});
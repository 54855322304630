import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    BooleanInput, ReferenceInput, SelectInput, DateInput, AutocompleteInput,
    TextInput, NumberInput, ArrayInput, SimpleFormIterator, Loading
} from 'react-admin';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styles } from './../../../EstilosGrid';
import { Divider } from '@material-ui/core';
import { CurrencyInput } from './../../../inputs/CurrencyInput';
import { PRInput } from '../../../inputs/PRInput';

import config from '../../../config/config';
let urlAppjs = config.UrlServer;

/**
 * @param parentProps = el record del Edit enviado mediante formData
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class EditElement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Elementos: [],
            datos: [],
            politicas: {
                rutinario: [],
                periodico: [],
                rehabilitacion: [],
                cambio: []
            },
            politicasParent : [],
            isLoadedPolicy: {
                rutinario: false,
                periodico: false,
                rehabilitacion: false,
                cambio: false
            },
            isLoadedElements : false

        }
        this.sources = {
            ZINT: "elemento_alrededors", SEPD: "elemento_alrededors", OBST: "elemento_alrededors", MARG: "elemento_alrededors",
            COMR: "elemento_alrededors",

            PART: "adoquin_detalles",

            POTR: "otro_detalles",

            ZCOR: "elemento_drenajes", POZT: "elemento_drenajes", DREN: "elemento_drenajes", DESC: "elemento_drenajes",
            CUNT: "elemento_drenajes", BOXC: "elemento_drenajes", BAJT: "elemento_drenajes", ALCT: "elemento_drenajes",

            INIV: "otros_elementos", IDES: "otros_elementos", CASC: "otros_elementos",

            PNTE: "elemento_contencions", MCON: "elemento_contencions", ESTE: "elemento_contencions", CONT: "elemento_contencions",

            SOS: "elemento_reds", SEM: "elemento_reds", PST: "elemento_reds", PMZ: "elemento_reds", LUM: "elemento_reds",
            ETD: "elemento_reds", ENL: "elemento_reds", CTV: "elemento_reds", CSP: "elemento_reds", BDP: "elemento_reds",
            ANT: "elemento_reds",

            TPVH: "seguridads", PRTP: "seguridads", DEFMT: "seguridads", CERC: "seguridads", CAPT: "seguridads",
            BRRJ: "seguridads", BRRF: "seguridads", BRRC: "seguridads", BORD: "seguridads",

            ZESC: "elemento_senalizacions", SVERV: "elemento_senalizacions", SVERR: "elemento_senalizacions", SVERP: "elemento_senalizacions",
            SVERI: "elemento_senalizacions", SHL: "elemento_senalizacions", SHCT: "elemento_senalizacions", SHBD: "elemento_senalizacions",
            REC: "elemento_senalizacions", PPT: "elemento_senalizacions",

            TRAF: "elemento_traficos",
        }
        this.stagedProps = {
            tipoElemento : this.props.tipoElemento,
            tramo : this.props.tramo,
            abInicio : this.props.abInicio,
            abFin : this.props.abFin
        }
        this.fetcher.bind(this);
        this.fetchPolicies.bind(this);
        this.fetchParentPolicies.bind(this);
        this.returnElementsComponent.bind(this);

    }

    componentDidMount() {
        // console.log(this.props.parentProps)
        this.fetcher(this.sources[this.props.tipoElemento.split("/")[2]], this.props.tramo, this.props.abInicio, this.props.abFin);
        this.fetchPolicies("rutinario", this.props.tipoElemento);
        this.fetchPolicies("periodico", this.props.tipoElemento);
        this.fetchPolicies("rehabilitacion", this.props.tipoElemento);
        this.fetchPolicies("cambio", this.props.tipoElemento);
    }
    componentDidUpdate(){
        setTimeout(() => {
            if(this.stagedProps.tipoElemento != this.props.tipoElemento ||
                this.stagedProps.tramo != this.props.tramo ||
                this.stagedProps.abInicio != this.props.abInicio||
                this.stagedProps.abFin != this.props.abFin
            ) {
                this.stagedProps.tipoElemento = this.props.tipoElemento;
                this.stagedProps.tramo = this.props.tramo;
                this.stagedProps.abInicio = this.props.abInicio;
                this.stagedProps.abFin = this.props.abFin;

                this.state.Elementos = [];
                this.state.datos = [];
                this.state.politicas.rutinario = [];
                this.state.politicas.periodico = [];
                this.state.politicas.rehabilitacion = [];
                this.state.politicas.cambio = [];

                this.state.isLoadedPolicy.rutinario = false;
                this.state.isLoadedPolicy.periodico = false;
                this.state.isLoadedPolicy.rehabilitacion = false;
                this.state.isLoadedPolicy.cambio = false;

                this.componentDidMount();
            }

        }, 1000)
    }

    fetcher(target, tramo,  abInicio, abFin) {
        this.setState({isLoadedElements : false});

        var urlPart = "/" + target + "?pagination=off&segmento.tramo=" + tramo;
        if(abInicio > 0) {
            urlPart = urlPart + "&segmento.abInicio[gte]=" + abInicio;
        }

        if(abFin > 0) {
            urlPart = urlPart + "&segmento.abFin[lte]=" + abFin;
        }

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                this.state.datos = [];
                this.setState({ 
                    Elementos: Elementos["hydra:member"]
                })
                this.state.Elementos.map(index => {
                    datos = {
                        seRealiza : false,
                        segmento: index.segmento["fullCodigo"],
                        tipoElemento : this.props.tipoElemento,
                        id : index.id, 
                        elementoInventarioId: "/" + target + "/" + index.id,
                        abInicio: index.segmento.abInicio,
                        abFin: index.segmento.abFin
                    }
                    this.state.datos.push(datos);
                })
            })
            .then(() => {
                urlPart = "/politicas_particulares_items?pagination=off&politicasMantenimientoParticular=" + this.props.parentProps.id;
                fetch(urlAppjs + urlPart,
                    {
                        headers: {
                            'authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((ElementosParticulares) => {
                        // console.log(ElementosParticulares);
                        ElementosParticulares["hydra:member"].map(element => {
                            this.state.datos.map( (dato, index) => {
                                if(element.elementoInventarioId.split("/")[2] == dato.id) {
                                    this.state.datos[index].seRealiza = true;
                                }
                            })
                        })
                        // console.log(this.state.datos);
                        this.setState({ 
                            isLoadedElements : true
                        })
                        this.forceUpdate();
                    })
            })
    }
    fetchPolicies(tipoMant, tipoElemento) {
        var entityMant = "";
        switch (tipoMant) {
            case "rutinario": entityMant = "politicas_elementos"; break;
            case "periodico": entityMant = "politicas_elemento_periodicos"; break;
            case "rehabilitacion": entityMant = "politicas_elemento_rehabilitacions"; break;
            case "cambio": entityMant = "politicas_elemento_cambios"; break;
        }

        var urlPart = "/" + entityMant + 
                        "?pagination=off&tipoElemento=" + tipoElemento + 
                        "&seRealiza=1";

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.state.politicas[tipoMant] = [];
                var datos = [];
                Elementos["hydra:member"].map(index => {
                    datos = {
                        politicasMantenimiento : index.politicasMantenimiento,
                        valorU2: index.valorU,
                        fuente2: index.fuente,
                        fecha2: index.fecha,
                        descripcion2:index.descripcion
                    }
                    if(index.hasOwnProperty("prolVidaUtil")) {
                        datos.prolVidaUtil2 = index.prolVidaUtil;
                    }
                    if(tipoMant == "rutinario" || tipoMant == "periodico") {
                        if(index.periocidad == 1) {
                            datos.periocidad2 = index.periocidad + " Mes";
                        } else {
                            datos.periocidad2 = index.periocidad + " Meses";
                        }
                    } else {
                        datos.periocidad2 = index.periocidad + " Años";
                    }
                    this.state.politicas[tipoMant].push(datos);
                })
                // this.forceUpdate();
            })
            .then(()=> {
                urlPart = "/" + entityMant.slice(0, -1) + 
                        "_particulars?pagination=off&politicasMantenimientoParticular=" + this.props.parentProps.id;
                // urlPart = "/politicas_particulares_items?pagination=off&politicasMantenimientoParticular=" + this.props.parentProps.id;
                fetch(urlAppjs + urlPart,
                    {
                        headers: {
                            'authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((ElementosParticulares) => {
                        ElementosParticulares["hydra:member"].map(particular => {
                            this.state.politicas[tipoMant].map((general, index) => {
                                if(particular.politicasMantenimiento == general.politicasMantenimiento) {
                                    // console.log(this.state.politicas[tipoMant][index], "<==>", particular)
                                    this.state.politicas[tipoMant][index].seRealiza = particular.seRealiza;
                                    this.state.politicas[tipoMant][index].periocidad = particular.periocidad;
                                    this.state.politicas[tipoMant][index].periocidad = particular.periocidad;
                                    this.state.politicas[tipoMant][index].politicasMantenimiento  = particular.politicasMantenimiento;
                                    this.state.politicas[tipoMant][index].valorU = particular.valorU;
                                    this.state.politicas[tipoMant][index].fuente = particular.fuente;
                                    this.state.politicas[tipoMant][index].fecha = particular.fecha;
                                    this.state.politicas[tipoMant][index].descripcion = particular.descripcion;
                                    if(particular.hasOwnProperty("prolVidaUtil")) {
                                        // console.log("VidaUtil");
                                        this.state.politicas[tipoMant][index].prolVidaUtil = particular.prolVidaUtil;
                                    }
                                }
                            })
                        })
                        // console.log(this.state.politicas[tipoMant]);
                        this.state.isLoadedPolicy[tipoMant] = true;
                        
                        this.forceUpdate();
                    })
            })
    }
    fetchParentPolicies(id) {
        var urlPart = "/politicas_mantenimientos?id=" + id;

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.state.politicasParent.push(Elementos["hydra:member"].id);
                // this.setState({ politicasParent: Elementos["hydra:member"] })
            })
    }

    returnElementsComponent(classes) {
        const diligenciado = (value) => {
            var errors= 0;
            var returnMsg = "";
            if (typeof value !== 'undefined') {
                for (var i = 0; i < value.length; i++) {
                    if (value[i].seRealiza != true) {
                        errors = 1;
                    } else {
                        errors=0;
                        break;
                    }
                }
                if(errors > 0) {
                    return "Debe escoger al menos un elemento.";
                }
            }
            
        }

        return (
            <ArrayInput
                label=" "
                source="politicasParticularesItems2"
                validate={[diligenciado]}
                defaultValue={this.state.datos}
            >
                <SimpleFormIterator
                    disableAdd 
                    disableRemove
                >
                    <TextInput disabled label="tipo elemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                    <TextInput disabled label="tipo elemento" source="elementoInventarioId" fullWidth formClassName={classes.ocultar} />
                    <TextInput disabled label="Segmento" source="segmento" fullWidth formClassName={classes.fieldCol5} />
                    <TextInput disabled label="ID" source="id" fullWidth formClassName={classes.fieldCol1} />
                    <PRInput disabled label="Abscisa Inicial" source="abInicio" fullWidth formClassName={classes.fieldCol2} />
                    <PRInput disabled label="Abscisa Final" source="abFin" fullWidth formClassName={classes.fieldCol2} />
                    <BooleanInput label="Se Aplica Política Particular" source="seRealiza" fullWidth formClassName={classes.fieldCol2} />
                </SimpleFormIterator>
            </ArrayInput>

        )

    }

    returnPoliciesComponent(tipoPolitica, classes) {
        var choices = [];
        var source = "";
        switch (tipoPolitica) {
            case "rutinario": 
                choices = [
                    { id: 1, name: '1 Mes' },
                    { id: 3, name: '3 Meses' },
                    { id: 6, name: '6 Meses' },
                    { id: 9, name: '9 Meses' },
                    { id: 12, name: '12 Meses' },
                    { id: 18, name: '18 Meses' },
                ];
                source="politicasElementoParticulars";
            break;
            case "periodico":
                choices = [
                    { id: 1, name: '1 Mes' },
                    { id: 3, name: '3 Meses' },
                    { id: 6, name: '6 Meses' },
                    { id: 9, name: '9 Meses' },
                    { id: 12, name: '12 Meses' },
                    { id: 18, name: '18 Meses' },
                    { id: 24, name: '24 Meses' },
                    { id: 36, name: '36 Meses' },
                ];
                source="politicasElementoPeriodicoParticulars";
            break;
            case "rehabilitacion": 
                choices = [
                    { id: 2, name: '2 Años' }, { id: 2.5, name: '2,5 Años' },
                    { id: 3, name: '3 Años' }, { id: 3.5, name: '3,5 Años' },
                    { id: 4, name: '4 Años' }, { id: 4.5, name: '4,5 Años' },
                    { id: 5, name: '5 Años' }, { id: 5.5, name: '5,5 Años' },
                    { id: 6, name: '6 Años' }, { id: 6.5, name: '6,5 Años' },
                    { id: 7, name: '7 Años' }, { id: 7.5, name: '7,5 Años' },
                    { id: 8, name: '8 Años' }, { id: 8.5, name: '8,5 Años' },
                    { id: 9, name: '9 Años' }, { id: 9.5, name: '9,5 Años' },
                    { id: 10, name: '10 Años'}
                ];
                source="politicasElementoRehabilitacionParticulars";
            break;
            case "cambio": 
                choices = [
                    { id: 3, name: '3 Años' },   { id: 4, name: '4 Años' },   { id: 5, name: '5 Años' },
                    { id: 6, name: '6 Años' },   { id: 7, name: '7 Años' },   { id: 8, name: '8 Años' },
                    { id: 9, name: '9 Años' },   { id: 10, name: '10 Años' }, { id: 11, name: '11 Años' },
                    { id: 12, name: '12 Años' }, { id: 13, name: '13 Años' }, { id: 14, name: '14 Años' },
                    { id: 15, name: '15 Años' }, { id: 16, name: '16 Años' }, { id: 17, name: '17 Años' },
                    { id: 18, name: '18 Años' }, { id: 19, name: '19 Años' }, { id: 20, name: '20 Años' },
                    { id: 21, name: '21 Años' }, { id: 22, name: '22 Años' }, { id: 23, name: '23 Años' },
                    { id: 24, name: '24 Años' }, { id: 25, name: '25 Años' }, { id: 26, name: '26 Años' },
                    { id: 27, name: '27 Años' }, { id: 28, name: '28 Años' }, { id: 29, name: '29 Años' },
                    { id: 30, name: '30 Años' }
                ];
                source="politicasElementoCambioParticulars";
            break;
        }
        const diligenciado = (value) => {
            var errorMsg = [];
            var policiesNumber=0;
            var returnMsg = "";
            var today = new Date();
            var month = "";
            if ((today.getMonth() + 1) < 10) {
                month = "0" + (today.getMonth() + 1)
            } else {
                month = (today.getMonth() + 1)
            }
            today = today.getFullYear() + "-" + month + "-" + today.getDate();

            if (typeof value !== 'undefined') {
                for (var i = 0; i < value.length; i++) {
                    if (value[i].seRealiza == true) {

                        policiesNumber++;

                        if (policiesNumber > 1) {
                            errorMsg[0] ="Sólo debe usar una política general como base para política particular. ";
                        } else if (value[i].fecha > today) {
                            errorMsg[0] ="La fecha indicada en la política seleccionada no puede ser futura. ";
                        } else if (
                            value[i].periocidad == null || value[i].periocidad == "" ||
                            value[i].valorU == null || value[i].valorU == "" ||
                            value[i].fuente == null || value[i].fuente == "" ||
                            value[i].fecha == null || value[i].fecha == "" ||
                            value[i].descripcion == null || value[i].descripcion == "") {
                                errorMsg[0] = "Debe diligenciar todos los datos de la política a modificar.";
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
            if (errorMsg.length > 0) {
                for (var i = 0; i < errorMsg.length; i++) {
                    returnMsg = returnMsg + errorMsg[i] + ". ";
                }
                return returnMsg;
            }
        }
        return (
            <ArrayInput
                label=" "
                source={source + "2"}
                validate={[diligenciado]}
                defaultValue={this.state.politicas[tipoPolitica]}
            >
                <SimpleFormIterator disableAdd disableRemove >
                    <ReferenceInput
                        label="Nombre de la Política"
                        source="politicasMantenimiento"
                        reference="politicas_mantenimientos"
                        disabled
                        fullWidth
                    >
                        <SelectInput optionText="nombre_.nombre" fullWidth />
                    </ReferenceInput>
                    <TextInput label="Periodicidad" source="periocidad2" disabled fullWidth formClassName={classes.fieldCol2} />
                    <CurrencyInput label="Valor Unitario" source="valorU2" disabled fullWidth className="align-number" formClassName={classes.fieldCol2} />
                    <TextInput label="Fuente" source="fuente2" disabled fullWidth formClassName={classes.fieldCol3} />
                    {tipoPolitica === "rutinario" &&
                        <DateInput label="Fecha" source="fecha2" disabled fullWidth formClassName={classes.fieldCol5} />
                    }
                    {tipoPolitica === "periodico" &&
                        <DateInput label="Fecha" source="fecha2" disabled fullWidth formClassName={classes.fieldCol5} />
                    }
                    {tipoPolitica === "rehabilitacion" &&
                        <NumberInput label="Prol. Vida Util" source="prolVidaUtil2" disabled fullWidth formClassName={classes.fieldCol2} />
                    }
                    {tipoPolitica === "rehabilitacion" &&
                        <DateInput label="Fecha" source="fecha2" disabled fullWidth formClassName={classes.fieldCol3} />
                    }
                    {tipoPolitica === "cambio" &&
                        <NumberInput label="Prol. Vida Util" source="prolVidaUtil2" disabled fullWidth formClassName={classes.fieldCol2} />
                    }
                     {tipoPolitica === "cambio" &&
                        <DateInput label="Fecha" source="fecha2" disabled fullWidth formClassName={classes.fieldCol3} />
                    }

                    <br />
                    <TextInput label="Descripción" source="descripcion2" disabled fullWidth formClassName={classes.fieldCol12} />

                    <Divider fullWidth />

                    <BooleanInput label="Se Modifica" source="seRealiza" fullWidth formClassName={classes.fieldCol12} />

                    {/**************************RUTINARIO************************************************** */}
                    {tipoPolitica === "rutinario" &&
                        <AutocompleteInput
                            label="Periodicidad"
                            source="periocidad"
                            fullWidth
                            options={{ fullWidth: true }}
                            choices={choices}
                            formClassName={classes.fieldCol3}
                        />
                    }
                    {tipoPolitica === "rutinario" && <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} /> }
                    {tipoPolitica === "rutinario" &&<TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />}
                    {tipoPolitica === "rutinario" && <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />}
                    
                    {/**************************PERIODICO************************************************** */}
                    {tipoPolitica === "periodico" &&
                        <AutocompleteInput
                            label="Periodicidad"
                            source="periocidad"
                            fullWidth
                            options={{ fullWidth: true }}
                            choices={choices}
                            formClassName={classes.fieldCol3}
                        />
                    }
                    {tipoPolitica === "periodico" && <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} /> }
                    {tipoPolitica === "periodico" &&<TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />}
                    {tipoPolitica === "periodico" && <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />}

                    {/**************************REHABILITACION************************************************** */}
                    {tipoPolitica === "rehabilitacion" &&
                        <AutocompleteInput
                            label="Periodicidad"
                            source="periocidad"
                            fullWidth
                            options={{ fullWidth: true }}
                            choices={choices}
                            formClassName={classes.fieldCol2}
                        />
                    }
                    {tipoPolitica === "rehabilitacion" && <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} /> }
                    {tipoPolitica === "rehabilitacion" && <TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />}
                    {tipoPolitica === "rehabilitacion" && <NumberInput label="Prol. Vida Util" source="prolVidaUtil"  fullWidth formClassName={classes.fieldCol2} />}
                    {tipoPolitica === "rehabilitacion" && <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />}

                    {/**************************CAMBIO************************************************** */}
                    {tipoPolitica === "cambio" &&
                        <AutocompleteInput
                            label="Periodicidad"
                            source="periocidad"
                            fullWidth
                            options={{ fullWidth: true }}
                            choices={choices}
                            formClassName={classes.fieldCol2}
                        />
                    }
                    {tipoPolitica === "cambio" && <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} /> }
                    {tipoPolitica === "cambio" && <TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />}
                    {tipoPolitica === "cambio" && <NumberInput label="Vida Util" source="prolVidaUtil"  fullWidth formClassName={classes.fieldCol2} />}
                    {tipoPolitica === "cambio" && <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />}
                    <TextInput label="Descripción" source="descripcion" fullWidth formClassName={classes.fieldCol12} />

                </SimpleFormIterator>
            </ArrayInput>
        )
    }

    render() {
        const { classes } = this.props;

        const preventSave = (value) => {
            return ("Debe seleccionar filtros válidos para diligenciar las políticas particulares de los elementos correspondientes.");
        }
        
        if (this.state.datos.length > 0 &&
            this.state.isLoadedPolicy.rutinario == true &&
            this.state.isLoadedPolicy.periodico == true &&
            this.state.isLoadedPolicy.rehabilitacion == true &&
            this.state.isLoadedPolicy.cambio == true &&
            this.state.isLoadedElements == true     
            ) {
            return (
                <Fragment>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Elementos</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnElementsComponent(classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Mantenimiento particular Rutinario</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnPoliciesComponent("rutinario", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Mantenimiento particular Periódico</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnPoliciesComponent("periodico", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Mantenimiento particular Rehabilitación</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnPoliciesComponent("rehabilitacion", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Mantenimiento particular Cámbio</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnPoliciesComponent("cambio", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Fragment>
            )
        } else if(this.state.Elementos.length == 0 && this.state.isLoadedElements == true) {
            
            return(
                <Fragment>
                    <ArrayInput
                        label=" "
                        source="preventSave"
                        validate={[preventSave]}
                    >
                        <SimpleFormIterator
                            disableAdd 
                            disableRemove
                        >
                            <TextInput disabled label="tipo elemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <div style={{textAlign : "center"}}>
                        <Typography variant="h2" component="h2">
                            Sin Resultados.
                        </Typography>
                    </div>
                </Fragment>
            )
            
        } else {
            return <Loading />
        }

    }
}

EditElement.propTypes = {
    classes: PropTypes.object.isRequired,
}
const EditElementosMantenimientoParticular = withStyles(styles)(EditElement);


export default EditElementosMantenimientoParticular;
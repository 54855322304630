import React, { Fragment } from 'react';
import { List, Datagrid,SelectField, NumberField,TextField, EditButton, ShowButton, Filter,  SelectInput,CloneButton} from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import EditButtonPims from '../EditButton'
// const FiltrosElementos = (props) => (
//     <Filter {...props}>
//         <SelectInput source="tipo" label="Tipo de Material"choices={choises2} alwaysOn/>
//     </Filter>
// );

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const TrayectoriasList = props => (
    <List {...props} sort={{ field:"updatedAt", order: 'DESC' }} title="Lista Alternativas" bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>    
                    <TextField label="Nombre de la alternativa" source="nombre" />
                    <NumberField source="precio" label="Precio total ($/m²)" />  
                    <NumberField source="preciocar" label="Precio carpeta ($/m²)" />  
                    <NumberField label="Vida esperada (años)" source="vida"  />
                    <NumberField label="Espesor removido (mm)" source="espesor" />
            <CloneButton />
            <ShowButton />
            <EditButton/>
        </Datagrid>
    </List>
);
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {
  NumberInput, ReferenceInput, SelectInput, TextInput, required, BooleanInput,Toolbar,FormTab, TabbedForm,
  DateInput, crudUpdateMany
} from 'react-admin';
import config from '../../../config/config';
import Grid from '@material-ui/core/Grid';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';


//----------Validaciones particulares del formulario

import {
  validarDosDecimales, maxLength50, maxLength40, maxCostMant
} from './../../../helpers/validacionesInputs';

//----------INICIO DE COMPONENTES DE MODAL----------
// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }
let urlAppjs = config.UrlServer;

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  ocultar: { display: "none" },
});

class SimpleModal extends React.Component {
  state = {
    open: false
  };

  values = {
    nombre : "",
    politica : "",
    denySave : true
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClick = () => {
    
    const closeModal = () => {
      this.setState({ open: false });
      window.location.reload();
    }
    fetch(urlAppjs + this.values.politica,
            {
                method : 'PUT',
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({nombre : this.values.nombre})
                
            })
            .then((response) => {
              closeModal()
            })
    
  };
 //----------FIN DE COMPONENTES DE MODAL----------
 
  render() { 
    const { classes } = this.props;
//seteo de valores de datos provinientes de inputs
const setPolitica = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    if (valor != "") {
        this.values.politica = valor
    }
    if (this.values.politica != "" && this.values.nombre != "") {
        this.values.denySave = false;
    } else {
        this.values.denySave = true
    }
}

const setNombre = (value) => {
    console.log(value.target.value);
    if (value.target.defaultvalue != "") {
        this.values.nombre = value.target.value
    }
    if (this.values.politica != "" && this.values.nombre != "") {
        this.values.denySave = false;
    } else {
        this.values.denySave = true
    }
}
    
    const CustomToolbar = ({ basePath, data, ...props }) => (
      <Toolbar {...props} >
          <Button onClick={this.handleClick} disabled={this.values.denySave}>
              <BeenhereIcon />Guardar
          </Button> 
          <Button onClick={this.handleClose}>
              <CancelIcon />Cerrar
          </Button> 
      </Toolbar>
  )
    return (
      <div>
        <Button onClick={this.handleOpen} style={{paddingLeft : 0}}><EditIcon /></Button>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div
            style={getModalStyle()}
            className={classes.paper}>
            <TabbedForm toolbar={<CustomToolbar />}>
                <FormTab label="Actualizar Nombre de Política">
                <Grid container spacing={24}>
                <Grid item xs={6}>
                  <ReferenceInput
                    label="Seleccione Política"
                    source="politicaNombre"
                    reference="politicas_nombres"
                    onChange={setPolitica}
                    validate={[required()]}
                  >
                    <SelectInput optionText="nombre" fullWidth />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    label="Nuevo Nombre"
                    source="fuente"
                    onChange={setNombre}
                    fullWidth
                  />
                </Grid>
              </Grid>
                </FormTab>
            </TabbedForm>
            {/* <SimpleModalWrapped2 /> */}
          </div>
        </Modal>
      </div>
    );
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped2 = withStyles(styles)(SimpleModal);

export default SimpleModalWrapped2;
// export default connect(undefined, { crudUpdateMany })(SimpleModalWrapped2);
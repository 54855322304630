import React , {useState} from 'react';
import { NumberInput,SelectInput,ReferenceInput, TextInput, AutocompleteArrayInput, email,
    ReferenceArrayInput, AutocompleteInput, number } from 'react-admin';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
} from 'react-admin';
 
import Grid from '@material-ui/core/Grid';

export const fooValidators = [minValue(5, 'valor minimo 5')];

export const Servicio_publicoCreate = props => {
    const [departamentoss , setDepartamento]= useState('');

    const ValidarDepartamento = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(value) 
    }

    const Departamento = { departamento: departamentoss };
    return(
    <PimsCreate {...props} title="Crear Servicios públicos">
        <SimpleForm submitOnEnter={false} redirect="list" >      
            <Grid container spacing={24}>
            <Grid item xs={4}>
                    <ReferenceInput label="Proyecto" source="proyecto" reference="proyectos" validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                        <SelectInput  optionText="nombre" disabled fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    <SelectInput label="Grupo" source="grupo" choices={[
                        { id: 'Acueducto', name: 'Acueducto' },
                        { id: 'Aseo', name: 'Aseo' },
                        { id: 'Energía', name: 'Energía' },
                        { id: 'Gas natural', name: 'Gas natural' },
                        { id: 'Otros', name: 'Otros' },
                        { id: 'Telecomunicaciones', name: 'Telecomunicaciones' },
                    ]} fullWidth validate={[required()]}/>
                </Grid>                
                <Grid item xs={4}></Grid>
                <Grid item xs={3}>
                <SelectInput label="Tipo de Identificación" fullWidth source="identificacion" allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'CC' },
                            { id: 'NIT', name: 'NIT' },
                            { id: 'Otro', name: 'Otro' },
                            
                        ]} validate={[required()]}/>  
                </Grid>
                <Grid item xs={3}>
                    <NumberInput label="Número de identificación" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10),minValue(1),maxValue(9999999999)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Nombres" source="nombre" fullWidth validate={[minLength(2), maxLength(100)]}/>
                </Grid>                
                <Grid item xs={6}>
                    <TextInput label="Apellidos"  source="apellido" fullWidth validate={[minLength(2), maxLength(100)]}/> 
                </Grid>
                
                <Grid item xs={4}>
                    <TextInput label="Dirección" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100), minValue(1)]}/>
                </Grid>               
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Departamento" 
                        source="departamento" 
                        reference="departamentos" 
                        filterToQuery={searchText => ({ nombre: searchText })}
                        validate={[required(), ValidarDepartamento]}
                    >
                        <AutocompleteInput options={{fullWidth : true}}  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    {/* MUNICIPIOS POR DEPARTAMENTO */}                    
                    {
                        departamentoss === '/departamentos/05' && (
                            <ReferenceArrayInput label="Distrito" source="municipio1" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/05'}} fullWidth>
                                <AutocompleteArrayInput  optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/08' && (
                            <ReferenceArrayInput label="Distrito" source="municipio2" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/08'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/11' && (
                            <ReferenceArrayInput label="Distrito" source="municipio3" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/11'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/13' && (
                            <ReferenceArrayInput label="Distrito" source="municipio4" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/13'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/15' && (
                            <ReferenceArrayInput label="Distrito" source="municipio5" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/15'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/17' && (
                            <ReferenceArrayInput label="Distrito" source="municipio6" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/17'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/18' && (
                            <ReferenceArrayInput label="Distrito" source="municipio7" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/18'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/19' && (
                            <ReferenceArrayInput label="Distrito" source="municipio8" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/19'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/20' && (
                            <ReferenceArrayInput label="Distrito" source="municipio9" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/20'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/23' && (
                            <ReferenceArrayInput label="Distrito" source="municipio10" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/23'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }                    
                    {
                        departamentoss === '/departamentos/25' && (
                            <ReferenceArrayInput label="Distrito" source="municipio11" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/25'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>                            
                        )
                    }
                    {
                        departamentoss === '/departamentos/27' && (
                            <ReferenceArrayInput label="Distrito" source="municipio12" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/27'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>                            
                        )
                    }
                    {
                        departamentoss === '/departamentos/41' && (
                            <ReferenceArrayInput label="Distrito" source="municipio13" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/41'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>                            
                        )
                    }
                    {
                        departamentoss === '/departamentos/44' && (
                            <ReferenceArrayInput label="Distrito" source="municipio14" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/44'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/47' && (
                            <ReferenceArrayInput label="Distrito" source="municipio15" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/47'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>  
                        )
                    }
                    {
                        departamentoss === '/departamentos/50' && (
                            <ReferenceArrayInput label="Distrito" source="municipio16" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/50'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/52' && (
                            <ReferenceArrayInput label="Distrito" source="municipio17" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/52'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/54' && (
                            <ReferenceArrayInput label="Distrito" source="municipio18" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/54'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/63' && (
                            <ReferenceArrayInput label="Distrito" source="municipio19" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/63'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/66' && (
                            <ReferenceArrayInput label="Distrito" source="municipio20" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/66'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/68' && (
                            <ReferenceArrayInput label="Distrito" source="municipio21" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/68'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>  
                        )
                    }
                    {
                        departamentoss === '/departamentos/70' && (
                            <ReferenceArrayInput label="Distrito" source="municipio22" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/70'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/73' && (
                            <ReferenceArrayInput label="Distrito" source="municipio23" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/73'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/76' && (
                            <ReferenceArrayInput label="Distrito" source="municipio24" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/76'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/81' && (
                            <ReferenceArrayInput label="Distrito" source="municipio25" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/81'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/85' && (
                            <ReferenceArrayInput label="Distrito" source="municipio26" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/85'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/86' && (
                            <ReferenceArrayInput label="Distrito" source="municipio27" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/86'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/88' && (
                            <ReferenceArrayInput label="Distrito" source="municipio28" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/88'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/91' && (
                            <ReferenceArrayInput label="Distrito" source="municipio29" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/91'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/94' && (
                            <ReferenceArrayInput label="Distrito" source="municipio30" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/94'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/95' && (
                            <ReferenceArrayInput label="Distrito" source="municipio31" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/95'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/97' && (
                            <ReferenceArrayInput label="Distrito" source="municipio32" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/97'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/99' && (
                            <ReferenceArrayInput label="Distrito" source="municipio33" reference="municipios" validate={[required()]} filter={{departamento: '/departamentos/99'}} fullWidth>
                                <AutocompleteArrayInput optionText="nombre" options={{fullWidth : true}} fullWidth/>
                            </ReferenceArrayInput>
                        )
                    }
                </Grid> 
                <Grid item xs={6}>
                    <TextInput label="Teléfono 1"   source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]}/> 
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Teléfono 2"  source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput label="Correo electrónico" source="correo" fullWidth validate={[minLength(2), maxLength(100), email()]}/>
                </Grid>                
                
                <Grid item xs={12}>
                    <TextInput source="observaciones" fullWidth validate={[minLength(2), maxLength(250)]}/>
                </Grid>
                
            </Grid>
        </SimpleForm>
    </PimsCreate>
)};
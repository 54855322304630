import React from 'react';
import { FormTab, TextInput, SelectInput } from 'react-admin';
import { Edit } from './../Edit';
import { withStyles, Grid } from '@material-ui/core';
import { TabbedForm } from './../TabbedForm';
import {
    required,

} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../EstilosGrid';
import UniqueAttribute from './../componentes/UniqueAttribute';

const AtributoTitulo = ({ record }) => {
    return <span>Atributos {record ? `"${record.nombre}"` : ''}</span>;
};

const opciones = [
    { id: 1, name: 'Acceso Completo' },
    { id: 2, name: 'Acceso Parcial' },
    { id: 3, name: 'Sin Acceso' },
    { id: 4, name: 'Visualiza' }
];

export const AtributosEdit = withStyles(styles)(({ classes, ...props }) => {


    return (
        <Edit {...props} title={<AtributoTitulo />} undoable={false} >
            <TabbedForm redirect="list" >
                <FormTab label="Administración" submitOnEnter={false} >

                    {/* <TextInput label="Nombre de rol" source="nombre" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} /> */}
                    <UniqueAttribute
                        id="rol"
                        label="Nombre de rol"
                        source="nombre"
                        reference="atributos"
                        operation="edit"
                        formClassName={classes.grid_cont4s}
                    />
                    <SelectInput label="Tipo de rol" source="tipoRol" choices={[
                        { id: "Administrativo", name: 'Administrativo' },
                        { id: "Campo", name: 'Campo' },
                        { id: "Oficina", name: 'Oficina' },
                    ]} fullWidth validate={[required()]} sort={{ field: 'id', order: 'ASC' }} formClassName={classes.grid_cont4} />

                    <SelectInput label="Usuarios" source="usuario" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                    <SelectInput label="Perfiles" source="perfiles" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />

                    <SelectInput label="Asignar Clave" source="asigclaves" choices={[
                        { id: 1, name: 'Acceso Completo' },

                        { id: 3, name: 'Sin Acceso' },

                    ]} fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                </FormTab>

                <FormTab label="Proyecto" submitOnEnter={false} redirect="list">

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Proyectos</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>

                    <SelectInput label="Proyectos" source="proyecto" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />

                    <SelectInput label="Rutas" source="rutas" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                    <SelectInput label="Unidades Funcionales" source="unifuncionales" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput label="Tramos" source="tramos" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />

                    <SelectInput label="Segmentos" source="segmento" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Configuraciones - Materiales</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>

                    <SelectInput label="Materiales" source="materiales" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />

                    <SelectInput label="Granulometría" source="ganulometria" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Configuraciones - Indicadores</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <SelectInput label="Parámetros de Evaluación" source="parametrosEvaluacion" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                    <SelectInput label="Diagrama Colorimétrico" source="diagramaColorimetrico" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Configuraciones - Deterioro PERS</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <SelectInput label="Reparaciones" source="reparaciones" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                    <SelectInput label="Deterioros" source="deterioros" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />


                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Contactos</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>

                    <SelectInput label="Contactos de Emergencia" source="contemergencia" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />

                    <SelectInput label="Proveedores" source="proveedores" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                    <SelectInput label="Servicios Públicos" source="serpublicos" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />

                </FormTab>
                <FormTab label="Inventario">

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Segmentos (Calzada)</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>

                    <SelectInput label="Flexible" source="flexible" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3s} />

                    <SelectInput label="Rígido" source="rigido" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />

                    <SelectInput label="Adoquín" source="adoquin" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />

                    <SelectInput label="Otro" source="otro" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <div style={{ textAlign: "center", width: "100%" }}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Elementos Inventario</Typography>
                            </div>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <SelectInput label="Sistemas de Contención" source="seguridad" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                    <SelectInput label="Drenaje" source="drenaje" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput label="Señalización" source="senalizacion" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput label="Alrededores" source="alrededores" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                    <SelectInput label="Otros Elementos" source="otroElemento" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput label="Redes" source="redes" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Puentes y Estructuras Especiales</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <SelectInput label="Puentes" source="puentes" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                    <SelectInput label="Contención" source="contencion" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput label="Pontón" source="ponton" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput label="Túneles" source="tunel" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                </FormTab>
                <FormTab label="Tránsito">
                    <SelectInput label="Inventario Peajes" source="inventario" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                    <SelectInput label="Tránsito Histórico" source="transitoMensual" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    <SelectInput label="Inventario Estación de conteo" source="inventarioa" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s} />
                    {/* <SelectInput label="Ejes Equivalentes" source="transitoMensuala" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} /> */}
                    {/* <SelectInput label="Deterioros" source="tasaCrecimiento" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4} /> */}
                </FormTab>
                <FormTab label="Indicadores">
                    <SelectInput label="Datos Básicos" source="datosBasicos" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3s} />
                    <SelectInput label="Deterioro" source="colaDePeaje" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />
                    <SelectInput label="Calificación Puentes" source="calificacionUentes" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />
                    <SelectInput label="Calificación Pontones" source="calificacionPontones" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />
                    <SelectInput label="Disponibilidad de Vías" source="disponibilidadVia" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3s} />
                    <SelectInput label="Cola de Peaje" source="colaDePeajea" choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />

                </FormTab>
                <FormTab label="Gestión">
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Programación</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <SelectInput label="Parámetros de Planeación" source="parametrosAnalisis" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont3s} />

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Pavimento PERS</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <SelectInput label="Alternativa de Intervención" source="alternativaIntervencion" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont3s} />

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Otros Sistemas</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <SelectInput label="Políticas Generales" source="politicaGeneral" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont3s} />
                    <SelectInput label="Políticas Particulares" source="politicaParticular" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont3} />

                </FormTab>
            </TabbedForm>
        </Edit>
    )
});
import React,{ useState } from 'react';
import { NumberInput,required, DisabledInput,TextInput,FormDataConsumer } from 'react-admin';
import { minValue,number, maxValue, minLength, maxLength } from 'ra-core';
import {validarDosDecimales,numero} from '../validacion/validacionColores';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
// import { number } from 'prop-types';
// import loader from './../../../../../loader10.gif';


    class Coloramiento extends React.Component {


    constructor(props){
        super(props)
        this.state = {  record: [],tipo: "",value: ''};
        this.array = {
            valoresx1 : 0,valoresx2 : 0,valoresx3 : 0,valoresx4 : 0, maximo: 0,minimo: 0,maximo1: 0,minimo1: 0,
        }
        this.muestra_oculta = this.muestra_oculta.bind(this);
        this.validatestring = this.validatestring.bind(this);
        this.validateint = this.validateint.bind(this);
        this.validatenumer = this.validatenumer.bind(this);
       // this.useStyles = this.useStyles.bind(this);

    }

    componentDidMount(){
        this.setState({ record: this.props.record })
        this.setState({ tipo: this.props.tipo })
        this.array.valoresx1=this.props.record.p1
        this.array.valoresx2=this.props.record.p2
        this.array.valoresx3=this.props.record.p3   
        this.array.valoresx4=this.props.record.p4
        this.array.minimo=this.props.record.min
        this.array.minimo1=this.props.record.min1
        this.array.maximo=this.props.record.max   
        this.array.maximo1=this.props.record.max1
    }

    // rectay(event){
    //     for(let i=0; i<4; i++){
    //         //$x=($a2-$a1)/($b1-$b2);
    //         //$y=($b1*$x)+$a1;
    //     let j=i-1; //j=1 i=2
    //         if(j<0){
    //             let j=3;
    //         }
    //         let y =(b[i]*x)+a[i];
    //        let putosy,[i]=y;
    //         console.log( "X-> "+x+"\n");
    //         }
    //     }

       validatestring = (value) => {
        if(value){
            let date2=value.split('=')
            let date3=date2[1].split(' ')
          if (isNaN(date3[0])) {
            //console.log(date3[0]);
          return "El número no se está intersectando";
        }
        else if(!isFinite(date3[0])){

          return "El número no se está intersectando";
        }
        else if(typeof date3[0] === 'undefined'){

            return "El número no se está intersectando";
          }
      }
    }
    validateint = (value) => {
        if(value){
            let date2=value.split('=')
            let date3=date2[1].split(' ')
            date3.push(date3[1])
          if (isNaN(date3[0])) {
           // console.log(date3[0]);
          return "El número no se está intersectando";
        }
        else if(!isFinite(date3[0])){

          return "El número no se está intersectando";
        }
        else if(typeof date3[0] === 'undefined'){

            return "El número no se está intersectando";
          }
      }
        //   debugger
    }
    validatenumer = (value) => {
        if(value){
        let date2=value.split('=');
        let date3=date2[1].split(' ');
          if (date3[0]<0) {
            //console.log(date3[0]);
          return "El número no debe ser negativo";
        }
      }
    }
       muestra_oculta (id){
        if (document.getElementById(id)){
        let lol = document.getElementById("valorb_3");
        var el = document.getElementById(id);
        if(lol.value !== ""){
        el.style.display = (el.style.display == 'flex') ? 'flex' : 'flex';
        // console.log(lol)
        }
    }
        }

    render(){
        const { colores } = this.state;
        const { record } = this.state;
        const {tipo} = this.state;
        
        const recta=()=>{
            
        let a = [];
        let b= [];
        let puntosx=[]
        let puntosy=[]
        for(let i=0; i<4; i++){
        let valor_a=document.getElementById('valora_'+i)
        let valor_b=document.getElementById('valorb_'+i)
        if(valor_a && valor_b && valor_a.value && valor_b.value){
            a.push(parseFloat(valor_a.value));
            b.push(parseFloat(valor_b.value));
        }
        }
        if(a.length===4 && b.length===4){
            let x=0;
            let y=0;
            let j=0;
            //console.log(a);
            //console.log(b);
            for(let i=0; i<4; i++){
                //$x=($a2-$a1)/($b1-$b2);
                //$y=($b1*$x)+$a1;
            let j=i-1; //j=1 i=2
                if(j<0){
                    j=3;
                }
                x=(a[i]-a[j])/(b[j]-b[i]);
                console.log(x);
                y =(b[i]*x)+a[i];
                console.log(y);
                puntosx[i] = parseFloat(x).toFixed(3);
                puntosy[i] = parseFloat(y).toFixed(3);

                }

                //console.log(puntosx)
                //console.log(puntosy)
        this.array.maximo = Math.max(...puntosx)
        this.array.minimo = Math.min(...puntosy)
        this.array.minimo1 = Math.min(...puntosx)
        this.array.maximo1 = Math.max(...puntosy)
        
            }
         this.array.valoresx1="x="+puntosx[0]+" y="+puntosy[0];
         this.array.valoresx2="x="+puntosx[1]+" y="+puntosy[1];
         this.array.valoresx3="x="+puntosx[2]+" y="+puntosy[2];
         this.array.valoresx4="x="+puntosx[3]+" y="+puntosy[3];
        //  console.log(this.array.valoresx1);
        //  console.log(this.array.valoresx2);
        //  console.log(this.array.valoresx3);
        //  console.log(this.array.valoresx4);
        //  if(puntosy && puntosx){
        //  this.array.valoresx1 = parseFloat(puntosx[0]) /parseFloat (puntosy[0]);
        //  this.array.valoresx2 = parseFloat(puntosx[1]) /parseFloat (puntosy[1]);
        //  this.array.valoresx3 = parseFloat(puntosx[2]) /parseFloat (puntosy[3]);
        //  this.array.valoresx4 = parseFloat(puntosx[3]) /parseFloat (puntosy[3]);
        // }
        // else{

        //     this.array.valoresx1= parseFloat(Math.round(puntosx * 100) / 100).toFixed(2);
        //     this.array.valoresx2= parseFloat(Math.round(puntosy * 100) / 100).toFixed(2);
        // }
        //  this.array.maximo = Math.max(...myArray);

        //  var minValue = Math.min(...myArray); // 1

        }
        let colores2=["Recta Superior","Recta Derecha","Recta Inferior","Recta Izquierda"];

        let coloramiento=this.props.record
        //console.log(this.props.record)

            //

            // const colored = TextInput => props => {
            //     const classes = this.useStyles();
            // }
            if(tipo==="editar" && coloramiento){
                // console.log(tipo);
                //console.log(this.array.valoresx1)
                return(
                    
                    <React.Fragment>
                    
                        <div style={{display: 'flex'}}>
                            {
                                colores2.map((data, i) => (
                                    <div style={{margin: '0px 40px'}}>
                                        <div style={{textAlign: 'center', marginTop: '15px'}}>
                                        {colores2[i]}
                                        </div>
                                        <div>
                                            <NumberInput label="Valor A" source={`valora_${i}`} id={`valora_${i}`} onChange={recta}  validate={[required(),]}/>
                                            <NumberInput label="Valor B" source={`valorb_${i}`} id={`valorb_${i}`} onKeyDown={this.muestra_oculta("contenido_a_mostrar")} onChange={recta}  validate={[required(),]}/>
                                            <NumberInput source={`color_id_${i}`} label=" " defaultValue={i} style={{display: 'none'}}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <hr />
                        <div id="contenido_a_mostrar" style={{display: 'none', justifyContent: 'flex',margin: '0px 10px', marginTop: '-20px'}}>
                            <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto1:</strong> <TextInput   label=" " disabled source="p1edit"  className="align-text" validate={[this.validateint,this.validatenumer]} defaultValue={this.array.valoresx1}/>
                            <span style={{margin: '0px 10px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto2:</strong> <TextInput source="p2edit" disabled label=" " validate={[this.validateint,this.validatenumer]} defaultValue={this.array.valoresx2}/>                      
                        <span style={{margin: '0px 10px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto3:</strong> <TextInput source="p3edit" disabled label=" " validate={[this.validateint,this.validatenumer]} defaultValue={this.array.valoresx3}/>
                        <span style={{margin: '0px 10px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto4:</strong> <TextInput source="p4edit" label=" "disabled validate={[this.validateint,this.validatenumer]} defaultValue={this.array.valoresx4}/>
                        </div>
                        <hr></hr>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Mínimo x: </strong><NumberInput label=" " source="min1edit" disabled className="align-text" defaultValue={this.array.minimo1} />
                        <span style={{margin: '0px 30px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Mínimo y: </strong><NumberInput label=" " source="minedit" disabled className="align-text" defaultValue={this.array.minimo}/>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Máximo x: </strong><NumberInput label=" " source="maxedit" disabled className="align-text" defaultValue={this.array.maximo}/>
                        <span style={{margin: '0px 30px'}}></span>
                         <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Máximo y: </strong><NumberInput label=" " source="max1edit" disabled className="align-text" defaultValue={this.array.maximo1} />
                         </div>
                         <hr></hr>
                         <h3 style={{marginLeft: '40%'}}>Valores Retro-Reflectividad</h3>
                         <NumberInput label="Valor mínimo de Cumplimiento" source="valor"  validate={[required(), minValue(0)]} className="align-text" style={{width:"300px"}} />
                        <DisabledInput source="dec_length" label=" " style={{display: 'none'}}/>
                    </React.Fragment>
                    )            
        }
            
else{
    return(
        <React.Fragment>
            <div style={{display: 'flex'}}>
                {
                    colores2.map((data, i) => (
                        <div style={{margin: '0px 40px'}}>
                            <div style={{textAlign: 'center', marginTop: '15px'}}>
                            {colores2[i]}
                            </div>
                            <div>
                                <NumberInput label="Valor A" source={`valora_${i}`} id={`valora_${i}`} onChange={recta}  validate={[required(),]}/>
                                <NumberInput label="Valor B" source={`valorb_${i}`} id={`valorb_${i}`} onKeyDown={this.muestra_oculta("contenido_a_mostrar")} onChange={recta}  validate={[required(),]}/>
                                <NumberInput source={`color_id_${i}`} label=" " defaultValue={i} style={{display: 'none'}}/>
                            </div>
                        </div>
                    ))
                }
            </div>
            <hr />
            <div id="contenido_a_mostrar" style={{display: 'none', justifyContent: 'flex',margin: '0px 10px', marginTop: '-20px'}}>
                <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto1:</strong> <TextInput   label=" " disabled source="p1"  className="align-text" validate={[this.validatestring,this.validatenumer]} defaultValue={this.array.valoresx1}/>
                <span style={{margin: '0px 10px'}}></span>
            <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto2:</strong> <TextInput source="p2" disabled label=" " validate={[this.validatestring,this.validatenumer]} defaultValue={this.array.valoresx2}/>
                <span style={{margin: '0px 10px'}}></span>
            <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto3:</strong> <TextInput source="p3" disabled label=" " validate={[this.validatestring,this.validatenumer]} defaultValue={this.array.valoresx3}/>
                <span style={{margin: '0px 10px'}}></span>
            <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>Punto4:</strong> <TextInput source="p4" label=" "disabled validate={[this.validatestring,this.validatenumer]} defaultValue={this.array.valoresx4}/>
            </div>
            <hr></hr>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
            <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Mínimo x: </strong><NumberInput label=" " source="min1" disabled className="align-text" defaultValue={this.array.minimo1} />
            <span style={{margin: '0px 30px'}}></span>
            <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Mínimo y: </strong><NumberInput label=" " source="min"  disabled className="align-text" defaultValue={this.array.minimo}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
            <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Máximo x: </strong><NumberInput label=" " source="max"  disabled className="align-text" defaultValue={this.array.maximo}/>
            <span style={{margin: '0px 30px'}}></span>
             <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>Valor Máximo y: </strong><NumberInput label=" " source="max1"  disabled className="align-text" defaultValue={this.array.maximo1} />
             </div>
             <hr></hr>
             <h3 style={{marginLeft: '40%'}}>Valores Retro-Reflectividad</h3>
             <NumberInput label="Valor mínimo de Cumplimiento" source="valor"  validate={[required(), minValue(0)]} className="align-text" style={{width:"300px"}} />
            <DisabledInput source="dec_length" label=" " style={{display: 'none'}}/>
        </React.Fragment>
    )
}
}
}

export default Coloramiento;
import React, { useState, Fragment } from 'react';
import { TabbedForm, FormTab, TextInput, DateInput, SelectInput, NumberInput, ReferenceInput, DisabledInput, ImageField,ImageInput,
         ArrayInput, SimpleFormIterator, AutocompleteInput, FormDataConsumer
} from 'react-admin';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import { PercentageInput } from './../../../../inputs/PercentageInput';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength
} from 'react-admin';
import { ImagenesInventario } from './../../../images/ImagenesInventario';
import { ButtonCarousel } from './../../../images/ButtonCarouselPeru';
import { Entero, isFechaInvalida } from '../../../validacionesInputs';



 const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    observaciones_field: {display: 'inline-block', width: '40%'},
    last_filed: { display: 'inline-block', marginLeft: 32, width: '30%' },   
    separador: { marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0}, 
    subtittle: {marginTop: '15px',marginBottom: '15px'},       
    tabla:  {display: 'inline-block', width: '30%', padding: '5px'},
    grid_cont4:  { display: 'inline-block', width: '32%' ,marginBottom: '20px', padding: '5px'},
};

export const ElementoFlexibleEdit = withStyles(styles)(({ classes, ...props }) => {

    //----------------------------------------------
    //-----------------VALIDACIÓNES-----------------
    //----------------------------------------------

    let Esal10 = 0;
    const value10 = (value) =>{
        Esal10 = value;
    }

    let auxValue;
    const value20 = (value) =>{
        if(value){
            auxValue = document.getElementById('esal_time_10');
            if(Esal10 === 0){ Esal10 = auxValue.value; }                
            if(value <= Esal10){            
                return "Debe ser mayor a 'ESAL (Proyección 10 años)'"
            }
        }        
    }

    //GEOMETRIA
    //------------------->   

    //HOOKS VALOR AUTOMATICO AREA DE CARRILES(LARGO CALZADA) Y AREA VERDADERA CARRILES
    const [LargoCal, setLargoCal] = useState(1);
    const [AnchoCal, setAnchoCal] = useState(1);
    const [NumCarr, setNumCarr] = useState(1);
    const [AnchoCarr, setAnchoCarr] = useState(1);

    //CAMBIAR EL VALOR AREA DE CARRILES (LARGO)
   //CAMBIAR EL VALOR AREA DE CARRILES (LARGO)
   const ValDetallesCalzada = (value) =>{   
        if(value){
            //Si ingresa un decimal VAlidar solo 2     
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }                            
                    }                                                            
                }                
            }

            setLargoCal(value);
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100) * (document.getElementById("anchoCar").value*100))/1000000;  

        }        
    }

    //VALIDAR NUMERO CON DECIMALES
    const valNumDecimals = (value) =>{    
        if(value){            
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }                            
                    }                                                            
                }                
            }
        } 
    }

    //CAMBIAR EL VALOR AREA DE CARRILES (ANCHO)
    const ValDetallesCalzadaAncho = (value) =>{    
        if(value){
            setAnchoCal(value)
            
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100) * (document.getElementById("anchoCar").value*100))/1000000;  

        }
    }    

    //VALOR AUTOMATICO AREA VERDADERA CARRILES(NUMERO CARRILES)    
    const ValNumeroCarriles = (value) =>{    
        if (value){
            setNumCarr(value)
            
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(LargoCal)*100) * (parseFloat(AnchoCal)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(LargoCal)*100) * (parseFloat(AnchoCal)*100) * (AnchoCarr*100))/1000000;            
        }        
    }

    //ASIGNAR VALOR AUTOMATICO VERDADERA AREA CARRILES (ANCHO CARRIL)
    const ValAnchoCarril = (value) =>{ 
        if (value){

            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return "El valor ingresado solo puede tener 1 decimal"; }       
                }                
            }
            setAnchoCarr(value)
            
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100) * (document.getElementById("anchoCar").value*100))/1000000;  

        }
    }

    //VALIDACION PENDIENTE 0-20
    const valPendiente20 = (value) =>{
        if(value){
            let longitud20 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud20[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return "El valor ingresado debe ser entero" }
            }  
            //VALIDAR MINIMO 0
            if(parseInt(longitud20[0]) < 0){ return "Debe ser al menos 0"; }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud20[0]) > 20){ return "Debe ser 20 o menos"; }            
        }        
    }

    //VALIDACION PENDIENTE 0-5  
    const valPendiente5 = (value) =>{
        if(value){
            let longitud5 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud5[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return "El valor ingresado debe ser entero" }
            }            
            //VALIDAR MINIMO 0
            if(parseInt(longitud5[0]) < 0){ return "Debe ser al menos 0"; }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud5[0]) > 5){ return "Debe ser 5 o menos"; }            
        }
    }

    //ESTRUCTURA
    //------------------->    
    let espensor1 = 0, valEspensor1;
    let espensor2 = 0, valEspensor2;
    let espensor3 = 0, valEspensor3;
    let espensor4 = 0, valEspensor4;
    let espensor5 = 0, valEspensor5;
    let espensor6 = 0, valEspensor6;
    let espensor7 = 0, valEspensor7;    

    //VALOR ESPENSOR
    let defecto = 0;
    const [espensortotal, setEspensortotal] = useState(defecto);
    const ValEspensor = (value) => {
        if(value){      
            
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }
            //ESPENSOR 1
            valEspensor1 = document.getElementById('tablaSegmento[0].estructuraEspesor');
            valEspensor2 = document.getElementById('tablaSegmento[1].estructuraEspesor');
            valEspensor3 = document.getElementById('tablaSegmento[2].estructuraEspesor');
            valEspensor4 = document.getElementById('tablaSegmento[3].estructuraEspesor');
            valEspensor5 = document.getElementById('tablaSegmento[4].estructuraEspesor');
            valEspensor6 = document.getElementById('tablaSegmento[5].estructuraEspesor');
            valEspensor7 = document.getElementById('tablaSegmento[6].estructuraEspesor');

            // console.log("valEspensor", valEspensor1, valEspensor2, valEspensor3, valEspensor4, valEspensor5, valEspensor6);
            
            //ESPENSOR 1
            if(valEspensor1) { if(valEspensor1.value !== null){ espensor1 = valEspensor1.value } }
            //ESPENSOR 2
            if(valEspensor2) { if(valEspensor2.value !== null){ espensor2 = valEspensor2.value } }
            //ESPENSOR 2
            if(valEspensor3) { if(valEspensor3.value !== null){ espensor3 = valEspensor3.value } }
            //ESPENSOR 2
            if(valEspensor4) { if(valEspensor4.value !== null){ espensor4 = valEspensor4.value } }
            //ESPENSOR 2
            if(valEspensor5) { if(valEspensor5.value !== null){ espensor5 = valEspensor5.value } }
            //ESPENSOR 2
            if(valEspensor6) { if(valEspensor6.value !== null){ espensor6 = valEspensor6.value } }
            //ESPENSOR 2
            if(valEspensor7) { if(valEspensor7.value !== ''){ espensor7 = valEspensor7.value } }
            
            var suma = parseInt(espensor1) + parseInt(espensor2) + parseInt(espensor3) + parseInt(espensor4) + parseInt(espensor5) + parseInt(espensor6) + parseInt(espensor7);
            if (!isNaN(suma)) {
                document.getElementById('espensorTotalFE').value = suma;
            } else {
                document.getElementById('espensorTotalFE').value = "";
            }
        }  else {
            setEspensortotal(0);
        }
    }
    

    //VALOR MODULO 1
    const ValModulo = (value) =>{
        if(value){  
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }          
        } 
    }

    //VALOR ESTRUCTURA 1
    const ValEstructura = (value) =>{
        if(value){
            if(value !== 0){
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if(!pattern.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }            
                }                
            }
        } 
    }

    //VALIDAR NUMERO ENTERO
    const valentero = (value) =>{
        if(value !== null){
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }
        }
    }
    
    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum1Decimal = (value) =>{ 
        if(value){ 
            if(typeof value === 'string'){
                value = parseFloat(value.replace(",", "."));        
            }
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return "El valor ingresado solo puede tener 1 decimal"; }       
                }                
            }
        }  
    }

    //VALIDAR ESPECIFICACIÓN
    const [material , setMaterial]= useState('');
    const ValidarEspecificacion = (value)  => { 
        let lenghtObject = Object.keys(value);
        let valor = '';
        
        if(value){
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            var filtroGra = valor.split('/materials/');  
            setMaterial(filtroGra[1]);
        }       
        
    }    
    const [estado, setEstado] = useState('');

    const Validationrazon =(value) =>{                 
        setEstado(value);
    }
    const [condicionberma, setCondicionberma] = useState(true);
    const validarCondicionBerma = (value) => {

        if(value){
            if (value === 'Inexistente'){
                setCondicionberma(false);
            }else{
                setCondicionberma(true);
            }
        }

    }

    const [arrayMax, setArrayMax] = useState(false);
    const arrayCount5 = (value) => {
        if(typeof value !== 'undefined'){
            if(value.length > 0) {
                if("estructuraEspesor" in value[value.length -1]) {
                    if(value[value.length -1].estructuraEspesor > 0) {
                        return "El Espesor en la capa "+value.length+" (la última), debe ser 0";
                    }
                }
                if (value.length > 4) {
                    setArrayMax(true);
                } else {
                    setArrayMax(false);
                }
            } else {
                return "Mínimo 1 capa"
            }
        } else {
            return "Mínimo 1 capa"
        } 
    }

    const [anchosep, setAnchosep] = useState('');

    const ValidationConSeparador = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';
        // for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        //setAnchosep(valor) 
        setAnchosep(value)  
    }

    const [type, setType] = useState('edit');

    const Type = () => {
        setType('edit')  
        
    }

    /*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }

    return(
    <Edit title="Editar Segmento Flexible" {...props} undoable={false}>
        <TabbedForm redirect="list">
            <FormTab label="Detalles">
            <FormDataConsumer >
                    {({formData, dispatch, ...rest}) => (
                        <Fragment>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="Unidad Funcional" 
                                        source="unidadFuncional" 
                                        reference="unidad_funcionals" 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "tramo", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        disabled
                                    >
                                        <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                <ReferenceInput 
                                        label="Tramo" 
                                        source="tramo" 
                                        reference="tramos" 
                                        filter={{unidadFuncional: formData.unidadFuncional}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "segmento", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        disabled
                                    >
                                        <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="Segmento" 
                                        source="segmento" 
                                        reference="segmentos" 
                                        filter={{tramo: formData.tramo}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        disabled
                                    >
                                        <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </Fragment>
                        
                    )}
                </FormDataConsumer>
                <ArrayInput source="detallesSegmento" style={{width: '100%'}} label=" "
                defaultValue={[
                    {                        
                        estadoDetalles: null,
                        estadoRazon: null,
                        dateInicioOperacion: null,
                        proveedor: null,
                        garantiaMeses: null,
                        esalTime: null,
                        nivelTrafico: null,
                        esalTime10: null,
                        esalTime20: null,
                        detallesObservaciones: null
                    }
                ]} className="inventariosSegmentos">                
                    <SimpleFormIterator disableRemove disableAdd>                          
                        <div></div>
                        <SelectInput 
                            source="estadoDetalles" 
                            label="Estado" choices={[
                                { id: 'Activo', name: 'Activo' },
                                { id: 'Inactivo', name: 'Inactivo' }, ,                    
                            ]} 
                            validate={[required(), Validationrazon]} 
                             
                            formClassName={classes.grid_cont4}
                        />                                
                        
                        {estado === "Inactivo" &&
                            <TextInput 
                                source="estadoRazon" 
                                label="Razón" 
                                validate={[required()]}
                                 
                                formClassName={classes.grid_cont4}
                            />
                        }              
                        <div ></div>     
                        <DateInput 
                            source="dateInicioOperacion" 
                            label="Fecha de Inicio Operación" 
                            formClassName={classes.first_field} 
                             
                            validate={[required(), isFechaInvalida]} 
                        />                         
                        
                        <ReferenceInput 
                            label="Proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{proyecto: props.proyecto, suministros: 1, sistema: "CLZ"}} 
                            sort={{ field: 'razonSocial', order: 'ASC' }}
                            formClassName={classes.grid_cont4}
                            validate={[Type]} 
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                        >
                            <AutocompleteInput optionText="razonSocial"  />
                        </ReferenceInput>
                        <NumberInput 
                            source="garantiaMeses" 
                            label="Garantía (Meses)" 
                            validate={[minValue(0), Entero]} 
                            formClassName={classes.grid_cont4}
                        />
                        <div className={classes.subtittle}><strong>Tráfico</strong></div>                
                        <Divider fullWidth />
                        <NumberInput 
                            source="esalTime" 
                            label="ESAL (Año)" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(),minValue(0), Entero]}
                            />
                        
                        <SelectInput source="nivelTrafico" label="Nivel de Tráfico" choices={[
                                { id: 'NT1', name: 'NT1' },
                                { id: 'NT2', name: 'NT2' },
                                { id: 'NT3', name: 'NT3' },
                            ]} 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required()]} 
                        />  
                        <div></div>                
                        <NumberInput 
                            source="esalTime10" 
                            label="ESAL (Proyección 10 Años)" 
                            formClassName={classes.grid_cont4} 
                            validate={[minValue(0), value10, Entero]}
                             
                        />
                        <NumberInput 
                            source="esalTime20" 
                            label="ESAL (Proyección 20 Años)" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[minValue(0), value20, Entero]}
                        />
                        <Divider fullWidth formClassName={classes.grid_cont12s} />                
                        <TextInput 
                            source="detallesObservaciones" 
                            label="Observaciones" 
                            formClassName={classes.grid_cont12s} 
                            validate={[minLength(0), maxLength(200)]} 
                             
                        />
                    </SimpleFormIterator>
                </ArrayInput>    
            </FormTab>
            <FormTab label="Geometría">
                <ArrayInput source="geometriaSegmento" style={{width: '100%'}} label=" "
                    defaultValue={[
                        {
                            geometriaLargo: null,
                            geometriaAncho: null,
                            geometriaNumCarriles: null,
                            geometriaAnchoCarril: null,
                            geometriaAnchoBerma: null,
                            geometriaTipoBerma: null,
                            geometriaCondicionBerma: null,
                            geometriaLongitudinal: null,
                            geometriaLongitudinalMa: null,
                            geometriaLongitudinalMi: null,
                            geometriaTransversal: null,
                            geometriaRadioMi: null,
                            geometriaRadioMa: null,
                            geometriaRadioCurvatura: null,
                            geometriaVelDiseno: null,
                            geometriaSeparador: null,
                            geometriaSeparadorAncho: null,
                            geometriaCondicionesGeometrica: null,
                            geometriaAlineamiento: null,
                            geometriaTipoTerreno: null,
                            detallesGeometria: null,
                            aux1: 0,
                            aux2: 0
                        }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>  
                        <div className={classes.subtittle}><strong>Detalles de Calzada</strong></div>
                        <Divider fullWidth />
                        <NumberInput 
                            id="largom"
                            source="geometriaLargo" 
                            label="Largo (m)" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(0), maxValue(1999.99), ValDetallesCalzada]}
                        />
                        <NumberInput 
                            id="anchom"
                            source="geometriaAncho" 
                            label="Ancho de la Calzada (m)" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(3), maxValue(40), ValDetallesCalzadaAncho]}
                        />
                        <div className={classes.subtittle}><strong>Detalles de Carriles</strong></div>
                        <Divider fullWidth />
                        <NumberInput 
                            source="geometriaNumCarriles" 
                            label="Número de Carriles" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(1), maxValue(5), ValNumeroCarriles, Entero]}
                        />
                        <NumberInput 
                            id="anchoCar"
                            source="geometriaAnchoCarril" 
                            label="Ancho por Carril (m)" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(1), maxValue(5), ValAnchoCarril]}
                            />
                        <div></div>
                        <SelectInput 
                            source="geometriaTipoBerma" 
                            label="Tipo de Berma" choices={[
                                { id: 'Berma Cuneta', name: 'Berma Cuneta' },
                                { id: 'Flexible', name: 'Flexible' },
                                { id: 'Inexistente', name: 'Inexistente' },
                                { id: 'Rígida', name: 'Rígida' },
                            ]} formClassName={classes.grid_cont4} 
                             
                            validate={[validarCondicionBerma]}
                        />
                        {condicionberma && (
                            <NumberInput 
                                source="geometriaAnchoBerma" 
                                label="Ancho de Berma (m)" 
                                formClassName={classes.grid_cont4} 
                                 
                                validate={[required(), minValue(0), maxValue(10), valNum1Decimal]}
                            />
                        )}
                        {condicionberma && (
                            <SelectInput source="geometriaCondicionBerma" label="Condición de Berma" resettable choices={[
                                { id: 'Bueno', name: 'Bueno' },
                                { id: 'Regular', name: 'Regular' },
                                { id: 'Deficiente', name: 'Deficiente' },               
                            ]} formClassName={classes.grid_cont4} 
                             
                            /> 
                        )}                           
                        <div></div>
                        <DisabledInput 
                            label="Área de Carriles (m²)" 
                            source="sumaAreaCarriles" 
                            id="AreaCarrilesFE" 
                            className="align-number"
                            formClassName={classes.grid_cont4} 
                             
                        />                
                        <NumberInput 
                            label="Área Verdadera Carriles (m²)" 
                            source="areaCarrilesVerdadera" 
                            id="AreaVerdaderaFE" 
                            className="align-number"
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0)]}
                             
                        />
                        <div className={classes.subtittle}><strong>Propiedades geométricas</strong></div>
                        <Divider fullWidth />
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaLongitudinal" 
                            label="Pendiente Longitudinal" 
                            formClassName={classes.grid_cont4} 
                            validate={[valPendiente20]}
                             
                        />
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaLongitudinalMa" 
                            label="Pendiente Longitudinal Máxima" 
                            formClassName={classes.grid_cont4} 
                            validate={[valPendiente20]}
                             
                        />
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaLongitudinalMi" 
                            label="Pendiente Longitudinal Mínima" 
                            formClassName={classes.grid_cont4} 
                            validate={[valPendiente20]}
                             
                        />
                        {/* -----------RADIO--------- */}
                        <br/>
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaTransversal" 
                            label="Pendiente Transversal" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), valPendiente5]}
                             
                        />
                        <NumberInput 
                            source="geometriaRadioMi" 
                            label="Radio Mínimo (m)" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0), maxValue(5), Entero]}
                             
                        />
                        <NumberInput 
                            source="geometriaRadioMa" 
                            label="Radio Máximo (m)" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0), maxValue(5000), Entero]}
                             
                        />
                        <br/>
                        {/* -------------------- */}
                        <NumberInput 
                            source="geometriaRadioCurvatura" 
                            label="Radio de Curvatura (°/km)" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0), maxValue(5000), valNum1Decimal]} 
                             
                        />
                        <NumberInput 
                            source="geometriaVelDiseno" 
                            label="Velocidad de Diseño (km/h)" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(10), maxValue(150), valentero]} 
                             
                        />
                        <div></div>
                        <SelectInput source="geometriaSeparador" label="Con Separador" choices={[
                                { id: 'Si', name: 'Si' },
                                { id: 'No', name: 'No' },                    
                            ]} 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), ValidationConSeparador]}
                             
                        />
                        { anchosep === "Si" &&   
                            <NumberInput 
                                source="geometriaSeparadorAncho" 
                                label="Ancho Separador (m)" 
                                formClassName={classes.grid_cont4} 
                                validate={[required(), minValue(0), maxValue(50)]}
                                 
                            />
                        }                 
                        <div></div>
                        <SelectInput source="geometriaCondicionesGeometrica" label="Condiciones Geométricas" choices={[
                                { id: 'Bueno', name: 'Bueno' },
                                { id: 'Deficiente', name: 'Deficiente' },
                                { id: 'Regular', name: 'Regular' },
                            ]} formClassName={classes.grid_cont4} 
                             
                        />
                        <SelectInput source="geometriaAlineamiento" label="Alineamiento" choices={[
                                { id: 'Curvo', name: 'Curvo' },
                                { id: 'Predominantemente Curvo', name: 'Predominantemente Curvo' },
                                { id: 'Predominantemente Recto', name: 'Predominantemente Recto' },
                                { id: 'Recto', name: 'Recto' },
                            ]} formClassName={classes.grid_cont4} 
                             
                        />
                        <SelectInput source="geometriaTipoTerreno" label="Tipo de Terreno" choices={[
                                { id: 'Plano', name: 'Plano' },
                                { id: 'Ondulado', name: 'Ondulado' },
                                { id: 'Montañoso', name: 'Montañoso' },
                                { id: 'Escarpado', name: 'Escarpado' },
                            ]} formClassName={classes.grid_cont4} 
                             
                        />
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesGeometria" label="Observaciones" validate={[minLength(0), maxLength(200)]} formClassName={classes.grid_cont12}  />   
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Estructura" >
                <div className={classes.subtittle}><strong>Información</strong></div>
                <Divider fullWidth />                
                <ArrayInput 
                    source="tablaSegmento" 
                    style={{width: '100%'}} 
                    label=" " 
                    validate={required(), arrayCount5}
                     
                >
                    <SimpleFormIterator 
                        disableAdd={arrayMax}
                    >
                        <NumberInput 
                            source="estructuraEspesor" 
                            label="Espesor (mm)" 
                            validate={[ValEspensor, required(), minValue(0), maxValue(20000), Entero]} 
                            formClassName={classes.tabla} style={{width: '100%'}}
                        />        
                        <NumberInput 
                            source="estructuraModulo" 
                            label="Módulo (MPa)" 
                            validate={[required(), minValue(0), maxValue(100000), ValModulo, Entero]} 
                            formClassName={classes.tabla} style={{width: '100%'}}
                        />   
                        <NumberInput 
                            source="estructuraDs" 
                            label="Desviación Estándar (Std)" 
                            validate={[required(), minValue(0.01), maxValue(4), valNumDecimals]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}} 
                            className="Longitudes_segmentos"
                        />                       
                         
                        <ReferenceInput 
                            label="Material" 
                            source="material"  
                            reference="materials" 
                            filterToQuery={searchText => ({ material: searchText })} 
                            sort={{field : 'material', order : 'asc'}}
                            onChange={ValidarEspecificacion}
                            validate={[required()]} 
                              
                            formClassName={classes.tabla} 
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="material"  />
                        </ReferenceInput>                        
                        <ReferenceInput 
                            label="Especificación" 
                            source="material" 
                            reference="materials"  
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        >
                            <SelectInput  optionText="especificacion" disabled   />
                        </ReferenceInput>   
                        <ReferenceInput 
                            label="Granulometría" 
                            source="granulometria" 
                            reference="granulometrias" 
                            filterToQuery={searchText => ({ granulometria: searchText })} 
                            filter={{material: material}}
                            sort={{field : 'granulometria', order : 'asc'}}
                             
                            formClassName={classes.tabla} 
                        >
                            <AutocompleteInput optionText="granulometria" />
                        </ReferenceInput>     
                    </SimpleFormIterator>
                </ArrayInput>
                <div></div><br/>                                               
                <DisabledInput 
                    label="Espesor Total (mm)" 
                    source="espensorTotal" 
                    id="espensorTotalFE" 
                    formClassName={classes.first_field} 
                    className="align-number"
                    defaultValue ={espensortotal}
                />                
                <ArrayInput source="estructuraSegmento" style={{width: '100%'}} label=" "
                    defaultValue={[
                        {
                            estructuraNumDiseno: null,
                            estructuraFecha: null,
                            estructuraNumero: null,
                            estructuraNumeroFecha: null,
                            estructuraCbr: null,
                            estructuraCbrFecha: null,
                            estructuraFechaConst: null,
                            estructuraFechaRehab: null,
                            detallesEstructura: null
                        }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd> 
                        <div className={classes.subtittle}><strong>Capacidad estructural</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="estructuraNumDiseno" label="Número Estructural de Diseño" formClassName={classes.first_field} validate={[required(), minValue(0.01), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraFecha" label="Fecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraNumero" label="Número Estructural Efectivo" formClassName={classes.first_field} validate={[required(), minValue(0.01), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraNumeroFecha" label="Fecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <PercentageInput source="estructuraCbr" label="CBR (%)" className="align-number"  formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(100), valNum1Decimal]}/>
                        <DateInput source="estructuraCbrFecha" label="Fecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>                        
                        <div className={classes.subtittle}><strong>Fechas importantes</strong></div>
                        <Divider fullWidth />
                        <DateInput source="estructuraFechaConst" label="Fecha de Construcción" formClassName={classes.first_field} validate={[required(), isFechaInvalida]} /> 
                        <DateInput source="estructuraFechaRehab" label="Fecha de Última Rehabilitación" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} />                         
                        <Divider fullWidth formClassName={classes.separador} />
                        <TextInput source="detallesEstructura" label="Observaciones" validate={[minLength(0), maxLength(200)]}  fullWidth/>   
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="FOTOGRAFÍAS">
                <Grid container spacing={24}>                         
                    <Grid item xs={8}>  
                        <ArrayInput label="" source="images" validate={ImagenesEdit} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>                           
                                <ReferenceInput 
                                    disabled 
                                    label="Tipo de elemento" 
                                    source="tipoImagen" 
                                    reference="tipo_images" 
                                    fullWidth 
                                    validate={[required()]} 
                                    sort={{ field: 'nombre', order: 'ASC' }}  
                                    formClassName={classes.grid_cont6}
                                >
                                    <SelectInput  optionText="nombre"  />
                                </ReferenceInput>                          
                            </SimpleFormIterator>
                        </ArrayInput>    
                        
                    </Grid>      
                    <Grid item xs={4}>                   
                        <ButtonCarousel ImagenesOpen={imagenesOpen}   />            
                    </Grid>                    
                </Grid>                   
            </FormTab>
        </TabbedForm>
    </Edit>
    )
});
import React from 'react';
import { WithPermissions,ListButton,TabbedShowLayout, 
    EditButton, Tab, TextField, DateField, CardActions,NumberField,
    ArrayField, SingleFieldList,ReferenceField,SelectField,FormTab,ReferenceInput,SelectInput,AutocompleteInput, ChipField,ReferenceArrayField} from 'react-admin';
import { Show } from './../Show';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const PostActions =({basePath,data,
})=>{
var serpublicos = localStorage.getItem("serpublicos");
return(
        <WithPermissions
        render={({ permissions }) => (
            "1" === serpublicos   ||    "2" === serpublicos           
    ? 
    <CardActions style={cardActionStyle}>
        
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
        {/* Add your custom actions
        <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
    

    :  <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} record={data} />
        </CardActions>
    )}
    />
    
)
};

export const Servicio_publicoShow = props => (
    <Show  {...props} title="Información Servicio Público" actions={<PostActions/>}>
        <TabbedShowLayout>
            <Tab label="Información básica">
            <ReferenceField source="proyecto" reference="proyectos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <br/><br/> 
            <TextField source="grupo" />
            <span></span>
            <span></span>
            <TextField label="Tipo de Identificación" source="identificacion" />
            <NumberField label="Número de identificación" source="numero_identificacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
            <TextField source="empresa" />
            <TextField label="Nombres" source="nombre" />
            <TextField label="Apellidos" source="apellido" />
            <span></span>
            <TextField label="Dirección" source="direccion"/>
            <TextField label="Provincia" source="departamento_.nombre"/>            
            <ReferenceArrayField label="Distrito" reference="municipios" source="municipio">
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
                </ReferenceArrayField>                
            <TextField label="Teléfono 1" source="telefono_1" />
            <TextField label="Teléfono 2" source="telefono_2" />
            <span></span>
            <TextField label="Correo electrónico" source="correo" />
            <span></span>     <span></span>   
            <TextField source="observaciones" />
            </Tab>
            <Tab label="Auditoría">
                <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <span></span>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <span></span>   
            </Tab>
        </TabbedShowLayout>
    </Show>
);
import React, {useState} from 'react';
import { required,TextInput,SelectInput ,NumberInput} from 'react-admin';
import { Grid } from '@material-ui/core';
export const ComponenteDeterioroPers = () =>
{
    const [elemento, setElemento] = useState();
    const ValorElemento = (value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor)
        
    }

    return(
        <Grid container spacing={24}>
            <Grid item xs={12}>
                <SelectInput label="Tipo de reparación" source='tipoReparacion' choices={[
                    {id:'PFLEX', name:'Flexible'},
                    { id:'PRIGD', name:'Rígido'}
                ]} validate={[required()]} onChange={ValorElemento} fullWidth/>
            </Grid>            
            <Grid item xs={4}>   
                <TextInput source="nombre" validate={[required()]} fullWidth/>             
            </Grid>
            <Grid item xs={4}>  
                {elemento === 'PFLEX' &&
                    <SelectInput source="unidad" choices={[
                        {id : '$/m', name:'$/m'},
                        {id : '$/m²', name:'$/m²'}
                    ]} validate={[required()]} fullWidth/>
                }
                {elemento !== 'PFLEX' &&
                    <SelectInput source="unidad" choices={[ 
                        {id : '$/m', name:'$/m'},
                        {id : '$/m²', name:'$/m²'},
                        {id : '$/losa', name:'$/losa'}
                    ]} validate={[required()]} fullWidth/>
                }           
            </Grid>
            <Grid item xs={4}>   
                <NumberInput source="costo" validate={[required()]} fullWidth/>           
            </Grid>   
            <Grid item xs={12}>   
                <TextInput source="observaciones"  fullWidth/>           
            </Grid>                                                                                         
        </Grid>             
    )
}

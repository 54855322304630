import React, { useState, Fragment } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, FormDataConsumer, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { PimsElementoActions } from './../../../helpers/PimsElementoActions';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import AddElementosMantenimientoParticular from './../localHelpers/AddElementosMantenimientoParticular';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import AddFlujoCajaDetalles  from './../localHelpers/AddFlujoCajaDetalles';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import config from '../../../config/config';
let urlAppjs = config.UrlFront;

export const FlujoCajaCreate = withStyles(styles)(({ classes, ...props }) => {

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    return (
        <PimsCreate  {...props} 
            title="Crear Flujo de Caja" 
            actions={<PimsElementoActions />} 
        >
            <TabbedForm 
                redirect="list" 
                //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE FLUJO DE CAJA ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                                   
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="Unidad Funcional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                validate={[required()]}
                            >
                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                            </ReferenceInput>
                        </Grid> 
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="Nombre de Politicas"
                                source="nombrePolitica"
                                reference="politicas_nombres"
                                validate={[required()]}
                            >
                                <AutocompleteInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                            </ReferenceInput>
                        </Grid> 
                        <Grid item xs={4}>
                            <NumberInput label="Tasa de Interés" source="tasaInteres" fullWidth/>
                        </Grid>             
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

});

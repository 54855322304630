import React, { useState } from 'react';
import { CardActions, TabbedForm, FormTab, EditButton, Datagrid, ListButton, ShowController, ShowView, TabbedShowLayout, Tab, SelectField, TextField, DateField, ArrayField, ReferenceField, NumberField, BooleanField } from 'react-admin';
//import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
//import { PRField } from './../../fields/PRField';
// import Divider from '@material-ui/core/Divider';
// import { GMapField } from './../../fields/GMapField';
// import { DField } from './../../fields/DField'
// import { AField } from './../../fields/AField';
import Grid from '@material-ui/core/Grid';
//import Reparaciones from './campos/viewreparacion';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../../../EstilosGrid';
import  ViewColoramiento from './color/Viewcoloramiento';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

// const styles = {    
//     ocultar: { display: "none" },

//   };

const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);


export const ColorShow = withStyles(styles)(({ classes, ...props }) => {
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Diagrama Colorimétrico" actions={<PostShowActions />} >
                    <TabbedForm>
                    <FormTab label="Identificación">
                    <h3 style={{marginLeft: '40%'}}>Parametros Área de Color</h3>
                   <hr fullWidth></hr> 
                <TextField source="color" label="Color" formClassName={classes.grid_cont4} />  
                <span></span>
                <WithProps>{({ record, ...props }) =>
                <ViewColoramiento record={record}/>}
                </WithProps>
                </FormTab>
                <FormTab label="Auditoria">
                            <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont4} />
                            <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont4}/>
                            <DateField source="updatedAt" label="Fecha actualización"formClassName={classes.grid_cont4} />
                            <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont4}/>
                        </FormTab>  
                </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}
);
const WithProps = ({ children, ...props }) => children(props);
import React, { useState } from 'react';
import { SimpleForm, TabbedForm, ReferenceInput, SelectInput, FormTab, AutocompleteInput, FormDataConsumer, TextInput, Query, NumberInput } from 'react-admin';
import { DateInput, DisabledInput, choices, Filter } from 'react-admin';
import { PimsCreate } from './../../../Create';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { required, minLength, maxLength } from 'react-admin';

import { Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';

import Grupo1 from './../tabs/edit/grupo1';
import Grupo2 from './../tabs/edit/grupo2';
import Grupo3 from './../tabs/edit/grupo3';
import Retroreflectividad from './../tabs/edit/retroreflectividad';
import Decoloracion from './../tabs/edit/decoloracion';
import config from '../../../../config/config';
let urlAppjs = config.UrlServer;
let urlAppjs2 = config.UrlServer;

const LinkCancelar = () => {
    let redirect = "evaluacion_seguridad"
    return <a href={urlAppjs2 + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
}

const CustomToolbar = ({ basePath, data, ...props }) => (
    <Toolbar {...props} >
        <SaveButton />
        <LinkCancelar />
    </Toolbar>
);







const styles = {
    grid_cont12: { width: '100%', marginBottom: '20px' },
    grid_cont6_f: { display: 'inline-block', width: '48%' },
    grid_cont6_s: { display: 'inline-block', width: '48%', marginLeft: '20px' },

    grid_cont5_f: { display: 'inline-block', width: '41%' },
    grid_cont5_s: { display: 'inline-block', width: '41%', marginLeft: '20px' },

    grid_cont4_f: { display: 'inline-block', width: '31%' },
    grid_cont4_s: { display: 'inline-block', width: '31%', marginLeft: '20px' },

    grid_cont3_f: { display: 'inline-block', width: '22%' },
    grid_cont3_s: { display: 'inline-block', width: '22%', marginLeft: '20px' },

    grid_cont2_f: { display: 'inline-block', width: '15%' },
    grid_cont2_s: { display: 'inline-block', width: '15%', marginLeft: '20px' },

    medicion_global: { display: 'inline-block', width: '31%', marginLeft: '20px' },
    tipo: { width: '90%', fontWeight: 'bold' },
    ocultar: { display: 'none' }
};

export const EvaluacionSVCreate = withStyles(styles)(({ classes, ...props }) => {

    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');
    const [segmentos, setSegmento] = useState('');
    const [tipoSenales, setTipoSenal] = useState('');
    const [modelos, setModelo] = useState('');

    const ValidarUnidad = (value) => {


        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value);


    }

    const Unidades = { unidadFuncional: unidads };


    const ValidarTramo = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value);
    }
    const ValidarSegmento = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSegmento(value)

    }

    const ValidarTipoElemento = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setTipoSenal(valor);
    }

    const ValidarModelo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setModelo(value)
    }

    const Segmentos = { segmento: segmentos };

    const [opciones, setOpcion] = useState([{ id: '', name: 'Cargando...' }]);


    const [elementos, setElemento] = useState('');
    const ValidarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(value)

        // fetch(urlAppjs+ '/elementoSenal/')
        // .then((response) => {
        //     //console.log("REST");
        //     return response.json()
        // })
        // .then((response) => {
        //     setOpcion(response);    
        //     //console.log(response)
        // })        
    }
    const Elementos = { tipoElementoSenal: elementos };


    const ValidarTipoSenal = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoSenal(value)
    }
    const TipoSenales = { tipoSenal: tipoSenales };

    const Modelos = { tipoSenal: modelos };

    const [senales, setSenal] = useState('');
    const ValidarSenal = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSenal(value)
    }
    const Senales = { senal: modelos };


    const filtroSenalizacion = { segmento: segmentos, tipoSenal: tipoSenales };

    const filtroSenalizacionModelo = { segmento: segmentos, senal: modelos };


    const cambioFiltro = () => {

        if (elementos !== '/tipo_elemento_senal_perus/SV') {
            filtroSenalizacion.tipoSenal = tipoSenales;
        } else {
            filtroSenalizacion.tipoSenal = modelos;
        }

    }

    const condicionSenal = () => {

        if (elementos) {
            if (elementos === '/tipo_elemento_senal_perus/SV') {



            } else if (elementos !== '/tipo_elemento_senal_perus/SV') {
                setModelo(null);

            }
        }

    }

    //    const validarfecha=(value)=>{
    //     let fecha=new Date(value+"T00:00:00");
    //     let fechaact=new Date();
    //     if(fecha>fechaact){
    //         //console.log(fecha+" */* "+fechaact+" */* "+value)
    //         return "La fecha No puede ser mayor a la fecha actual";
    //     }
    //    }
    const validarfecha = (value) => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        if (document.getElementById("datefield")) {
            document.getElementById("datefield").setAttribute("max", today);
        }
    }
    return (
        <PimsCreate title="Crear evaluación" {...props}>
            <SimpleForm submitOnEnter={false} redirect="list" toolbar={<CustomToolbar />}>
                {/* HEADER */}
                <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" validate={[required(), ValidarUnidad]} filterToQuery={searchText => ({ codigo: searchText })} formClassName={classes.grid_cont3_f} fullWidth>
                    <AutocompleteInput optionText="fullCodigos" fullWidth />
                </ReferenceInput>

                {unidads &&
                    <ReferenceInput label="Tramo" source="tramo" reference="tramos" validate={[required(), ValidarTramo]} formClassName={classes.grid_cont3_s} filterToQuery={searchText => ({ codigo: searchText })} sort={{ field: 'codigo', order: 'ASC' }} filter={{ unidadFuncional: unidads }} fullWidth>
                        <AutocompleteInput optionText="fullCodigos" fullWidth />
                    </ReferenceInput>
                }
                {tramos &&
                    <ReferenceInput label="Segmento" source="segmento" reference="segmentos" validate={[required(), ValidarSegmento]} formClassName={classes.grid_cont3_s} filterToQuery={searchText => ({ codigo: searchText })} sort={{ field: 'codigo', order: 'ASC' }} filter={{ tramo: tramos }} fullWidth>
                        <AutocompleteInput optionText="fullCodigos" fullWidth />
                    </ReferenceInput>
                }
                {segmentos &&
                    <ReferenceInput label="Elemento" source="tipoElementoSenal" fullWidth reference="tipo_elemento_senal_perus" validate={[required(), ValidarElemento]} sort={{ field: 'nombre', order: 'ASC' }} filter={{ segmento: segmentos }} formClassName={classes.grid_cont2_s}>
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                }
                {elementos &&
                    <ReferenceInput label="Tipo de elemento" source="tipoSenal" fullWidth reference="tipo_senal_perus" validate={[required(), ValidarTipoElemento]} sort={{ field: 'nombre', order: 'ASC' }} filter={{ tipoElementoSenalPeru: elementos }} formClassName={classes.grid_cont3_f}>
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                }
                {tipoSenales &&
                    <ReferenceInput label="Modelo" source="nombre" fullWidth reference="senal_vertical_perus" validate={[required(), ValidarModelo]} filter={{ tipoSenalPeru: tipoSenales }} sort={{ field: "nombre", order: "ASC" }} filterToQuery={searchText => ({ nombre: searchText })} formClassName={classes.grid_cont3_s}>
                        <AutocompleteInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                }
                {modelos &&
                    <ReferenceInput label="Señalización" source="elemento_senalizacion" reference="elemento_senalizacion_perus" validate={[required()]} formClassName={classes.grid_cont5_s} sort={{ field: 'codigo', order: 'ASC' }} filter={{ segmento: segmentos, tramo: tramos, unidadFuncional: unidads, tipoSenalPeru: tipoSenales, tipoElementoSenalPeru: elementos, senalVerticalPeru: modelos }} fullWidth>
                        <SelectInput optionText="fullCodigo" fullWidth />
                    </ReferenceInput>
                }
                <div formClassName={classes.grid_cont12} fullWidth></div>
                <DateInput label="Fecha" source="fecha" id="datefield" formClassName={classes.grid_cont4_f} fullWidth validate={[validarfecha, required()]} readonly />
                <FormDataConsumer formClassName={classes.ocultar}>
                    {({ formData, ...rest }) => {
                        if (formData && formData.fecha) {
                            let fecha = new Date(formData.fecha + "T00:00:00");
                            let fechaact = new Date();
                            if (fecha > fechaact || !fecha) {
                                formData.fecha = fechaact
                            }
                        }
                    }}
                </FormDataConsumer>
                <TextInput label="Condición Global" source="medicion_global" formClassName={classes.medicion_global} fullWidth />
                <Query type='GET_ONE' resource='preguntas' payload={{ id: "preguntas/23" }}>
                    {({ data, loading, error }) => {
                        if (data && data.peso) {
                            return (
                                <NumberInput source="medicion_global_peso" defaultValue={data.peso} style={{ display: "none" }} />
                            )
                        } else {
                            return (
                                <span>No Se Encontró Peso Para la Medición Global</span>
                            )
                        }
                    }}
                </Query>
                {/* TABS */}
                <TabbedForm redirect="list" save={false} submitOnEnter={false} saving={false} className="Evaluaciones-tabs">
                    <FormTab label="Deterioro de Placa o Panel">
                        <Grupo1 />
                    </FormTab>
                    <FormTab label="Deterioro de los Elementos de Fijación">
                        <Grupo2 />
                    </FormTab>
                    <FormTab label="Deterioro de los Soportes/Estructuras de Soportes">
                        <Grupo3 />
                    </FormTab>
                    <FormTab label="RETRO-REFLECTIVIDAD">
                        <WithProps>{({ record, ...props }) =>
                            <Retroreflectividad />}
                        </WithProps>
                    </FormTab>
                    <FormTab label="DECOLORACIÓN">
                        <Decoloracion />
                    </FormTab>
                </TabbedForm>
                <TabbedForm redirect="list" save={false} submitOnEnter={false} saving={false} className="Evaluaciones-tabs">
                    {/*<FormTab label="Grupo 1">
                        <Grupo1 />
                    </FormTab>
                    <FormTab label="Grupo 2">
                        <Grupo2 />
                    </FormTab>
                    <FormTab label="Grupo 3">
                        <Grupo3 />
                    </FormTab>*/}
                </TabbedForm>
            </SimpleForm>
        </PimsCreate>
    )

});
const WithProps = ({ children, ...props }) => children(props);
import React , {useState}from 'react';
import { NumberInput, TextInput,  BooleanInput , Query, AutocompleteArrayInput, AutocompleteInput, number
} from 'react-admin';
import { ReferenceInput, SelectInput, minValue } from 'react-admin';
import { FormTab } from 'react-admin';
import { PimsCreate } from './../Create';
import {required,minLength,maxLength,} from 'react-admin';
import { ReferenceArrayInput, SelectArrayInput,  } from 'react-admin'
import { TabbedForm } from './../TabbedForm';
import Grid from '@material-ui/core/Grid';

export const ProveedorCreate = props => {
 
    const [departamentoss , setDepartamento]= useState('');

    const ValidarDepartamento = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(valor) 
    }

/*VALOR PARA ORDENAMIETO DE SISTEMA*/

    const[sistemaNombre , setSistemaNombre]=useState('');
    const SistemaNombre = (value) =>{
        setSistemaNombre(value)
    }

    return(
    <PimsCreate {...props} >
        <TabbedForm title="Crear proveedor" submitOnEnter={false} redirect="list"  >  
            <FormTab label="PROYECTO"  >        
            <Grid container spacing={24}>
                <Grid item xs={4}>
                    <ReferenceInput label="Proyecto" source="proyecto" reference="proyectos" validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                        <SelectInput  optionText="nombre" disabled fullWidth />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    <BooleanInput source="mantenimiento" fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <BooleanInput source="suministros" fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput 
                        label="Sistema" 
                        source="sistema" 
                        reference="sistemas" 
                        validate={[required(), SistemaNombre]}
                    >
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid>
                {    sistemaNombre &&                                
                    <Query type="GET_ONE" resource="sistemas" payload={{ id: sistemaNombre}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput  
                                    style={{display:'none'}} 
                                    label="Nombre sistema" 
                                    defaultValue={data.nombre} 
                                    source="nombreSistema" 
                                    fullWidth validate={[required()]}
                                />;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                }
                <Grid item xs={3}>
                <SelectInput label="Tipo de Identificación" source="identificacion" allowEmpty emptyValue="" choices={[
                        { id: 'CC', name: 'CC' },
                        { id: 'NIT', name: 'NIT' },
                        { id: 'Otro', name: 'Otro' },
                        
                    ]} fullWidth validate={[required()]}/>
                </Grid>
                <Grid item xs={4}>
                <TextInput label="Número de identificación" source="numeroIdentificacion" fullWidth validate={[required(),  minLength(5), maxLength(25), number("El valor debe ser numérico.")]} />
                </Grid>
                <Grid item xs={5}>
                    <TextInput label="Empresa" source="razonSocial" fullWidth validate={[required(), minLength(2), maxLength(250)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Nombres" source="nombre" fullWidth validate={[ minLength(2), maxLength(250)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Apellidos" source="apellido" fullWidth validate={[ maxLength(250)]}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="Dirección" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(250)]}/>
                </Grid>
                
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Departamento" 
                        source="departamento"  
                        reference="departamentos" 
                        filterToQuery={searchText => ({ nombre: searchText })} 
                        sort={{field : 'nombre', order : 'asc'}}
                        onChange={ValidarDepartamento}
                        fullWidth 
                        validate={[required()]}
                    >
                        <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    {/* MUNICIPIOS POR DEPARTAMENTO */}                    
                    {
                        departamentoss === '/departamentos/05' && (
                            // <ReferenceArrayInput 
                            //  label="Distrito" source="municipio1" 
                            //  reference="municipios"  
                            //   fullWidth>
                            //     <SelectArrayInput optionText="nombre" fullWidth/>
                            // </ReferenceArrayInput>
                            
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio1"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/05'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/08' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio2"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/08'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/11' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio3"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/11'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/13' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio4"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/13'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/15' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio5"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/15'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/17' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio6"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/17'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/18' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio7"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/18'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/19' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio8"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/19'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/20' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio9"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/20'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/23' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio10"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/23'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }                    
                    {
                        departamentoss === '/departamentos/25' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio11"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/25'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>                           
                        )
                    }
                    {
                        departamentoss === '/departamentos/27' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio12"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/27'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>                           
                        )
                    }
                    {
                        departamentoss === '/departamentos/41' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio13"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/41'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>                          
                        )
                    }
                    {
                        departamentoss === '/departamentos/44' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio14"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/44'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/47' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio15"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/47'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/50' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio16"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/50'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/52' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio17"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/52'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/54' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio18"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/54'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/63' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio19"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/63'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/66' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio20"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/66'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/68' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio21"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/68'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput> 
                        )
                    }
                    {
                        departamentoss === '/departamentos/70' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio22"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/70'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/73' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio23"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/73'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/76' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio24"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/76'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/81' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio25"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/81'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/85' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio26"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/85'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/86' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio27"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/86'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/88' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio28"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/88'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/91' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio29"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/91'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/94' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio30"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/94'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/95' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio31"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/95'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/97' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio32"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/97'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                    {
                        departamentoss === '/departamentos/99' && (
                            <ReferenceArrayInput 
                                label="Distrito" 
                                source="municipio33"  
                                reference="municipios" 
                                filter={{departamento: '/departamentos/99'}}
                                filterToQuery={searchText => ({ nombre: searchText })} 
                                sort={{field : 'nombre', order : 'asc'}}
                                fullWidth 
                                validate={[required()]}
                            >
                                <AutocompleteArrayInput   options={{fullWidth : true}} optionText="nombre"  />
                            </ReferenceArrayInput>
                        )
                    }
                </Grid>  
                {/* <Grid item xs={2}>                   
                   <ReferenceInput label="Indicativo" source="departamento" reference="departamentos" >
                       <SelectInput  optionText="indicativo" disabled fullWidth />
                   </ReferenceInput>                  
               </Grid> */}
                <Grid item xs={6}>
                    <TextInput label="Teléfono 1"   source="telefono" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]}/> 
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Teléfono 2"  source="telefono2" fullWidth className="labelNumber" validate={[number("Solo Números")]}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput label="Correo electrónico" source="correo" fullWidth validate={[minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={12}>
                    <TextInput label="Observaciones" source="observaciones" fullWidth validate={[minLength(2), maxLength(200)]}/> 
                </Grid>                
            </Grid>            
            </FormTab>
        </TabbedForm>
    </PimsCreate>
)};
import React, { useState } from 'react';
import { FormTab, NumberInput, ReferenceInput, SelectInput, FormDataConsumer, DateInput, AutocompleteInput,
  TabbedForm, BooleanInput, TextInput, ArrayInput, SimpleFormIterator 
} from 'react-admin';
import { PimsElementoActions } from './../PimsElementoActions';
import { abValidationInicio, abValidationFin, latMininima,Entero ,lngMininima, validarDosDecimales, validarTresDecimales, isFechaInvalida
 } from '../../validacionesInputs';
import Typography from '@material-ui/core/Typography';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import { DInput } from '../../../inputs/DInput';
import { AInput } from '../../../inputs/AInput';
import { styles } from './../../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import ElementosCreados from './../../ElementosCreados';
import { withStyles } from '@material-ui/core';
import { required, minValue, maxValue, minLength, maxLength } from 'react-admin';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import config from '../../../config/config';
import { ImagenesInventario } from '../../images/ImagenesInventario';
let urlAppjs = config.UrlServer;

const validarEnteroNT = (value) => {
  if (value) {
    if (!/^([0-9]+)*$/.test(value)) { return "Debe ser un número entero"; }
  }
}

const choices = [
  { id: '2000', name: '2000' },
  { id: '2001', name: '2001' },
  { id: '2002', name: '2002' },
  { id: '2003', name: '2003' },
  { id: '2004', name: '2004' },
  { id: '2005', name: '2005' },
  { id: '2006', name: '2006' },
  { id: '2007', name: '2007' },
  { id: '2008', name: '2008' },
  { id: '2009', name: '2009' },
  { id: '2010', name: '2010' },
  { id: '2011', name: '2011' },
  { id: '2012', name: '2012' },
  { id: '2013', name: '2013' },
  { id: '2014', name: '2014' },
  { id: '2015', name: '2015' },
  { id: '2016', name: '2016' },
  { id: '2017', name: '2017' },
  { id: '2018', name: '2018' },
  { id: '2019', name: '2019' },
  { id: '2020', name: '2020' },
  { id: '2021', name: '2021' },
  { id: '2022', name: '2022' },
  { id: '2023', name: '2023' },
  { id: '2024', name: '2024' },
  { id: '2025', name: '2025' },
  { id: '2026', name: '2026' },
  { id: '2027', name: '2027' },
  { id: '2028', name: '2028' },
  { id: '2029', name: '2029' },
  { id: '2030', name: '2030' }
];

export const ElementoPontonCreate = withStyles(styles)(({ classes, ...props }) => {

  const [unidads, setUnidad] = useState('');
  const [tramos, setTramo] = useState('');

  const ValidarUnidad = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const Unidades = { unidadFuncional: unidads };

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }

  const Tramoss = { tramo: tramos };

  /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');
  const [segmentos, setSegmento] = useState('');
  const ValidarSegmento = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(value)
  }


  /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [estados, setEstado] = useState('');

  const ValidarEstado = (value) => {
    console.log(value)
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setEstado(valor)

  }

  /*Validación de rango de coordenadas*/
  const lngIni = (value) => {

    if (lngMax !== '') {
      if (parseFloat(value) > parseFloat(lngMin) || parseFloat(value) < parseFloat(lngMax)) {
        return "El rango debe estar entre " + lngMin + " y " + lngMax;
      }
    }
  }


  const latIni = (value) => {

    if (latMax !== '') {

      if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {

        return "El rango debe estar entre " + latMin + " y " + latMax;
      }
    }
  }
  /*Fin de rango de coordenadas*/

/*OPCIONES PARA TIPO DE IMÁGENES*/
  const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
  const OpcionTipoImagen = (value) =>{    
       setDefaultTipoImagen([
           {
               tipoImagen : '/tipo_images/1',
           },
           {
               tipoImagen : '/tipo_images/7',
           },
           {
               tipoImagen : '/tipo_images/8',
           },
           {
               tipoImagen : '/tipo_images/9',
           },
           {
               tipoImagen : '/tipo_images/5',
           },
           {
               tipoImagen : '/tipo_images/6',
           }
       ])
  }

  return (
    <PimsCreate  {...props} title="Crear Elemento Pontones" actions={<PimsElementoActions />}>
      <TabbedForm redirect="list">
        <FormTab label="IDENTIFICACIÓN">
          <CoordenadasProyecto/>
          <SelectInput label="Elemento" source="tipoElemento" validate={[required(),OpcionTipoImagen]}
            choices={[
              { id: 'PTON', name: 'Pontón'}
            ]}
            fullWidth formClassName={classes.grid_cont6_f} />
          <SelectInput label="Margen" source="margen" allowEmpty emptyValue="" validate={[required()]}
            choices={[
              { id: 'Derecha', name: 'Derecha' },
              { id: 'Izquierda', name: 'Izquierda' },
              { id: 'Centro', name: 'Centro' },
            ]}
            fullWidth formClassName={classes.grid_cont6_s} />
          <SelectInput label="Tipo" source="tipoPonton" allowEmpty emptyValue="" validate={[required(), maxLength(20)]}
            choices={[
              { id: "Concreto", name: "Concreto" },
              { id: "Metálicos o mixtos", name: "Metálicos o mixtos" },
            ]}
            fullWidth formClassName={classes.grid_cont6_f} />
          <NumberInput label="Número de carriles" source="numeroCarriles" fullWidth validate={[required(),Entero ,minValue(1), maxValue(10)]} formClassName={classes.grid_cont6_s} />
          <h3 style={{ marginBottom: 0 }}>Localización</h3>
          <Divider fullWidth />
              <ReferenceInput
                label="Unidad Funcional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
                formClassName={classes.grid_cont4_f}
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
            {unidads &&
                <ReferenceInput
                  label="Tramo"
                  source="tramo"
                  alwaysOn
                  reference="tramos"
                  filter={{ unidadFuncional: unidads }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  sort={{ field: 'codigo', order: 'ASC', }}
                  validate={[required(), ValidarTramo]}
                  formClassName={classes.grid_cont4_s}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
            }
            {tramos &&
                <ReferenceInput
                  label="Segmento"
                  source="segmento"
                  reference="segmentos"
                  sort={{ field: 'codigo', order: 'ASC' }}
                  filter={{ tramo: tramos }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  validate={[required(), ValidarSegmento]}
                  formClassName={classes.grid_cont4_s}
                >

                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
            }
          {/* ELEMENTOS OCULTOS */}
            {segmentos && <AbsSegmento segmento={segmentos}/>} 
          {/* ----------------- */}
          {segmentos &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas iniciales</Typography>
                <Divider fullWidth style={{ marginTop: '10px' }} />
              </Grid>
            </Grid>
          }
          {segmentos && 
              <PRInput 
                label="Abscisa" 
                id="ingresoAbInicio" 
                source="abInicio" 
                fullWidth 
                validate={[abValidationInicio, required()]} 
                formClassName={classes.grid_cont4_f} 
              />
          }
          {segmentos && 
              <NumberInput 
                label="Latitud" 
                id="LatitudInicial" 
                source="latitudInicial" 
                fullWidth 
                validate={[required(), latMininima, LatitudMinimaArray]} 
                formClassName={classes.grid_cont4_s} 
              />
          }
          {segmentos && 
              <NumberInput 
                label="Longitud" 
                id="LongitudInicial" 
                source="longitudInicial" 
                fullWidth 
                validate={[required(), lngMininima, LongitudMinimaArray]} 
                formClassName={classes.grid_cont4_s} 
              />
            }
          <br/>
          {segmentos &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                <Divider fullWidth style={{ marginTop: '10px' }} />
              </Grid>
            </Grid>
          }
          {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, required()]} formClassName={classes.grid_cont4_f} />}
          {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[required(), latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont4_s} />}
          {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[required(), lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4_s} />}
          {segmentos &&
            <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
              defaultValue={[
                {
                  lat: latitudMinimaArray,
                  lng: longitudMinimaArray
                },
                {
                  lat: latitudMaximaArray,
                  lng: longitudMaximaArray
                }
              ]}
            >
              <SimpleFormIterator disableAdd disableRemove >
                <NumberInput label="Latitud" source="lat" fullWidth />
                <NumberInput label="Longitud" source="lng" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          }
        </FormTab>
        <FormTab label="Datos Administrativos">
          <h3 style={{ marginBottom: 0 }}>Detalles Constructivos</h3><Divider fullWidth />
          <DateInput label="Año" source="ano" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont3_f} />
          <NumberInput label="Área (m²)" source="area" validate={[minValue(0), validarDosDecimales]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="Norma de diseño" source="norma" fullWidth validate={[minLength(1), maxLength(60)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="Diseñador" source="disenador" fullWidth validate={[minLength(1), maxLength(60)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="Informe de Diseño" source="info_di" fullWidth validate={[minLength(1), maxLength(200)]} />
          <h3 style={{ marginBottom: 0 }}>Detalles de Refuerzo o Rehabilitación</h3><hr fullWidth />
          <DateInput label="Año" validate={[isFechaInvalida]} source="anoa" fullWidth formClassName={classes.grid_cont3_f} />
          <NumberInput label="Área (m²)" source="areaa" validate={[minValue(0)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="Norma de diseño" source="normaa" validate={[minLength(1), maxLength(60)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="Diseñador" source="disenadora" validate={[minLength(1), maxLength(60)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="Informe de Diseño" source="info_dia" validate={[minLength(1), maxLength(200)]} fullWidth />
          <div></div>
          <TextInput label="Observaciones" source="obser_dia" fullWidth validate={[minLength(1), maxLength(200)]} />
          <h3 style={{ marginBottom: 0 }}>Detalles de Inspección</h3><Divider fullWidth />
          <DateInput label="Fecha Recolección Datos" validate={[isFechaInvalida]} source="anob" fullWidth formClassName={classes.grid_cont4_f} />
          <SelectInput label="Dirección de abscisado" source="sentido"
            choices={[
              { id: 'S', name: 'S' },
              { id: 'N', name: 'N' },
              { id: 'E', name: 'E' },
              { id: 'O', name: 'O' },
            ]}
            fullWidth formClassName={classes.grid_cont4_s} />
          <ReferenceInput label="Estación de Conteo" source="estacion_conteo" reference="estacion_conteos" fullWidth formClassName={classes.grid_cont4_s}>
            <SelectInput optionText="nombre" />
          </ReferenceInput>
          <TextInput label="Inspector" source="inspector" validate={[minLength(1), maxLength(30)]} fullWidth formClassName={classes.grid_cont4_f} />
          <NumberInput label="Requisitos de Inspección" source="inspeccion" validate={[minValue(0), maxValue(99999999), validarEnteroNT]} fullWidth formClassName={classes.grid_cont4_s} />
          <NumberInput label="Número de Secciones de Inspección" source="numinspeccion" validate={[minValue(0), maxValue(99999999), validarEnteroNT]} fullWidth formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>Miembros Interesados</h3><Divider fullWidth />
          <TextInput label="Propietario" source="propietario" fullWidth validate={[minLength(1), maxLength(20)]} fullWidth formClassName={classes.grid_cont4_f} />
          <TextInput label="Administración Vial" source="admon_vial" fullWidth validate={[minLength(1), maxLength(20)]} fullWidth formClassName={classes.grid_cont4_s} />
          <TextInput label="Proyectista" source="proyectista" fullWidth validate={[minLength(1), maxLength(30)]} fullWidth formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>Características operacionales</h3><Divider fullWidth />
          <DInput label="Coeficiente de aceleración sísmica (Aa)" source="coeficiente" className="align-number" validate={[minValue(0), maxValue(1)]} fullWidth formClassName={classes.grid_cont6_f} />
          <SelectInput label="Paso por el cauce" source="cauce"
            choices={[
              { id: 'S', name: 'S' },
              { id: 'N', name: 'N' }
            ]}
            fullWidth formClassName={classes.grid_cont6_s} />
          <SelectInput label="Existe variante" source="variante"
            choices={[
              { id: 'S', name: 'S' },
              { id: 'N', name: 'N' }
            ]}
            fullWidth formClassName={classes.grid_cont4_f} />
          <FormDataConsumer formClassName={classes.grid_cont4_s}>
            {({ formData, ...rest }) => formData.variante === "S" && (
              <NumberInput label="Longitud variante (km)" source="long_variante" validate={[minValue(0), maxValue(99999999), validarTresDecimales]} fullWidth />
            )

            }
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.grid_cont4_s}>
            {({ formData, ...rest }) => formData.variante === "S" && (
              <SelectInput label="Estado" source="esta"
                choices={[
                  { id: 'B', name: 'B' },
                  { id: 'R', name: 'R' },
                  { id: 'M', name: 'M' },

                ]}
                fullWidth />
            )

            }
          </FormDataConsumer>
          <h3 style={{ marginBottom: 0 }}>Capacidad de carga para tránsito legal</h3><Divider fullWidth />
          <TextInput label="Vehículo de diseño" source="vehi_dise" fullWidth validate={[minLength(0), maxLength(10)]} formClassName={classes.grid_cont4_f} />
          <TextInput label="Clase distribución de carga" source="distri_carga" fullWidth validate={[minLength(0), maxLength(20)]} formClassName={classes.grid_cont4_s} />
          <DInput label="Capacidad de carga (t)" source="capa_carga" fullWidth className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="Longitud de luz crítica (m)" source="luz_critica" fullWidth className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="Factor de clasificación" source="fac_clasi" fullWidth className="align-number" formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>Capacidad de carga para transportes especiales</h3>
          <Divider fullWidth />
          <TextInput label="Fuerza cortante (t)" source="fue_cor" fullWidth validate={[required(), minValue(0, "Debe ser al menos 0."), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="Momento (t.m)" source="momento" fullWidth validate={[required(), minValue(0, "Debe ser al menos 0."), validarDosDecimales]} formClassName={classes.grid_cont4_s} />
          <DInput label="Línea de carga por rueda (t)" source="linea_carga" fullWidth className="align-number" formClassName={classes.grid_cont4_s} />
          <TextInput label="Observaciones" source="obs_1" validate={[required()]} fullWidth />
        </FormTab>
        <FormTab label="Geometría">
          <h3 style={{ marginBottom: 0 }}>General</h3><Divider fullWidth />
          <SelectInput label="Pontón en terraplén" source="pon_terra" validate={[required()]}
            choices={[
              { id: 'S', name: 'S' },
              { id: 'N', name: 'N' }
            ]}
            fullWidth formClassName={classes.grid_cont6_f} />
          <SelectInput label="Pontón en Curvatura/Tangente" source="pon_c" validate={[required()]}
            choices={[
              { id: 'T', name: 'T' },
              { id: 'C', name: 'C' },
            ]}
            fullWidth formClassName={classes.grid_cont6_s} />
          <h3 style={{ marginBottom: 0 }}>Detalles de estructura</h3><Divider fullWidth />
          <DInput label="Longitud (m)" source="long_2" fullWidth               validate={[required(), minValue(0), maxValue(15), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="Ancho losa superior (m)" source="ancho_2" fullWidth   validate={[required(), minValue(0), maxValue(200), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="Espesor losa superior (m)" source="espe_2" fullWidth  validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="Altura inferior (m)" source="altu_2" fullWidth        validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="Ancho losa inferior (m)" source="ancho_3" fullWidth   validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="Espesor losa inferior (m)" source="espe_3" fullWidth  validate={[required(), minValue(0), maxValue(200), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="Profundidad del relleno (m)" source="pro_3" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="Espesor de muros (m)" source="espe_4" fullWidth       validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="Separación entre muros (m)" source="sepa_4" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
        </FormTab>
        <FormTab label="Reparaciones">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Componente</TableCell>
                <TableCell align="right">Aplica</TableCell>
                <TableCell align="right">Fecha reparación</TableCell>
                <TableCell align="right">Tipo</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Año</TableCell>
                <TableCell align="right">Costo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow >
                <TableCell component="th">Superficie del pontón</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica1" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha1" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text1" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant1" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel13" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio1" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Andenes / Bordillos</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica2" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha2" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text2" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant2" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel2" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio2" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Barandas</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica3" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha3" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text3" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant3" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel3" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio3" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Conos /Taludes</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica14" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha14" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text14" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant14" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel14" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio14" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Elementos de armadura</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica15" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha15" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text15" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant15" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel15" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio15" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Cauce</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica16" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha16" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text16" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant16" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel16" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio16" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Losa superior</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica17" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha17" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text17" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant17" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel17" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio17" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Losa inferior</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica18" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha18" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text18" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant18" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel18" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio18" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Muros</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica19" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha19" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text19" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant19" fullWidth className="align-number"/></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel19" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio19" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Otros elementos</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica10" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha10" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text10" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant10" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel10" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio10" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">Pontón en general</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica21" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha21" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text21" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant21" fullWidth className="align-number" /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel21" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio21" fullWidth /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </FormTab>
        <FormTab label="DETALLES">
          <SelectInput label="Condición del elemento" source="estado" validate={[required()]} onChange={ValidarEstado}
            choices={[
              { id: "Existente", name: "Existente" },
              { id: "Reemplazo", name: "Reemplazo" },
              { id: "Nuevo", name: "Nuevo" },
            ]}
            fullWidth formClassName={classes.grid_cont4_f} />
          {estados === "Reemplazo" && (
            <SelectInput label="Motivo reemplazo" source="motivoReemplazo" validate={[required()]}
              choices={[
                { id: "Accidente", name: 'Accidente' },                                         
                { id: "Instalación", name: 'Instalación' },
                { id: "Reemplazo programado", name: 'Reemplazo programado' }, 
                { id: "Vandalismo", name: 'Vandalismo' },                                         
                { id: "Otro", name: 'Otro' }, 
              ]}
              fullWidth formClassName={classes.grid_cont4_s} />
          )
          }
          {estados === "Reemplazo" && (
            <ReferenceInput
              label="Reemplazo"
              source="elementoReemplazo"
              reference="elemento_pontons"
              filter={{
                  tipoElemento : "PTON",
                  status : 1,
                  // unidadFuncional: unidads,
                  // tramo: tramos,
                  // segmento : segmentos
              }}
              fullWidth
              validate={[required()]}
              formClassName={classes.grid_cont4_s}
        >
            <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigo" />
        </ReferenceInput>
            
            // <ElementosCreados tabla="elemento_pontons" tipo="create" fullWidth formClassName={classes.grid_cont4_s} />
          )
          }
          <br/>
          <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
          <div></div>
              <DateInput label="Fecha de instalación" source="fecha" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont4_f} />
              <ReferenceInput 
                  label="Proveedor" 
                  source="proveedor" 
                  reference="proveedors" 
                  filter={{ sistema: 'PTS' }}
                  sort={{ field: 'razonSocial', order: 'ASC' }}
                  filterToQuery={searchText => ({ razonSocial: searchText })} 
                  validate={[required()]}
                  formClassName={classes.grid_cont4_s}
              >
                  <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
              </ReferenceInput>
              <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]} formClassName={classes.grid_cont4_s} />
            
          <div></div>
          <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont6_f} />
          <div></div>
          <TextInput source="observaciones" validate={[required(), minLength(1), maxLength(200)]} fullWidth formClassName={classes.grid_cont6_f} />
        </FormTab>
        <FormTab label="FOTOGRAFÍAS">
          <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
        </FormTab>
      </TabbedForm>
    </PimsCreate>
  )
});
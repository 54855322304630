import React, { useState } from 'react';
import {  TabbedForm, FormTab, TextInput,SingleFieldList, ShowController, ShowView,  SelectField, TextField, DateField, ArrayField, ReferenceField, NumberField, BooleanField } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { PRField } from './../../fields/PRField';
import Divider from '@material-ui/core/Divider';
import { GMapField } from './../../fields/GMapField';
import Reparaciones from './campos/viewreparacion';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../EstilosGrid';
import { ButtonCarousel } from './../images/ButtonCarousel';

const choices1 = [
    { _id: '01', full_name: '01' },
    { _id: '02', full_name: '02' },
    { _id: '03', full_name: '03' },
];
const choices = [
    { _id: '01', full_name: 'Circular' },
    { _id: '02', full_name: 'Rectangular' },
    { _id: '03', full_name: 'Otra' },
];
const optionRenderer = choice => `${choice.id}`;
const choises2 = [
    { id: '01 ', name: ' 01 ' },
    { id: '02 ', name: ' 02 ' },
    { id: '03 ', name: ' 03 ' },
    { id: '04 ', name: ' 04 ' },
    { id: '05 ', name: ' 05 ' },
    { id: '00 ', name: ' 00 ' },
    { id: 'N/A ', name: ' N/A ' },
]
const choises3 = [
    { id: '01 ', name: ' Balancín ' },
    { id: '02 ', name: ' Rodillo ' },
    { id: '03 ', name: ' Placa en neopreno ' },
    { id: '04 ', name: ' Apoyo fijo ' },
    { id: '05 ', name: ' Basculante ' },
    { id: '00 ', name: ' Otros ' },
    { id: 'N/A ', name: ' No aplica ' },
];

export const PuenteShow = withStyles(styles)(({ classes, ...props }) => {
    const [imagen , setImagen] =useState(true);
    const [latitud , setLatitud] =useState();
    const [longitud , setLongitud] =useState();
    const Latitud =  (value) =>{
        setLatitud(value)
    }
    const Longitud =  (value) =>{
        setLongitud(value)
    }
    const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
        
     }
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Elemento Puentes" actions={<ButtonCarousel />} >
                    <TabbedForm >

                        <FormTab label="Identificación">

                            <ReferenceField label="Elemento" source="elementos" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4} filter={{ sistema: 'PTS' }}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            <TextInput source="src" validate={ValidarImagen} style={{display : 'none'}}/>
                            <TextField label="Margen" source="margen" fullWidth linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            <NumberField source="carriles" className="align-number" allowEmpty emptyValue="" linkType={false} fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Localización</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="Unidad Funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            {/* <PField label="PR Inicio" source="pInicio" />
                        <PField label="PR Fin"  source="pFin" /> */}
                            <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
                            <PRField label="Abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="Latitud" source="latitudInicial" fullWidth  style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud" source="longitudInicial" fullWidth  style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <Typography variant="b" component="b">Coordenadas Finales</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
                            <PRField label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="Latitud" source="latitudFinal" fullWidth  validate={Latitud} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud" source="longitudFinal" fullWidth validate={Longitud}  style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <GMapField style={{ width: "100%" }}
                                defaultZoom={12}
                                defaultCenter={{ lat: latitud, lng: longitud }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />
                        </FormTab>
                        <FormTab label="Datos Administrativos">

                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Detalles Constructivos</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <DateField label="Año" source="administrativos[0].years" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="Área(m²)" source="administrativos[0].area" id="area1" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <TextField label="Norma de diseño" source="administrativos[0].norma_diseno" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Diseñador" source="administrativos[0].disenador" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Informe de Diseño" source="administrativos[0].info_di" fullWidth formClassName={classes.grid_cont3} />
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Detalles Constructivos o Rehabilitacion</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <DateField label="Año" source="administrativos[0].yearsb" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="Área(m²)" source="administrativos[0].areab" fullWidth id="area2" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <TextField label="Norma de diseño" source="administrativos[0].norma_disenob" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Diseñador" source="administrativos[0].disenadorb" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Informe de Diseño" source="administrativos[0].info_dib" fullWidth formClassName={classes.grid_cont3} />
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Detalles de Inspección</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <DateField label="Fecha de Recolección de Datos" source="administrativos[0].fecha" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Dirección de abscisado" source="administrativos[0].direccion_abscisado" allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont3} />
                            <ReferenceField label="Estación de Conteo" source="administrativos[0].estacion_conteo" linkType={false} reference="estacion_conteos" fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            <TextField label="Inspector" source="administrativos[0].inspector" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="Número de Secciones de Inspección" source="administrativos[0].numinspeccion" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <NumberField label="Requisitos de Inspección" source="administrativos[0].inspeccion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Miembros interesados</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="Propietario" source="administrativos[0].propietario" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Administración Vial" source="administrativos[0].admon_vial" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Proyectista" source="administrativos[0].proyectista" fullWidth formClassName={classes.grid_cont3} />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Caracteristicas Operacionales</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <NumberField label="Coeficiente de aceleración sísmica (Aa)" source="administrativos[0].coe_sismo" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <TextField label="Paso por el cauce" source="administrativos[0].paso_cauce" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Existe variante" source="administrativos[0].exis_variante" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="Longitud variante (km)" source="administrativos[0].long_km" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <br></br>

                            <TextField label="Estado" source="administrativos[0].estado_op" fullWidth formClassName={classes.grid_cont3} />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Capacidad de carga para transito Operacional</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="Vehículo de diseño" source="administrativos[0].vehi_di" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Clase de Distribución de Carga" source="administrativos[0].distri_carga" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="Capacidad de Carga(t)" source="administrativos[0].capacidad" fullWidth id="area3" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <NumberField label="Longitud de luz Crítica (m)" source="administrativos[0].luz_critica" fullWidth id="area4" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <NumberField label="Factor de Clasificación" source="administrativos[0].fac_clas" fullWidth id="area5" formClassName={classes.grid_cont3} />
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Capacidad de Carga para Transportes especiales</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="Fuerza Cortante (t)" source="administrativos[0].fac_cort" fullWidth id="area6" formClassName={classes.grid_cont4} />
                            <TextField label="Momento (t.m)" source="administrativos[0].momento" fullWidth id="area7" formClassName={classes.grid_cont4} />
                            <TextField label="Línea de Carga por Rueda(t)" source="administrativos[0].car_rue" fullWidth id="area8" formClassName={classes.grid_cont4} />
                            <TextField label="Oservaciones" source="administrativos[0].observacioness" fullWidth formClassName={classes.grid_cont_div} />
                        </FormTab>
                        <FormTab label="Geometría">
                            <span></span>
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">General</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="Puente en terraplén" source="geometria[0].terraplen" allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="Puente en Curvatura/Tangente" source="geometria[0].curva" allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="Esviajamiento (gra)" source="geometria[0].esviajamiento" allowEmpty emptyValue="" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Superestructura</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <NumberField label="Número de luces" fullWidth source="geometria[0].luces" allowEmpty fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} emptyValue=""formClassName={classes.grid_cont4} />
                            <br/>
                            <NumberField label="Longitud luz menor (m)" source="geometria[0].luz_menor" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud luz mayor(m)" source="geometria[0].luz_mayor" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud total(m)" source="geometria[0].long_total" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont3} />
                            <NumberField label="Ancho de tablero (m)" source="geometria[0].ancho_tab" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Área del tablero (m²)" source="geometria[0].area_tab" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.first_field} formClassName={classes.grid_cont4} />
                            <NumberField label="Ancho del separador(m)" source="geometria[0].ancho_sep" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <NumberField label="Ancho del andén izquierdo(m)" source="geometria[0].ancho_anden_izq" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Ancho del andén derecho (m)" source="geometria[0].ancho_anden_der" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Ancho de calzada(m)" source="geometria[0].ancho_calzada" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Ancho entre bordillos(m)" source="geometria[0].ancho_bordillo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Subestructura</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <NumberField label="Altura de pilas(m)" source="geometria[0].alt_pilas" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud de apoyo en pilas (m)" source="geometria[0].long_pilas" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Altura de estribos (m)" source="geometria[0].alt_estribos" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud de apoyo en estribos (m)" source="geometria[0].long_estribos" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />

                        </FormTab>
                        <FormTab label="Superestructura">
                        <ArrayField label="Código" source="superestructura" fieldKey="uuid" disabled emptyValue="" formClassName={classes.grid_cont4}>
                        <SingleFieldList>
                            <ReferenceField  source="superficie" reference="superficies"  linkType={false} >
                                <TextField source="ids" />
                            </ReferenceField>
                            </SingleFieldList>
                                </ArrayField>
                                <ArrayField label="Tipo de superficie"  source="superestructura" fieldKey="uuid"  fullWidth formClassName={classes.grid_cont4}>
                        <SingleFieldList>
                            <ReferenceField source="superficie" linkType={false} reference="superficies"  >
                                <TextField source="descripcion" />
                            </ReferenceField>
                            </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <span></span>
                            <Typography variant="h3" style={{marginTop:"50px"}} component="h3">Principal</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField label="Diseño tipo" source="superestructura" formClassName={classes.grid_cont4} >
                            <SingleFieldList>
                            <TextField  source="dise_tipo" />
                            </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo  === "S" &&
                            <ArrayField label="Material" source="superestructura" allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} >
                            <SingleFieldList>
                                <TextField  source="material" />
                                </SingleFieldList>
                                </ArrayField>
                                  }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo  === "S" &&
                            <ArrayField label="Código" source="superestructura" fieldKey="uuid"  formClassName={classes.grid_cont4} >
                            <SingleFieldList>
                                <ReferenceField  source="transversarl" reference="transversarls" linkType={false} >
                                    <TextField source="ids" />
                                </ReferenceField>
                               </SingleFieldList>
                               </ArrayField>
                            }
                           {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo  === "S" &&
                           <ArrayField label="Tipo de estructuración transversal" source="superestructura" fieldKey="uuid" formClassName={classes.grid_cont4} fullWidth>
                           <SingleFieldList>
                                <ReferenceField  source="transversarl" linkType={false} reference="transversarls" >
                                    <TextField source="descripcion" />
                                </ReferenceField>
                                 </SingleFieldList>
                                 </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo  === "S" &&
                            <ArrayField label="Código" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                                <ReferenceField  source="longitud" reference="longituds" disabled emptyValue="" linkType={false}>
                                    <TextField source="ids" />
                                </ReferenceField>
                                </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo  === "S" &&
                             <ArrayField label="Tipo de estructuración longitudinal" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                             <SingleFieldList>
                                <ReferenceField source="longitud" reference="longituds" disabled emptyValue="" linkType={false} >
                                    <TextField source="decripcion" />
                                </ReferenceField>
                                </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.dise_tipo !== "S" &&
                                <div></div>
                            }

                            <Typography variant="h3" component="h3">Secundario</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField label="Diseño tipo" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                             <SingleFieldList>
                            <TextField source="dise_tipoa" emptyValue="" linkType={false}/>
                            </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa  === "S" &&
                            <ArrayField label="Material" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                                <TextField source="materiala" allowEmpty emptyValue="" linkType={false} />
                                </SingleFieldList>
                                </ArrayField>
                            }
                           {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa  === "S" &&
                            <ArrayField label="Código" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                                <ReferenceField source="transversarla" reference="transversarlas" linkType={false} disabled emptyValue="" formClassName={classes.grid_cont4}>
                                    <TextField source="ids" />
                                </ReferenceField>
                                </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa  === "S" &&
                            <ArrayField label="Tipo de estructuración transversal" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                                <ReferenceField  source="transversarla" reference="transversarlas" linkType={false} disabled>
                                    <TextField source="descripcion" />
                                </ReferenceField>
                                </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa  === "S" &&
                            <ArrayField label="Código" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                                <ReferenceField  source="longa" reference="longas" disabled emptyValue="" linkType={false} >
                                    <TextField source="ids" />
                                </ReferenceField>
                                </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa  === "S" &&
                            <ArrayField label="Tipo de estructuración longitudinal" source="superestructura" fieldKey="uuid" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                                <ReferenceField source="longa" reference="longas" linkType={false} disabled emptyValue="">
                                    <TextField source="descripcion" />
                                </ReferenceField>
                                </SingleFieldList>
                                </ArrayField>
                            }
                            <Typography variant="h3" component="h3">Señales</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField label="Carga máxima" source="superestructura" fieldKey="uuid" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                            <TextField source="car_max" fullWidth />
                            </SingleFieldList>
                            </ArrayField>
                            <ArrayField label="Velocidad máxima (km/h)" source="superestructura" fieldKey="uuid" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                            <NumberField source="velo_max" fullWidth   />
                            </SingleFieldList>
                            </ArrayField>
                            <ArrayField label="Otra" source="superestructura" fullWidth formClassName={classes.grid_cont3}>
                            <SingleFieldList>
                            <TextField  source="otra" fullWidth  />
                            </SingleFieldList>
                            </ArrayField>
                            <Typography variant="h3" component="h3">Detalles</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField label="Código" source="superestructura" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                            <ReferenceField  source="baranda" reference="barandas" disabled emptyValue="" linkType={false}>
                                <TextField source="ides" />
                            </ReferenceField>
                            </SingleFieldList>
                            </ArrayField>
                            <ArrayField label="Detalles de baranda" source="superestructura" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                            <ReferenceField  source="baranda" reference="barandas" disabled emptyValue="" linkType={false}>
                                <TextField source="descripcion" />
                            </ReferenceField>
                            </SingleFieldList>
                            </ArrayField>
                            <ArrayField label="Código" source="superestructura" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                            <ReferenceField source="expantion" reference="expantions" emptyValue="" linkType={false} >
                                <TextField source="ids" />
                            </ReferenceField>
                            </SingleFieldList>
                            </ArrayField>
                            <ArrayField label="Junta de expansión" source="superestructura" fullWidth formClassName={classes.grid_cont4}>
                            <SingleFieldList>
                            <ReferenceField  source="expantion" reference="expantions" emptyValue="" linkType={false} >
                                <TextField source="descripcion" />
                            </ReferenceField>
                            </SingleFieldList>
                            </ArrayField>
                            
                        </FormTab>

                        <FormTab label="Subestructura">
                            <Typography variant="h3" component="h3">Estribos</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="Tipo" source="subestructura[0].tipoestribos" reference="tipoestribos" fullWiidth linkType={false} formClassName={classes.grid_cont3s} >
                                <TextField source="descripcion" />
                            </ReferenceField>
                            <ReferenceField label="Código" source="subestructura[0].estri" reference="estris" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <TextField source="ids" disabled emptyValue="" />
                            </ReferenceField>
                            <ReferenceField label="Material" source="subestructura[0].estri" reference="estris" allowEmpty emptyValue="" fullWidth linkType={false} formClassName={classes.grid_cont3} >
                                <TextField source="descripcion" fullWidth />
                            </ReferenceField>
                            <SelectField label="Tipo de cimentación" source="subestructura[0].tipo_cimen" allowEmpty emptyValue="" fullWidth choices={[
                                { id: '01 ', name: ' Profunda ' },
                                { id: '02 ', name: ' Superficial ' },
                            ]} optionText="name" linkType={false} formClassName={classes.grid_cont3} />
                            <br></br><br></br>
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">Pilas</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="Código" source="subestructura[0].tippilas" reference="tippilas" disabled fullWidth linkType={false} formClassName={classes.grid_cont3s}>
                                <TextField source="ids" />
                            </ReferenceField>
                            <ReferenceField label="Tipo" source="subestructura[0].tippilas" reference="tippilas" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <TextField source="descripcion" fullWidth />
                            </ReferenceField>
                            <TextField label="Código" source="subestructura[0].forma_pila" optionText="full_name" optionValue="_id" allowEmpty emptyValue="" disabled fullWidth choices={choices1} fullWidth linkType={false} formClassName={classes.grid_cont3} />
                            <SelectField label="Forma" source="subestructura[0].forma_pila" fullWidth choices={choices} optionText="full_name" optionValue="_id" fullWidth linkType={false} formClassName={classes.grid_cont3} />
                            <SelectField label="Tipo de cimentación" source="subestructura[0].cimen_pila" fullWidth choices={[
                                { id: '01 ', name: ' Profunda ' },
                                { id: '02 ', name: ' Superficial ' },
                            ]} optionText="name" fullWidth linkType={false} formClassName={classes.grid_cont3s} />
                            <ReferenceField label="Código" source="subestructura[0].estrisa" reference="estris" disabled emptyValue="" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <TextField source="ids" />
                            </ReferenceField>
                            <ReferenceField label="Material" source="subestructura[0].estrisa" reference="estris" allowEmpty emptyValue="" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <TextField source="descripcion" />
                            </ReferenceField>
                            <br></br><br></br>
                            <Typography variant="h3" component="h3">Apoyos</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="Código" source="subestructura[0].apoyo_fijo" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                            <SelectField label="Apoyos fijos sobre estribos" source="subestructura[0].apoyo_fijo" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                            <TextField label="Código" source="subestructura[0].pila_movil" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                            <SelectField label="Apoyos móviles sobre estribos" source="subestructura[0].pila_movil" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                            <TextField label="Código" source="subestructura[0].pila_pila" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                            <SelectField label="Apoyos fijos en pilas" source="subestructura[0].pila_pila" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                            <TextField label="Código" source="subestructura[0].apoyo_movil" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                            <SelectField label="Apoyos móviles en pilas" source="subestructura[0].apoyo_movil" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                            <TextField label="Código" source="subestructura[0].pila_vigas" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                            <SelectField label="Apoyos fijos en vigas" source="subestructura[0].pila_vigas" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                            <TextField label="Código" source="subestructura[0].movil_vigas" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                            <SelectField label="Apoyos móviles en vigas" source="subestructura[0].movil_vigas" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                        </FormTab>
                        <FormTab label="Reparaciones">

                            <WithProps>{({ record, ...props }) =>
                                <Reparaciones record={record} />}
                            </WithProps>
                        </FormTab>
                        <FormTab label="DETALLES">
                            <TextField label="Condición del elemento" source="detalles[0].estado" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="Motivo de reemplazo" source="detalles[0].mot_remplazo" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="Elemento que Reemplazo" source="detalles[0].elemnto_remplazo" linkType={false} fullWidth formClassName={classes.grid_cont3} />
                            <br/>
                            <TextField label="Estado" source="detalles[0].estados" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            <br/>
                            <DateField label="Fecha de instalación" source="detalles[0].fecha" fullWidth formClassName={classes.grid_cont4} />
                            <ReferenceField label="Proveedor" source="detalles[0].proveedor" reference="proveedors" linkType={false} filter={{ sistema: 'PTS' }} fullWidth formClassName={classes.grid_cont4} >
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="Garantía (meses)" source="detalles[0].garantia" reference="detalless" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="detalles[0].incluir" />
                            <TextField label="Detalles y observaciones" source="detalles[0].observaciones" fullWidth formClassName={classes.grid_cont4} />
                        </FormTab>
                        <FormTab label="Auditoria">
                            <DateField source="createdAt" label="Fecha creación" />
                            <TextField source="createdBy" label="Creado por" />
                            <DateField source="updatedAt" label="Fecha actualización" />
                            <TextField source="updatedBy" label="Actualizado por" />
                        </FormTab>

                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}
);
const WithProps = ({ children, ...props }) => children(props);
import React, {useState} from 'react';
import {  TabbedShowLayout, TabbedForm ,Toolbar, SelectInput, SimpleForm} from 'react-admin';
import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Parametros from './parametros';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};
const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar>
));
export const ParametrosShow = withStyles(styles)(({ classes, ...props }) => {
const [tipoParametro, setTipoParametro] = useState('');
const validarTipoParametro=(value)=>{
setTipoParametro(value);
}
return (
<Show title="Mostrar Parámetros de Evaluación"  {...props}>
<SimpleForm submitOnEnter={false} toolbar={<CustomToolbar/>}>   
<Typography component="b" variant="b" style={{textAlign: "center"}} formClassName={classes.grid_cont12} fullWidth>Parametros de Evaluación</Typography>
        <Divider formClassName={classes.grid_cont12} fullWidth/>
         <SelectInput label="Indicadores que apliquen a:" choices={[
             {id:"All", name:"Todos los Parámetros"},
             {id:"PFLEX", name:"Indicadores exclusivos para Pavimento Flexible"},
             {id:"PRIG", name:"Indicadores exclusivos para Pavimento Rígido"},
             {id:"PRIGYFLEX", name:"Indicadores exclusivos para Pavimento Rigido y Flexible"},
             {id:"PUENTYEST", name:"Indicadores exclusivos de Puentes y Estructuras"},
             {id:"TUN", name:"Indicadores exclusivos para Túneles"},
             {id:"OPER", name:"Indicadores Operacionales"},

         ]} formClassName={classes.grid_cont12} fullWidth validate={validarTipoParametro} defaultValue="All"/>
          <WithProps>{({record,...props})=>
         <Parametros aplicaind={tipoParametro} tipo="mostrar" record={record}/>
        }
        </WithProps>
</SimpleForm>
</Show>)
})
const WithProps = ({children,...props}) => children(props);
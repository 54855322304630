import React  from 'react';
import { TextInput, SelectInput,ReferenceInput,AutocompleteInput,NumberInput,minValue,maxValue,BooleanInput,ArrayInput,SimpleFormIterator } from 'react-admin';
import { withStyles, Grid } from '@material-ui/core';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';
import { styles } from './../../EstilosGrid';
import { required, maxLength } from 'react-admin';
import { abValidationInicio,Entero,validarUnDecimal,validateDecima,validarDecimales,validarAB, abValidationFin, validarDosDecimales, validarPorcentaje, validarDecimales2 } from '../validacionesInputs';
import { useState } from 'react';
import Analisis from './tablaAnalisis/analisis';





export const ParametrosAnalisisCreate = withStyles(styles)(({ classes, ...props }) => {
    const styles = {
        first_field: { width: '100%' }    
    };
    const choices=[
        { id: '2000', name: '2000' },
        { id: '2001', name: '2001' },
        { id: '2002', name: '2002' },
        { id: '2003', name: '2003' },
        { id: '2004', name: '2004' },
        { id: '2005', name: '2005' },
        { id: '2006', name: '2006' },
        { id: '2007', name: '2007' },
        { id: '2008', name: '2008' },
        { id: '2009', name: '2009' },
        { id: '2010', name: '2010' },
        { id: '2011', name: '2011' },
        { id: '2012', name: '2012' },
        { id: '2013', name: '2013' },
        { id: '2014', name: '2014' },
        { id: '2015', name: '2015' },
        { id: '2016', name: '2016' },
        { id: '2017', name: '2017' },
        { id: '2018', name: '2018' },
        { id: '2019', name: '2019' },
        { id: '2020', name: '2020' },
        { id: '2021', name: '2021' },
        { id: '2022', name: '2022' },
        { id: '2023', name: '2023' },
        { id: '2024', name: '2024' },
        { id: '2025', name: '2025' },
        { id: '2026', name: '2026' },
        { id: '2027', name: '2027' },
        { id: '2028', name: '2028' },
        { id: '2029', name: '2029' },
        { id: '2030', name: '2030' }
      ]
      const choices2=[
        {id:10, name:'10'},
        {id:11, name:'11'},
        {id:12, name:'12'},
        {id:13, name:'13'},
        {id:14, name:'14'},
        {id:15, name:'15'},
        {id:16, name:'16'},
        {id:17, name:'17'},
        {id:18, name:'18'},
        {id:19, name:'19'},
        {id:20, name:'20'},
        {id:21, name:'21'},
        {id:22, name:'22'},
        {id:23, name:'23'},
        {id:24, name:'24'},
        {id:25, name:'25'},
        {id:26, name:'26'},
        {id:27, name:'27'},
        {id:28, name:'28'},
        {id:29, name:'29'},
        {id:30, name:'30'}
      ]
      const [years1, setYears1] = useState('');
      const [years2, setYears2] = useState('');
      const validarAño1 = (value) => {
         let sumaAños = parseInt(value) + parseInt(años)
          setYears1(value)
          setYears2(sumaAños)
      
      }
      const validarRango = (value) =>{
        if (value < 10 ){
          return "El valor debe ser mayor a 10";
        }
        else if (value > 30){
          return "El valor debe ser menor a 30"
        }
      }
      
      // const validarAño2 = (value) => {
      //   if(years1 > value){
      //     return "La fecha inicial debe ser anterior a la final."
      // }
      //     setYears2(value)
      
      
      // }
      const [años,setAños] = useState('')
      const validarAños = (value) => {
       
        let suma =  parseInt(years1) + parseInt (value)
        setAños(value)
        setYears2(suma)
         console.log(years1,value)
          console.log(suma)
      }
    return(
    <PimsCreate {...props} title="Crear Parámetros de planeación">
        <SimpleForm redirect="list" >
        <ReferenceInput
                label="Unidad Funcional"
                source="unidad_funcional"
                id="unidad_id"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required()]}
                fullWidth
                formClassName={classes.grid_cont4s}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
              <br/> 
                    <TextInput source="plan" label="Plan de mantenimiento" fullWidth validate={[maxLength(20)]} formClassName={classes.grid_cont4s}/>  
                    <NumberInput label="Número de años de análisis" source="num_analisis" fullWidth validate={[required(),validarRango,validarAños]} formClassName={classes.grid_cont4} />
                   <SelectInput id="12" label="Año de evaluación" allowEmpty  source="years1" validate={validarAño1} choices={choices} fullWidth defaultValue={'2020'} formClassName={classes.grid_cont3}/>
                    <TextInput label="Descripción del escenario" source="descripcion" fullWidth formClassName={classes.grid_cont12}/>
                    <NumberInput label="Tasa crecimiento tránsito" source="tasa_crecimiento"fullWidth validate={[required(),validarUnDecimal]} formClassName={classes.grid_cont4s}/>
                    <NumberInput label="Tasa de interés" source="tasa_interes"fullWidth validate={[required(),validarUnDecimal]} formClassName={classes.grid_cont4}/>
                     <TextInput label="Moneda" source="coin" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont3} />
                     <NumberInput label="Costo de reparación estructural" source="per_analisis" fullWidth validate={[required(),minValue(0)]} formClassName={classes.grid_cont4s}/>
                    <NumberInput label="Costo de reparación de la capa superficial" source="nom_analisis" fullWidth validate={[required(),minValue(0)]} formClassName={classes.grid_cont4}/>
                    {/*<SelectInput label="Año de evaluación" source="years" defaultValue={'2020'} fullWidth choices={choices} formClassName={classes.grid_cont4}/> */}
                  
                    <SelectInput id="13" label="Hasta" disabled allowEmpty  source="years2" choices={choices} defaultValue={years2} fullWidth style={{display:'none'}} formClassName={classes.grid_cont3}/>
                  {/* <WithProps>{({ record, ...props }) => */}
                  
                  {years2 && years1 &&
                                <Analisis years1={years1} years2={years2} />
                               
                  }
                            {/* </WithProps> */}
                    {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
                    {/* <SelectInput label="Comportamiento" source="tipo" fullWidth validate={[required()]} disabled  formClassName={classes.grid_cont3}/> */}
                                
        </SimpleForm>
    </PimsCreate>
)});
const WithProps = ({ children, ...props }) => children(props);
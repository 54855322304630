import React, {useState, Fragment} from 'react';
import { Query, Loading } from 'react-admin';

import Drawer from '@material-ui/core/Drawer';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button} from 'react-admin';
// import { CarouselImage } from './CarouselImagePeru';

export const ButtonCarousel = ({
    record,
    ImagenesOpen,
    data,
    TipoImagen
}) => {
    
    const [showPanel, setShowPanel] = useState(false);

    const handleClick = () =>{
        setShowPanel(true)
    }

    const handleCloseClick = () =>{
        setShowPanel(false)
    }
     
    if(typeof record !== 'undefined' && record.images !== [] && record.images !== null && record.images !== ''&& record.images.length != 0){
        console.log(record.images);
        const resource = record.images[0].split("/")[1];
        const payload = {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'ASC' },
            filter: { 
                recurso: record.id
            }
         };

        if(record.images.length > 0)
        {
            return (
                
                <Fragment>
                    <Button onClick={handleClick} label="Imágenes actuales">
                        <IconImageEye />
                    </Button>
                    <Drawer
                        anchor="right"
                        open={showPanel}
                        onClose={handleCloseClick}
                    >
                        <div>
                            <Button label="Close" onClick={handleCloseClick}>
                                <IconKeyboardArrowRight />
                            </Button>
                        </div>   
                        <Query type="GET_LIST" resource={resource} payload={payload}>
                            {({ data, loading, error }) => {
                                if (loading) { return <Loading />; }
                                if (error) {return <p>ERROR</p>; }
                                // console.log(data);

                                return (
                                    <Fragment>
                                        {data.map((item,index)=>   
                                                <div style={{textAlign: 'center', border :'solid  0.5px black', margin : '5px', padding : '5px'}} >
                                                        <img src={item.src} alt="Logo"  height="250"/>
                                                        <p style={{fontSize : '1.2em'}}>{item.tipoImagen_.nombre}</p>                                    
                                                </div>           
                                        )}
                                    </Fragment>
                                )
                            }}
                        </Query>    
                        {/* {
                            console.log(record)
                            
                        } */}
                    </Drawer>
                </Fragment>
            );
        }else{

            return(<div></div>)
        }     
    }else if(typeof ImagenesOpen !== 'undefined' && ImagenesOpen !== [] && ImagenesOpen !== null && ImagenesOpen !== ''){
        if(ImagenesOpen.length > 0)
        {
            return (
                
                <Fragment>
                    <Button onClick={handleClick} label="Imágenes actuales">
                        <IconImageEye />
                    </Button>
                    <Drawer
                        anchor="right"
                        open={showPanel}
                        onClose={handleCloseClick}
                    >
                        <div>
                            <Button label="Close" onClick={handleCloseClick}>
                                <IconKeyboardArrowRight />
                            </Button>
                        </div>               
                        {
                            ImagenesOpen.map((item,index)=>{    
                                                                
                                return(
                                    <div style={{textAlign: 'center', border :'solid  0.5px black', margin : '5px', padding : '5px'}} >
                                        <img src={item.src} alt="Logo"  height="250"/>
                                        <p style={{fontSize : '1.2em'}}>{item.tipoImagen_.nombre}</p>                                    
                                    </div>
                                )          
                            })
                        }                                         
                    </Drawer>
                </Fragment>
            );
        }else{

            return(<div></div>)
        }     
    }else{

        return(<div></div>)
    }    
};
import React, { useState, Fragment } from 'react';
import {
    NumberInput, minValue, TextInput, BooleanInput, ReferenceInput, SelectInput, SelectArrayInput,
    ReferenceArrayInput, AutocompleteArrayInput, AutocompleteInput, DateInput, Query, number, REDUX_FORM_NAME, FormDataConsumer
}
    from 'react-admin';
import { change } from 'redux-form';
import { Edit } from './../Edit';
import { SimpleForm } from './../SimpleForm';
import { required, minLength, maxLength, } from 'react-admin'
import Grid from '@material-ui/core/Grid';
import MunicipioArray from './municipioArray';



export const ProveedorEdit = props => {

    const [departamentoss, setDepartamento] = useState('Ninguno');

    const ValidarDepartamento = (value) => {
        // let idDepartamento = value.split("departamentos/")        
        // Auxprueba=idDepartamento[1]      
        setDepartamento(value);
    }

    /*VALOR PARA ORDENAMIETO DE SISTEMA*/

    const [sistemaNombre, setSistemaNombre] = useState('');
    const SistemaNombre = (value) => {
        console.log(value);
        setSistemaNombre(value)
    }
    return (
        <Edit {...props} title="Editar Proveedor" undoable={false} >
            <SimpleForm submitOnEnter={false} >
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput
                            label="Proyecto"
                            source="proyecto"
                            reference="proyectos"
                            disabled
                            fullWidth
                            validate={[required(), ValidarDepartamento]}
                            filterToQuery={searchText => ({ nombre: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanInput source="mantenimiento" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanInput source="suministros" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <ReferenceInput label="Sistema" source="sistema" reference="sistemas" validate={[required(), SistemaNombre]}>
                            <SelectInput optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    {/* <Grid item xs={12}>
                    <ReferenceInput 
                        label="Nombre sistema" 
                        source="nombreSistema" 
                        reference="sistemas" 
                        validate={[required()]}
                        filter={{ id: sistemaNombre}}
                    >
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid> */}
                    {sistemaNombre &&
                        <Query type="GET_ONE" resource="sistemas" payload={{ id: sistemaNombre }} >
                            {({ data, loading, error }) => {
                                if (data) {
                                    console.log(data.nombre);
                                    return <TextInput
                                        style={{ display: 'none' }}
                                        label="Nombre sistema"
                                        defaultValue={data.nombre}
                                        source="nombreSistema"
                                        fullWidth
                                        validate={[required()]}
                                    />;
                                }
                                return <div></div>;
                            }}
                        </Query>
                    }
                    <Grid item xs={4}>
                        <SelectInput label="Tipo de Identificación" source="identificacion" fullWidth allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'CC' },
                            { id: 'NIT', name: 'NIT' },
                            { id: 'Otro', name: 'Otro' },

                        ]} validate={[required()]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="Número de identificación" source="numeroIdentificacion" fullWidth validate={[required(),  minLength(5), maxLength(25), number("El valor debe ser numérico.")]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="Empresa" source="razonSocial" fullWidth validate={[required(), minLength(2), maxLength(250)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="Nombres" source="nombre" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="Apellidos" source="apellido" fullWidth />
                    </Grid>

                    <Grid item xs={4}>
                        <TextInput label="Dirección" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(250)]} />
                    </Grid>
                    <Grid item xs={8}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid container spacing={24}>
                                            <Grid item xs={6}>
                                                <ReferenceInput
                                                    id="departamento"
                                                    value=""
                                                    label="Departamento"
                                                    source="departamento"
                                                    reference="departamentos"
                                                    fullWidth
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    onChange={value => dispatch(
                                                        change(REDUX_FORM_NAME, 'municipio', null)
                                                    )}
                                                    validate={[required(), ValidarDepartamento]}
                                                >
                                                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReferenceArrayInput
                                                    label="Municipio"
                                                    source="municipio"
                                                    reference="municipios"
                                                    validate={[required()]}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    filter={{
                                                        departamento: departamentoss
                                                    }}
                                                >
                                                    <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceArrayInput>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>

                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput
                            label="Teléfono 1"
                            source="telefono"
                            fullWidth
                            className="labelNumber"
                            validate={[required()]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput
                            label="Teléfono 2"
                            source="telefono2"
                            fullWidth
                            className="labelNumber"
                            validate={[]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="Correo electrónico" source="correo" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="observaciones" fullWidth validate={[minLength(2), maxLength(200)]} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};
import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField, } from 'react-admin';
import { Show } from './../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const AtributoTitulo = ({ record }) => {
    return <span>Atributos {record ? `"${record.nombre}"` : ''}</span>;
};
export const AtributosShow = props => (
    <Show  {...props} title={<AtributoTitulo />} bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="Administración" submitOnEnter={false} >
                <TextField label="Nombre de rol" source="nombre" fullWidth />
                <TextField label="Tipo de rol" source="tipoRol" />
                <TextField label="Usuarios" source="indiusuario" />
                <TextField label="Perfiles" source="indiperfiles" />
                <TextField label="Asignar Clave" source="indiasigclaves" />
            </Tab>
            <Tab label="Proyecto" >
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Proyectos
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <TextField label="Proyectos" source="indiproyecto" />
                <TextField label="Rutas" source="indirutas" />
                <TextField label="Unidades Funcionales" source="indiunifuncionales" />
                <TextField label="Tramos" source="inditramos" />
                <TextField label="Segmentos" source="indisegmento" />

                <div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Contactos
                </Typography>

                <div></div><div></div>
                <Divider />
                <Divider />
                <Divider />

                <TextField label="Contactos de Emergencia" source="indicontemergencia" />
                <TextField label="Proveedores" source="indiproveedores" />
                <TextField label="Servicios Públicos" source="indiserpublicos" />

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Configuraciones - Materiales
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <TextField label="Materiales" source="indimateriales" />
                <TextField label="Granulometría" source="indiganulometria" />
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Configuraciones - Indicadores
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <TextField label="Parámetros de Evaluación" source="parametrosEvaluacionText" />
                <TextField label="Diagrama Colorimétrico" source="diagramaColorimetricoText" />
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Configuraciones - Deterioro PERS
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <TextField label="Reparaciones" source="reparacionesText" />
                <TextField label="Deterioros" source="deteriorosText" />
                <div></div>
            </Tab>
            <Tab label="Inventario">

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Segmentos (calzada)
                </Typography>
                <div></div><div></div>
                <Divider /> <Divider /> <Divider />
                <TextField label="Flexible" source="indiflexible" />
                <TextField label="Rígido" source="indirigido" />
                <TextField label="Adoquín" source="indiadoquin" />
                <TextField label="Otro" source="indiotro" />
                <div></div>           <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Elementos Inventario
                </Typography>
                <div></div>
                <Divider />
                <Divider />
                <Divider />

                <TextField label="Sistemas de Contención" source="indiseguridad" />
                <TextField label="Drenaje" source="indidrenaje" />
                <TextField label="Señalización" source="indisenalizacion" />
                <TextField label="Alrededores" source="indialrededores" />
                <TextField label="Otros Elementos" source="indiotroelemento" />
                <TextField label="Redes" source="indiredes" />

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    Puentes y Estructuras Especiales
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <TextField label="Puentes" source="indipuentes" />
                <TextField label="Contención" source="indicontencion" />
                <TextField label="Pontón" source="indiponton" />
                <TextField label="Túneles" source="inditunel" />
                <div></div>
                <di></di>
            </Tab>
            <Tab label="Tránsito">


                <b>Peajes</b>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />

                <TextField label="Inventario" source="indiinventario" />
                <TextField label="Tránsito Histórico" source="inditransitomensual" />
                <div></div>

                <b>Estación de Conteo</b>
                
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <TextField label="Inventario" source="indiinventarioa" />
                {/* <TextField label="Ejes Equivalentes" source="inditransitomensuala" />
                <TextField label="Deterioros" source="inditasacrecimiento" /> */}
                <div></div>
                <div></div>

            </Tab>
            <Tab label="Indicadores">
                <TextField label="Datos Básicos" source="indidatosbasicos" />
                <TextField label="Deterioro" source="indicoladepeaje" />
                <TextField label="Calificación Puentes" source="indicalificacionpuentes" />
                <TextField label="Calificación Pontones" source="indicalificacionpontones" />
                <TextField label="Disponibilidad de Vías" source="indDisponibilidadVia" />
                <TextField label="Cola de Peaje" source="indicoladepeaje" />

            </Tab>
            <Tab label="Gestión">
                <b>Programación</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />

                <TextField label="Parámetros de Planeación" source="parametrosAnalisisText" />
                <div></div>
                <div></div>

                <b>Pavimento PERS</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />

                <TextField label="Alternativa de Intervención" source="alternativaIntervencionText" />
                <div></div>
                <div></div>

                <b>Otros Sistemas</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <TextField label="Políticas Generales" source="politicaGeneralText" />
                <TextField label="Políticas Particulares" source="politicaParticularText" />

            </Tab>
            <Tab label="Auditoría">
                <DateField source="createdAt" label="Fecha creación" />
                <TextField source="createdBy" label="Creado por" />
                <div></div>
                <DateField source="updatedAt" label="Fecha actualización" />
                <TextField source="updatedBy" label="Actualizado por" />
                <div></div>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
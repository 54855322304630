import React, {Component, Fragment} from 'react';
import { Query, Loading } from 'react-admin';
import Drawer from '@material-ui/core/Drawer';

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button } from 'react-admin';
import { Gallery, GalleryImage } from 'react-gesture-gallery';
import loader from './../../../src/loading.gif';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

export class CarouselImage extends Component {
    constructor(props) {
        super(props) 
    }
    state = { 
        showPanel: false,
        index: 0,
        ImagesGallery: [], 

        resource  : '',
        payload : {},
        type : 'updated'   
    };

    componentWillMount() {
        console.log(this.props.record.images, "isStr? " + (typeof this.props.record.images[0] === "string"));
        console.log(this.props.record.images, "isObj? " + (typeof this.props.record.images[0] === "object"));
        if(
            typeof this.props.record.images     !== 'undefined' && 
            typeof this.props.record.images[0] === "string" &&
            typeof this.props.record.images    !== [] && 
            typeof this.props.record.images    !== null && 
            typeof this.props.record.images    !== ''
        ) {
                this.state.resource = this.props.record.images[0].split("/")[1];
                this.state.payload = {
                    pagination: { page: 1, perPage: 10 },
                    sort: { field: 'id', order: 'ASC' },
                    filter: { 
                        recurso: this.props.record.id
                    }
                 };
                 this.state.type = 'updated';
        } else if (
            typeof this.props.record.images     !== 'undefined' && 
            typeof this.props.record.images[0] === "object" &&
            typeof this.props.record.images    !== [] && 
            typeof this.props.record.images    !== null && 
            typeof this.props.record.images    !== ''
        ) {
            this.state.type = 'legacy';
        }


    //     //OBTENER EL TIPO DEL ELEMENTO
    //     let url_elemento_aux = document.location.hash;
    //     let url_elemento = url_elemento_aux.split("/%2F");
    //     let componente = url_elemento[1];
    //     componente = componente.replace("/show", "");
    //     componente = componente.replace("%2F", "/");
  
    //     var urlApi = urlAppjs+"/"+componente;
        
    //     fetch(urlApi)
    //       .then((response) => {
    //         return response.json()
    //       })
    //       .then((ImagesGallery) => {
    //         this.setState({ ImagesGallery: ImagesGallery["images"] })            
    //     })        

    }
    

    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    setIndex(value){
        if(value === this.state.ImagesGallery.length) {
            this.setState({ index: 0 });
        } else {
            this.setState({ index: value });
        }        
    }


    render() {    
        
        const { showPanel } = this.state;
        const { index } = this.state;
        const { ImagesGallery } = this.state;
        let urlImage = urlAppjs+"/";
        if(ImagesGallery !== [] && ImagesGallery !== null && ImagesGallery !== undefined){

       if(this.state.type == 'legacy'){
            return (
                <Fragment>
                    <Button onClick={this.handleClick} label="GALERIA">
                        <PhotoLibraryIcon />
                    </Button>
                    <Drawer
                        anchor="right"
                        open={showPanel}
                        onClose={this.handleCloseClick}
                        className="ContenedorGaleria"
                    >
                        <div>
                            <Button label="Cerrar" style={{color:'white'}} onClick={this.handleCloseClick}>
                                <IconKeyboardArrowRight />
                            </Button>
                        </div>
                        {/* CARRUSEL */}
                        <div className="GaleriaShow">
                            <Gallery
                                index={index}
                                onRequestChange={i => {
                                    this.setIndex(i);
                                }}
                            >
                                {this.props.record.images.map(image => (
                                    <GalleryImage src={image.src}/>
                                ))}                    
                            </Gallery>                
                        </div>
                    </Drawer>
                </Fragment>
            );
        } else if(this.state.type == 'updated'){
            return (
                <Fragment>
                    <Button onClick={this.handleClick} label="GALERIA">
                        <PhotoLibraryIcon />
                    </Button>
                    <Drawer
                        anchor="right"
                        open={showPanel}
                        onClose={this.handleCloseClick}
                        className="ContenedorGaleria"
                    >
                        <div>
                            <Button label="Cerrar" style={{color:'white'}} onClick={this.handleCloseClick}>
                                <IconKeyboardArrowRight />
                            </Button>
                        </div>
                        {/* CARRUSEL */}
                        <div className="GaleriaShow">
                            <Gallery
                                index={index}
                                onRequestChange={i => {
                                    this.setIndex(i);
                                }}
                            >
                                {this.props.record.images.map(image => (
                                    <Query type="GET_ONE" resource={this.state.resource} payload={{id: image}}>
                                        {({ data, loading, error }) => {
                                            if (loading) { return <Loading />; }
                                            if (error) {return <p>ERROR</p>; }
                                            // console.log(data);

                                            return (
                                                <GalleryImage src={data.src}/>    
                                            )
                                        }}
                                    </Query> 
                                ))}                    
                            </Gallery>                
                        </div>
                    </Drawer>
                </Fragment>
            );
        }
        
        
        else{
            return (
                <Fragment>
                    <Button onClick={this.handleClick} label="GALERÍA">
                        <PhotoLibraryIcon />
                    </Button>
                    <Drawer
                        anchor="right"
                        open={showPanel}
                        onClose={this.handleCloseClick}
                        className="ContenedorGaleria"
                    >
                        <div>
                            <Button label="Cerrar" style={{color:'white'}} onClick={this.handleCloseClick}>
                                <IconKeyboardArrowRight />
                            </Button>
                        </div>
                        {/* CARRUSEL */}
                        <div className="Reload">
                            <img src={loader}/>
                        </div>
                    </Drawer>
                </Fragment>
            );
        }
    }else{
        return <div></div>
    }
    }
}

export default CarouselImage;
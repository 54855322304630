import React from 'react';
import { TextInput, required, minLength, maxLength } from 'react-admin';
import config from '../../../../../config/config';
import { func } from 'prop-types';
let urlAppjs = config.UrlServer;
/**
 * Atributos requeridos
 * id
 * label
 * source
 * reference
 * operation
 * validate
 */

class UniqueUF extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fetch: [],
            error: false,
            tipo: "",
            oldValue : null,
            reference : "colores",
            id : this.props.source
        }
        
        this.unique  = this.unique.bind(this);
        this.ValidaLetra  = this.ValidaLetra.bind(this);
        
    }
    componentWillMount() {
        this.setState({ tipo: this.props.tipo })
        fetch(urlAppjs + "/"+this.state.reference+".json?proyecto="+window.localStorage.getItem('id_project')+"?pagination=false",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.state.fetch = response;
            });
        }
        
         ValidaLetra = (value)=>{
             if(value){
            if (typeof value !== 'string') return ''
            return value[0].toUpperCase()+ value.slice(1);
        }
         }
    unique() {
        if (document.getElementById(this.props.id)) {
            var texto = document.getElementById(this.props.id).value;
            if (this.state.oldValue == null) {
                this.state.oldValue = texto;
            }
            var find = this.state.fetch.find(data => data[this.props.source].toLowerCase().trim() == texto.toLowerCase().trim());
            switch (this.props.operation) {
                case "editar":
                    if (typeof find === 'object' && find[this.props.source].toLowerCase().trim() != this.state.oldValue.toLowerCase().trim()) {
                        this.state.error = true;
                    } else {
                        this.state.error = false;
                    }
                break;
                
                default:
                    if (typeof find === 'object') {
                        this.state.error = true;
                    } else {
                        this.state.error = false;
                    }
                    break;
            }
                
        }
        if (this.state.error == true) {
            return "El dato digitado ya se encuentra en uso.";
        }
    }
     
         
    render() {
        const {tipo} = this.state;
        if(tipo==="editar"){
        return (
            <TextInput
                id={this.props.source}
                label={this.props.label}
                source={this.props.source}
                style={{width:"300px"}}
                onChange={this.unique}
                disabled
                format={ this.ValidaLetra}
                validate={[
                    this.unique,
                    required(), 
                    minLength(2), 
                    maxLength(10)
                ]}
                formClassName={this.props.formClassName}
            />
        )
            }
            else{
                return (
                    <TextInput
                        id={this.props.source}
                        label={this.props.label}
                        source={this.props.source}
                        style={{width:"300px"}}
                        onChange={this.unique}
                        format={ this.ValidaLetra}
                        validate={[
                            this.unique,
                            required(), 
                            minLength(2), 
                            maxLength(10)
                        ]}
                        formClassName={this.props.formClassName}
                    />
                )
            }
    }
}

export default UniqueUF;




// export const validatestring = (value) => {
//     if(value){
//       if (isNaN(value)) {
//      let pattern = new RegExp("^[0-9]+$").length == 1;
//     if(!pattern.test(value)){
//         console.log(pattern.test(value));
//       return "El número no se está intersectando";
//     }
//     }
//   }
// }
// export const number = (message = 'Must be a number') =>
//     value => value && isNaN(Number(value)) ? message : undefined;
// export const validarDosDecimales =(value) =>{
//   if(value){
//       if(value !== 0 && value  !== 'No aplica'){
//           if (parseFloat(value)  % 1 !== 0) {
//               let pattern = new RegExp("^[+-]?[0-9]+([.][0-9]{1,2})$");
//               if(!pattern.test(value)){ 
//                   //let patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
//                   // if(!patterns.test(parseFloat(value) )){ 
//                   // }                           
//                   return "El valor ingresado solo puede tener hasta 2 decimales"; 
//               }                            
//           }                
//       }
//   }  
// }
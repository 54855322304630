import React, {useState} from 'react';
import { NumberInput, ReferenceInput, ArrayInput, SimpleFormIterator, AutocompleteInput,
        SelectInput, FormDataConsumer,  DateInput, ImageInput, ImageField,
        TextInput, BooleanInput, maxLength
    } from 'react-admin';
import { PRInput } from '../../inputs/PRInput';
import Grid from '@material-ui/core/Grid';
import { Edit } from './../Edit';
import { withStyles } from '@material-ui/core';
import {latMininima, lngMininima,abValidationInicio, abValidationFin, validarUnDecimal, Entero, 
         validarDosDecimales,validarAB, isFechaInvalida
    } from '../validacionesInputs';
import Divider from '@material-ui/core/Divider';
import  {styles}  from './../../EstilosGrid';
import {
    required,
    FormTab,
    
    maxValue
} from 'react-admin';
import { minValue } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import { ButtonCarousel } from './../images/ButtonCarouselPeru';

import TabbedForm from '../TabbedForm'
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';


const defensachoices = 
[
    {id :  '1' , name : 'Terminal de inicio abatida y esviada y Terminal final abatida ' },
    {id :  '2 ', name : 'Terminal de inicio abatida y esviada y Terminal final abatida y esviada ' },
    {id :  '3 ', name : 'Terminal de inicio abatida y esviada y Terminal final cola de pez ' },
    {id :  '4' , name : 'Terminal de inicio abatida y esviada y Terminal final enterrada en talud ' },
    {id :  '5' , name : 'Terminal de inicio abatida y Terminal final abatida ' },
    {id :  '6' , name : 'Terminal de inicio abatida y Terminal final abatida y esviada ' },
    {id :  '7' , name : 'Terminal de inicio abatida y Terminal final cola de pez ' },
    {id :  '8' , name : 'Terminal de inicio abatida y Terminal final enterrada en talud ' },
    {id :  '9' , name : 'Terminal de inicio cola de pez y Terminal final abatida ' },
    {id : '10' , name : 'Terminal de inicio cola de pez y Terminal final abatida y esviada ' },
    {id : '11' , name : 'Terminal de inicio cola de pez y Terminal final cola de pez ' },
    {id : '12' , name : 'Terminal de inicio cola de pez y Terminal final enterrada en talud ' },
    {id : '13' , name : 'Terminal de inicio enterrada en talud y Terminal final abatida ' },
    {id : '14' , name : 'Terminal de inicio enterrada en talud y Terminal final abatida y esviada ' },
    {id : '15' , name : 'Terminal de inicio enterrada en talud y Terminal final cola de pez ' },
    {id : '16' , name : 'Terminal de inicio enterrada en talud y Terminal final enterrada en talud ' },                                                     
]
const optionRendererDefensa = choice => `${choice.name} ${choice.name}`;

export const SeguridadEdit= withStyles(styles)(({ classes, ...props }) => {

const [elemento , setElemento]= useState('');
const ValidarElemento =(value) =>{  
    setElemento(value)
}
  
const [unidads , setUnidad] = useState('');
const [tramos , setTramo]= useState('');
const [segmentos , setSegmento]= useState('');

const ValidarSegmento = (value)  => {             
    setSegmento(value);
}

const ValidarUnidad = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value);
}

const Unidades = { unidadFuncional: unidads };

const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value);
}
const Tramoss = { tramo: tramos };

/*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray , setLatitudArray]= useState('');
    const LatitudMinimaArray =(value) =>{           
        setLatitudArray(value) 
    }
    const [longitudMinimaArray , setLongitudArray]= useState('');
    const LongitudMinimaArray =(value) =>{           
        setLongitudArray(value) 
    }
    const [longitudMaximaArray , setLongitudMaxdArray]= useState('');
    const LongitudMaximaArray =(value) =>{           
        setLongitudMaxdArray(value) 
    }
    const [latitudMaximaArray , setLatitudMaxdArray]= useState('');
    const LatitudMaximaArray =(value) =>{           
        setLatitudMaxdArray(value) 
    }
/*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const [estados, setEstado] = useState('');
    const ValidarEstado =(value) =>{                
        setEstado(value)
        
    }
/*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }
    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = (value) =>{
        if(value !== '/tipo_elementos/CAPT' && value !== '/tipo_elementos/TPVH' && value !== '/tipo_elementos/CERC' && value !== '/tipo_elementos/BORD' ){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }else if(value === '/tipo_elementos/CERC'){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },            
            ])
        }else{
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }       
    }

  return(
    <Edit  {...props} title="Editar Sistemas de Contención Vehicular" undoable={false}>                   
       <TabbedForm redirect="list" >
            <FormTab label="Identificación">
            <Grid container spacing={24}>
             
                    <CoordenadasProyecto/>
               
                <Grid item xs={6}>
                    <ReferenceInput 
                        label="Elemento" 
                        disabled 
                        source="tipoElemento" 
                        reference="tipo_elementos" 
                        validate={[required(), ValidarElemento, OpcionTipoImagen]} 
                        filter={{sistema: 'SEG'}} 
                        fullWidth 
                        sort={{ 
                            field: 'nombre', 
                            order: 'ASC'
                        }} 
                    >                               
                        <SelectInput  optionText="nombre"   />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput fullWidth label="Margen" source="margen" validate={[required()]}  choices={[
                        {id : 'Derecha' , name : 'Derecha'},
                        {id : 'Izquierda' , name : 'Izquierda'},
                        {id : 'Centro' , name : 'Centro'},
                       ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{marginBottom: 0}}>Localización</h3>                    
                    <hr></hr>
                </Grid>        
                        <Grid item xs={4}>
                            <ReferenceInput 
                                label="Unidad Funcional" 
                                source="unidadFuncional"  
                                reference="unidad_funcionals" 
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{field : 'codigo', order : 'asc'}}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                disabled
                            >
                                <AutocompleteInput options={{fullWidth : true, disabled: true}}  optionText="fullCodigos"/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>     
                            <ReferenceInput 
                                label="Tramo" 
                                source="tramo" 
                                reference="tramos" 
                                filter={Unidades} 
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{ field: 'codigo', order: 'ASC' ,}}
                                validate={[required(), ValidarTramo]}
                                formClassName={classes.grid_cont4}
                                disabled
                            >
                                <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="fullCodigos" />
                            </ReferenceInput>                           
                        </Grid>   
                        <Grid item xs={4}> 
                            <ReferenceInput 
                                label="Segmento" 
                                source="segmento" 
                                reference="segmentos" 
                                sort={{ field: 'codigo', order: 'ASC' }}
                                filter={Tramoss}  
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                validate={[required(), ValidarSegmento]}
                                formClassName={classes.grid_cont4}
                            >
                            
                                <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="fullCodigos" disabled />
                            </ReferenceInput>
                        </Grid>
                         
                    </Grid> 
                {segmentos &&
                        <AbsSegmento segmento={segmentos}/>
                }
                {segmentos &&                
                   <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas inicales</Typography>
                            <Divider fullWidth style={{marginTop : '10px'}} />
                        </Grid>
                    </Grid>              
                }           
                {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio,required(), validarAB]}  formClassName={classes.grid_cont4s}/>}
                {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial"  fullWidth validate={[required(), latMininima,LatitudMinimaArray ]} formClassName={classes.grid_cont4}/>}     
                {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial"  fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4}/>}
                {segmentos &&           
                    <Grid container spacing={24}>     
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                            <Divider fullWidth style={{marginTop : '10px'}} />
                        </Grid>    
                    </Grid>            
                } 
                {/* validarLogicaAbsFinEdit */}
                {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin,required(), validarAB]} formClassName={classes.grid_cont4s}/>}
                {segmentos && <NumberInput label="Latitud" source="latitudFinal"  fullWidth validate={[required(), LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont4}/>}     
                {segmentos && <NumberInput label="Longitud" source="longitudFinal"  fullWidth validate={[required(), lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4}/>}
                {segmentos &&                                              
                    <ArrayInput label="" source="ubicaciones"  className={classes.ocultar}
                        defaultValue={[
                            {
                                lat: latitudMinimaArray,
                                lng: longitudMinimaArray
                            },
                            {
                                lat: latitudMaximaArray,
                                lng: longitudMaximaArray
                            }
                        ]}
                    >
                        <SimpleFormIterator disableAdd disableRemove >                            
                            <NumberInput label="Latitud" source="lat" fullWidth />
                            <NumberInput label="Longitud" source="lng" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                }
                        
            </FormTab>
          
            <FormTab label="Características">
                
                <FormDataConsumer>
                  {({ formData, ...rest }) => (  formData !== null && formData.tipoElemento === "/tipo_elementos/BRRC"  &&
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                           {id : 'Concreto ' , name : 'Concreto '},
                           {id : 'Malla ' , name : 'Malla '},
                           {id : 'Metalica ' , name : 'Metálica '},
                           {id : 'Plastica ' , name : 'Plástica '},
                           {id : 'Polisombra ' , name : 'Polisombra '},
                           {id : 'Prefabricado ' , name : 'Prefabricado '},                           
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                            <NumberInput label="Cantidad de elementos" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                            <NumberInput label="No. Captafaros" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                          <NumberInput label="Largo (m)" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {({ formData, ...rest }) => (  formData !== null && formData.tipoElemento === "/tipo_elementos/BRRF"  &&
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                           {id : 'Concreto ' , name : 'Concreto '},
                           {id : 'Malla ' , name : 'Malla '},
                           {id : 'Metalica ' , name : 'Metálica '},
                           {id : 'Plastica ' , name : 'Plástica '},
                           {id : 'Polisombra ' , name : 'Polisombra '},
                           {id : 'Prefabricado ' , name : 'Prefabricado '},
                           
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="Cantidad de elementos" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="No. Captafaros" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                          <NumberInput label="Largo (m)" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>                
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !== null && formData.tipoElemento === "/tipo_elementos/BRRJ"  &&
                    <Grid container spacing={24}>
                           
                         
                        
                    <Grid item xs={4}>
                    <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                       {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                       {id : 'Concreto ' , name : 'Concreto '},
                       {id : 'Malla ' , name : 'Malla '},
                       {id : 'Metalica ' , name : 'Metálica '},
                       {id : 'Plastica ' , name : 'Plástica '},
                       {id : 'Polisombra ' , name : 'Polisombra '},
                       {id : 'Prefabricado ' , name : 'Prefabricado '},
                       
                   ]}/>
                    </Grid> 
                    <Grid item xs={4}>
                    <NumberInput label="Cantidad de elementos" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                    </Grid> 
                    <Grid item xs={4}>
                    <NumberInput label="No. Captafaros" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                    </Grid> 
                    <Grid item xs={4}>
                      <NumberInput label="Largo (m)" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                    </Grid>  
                    <Grid item xs={4}>
                      <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                    </Grid>  
                    <Grid item xs={4}>
                      <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                    </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/BORD"  &&
                    <Grid container spacing={24}>

                        <Grid item xs={4}>
                        <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                           {id : 'Concreto ' , name : 'Concreto '},
                           {id : 'Malla ' , name : 'Malla '},
                           {id : 'Metalica ' , name : 'Metálica '},
                           {id : 'Plastica ' , name : 'Plástica '},
                           {id : 'Polisombra ' , name : 'Polisombra '},
                           {id : 'Prefabricado ' , name : 'Prefabricado '},
                           
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="Cantidad de elementos" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                          <NumberInput label="Largo (m)" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                 
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/CERC"  &&
                    <Grid container spacing={24}>

                        <Grid item xs={4}>
                        <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                           {id : 'Concreto ' , name : 'Concreto '},
                           {id : 'Malla ' , name : 'Malla '},
                           {id : 'Metalica ' , name : 'Metálica '},
                           {id : 'Plastica ' , name : 'Plástica '},
                           {id : 'Polisombra ' , name : 'Polisombra '},
                           {id : 'Prefabricado ' , name : 'Prefabricado '},
                           
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="Cantidad de elementos" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
 
                        <Grid item xs={4}>
                          <NumberInput label="Largo (m)" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>      
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/CAPT"  &&
                    <Grid container spacing={24}>                            
                        <Grid item xs={6}>
                            <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                                {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                                {id : 'Concreto ' , name : 'Concreto '},
                                {id : 'Malla ' , name : 'Malla '},
                                {id : 'Metalica ' , name : 'Metálica '},
                                {id : 'Plastica ' , name : 'Plástica '},
                                {id : 'Polisombra ' , name : 'Polisombra '},
                                {id : 'Prefabricado ' , name : 'Prefabricado '},
                            
                            ]}/>
                        </Grid> 
                        <Grid item xs={6}>
                            <NumberInput label="No. Captafaros" source="captafaros" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput label="Largo (m)" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/DEFMT"  &&
                    <Grid container spacing={24}>                           
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                           {id : 'Concreto ' , name : 'Concreto '},
                           {id : 'Malla ' , name : 'Malla '},
                           {id : 'Metalica ' , name : 'Metálica '},
                           {id : 'Plastica ' , name : 'Plástica '},
                           {id : 'Polisombra ' , name : 'Polisombra '},
                           {id : 'Prefabricado ' , name : 'Prefabricado '},
                           
                       ]}/>
                       </Grid>
                       <Grid item xs={4}>                       
                       <SelectInput fullWidth label="Tipo de perfil" source="perfil" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Perfil en C' , name : 'Perfil en C'},
                           {id : 'Perfil en U' , name : 'Perfil en U'},
                           {id : 'Perfil W - doble onda' , name : 'Perfil W - doble onda'},
                           {id : 'Simple' , name : 'Simple'},                           
                       ]}/>
                        </Grid>  
                        <Grid item xs={4}>   
                            <SelectInput 
                                fullWidth 
                                label="Tipo de defensa" 
                                source="defensa" 
                                choices={defensachoices} 
                                optionText={optionRendererDefensa} 
                                allowEmpty
                                emptyValue="" 
                                validate={[required()]}  
                            />
                        </Grid>  
                        <Grid item xs={4}>
                        <NumberInput label="No. Postes" source="postes" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="No. Captafaros" source="captafaros" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="No. Unidades" source="unidades" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="No. Terminales" source="terminales" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="No. Tornillos" source="tornillos" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="Largo (m)" source="largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/PRTP"  &&
                    <Grid container spacing={24}>                           
                        <Grid item xs={6}>
                        <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                           {id : 'Concreto ' , name : 'Concreto '},
                           {id : 'Malla ' , name : 'Malla '},
                           {id : 'Metalica ' , name : 'Metálica '},
                           {id : 'Plastica ' , name : 'Plástica '},
                           {id : 'Polisombra ' , name : 'Polisombra '},
                           {id : 'Prefabricado ' , name : 'Prefabricado '},
                           
                       ]}/>
                       </Grid>
                       <Grid item xs={6}>                       
                       <SelectInput fullWidth label="Tipo de perfil" source="perfil" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Perfil en C' , name : 'Perfil en C'},
                           {id : 'Perfil en U' , name : 'Perfil en U'},
                           {id : 'Perfil W - doble onda' , name : 'Perfil W - doble onda'},
                           {id : 'Simple' , name : 'Simple'},                           
                       ]}/>
                        </Grid>  

                        <Grid item xs={4}>
                          <NumberInput label="Largo (m)" source="ca_largo" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="ca_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    </Grid>
                    <Grid item xs={4}>
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="ca_altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="No. Captafaros" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={6}>
                          <NumberInput label="Calibre (m)" source="calibre_mm" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>  
                        {/*NOTA: en el excel, en el item Pertiles de Puentes, en caracteristicas, 
                            solo se envian largo y no. captafaros*/}
                        {/* <Grid item xs={4}>
                          <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>    */}
       
                    </Grid>
                    )}
                  </FormDataConsumer>
                  
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/TPVH"  &&
                    <Grid container spacing={24}>                           
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           {id : 'Barrera jersey plastica ' , name : 'Barrera jersey plástica '},
                           {id : 'Concreto ' , name : 'Concreto '},
                           {id : 'Malla ' , name : 'Malla '},
                           {id : 'Metalica ' , name : 'Metálica '},
                           {id : 'Plastica ' , name : 'Plástica '},
                           {id : 'Polisombra ' , name : 'Polisombra '},
                           {id : 'Prefabricado ' , name : 'Prefabricado '},
                           
                       ]}/>
                       </Grid>
                       <Grid item xs={4}>                       
                            <SelectInput fullWidth label="Tipo de tope" source="tope" allowEmpty emptyValue="" validate={[required()]}  choices={[
                                {id : 'Horizontal' , name : 'Horizontal'},
                                {id : 'Vertical' , name : 'Vertical'},                    
                            ]}/>
                        </Grid>  
                        <Grid item xs={4}>
                            <SelectInput fullWidth label="Sección" source="seccion" allowEmpty emptyValue="" validate={[required()]}  choices={[
                                {id : 'Circular' , name : 'Circular'},
                                {id : 'Rectangular' , name : 'Rectangular'},                    
                             ]}/> 
                        </Grid>  
                        <Grid item xs={6}>
                            <NumberInput label="Diámetro/Base (m)" source="diametro" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarDosDecimales]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarDosDecimales]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="Cantidad de topes" source="topes" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="Longitud del tramo (m)" source="tramos" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>
                    </Grid>
                    )}
                  </FormDataConsumer>
            </FormTab>
            <FormTab label="Detalles" >
                <Grid container spacing={24}>                       
                    <Grid item xs={4}>
                        <SelectInput label="Condicion del elemento" source="condicionElemento" choices={[ 
                            { id: "Existente", name: 'Existente' },
                            { id: "Reemplazo", name: 'Reemplazo' },
                            { id: "Nuevo", name: 'Nuevo' },   
                        ]}  fullWidth validate={[required(), ValidarEstado]} 
                        />
                    </Grid>     
                    <Grid item xs={4}>
                        {estados === "Reemplazo" &&
                                <SelectInput fullWidth source="motivoReemplazo" allowEmpty emptyValue="" fullWidth choices={[
                                { id: 'Accidente', name: 'Accidente' },
                                { id: 'Instalacion' , name : 'Instalación'}, 
                                { id: 'Reemplazo programado', name: 'Reemplazo programado' },
                                { id: 'Vandalismo', name: 'Vandalismo' },
                                { id: 'Otro', name: 'Otro' }
                            ]} />

                        }                        

                    </Grid>   
                    <Grid item xs={4}>
                        {estados === "Reemplazo" && 
                            // <ElementosCreados 
                            //     tabla="seguridads" 
                            //     tipo="editar" 
                            //     elementos={elemento} 
                            //     source="elementoReemplazo_id"
                            //     fullWidth 

                            // />
                            <ReferenceInput
                                label="Elemento que reemplazo"
                                source="elementoReemplazo_id"
                                reference="seguridads"
                                filter={{
                                    tipoElemento : elemento,
                                    status : 1,
                                    unidadFuncional: unidads,
                                    tramo: tramos,
                                    segmento : segmentos
                                }}
                                filterToQuery={searchText => ({ fullCodigo: searchText })} 
                                fullWidth
                                formClassName={classes.grid_cont4}
                            >
                                <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigo" />
                            </ReferenceInput>
                        }
                    </Grid> 
                </Grid>
                <SelectInput label="Estado" source="estado" choices={[
                                { id: "Activo", name: 'Activo' },
                                { id: "Inactivo", name: 'Inactivo' },
                            ]} fullWidth validate={[required()]} 
                            formClassName={classes.grid_cont4_f}
                            />
                            <br/>
                <Grid container spacing={24}>       
                    <Grid item xs={3}>
                        <DateInput 
                            label="Fecha de instalación" 
                            source="fechaInstalacion" 
                            fullWidth 
                            validate={[required(), isFechaInvalida]}/>        
                    </Grid>
                    <Grid item xs={6}>
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => ( 
                                <ReferenceInput 
                                    label="Proveedor" 
                                    source="proveedor" 
                                    reference="proveedors" 
                                    filter={{ 
                                        proyecto: props.proyecto, 
                                        suministros: 1, sistema: 'SEG' 
                                    }}
                                    sort={{ field: 'razonSocial', order: 'ASC' }}
                                    filterToQuery={searchText => ({ razonSocial: searchText })} 
                                    validate={[required()]}
                                >
                                    <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                                </ReferenceInput>
                            )
                        }
                        </FormDataConsumer>           
                    </Grid>    
                    <Grid item xs={3}>
                        <FormDataConsumer>
                            {
                            ({ formData, ...rest }) => (  
                                <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required() , Entero , minValue(0)]}/>
                                )
                            }
                        </FormDataConsumer> 
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <TextInput source="observaciones" fullWidth validate={[required(), maxLength(200, "Máximo 200 caracteres")]}/>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="FOTOGRAFÍAS">
                {/* <ImageInput multiple={true} style={{display :'none'}} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                    <ImageField source="src" title="title" />
                </ImageInput> */}
                <Grid container spacing={24}>                         
                    <Grid item xs={8}>  
                        <ArrayInput label="" source="imagess" defaultValue={defaultTipoImagen} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>                           
                                <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                    <SelectInput  optionText="nombre"  />
                                </ReferenceInput>                          
                            </SimpleFormIterator>
                        </ArrayInput>                            
                    </Grid>     
                    <Grid item xs={4}>  
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                <ButtonCarousel record={formData} {...rest} />                                
                            }
                        </FormDataConsumer>
                    </Grid>                                                    
                </Grid>                   
            </FormTab>
      </TabbedForm>
    </Edit>
)});
import React, { Fragment } from 'react';
import { List, Datagrid,SelectField, TextField, EditButton, ShowButton, Filter,  SelectInput} from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import EditButtonPims from '../../../EditButton'
const choises2 = [
    { id: '1', name: 'Adoquín en arcilla' },
    { id: '2', name: 'Adoquines de concreto' },
    { id: '3', name: 'Afirmado' },
    { id: '4', name: 'Base asfáltica' },
    { id: '5', name: 'Base de concreto hidráulico' },
    { id: '6', name: 'Base estabilizada - otros' },
    { id: '7', name: 'Base estabilizada con asfalto espumado' },
    { id: '8', name: 'Base estabilizada con emulsión asfáltica' },
    { id: '9', name: 'Base granular' },
    { id: '10', name: 'Base tratada con cemento' },
    { id: '11', name: 'Concreto hidráulico' },
    { id: '12', name: 'Lechada ásfaltica' },
    { id: '13', name: 'Mezcla abierta en caliente' },
    { id: '14', name: 'Mezcla abierta en frío' },
    { id: '15', name: 'Mezcla asfáltica modificada con grano de caucho' },
    { id: '16', name: 'Mezcla asfáltica modificada con polímeros' },
    { id: '17', name: 'Mezcla densa en caliente' },
    { id: '18', name: 'Mezcla densa en frío' },
    { id: '19', name: 'Mezcla drenante' },
    { id: '20', name: 'Microaglomerado en caliente' },
    { id: '21', name: 'Sub base asfalto espumado' },
    { id: '22', name: 'Sub-base estabilizada - otros' },
    { id: '23', name: 'Sub base estabilizada con asfalto' },
    { id: '24', name: 'Sub base estabilizada con cemento' },
    { id: '25', name: 'Sub-base granular' },
    { id: '26', name: 'Subrazante estabilizada con cal' },
    { id: '27', name: 'Subrazante estabilizada con cemento' },
    { id: '28', name: 'Subrazante estabilizada con geomalla' },
    { id: '29', name: 'Subrazante estabilizada con geotextil' },
    { id: '30', name: 'Subrazante fina' },
    { id: '31', name: 'Subrazante granular' },
    { id: '32', name: 'Suelo cemento' },
];

// const FiltrosElementos = (props) => (
//     <Filter {...props}>
//         <SelectInput source="tipo" label="Tipo de Material"choices={choises2} alwaysOn/>
//     </Filter>
// );

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const MaterialesList = props => (
    <List {...props} sort={{ field:"updatedAt", order: 'DESC' }} title="Lista Materiales" bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>    
            <TextField source="material" label="Código de Material" />
            <TextField source="especificacion" label="Especificación" />
            <SelectField source="tipo" label="Tipo de Material" choices={choises2} optionText="name"/>
            <ShowButton />
            <EditButton/>
        </Datagrid>
    </List>
);
import React, {useState} from 'react';
import { ReferenceInput,ArrayInput,SimpleFormIterator,DateInput, AutocompleteInput ,BooleanInput,TabbedForm,FormTab, TextInput, NumberInput, SelectInput} from 'react-admin';
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { abValidationInicio,validarUnDecimal,validarLogicaPrFinEdit,abValidationFin,validateDecima, validarDecimales,validarAB, validarDosDecimales, validarPorcentaje } from '../../../../src/resources/validacionesInputs';
import { DInput } from '../../../inputs/DInput';
import { withStyles } from '@material-ui/core';
import { PRInput } from './../../../inputs/PRInput';
import AbsSegmento from '../../AbsSegmento';
import { latMininima, lngMininima,Entero } from '../../validacionesInputs';
import {PercentageInput} from '../../../inputs/PercentageInput';
import Tasas from './views/tasas';
import {
  required,
  minLength,
  maxLength,
  minValue,
  FormDataConsumer,
  maxValue
} from 'react-admin';

// 



export const EstacionInventarioEdit = withStyles(styles)(({ classes, ...props }) => {
    
const styles = {    
    ocultar: { display: "none" },
  
  };
  const abValidationInicio1 =() =>{
    
 /*VALORES ABS INICIO*/
 let AbInicioBase = document.getElementById('IdAbInicio');
 if(AbInicioBase)
 {
    
     AbInicioBase = AbInicioBase.value
     let ingresoAbInicio = document.getElementById('ingresoAbInicio1').value;
     let separadorInicio = ingresoAbInicio.split("+");
     let separadorInicio1 = separadorInicio[0].split("K");
     let seAbInicioBase = AbInicioBase.split(".");
     let AbFinBase = document.getElementById('IdAbFin').value;
     let seAbFinBase = AbFinBase.split(".");
  
  /*VALIDACIONES PR INICIO*/
  if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
  {
      
      return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
  }
  if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
  {
      if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))
  
      return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
  }
  if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
  {
      
      if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))
  
      return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
  }
}
  }
  const abValidationInicio2 =() =>{
    
   /*VALORES ABS INICIO*/
 let AbInicioBase = document.getElementById('IdAbInicio');
 if(AbInicioBase)
 {
    
     AbInicioBase = AbInicioBase.value
     let ingresoAbInicio = document.getElementById('ingresoAbInicio2').value;
     let separadorInicio = ingresoAbInicio.split("+");
     let separadorInicio1 = separadorInicio[0].split("K");
     let seAbInicioBase = AbInicioBase.split(".");
     let AbFinBase = document.getElementById('IdAbFin').value;
     let seAbFinBase = AbFinBase.split(".");
  
  
  /*VALIDACIONES PR INICIO*/
  if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
  {
      
      return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
  }
  if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
  {
      if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))
  
      return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
  }
  if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
  {
      
      if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))
  
      return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
  }
}
  }
  const validarAB =() => {
    var ingresoAbInicio = document.getElementById("ingresoAbInicio1");
    var ingresoAbFin = document.getElementById("ingresoAbInicio2");
    if (ingresoAbInicio && ingresoAbFin) {

        let Abinc = ingresoAbInicio.value.split("+");
        Abinc[0] = Abinc[0].replace("K", "");
        ingresoAbInicio = Abinc[0] + "." + Abinc[1];

        let Abfin = ingresoAbFin.value.split("+");
        Abfin[0] = Abfin[0].replace("K", "");
        ingresoAbFin = Abfin[0] + "." + Abfin[1];

        if (ingresoAbInicio > ingresoAbFin) {
            return "La Abscisa Inicial no puede ser mayor que la final";
        }
    }
}
  
const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  const [elemento, setElemento] = useState('');
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');
  const ValidarSegmento = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(value)
  }
  /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [estados, setEstado] = useState('');
  const ValidarEstado = (value) => {

   // let lenghtObject = Object.keys(value);
    // let valor = '';

   // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setEstado(value)

  }
  //const Unidades = { unidadFuncional: unidads };
  
  const [unidads, setUnidad] = useState('');
  const ValidarUnidad = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [segmentos, setSegmento] = useState('');
  const validarfecha=(value)=>{
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();
     if(dd<10){
            dd='0'+dd
        } 
        if(mm<10){
            mm='0'+mm
        } 
    
    today = yyyy+'-'+mm+'-'+dd;
    if(document.getElementById("datefield")){
        document.getElementById("datefield").setAttribute("max", today);
    }
    }
const validarfechaact=(value)=>{
let fecha=new Date(value+"T00:00:00");
let fechaact=new Date();
if(fecha>fechaact){
    return "La fecha no puede ser mayor a la actual"
}
}
const [years1, setYears1] = useState('');
const validarAño1 = (value) => {
   
   
    setYears1(value)


}
const [years2, setYears2] = useState('');
const validarAño2 = (value) => {
  if(years1 > value){
    return "La fecha inicial debe ser anterior a la final."
}
// else if (years1 === value){
//   return "La fecha incial no deber ser a la final."
// }
    setYears2(value)


}
const validateMinimo = (valor) => {

  var n = parseInt(valor);
  if (n <= 100) {
    console.log(n)
    if (n >= -100) {
    } else {
      return "El número ingresado debe ser mayor a -100"
    }

  } else {
    return "El número ingresado debe ser menor a 100"
  }
}
const choices=[
  { id: '2000', name: '2000' },
  { id: '2001', name: '2001' },
  { id: '2002', name: '2002' },
  { id: '2003', name: '2003' },
  { id: '2004', name: '2004' },
  { id: '2005', name: '2005' },
  { id: '2006', name: '2006' },
  { id: '2007', name: '2007' },
  { id: '2008', name: '2008' },
  { id: '2009', name: '2009' },
  { id: '2010', name: '2010' },
  { id: '2011', name: '2011' },
  { id: '2012', name: '2012' },
  { id: '2013', name: '2013' },
  { id: '2014', name: '2014' },
  { id: '2015', name: '2015' },
  { id: '2016', name: '2016' },
  { id: '2017', name: '2017' },
  { id: '2018', name: '2018' },
  { id: '2019', name: '2019' },
  { id: '2020', name: '2020' },
  { id: '2021', name: '2021' },
  { id: '2022', name: '2022' },
  { id: '2023', name: '2023' },
  { id: '2024', name: '2024' },
  { id: '2025', name: '2025' },
  { id: '2026', name: '2026' },
  { id: '2027', name: '2027' },
  { id: '2028', name: '2028' },
  { id: '2029', name: '2029' },
  { id: '2030', name: '2030' }
]
const [totaliza, setTotaliza] = useState('');
const sumar = (valor)=> {
  let val1 = 0;
  let val2 = 0;
  let val3 = 0;
  let val4 = 0;
  let val5 = 0;
  let val6 = 0;
  val1 = document.getElementById('2').value;
  val2 = document.getElementById('3').value;
  val3 = document.getElementById('4').value;
  val4 = document.getElementById('5').value;
  val5 = document.getElementById('6').value;
  val6 = document.getElementById('7').value;
  
  if (val1 != null && valor != null && val2 != null && val3 != null && val4 != null && val5 != null && val6 != null) {
  }
  
    setTotaliza(parseInt(val1) + parseInt(val2) + parseInt(valor) + parseInt(val3) + parseInt(val4) + parseInt(val5) + parseInt(val6)) ;
    console.log('spTotal');
    
  
}
    return(
<Edit title="Editar Estación de conteo" {...props}>
<TabbedForm  redirect="list">
            <FormTab label="Identificación">
                <Grid container spacing={24}>
                  <Grid item xs={4}>
                        <TextInput source="nombre" fullWidth/>
                    </Grid>
                       {/* ------- LOCALIZACIÓN --------- */}
            <Grid item xs={12}>
              <h3 style={{ marginBottom: 0 }}>Localización</h3>
              <hr></hr>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="Unidad Funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
            </Grid>

            {unidads &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="Tramo"
                  source="tramo"
                  alwaysOn
                  reference="tramos"
                  filter={{ unidadFuncional: unidads }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  sort={{ field: 'codigo', order: 'ASC', }}
                  validate={[required(), ValidarTramo]}
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
              </Grid>
            }
            {tramos &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="Segmento"
                  source="segmento"
                  reference="segmentos"
                  sort={{ field: 'codigo', order: 'ASC' }}
                  filter={{ tramo: tramos }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  validate={[required(),ValidarSegmento]}
                  formClassName={classes.grid_cont4}
                >

                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                {/* <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbinicio" id="IdAbInicio" />
                </ReferenceInput>
                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbfin" id="IdAbFin" />
                </ReferenceInput> */}
              </Grid>
            }

          </Grid>
          {segmentos && <AbsSegmento segmento={segmentos} />}
          {segmentos &&
            <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />
            </Grid>
          </Grid>
          }
          {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4s} />}
          {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos &&
           
          
            <ArrayInput label="" source="ubicaciones" style={{
              display:
                "none"
            }}
              defaultValue={[
                {
                  lat: latitudMinimaArray,
                  lng: longitudMinimaArray
                },
                {
                  lat: latitudMaximaArray,
                  lng: longitudMaximaArray
                }
              ]}
            >
              <SimpleFormIterator disableAdd disableRemove >
                <NumberInput label="Latitud" source="lat" fullWidth />
                <DInput label="Longitud" source="lng" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          }
</FormTab>
<FormTab label="Ejes equivalentes">
            <NumberInput label="Factor direccional" source="direccional" validate={[required(),validarDosDecimales, minValue(0), maxValue(1)]} formClassName={classes.grid_cont4}/>
            <NumberInput label="Factor carril" source="carril" validate={[required(),validarDosDecimales, minValue(0), maxValue(1)]} formClassName={classes.grid_cont4}/>
            <SelectInput id="ano_id" label="Año" source="years"  choices={choices} fullWidth validate={required()} formClassName={classes.grid_cont3}/>
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '20px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Categoría</strong> 
            <span style={{margin: '0px -10px'}}>
            </span>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Volumen</strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
             <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Factor</strong>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '300px', marginBottom: '15px'}}>Autos</strong> 
            <NumberInput label=" " source="aut1" validate={[required(),Entero, minValue(0),sumar]} id="1" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " source="aut2" validate={[required(),validarUnDecimal,minValue(0)]}  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '300px', marginBottom: '15px'}}>Buses</strong> 
            <NumberInput label=" " source="bus1" validate={[required(),Entero ,minValue(0)]} id="2" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " source="bus2" validate={[required(),validarUnDecimal,minValue(0)]}  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>C<sub style={{fontSize: 'smaller'}}>2</sub>P</strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]}  source="c1volumen" id="3" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="c2factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>C<sub style={{fontSize: 'smaller'}}>2</sub>G</strong> 
            <NumberInput label=" " validate={[required(), Entero,minValue(0)]} id="4" source="cgvolumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="cgfactor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '310px', marginBottom: '15px'}}>C<sub style={{fontSize: 'smaller'}}>3</sub>yC<sub style={{fontSize: 'smaller'}}>4</sub></strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]} id="5"  source="c3volumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="c3factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '330px', marginBottom: '15px'}}>C<sub style={{fontSize: 'smaller'}}>5</sub></strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]} id="6"  source="c5volumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="c5factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>>C<sub style={{fontSize: 'smaller'}}>5</sub></strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]} id="7"  source="mayorc5volumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="mayorc5factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 190px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>TPD</strong> 
            <NumberInput label=" " disabled source="tpd"  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '200px', marginBottom: '15px'}}/>
             </div>
             <FormDataConsumer>
             {({ formData, ...rest }) => {formData.tpd=formData.aut1 + formData.bus1 + formData.c1volumen + formData.c3volumen + formData.c5volumen + formData.cgvolumen + formData.mayorc5volumen }
            }
             </FormDataConsumer>
            </FormTab>
            <FormTab label="Tasas de crecimiento">
              <NumberInput label="ESAL (Proyección 5 años)" source="esal1"  validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont4}/>
              <NumberInput label="ESAL (Proyección 10 años)" source="esal2" validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont4}/>
              <NumberInput label="ESAL (Proyección 20 años)" source="esal3" validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont3}/>
              <NumberInput label="TPD actual" source="tpdactual" validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont4}/>
              <PercentageInput label="Tasa de crecimiento" source="tasacrecimiento" validate={[required(),validateMinimo]} className="align-number" fullWidth formClassName={classes.grid_cont4}/>
              <br/>
            <SelectInput id="ano_id" label="Desde" source="years1" validate={[validarAño1]} choices={choices} fullWidth formClassName={classes.grid_cont3}/>
            <SelectInput id="ano_id" label="Hasta" source="years2" validate={[validarAño2]} choices={choices} fullWidth formClassName={classes.grid_cont3}/>
            
            {years1 && years2 &&
                    <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <Tasas record={record} years1={years1}  years2={years2} tipo="tipo"/> }
                </WithProps> 
}
            </FormTab>
      </TabbedForm>
        </Edit>
    
)});
const WithProps = ({ children, ...props }) => children(props);
import React  from 'react';
import { TextInput, SelectInput,ReferenceInput,AutocompleteInput,NumberInput,minValue,maxValue,BooleanInput } from 'react-admin';
import { withStyles, Grid } from '@material-ui/core';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';
import { styles } from './../../EstilosGrid';
import { required, maxLength } from 'react-admin';
import { abValidationInicio,Entero,validarUnDecimal,validateDecima,validarDecimales,validarAB, abValidationFin, validarDosDecimales, validarPorcentaje, validarDecimales2 } from '../validacionesInputs';
import { useState } from 'react';


export const TrayectoriasCreate = withStyles(styles)(({ classes, ...props }) => {
    const styles = {
        first_field: { width: '100%' }    
    };
    const validatePrecio = (valor) => {
        let precio1 = 0;
        let precio2 = 0;
        precio1 =  document.getElementById('12').value;
        precio2 =  document.getElementById('13').value;
        if(parseInt(precio1) === parseInt(precio2) ){
          return "El precio total no puede ser igual al precio carpeta"
        }
        else if(parseInt(precio1) < parseInt(precio2) ){
          return "El precio total debe ser mayor al precio carpeta"
        }
      }
      
    return(
    <PimsCreate {...props} title="Crear Alternativas">
        <SimpleForm redirect="list" >
        <ReferenceInput
                label="Unidad Funcional"
                source="unidad_funcional"
                id="unidad_id"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required()]}
                fullWidth
                formClassName={classes.grid_cont4}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
              <NumberInput source="trafico" label="Limite de tránsito" fullWidth validate={[minValue(0) ,Entero,maxValue(1000000)]} formClassName={classes.grid_cont4}/>
                    <TextInput label="Nombre de la alternativa" source="nombre" fullWidth validate={[maxLength(30)]} formClassName={classes.grid_cont3}/>
                    <NumberInput source="precio" id="12" label="Precio total ($/m²)" fullWidth validate={[minValue(0) ,Entero]} formClassName={classes.grid_cont4}/>  
                    <NumberInput source="preciocar" id="13" label="Precio carpeta ($/m²)" fullWidth validate={[minValue(0), Entero,validatePrecio]} formClassName={classes.grid_cont4}/>  
                    <NumberInput label="Vida esperada (años)" source="vida" fullWidth validate={[minValue(0),Entero ,maxValue(30)]} formClassName={classes.grid_cont3} />
                    <NumberInput label="Espesor removido (mm)" source="espesor" fullWidth validate={[minValue(0),Entero ,maxValue(500)]} formClassName={classes.grid_cont4}/>
                    
                    <div style={{display: 'flex', justifyContent: 'center', margin: '0px 350px', marginTop: '20px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}></strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Espesores</strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
             <strong style={{display: 'flex', alignItems: 'flex', marginRight: '-50px', marginBottom: '15px',width:'1000px'}}>Código material </strong>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa1 (mm)</strong> 
            <NumberInput label=" " source="espesorcap1" validate={[minValue(0),Entero ,maxValue(2000)]} id="1" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>
            <ReferenceInput label=" " source="material" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <SelectInput optionText="material"/>
              </ReferenceInput>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa2 (mm)</strong> 
            <NumberInput label=" " source="espesorcap2" validate={[minValue(0),Entero ,maxValue(2000)]} id="2" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>
            <ReferenceInput label=" " source="material1" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <SelectInput optionText="material"/>
              </ReferenceInput>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%' }}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa3 (mm)</strong> 
            <NumberInput label=" " source="espesorcap3" validate={[minValue(0),Entero ,maxValue(2000)]} id="3" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>
            <ReferenceInput label=" " source="material2" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <SelectInput optionText="material"/>
              </ReferenceInput>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa4 (mm)</strong> 
            <NumberInput label=" " source="espesorcap4" validate={[minValue(0),Entero ,maxValue(2000)]} id="4" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>
            <ReferenceInput label=" " source="material3" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <SelectInput optionText="material"/>
              </ReferenceInput>
                    
                    
            </div>
                    {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
                    <BooleanInput label="Rígido" source="rigido" formClassName={classes.grid_cont4}/>
                    <BooleanInput label="Flexible" source="flexible" formClassName={classes.grid_cont4}/>
                    <div style={{display: 'flex', justifyContent: 'center', margin: '0px 350px', marginTop: '20px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Indicador</strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Mejorar</strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
             <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Limite </strong>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 340px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '400px', marginBottom: '15px'}}>IRI(mm/m)</strong> 
            <NumberInput label=" " source="iri_mejora" validate={[required(),Entero, minValue(0),maxValue(20)]} id="1" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " source="iri_limite" validate={[required(),Entero,minValue(0),maxValue(20) ]}  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 340px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '420px', marginBottom: '15px'}}>Fricción</strong> 
            <NumberInput label=" " source="friccion_mejora" validate={[required(),Entero ,minValue(0),maxValue(100)]} id="2" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " source="friccion_limite" validate={[required(),Entero,minValue(0),maxValue(100)]}  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 340px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '340px', marginBottom: '15px'}}>Ahuellamiento(mm)</strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0),maxValue(100)]}  source="ahuellamiento_mejora" id="3" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),Entero, minValue(0),maxValue(100)]}  source="ahuellamiento_limite" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 340px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '430px', marginBottom: '15px'}}>PCI(%)</strong> 
            <NumberInput label=" " validate={[required(), Entero,minValue(0),maxValue(100)]} id="4" source="pci_mejora" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),Entero, minValue(0),maxValue(100)]}  source="pci_limite" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
                                
        </SimpleForm>
    </PimsCreate>
)});
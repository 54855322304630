import React, {useState} from 'react';
import { CardActions, TabbedForm ,Toolbar, TextInput, ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, FormTab, ArrayField,ChipField,SingleFieldList,NumberField,ListButton,EditButton, FunctionField  } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { GMapField } from './../../../fields/GMapField';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import  {styles}  from './../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import { ButtonCarousel } from './../../images/ButtonCarousel';

export const OtrosElementosShow = withStyles(styles)(({ classes,record, ...props }) => {
    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
    // console.log(value + "Aqui est la imagen ")
    if(value !== null && value !== undefined && value !== 'undefined')
    {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setImagen(value) 
        // console.log(value + "Aqui est la imagen ")
    }
    }


    return(
        <ShowController title="Informacion Otro Elemento" {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Informacion Otro Elemento"  actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="IDENTIFICACIÓN">
                            <TextField label="Ruta" source="fullCodigo" fullWidth formClassName={classes.grid_cont4}/>
                            {/* <NumberField label="Consecutivo" source="fullConsecutivo" fullWidth formClassName={classes.grid_cont4}/> */}
                            <br></br>
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} formClassName={classes.ocultar}/>
                            <ReferenceField label="Elemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                                <TextField source="nombre" />       
                            </ReferenceField>                      
                            <TextField source="margen" fullWidth formClassName={classes.grid_cont4}/>

                            <Typography variant="h3" component="h3">Localización</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                                <TextField source="fullCodigos" />       
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                                <TextField source="fullCodigos" />       
                            </ReferenceField> 
                            <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>    
                                <TextField source="fullCodigos" />       
                            </ReferenceField> 
                            {/* <PField label="PR Inicio" source="pInicio" />
                            <PField label="PR Fin"  source="pFin" /> */}
                            <PRField label="Abscisa Inicio" source="abInicio"fullWidth formClassName={classes.grid_cont4} />
                            <PRField label="Abscisa Fin" source="abFin" fullWidth formClassName={classes.grid_cont4}/>
                            <NumberField label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/> 

                            <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                            <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                            <PRField label="Abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4}/>
                            <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                            <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/> 
                                        
                            <Typography variant="b" component="b">Coordenadas Finales</Typography>
                            <Divider fullWidth style={{width:'300%' , marginTop :'10px'}} /> 
                            <PRField label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth formClassName={classes.grid_cont4}/>
                            <NumberField label="Latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                            <NumberField label="Longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            <GMapField style={{width : "100%"}}
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {imagen} 
                            /> 
                        </FormTab> 
                        <FormTab label="Características">
                            <br/>
                            <TextField source="interseccion" label="Clase de intersección" formClassName={classes.grid_cont4s}/>
                            <TextField source="material" label="Material" formClassName={classes.grid_cont4}/>
                            <TextField source="flujo_peatonal" label="Flujo peatonal" formClassName={classes.grid_cont4}/>
                            <br/>
                            <TextField source="clase_via_intersecta" label="Clase de vía intersecta" formClassName={classes.grid_cont4s}/>
                            <TextField source="tipo_via_intersecta" label="Tipo de vía intersecta" formClassName={classes.grid_cont4}/>
                            <TextField source="reductores_velocidad" label="Reductores de velocidad vía intersecta" formClassName={classes.grid_cont4}/>
                            <br/>
                            {controllerProps.record && controllerProps.record.elemento  === "Intersecciones a desnivel" &&
                                <div className={classes.subtittle}><strong>Características del paso a desnivel </strong></div>
                            }
                            {controllerProps.record && controllerProps.record.elemento  === "Intersecciones a desnivel" &&
                                <Divider fullWidth />
                            }
                            {controllerProps.record && controllerProps.record.elemento  === "Intersecciones a desnivel" &&
                                <TextField label="Largo (m)" source="largo" formClassName={classes.grid_cont4s}/>
                            }
                            {controllerProps.record && controllerProps.record.elemento  === "Intersecciones a desnivel" &&
                                <TextField label="No. Carriles" source="carriles" formClassName={classes.grid_cont4}/>
                            }
                            {controllerProps.record && controllerProps.record.elemento  === "Intersecciones a desnivel" &&
                                <TextField label="Sentido" source="sentido" formClassName={classes.grid_cont4}/> 
                            }
                        </FormTab> 
                        <FormTab label="Detalles">
                            <br></br>
                            <TextField label="Condición del elemento" fullWidth source="estado" fullWidth formClassName={classes.grid_cont4s}/>                          
                            {controllerProps.record && controllerProps.record.estado  === "Reemplazo" &&
                                <TextField label="Motivo de reemplazo" source="motivo" fullWidth formClassName={classes.grid_cont4}/>                          
                            }
                            {controllerProps.record && controllerProps.record.estado  === "Reemplazo" &&
                                <TextField 
                                label="Elemento que Reemplazo"
                                source="fullCodigoElemReem"
                                formClassName={classes.grid_cont3}
                            />
                            }
                            {controllerProps.record && controllerProps.record.estado  !== "Reemplazo" &&
                                <br/>
                            }
                            <br/>
                            <TextField label="Estado" source="estados" fullWidth formClassName={classes.grid_cont4}/>
                            <br/>
                            <DateField  label="Fecha de instalación" source="fecha_instalacion" fullWidth formClassName={classes.grid_cont4s}/>                          
                            <TextField  label="Proveedor" source="proveedor_.razonSocial" fullWidth formClassName={classes.grid_cont4}/>                          
                            <NumberField label="Garantía (meses)" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                            <br/>
                            <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento"  formClassName={classes.grid_cont12}/>
                            <br></br>
                            <TextField  source="observaciones" fullWidth formClassName={classes.grid_cont12}/> 

                            
                        </FormTab> 
                        <FormTab label="Auditoría">
                            <br></br>
                            <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4}/> 
                            <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4}/>
                            <span></span>               
                            <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4}/>
                            <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4}/>
                            <span></span>    
                        </FormTab> 
        
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
)})
import React, { useState } from 'react';
import { FormTab, NumberInput, ReferenceInput, Toolbar, SaveButton, SelectInput, ArrayInput, SimpleFormIterator, 
    DateInput, BooleanInput, TextInput, AutocompleteInput
} from 'react-admin';
import { PimsElementoActions } from './../PimsElementoActions';
import { validarUnDecimal, abValidationInicio, abValidationFin, latMininima, lngMininima, validarLogicaAbsFinCreate,  
    validacionmaxfechaact, isFechaInvalida } from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import {
    required,
    maxLength,
    minValue,
} from 'react-admin';
import TabbedForm from './../../TabbedForm';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import config from '../../../config/config';
import { ImagenesInventario } from '../../images/ImagenesInventario';
let urlAppjs2 = config.UrlFront;

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const tipoElemento = { sistema: 'ALR' };

const LinkCancelar = () => {
    let redirect = "elemento_alrededors"
    return <a href={urlAppjs2 + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
}


const CustomToolbar = ({ basePath, data, ...props }) => (
    <Toolbar {...props} >
        <SaveButton />
        <LinkCancelar />
    </Toolbar>
);

export const ElementoAlrededorCreate = withStyles(styles)(({ classes, ...props }) => {

    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
    const [latMin, setLatMin] = useState('');
    const [lngMin, setLngMin] = useState('');
    const [latMax, setLatMax] = useState('');
    const [lngMax, setLngMax] = useState('');
    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSegmento(value)
        let idSegmento = valor;
        idSegmento = idSegmento.split('/');
    }

    /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/



    //------- VALIDACIONES DE NUMEROS NEGATIVOS -------
    const validarNegativos = (value) => {
        if (value < 0) {
            return "El valor ingresado no puede ser negativo";
        }
    }


    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    const Unidades = { unidadFuncional: unidads };

    const [tramos, setTramo] = useState('');
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }
    const Tramoss = { tramo: tramos };

    const [estados, setEstado] = useState('');

    const [elemento, setElemento] = useState('');
    const [opciones, setOpcion] = useState();
    const ValidarElemento = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor);
        if (valor != "/tipo_elemento/MARG" && valor != "/tipo_elemento/SEPD" && valor != "/tipo_elemento/ZINT") {
            setEstado("");
        }
    }

    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)

    }

    const [obras, setObr] = useState('');

    const ValidarObra = (value) => {
        let valor = value['target']['checked'];
        setObr(valor)
        if (valor == false) {
            setFicha(valor)
            setEstudio(valor)
        }

    }

    const [ficha, setFicha] = useState('');

    const ValidarFicha = (value) => {
        let valor = value['target']['checked'];
        setFicha(valor)
        if (valor == false) {
            setEstudio(valor)
        }

    }
    const [estudio, setEstudio] = useState('');

    const ValidarEstudio = (value) => {
        let valor = value['target']['checked'];
        setEstudio(valor)

    }

    const choices = opciones;
    const optionRenderer = choice => `${choice.name}`;

    const FormatoGarantia=v=>{
        if(v){
            let vaux=v.toString().replace(',', '').replace('.', '')
            return parseInt(vaux)
        }
        else{
        return v;
        }
    }

    
    const [ancho, setAncho] = useState('');
    const largo = (value) => {
      let valAncho = 0;
      valAncho = document.getElementById('23').value;
      if (valAncho != null && value != null) {
      }
      setAncho (parseFloat(value) * parseFloat(valAncho).toFixed(1));
    }


/*OPCIONES PARA TIPO DE IMÁGENES*/
   const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
   const OpcionTipoImagen = (value) =>{       
       if(value === '/tipo_elementos/SEPD'){
           setDefaultTipoImagen([
               {
                   tipoImagen : '/tipo_images/1',
               },
               {
                   tipoImagen : '/tipo_images/7',
               },
               {
                   tipoImagen : '/tipo_images/8',
               },
               {
                   tipoImagen : '/tipo_images/9',
               },
               {
                   tipoImagen : '/tipo_images/13',
               },
               {
                   tipoImagen : '/tipo_images/14',
               }
           ])
       }else if(value === '/tipo_elementos/MARG' || value === '/tipo_elementos/OBST' ){
           setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },            
           ])
       }else if(value === '/tipo_elementos/COMR' || value === '/tipo_elementos/ZINT' ){
           setDefaultTipoImagen([
               {
                   tipoImagen : '/tipo_images/1',
               },
               {
                   tipoImagen : '/tipo_images/7',
               },
               {
                   tipoImagen : '/tipo_images/8',
               },
               {
                   tipoImagen : '/tipo_images/9',
               },
               {
                    tipoImagen : '/tipo_images/13',
                },
                {
                    tipoImagen : '/tipo_images/14',
                }
           ])
       }       
   }
//    const abValidationInicio1 =() =>{
    
//     /*VALORES ABS INICIO*/
//     let AbInicioBase = document.getElementById('IdAbInicio');
//     if(AbInicioBase)
//     {
       
//         AbInicioBase = AbInicioBase.value
//         let ingresoAbInicio = document.getElementById('ingresoAbInicio1').value;
//         let separadorInicio = ingresoAbInicio.split("+");
//         let separadorInicio1 = separadorInicio[0].split("K");
//         let seAbInicioBase = AbInicioBase.split(".");
//         let AbFinBase = document.getElementById('IdAbFin').value;
//         let seAbFinBase = AbFinBase.split(".");
     
//      /*VALIDACIONES PR INICIO*/
//      if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
//      {
         
//          return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
//      }
//      if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
//      {
//          if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))
     
//          return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
//      }
//      if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
//      {
         
//          if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))
     
//          return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
//      }
//    }
//      }
     const validatePrimera =(value) =>{
         console.log(value)
        let AbInicioBase = document.getElementById('IdAbInicio');
            if (AbInicioBase){
        if(typeof value !== 'undefined'){
            if(value.length > 0) {
                AbInicioBase = AbInicioBase.value
                let seAbInicioBase = AbInicioBase.split(".");
                let AbFinBase = document.getElementById('IdAbFin').value;
                let seAbFinBase = AbFinBase.split(".");
                for(let i=0; i<value.length;i++){
                    if(value[i].hasOwnProperty("abInicio"))
                    {
                        let ingresoAbInicio = value[i].abInicio
                        let separadorInicio = ingresoAbInicio.split("+");
                        let separadorInicio1 = separadorInicio[0].split("K");
                     
                     /*VALIDACIONES PR INICIO*/
                     if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
                     {
                         
                         return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
                     }
                     if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
                     {
                         if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))
                     
                         return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
                     }
                     if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
                     {
                         
                         if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))
                     
                         return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
                     }
                   }
                     }
                    }
                    }
                }
            }
    return (
        <PimsCreate  {...props} title="Crear Elemento Alrededor" actions={<PimsElementoActions />}>
            <TabbedForm redirect="list">
                <FormTab label="IDENTIFICACIÓN">
                    <CoordenadasProyecto/>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <ReferenceInput
                                source="tipoElemento"
                                reference="tipo_elementos"
                                validate={[required(), OpcionTipoImagen]}
                                onChange={ValidarElemento}
                                filter={tipoElemento}
                                sort={{ field: 'nombre', order: 'ASC' }}
                                fullWidth
                                formClassName={classes.grid_cont12}
                            >
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput label="Margen" source="margen" allowEmpty emptyValue="" validate={[required()]} choices={[
                                { id: 'Derecha', name: 'Derecha' },
                                { id: 'Izquierda', name: 'Izquierda' },
                                { id: 'Centro', name: 'Centro' },
                            ]} fullWidth formClassName={classes.grid_cont12} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="b" component="b">Localización</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Divider fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="Unidad Funcional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                formClassName={classes.grid_cont4_f}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>

                        {unidads &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="Tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    formClassName={classes.grid_cont4_s}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {tramos &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="Segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    formClassName={classes.grid_cont4_s}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                    </Grid>

                    {segmentos &&
                        <AbsSegmento segmento={segmentos} />
                    }
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas iniciales</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4_f} />}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4_s} />}
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4_s} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarLogicaAbsFinCreate, required()]} formClassName={classes.grid_cont4_f} />}
                    {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[latMininima, LatitudMaximaArray, required()]} formClassName={classes.grid_cont4_s} />}
                    {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[lngMininima, LongitudMaximaArray,  required()]} formClassName={classes.grid_cont4_s} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="Características">
                    {elemento === "/tipo_elementos/COMR" &&
                        <SelectInput label="Tipo de servicio" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: "Centro educativo", name: "Centro educativo" },
                            { id: "Estación de combustible", name: "Estación de combustible" },
                            { id: "Hospital/Centro de salud", name: "Hospital/Centro de salud" },
                            { id: "Hotel", name: "Hotel" },
                            { id: "Montallantas", name: "Montallantas" },
                            { id: "Restaurante", name: "Restaurante" },
                            { id: "Taller automotriz", name: "Taller automotriz" },
                            { id: "Otros", name: "Otros" },

                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <SelectInput label="Tipo de acceso" source="tipoAcceso" allowEmpty emptyValue="" choices={[
                            { id: "Con carril de aceleración/desaceleración", name: "Con carril de aceleración/desaceleración" },
                            { id: "Sin carril", name: "Sin carril" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <SelectInput label="Estacionamiento" source="estacionamiento" allowEmpty emptyValue="" choices={[
                            { id: "En fachada", name: "En fachada" },
                            { id: "En vía", name: "En vía" },
                            { id: "Interno", name: "Interno" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <br/>
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                    
                        <NumberInput 
                            label="Largo del acceso (m)" 
                            source="largo" 
                            id="22"
                            validate={[largo,minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_f} 
                        />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <NumberInput 
                            label="Ancho del acceso (m)" 
                            source="ancho" 
                            id="23"
                            validate={[minValue(0), validarUnDecimal]} 
                            fullWidth formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <NumberInput 
                            label="Área de acceso (m²)" 
                            source="area" 
                            disabled
                            defaultValue={ancho}
                            validate={[minValue(0)]} 
                            fullWidth formClassName={classes.grid_cont4_s} 
                        />
                    }

                    {/* ------ Elemento = Márgenes ------- */}
                    {elemento === "/tipo_elementos/MARG" &&
                        <SelectInput label="Superficie características" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: "Concreto/Adoquín", name: "Concreto/Adoquín" },
                            { id: "No aplica", name: "No aplica" },
                            { id: "Talud", name: "Talud" },
                            { id: "Vegetación", name: "Vegetación" },
                            { id: "Otro", name: "Otro" },

                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont12} />
                    }
                    {elemento === "/tipo_elementos/MARG" &&
                        <NumberInput 
                            label="Largo (m)" 
                            source="largo" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth formClassName={classes.grid_cont4_f} 
                        />
                    }
                    {elemento === "/tipo_elementos/MARG" &&
                        <NumberInput 
                            label="Ancho (m)" 
                            source="ancho" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {elemento === "/tipo_elementos/MARG" &&
                        <NumberInput 
                            label="Área (m²)" 
                            source="area" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth formClassName={classes.grid_cont4_s} 
                        />
                    }

                    {/* ------ Elemento =  Separadores ------- */}
                    {elemento === "/tipo_elementos/SEPD" &&
                        <SelectInput label="Superficie caraterística" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: "Concreto/Adoquín", name: "Concreto/Adoquín" },
                            { id: "No aplica", name: "No aplica" },
                            { id: "Talud", name: "Talud" },
                            { id: "Vegetación", name: "Vegetación" },
                            { id: "Otro", name: "Otro" },

                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont12} />
                    }
                    {elemento === "/tipo_elementos/SEPD" &&
                        <NumberInput 
                            label="Largo (m)" 
                            source="largo" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_f} 
                        />
                    }
                    {elemento === "/tipo_elementos/SEPD" &&
                        <NumberInput 
                            label="Ancho (m)" 
                            source="ancho" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {elemento === "/tipo_elementos/SEPD" &&
                        <NumberInput 
                            label="Área (m²)" 
                            source="area" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                        />
                    }

                    {/* ------ Elemento = Márgenes, Separadores ------- */}
                    {elemento === "/tipo_elementos/OBST" &&
                        <SelectInput label="Tipo" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: "Cercado", name: "Cercado" },
                            { id: "Construcción ", name: "Construcción " },
                            { id: "Otros", name: "Otros" },
                            { id: "Río", name: "Río" },
                            { id: "Zona verde", name: "Zona verde" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont6s} />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput 
                            label="Longitud de afectación (m)" 
                            source="longitudAfectacion" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont6} 
                        />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <br/>
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput 
                            label="Largo (m)" 
                            source="largo" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_f} 
                        />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput 
                            label="Ancho (m)" 
                            source="ancho" 
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput 
                            label="Altura (m)" 
                            source="area" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                            />
                    }

                    {/* ------ Elemento = Zona inestable ------- */}
                    
                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="Tipo de afectación" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: "Caída", name: "Caída" },
                            { id: "Deslizamiento ", name: "Deslizamiento " },
                            { id: "Flujos", name: "Flujos" },
                            { id: "Movimiento complejo", name: "Movimiento complejo" },
                            { id: "Volcamiento", name: "Volcamiento" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="Riesgo" source="riesgo" allowEmpty emptyValue="" choices={[
                            { id: "Baja", name: "Baja" },
                            { id: "Media ", name: "Media " },
                            { id: "Alta", name: "Alta" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="Ubicación" source="seccion" allowEmpty emptyValue="" choices={[
                            { id: "Talud superior", name: "Talud superior" },
                            { id: "Talud inferior ", name: "Talud inferior " },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <br/>
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <NumberInput label="Longitud de calzada afectada (m)" source="longitudAfectacion" validate={[required(), minValue(0), validarUnDecimal]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <br/>
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <Typography variant="h3" component="h3" className={classes.coord}>Tramos de Pendiente Variable</Typography>
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                       <Divider fullWidth style={{ marginTop: '10px' }} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <ArrayInput label=" " fullWidth source="tramosPendientes" validate={[validatePrimera]}>
                            <SimpleFormIterator >
                                <NumberInput label="Tramos de Pendiente Variable" source="tramoPendiente" label="Tramo" fullWidth formClassName={classes.grid_cont4_f} />
                                <PRInput label="Abscisa Inicio" id="ingresoAbFin" source="abInicio" id="ingresoAbInicio1" fullWidth fullWidth formClassName={classes.grid_cont4_s} />
                                <NumberInput source="pendTransversal" label="Pendiente Transversal H:V" fullWidth formClassName={classes.grid_cont4_s} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <BooleanInput label="Obras de estabilización" source="obraEstabilizacion" onChange={ValidarObra} fullWidth formClassName={classes.grid_cont4_s} />

                    }

                    {obras &&
                        <SelectInput label="Tipo de obra" source="tipoObra" allowEmpty emptyValue="" validate={[required()]} choices={[
                            { id: "Anclajes en roca", name: "Anclajes en roca" },
                            { id: "Cercados y redes de contención", name: "Cercados y redes de contención" },
                            { id: "Escudo contra caída de rocas", name: "Escudo contra caída de rocas" },
                            { id: "Muro anclado", name: "Muro anclado" },
                            { id: "Muro de concreto reforzado", name: "Muro de concreto reforzado" },
                            { id: "Muro de encofrado", name: "Muro de encofrado" },
                            { id: "Muro de gaviones", name: "Muro de gaviones" },
                            { id: "Muro de gravedad en concreto", name: "Muro de gravedad en concreto" },
                            { id: "Muro de tierra reforzada", name: "Muro de tierra reforzada" },
                            { id: "Muros de contención", name: "Muros de contención" },
                            { id: "Pantallas ancladas", name: "Pantallas ancladas" },
                            { id: "Pilotes y caissons", name: "Pilotes y caissons" },
                            { id: "Reconformación", name: "Reconformación" },
                            { id: "Revestimiento flexible", name: "Revestimiento flexible" },
                            { id: "Revestimiento vegetal", name: "Revestimiento vegetal" },
                            { id: "Suelo empernado", name: "Suelo empernado" },
                            { id: "Otros", name: "Otros" },
                        ]} fullWidth formClassName={classes.grid_cont12} />

                    }
                    {obras &&
                        <NumberInput 
                            label="Largo (m)" 
                            source="largo" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth formClassName={classes.grid_cont4_f} 
                        />
                    }
                    {obras &&
                        <NumberInput 
                            label="Ancho (m)" 
                            source="ancho" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {obras &&
                        <NumberInput 
                            label="Altura (m)" 
                            source="area" 
                            validate={[required(), minValue(0), validarUnDecimal]} 
                            fullWidth formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {obras &&
                        <br/>
                    }
                    {obras &&
                        <BooleanInput 
                            label="Ficha de inspección de obra" 
                            source="fichaInspeccion" 
                            onChange={ValidarFicha} 
                            fullWidth 
                            formClassName={classes.grid_cont12} 
                        />
                    }
                    {ficha &&
                        <TextInput 
                            source="inspector" 
                            validate={[minValue(0)]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_f} 
                        />
                    }
                    {ficha &&
                        <DateInput 
                            label="Fecha del estudio" 
                            source="fechaInspeccion" 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                            validate={validacionmaxfechaact, isFechaInvalida}
                        />
                    }
                    {ficha &&
                        <TextInput 
                            label="Nombre de la ficha" 
                            source="nombreFicha" 
                            validate={[maxLength(50)]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {ficha &&
                        <br/>
                    }
                    {ficha &&
                        <BooleanInput 
                            label="Estudios de estabilidad del sitio" 
                            source="estudioEstabilidad"
                            onChange={ValidarEstudio} 
                            fullWidth 
                            formClassName={classes.grid_cont12} 
                        />
                    }
                    {estudio &&
                        <TextInput 
                            source="consultor" 
                            validate={[maxLength(30)]} 
                            fullWidth 
                            formClassName={classes.grid_cont12} 
                        />
                    }
                    {estudio &&
                        <DateInput 
                            label="Fecha del estudio" 
                            source="fechaEstudio" 
                            fullWidth 
                            formClassName={classes.grid_cont4_f} 
                            validate={validacionmaxfechaact, isFechaInvalida}
                        />
                    }
                    {estudio &&
                        <TextInput 
                            label="Código de estudio" 
                            source="codigoEstudio" 
                            validate={[maxLength(10)]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                        />
                    }
                    {estudio &&
                        <TextInput 
                            label="Nombre del estudio" 
                            source="nombreEstudio" 
                            validate={[maxLength(50)]} 
                            fullWidth 
                            formClassName={classes.grid_cont4_s} 
                        />
                    }

                </FormTab>
                {/* ---------- DETALLES ------------- */}
                <FormTab label="Detalles">
                    {elemento === "/tipo_elementos/OBST" && 
                        <br/>
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <SelectInput label="Condición del elemento" source="estado" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    }

                    {/* ------ Elemento = Márgenes, Separadores ------- */}
                    {elemento === "/tipo_elementos/MARG" &&
                        <SelectInput label="Condición del elemento" source="estado" allowEmpty emptyValue="" choices={[
                            { id: "Existente", name: "Existente" },
                            { id: "Reemplazo", name: "Reemplazo" },
                            { id: "Nuevo", name: "Nuevo" },
                            
                        ]} validate={[required()]} onChange={ValidarEstado} fullWidth formClassName={classes.grid_cont4_f} />
                    }

                    {/* ------ Elemento = Márgenes, Separadores ------- */}
                    {elemento === "/tipo_elementos/SEPD" &&
                        <SelectInput label="Condición del elemento" source="estado" allowEmpty emptyValue="" choices={[
                            { id: "Existente", name: "Existente" },
                            { id: "Reemplazo", name: "Reemplazo" },
                            { id: "Nuevo", name: "Nuevo" },
                        ]} validate={[required()]} onChange={ValidarEstado} fullWidth formClassName={classes.grid_cont4_f} />
                    }

                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="Condición del elemento" source="estado" allowEmpty emptyValue="" choices={[
                            { id: "Existente", name: "Existente" },
                            { id: "Reemplazo", name: "Reemplazo" },
                            { id: "Nuevo", name: "Nuevo" },
                        ]} validate={[required()]} onChange={ValidarEstado} fullWidth formClassName={classes.grid_cont4_f} />
                    }


                    {estados === "Reemplazo" &&
                        <SelectInput label="Motivo de reemplazo" source="condicion" choices={[
                            { id: "Accidente", name: 'Accidente' },
                            { id: "Instalación", name: 'Instalación' },
                            { id: "Otro", name: 'Otro' },
                            { id: "Reemplazo programado", name: 'Reemplazo programado' },
                            { id: "Vandalismo", name: 'Vandalismo' },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }

                    {estados === "Reemplazo" &&
                      <ReferenceInput
                        label="Elemento que Reemplazo"
                        source="elementoReemplazo"
                        reference="elemento_alrededors"
                        filter={{
                            tipoElemento : elemento,
                            status : 1,
                            // unidadFuncional: unidads,
                            // tramo: tramos,
                            // segmento : segmentos
                        }}
                        fullWidth
                        validate={[required()]}
                        formClassName={classes.grid_cont4_s}
                  >
                      <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigo" />
                  </ReferenceInput>
                    }
                    {estados != "Reemplazo" &&
                        <div fullWidth formClassName={classes.grid_cont7}></div>
                    }
                    {/* --------ZONA INESTABLE -----------*/}
                    {elemento === "/tipo_elementos/ZINT" &&
                        <DateInput label="Fecha de construcción" source="fechaConstruccion" fullWidth formClassName={classes.grid_cont4_f} validate={validacionmaxfechaact, isFechaInvalida}/>
                    }

                    {elemento === "/tipo_elementos/ZINT" &&
                        <ReferenceInput 
                            label="Proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{ proyecto: props.proyecto, suministros: 1, sistema: 'ALR' }} 
                            validate={[required()]} fullWidth formClassName={classes.grid_cont4_s}
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                    >
                        <AutocompleteInput options={{fullWidth: true}}  optionText="razonSocial" fullWidth />
                        </ReferenceInput>
                    }

                    {elemento === "/tipo_elementos/ZINT" &&
                        <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required(), minValue(0)]} formClassName={classes.grid_cont4_s} format={FormatoGarantia}/>
                    }
                    <br/>
                    <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    <br/>
                    <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont12} />
                    <TextInput source="observaciones" validate={[required()]} fullWidth formClassName={classes.grid_cont12} />
                </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
}); 
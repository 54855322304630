import React, {useState} from 'react';
import { SimpleForm } from './../../../SimpleForm';
import {Query, TextInput, SelectInput, ReferenceInput, AutocompleteInput,FormDataConsumer} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';
import { required, maxLength } from 'react-admin';
import UniqueGranulometry from './../../../componentes/UniqueGranulometry';

const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_field: { display: 'inline-block', marginLeft: 32, width: '30%' }
};

export const GranulometriaEdit = withStyles(styles)(({ classes, ...props }) => {
    
    const [materialCodigo, setNombreMaterial] = useState('')
    const EspecificacionMaterial = (value) =>{
        setNombreMaterial(value)
    } 
    const choises = [
        { id: '1', name: 'Adoquín en arcilla' },
        { id: '2', name: 'Adoquines de concreto' },
        { id: '3', name: 'Afirmado' },
        { id: '4', name: 'Base asfáltica' },
        { id: '5', name: 'Base de concreto hidráulico' },
        { id: '6', name: 'Base estabilizada - otros' },
        { id: '7', name: 'Base estabilizada con asfalto espumado' },
        { id: '8', name: 'Base estabilizada con emulsión asfáltica' },
        { id: '9', name: 'Base granular' },
        { id: '10', name: 'Base tratada con cemento' },
        { id: '11', name: 'Concreto hidráulico' },
        { id: '12', name: 'Lechada ásfaltica' },
        { id: '13', name: 'Mezcla abierta en caliente' },
        { id: '14', name: 'Mezcla abierta en frío' },
        { id: '15', name: 'Mezcla asfáltica modificada con grano de caucho' },
        { id: '16', name: 'Mezcla asfáltica modificada con polímeros' },
        { id: '17', name: 'Mezcla densa en caliente' },
        { id: '18', name: 'Mezcla densa en frío' },
        { id: '19', name: 'Mezcla drenante' },
        { id: '20', name: 'Microaglomerado en caliente' },
        { id: '21', name: 'Sub base asfalto espumado' },
        { id: '22', name: 'Sub-base estabilizada - otros' },
        { id: '23', name: 'Sub base estabilizada con asfalto' },
        { id: '24', name: 'Sub base estabilizada con cemento' },
        { id: '25', name: 'Sub-base granular' },
        { id: '26', name: 'Subrazante estabilizada con cal' },
        { id: '27', name: 'Subrazante estabilizada con cemento' },
        { id: '28', name: 'Subrazante estabilizada con geomalla' },
        { id: '29', name: 'Subrazante estabilizada con geotextil' },
        { id: '30', name: 'Subrazante fina' },
        { id: '31', name: 'Subrazante granular' },
        { id: '32', name: 'Suelo cemento' },
    ];
    const [especificacion, setEspecificacion] = useState('');
    const [tipo, setTipo] = useState('');
     const validateEspecificacion = (value) =>{
        setTipo(value);
        let  especificacion = [
            "",
            "",
            "INV-311-13",
            "INV-450-13",
            "INV-505-13",
            "INV-13",
            "",
            "INV-340-13",
            "INV-330-13",
            "INV-351-13",
            "INV-500-13",
            "INV-433-13",
            "INV-451-13",
            "INV-441-13",
            "INV-413-13",
            "INV-414-13",
            "INV-450-13",
            "INV-440-13",
            "INV-453-13",
            "INV-452-13",
            "",
            "",
            "",
            "",
            "INV-320-13",
            "INV-236-13",
            "INV-235-13",
            "INV-233-13",
            "INV-232-13",
            "",
            "",
            "INV-350-13"
            
        ];
        setEspecificacion(especificacion[parseInt(value)-1]);
    } 

    return(
        <Edit title="Editar granulometría" {...props} undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list"  >
            <SelectInput source="tipo" label="Tipo de Material" choices={choises} fullWidth validate={[required(),validateEspecificacion]}  formClassName={classes.first_field}/>
            <TextInput source="especificacion" label="Especificación" fullWidth validate={[maxLength(20),EspecificacionMaterial]} defaultValue={especificacion}  formClassName={classes.last_field}/>  
                {/* <ReferenceInput label="Tipo de Material" source="material" reference="materials" formClassName={classes.first_field} >
                    <SelectInput optionText="tipo" disabled/>
                </ReferenceInput>                                
                <ReferenceInput 
                    label="Especificación" 
                    source="material" 
                    reference="materials" 
                    filterToQuery={searchText => ({ especificacion: searchText })} 
                    formClassName={classes.first_field} 
                    validate={[required(), EspecificacionMaterial]}
                >
                    <AutocompleteInput optionText="especificacion" disabled/>
                </ReferenceInput> */}
                <UniqueGranulometry 
                        id="granulometria"
                        label="Granulometría" 
                        source="granulometria" 
                        operation="edit"
                        style={{width: '100%'}} 
                        fullWidth 
                        formClassName={classes.last_field}
                    />
                {/* {                                    
                    <Query type="GET_ONE" resource="materials" payload={{ id: materialCodigo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput style={{display:'none'}}   label="Nombre Material" defaultValue={data.tipo} source="especificacion" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>     
                    //                      
                } */}
                    <FormDataConsumer>

                     {({ formData, ...rest }) => {
                     if((formData.especificacion === ' ' || formData.tipo !==  tipo) && tipo !== '' ){ formData.especificacion = especificacion}
                        }
                         }
                    </FormDataConsumer>
            </SimpleForm>
        </Edit>
)})
import React, {useState, Fragment} from 'react';
import { SimpleForm, Query, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput,  FileInput, FileField , AutocompleteInput} from 'react-admin';
import { PimsCreate } from './../../Create';
import { PRInput } from './../../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {required} from 'react-admin';
import {abValidationInicio, abValidationFin, validarLogicaAbsFinCreate} from '../../validacionesInputs';
import config from '../../../config/config'; 
let urlAppjs = config.UrlServer;

export const CargarInformacionCreate = withStyles(styles)(({ classes, ...props }) => {
    const[tipoMedicion, setTipoMedicion] = useState('');
    const [datosArchivo, setdatosArchivo] = useState('');
    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');
    const [parametro, setParametro] = useState('');
    const [correccionabini, setCorreccionabini] =useState('');
    const [correccionabfin, setCorreccionabfin] =useState('');
    // const [validacionesabinicial, setValidacionesabinicial]=useState([required(), abValidationInicio]);
    // const [validacionesabfinal, setValidacionesabfinal]=useState([required(), abValidationFin, validarLogicaAbsFinCreate]);

    const validartipoMedida=(value)=>{
        setTipoMedicion(value);
        // console.log(tramos);
        // console.log(parametro)
        if(value==="De Corrección"){
            let tramo=0;
            let indicador=0;
            let respuesta=0;
            if(tramos){
                tramo=tramos.replace("/tramos/", "");
            }
            if(parametro){
                indicador=parametro.replace("/parametros/", "");
            }
            if(tramos && parametro && tramos!="" && parametro!=""){
            fetch(urlAppjs+"/buscarTramoCorreccion/"+tramo+"/"+indicador)
            .then((response)=>{
                return response.json()
            })
            .then((response)=>{
                respuesta=response.respuesta;
                if(respuesta===1){
                    setCorreccionabini(response.abinicial)
                    setCorreccionabfin(response.abfinal)
                }
                //console.log(response)
            });
            if(correccionabini && correccionabfin){}
            else{
                return "No se encontraron cargas de datos publicadas para el indicador"
            }
            }
            // if(respuesta===0){
            //     return "No se han encontrado Cargas Publicadas para este indicador"
            // }
        }else{

        }
        
        
    }
    const ValidarUnidad = (value) => {
        setUnidad(value)
    }
    const ValidarTramo = (value) => {
        //console.log(value)
        if(value){
            setTramo(value)
        }
        
    }
const validarfechaact=(value)=>{
    let fecha=new Date(value+"T00:00:00");
    let fechaact=new Date();
    if(fecha>fechaact){
        return "La fecha no puede ser mayor a la actual"
    }
}        
const ValidarParametro=(value)=>{
    setParametro(value);
}

const ValidarCorreccionIni=(value)=>{
if(value && typeof value==="string"){
    let valor=value.replace("K", "").replace("+", ".");
    valor=parseFloat(valor);
    if(correccionabini!="" || correccionabfin!=""){
        if(valor<correccionabini){
            return "La abscisa inicial de corrección no puede ser menor a la abscisa inicial"
        }else if(valor>correccionabfin){
            return "La abscisa inicial de corrección no puede ser mayor a la abscisa final"
        }
    }else{
        return "No se encontraron cargas de datos publicadas para el indicador"
    }
}
}
const ValidarCorreccionFin=(value)=>{
    if(value && typeof value==="string"){
        let valor=value.replace("K", "").replace("+", ".");
        valor=parseFloat(valor);
        console.log(correccionabini+"//"+correccionabfin);
        if(correccionabini!="" || correccionabfin!=""){
            if(valor<correccionabini){
                return "La abscisa final de corrección no puede ser menor a la abscisa inicial"
            }else if(valor>correccionabfin){
                return "La abscisa final de corrección no puede ser mayor a la abscisa final"
            }
        }else{
            return "No se encontraron cargas de datos publicadas para el indicador"
        }
    }
}
    let idproyecto=localStorage.getItem("id_project");
    return(
        <PimsCreate  {...props} title="Crear Carga de Información">
            <SimpleForm submitOnEnter={false} redirect="list" > 
            <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Selección del Indicador</Typography>
            <ReferenceInput label="Indicador" source="indicador" reference="parametros" sort={{ field: 'id', order: 'ASC' }} fullWidth formClassName={classes.grid_cont12} validate={required(), ValidarParametro}>
                <SelectInput optionText="codigoCompleto" />
            </ReferenceInput>
            <TextInput source="indicadorident" style={{display: "none"}} defaultValue={parametro}/>
            <TextInput source="tramoident" style={{display: "none"}} defaultValue={tramos}/>
            <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Detalles</Typography>
            <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ field: 'codigo', order: 'asc' }} 
            formClassName={classes.grid_cont3_f}
            validate={[required(), ValidarUnidad]}
            fullWidth
            >
               <AutocompleteInput  options={{ fullWidth: true }} optionText="fullCodigos" fullWidth/>
            </ReferenceInput>
            {unidads &&
                <ReferenceInput label="Tramo" source="tramo" reference="tramos" 
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{ field: 'codigo', order: 'ASC' }} 
                fullWidth 
                formClassName={classes.grid_cont3_s}
                validate={[required(), ValidarTramo]}
                filter={{ unidadFuncional: unidads }}
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                </ReferenceInput>   
            }
            {tramos &&
            <Query type='GET_ONE' resource='tramo' payload={{ id: tramos }} formClassName={classes.ocultar}>
            {({ data, loading, error }) => {
                if (data) {
                return (
                    <React.Fragment>
                        <NumberInput 
                            source="abInicios"
                            label="ABSCISA INICAL"
                            id="IdAbInicio"
                            defaultValue={data.abInicios}                       
                            style={{ display: 'none', width: '100%' }}
                            fullWidth
                        />
                        <NumberInput 
                            source="abFins"
                            label="ABSCISA FINAL"
                            id="IdAbFin"
                            defaultValue={data.abFins}                       
                            style={{ display: 'none', width: '100%' }}
                            fullWidth
                        />
                    </React.Fragment>
                    
                )}
                else{
                    return (<div></div>)
                }
            }}
        </Query>
            }
            {tramos && tipoMedicion!="De Corrección" &&
            <PRInput label="Abscisa inicial" id="ingresoAbInicio" source="abini" fullWidth   formClassName={classes.grid_cont3_s} validate={[required(), abValidationInicio]}/>
            }
            {tramos && tipoMedicion!="De Corrección" &&
            <PRInput label="Abscisa Final" id="ingresoAbFin" source="abfin" fullWidth  formClassName={classes.grid_cont3_s} validate={[required(), abValidationFin, validarLogicaAbsFinCreate]}/>
            }
            {tramos && parametro && tipoMedicion==="De Corrección" &&
            <PRInput label="Abscisa inicial" id="ingresoAbInicio" source="abiniC" fullWidth formClassName={classes.grid_cont3_s} defaultValue={correccionabini}  disabled/>
            }
            {tramos && parametro && tipoMedicion==="De Corrección" &&
            <PRInput label="Abscisa Final" id="ingresoAbFin" source="abfinC" fullWidth  formClassName={classes.grid_cont3_s} defaultValue={correccionabfin}  disabled/>
            }
            {/* {
                <FormDataConsumer formClassName={classes.ocultar}>
                {({formData, dispatch, ...rest}) => {
                    if(typeof formData != 'undefined' && typeof formData.abini!= 'undefined' && typeof formData.abfin!= 'undefined' && parametro && tipoMedicion==="De Corrección"){
                        formData.abini=correccionabiniC;
                        formData.abfin=correccionabfinC;
                    }
                }}
                </FormDataConsumer>
            } */}
            {!unidads &&
            <div formClassName={classes.grid_cont3_s}></div>
            }
            {!tramos &&
            <div formClassName={classes.grid_cont3_s}></div>
            }
            {!tramos &&
            <div formClassName={classes.grid_cont3_s}></div>
            }
            
            <DateInput label="Fecha de Medición" id="datefield" source="fechaMedicion" formClassName={classes.grid_cont3_f} fullWidth validate={[required(), validarfechaact]}/>

            <SelectInput label="Medido Por" source="medidoPor" formClassName={classes.grid_cont3_s} fullWidth validate={[required()]} choices={[
                                {id: 'Interventoría', name: 'Interventoría'},
                                {id: 'Mantenimiento', name: 'Mantenimiento'}
                            ]}/>
            <SelectInput label="Estado" source="estado" formClassName={classes.grid_cont3_s} fullWidth validate={[required()]}
            choices={[
                {id: 'Por Revisar', name: 'Por Revisar'},
                {id: 'Publicada', name: 'Publicada'},
                {id: 'Revisión realizada', name: 'Revisión realizada'}
            ]}
            />
            <SelectInput label="Tipo de medición" source="tipoMedicion" formClassName={classes.grid_cont3_s} validate={[required(), validartipoMedida]} choices={[
                                {id: 'Periódica', name: 'Periódica'},
                                // {id: 'De Verificación', name: 'De Verificación'},
                                {id: 'De Corrección', name: 'De Corrección'},
                                
                                
                            ]}/>
            {tipoMedicion==="De Corrección" &&
            <Fragment>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Rango de Corrección</Typography>
            <PRInput label="Abscisa inicial"  source="abiniCorreccion" fullWidth validate={[required(), ValidarCorreccionIni]}  className={classes.grid_cont3_f}/>
            <PRInput label="Abscisa Final"  source="abfinCorreccion" fullWidth validate={[required(), ValidarCorreccionFin]} className={classes.grid_cont3_s}/>
            </Fragment>
            }
            <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Cargar Archivo</Typography>
            <FileInput source="archivoCarga" label="Related files" accept=".csv" multiple={false} validate={[required()]}>
                <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer style={{display: "none"}}>
                                {({formData, ...rest}) => {
                                    if(formData && formData.archivoCarga && formData.archivoCarga.src){
                                        //console.log(formData.archivoCarga)
                                        var blobUrl=formData.archivoCarga.src;
                                        fetch(blobUrl)
                                        .then((res) => {
                                            return res.blob()
                                        })
                                        .then((blob) => {
                                            var myReader = new FileReader();
                                            myReader.readAsText(blob);
                                            //console.log(blob);
                                            let texto=""
                                            myReader.onload = function(e) {
                                                var txt = myReader.result;
                                                setdatosArchivo(txt)
                                            };
                                        })

                                    }    
                                    else{
                                        setdatosArchivo("");
                                    }                            
                                }}
                    </FormDataConsumer>
            <TextInput  source="datosarchivo" id="datosarchivo" style={{display: "none"}} multiline defaultValue={datosArchivo}/>
            <TextInput source="proyecto" defaultValue={idproyecto} style={{display: "none"}}/>
            <Divider fullWidth/>
            </SimpleForm>
         </PimsCreate>
    )
})
import React from 'react';
import { TabbedShowLayout, TextField, Tab, DateField,NumberField,SelectField} from 'react-admin';

import { Show } from './../../../Show';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../../../EstilosGrid';

export const MaterialesShow = withStyles(styles)(({ classes,record, ...props }) => {
    const choises2 = [
        { id: '1', name: 'Adoquín en arcilla' },
        { id: '2', name: 'Adoquines de concreto' },
        { id: '3', name: 'Afirmado' },
        { id: '4', name: 'Base asfáltica' },
        { id: '5', name: 'Base de concreto hidráulico' },
        { id: '6', name: 'Base estabilizada - otros' },
        { id: '7', name: 'Base estabilizada con asfalto espumado' },
        { id: '8', name: 'Base estabilizada con emulsión asfáltica' },
        { id: '9', name: 'Base granular' },
        { id: '10', name: 'Base tratada con cemento' },
        { id: '11', name: 'Concreto hidráulico' },
        { id: '12', name: 'Lechada ásfaltica' },
        { id: '13', name: 'Mezcla abierta en caliente' },
        { id: '14', name: 'Mezcla abierta en frío' },
        { id: '15', name: 'Mezcla asfáltica modificada con grano de caucho' },
        { id: '16', name: 'Mezcla asfáltica modificada con polímeros' },
        { id: '17', name: 'Mezcla densa en caliente' },
        { id: '18', name: 'Mezcla densa en frío' },
        { id: '19', name: 'Mezcla drenante' },
        { id: '20', name: 'Microaglomerado en caliente' },
        { id: '21', name: 'Sub base asfalto espumado' },
        { id: '22', name: 'Sub-base estabilizada - otros' },
        { id: '23', name: 'Sub base estabilizada con asfalto' },
        { id: '24', name: 'Sub base estabilizada con cemento' },
        { id: '25', name: 'Sub-base granular' },
        { id: '26', name: 'Subrazante estabilizada con cal' },
        { id: '27', name: 'Subrazante estabilizada con cemento' },
        { id: '28', name: 'Subrazante estabilizada con geomalla' },
        { id: '29', name: 'Subrazante estabilizada con geotextil' },
        { id: '30', name: 'Subrazante fina' },
        { id: '31', name: 'Subrazante granular' },
        { id: '32', name: 'Suelo cemento' },
    ];
    const optionRenderer = choice => `${choice.id}`;
    const choises3 = [
        { id: '1', name: 'Otros' },
        { id: '2', name: 'Otros' },
        { id: '3', name: 'No ligado' },
        { id: '4', name: 'Bituminoso' },
        { id: '5', name: 'Cementado' },
        { id: '6', name: 'No ligado' },
        { id: '7', name: 'Bituminoso' },
        { id: '8', name: 'Bituminoso' },
        { id: '9', name: 'No ligado' },
        { id: '10', name: 'Cementado' },
        { id: '11', name: 'Cementado' },
        { id: '12', name: 'Bituminoso' },
        { id: '13', name: 'Bituminoso' },
        { id: '14', name: 'Bituminoso' },
        { id: '15', name: 'Bituminoso' },
        { id: '16', name: 'Bituminoso' },
        { id: '17', name: 'Bituminoso' },
        { id: '18', name: 'Bituminoso' },
        { id: '19', name: 'Bituminoso' },
        { id: '20', name: 'Bituminoso' },
        { id: '21', name: 'No ligado' },
        { id: '22', name: 'No ligado' },
        { id: '23', name: 'No ligado' },
        { id: '24', name: 'No ligado' },
        { id: '25', name: 'No ligado' },
        { id: '26', name: 'No ligado' },
        { id: '27', name: 'No ligado' },
        { id: '28', name: 'No ligado' },
        { id: '29', name: 'No ligado' },
        { id: '30', name: 'No ligado' },
        { id: '31', name: 'No ligado' },
        { id: '32', name: 'No ligado' }
    ];

    return(
        <Show title="Información Material" {...props}>
            <TabbedShowLayout>
                <Tab label="Información Básica">
                    <TextField 
                        source="material" 
                        label="Código de material" 
                        formClassName={classes.grid_cont4s}
                    />                
                     <SelectField source="tipo" label="Tipo de Material"  choices={choises2}  optionText="name"   formClassName={classes.grid_cont4}/>
                    <SelectField label="Comportamiento" source="tipo" fullWidth choices={choises3}  optionText="name"  formClassName={classes.grid_cont3}/>
                    <TextField source="especificacion" label="Especificación" fullWidth   formClassName={classes.grid_cont4}/>  
                    <NumberField label="Módulo teórico (MPa)" source="refmodulus" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                    <NumberField label="Módulo mínimo (MPa)" source="minmodulus" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                    <NumberField label="Desviación estandar" source="stdfactor" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                    <NumberField label="Modulo de poisson" source="poissons"fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                    {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
                    <br/>
                    <TextField label="Observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont4}/> 
                    </Tab>
                <Tab label="Auditoría">
                    <DateField source="createdAt" label="Fecha Creación"/> 
                    <TextField source="createdBy" label="Creado Por"/>
                    <span></span>               
                    <DateField source="updatedAt" label="Fecha Actualización"/>
                    <TextField source="updatedBy" label="Actualizado Por"/>
                    <span></span>   
                </Tab>
            </TabbedShowLayout>
        </Show>
)})
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import AdminIcon from '@material-ui/icons/Domain';
import InventarioIcon from '@material-ui/icons/Streetview';
import HomeIcon from '@material-ui/icons/Home';
import Report from '@material-ui/icons/Report';
import BookIcon from '@material-ui/icons/Book';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ContactsIcon from '@material-ui/icons/Contacts';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import Usuario from '@material-ui/icons/People';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import GradientIcon from '@material-ui/icons/Gradient';
import PowerInputIcon from '@material-ui/icons/PowerInput';
import InvertColors from '@material-ui/icons/InvertColors';
import FilterHdr from "@material-ui/icons/FilterHdr";
import DescriptionIcon from '@material-ui/icons/Description';
import { withRouter } from 'react-router-dom';
import CropIcon from '@material-ui/icons/Crop';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SatelliteIcon from '@material-ui/icons/Satellite';
import StyleIcon from '@material-ui/icons/Style';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SettingsIcon from '@material-ui/icons/Settings';
import SvgIcon from '@material-ui/core/SvgIcon';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import TimelineIcon from '@material-ui/icons/Timeline';
// NUEVO2
import {    

    MenuItemLink,    
    WithPermissions,

} from 'react-admin';

import PimsSubMenu from './PimsSubMenu';

function SquareFoot(props) {
    return (
      <SvgIcon {...props}>
        <path d="M17.66,17.66l-1.06,1.06l-0.71-0.71l1.06-1.06l-1.94-1.94l-1.06,1.06l-0.71-0.71l1.06-1.06l-1.94-1.94l-1.06,1.06 l-0.71-0.71l1.06-1.06L9.7,9.7l-1.06,1.06l-0.71-0.71l1.06-1.06L7.05,7.05L5.99,8.11L5.28,7.4l1.06-1.06L4,4v14c0,1.1,0.9,2,2,2 h14L17.66,17.66z M7,17v-5.76L12.76,17H7z" />
      </SvgIcon>
    );
  }

//<DashboardMenuItem onClick={onMenuClick} />
//<MenuItemLink to={`/elemento_seguridads`} primaryText="Seguridad" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_drenajes`} primaryText="Drenaje" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_senalizacions`} primaryText="Señalización" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_alrededors`} primaryText="Alrededores" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_puentes`} primaryText="Puentes y pontones" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_otros`} primaryText="Otros" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_instalacions`} primaryText="Instalaciones" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_reds`} primaryText="Redes" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
//<MenuItemLink to={`/elemento_traficos`} primaryText="Trafico" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>



class PimsMenu extends Component {
    state = {
        menuAdmin: false,
        menuInventario: false, 
        menuInicio: false,        
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        // logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, open, logout } = this.props;
        var usuarios=localStorage.getItem('usuarios');
        var perfiles=localStorage.getItem('perfiles');
        // var asigclaves=localStorage.getItem('asigclaves');
        var proyecto=localStorage.getItem('proyecto');
        var rutas=localStorage.getItem('rutas');
        var unifuncionales=localStorage.getItem('unifuncionales');
        var tramos=localStorage.getItem('tramos');
        var segmento=localStorage.getItem('segmento');
        var contemergencia=localStorage.getItem('contemergencia');
        var proveedores=localStorage.getItem('proveedores');
        var serpublicos=localStorage.getItem('serpublicos');
        var atributos_valores=localStorage.getItem('atributos_valores');
        var atributos_keys=localStorage.getItem('atributos_keys');
        var atributos=[];
        if(atributos_valores && atributos_keys){
            let llaves= atributos_keys.split(',');
            let valores = atributos_valores.split(',');
            for(let i=0; i<llaves.length; i++){
                let permiso=0;
                if(valores[i]==="1" || valores[i]==="2" || valores[i]==="4"){permiso=1}
                atributos[llaves[i]]=permiso;
            }
            //console.log(valores);
        }
        if (!localStorage.getItem('visibility')) {
            localStorage.setItem("visibility", "hidden");
        }
        return (
            
            <div 
                id="pimsMenu" 
                //style={{visibility : localStorage.getItem('visibility')}}
                >
                {' '} 
                <MenuItemLink to={`/`} primaryText="Inicio" leftIcon={<HomeIcon />} onClick={onMenuClick} />                 
                <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuAdmin')}
                    isOpen={this.state.menuAdmin}
                    sidebarIsOpen={open}
                    name="Administración"
                    icon={<AdminIcon />}
                >
                    <PimsSubMenu
                            name="Usuarios"
                            icon={<Usuario />}
                            handleToggle={() => this.handleToggle('menuUsuario')}
                        isOpen={this.state.menuUsuario}
                        sidebarIsOpen={open}
                    >
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['usuario']===1
                           ? <MenuItemLink to={`/usuarios`} primaryText="Usuario" leftIcon={<PersonAdd />} onClick={onMenuClick}/>
                           : null
                           )}
                       />
                       <WithPermissions
                            render={({ permissions }) => (
                                //perfiles === "1" || perfiles === "2" || perfiles === "4"
                                atributos['perfiles']===1
                               ?  <MenuItemLink to={`/atributos`} primaryText="Atributos" leftIcon={<AssignmentInd />} onClick={onMenuClick}/>
                               : null
                               )}
                           />     
                                
                    </PimsSubMenu>
                    <PimsSubMenu
                        handleToggle={() => this.handleToggle('menuProyect')}
                        isOpen={this.state.menuProyect}
                        sidebarIsOpen={open}
                        name="Proyectos"
                        icon={<BookIcon />}
                        
                    >
                        <WithPermissions 
                            render={({ permissions }) => (
                                //proyecto === "1" || proyecto === "2" || proyecto === "4"
                                atributos['proyecto']===1
                                ? <MenuItemLink to={`/proyectos`} primaryText="Diseñar Proyecto" leftIcon={<SquareFoot />} onClick={onMenuClick} />
                                :null
                            )}
                        />
                        <WithPermissions 
                            render={({ permissions }) => (
                                //rutas === "1" || rutas === "2" || rutas === "4"
                                atributos['rutas']===1
                         ? <MenuItemLink to={`/rutas`} primaryText="Rutas" leftIcon={<SquareFoot />} onClick={onMenuClick}/>
                        :null
                            )}
                        />
                        <WithPermissions 
                            render={({ permissions }) => (
                                //unifuncionales === "1" || unifuncionales === "2" || unifuncionales === "4"
                                atributos['unifuncionales']===1
                        ?<MenuItemLink to={`/unidad_funcionals`} primaryText="Unidades funcionales" leftIcon={<SquareFoot />} onClick={onMenuClick}/>
                        :null
                            )}
                        />
                        <WithPermissions 
                            render={({ permissions }) => (
                                //tramos === "1" || tramos === "2" || tramos === "4"
                                atributos['tramos']===1
                        ?<MenuItemLink to={`/tramos`} primaryText="Tramos" leftIcon={<SquareFoot />} onClick={onMenuClick}/>
                        :null
                            )}
                        />
                         <WithPermissions 
                            render={({ permissions }) => (
                                //segmento === "1" || segmento === "2" || segmento === "4"
                                atributos['segmento']===1
                        ?<MenuItemLink to={`/segmentos`} primaryText="Segmentos" leftIcon={<SquareFoot />} onClick={onMenuClick}/>
                        :null
                            )}
                        />
                        <PimsSubMenu
                        handleToggle={() => this.handleToggle('configurations')}
                        isOpen={this.state.configurations}
                        sidebarIsOpen={open}
                        name="Configuraciones"
                        icon={<SettingsIcon />}
                        >
                            <PimsSubMenu
                                handleToggle={() => this.handleToggle('menuMateriales')}
                                isOpen={this.state.menuMateriales}
                                sidebarIsOpen={open}
                                name="Materiales"
                                icon={<StyleIcon />}
                            >
                                <WithPermissions 
                                render={({ permissions }) => (
                                atributos['materiales']===1
                                ?<MenuItemLink to={`/materials`} primaryText="Materiales" onClick={onMenuClick}/>                                       
                                :null
                                    )}
                                />
                                <WithPermissions 
                                render={({ permissions }) => (
                                atributos['ganulometria']===1
                                ?<MenuItemLink to={`/granulometrias`} primaryText="Granulometría" onClick={onMenuClick}/>                                                               
                                :null
                                    )}
                                />
                                
                            </PimsSubMenu>
                            <PimsSubMenu
                                handleToggle={() => this.handleToggle('confIndicadores')}
                                isOpen={this.state.confIndicadores}
                                sidebarIsOpen={open}
                                name="Indicadores"
                                icon={<StyleIcon />}
                            >
                                <MenuItemLink to={`/preguntas`} primaryText="Criterios de Cumplimiento" onClick={onMenuClick}/>
                                {/* <MenuItemLink to={`/parametros_grupos`} primaryText="Parámetros de evaluación y Rangos de alerta" onClick={onMenuClick}/> */}
                                <MenuItemLink to={`/colores`} primaryText="Diagrama colorimétrico" onClick={onMenuClick}/>

                            </PimsSubMenu>
                            <PimsSubMenu
                                handleToggle={() => this.handleToggle('confReparacionPers')}
                                isOpen={this.state.confReparacionPers}
                                sidebarIsOpen={open}
                                name="Deterioro PERS"
                                icon={<StyleIcon />}
                            >
                                <MenuItemLink to={`/reparacion_localizadas`} primaryText="Reparaciones" onClick={onMenuClick}/>                                                                    
                                <MenuItemLink to={`/tipo_deterioro_pers`} primaryText="Deterioros" onClick={onMenuClick}/>                                                                    

                            </PimsSubMenu>
                        </PimsSubMenu>
                    </PimsSubMenu>
                    <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuContact')}
                    isOpen={this.state.menuContact}
                    sidebarIsOpen={open}
                    name="Contactos"
                    icon={<ContactsIcon />}
                    >
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['contemergencia']===1
                                    ? <MenuItemLink to={`/contacto_emergencias`} primaryText="Emergencias" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
                                    : null
                            )}
                        />
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['proveedores']===1
                        ?<MenuItemLink to={`/proveedors`} primaryText="Proveedores" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
                        : null
                            )}
                        />
                         <WithPermissions
                            render={({ permissions }) => (
                                atributos['serpublicos']===1
                        ?<MenuItemLink to={`/servicios_Publicos`} primaryText="Servicios Públicos" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
                        : null
                            )}
                        />
                        </PimsSubMenu>
                </PimsSubMenu>

                

                <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuInventario')}
                    isOpen={this.state.menuInventario}
                    sidebarIsOpen={open}
                    name="Inventario"
                    icon={<InventarioIcon />}
                >
                    <PimsSubMenu
                        handleToggle={() => this.handleToggle('menuSegmentos')}
                        isOpen={this.state.menuSegmentos}
                        sidebarIsOpen={open}
                        name="Segmentos"
                        icon={<BookIcon />}
                    >
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['flexible']===1
                        ?<MenuItemLink to={`/flexible_detalles`} primaryText="Flexible" onClick={onMenuClick}/>
                        : null
                            )}
                        />
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['rigido']===1
                        ?<MenuItemLink to={`/rigido_detalles`} primaryText="Rígido" onClick={onMenuClick}/>                                       
                        : null
                            )}
                        />                                       
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['adoquin']===1
                        ?<MenuItemLink to={`/adoquin_detalles`} primaryText="Adoquín" onClick={onMenuClick}/>
                        : null
                            )}
                        /> 
                        <WithPermissions
                            render={({ permissions }) => (
                                atributos['otro']===1
                        ?<MenuItemLink to={`/otro_detalles`} primaryText="Otro" onClick={onMenuClick}/>                        
                        : null
                            )}
                        /> 
                        
                    </PimsSubMenu>
                        <WithPermissions
                        render={({ permissions }) => (
                        atributos['seguridad']===1
                        ?<MenuItemLink to={`/seguridads`} primaryText="Sistemas de Contención Vehicular" leftIcon={<GradientIcon />} onClick={onMenuClick}/>         
                        : null
                            )}
                        />
                        <WithPermissions
                        render={({ permissions }) => (
                        atributos['drenaje']===1
                        ?<MenuItemLink to={`/elemento_drenajes`}  primaryText="Drenaje" leftIcon={<InvertColors/>} onClick={onMenuClick}/>
                        : null
                            )}
                        />
                        {/* <WithPermissions
                        render={({ permissions }) => (
                        atributos['senalizacion']===1
                        ?<MenuItemLink to={`/elemento_senalizacions`} primaryText="Señalización" leftIcon={<Report />} onClick={onMenuClick}/> 
                        : null
                            )}
                        /> */}
                        
                        <MenuItemLink to={`/elemento_senalizacion_perus`} primaryText="Señalización." leftIcon={<Report />} onClick={onMenuClick}/> 
                        <WithPermissions
                        render={({ permissions }) => (
                        atributos['alrededores']===1
                        ?<MenuItemLink to={`/elemento_alrededors`} primaryText="Alrededores" leftIcon={<FilterHdr/>} onClick={onMenuClick}/>
                        : null
                            )}
                        />
                        
                       
                        
                        <PimsSubMenu
                            handleToggle={() => this.handleToggle('menuConPonPu')}
                            isOpen={this.state.menuConPonPu}
                            sidebarIsOpen={open}
                            name="Puentes y estructuras especiales"
                            icon={<ConfirmationNumberIcon />}
                        >
                            <WithPermissions
                                render={({ permissions }) => (
                                atributos['tunel']===1
                                ?<MenuItemLink to={`/tuneles`} primaryText="Túneles" leftIcon={<PowerInputIcon />} onClick={onMenuClick}/> 
                                : null
                                    )}
                            />
                            
                            <WithPermissions
                                render={({ permissions }) => (
                                atributos['contencion']===1
                                ?<MenuItemLink to={`/elemento_contencions`} primaryText="Contención" leftIcon={<SatelliteIcon/>} onClick={onMenuClick}/>
                                : null
                                    )}
                            />
                            
                            <WithPermissions
                                render={({ permissions }) => (
                                atributos['ponton']===1
                                ?<MenuItemLink to={`/elemento_pontons`} primaryText="Pontón" leftIcon={<CropIcon/>} onClick={onMenuClick}/>
                                : null
                                    )}
                            />
                            
                            <WithPermissions
                                render={({ permissions }) => (
                                atributos['puentes']===1
                                ?<MenuItemLink to={`/puentes`} primaryText="Puentes" leftIcon={<PowerInputIcon />} onClick={onMenuClick}/> 
                                : null
                                    )}
                            />
                            
                        </PimsSubMenu>
                            <WithPermissions
                                render={({ permissions }) => (
                                atributos['otroElemento']===1
                                ?<MenuItemLink to={`/otros_elementos`} primaryText="Otros elementos" leftIcon={<DescriptionIcon/>} onClick={onMenuClick}/>                                          
                                : null
                                    )}
                            />
                            <WithPermissions
                                render={({ permissions }) => (
                                atributos['redes']===1
                                ?<MenuItemLink to={`/elemento_reds`} primaryText="Redes" leftIcon={<CropIcon/>} onClick={onMenuClick}/>
                                : null
                                    )}
                            />
                        </PimsSubMenu> 
                <PimsSubMenu
                    handleToggle={() => this.handleToggle('menutransito')}
                    isOpen={this.state.menutransito}
                    sidebarIsOpen={open}
                    name="Tránsito"
                    icon={<DirectionsTransitIcon />}
                >
                    <PimsSubMenu
                    handleToggle={() => this.handleToggle('menupeajes')}
                    isOpen={this.state.menupeajes}
                    sidebarIsOpen={open}
                    name="Peajes"
                    icon={<LocalConvenienceStoreIcon />}
                    >
                        <WithPermissions
                                render={({ permissions }) => (
                                atributos['inventario']===1
                                ?<MenuItemLink to={`/inventariopeajes`} primaryText="Inventario" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>
                                : null
                                    )}
                            />
                         
                         <WithPermissions
                                render={({ permissions }) => (
                                atributos['transitoMensual']===1
                                ?<MenuItemLink to={`/mensuals`} primaryText="Tránsito Histórico" /*leftIcon={<orders.icon />}*/ onClick={onMenuClick}/>         
                                : null
                                    )}
                            />
                         
                         <WithPermissions
                                render={({ permissions }) => (
                                atributos['colaDePeajea']===1
                                ?<MenuItemLink to={`/colajes`} primaryText="Cola de Peaje" /*leftIcon={<orders.icon />} */onClick={onMenuClick}/>
                                : null
                                    )}
                            />
                        
                                                                     
                        
                        
                    </PimsSubMenu>

                    <WithPermissions
                            render={({ permissions }) => (
                            atributos['inventarioa']===1
                            ?<MenuItemLink to={`/estacion_conteos`} primaryText="Estación de conteo" leftIcon={<ViewArrayIcon />} onClick={onMenuClick}/>
                            : null
                                )}
                        />
                     {/* <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuconteo')}
                    isOpen={this.state.menuconteo}
                    sidebarIsOpen={open}
                    name="Estación de Conteo"
                    icon={<HourglassEmptyIcon />} 
                    > */}
                        {/* <WithPermissions
                                render={({ permissions }) => (
                                atributos['transitoMensuala']===1
                                ?<MenuItemLink to={`/ejes`} primaryText="Ejes equivalentes"  onClick={onMenuClick}/>
                                : null
                                    )}
                            /> 
                        <WithPermissions
                                render={({ permissions }) => (
                                atributos['tasaCrecimiento']===1
                                ?<MenuItemLink to={`/tasas`} primaryText="Tasas de crecimiento" onClick={onMenuClick}/>
                                : null
                                    )}
                                />    */}                        
                        
                    {/* </PimsSubMenu>   */}
                </PimsSubMenu> 
                {/* <PimsSubMenu
                        handleToggle={() => this.handleToggle('menuIndicadores')}
                        isOpen={this.state.menuIndicadores}
                        sidebarIsOpen={open}
                        name="Indicadores"
                        icon={<BookIcon />}
                    >
                        <PimsSubMenu
                        handleToggle={() => this.handleToggle('menuIndicadores4G')}
                        isOpen={this.state.menuIndicadores4G}
                        sidebarIsOpen={open}
                        name="Indicadores 4G"
                        icon={<BookIcon />}
                    >
                        <MenuItemLink to={`/indicadores_cargar_informacions`} primaryText="Cargar Información" onClick={onMenuClick}/>
                    </PimsSubMenu>
                        <WithPermissions
                                render={({ permissions }) => (
                                atributos['datosBasicos']===1
                                ?<PimsSubMenu
                                handleToggle={() => this.handleToggle('menuDatosBasicos')}
                                isOpen={this.state.menuDatosBasicos}
                                sidebarIsOpen={open}
                                name="Datos Básicos"
                                icon={<BookIcon />}
                            >
                                <MenuItemLink to={`/medido_pors`} primaryText="Medido Por" onClick={onMenuClick}/>
                                <MenuItemLink to={`/estado_indicadors`} primaryText="Estado" onClick={onMenuClick}/>
                                <MenuItemLink to={`/tipo_dominios`} primaryText="Tipo de Dominio" onClick={onMenuClick}/>
                                <MenuItemLink to={`/fisura_indicadors`} primaryText="Fisura" onClick={onMenuClick}/>
                                <MenuItemLink to={`/calificacion_indicadors`} primaryText="Calificación" onClick={onMenuClick}/>
                                <MenuItemLink to={`/evaluacion_indicadors`} primaryText="Evaluación" onClick={onMenuClick}/>
                                <MenuItemLink to={`/niveles_indicadors`} primaryText="Niveles" onClick={onMenuClick}/>                                                        
                            </PimsSubMenu>
                                : null
                                    )}
                            />
                        
                        <WithPermissions
                                render={({ permissions }) => (
                                atributos['colaDePeaje']===1
                                ?<MenuItemLink to={`/deterioro_detalles`} primaryText="Deterioros" onClick={onMenuClick}/>  
                                : null
                                    )}
                            />
                        <WithPermissions
                                render={({ permissions }) => (
                                atributos['calificacionUentes']===1
                                ?<MenuItemLink to={`/calificacions`} primaryText="Calificación Puentes" onClick={onMenuClick}/>
                                : null
                                    )}
                            />
                        <WithPermissions
                                render={({ permissions }) => (
                                atributos['calificacionPontones']===1
                                ?<MenuItemLink to={`/calificacion_pontons`} primaryText="Calificación Pontones" onClick={onMenuClick}/>                      
                                : null
                                    )}
                            />
                        <WithPermissions
                                render={({ permissions }) => (
                                atributos['disponibilidadVia']===1
                                ?<MenuItemLink to={`/disponibilidad_vias`} primaryText="Disponibilidad de Vía" /*leftIcon={<orders.icon />} onClick={onMenuClick}/>            
                                : null
                                    )}
                            />
                        
                </PimsSubMenu>
                */}
                <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuIndicadoresP')}
                    isOpen={this.state.menuIndicadoresP}
                    sidebarIsOpen={open}
                    name="Indicadores."
                    icon={<BookIcon />}
                >
                        {/* <PimsSubMenu
                            handleToggle={() => this.handleToggle('menuDatosBasicosP')}
                            isOpen={this.state.menuDatosBasicosP}
                            sidebarIsOpen={open}
                            name="Datos Básicos"
                            icon={<BookIcon />}
                        >                            
                            <MenuItemLink to={`/colores`} primaryText="Colores" onClick={onMenuClick}/>
                            
                        </PimsSubMenu>                         */}
                        <PimsSubMenu
                            handleToggle={() => this.handleToggle('menuEvaluación')}
                            isOpen={this.state.menuEvaluación}
                            sidebarIsOpen={open}
                            name="Evaluación"
                            icon={<AssignmentTurnedInIcon />}
                        >
                            <MenuItemLink to={`/evaluacion_seguridads`} primaryText="Señalización" onClick={onMenuClick}/>  
                        </PimsSubMenu>
                </PimsSubMenu>
                <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuGestion')}
                    isOpen={this.state.menuGestion}
                    sidebarIsOpen={open}
                    name="Gestión"
                    icon={<TouchAppIcon />}
                >
                    <PimsSubMenu
                    handleToggle={() => this.handleToggle('menuprogramacion')}
                    isOpen={this.state.menuprogramacion}
                    sidebarIsOpen={open}
                    name="Programación"
                    icon={<LoyaltyIcon />}
                    >
                          <MenuItemLink to={`/parametros_analises`} primaryText="Parámetros de planeación" leftIcon={<TimelineIcon />} onClick={onMenuClick}/>  
                        

                        <PimsSubMenu
                            handleToggle={() => this.handleToggle('otrosElementos')}
                            isOpen={this.state.otrosElementos}
                            sidebarIsOpen={open}
                            name="Otros Sistemas"
                            icon={<AssignmentIcon />}
                        >
                            <MenuItemLink to={`/politicas_mantenimientos`} primaryText="Políticas Generales" onClick={onMenuClick}/>  
                            <MenuItemLink to={`/politicas_mantenimiento_particulars`} primaryText="Políticas Particulares" onClick={onMenuClick}/>  

                        </PimsSubMenu>

                        <PimsSubMenu
                            handleToggle={() => this.handleToggle('menupavimento2')}
                            isOpen={this.state.menupavimento2}
                            sidebarIsOpen={open}
                            name="Pavimentos - PERS"
                            icon={<ViewHeadlineIcon />}
                        >
                            <MenuItemLink to={`/trayectorias`} primaryText="Alternativas de intervención" onClick={onMenuClick}/>
                            <MenuItemLink to={`/modelo_costos_pers`} primaryText="Reparaciones Menores" onClick={onMenuClick}/>                                                                    
                            <MenuItemLink to={`/pers_exports`} primaryText="Exportar base pers" onClick={onMenuClick}/>  
                            <MenuItemLink to={``} primaryText="Reportes pavimentos" onClick={onMenuClick}/>  
                        </PimsSubMenu>
                    </PimsSubMenu>            


                   <PimsSubMenu
                        handleToggle={() => this.handleToggle('menucostosReg')}
                        isOpen={this.state.menucostosReg}
                        sidebarIsOpen={open}
                        name="Registro de costos"
                        icon={<ViewHeadlineIcon />}
                    >
                         {/* <MenuItemLink to={`/trayectorias`} primaryText="Alternativas de intervención" onClick={onMenuClick}/>  */}
                    </PimsSubMenu>

                    <MenuItemLink to={`/flujo_cajas`} primaryText="Flujo de Caja" leftIcon={<ViewHeadlineIcon />} onClick={onMenuClick}/> 
                </PimsSubMenu>
                
                
                         
                {logout}
            </div>
            
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    /*theme: state.theme,
    locale: state.i18n.locale,*/
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
);

export default enhance(PimsMenu);


import React from 'react';
import { List, Datagrid, TextField, SelectField, EditButton, ShowButton, Filter, ReferenceInput, SelectInput } from 'react-admin';
import { PField } from './../../fields/PField';
import { WithPermissions, BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

// var rutas = localStorage.getItem("rutas");

const UnidadFuncionalFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Categoría" source="categoriaRuta" choices={[
            { id: 1, name: 'Primer orden' },
            { id: 2, name: 'Segundo orden' },
            { id: 3, name: 'Tercer orden' },
         ]}  alwaysOn           
        />
    </Filter>
);


const PostBulkActionButtons = props => {

    var rutas = localStorage.getItem("rutas");

    return(

    <WithPermissions
            render={({ permissions }) => (
                 "1" === rutas                 
        ? 
        <Fragment>
            <BulkDeleteWithConfirmButton {...props}/>
        </Fragment>
        : null
        )}
    />

    )};
    
    const PostActions =({basePath,
        currentSort,    
        exporter,    
        filterValues,   
        resource,    
        total})=>{
    var rutas = localStorage.getItem("rutas");
    return(
            <WithPermissions
            render={({ permissions }) => (
                "1" === rutas   ||    "2" === rutas           
        ? 
        <CardActions>
            <CreateButton basePath={basePath} />
            <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        </CardActions>
        

        :  <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
    />
        )}
        />
        
    )
};


export const RutaList = props => (
    <List {...props}  sort={{ field: "updatedAt", order: 'DESC' }}  filters={<UnidadFuncionalFilter/>} actions={<PostActions/>}  bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>            
            <TextField label="Código Ruta" source="codigo" />
            <SelectField label="Categoría" source="categoriaRuta" 
                choices={[
                    { id: 1, name: 'Primer orden' },
                    { id: 2, name: 'Segundo orden' },
                    { id: 3, name: 'Tercer orden' },
                ]} 
            />
            <PField label="PR inicio" source="pInicio"/>
            <PField label="PR fin"  source="pFin"/>
            <TextField source="tramo" />  
            <ShowButton />
            {
                localStorage.getItem('rutas') === "1" || localStorage.getItem('rutas') === "2" ?   <EditButton/> : null
            }               
        </Datagrid>
    </List>
);

const mapStateToProps = 
    state => 
        ({ 
        proyecto: state.proyecto, 
        ruta: state.ruta,
        unidadFuncional: state.unidadFuncional,
    });

export default connect(mapStateToProps, {})(RutaList);
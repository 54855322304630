import React from 'react';
import { SimpleForm} from 'react-admin';
import { Edit } from './../../Edit';
import { withStyles, Grid } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import {ComponenteDeterioroPers} from '../componentes/ComponeteReparacionPers';

export const ReparacionPersEdit = withStyles(styles)(({ classes, ...props }) => {
    return (
        <Edit {...props} undoable={false} title='Editar Reparación PERS' >
            <SimpleForm redirect="list" >
                    <ComponenteDeterioroPers/>
            </SimpleForm>
        </Edit>
    )
});
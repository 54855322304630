import React, { useState, Fragment } from 'react';
import {
    SelectInput
} from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../../EstilosGrid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import config from '../../../config/config';
let urlAppjs = config.UrlServer;
let frontAppjs = config.UrlFront;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class AddElement extends React.Component {
    constructor(props) {
        super(props)
        this.stagedProps = {
            parentProps : this.props.parentProps
        }
        this.state = {
            excel : [],
            excelMountCount : 0,
            isExcelLoaded : false,
            isDialogOpen : false,
            disableSelect : false,
        }
        this.fetcher.bind(this);
        this.returnMatrix.bind(this);
        this.generarmatriz.bind(this);
        this.generarExcel.bind(this);
    }

    componentDidMount() {
        // console.log(window.location.href.split("#")[1].split("/")[1]);
        if (typeof this.props.parentProps !== 'undefined') {
            // console.log(this.props.parentProps);
            this.fetcher("matriz/consultar/NULL/" + this.props.parentProps.originId.split("/")[2] );
            // setTimeout(()=> {window.location.reload(false)}, 60000);
            setTimeout(()=> {this.componentDidMount()}, 60000);
            
        }
    }

    fetcher(target, filters = null){
        this.setState({isExcelLoaded : false});
        var urlPart = "/" + target ;
        if(Array.isArray(filters)) {
            filters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if(filters !== null) {
            urlPart = urlPart + "&" + filters;
        }

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.state["excel"] = Elementos;
                this.setState({isExcelLoaded : true});
            })
    }

    generarmatriz(){
        var target = 'generarmatriz/{"uf" : "'+this.props.parentProps.unidadFuncional.split("/")[2]+'","nombre": "'+this.props.parentProps.nombrePolitica.split("/")[2]+'" , "flujoCaja": "'+this.props.parentProps.originId.split("/")[2]+'"}';
        const reload = () => {
            window.location.reload(false);
        }
        var urlPart = "/" + target ;
        
        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                reload();
            })
    }

    generarExcel(id) {
        console.log(id);
        const reload = () => {
            window.location.reload(false);
        }
        if(id !==undefined) {
            var urlPart = "/matriz/generar/" + id + "/NULL" ;
            fetch(urlAppjs + urlPart,
                {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(()=> {
                    alert("El portal se recargará periodicamente para mostrar el estado de la generación, aunque tambien puede recargar automáticamente.");
                    reload();
                })
        }
        
    }
    
    returnMatrix(classes) {
        
        var matrix =
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.TbCellCol1}>ID</TableCell>
                            <TableCell className={classes.TbCellCol1}>ID Flujo de Caja</TableCell>
                            <TableCell className={classes.TbCellCol3}>Fecha</TableCell>
                            <TableCell className={classes.TbCellCol3}>Status</TableCell>
                            <TableCell className={classes.TbCellCol3}>Link de Descarga</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            {this.state.excel.map((index) => (
                                <TableRow>
                                    <TableCell className={classes.TbCellCol1}>{index.id}</TableCell>
                                    <TableCell className={classes.TbCellCol1}>{index.flujo_caja_id}</TableCell>
                                    <TableCell className={classes.TbCellCol3}>{index.fecha_emision}</TableCell>
                                    <TableCell className={classes.TbCellCol3}>{index.status}</TableCell>
                                    <TableCell className={classes.TbCellCol3}>
                                        {index.xls ==="-" &&
                                            index.xls
                                        }
                                        {index.xls !=="-" &&
                                            <a href={urlAppjs + "/" + index.xls}>{index.xls}</a>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                </Table>

        return (matrix)

    }
    render() {
        var choices = [];
        console.log(this.state.excel);
        if(!this.state.excel.hasOwnProperty("recordId")){
            this.state.excel.map((index) => {
                if (index.xls == "-" && index.status == "Matriz Generada") {
                    choices.push({ 
                        id: index.id, 
                        name: index.id 
                    })
                }
            })
        }
        const { classes } = this.props;
        // console.log(this.state.excel);
        
        const handleClickOpen = () => {
            this.setState({isDialogOpen : true});
        };
        const handleClose = () => {
            this.setState({isDialogOpen : false});
        };
        const handleFetch = () => {
            this.generarmatriz();
            this.setState({
                isDialogOpen : false,
                excelMountCount : 0
            });
        };
        const dialog =  (buttonText, header, msg) => {
            return (
              <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                  {buttonText}
                </Button>
                <Dialog
                  open={this.state.isDialogOpen}
                  onClose={handleClose}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {header}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {msg}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                      No
                    </Button>
                    <Button onClick={handleFetch} color="primary">
                      Si
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            );
          }
        const isDisabled = (id) => {
            if(id != undefined) {
                this.setState({
                    disableSelect : true
                })
            } else {
                this.setState({
                    disableSelect : false
                })
            }
        }
        if(this.state.isExcelLoaded == true && this.state.excel.length > 0 ) {
            return (
                <Fragment>
                    <Grid container alignItems="flex-end" spacing={24}>
                        <Grid item xs={6}>
                            <Typography component="h4">
                                La matriz se está recargando periodicamente para actualizar los datos.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput 
                                label="Generar Excel Para:" 
                                choices={choices}
                                disabled = {this.state.disableSelect}
                                fullWidth
                                validate={this.generarExcel}
                                />
                        </Grid>
                    </Grid>
                    <Divider fullWidth/>
                    {this.returnMatrix(classes, this)}
                    <Divider />
                    <div style={{textAlign : "right", width : "100%"}}>
                        {dialog("Generar Nueva Matriz", "¿Generar Nueva Matriz?", "¿Seguro que desea generar una nueva matriz?")}
                    </div>
                </Fragment>
                );
        } else if(this.state.isExcelLoaded == true && this.state.excel.length == 0) {
            return (
                <div style={{textAlign : "center", width : "100%"}}>
                    <Typography component="h4">
                        Sin resultados.
                    </Typography>
                    {dialog("Generar Nueva Matriz", "¿Generar Nueva Matriz?", "¿Seguro que desea generar una nueva matriz?")}
                </div>
            )
        } else {
            return (
                <div style={{textAlign : "center", width : "100%"}}>
                    <br/>
                   <LinearProgress variant="indeterminate" />
                    {/* <Typography component="h4">
                        Cargando...
                    </Typography> */}
                </div>
            )
        }

    }
}

AddElement.propTypes = {
    classes: PropTypes.object.isRequired,
}
const AddFlujoCajaDetalles = withStyles(styles)(AddElement);


export default AddFlujoCajaDetalles;
import React from 'react';
import { TextField, NumberField, BooleanField, SelectField } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

class TablaTuneles extends React.Component {

  constructor(props) {
    super(props)
    this.state = { TablaEstructura: [] }


  }

  componentWillMount() {
    var urlApi = urlAppjs + "/" + this.props.tabla + "/" + this.props.id;

    fetch(urlApi)
      .then((response) => {
        return response.json()
      })
      .then((TablaEstructura) => {
        this.setState({ TablaEstructura: TablaEstructura })
      })
  }

  render() {
    let Datos = Object.values(this.state.TablaEstructura);

    console.log(Datos);
    if (Datos.length > 0) {
      return (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Costado</TableCell>
              <TableCell align="right">Cantidad</TableCell>
              <TableCell align="right">Separación (m)</TableCell>
              <TableCell align="right">Longitud (m)</TableCell>
              <TableCell align="right">Ancho (m)</TableCell>
              <TableCell align="right">Altura (m)</TableCell>
              <TableCell align="right">Pendiente (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell component="th"  >
                Bahías de parqueo
              </TableCell>
              <TableCell align="right">
                {Datos[22]}
              </TableCell>
              <TableCell align="right">
                {Datos[23]}
              </TableCell>
              <TableCell align="right">
                {Datos[24]}
              </TableCell>
              <TableCell align="right">
                {Datos[25]}
              </TableCell>
              <TableCell align="right">
                {Datos[26]}
              </TableCell>
              <TableCell align="right">
                {Datos[27]}
              </TableCell>
              <TableCell align="right">
                {Datos[28]}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                Carril de emergencia
              </TableCell>
              <TableCell align="right">
                {Datos[29]}
              </TableCell>
              <TableCell align="right">
                {Datos[30]}
              </TableCell>
              <TableCell align="right">
                {Datos[31]}
              </TableCell>
              <TableCell align="right">
                {Datos[32]}
              </TableCell>
              <TableCell align="right">
                {Datos[33]}
              </TableCell>
              <TableCell align="right">
                {Datos[34]}
              </TableCell>
              <TableCell align="right">
                {Datos[35]}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                Galerías de evacuación
            </TableCell>
              <TableCell align="right">
                {Datos[36]}
              </TableCell>
              <TableCell align="right">
                {Datos[37]}
              </TableCell>
              <TableCell align="right">
                {Datos[38]}
              </TableCell>
              <TableCell align="right">
                {Datos[39]}
              </TableCell>
              <TableCell align="right">
                {Datos[40]}
              </TableCell>
              <TableCell align="right">
                {Datos[41]}
              </TableCell>
              <TableCell align="right">
                {Datos[42]}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                Nicho de Emergencia S.O.S
            </TableCell>
              <TableCell align="right">
                {Datos[43]}
              </TableCell>
              <TableCell align="right">
                {Datos[44]}
              </TableCell>
              <TableCell align="right">
                {Datos[45]}
              </TableCell>
              <TableCell align="right">
                {Datos[46]}
              </TableCell>
              <TableCell align="right">
                {Datos[47]}
              </TableCell>
              <TableCell align="right">
                {Datos[48]}
              </TableCell>
              <TableCell align="right">
                {Datos[49]}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                Nicho de Control de incendios
              </TableCell>
              <TableCell align="right">
                {Datos[50]}
              </TableCell>
              <TableCell align="right">
                {Datos[51]}
              </TableCell>
              <TableCell align="right">
                {Datos[52]}
              </TableCell>
              <TableCell align="right">
                {Datos[53]}
              </TableCell>
              <TableCell align="right">
                {Datos[54]}
              </TableCell>
              <TableCell align="right">
                {Datos[55]}
              </TableCell>
              <TableCell align="right">
                {Datos[56]}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                Nicho de Equipos eléctricos
              </TableCell>
              <TableCell align="right">
                {Datos[57]}
              </TableCell>
              <TableCell align="right">
                {Datos[58]}
              </TableCell>
              <TableCell align="right">
                {Datos[59]}
              </TableCell>
              <TableCell align="right">
                {Datos[60]}
              </TableCell>
              <TableCell align="right">
                {Datos[61]}
              </TableCell>
              <TableCell align="right">
                {Datos[62]}
              </TableCell>
              <TableCell align="right">
                {Datos[63]}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    } else {
      return (
        <p className="text-center">Cargando Tabla...</p>
      )
    }

  }
}

export default TablaTuneles;
import React, { useState } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, TextField, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PimsActionsWithoutImage } from './../../../helpers/PimsActionsWithoutImage';
import { CurrencyInput } from './../../../inputs/CurrencyInput'
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleModalWrapped2 from './../localHelpers/SimpleModalWrapped2'
import config from '../../../config/config';
let urlAppjs = config.UrlFront;
let urlBack = config.UrlServer;

export const PoliticasMantenimientoCreate = withStyles(styles)(({ classes, ...props }) => {

    const [results, setResults] = useState([]);
    var result = [];
    if(results.length == 0) {
        fetch(urlBack + "/sistemas.json?pagination=off?visibleOnPolicies=1",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((unidades) => {
                unidades.map(item => {
                    if(item.nombre == "Seguridad") {
                        result.push({
                            id: "/sistemas/" + item.id,
                            name : "Sistemas de Contención"
                        })
                    } else {
                        result.push({
                            id: "/sistemas/" + item.id,
                            name: item.nombre
                        })
                    }
                })
            })
            .then(()=> {
                setResults(result.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
                console.log(results);
            });
    }

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    const [sistema, setSistema] = useState();
    const [elemento, setElemento] = useState();
    const [payload, setPayload] = useState({});
    const regSistema = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        if (valor.split("/")[2]) {
            valor = valor.split("/")[2]
        }
        setSistema(valor);
        setPayload({
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'nombre', order: 'ASC' },
            filter: { 
                sistema: "/sistemas/" + valor,
                visibleOnPolicies : 1
            }
        })
        setElemento("tipo_elementos");
    }

    const diligenciadoRutinario = (value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if((today.getMonth() +1) < 10){
            month =  "0"+(today.getMonth() +1)
        } else {
            month =  (today.getMonth() +1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if(typeof value !== 'undefined'){
            for (var i=0; i<value.length; i++) {
                if(value[i].seRealiza == true) {

                    if(value[i].periocidad == null   || value[i].periocidad == "" ||
                        value[i].valorU == null      || value[i].valorU == ""     ||
                        value[i].fuente == null      || value[i].fuente == ""     ||
                        value[i].fecha == null       || value[i].fecha == ""      ||
                        value[i].descripcion == null || value[i].descripcion == ""                    
                    ) {
                        errorMsg.push("Faltan datos de " + 
                                    value[i].nombreElemento + 
                                    ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push("La fecha indicada en " + 
                                    value[i].nombreElemento + " " +
                                    "no puede ser futura. ");
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i=0; i<errorMsg.length; i++) {
                returnMsg = returnMsg + (i+1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    }

    const diligenciadoPeriodico = (value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if((today.getMonth() +1) < 10){
            month =  "0"+(today.getMonth() +1)
        } else {
            month =  (today.getMonth() +1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if(typeof value !== 'undefined'){
            for (var i=0; i<value.length; i++) {
                if(value[i].seRealiza == true) {

                    if(value[i].periocidad == null   || value[i].periocidad == "" ||
                        value[i].valorU == null      || value[i].valorU == ""     ||
                        value[i].fuente == null      || value[i].fuente == ""     ||
                        value[i].fecha == null       || value[i].fecha == ""      ||
                        value[i].descripcion == null || value[i].descripcion == ""                    
                    ) {
                        errorMsg.push("Faltan datos de " + 
                                    value[i].nombreElemento + 
                                    ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push("La fecha indicada en " + 
                                    value[i].nombreElemento + " " +
                                    "no puede ser futura. ");
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i=0; i<errorMsg.length; i++) {
                returnMsg = returnMsg + (i+1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    }

    const diligenciadoRehabilitacion = (value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if((today.getMonth() +1) < 10){
            month =  "0"+(today.getMonth() +1)
        } else {
            month =  (today.getMonth() +1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if(typeof value !== 'undefined'){
            for (var i=0; i<value.length; i++) {
                if(value[i].seRealiza == true) {

                    if(value[i].periocidad == null   || value[i].periocidad == "" ||
                        value[i].valorU == null      || value[i].valorU == ""     ||
                        value[i].fuente == null      || value[i].fuente == ""     ||
                        value[i].fecha == null       || value[i].fecha == ""      ||
                        value[i].descripcion == null || value[i].descripcion == ""                    
                    ) {
                        errorMsg.push("Faltan datos de " + 
                                    value[i].nombreElemento + 
                                    ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push("La fecha indicada en " + 
                                    value[i].nombreElemento + " " +
                                    "no puede ser futura. ");
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i=0; i<errorMsg.length; i++) {
                returnMsg = returnMsg + (i+1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    }

    const diligenciadoCambio = (value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if((today.getMonth() +1) < 10){
            month =  "0"+(today.getMonth() +1)
        } else {
            month =  (today.getMonth() +1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if(typeof value !== 'undefined'){
            for (var i=0; i<value.length; i++) {
                if(value[i].seRealiza == true) {

                    if(value[i].periocidad == null   || value[i].periocidad == "" ||
                        value[i].valorU == null      || value[i].valorU == ""     ||
                        value[i].fuente == null      || value[i].fuente == ""     ||
                        value[i].fecha == null       || value[i].fecha == ""      ||
                        value[i].descripcion == null || value[i].descripcion == ""                    
                    ) {
                        errorMsg.push("Faltan datos de " + 
                                    value[i].nombreElemento + 
                                    ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push("La fecha indicada en " + 
                                    value[i].nombreElemento + " " +
                                    "no puede ser futura. ");
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i=0; i<errorMsg.length; i++) {
                returnMsg = returnMsg + (i+1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    }



    const [validations, setValidations] = useState()

    return (
        <PimsCreate  {...props} title="Crear Política de Mantenimiento" actions={<PimsActionsWithoutImage />} >
            <TabbedForm 
                redirect="list" 
                //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE MANTENIMIENTO ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                    <TextInput
                        label="tipo"
                        source="tipo"
                        validate={[required()]}
                        defaultValue="normal"
                        formClassName={classes.ocultar}
                    />
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={7}>
                            <ReferenceInput
                                label="Nombre de la Política"
                                source="nombre"
                                reference="politicas_nombres"
                                validate={[required()]}
                                fullWidth
                            >
                                <SelectInput optionText="nombre" fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={1}>
                            <SimpleModalWrapped2 />
                        </Grid>
                        
                        <Grid item xs={4}>
                            <SelectInput
                                label="Sistema"
                                source="sistema"
                                choices={results}
                                onChange={regSistema}
                                validate={[required()]}
                                fullWidth
                            />
                            {/* <ReferenceInput
                                label="Sistema"
                                source="sistema"
                                reference="sistemas"
                                onChange={regSistema}
                                filter={{
                                    visibleOnPolicies : 1
                                }}
                                validate={[required()]}
                                fullWidth
                            >
                                <SelectInput optionText="nombre" fullWidth />
                            </ReferenceInput> */}
                        </Grid>


                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            {sistema &&
                                <div className={classes.fieldCol12}>
                                    <ExpansionPanel>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography >Elementos para Mantenimiento Rutinario</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Query type="GET_LIST" resource={elemento} payload={payload}>
                                                {({ data, loading, error }) => {
                                                    if (loading) { return <Loading />; }
                                                    if (error) { return error; }
                                                    var defaultValues = [];
                                                    var datos = [];
                                                    {
                                                        data.map(index => {
                                                            datos = {
                                                                sistema: "/sistemas/" + sistema,
                                                                 
                                                                tipoElemento: index.id,
                                                                nombreElemento: index.nombre,
                                                                seRealiza: false,
                                                                unidadMedida: index.unidadMedida,
                                                                periocidad: null,
                                                                valorU: null,
                                                                fuente: null,
                                                                fecha: null,
                                                                descripcion: null
                                                            }
                                                            defaultValues.push(datos)
                                                        })
                                                    }
                                                    return (
                                                        <ArrayInput
                                                            label=" "
                                                            source="politicasElementos"
                                                            validate={[diligenciadoRutinario]}
                                                            defaultValue={defaultValues}
                                                        >
                                                            <SimpleFormIterator disableAdd disableRemove>
                                                                <TextInput disabled source="tipoMantenimiento" fullWidth formClassName={classes.ocultar} />

                                                                <TextInput disabled label="sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                <TextInput disabled label="tipoElemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                                                                <BooleanInput label="Se Realiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} />
                                                                <br />
                                                                <ReferenceInput disabled label="Unidad" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol1} >
                                                                    <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                </ReferenceInput>
                                                                <AutocompleteInput 
                                                                    label="Periodicidad" 
                                                                    source="periocidad" 
                                                                    fullWidth 
                                                                    options={{fullWidth: true}}
                                                                    choices={[
                                                                            { id: 1, name: '1 Mes' },
                                                                            { id: 3, name: '3 Meses' },
                                                                            { id: 6, name: '6 Meses' },
                                                                            { id: 9, name: '9 Meses' },
                                                                            { id: 12, name: '12 Meses' },
                                                                            { id: 18, name: '18 Meses' },
                                                                        ]} 
                                                                    formClassName={classes.fieldCol2}
                                                                />
                                                                <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} />
                                                                <TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                                                                <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />
                                                                <br />
                                                                <TextInput label="Descripción" source="descripcion" fullWidth formClassName={classes.fieldCol12} />
                                                            </SimpleFormIterator>
                                                        </ArrayInput>
                                                    );
                                                }}
                                            </Query>

                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography >Elementos para Mantenimiento Periódico</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Query type="GET_LIST" resource={elemento} payload={payload}>
                                                {({ data, loading, error }) => {
                                                    if (loading) { return <Loading />; }
                                                    if (error) { return error; }
                                                    // return <div>{console.log(data)}</div>;
                                                    var defaultValues = [];
                                                    var datos = [];
                                                    {
                                                        data.map(index => {
                                                            datos = {
                                                                sistema: "/sistemas/" + sistema,
                                                                 
                                                                tipoElemento: index.id,
                                                                nombreElemento: index.nombre,
                                                                seRealiza: false,
                                                                unidadMedida: index.unidadMedida,
                                                                periocidad: null,
                                                                valorU: null,
                                                                fuente: null,
                                                                fecha: null,
                                                                descripcion: null
                                                            }
                                                            defaultValues.push(datos)
                                                        })
                                                    }
                                                    return (
                                                        <ArrayInput
                                                            label=" "
                                                            source="politicasElementoPeriodicos"
                                                            validate={[diligenciadoPeriodico]}
                                                            defaultValue={defaultValues}
                                                        >
                                                            <SimpleFormIterator disableAdd disableRemove>
                                                                <TextInput disabled label="sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                <TextInput disabled label="tipoElemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                                                                <BooleanInput label="Se Realiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} />
                                                                <br />
                                                                <ReferenceInput disabled label="Unidad" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol1} >
                                                                    <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                </ReferenceInput>
                                                                <AutocompleteInput 
                                                                    label="Periodicidad" 
                                                                    source="periocidad" 
                                                                    fullWidth 
                                                                    options={{fullWidth: true}}
                                                                    choices={[
                                                                            { id: 1, name: '1 Mes' },
                                                                            { id: 3, name: '3 Meses' },
                                                                            { id: 6, name: '6 Meses' },
                                                                            { id: 9, name: '9 Meses' },
                                                                            { id: 12, name: '12 Meses' },
                                                                            { id: 18, name: '18 Meses' },
                                                                            { id: 24, name: '24 Meses' },
                                                                            { id: 36, name: '36 Meses' },
                                                                        ]} 
                                                                    formClassName={classes.fieldCol2}
                                                                />
                                                                <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} />
                                                                <TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                                                                <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />
                                                                <br />
                                                                <TextInput label="Descripción" source="descripcion" fullWidth formClassName={classes.fieldCol12} />
                                                            </SimpleFormIterator>
                                                        </ArrayInput>
                                                    );
                                                }}
                                            </Query>

                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography >Elementos para Mantenimiento Rehabilitación</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Query type="GET_LIST" resource={elemento} payload={payload}>
                                                {({ data, loading, error }) => {
                                                    if (loading) { return <Loading />; }
                                                    if (error) { return error; }
                                                    // return <div>{console.log(data)}</div>;
                                                    var defaultValues = [];
                                                    var datos = [];
                                                    {
                                                        data.map(index => {
                                                            datos = {
                                                                sistema: "/sistemas/" + sistema,
                                                                 
                                                                tipoElemento: index.id,
                                                                nombreElemento: index.nombre,
                                                                seRealiza: false,
                                                                unidadMedida: index.unidadMedida,
                                                                periocidad: null,
                                                                valorU: null,
                                                                fuente: null,
                                                                fecha: null,
                                                                descripcion: null
                                                            }
                                                            defaultValues.push(datos)
                                                        })
                                                    }
                                                    return (
                                                        <ArrayInput
                                                            label=" "
                                                            source="politicasElementoRehabilitacions"
                                                            validate={[diligenciadoRehabilitacion]}
                                                            defaultValue={defaultValues}
                                                        >
                                                            <SimpleFormIterator disableAdd disableRemove>
                                                                <TextInput disabled label="sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                <TextInput disabled label="tipoElemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                                                                <BooleanInput label="Se Realiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} />
                                                                <br />
                                                                <ReferenceInput disabled label="Unidad" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol1}>
                                                                    <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                </ReferenceInput>
                                                                <AutocompleteInput 
                                                                    label="Periodicidad" 
                                                                    source="periocidad" 
                                                                    fullWidth 
                                                                    options={{fullWidth: true}}
                                                                    choices={[
                                                                            { id: 2, name: '2 Años' }, { id: 2.5, name: '2,5 Años' },
                                                                            { id: 3, name: '3 Años' }, { id: 3.5, name: '3,5 Años' },
                                                                            { id: 4, name: '4 Años' }, { id: 4.5, name: '4,5 Años' },
                                                                            { id: 5, name: '5 Años' }, { id: 5.5, name: '5,5 Años' },
                                                                            { id: 6, name: '6 Años' }, { id: 6.5, name: '6,5 Años' },
                                                                            { id: 7, name: '7 Años' }, { id: 7.5, name: '7,5 Años' },
                                                                            { id: 8, name: '8 Años' }, { id: 8.5, name: '8,5 Años' },
                                                                            { id: 9, name: '9 Años' }, { id: 9.5, name: '9,5 Años' },
                                                                            { id: 10, name: '10 Años'}
                                                                        ]} 
                                                                    formClassName={classes.fieldCol2}
                                                                />
                                                                <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />
                                                                <TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />
                                                                <NumberInput label="Vida Útil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol2} />
                                                                <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />
                                                                <br />
                                                                <TextInput label="Descripción" source="descripcion" fullWidth formClassName={classes.fieldCol12} />
                                                            </SimpleFormIterator>
                                                        </ArrayInput>
                                                    );
                                                }}
                                            </Query>

                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography >Elementos para Mantenimiento Reconstrucción/Cambio</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Query type="GET_LIST" resource={elemento} payload={payload}>
                                                {({ data, loading, error }) => {
                                                    if (loading) { return <Loading />; }
                                                    if (error) { return error; }
                                                    // return <div>{console.log(data)}</div>;
                                                    var defaultValues = [];
                                                    var datos = [];
                                                    
                                                    {
                                                        data.map(index => {
                                                            datos = {
                                                                sistema: "/sistemas/" + sistema,
                                                                 
                                                                tipoElemento: index.id,
                                                                nombreElemento: index.nombre,
                                                                seRealiza: false,
                                                                unidadMedida: index.unidadMedida,
                                                                periocidad: null,
                                                                valorU: null,
                                                                fuente: null,
                                                                fecha: null,
                                                                descripcion: null
                                                            }
                                                            defaultValues.push(datos)
                                                        })
                                                    }
                                                    return (
                                                        <ArrayInput
                                                            label=" "
                                                            source="politicasElementoCambios"
                                                            validate={[diligenciadoCambio]}
                                                            defaultValue={defaultValues}
                                                        >
                                                            <SimpleFormIterator disableAdd disableRemove>
                                                                <TextInput disabled label="sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                <TextInput disabled label="tipoElemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                                                                <BooleanInput label="Se Realiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} />
                                                                <br />
                                                                <SelectInput label="Tipo de Operación" source="operacion" fullWidth formClassName={classes.fieldCol12} choices={[
                                                                    { id: 'reconstruccion', name: 'Reconstrucción' },
                                                                    { id: 'cambio', name: 'Cambio' }
                                                                ]} defaultValue="cambio"
                                                                formClassName={classes.fieldCol3}
                                                                />
                                                                <br />
                                                                <ReferenceInput disabled label="Unidad" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol1}>
                                                                    <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                </ReferenceInput>

                                                                <AutocompleteInput 
                                                                    label="Periodicidad" 
                                                                    source="periocidad" 
                                                                    fullWidth 
                                                                    options={{fullWidth: true}}
                                                                    formClassName={classes.fieldCol12}
                                                                    choices={[
                                                                            { id: 3, name: '3 Años' },   { id: 4, name: '4 Años' },   { id: 5, name: '5 Años' },
                                                                            { id: 6, name: '6 Años' },   { id: 7, name: '7 Años' },   { id: 8, name: '8 Años' },
                                                                            { id: 9, name: '9 Años' },   { id: 10, name: '10 Años' }, { id: 11, name: '11 Años' },
                                                                            { id: 12, name: '12 Años' }, { id: 13, name: '13 Años' }, { id: 14, name: '14 Años' },
                                                                            { id: 15, name: '15 Años' }, { id: 16, name: '16 Años' }, { id: 17, name: '17 Años' },
                                                                            { id: 18, name: '18 Años' }, { id: 19, name: '19 Años' }, { id: 20, name: '20 Años' },
                                                                            { id: 21, name: '21 Años' }, { id: 22, name: '22 Años' }, { id: 23, name: '23 Años' },
                                                                            { id: 24, name: '24 Años' }, { id: 25, name: '25 Años' }, { id: 26, name: '26 Años' },
                                                                            { id: 27, name: '27 Años' }, { id: 28, name: '28 Años' }, { id: 29, name: '29 Años' },
                                                                            { id: 30, name: '30 Años' }
                                                                        ]} 
                                                                    formClassName={classes.fieldCol2}
                                                                />                                                                <CurrencyInput label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />
                                                                <TextInput label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />
                                                                <NumberInput label="Vida Útil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol2} />
                                                                <DateInput label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />
                                                                <br />
                                                                <TextInput label="Descripción" source="descripcion" fullWidth formClassName={classes.fieldCol12} />

                                                            </SimpleFormIterator>
                                                        </ArrayInput>
                                                    );
                                                }}
                                            </Query>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

});

import React, {useState, Fragment} from 'react';
import { SimpleForm, TabbedForm, FormTab, SelectInput, NumberInput, TextInput, ReferenceInput, DateInput, ArrayInput, SimpleFormIterator, AutocompleteInput} from 'react-admin';
import { PRInput } from './../../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../Edit';
import  {styles}  from './../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EvaluacionPorSegmento from './EvaluacionPorSegmento'
import { required, minLength, maxLength } from 'react-admin';

export const CargarInformacionEdit = withStyles(styles)(({ classes, record, ...props }) => {
    const[tipoMedicion, setTipoMedicion] = useState('');
    let idproyecto=localStorage.getItem("id_project");
    const validartipoMedida=(value)=>{
        setTipoMedicion(value);        
    }
    return(
        <Edit  title="Editar Evaluación" {...props}  undoable={false}>
         <SimpleForm submitOnEnter={false} redirect="list" > 
         <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Selección del Indicador</Typography>
            <ReferenceInput label="Indicador" source="indicador" reference="parametros" sort={{ field: 'id', order: 'ASC' }} fullWidth formClassName={classes.grid_cont12} disabled>
                <SelectInput optionText="codigoCompleto" />
            </ReferenceInput>
            <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Detalles</Typography>
            <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ field: 'codigo', order: 'asc' }} 
            formClassName={classes.grid_cont3_f}
            disabled
            fullWidth
            >
               <SelectInput  options={{ fullWidth: true }} optionText="fullCodigos" fullWidth/>
            </ReferenceInput>
            <ReferenceInput label="Tramo" source="tramo" reference="tramos" 
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{ field: 'codigo', order: 'ASC' }} 
                fullWidth 
                formClassName={classes.grid_cont3_s}
                disabled
                >
                <SelectInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>   

            <PRInput label="Abscisa inicial" id="ingresoAbInicio" source="abiniFloat" fullWidth   formClassName={classes.grid_cont3_s} disabled/>
            <PRInput label="Abscisa Final" id="ingresoAbFin" source="abfinFloat" fullWidth  formClassName={classes.grid_cont3_s} disabled/>
            
            <DateInput label="Fecha de Medición" id="datefield" source="fechaMedicion" formClassName={classes.grid_cont3_f} fullWidth disabled/>

            <SelectInput label="Medido Por" source="medidoPor" formClassName={classes.grid_cont3_s} fullWidth choices={[
                                {id: 'Interventoría', name: 'Interventoría'},
                                {id: 'Mantenimiento', name: 'Mantenimiento'}
                            ]} />
            <SelectInput label="Estado" source="estado" formClassName={classes.grid_cont3_s} fullWidth 
            choices={[
                {id: 'Por Revisar', name: 'Por Revisar'},
                {id: 'Publicada', name: 'Publicada'},
                {id: 'Revisión realizada', name: 'Revisión realizada'}
            ]}/>
            <SelectInput label="Tipo de medición" source="tipoMedicion" formClassName={classes.grid_cont3_s} validate={[validartipoMedida]} choices={[
                                {id: 'Periódica', name: 'Periódica'},
                                // {id: 'De Verificación', name: 'De Verificación'},
                                {id: 'De Corrección', name: 'De Corrección'},
                                
                                
                            ]} disabled/>
            {tipoMedicion==="De Corrección" &&
            <Fragment>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>Rango de Corrección</Typography>
            <PRInput label="Abscisa inicial"  source="abiniCorreccionFloat" fullWidth   className={classes.grid_cont3_f} disabled/>
            <PRInput label="Abscisa Final"  source="abfinCorreccionFloat" fullWidth  className={classes.grid_cont3_s} disabled/>
            </Fragment>
            }
            <Divider fullWidth/>
            <TextInput source="proyecto" defaultValue={idproyecto} style={{display: "none"}}/>
           
            {/* <NumberInput label="Promedio carril I" source="promedioci"/>
            Incumplimientos puntuales carril I
            Promedio carril D
            Incumplimientos puntuales carril D */}
            {/* <WithProps>{({record,...props})=>
               <EvaluacionPorSegmento record={record}/>}
            </WithProps> */}
            
         </SimpleForm>
         </Edit>
    )
});

const WithProps = ({children,...props}) => children(props);
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
    ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer
  } from 'react-admin';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../../inputs/PRInput';
import { DInput } from '../../../inputs/DInput';
import { DEInput } from '../../../inputs/DEInput';
import { AInput} from '../../../inputs/AInput';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class Viewanalisis extends React.Component{

    constructor(props){
        console.log(props)
        super(props)
        this.state = { 
            titulos: [], record: [], tipo: "",totalSum:[],
            years1: props.years1,
            years2: props.years2,
            titulos2: []
    
    }
        this.array = {
            valoriza : 0
        }
    }
    

    componentDidMount(){
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })  
        this.setState({ tipo: this.props.tipo })  
       // console.log(this.props.record) 
       this.allYears()

    }
    componentWillReceiveProps() {
        // if(this.props.years1 != this.props.years2 && parseInt(this.props.years2) > parseInt(this.props.years1)){
            console.log();
            this.setState({years1:this.props.years1, years2:this.props.years2})
        this.allYears()
        
      }

    allYears(){
        const {totalSum} = this.state
        const years = []
        const total = totalSum
        let years1 = this.props.years1
        let years2 = this.props.years2
        console.log("Hasta acá llegamos equisde");
        for (let index = years1; index <= years2; index++) {
            years.push(parseInt(index))
            total.push("")
            
        }
        console.log(this.props.years2)
        this.setState({
            titulos2: years,
            totalSum: total
        })
        
    }

    totaliza = (i) => {
        const {years1, years2,totalSum} = this.state
        
        const total = totalSum

        if(total.length === 0) {
           for (let index = years1; index <= years2; index++) {
                total.push("")
            }
        }
        let valor_a=parseInt((document.getElementById('precioa_'+i).value).replace("$","") )
        let valor_b=parseInt((document.getElementById('preciob_'+i).value).replace("$",""))

        const a =  valor_a ? valor_a : 0
        const b =  valor_b ? valor_b : 0

        const suma = a + b

        total[i] = suma
        
        // this.setState(prevState => ({
        //     totalSum: [
        //       ...prevState.totalSum[i] = suma,
        //     ]
        //   }));

        console.log(total)
        
        this.setState({
            totalSum: total
        })

    }

    render(){
        const { titulos } = this.state;
        const {record} = this.state;
        const {tipo} = this.state;
        const  {titulos2} = this.state
//          const totaliza=(e, i)=>{
//              console.log(e,i)

//             return 
//          let a = [];
//         let b= [];
//         let valortotal=[];
//         for(let i=0; i<2; i++){
//         let valor_a=document.getElementById('precioa_'+i)
//         let valor_b=document.getElementById('preciob_'+i)
//         if(valor_a && valor_b){
//             a.push(parseFloat(valor_a.value));
//             b.push(parseFloat(valor_b.value));
//         }
//         }
    
// //     if (a.length===2 && b.length===2){
// //        x = a + b;
// //     }
// //     this.array.total=x;
// // }
//         if(a.length===2 && b.length===2){
//             let x=0;
//             //console.log(a);
//             //console.log(b);
//             for(let i=0; i<2; i++){
    
//                 x=a[i]+b[i];
//                 console.log(x)
//                 valortotal[i] = parseFloat(x);

//                 this.array.valoriza=valortotal[i];
//                 }
                
//             }
//         }
        // const  titulos2=[
        //     { id: '2000', name: '2000' },
        //     { id: '2001', name: '2001' },
        //     { id: '2002', name: '2002' },
        //     { id: '2003', name: '2003' },
        //     { id: '2004', name: '2004' },
        //     { id: '2005', name: '2005' },
        //     { id: '2006', name: '2006' },
        //     { id: '2007', name: '2007' },
        //     { id: '2008', name: '2008' },
        //     { id: '2009', name: '2009' },
        //     { id: '2010', name: '2010' },
        //     { id: '2011', name: '2011' },
        //     { id: '2012', name: '2012' },
        //     { id: '2013', name: '2013' },
        //     { id: '2014', name: '2014' },
        //     { id: '2015', name: '2015' },
        //     { id: '2016', name: '2016' },
        //     { id: '2017', name: '2017' },
        //     { id: '2018', name: '2018' },
        //     { id: '2019', name: '2019' },
        //     { id: '2020', name: '2020' },
        //     { id: '2021', name: '2021' },
        //     { id: '2022', name: '2022' },
        //     { id: '2023', name: '2023' },
        //     { id: '2024', name: '2024' },
        //     { id: '2025', name: '2025' },
        //     { id: '2026', name: '2026' },
        //     { id: '2027', name: '2027' },
        //     { id: '2028', name: '2028' },
        //     { id: '2029', name: '2029' },
        //     { id: '2030', name: '2030' }
        // ];
        let analisis=this.props.record.analisis

        
        // let analisis=[]
        // if(record && record.analisis){
        //     let eva=record.analisis;
        //     console.log(eva)
        //     eva.map((data, i)=>{
                
        //         analisis[i]={
        //             precio: data.precio,
        //             precio2: data.precio2,
        //             total: data.total,
        //             id: data.id
        //         }
        //     })
        // }
            return(
                <React.Fragment>
                        <div style={{textAlign : "center", marginBottom: '25px'}}>
              <Typography variant="h3" component="h3">
                 Presupuesto disponible
              </Typography>
          </div>
                   <Table aria-label="simple table"  >
                        <TableHead>
              <TableRow>
                <TableCell align="right">Año</TableCell>
                <TableCell align="right">Presupuesto mantenimiento pavimento</TableCell>
                <TableCell align="right">Presupuesto mantenimiento otros elementos</TableCell>
                <TableCell align="right">Presupuesto Total</TableCell>
                
              </TableRow>
            </TableHead>
            
                  
                        <TableBody>
                        {
                    titulos2.map((data, i) => (
                        <TableRow > 
                        <TableCell align="center"  >
                        <h3 style={{marginBottom: 0}}>{titulos2[i]}</h3>                    
                        </TableCell>
            
                     
                      <TableCell align="right" style={{textAlign:'right'}} ><span>{analisis[i].precio}</span></TableCell>  
                      <TableCell align="right" style={{textAlign:'right'}} ><span>{analisis[i].precio2}</span></TableCell>  
                      <TableCell align="right" style={{textAlign:'right'}} ><span>${analisis[i].total}</span> </TableCell>  
                      <span style={{display: 'none'}}>{i}</span>
                    </TableRow>
                     ))                        
                    }
                    </TableBody>     
                
                       
                   
                        </Table>
                </React.Fragment>
            )
        }
        
            

    }


export default Viewanalisis;
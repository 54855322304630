import React, {Fragment}from 'react';
import { Filter,SelectInput,ReferenceInput,SelectField,List,NumberField,ReferenceField,Datagrid, EditButton, ShowButton,TextField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { PRField } from './../../../fields/PRField';

const MensualFilter = (props) => (
    <Filter {...props}>
        
        <ReferenceInput label="Peaje" source="inventario_peajes" id="peajes_id" reference="inventariopeajes" linkType="show" alwaysOn>                
                        <SelectInput optionText="nombre" />                
                      </ReferenceInput>
         <SelectInput label="Año" source="ano"  choices={[
                                    { id: '2000', name: '2000' },
                                    { id: '2001', name: '2001' },
                                    { id: '2002', name: '2002' },
                                    { id: '2003', name: '2003' },
                                    { id: '2004', name: '2004' },
                                    { id: '2005', name: '2005' },
                                    { id: '2006', name: '2006' },
                                    { id: '2007', name: '2007' },
                                    { id: '2008', name: '2008' },
                                    { id: '2009', name: '2009' },
                                    { id: '2010', name: '2010' },
                                    { id: '2011', name: '2011' },
                                    { id: '2012', name: '2012' },
                                    { id: '2013', name: '2013' },
                                    { id: '2014', name: '2014' },
                                    { id: '2015', name: '2015' },
                                    { id: '2016', name: '2016' },
                                    { id: '2017', name: '2017' },
                                    { id: '2018', name: '2018' },
                                    { id: '2019', name: '2019' },
                                    { id: '2020', name: '2020' },
                                    { id: '2021', name: '2021' },
                                    { id: '2022', name: '2022' },
                                    { id: '2023', name: '2023' },
                                    { id: '2024', name: '2024' },
                                    { id: '2025', name: '2025' },
                                    { id: '2026', name: '2026' },
                                    { id: '2027', name: '2027' },
                                    { id: '2028', name: '2028' },
                                    { id: '2029', name: '2029' },
                                    { id: '2030', name: '2030' }
                                ]} alwaysOn/>
                 
                  <SelectInput label="Mes" source="mes"   choices={[
                            { id: '1', name: 'Enero' },
                            { id: '2', name: 'Febrero' },
                            { id: '3', name: 'Marzo' },
                            { id: '4', name: 'Abril' },
                            { id: '5', name: 'Mayo' },
                            { id: '6', name: 'Junio' },
                            { id: '7', name: 'Julio' },
                            { id: '8', name: 'Agosto' },
                            { id: '9', name: 'Septiembre' },
                            { id: '91', name: 'Octubre' },
                            { id: '92', name: 'Noviembre' },
                            { id: '93', name: 'Diciembre' }                                    
                        ]} alwaysOn/>
    </Filter>
);
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
export const Peajesmensualist = props => (
    <List title="Lista Tránsito Histórico" {...props} sort={{ field: 'id', order: 'DESC' }} filters={<MensualFilter/>}  bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid >                
                <ReferenceField label="Peaje" source="inventario_peajes" reference="inventariopeajes" linkType={false}>
                        <TextField source="nombre" />
                    </ReferenceField>
                    <ReferenceField label="Código" source="inventario_peajes" reference="inventariopeajes" align="right" style={{textAlign:'right'}} linkType={false}>
                        <NumberField source="codigo" />
                    </ReferenceField>
                    <ReferenceField label="Unidad Funcional" source="inventario_peajes" reference="inventariopeajes" linkType={false}>                
                    <TextField source="unidad_funcional_.fullCodigos" />                
            </ReferenceField>
                    <ReferenceField label="Abscisa" source="inventario_peajes" reference="inventariopeajes" linkType={false}>
                        <PRField source="abInicio" />
                    </ReferenceField>     
                    <TextField label="Sentido" source="sentido" />
                    <NumberField label="TPM" source="tPM" />
                    <TextField label="Año" source="ano" />   
                    <SelectField
                        label="Mes"
                        source="mes"
                        choices={[
                            { id: '1', name: 'Enero' },
                            { id: '2', name: 'Febrero' },
                            { id: '3', name: 'Marzo' },
                            { id: '4', name: 'Abril' },
                            { id: '5', name: 'Mayo' },
                            { id: '6', name: 'Junio' },
                            { id: '7', name: 'Julio' },
                            { id: '8', name: 'Agosto' },
                            { id: '9', name: 'Septiembre' },
                            { id: '91', name: 'Octubre' },
                            { id: '92', name: 'Noviembre' },
                            { id: '93', name: 'Diciembre' }                                    
                        ]}
                    />      
                    {/* <TextField label="Mes" source="mes" />             */}
            <ShowButton label="Histórico"/>
            <EditButton />
        </Datagrid>
    </List>
)
import React ,{useState} from 'react';
import {CardActions, TextInput, TabbedForm ,Toolbar, Datagrid, ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, FormTab, ArrayField,NumberField,ListButton,EditButton  } from 'react-admin';
// import CardActions from '@material-ui/core/CardActions';
import { PRField } from './../../../fields/PRField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../../EstilosGrid';
import { GMapField } from '../../../fields/GMapField';
import { ButtonCarousel } from './../../images/ButtonCarousel';



export const ElementoAlrededorShow  = withStyles(styles)(({ classes, record, ...props }) => {
    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }
    //let elemento = "/tipo_elementos/COMR";
    return(
    <ShowController {...props} title="Información Elemento Alrededor">
        {controllerProps =>
            <ShowView {...props} {...controllerProps} title="Información Elemento Alrededor"  actions={<ButtonCarousel />}>
                <TabbedForm toolbar={false}>
                    <FormTab label="Identificación">
                    <TextField label="Código" source="fullCodigo" fullWidth formClassName={classes.grid_cont4}/>
                        <NumberField label="Consecutivo" source="fullConsecutivo" fullWidth formClassName={[classes.grid_cont4]} />
                        <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
                        <ReferenceField label="Tipo de Elemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                            <TextField source="nombre" />       
                        </ReferenceField> 
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/CUNT" &&
                            <TextField label="Tipo de cuneta" source="tipoCuneta" fullWidth formClassName={classes.grid_cont4}/>                          
                        }                       
                        <TextField source="margen" fullWidth formClassName={classes.grid_cont4}/>
                
                        <Typography variant="b" component="b">Localización</Typography>
                        <Divider fullWidth formClassName={classes.grid_cont12}/>
                        <ReferenceField 
                            label="Unidad Funcional" 
                            source="unidadFuncional" 
                            reference="unidad_funcionals" 
                            linkType={false} 
                            fullWidth 
                            formClassName={classes.grid_cont4}
                        >    
                            <TextField source="fullCodigos" />       
                        </ReferenceField>
                        <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        <PRField label="Abscisa Inicio" source="abInicio"fullWidth formClassName={classes.grid_cont4} />
                        <PRField label="Abscisa Fin" source="abFin" fullWidth formClassName={classes.grid_cont4}/>
                        <NumberField label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>  
                        
                        <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                        <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                        <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={[classes.grid_cont4]}/>
                        <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={[classes.grid_cont3]}/> 
                        <br/>             
                        <Typography variant="b" component="b">Coordenadas Finales</Typography>
                        <Divider fullWidth style={{width:'300%' , marginTop :'10px'}} /> 
                        <NumberField label="Latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                        <NumberField label="Longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                        <GMapField style={{width : "100%"}}
                            defaultZoom={12}                                    
                            defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                            source="ubicacionMapa"
                            multipleMarkers="true"
                            googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                            icon= {imagen} 
                        /> 
                    </FormTab> 
                    <FormTab label="Características">
                        <br></br>
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" &&
                            <TextField label="Tipo de Servicio" source="tipoServicio" fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" &&
                            <TextField label="Tipo de Acceso" source="tipoAcceso" fullWidth formClassName={classes.grid_cont3}/>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" &&
                            <TextField label="Estacionamiento" fullWidth source="estacionamiento" fullWidth formClassName={classes.grid_cont3}/>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                        <br/>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" &&
                            <NumberField label="Largo del Acceso (m)" source="largo"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" &&
                            <NumberField label="Ancho del Acceso (m)" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" &&
                            <NumberField label="Área del Acceso (m²)" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                        }

                        {/* ------ Elemento = Márgenes, Separadores ------- */}

                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/MARG" &&
                            <TextField  label="Superficie caraterística"  source="tipoServicio" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/MARG" &&
                            <br></br>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/MARG" &&
                            <NumberField label="Largo (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/MARG" &&
                            <NumberField label="Ancho (m)" source="ancho"fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/MARG" &&
                            <NumberField  label="Área (m²)" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/SEPD" &&
                            <TextField  label="Superficie caraterística"  source="tipoServicio" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/SEPD" &&
                            <br></br>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/SEPD" &&
                            <NumberField label="Largo (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/SEPD" &&
                            <NumberField label="Ancho (m)" source="ancho"fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/SEPD" &&
                            <NumberField  label="Área (m²)" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                          
                        }
                         {/* ------ Elemento = Márgenes, Separadores ------- */}
                         {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/OBST" &&
                            <TextField label="Tipo" fullWidth source="tipoServicio"  fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/OBST" &&
                            <NumberField  label="Longitud de afectación (m)" source="longitudAfectacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/OBST" &&
                            <br></br>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/OBST" &&
                            <NumberField label="Largo (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/OBST" &&
                            <NumberField  label="Ancho (m)" source="ancho"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/OBST" &&
                            <NumberField  label="Altura (m)" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                          
                        }

                        {/* ------ Elemento = Zona inestable ------- */} 
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <TextField label="Tipo de afectación"  source="tipoServicio" fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <TextField  label="Riesgo"  source="riesgo" fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <TextField label="Ubicación"  source="seccion"  fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <br></br>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <NumberField label="Longitud de calzada afectada (m)" source="longitudAfectacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont6}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <br></br>
                        }
                          {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                        <Typography variant="h3" component="h3" className={classes.coord}>Tramos de Pendiente Variable</Typography>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                       <Divider fullWidth style={{ marginTop: '10px' }} />
                         }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <ArrayField label=" " fullWidth source="tramosPendientes">
                                <Datagrid>
                                    <NumberField source="tramoPendiente" label="Tramo" fullWidth formClassName={classes.grid_cont3} />                          
                                    <PRField label="Abscisa Inicio" id="ingresoAbFin" source="abInicio" fullWidth fullWidth formClassName={classes.grid_cont3}/>
                                    <NumberField source="pendTransversal" label="Pendiente Transversal H:V" fullWidth formClassName={classes.grid_cont3}  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>                       
                                </Datagrid>
                            </ArrayField>
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <div></div>
                        }
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <Divider fullWidth />                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <BooleanField  label="Obras de estabilización" source="obraEstabilizacion" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <div></div>
                        }
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <TextField label="Tipo de obra" fullWidth source="tipoObra" fullWidth formClassName={classes.grid_cont6} />                          
                        }
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <div></div>
                        }
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <NumberField  label="Largo (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <NumberField  label="Ancho (m)" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <NumberField  label="Altura (m)" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                          
                        }
                        
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <Divider fullWidth />                          
                        }
                        {controllerProps.record && controllerProps.record.obraEstabilizacion   &&
                            <BooleanField label="Ficha de inspección de obra" source="fichaInspeccion" fullWidth formClassName={classes.grid_cont6}/>                          
                        }
                        {controllerProps.record && controllerProps.record.fichaInspeccion   &&
                            <div></div>                          
                        }
                        {controllerProps.record && controllerProps.record.fichaInspeccion   &&
                            <TextField source="inspector" fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.fichaInspeccion   &&
                            <DateField label="Fecha del estudio" source="fechaInspeccion" fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.fichaInspeccion   &&
                            <TextField label="Nombre de la ficha" source="nombreFicha" fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.fichaInspeccion   &&
                            <Divider fullWidth />                          
                        }
                        {controllerProps.record && controllerProps.record.fichaInspeccion   &&
                            <BooleanField label="Estudios de estabilidad del sitio" source="estudioEstabilidad" formClassName={classes.grid_cont6}/>  
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad   &&
                            <div></div>                        
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad   &&
                            <TextField source="consultor" fullWidth formClassName={classes.grid_cont6}/>                          
                        }
                        {controllerProps.record && controllerProps.record.fichaInspeccion   &&
                            <div></div>                         
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad   &&
                            <DateField label="Fecha del estudio" source="fechaEstudio" fullWidth formClassName={classes.grid_cont3}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad   &&
                            <TextField  label="Código de estudio" source="codigoEstudio" fullWidth formClassName={classes.grid_cont3} />                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad   &&
                            <TextField label="Nombre del estudio" source="nombreEstudio"  fullWidth formClassName={classes.grid_cont3}/>                          
                        }

                        

                    </FormTab> 
                    <FormTab label="Detalles">
                        <br></br>
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" &&
                            <TextField label="Condición del elemento"  fullWidth source="estado"fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {/* ------ Elemento = Márgenes, Separadores ------- */} 
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/MARG" &&
                            <TextField label="Condición del elemento"  fullWidth source="estado" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/SEPD" &&
                            <TextField label="Condición del elemento"  fullWidth source="estado" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <TextField label="Condición del elemento"  fullWidth source="estado" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estado  === "Reemplazo" &&
                            <TextField label="Motivo de reemplazo" source="condicion"fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estado  === "Reemplazo" &&
                          
                            <TextField 
                                label="Elemento que Reemplazo"
                                source="FullCodigoElemReem" 
                                source="fullCodigo" 
                                linkType={false} 
                                formClassName={classes.grid_cont3}
                            />
                        }
                        {controllerProps.record && controllerProps.record.estado  !== "Reemplazo" &&
                            <div></div>                     
                        }

                        {/* --------ZONA INESTABLE -----------*/}

                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <DateField  label="Fecha de construcción" source="fechaConstruccion" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <TextField  label="Proveedor" source="proveedor_.razonSocial" fullWidth formClassName={classes.grid_cont4}/>                          
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/ZINT" &&
                            <NumberField  label="Garantía (meses)" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                          
                        }
                        <br/>
                        <TextField label="Estado" source="estados" fullWidth formClassName={classes.grid_cont4}/>
                        <br/>
                         <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" formClassName={classes.grid_cont12s}/>
                         <TextField  source="observaciones" formClassName={classes.grid_cont12s}/> 
                         
                    </FormTab> 
                    <FormTab label="Auditoría">
                        <br></br>
                        <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4}/> 
                        <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>               
                        <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4}/>
                        <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>    
                    </FormTab> 
    
                </TabbedForm>
            </ShowView>
        }
    </ShowController>
    )
});
import React, {useState, Fragment} from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput,
    BulkDeleteWithConfirmButton,SelectInput
} from 'react-admin';
import { PRField } from './../../../fields/PRField';

const FiltroAlrededor= (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <SelectInput label="Estado" source="estados" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]}  fullWidth alwaysOn
            />
    </Filter>
    )
};
const ConfirmarBorrar = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
    </Fragment>
);

export const ElementoPontonList = props => (
    <List {...props} bulkActionButtons={<ConfirmarBorrar />} title="Lista Elementos Pontones" sort={{ field: 'updatedAt', order: 'DESC' }} filters={<FiltroAlrededor/>} >
        <Datagrid>
            <TextField label="Código" source="fullCodigo" sortable={false}/>
            <TextField  label="Tipo" source="tipoPonton" sortable />
            <PRField label="Abscisa inicial" source="abInicio"/>
            <TextField source="margen" sortable/>
            <TextField label="Estado" source="estados" sortable/>
            <ShowButton />
            <EditButton />                           
        </Datagrid>
    </List>
);
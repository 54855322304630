import React, { useState } from 'react';
import {
    NumberInput, ReferenceInput, ImageField,
    SelectInput, ImageInput, AutocompleteInput, ArrayInput, SimpleFormIterator, DateInput,
    TextInput, BooleanInput, maxLength, minLength
} from 'react-admin';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import { POInput } from '../../../inputs/POInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { PimsElementoActions } from './../PimsElementoActions';
import { required, FormTab } from 'react-admin';
import { minValue } from 'ra-core';
import {
    latMininima, lngMininima, abValidationInicio, abValidationFin, validarDosDecimales,
    validarPorcentaje,  Entero, isFechaInvalida
} from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import TabbedForm from './../../TabbedForm';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';


const garantia = (value) => {
    if (!/^([0-9]+)*$/.test(value)) {
        return "Debe ser un número entero";
    }
}

export const ElementoDrenajeCreate = withStyles(styles)(({ classes, ...props }) => {

    //MOSTRAR/OCULTAR CAMPO RAZON
    const [detallesEstado, setDetallesEstado] = useState('');
    const validarRazon = (value) => { 
        setDetallesEstado(value)   
    }

    const [segmentos, setSegmento] = useState('');
    const [unidads, setUnidad] = useState('');

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }

    const Unidades = { unidadFuncional: unidads };

    const [tramos, setTramo] = useState('');

    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }

    const Tramoss = { tramo: tramos };

    const [elemento, setElemento] = useState('');

    const ValidarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor)
    }

    const ValidarSegmento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSegmento(value)
    }


    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const [estados, setEstado] = useState('');

    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)
    }

    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [opcionTipoImagen,setOpcionTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = (value) =>{
        if(value !== '/tipo_elementos/DESC'){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
           
        }else{
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        } 
        setOpcionTipoImagen([
            {id:'/tipo_images/1'  , name:'Panorámica'},
            {id:'/tipo_images/7'  , name:'Elemento completo'},
            {id:'/tipo_images/8'  , name:'Detalle 1'},
            {id:'/tipo_images/9'  , name:'Detalle 2'},
            {id:'/tipo_images/5'  , name:'Deterioro 1'},
            {id:'/tipo_images/6'  , name:'Deterioro 2'},
        ])       
    }

    const [seccion, setSeccion] = useState('');
    const ValidateSeccion = (value) => {
        setSeccion(value)
    }
    
    return (
        <PimsCreate  {...props} title="Crear Elemento Drenaje" actions={<PimsElementoActions />} >
            <TabbedForm redirect="list" >
                {/* ------ ELEMENTO DRENAJE ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                    <CoordenadasProyecto />
                    <ReferenceInput
                        label="Tipo de Elemento"
                        source="tipoElemento"
                        reference="tipo_elementos"
                        validate={[required(),OpcionTipoImagen]}
                        filter={{ sistema: 'DRE' }}
                        sort={{
                            field: 'nombre',
                            order: 'ASC'
                        }}
                        fullWidth
                        formClassName={classes.grid_cont4s}
                        onChange={ValidarElemento}
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    <SelectInput
                        fullWidth
                        label="Margen"
                        source="margen"
                        allowEmpty
                        emptyValue=""
                        validate={[required()]}
                        fullWidth choices={[
                            { id: 'Derecha', name: 'Derecha' },
                            { id: 'Izquierda', name: 'Izquierda' },
                        ]}
                        formClassName={classes.grid_cont4}
                    />
                    {elemento === "/tipo_elementos/CUNT" &&
                        <SelectInput fullWidth label="Tipo Cuneta" source="tipoCuneta" allowEmpty emptyValue="" choices={[
                            { id: 'Bajo Accesos a Predios o Intersección de Vías', name: 'Bajo Accesos a Predios o Intersección de Vías' },
                            { id: 'En Zonas Urbanas y Semiurbanas', name: 'En Zonas Urbanas y Semiurbanas' },
                            { id: 'Tipo Carretera', name: 'Tipo Carretera' },
                        ]} validate={[required()]} formClassName={classes.grid_cont4} />
                    }
                    {/* ------- LOCALIZACIÓN --------- */}
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">Localización</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="Unidad Funcional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>
                        {unidads &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="Tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    formClassName={classes.grid_cont4}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {tramos &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="Segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    formClassName={classes.grid_cont4}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                    </Grid>
                    {segmentos && <AbsSegmento segmento={segmentos} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas Inicales</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[required(), abValidationInicio]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas Finales</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[required(), abValidationFin]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[required(), LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[required(), lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="Características">
                    <SelectInput label="Material" fullWidth source="material" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                        { id: 'Acero', name: 'Acero' },
                        { id: 'Concreto', name: 'Concreto' },
                        { id: 'Concreto y acero', name: 'Concreto y acero' },
                        { id: 'Mampostería', name: 'Mampostería' },
                        { id: 'Otro', name: 'Otro' },
                        { id: 'PVC', name: 'PVC' },
                    ]} formClassName={classes.grid_cont6s} />
                    <SelectInput label="Sección" fullWidth source="seccion" allowEmpty emptyValue="" validate={[required(),ValidateSeccion]} fullWidth choices={[
                        { id: 'Circular', name: 'Circular' },
                        { id: 'Cuadrada', name: 'Cuadrada' },
                        { id: 'Rectangular', name: 'Rectangular' },
                        { id: 'Trapezoidal', name: 'Trapezoidal' },
                        { id: 'Triangular', name: 'Triangular' },
                    ]} formClassName={classes.grid_cont6} />
                    <NumberInput label="Largo (m)" source="largo" fullWidth validate={[required(), minValue(0), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4s} />
                    <NumberInput label="Ancho/Diámetro (m)" source="ancho" fullWidth validate={[required(), minValue(0), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4} />
                    {seccion !== 'Circular' &&
                        <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(), minValue(0), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4} />
                    }
                    <NumberInput id="numeroTramos" label="Número de Tramos" source="numeroTramos" fullWidth validate={[required(), minValue(0), Entero]} formClassName={classes.grid_cont6s} />
                    <NumberInput id="seccionHidraulica" label="Sección Hidráulica (m²)" source="seccionHidraulica" fullWidth validate={[required(), minValue(0), validarDosDecimales]} formClassName={classes.grid_cont6} />
                    <POInput label="Pendiente Transversal" source="pendienteTransversal" fullWidth className="align-number" className="align-number" validate={[required(), validarPorcentaje]} formClassName={classes.grid_cont6s} />
                    <POInput label="Pendiente Longitudinal" source="pendienteLongitudinal" fullWidth className="align-number" className="align-number" validate={[required(), validarPorcentaje]} formClassName={classes.grid_cont6} />
                </FormTab>
                {/* ------ DETALLES ---------- */}
                <FormTab label="Detalles" >
                    <SelectInput label="Condición del elemento" source="estado" choices={[
                        { id: 'Existente', name: 'Existente' },
                        { id: 'Reemplazo', name: 'Reemplazo' },
                        { id: 'Nuevo', name: 'Nuevo' },
                    ]} fullWidth validate={[required()]} formClassName={classes.grid_cont4s} onChange={ValidarEstado} />
                    {estados === "Reemplazo" &&
                        <SelectInput
                            label="Motivo del Reemplazo"
                            fullWidth
                            source="motivoReemplazo"
                            allowEmpty
                            emptyValue=""
                            fullWidth choices={[
                                { id: 'Accidente', name: 'Accidente' },
                                { id: 'Otro', name: 'Otro' },
                                { id: 'Reemplazo programado', name: 'Reemplazo programado' },
                                { id: 'Vandalismo', name: 'Vandalismo' },
                            ]}
                            formClassName={classes.grid_cont4}
                        />
                    }
                    {estados !== "Reemplazo" &&
                        <span formClassName={classes.grid_cont6} ></span>
                    }
                    {estados === "Reemplazo" &&
                        <ReferenceInput
                            label="Elemento que reemplazo"
                            source="elementoReemplazo_id"
                            reference="elemento_drenajes"
                            filter={{
                                status: 1,
                                tipoElemento: elemento,
                                unidadFuncional: unidads,
                                tramo: tramos,
                                segmento : segmentos
                            }}
                            fullWidth
                            validate={[required()]}
                            formClassName={classes.grid_cont4}
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                        </ReferenceInput>
                    }
                    <SelectInput 
                        source="estados" 
                        label="Estado" 
                        choices={[
                            { id: 'Activo', name: 'Activo' },
                            { id: 'Inactivo', name: 'Inactivo' },                    
                        ]} 
                        fullWidth
                        formClassName={classes.grid_cont4s} 
                        validate={[required(), validarRazon]}
                    />                                
                    {detallesEstado ===  "Inactivo" &&            
                        <TextInput source="estadoRazon" label="Razón" fullWidth formClassName={classes.grid_cont4} validate={[required()]}/>
                    }
                    <br/>
                    <DateInput label="Fecha de instalación" source="fechaInstalacion" fullWidth validate={[required(), isFechaInvalida]} formClassName={classes.grid_cont4s} />

                    <ReferenceInput
                        label="Proveedor"
                        source="proveedor"
                        reference="proveedors"
                        filter={{
                            proyecto: props.proyecto,
                            suministros: 1, sistema: 'DRE'
                        }}
                        sort={{ field: 'razonSocial', order: 'ASC' }}
                        filterToQuery={searchText => ({ razonSocial: searchText })}
                        formClassName={classes.grid_cont4}
                        fullWidth
                        validate={[required()]}
                    >
                        <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
                    </ReferenceInput>
                    <NumberInput label="Garantía (Meses)" source="garantia" fullWidth validate={[required(), garantia, minValue(0)]} formClassName={classes.grid_cont4} />
                    
                    <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth />
                    <TextInput source="observaciones" fullWidth validate={[required(), minLength(5, "Al menos 5 Caracteres."), maxLength(200, "No mas de 200 Caracteres.")]} />
                </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                <ArrayInput label="" source="images"  fullWidth
                    defaultValue={defaultTipoImagen}
                >
                    <SimpleFormIterator disableAdd disableRemove >
                        <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                            <ImageField source="src" title="title" />
                        </ImageInput>
                        <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                            <SelectInput  optionText="nombre"  />
                        </ReferenceInput>                         
                    </SimpleFormIterator>
                </ArrayInput>                
            </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
});
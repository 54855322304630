import React, { Fragment, useState } from 'react';
import { ReferenceInput,SelectInput,Filter,WithPermissions,BulkDeleteWithConfirmButton, List, 
    Datagrid, TextField, EditButton, ShowButton, ReferenceArrayField, SingleFieldList, ChipField, CardActions, CreateButton, 
    ExportButton, AutocompleteInput
} from 'react-admin';
import { withStyles} from '@material-ui/core/styles';
// var emergencia = localStorage.getItem("contemergencia");

const PostBulkActionButtons = props => {

    var emergencia = localStorage.getItem("contemergencia");

    return(

    <WithPermissions
            render={({ permissions }) => (
                 "1" === emergencia                 
        ? 
        <Fragment>
            <BulkDeleteWithConfirmButton {...props}/>
        </Fragment>
        : null
        )}
    />

    )};

    const PostActions =({basePath,
        currentSort,    
        exporter,    
        filterValues,   
        resource,    
        total})=>{
    var contemergencia = localStorage.getItem("contemergencia");
    return(
            <WithPermissions
            render={({ permissions }) => (
                "1" === contemergencia   ||    "2" === contemergencia           
        ? 
        <CardActions>
            <CreateButton basePath={basePath} />
            <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        </CardActions>
        

        :  <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
    />
        )}
        />
        
    )
};
    const styles = {
        ocultar: {
            display:"none",
        },
    };
    
const VariosFilterList = (props) => {

    const [departamento , setDepartamento]= useState('-');
    
    const ValidarDepartamento = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(valor) 
    }

    return (
            <Filter {...props}>
                <ReferenceInput 
                    label="Provincia" 
                    source="departamento" 
                    reference="departamentos"
                    onChange={ValidarDepartamento}
                    alwaysOn 
                    filterToQuery={searchText => ({ nombre: searchText })}  
                    sort={{field:"nombre", order:"ASC"}}
                >
                    <AutocompleteInput  optionText="nombre" fullWidth />
                </ReferenceInput>
                                        
                <ReferenceInput 
                    label="Distrito" 
                    source="municipio" 
                    reference="municipios" 
                    alwaysOn 
                    filterToQuery={searchText => ({ nombre: searchText })} 
                    sort={{field:"nombre", order:"ASC"}}
                    filter={{departamento : departamento}}
                >
                     <AutocompleteInput  optionText="nombre" fullWidth />  
                </ReferenceInput>
        
            <SelectInput id="NivelMax" label="Grupo" source="grupo" allowEmpty emptyValue=""  choices={[
                        { id: 'Alcaldía', name: 'Alcaldía' },
                        { id: 'Bomberos', name: 'Bomberos' },
                        { id: 'Centro educativo', name: 'Centro educativo' },
                        { id: 'Defensa civil', name: 'Defensa civil' },
                        { id: 'Ejército', name: 'Ejército' },
                        { id: 'Emisora radial', name: 'Emisora radial' },
                        { id: 'Interventoría', name: 'Interventoría' },
                        { id: 'Maquinaria', name: 'Maquinaria' },
                        { id: 'Otros', name: 'Otros' },
                        { id: 'POB-Administrativa', name: 'POB-Administrativa' },
                        { id: 'POB-Ambulancia', name: 'POB-Ambulancia' },
                        { id: 'POB-Carro taller', name: 'POB-Carro taller' },
                        { id: 'POB-Grúas', name: 'POB-Grúas' },
                        { id: 'POB-Peajes', name: 'POB-Peajes' },
                        { id: 'POB-Radio Operación', name: 'POB-Radio Operación' },
                        { id: 'POB-Supervisión vial', name: 'POB-Supervisión vial' },
                        { id: 'Policía', name: 'Policía' },
                        { id: 'Servicios médicos', name: 'Servicios médicos' },
                        { id: 'Talleres', name: 'Talleres' },
                    ]} alwaysOn/>
            </Filter>
           
    )
};
    
export const Contacto_emergenciaList = props => (
    <List title="Lista Contactos de Emergencia" {...props} sort={{ field: 'id', order: 'DESC' }} actions={<PostActions/>} filters={<VariosFilterList/>}  bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>            
            <ReferenceArrayField reference="municipios" source="municipio" sortable={false}>
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField label="Grupo" source="grupo" />
            <TextField source="empresa" />         
          
            <TextField label="Nombre" source="nombre" />
            <TextField label="Teléfono" source="telefono_1" sortable={false} className="Listar_telefono" />
            
            <ShowButton />
            {
                localStorage.getItem("contemergencia") === "1" || localStorage.getItem("contemergencia") === "2" ? <EditButton /> : null
            }
                       
        </Datagrid>
    </List>
);
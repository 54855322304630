import React from 'react';
import {DateField,Filter,List, Datagrid, TextField, EditButton, ShowButton,ReferenceField,SelectField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';


const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const FiltroSeguridad= (props) => (
    <Filter {...props}>
       
    </Filter>
);

export const CalificacionPontonList = props => (
    <List title="Lista Calificación" {...props}  sort={{ field: 'id', order: 'DESC' }} filters={<FiltroSeguridad/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
        <ReferenceField label="Ponton" source="ponton" reference="elemento_pontons" linkType={false}  fullWidth>                
                   <TextField source="fullCodigo" />                
                 </ReferenceField>  
           <TextField source="nombre_inspector"/>
           <DateField label="Fecha de Inspección" source="fecha2"/>
            <ShowButton />
            <EditButton />  
               
        </Datagrid>
    </List>
);
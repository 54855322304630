import React from 'react';
import { SimpleForm,Toolbar,SaveButton} from 'react-admin';
import { PimsCreate } from './../../Create';
import  {styles}  from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import BackButton from '../../../BotonCancelar'

import {ComponenteDeterioroPers} from '../componentes/ComponeteReparacionPers';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const PimsToolbarActions = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton redirect={false} label='GUADAR Y AGREGAR' />
        <SaveButton redirect='list' label='GUADAR Y FINALIZAR' />
        <BackButton />
    </Toolbar>
));

export const ReparacionPersCreate = withStyles(styles)(({ classes, ...props }) => (
    <PimsCreate {...props} title='Crear Reparación PERS'>
        <SimpleForm redirect="list" toolbar={<PimsToolbarActions/>} >
            <ComponenteDeterioroPers/>
        </SimpleForm>
    </PimsCreate>
));
import React, { Fragment, useState } from 'react';
import {AutocompleteInput, SelectInput,Filter,WithPermissions,ReferenceInput,List, Datagrid, TextField,NumberField, 
    EditButton, ShowButton , BulkDeleteWithConfirmButton, CardActions, CreateButton, ExportButton, ReferenceArrayField, 
    SingleFieldList, ChipField} from 'react-admin';
import { withStyles} from '@material-ui/core/styles';
/* PERMISOS */
const PostBulkActionButtons = props => {

    var serpublicos = localStorage.getItem("serpublicos");

    return(

    <WithPermissions    
            render={({ permissions }) => (
                 "1" === serpublicos                 
        ? 
        <Fragment>
            <BulkDeleteWithConfirmButton {...props}/>
        </Fragment>
        : null
        )}
    />

    )};
    var serpublicos = localStorage.getItem("serpublicos");
    
const PostActions = ({
       
    basePath,
    currentSort,
    
    exporter,
    
    filterValues,
    
    resource,
    
    total
}) => (
    <CardActions>
         {/* <WithPermissions
        render={({ permissions }) => (
                    "1" === serpublicos                 
            ?  */}
            <Fragment>
                <CreateButton basePath={basePath} />
            </Fragment>
            {/* // : null
            // )}
        /> */}
        
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        
       
    </CardActions>
);

const styles = {
    ocultar: {
        display:"none",
    },
};


const VariosFilterList = (props) => {

    const [departamento, setDepartamento] = useState();

    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(valor)
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                label="Departamento"
                source="departamento"
                reference="departamentos"
                onChange={ValidarDepartamento}
                alwaysOn
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: "nombre", order: "ASC" }}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>

            <ReferenceInput
                label="Municipios"
                source="municipio"
                reference="municipios"
                alwaysOn
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: "nombre", order: "ASC" }}
                filter={{ departamento: departamento }}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>  
        <SelectInput  label="Grupo" source="grupo" allowEmpty emptyValue="" choices={[
            { id: 'Acueducto', name: 'Acueducto' },
            { id: 'Aseo', name: 'Aseo' },
            { id: 'Energía', name: 'Energía' },
            { id: 'Gas natural', name: 'Gas natural' },
            { id: 'Otros', name: 'Otros' },
            { id: 'Telecomunicaciones', name: 'Telecomunicaciones' },
        ]} alwaysOn/>
    </Filter>
       
)};

export const Servicio_publicoList = props => (
    <List title="Lista Servicios Públicos"{...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={<VariosFilterList/>} actions={<PostActions/>} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid>        
            <ReferenceArrayField source="municipio" reference="municipios" sortable={false}>
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
            </ReferenceArrayField>            
            <TextField source="grupo"/>
            <TextField source="empresa" />
            <NumberField label="Número de identificación" source="numero_identificacion" sortable={false} />
            <TextField label="Dirección" source="direccion" sortable={false}/>
            <NumberField label="Teléfono1" source="telefono_1" sortable={false}/>
            <ShowButton />
            {
                serpublicos === "1" || serpublicos === "2" ? <EditButton /> : null
            }           
        </Datagrid>
    </List>
);
import React, { useState, Fragment } from 'react';
import {
    List, Datagrid, FormDataConsumer, EditButton, ShowButton, Filter, ReferenceField,
    SelectInput, ReferenceInput, BulkDeleteWithConfirmButton, TextField, AutocompleteInput
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ListIcon from '@material-ui/icons/List';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';

//--------LIST LINE ACTIONS
const ListarPlan = (props) => (
    <Button
        {...props}
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/plan_mantenimiento_detalles',
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ planMantenimientos: props.record.id }),
            }),
        }}
    >
        <ListIcon />
    </Button>
)


//-----FIN DEL LIST ACTIONS

const ConfirmarBorrar = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
    </Fragment>
);



const Filtro = (props) => {
    const [getSistema, setSistema] = useState();
    const regSistema = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';
        // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        // console.log("sistema->", valor)
        setSistema(value);
    }

    const [getTipoElemento, setTipoElemento] = useState();
    const regTipoElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElemento(valor)
    }

    const [getUnidadFuncional, setUnidadFuncional] = useState();
    const validarUnidadFuncional = (value) => {
        setUnidadFuncional(value);
    }
    const [getTramo, setTramo] = useState();
    const validarTramo = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                label="Unidad Funcional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                // validate={[validarUnidadFuncional]}
                alwaysOn
            >
                <AutocompleteInput optionText="fullCodigos" />
            </ReferenceInput>
            {/* <ReferenceInput
                label="Sistema"
                source="sistema"
                reference="sistemas"
                filter={{
                    visibleOnPolicies: 1
                }}
                validate={[regSistema]}
                alwaysOn
            >
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <ReferenceInput
                label="Elemento"
                source="tipoElemento"
                reference="tipo_elementos"
                onChange={regTipoElemento}
                filter={{
                    visibleOnPolicies: 1,
                    sistema: getSistema
                }}
                alwaysOn
                
            >
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            
            <ReferenceInput
                label="Tramo"
                source="tramo"
                reference="tramos"
                validate={[validarTramo]}
                filter={{
                    unidadFuncional: getUnidadFuncional
                }}
                alwaysOn
            >
                <AutocompleteInput optionText="fullCodigos" />
            </ReferenceInput> */}
        </Filter>
    );
}
export const FlujoCajaList = props => (
    <List {...props}
        sort={{ field: 'id', order: 'DESC' }}
        title="Lista Flujos de Caja"
        filters={<Filtro />}
        bulkActionButtons={<ConfirmarBorrar />}
    >
        <Datagrid>
            <TextField
                label="ID"
                source="id"
                linkType={false}
                fullWidth
            />
            <ReferenceField
                label="Unidad Funcional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                linkType={false}
                fullWidth
            >
                <TextField label="" source="codigo" />
            </ReferenceField>
            
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    BooleanInput, ReferenceInput, SelectInput, DateInput, required, AutocompleteInput,
    TextInput, NumberInput, ArrayInput, SimpleFormIterator, Loading
} from 'react-admin';
import { CurrencyInput } from '../../inputs/CurrencyInput'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import config from '../../config/config';
import { styles } from './../../EstilosGrid';

let urlAppjs = config.UrlServer;

class EditElement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Elementos: [],
            datos: {
                rutinario: [],
                periodico: [],
                rehabilitacion: [],
                cambio: [],
                unidadMedida: [],
                tipoElementos: []
            }

        }
        this.fetcher.bind(this);
        this.returnComponent.bind(this);

    }

    componentDidMount() {
        fetch(urlAppjs + "/unidad_medidas.json?pagination=off",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((unidades) => {
                this.state.datos.unidadMedida = unidades;
            })
        fetch(urlAppjs + "/tipo_elementos.json?pagination=off&sistema=" + this.props.sistema,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((tipoElementos) => {
                this.state.datos.tipoElementos = tipoElementos;

                this.fetcher("politicas_elementos", "rutinario");
                this.fetcher("politicas_elemento_periodicos", "periodico");
                this.fetcher("politicas_elemento_rehabilitacions", "rehabilitacion");
                this.fetcher("politicas_elemento_cambios", "cambio");
            })
    }

    fetcher(target, container) {
        fetch(urlAppjs + "/" + target + ".json?pagination=off&politicasMantenimiento=" + this.props.politica,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.setState({ Elementos: Elementos })
                var datos = [];
                var unidad = "";
                var abreviatura
                var nombreElemento = "";
                if (this.state.Elementos.hasOwnProperty("length")) {
                    this.state.Elementos.map(index => {
                        this.state.datos.unidadMedida.map(record => {
                            if (record.id == index.unidadMedida.split("/")[2]) {
                                unidad = "/unidad_medidas/" + record.id;
                                abreviatura = record.abreviatura;
                            }
                        })
                        this.state.datos.tipoElementos.map(record => {
                            if (record.id == index.tipoElemento.split("/")[2]) {
                                nombreElemento = record.nombre;
                            }
                        })
                        datos = {
                            sistema: index.sistema,
                            tipoElemento: index.tipoElemento,
                            nombreElemento: nombreElemento,
                            operacion : index.operacion,
                            seRealiza: index.seRealiza,
                            unidadMedida: unidad,
                            abreviatura : abreviatura,
                            periocidad: index.periocidad,
                            valorU: index.valorU,
                            prolVidaUtil : index.prolVidaUtil,
                            fuente: index.fuente,
                            fecha: index.fecha,
                            descripcion: index.descripcion
                        }
                        this.state.datos[container].push(datos);
                        this.forceUpdate();

                    })
                } else {
                    console.log("datos?")
                    datos = {
                        sistema: this.state.Elementos.sistema,
                        tipoElemento: this.state.Elementos.tipoElemento,
                        nombreElemento: this.state.Elementos.nombreElemento,
                        seRealiza: this.state.Elementos.seRealiza,
                        unidadMedida: this.state.Elementos.unidadMedida,
                        periocidad: this.state.Elementos.periocidad,
                        valorU: this.state.Elementos.valorU,
                        fuente: this.state.Elementos.fuente,
                        prolVidaUtil : this.state.Elementos.prolVidaUtil,
                        fecha: this.state.Elementos.fecha,
                        descripcion: this.state.Elementos.descripcion
                    }
                    this.state.datos[container].push(datos);
                    this.forceUpdate();
                }
            })
    }

    returnComponent(source, classes) {
        var arraySource = "";
        switch(source) {
            case "rutinario":
                arraySource = "politicasElementos"
            break;
            case "periodico":
                arraySource = "politicasElementoPeriodicos"
            break;
            case "rehabilitacion":
                arraySource = "politicasElementoRehabilitacions"
            break;
            case "cambio":
                arraySource = "politicasElementoCambios"
            break;
        }
        const diligenciado = (value) => {
        // console.log(source, value)
            var errorMsg = [];
            var returnMsg = "";
            var today = new Date();

            var month = "";
            if ((today.getMonth() + 1) < 10) {
                month = "0" + (today.getMonth() + 1)
            } else {
                month = (today.getMonth() + 1)
            }
            today = today.getFullYear() + "-" + month + "-" + today.getDate();
            if (typeof value !== 'undefined') {
                for (var i = 0; i < value.length; i++) {
                    if (value[i].seRealiza == true) {

                        if (value[i].periocidad == null || value[i].periocidad == "" ||
                            value[i].valorU == null || value[i].valorU == "" ||
                            value[i].fuente == null || value[i].fuente == "" ||
                            value[i].fecha == null || value[i].fecha == "" ||
                            value[i].descripcion == null || value[i].descripcion == ""
                        ) {
                            errorMsg.push("Faltan datos de " +
                                value[i].nombreElemento +
                                ". ");
                        } else {
                            if (value[i].fecha && value[i].fecha.toString().split("T")[0] > today) {
                                errorMsg.push("La fecha indicada en " +
                                    value[i].nombreElemento + " " +
                                    "no puede ser futura. ");
                            } else {
                                errorMsg = [];
                            }
                        }
                    }
                }
            }
            if (errorMsg.length > 0) {
                for (var i = 0; i < errorMsg.length; i++) {
                    returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
                }
                // errorMsg.map( msg => {returnMsg = returnMsg + msg})
                return returnMsg;
            }
        }
        return (
            <ArrayInput
                label=" "
                source={arraySource + "2"}
                validate={[diligenciado]}
                defaultValue={this.state.datos[source]}
            >
                <SimpleFormIterator disableAdd disableRemove>
                    {/**valores ocultos necesarios no diligenciables */}
                    <TextInput id={source + ".sistema"} disabled label="sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                    <TextInput id={source + ".tipoElemento"} disabled label="tipoElemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                    <TextInput disabled label="Unidad" source="unidadMedida" fullWidth formClassName={classes.ocultar} />
                    {/**------------------------------------------------- */}
                    <BooleanInput id={source + ".seRealiza"} label="Se Realiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                    <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} />
                    {source === "cambio" &&
                        <SelectInput 
                            id={source + ".operacion"} 
                            label="Tipo de Operación" 
                            source="operacion" 
                            fullWidth 
                            formClassName={classes.fieldCol12}
                             choices={[
                                    { id: 'reconstruccion', name: 'Reconstrucción' },
                                    { id: 'cambio', name: 'Cambio' }
                                ]} 
                            formClassName={classes.fieldCol3}
                        />
                    }
                    <br />
                    <TextInput id={source + ".unidad"} disabled label="Unidad" source="abreviatura" fullWidth formClassName={classes.fieldCol1} />
                    {source === "rutinario" &&
                        <AutocompleteInput 
                            label="Periodicidad" 
                            source="periocidad" 
                            fullWidth 
                            options={{fullWidth: true}}
                            choices={[
                                    { id: 1, name: '1 Mes' },
                                    { id: 3, name: '3 Meses' },
                                    { id: 6, name: '6 Meses' },
                                    { id: 9, name: '9 Meses' },
                                    { id: 12, name: '12 Meses' },
                                    { id: 18, name: '18 Meses' },
                                ]} 
                            formClassName={classes.fieldCol2}
                        />
                    }
                    {source === "periodico" &&
                        <AutocompleteInput 
                            label="Periodicidad" 
                            source="periocidad" 
                            fullWidth 
                            options={{fullWidth: true}}
                            choices={[
                                    { id: 1, name: '1 Mes' },
                                    { id: 3, name: '3 Meses' },
                                    { id: 6, name: '6 Meses' },
                                    { id: 9, name: '9 Meses' },
                                    { id: 12, name: '12 Meses' },
                                    { id: 18, name: '18 Meses' },
                                    { id: 24, name: '24 Meses' },
                                    { id: 36, name: '36 Meses' },
                                ]} 
                            formClassName={classes.fieldCol2}
                        />
                    }
                    {source === "rehabilitacion" &&
                            <AutocompleteInput 
                                label="Periodicidad" 
                                source="periocidad" 
                                fullWidth 
                                options={{fullWidth: true}}
                                choices={[
                                        { id: 2, name: '2 Años' }, { id: 2.5, name: '2,5 Años' },
                                        { id: 3, name: '3 Años' }, { id: 3.5, name: '3,5 Años' },
                                        { id: 4, name: '4 Años' }, { id: 4.5, name: '4,5 Años' },
                                        { id: 5, name: '5 Años' }, { id: 5.5, name: '5,5 Años' },
                                        { id: 6, name: '6 Años' }, { id: 6.5, name: '6,5 Años' },
                                        { id: 7, name: '7 Años' }, { id: 7.5, name: '7,5 Años' },
                                        { id: 8, name: '8 Años' }, { id: 8.5, name: '8,5 Años' },
                                        { id: 9, name: '9 Años' }, { id: 9.5, name: '9,5 Años' },
                                        { id: 10, name: '10 Años'}
                                    ]} 
                                formClassName={classes.fieldCol2}
                            />
                    }
                    {source === "cambio" &&
                            <AutocompleteInput 
                                label="Periodicidad" 
                                source="periocidad" 
                                fullWidth 
                                options={{fullWidth: true}}
                                formClassName={classes.fieldCol12}
                                choices={[
                                        { id: 3, name: '3 Años' },   { id: 4, name: '4 Años' },   { id: 5, name: '5 Años' },
                                        { id: 6, name: '6 Años' },   { id: 7, name: '7 Años' },   { id: 8, name: '8 Años' },
                                        { id: 9, name: '9 Años' },   { id: 10, name: '10 Años' }, { id: 11, name: '11 Años' },
                                        { id: 12, name: '12 Años' }, { id: 13, name: '13 Años' }, { id: 14, name: '14 Años' },
                                        { id: 15, name: '15 Años' }, { id: 16, name: '16 Años' }, { id: 17, name: '17 Años' },
                                        { id: 18, name: '18 Años' }, { id: 19, name: '19 Años' }, { id: 20, name: '20 Años' },
                                        { id: 21, name: '21 Años' }, { id: 22, name: '22 Años' }, { id: 23, name: '23 Años' },
                                        { id: 24, name: '24 Años' }, { id: 25, name: '25 Años' }, { id: 26, name: '26 Años' },
                                        { id: 27, name: '27 Años' }, { id: 28, name: '28 Años' }, { id: 29, name: '29 Años' },
                                        { id: 30, name: '30 Años' }
                                    ]} 
                                formClassName={classes.fieldCol2}
                            />
                    }
                    
                    {source === "rutinario" &&
                        <CurrencyInput id={source + ".valorU"} label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} />
                    }
                    {source === "periodico" &&
                        <CurrencyInput id={source + ".valorU"} label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} />
                    }
                    {source === "rehabilitacion" &&
                            <CurrencyInput id={source + ".valorU"} label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />
                    }
                    {source === "cambio" &&
                            <CurrencyInput id={source + ".valorU"} label="Valor Unitario" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />
                    }
                    
                    {source === "rutinario" &&
                        <TextInput id={source + ".fuente"} label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                    }
                    {source === "periodico" &&
                        <TextInput id={source + ".fuente"} label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                    }
                    {source === "rehabilitacion" &&
                        <TextInput id={source + ".fuente"} label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />
                    }
                    {source === "rehabilitacion" &&
                        <NumberInput id={source + ".vidaUtil"} label="Vida Útil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol2} />
                    }
                    
                    {source === "cambio" &&
                        <TextInput id={source + ".fuente"} label="Fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />
                    }
                    {source === "cambio" &&
                        <NumberInput id={source + ".vidaUtil"} label="Vida Útil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol2} />
                    }
                    <DateInput id={source + ".fecha"} label="Fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />
                    <br />
                    <TextInput label="Descripción" source="descripcion" fullWidth />
                </SimpleFormIterator>
            </ArrayInput>

        )

    }



    render() {
        const { classes } = this.props;
        if (this.state.datos["rutinario"].length &&
            this.state.datos["periodico"].length &&
            this.state.datos["rehabilitacion"].length &&
            this.state.datos["cambio"].length
        ) {
            return (
                <Fragment>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Elementos para Mantenimiento Rutinario</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("rutinario", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Elementos para Mantenimiento Periódico</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("periodico", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Elementos para Mantenimiento Rehabilitación</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("rehabilitacion", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >Elementos para Mantenimiento Reconstrucción/Cambio</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("cambio", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Fragment>
            )
        } else {
            return <Loading />
        }

    }
}

EditElement.propTypes = {
    classes: PropTypes.object.isRequired,
}
const EditElements = withStyles(styles)(EditElement);


export default EditElements;
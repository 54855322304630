import React from 'react';
import {
    ShowController, TabbedForm, FormTab, ShowView,
    TextField, ReferenceField, NumberField, ArrayField, SingleFieldList, Datagrid,
    DateField, Toolbar
} from 'react-admin';
import { PField } from './../../../../fields/PField';
import { PRField } from './../../../../fields/PRField';
import { PercentageField } from './../../../../fields/PercentageField';
import { withStyles } from '@material-ui/core';
import { GMapInput } from '../../../../inputs/GMapInput';
import Divider from '@material-ui/core/Divider';
import { styles } from '../../../../EstilosGrid';
import Typography from '@material-ui/core/Typography';
import { ButtonCarousel } from './../../../images/ButtonCarousel';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoFlexibleShow = withStyles(styles)(({ classes, record, ...props }) => {

    return (
        <ShowController title="Información Segmento Flexible" {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Segmento Flexible" actions={<ButtonCarousel />} >
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="Información Básica">
                            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField label="" source="codigo" />
                            </ReferenceField>
                            <br/>
                            <ReferenceField label="Abscisa Inicial" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abInicio" />
                            </ReferenceField>
                            <ReferenceField label="Abscisa Final" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abFin" />
                            </ReferenceField><NumberField label="Longitud (km)" source="segmento_.fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <ReferenceField label="PR Inicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4} >
                                <PField label="" source="pInicio" />
                            </ReferenceField>
                            <ReferenceField label="PR Fin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PField label="" source="pFin" />
                            </ReferenceField><span></span>

                            <b>Localización</b>
                            <Divider fullWidth style={{ marginTop: '10px' }} />

                            <TextField label="Departamento" source="segmento_.municipio_.departamento_.nombre"  fullWidth formClassName={classes.grid_cont4} />  
                            <TextField label="Municipio" source="segmento_.municipio_.nombre"  fullWidth formClassName={classes.grid_cont4}/> 
                            <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="Latitud" source="segmento_.latitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud" source="segmento_.longitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="Altura (m.s.n.m)" source="segmento_.alturaIni"fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont3} />
                            <Typography variant="b" component="b">Coordenadas Finales</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="Latitud" source="segmento_.latitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud" source="segmento_.longitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="Altura (m.s.n.m)" source="segmento_.alturaFin" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />

                            {/* <b>Características</b>

                            <Divider fullWidth />
                            <ReferenceField label="Tipo Calzada" source="segmento_.tipoCalzada" reference="tipo_calzadas" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo Segmento" source="segmento_.tipoSegmento" reference="tipo_segmentos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo de superficie" source="segmento_.tipoSuperficie" reference="tipo_elementos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <BooleanField  source="segmento_.estado" fullWidth formClassName={classes.grid_cont4}/> 
                            <span></span> */}

                            <GMapInput
                                defaultZoom={8}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="segmento_.ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={"/imagenes/senalesColombia/Elementos/segmento_inventaro/Flexible.png"}
                            />
                        </FormTab>
                        <FormTab label="Detalles">
                            <div></div><div></div><div></div>
                            <ArrayField source="detallesSegmento" label="Estado" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="estadoDetalles" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record  &&  controllerProps.record.detallesSegmento[0].estado_detalles === "Inactivo" &&
                                <ArrayField source="detallesSegmento" label="Razón" linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="estadoRazon" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br />
                            <ArrayField source="detallesSegmento" label="Fecha de Inicio Operación" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="dateInicioOperacion" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="Proveedor" formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <ReferenceField linkType={false}  source="proveedor" reference="proveedors" >
                                        <TextField source="razonSocial" />
                                    </ReferenceField >       
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="Garantía (Meses)" formClassName={classes.grid_cont3} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList >
                                    <NumberField source="garantiaMeses" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <strong>Tráfico</strong>
                            <br/>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <br/>
                            <ArrayField source="detallesSegmento" label="ESAL (Año)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="Nivel de Tráfico" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="nivelTrafico" />
                                </SingleFieldList>
                            </ArrayField>
                            <bt />
                            <ArrayField source="detallesSegmento" label="ESAL (Proyección 10 Años)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime10" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="ESAL (Proyección 20 Años)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime20" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <br/>
                            <ArrayField source="detallesSegmento" label="Observaciones" linkType={false} formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesObservaciones" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="Geometría">
                            <div></div><div></div><div></div>
                            <div><strong>Detalles de Calzada</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" label="Largo (m)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Ancho de la Calzada (m)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAncho" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <div></div>
                            <div><strong>Detalles de Carriles</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" label="Número de Carriles" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaNumCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Ancho por Carril (m)" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoCarril" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="geometriaSegmento" label="Tipo de Berma" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaTipoBerma !== "Inexistente" &&
                            <ArrayField source="geometriaSegmento" label="Ancho de Berma (m)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmento" label="Condición de Berma" linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="geometriaCondicionBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaTipoBerma !== "Inexistente" &&
                                <div></div>
                            }
                            <br/>
                            <ArrayField source="geometriaSegmento" label="Área de Carriles (m²)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="sumaAreaCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Área verdadera Carriles (m²)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="areaCarrilesVerdadera" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <div></div>
                            <div><strong>Propiedades geométricas</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" label="Pendiente Longitudinal" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Pendiente Longitudinal Máxima" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Pendiente Longitudinal Mínima" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            {/* -----------RADIO--------- */}
                            <ArrayField source="geometriaSegmento" label="Pendiente Transversal" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaTransversal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Radio Mínimo (m)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}> 
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Radio Máximo (m)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            {/* -------------------- */}
                            <ArrayField source="geometriaSegmento" label="Radio de Curvatura (°/km)" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioCurvatura" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Velocidad de Diseño (km/h)" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaVelDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField label="Con Separador" source="geometriaSegmento" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaSeparador" />
                                </SingleFieldList>
                            </ArrayField>
                {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaSeparador === "Si" &&
                            <ArrayField source="geometriaSegmento" label="Ancho Separador (m)" fullWidth linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaSeparadorAncho" />
                                </SingleFieldList>
                            </ArrayField>
                }
                            <div></div>
                            <br />
                            <ArrayField label="Condiciones Geométricas" source="geometriaSegmento" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList >
                                    <TextField source="geometriaCondicionesGeometrica" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Alineamiento" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaAlineamiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="Tipo de Terreno" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoTerreno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" linkType={false} label="Observaciones">
                                <SingleFieldList>
                                    <TextField source="detallesGeometria" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="Estructura">
                            <div></div><div></div><div></div>
                            <div><strong>Información Estructura</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField
                                source="tablaSegmento"
                                style={{ width: '100%' }}
                                label=" "
                                className="inventariosSegmento"
                            >
                                <Datagrid>
                                    <NumberField source="estructuraEspesor" label="Espesor (mm)" />
                                    <NumberField source="estructuraModulo" label="Módulo (MPa)" />
                                    <NumberField source="estructuraDs" label="Desviación Estándar (Std)" />
                                    
                                    <ReferenceField linkType={false} label="Material" source="material" reference="materials" >
                                        <TextField source="material" />
                                    </ReferenceField > 

                                    <ReferenceField linkType={false} label="Especificación" source="material" reference="materials" >
                                        <TextField source="especificacion" />
                                    </ReferenceField >
                                {controllerProps.record && controllerProps.record.tablaSegmento[0].granulometria === null &&
                                    // <p>No Definida</p>
                                    <TextField label="Granulometria" source="granulometria"/>
                                }
                                {controllerProps.record && controllerProps.record.tablaSegmento[0].granulometria !== null &&
                                    <ReferenceField linkType={false} label="Granulometría" source="granulometria" reference="granulometrias" >
                                        <TextField source="granulometria" />
                                    </ReferenceField >
                                }

                                </Datagrid>
                            </ArrayField>
                            {/* <TablaSegmentos tabla="flexible_detalles" id={IdSegmento} />              */}
                            <div></div><div></div>
                            <NumberField label="Espesor total (mm)" source="espensorTotal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "60%"}} />
                            <div></div><div></div>
                            <div className={classes.subtittle}><strong>Capacidad estructural</strong></div><div></div><br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmento" label="Número Estructural de Diseño" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="Fecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmento" label="Número Estructural Efectivo" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumero" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="Fecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraNumeroFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmento" label="CBR (%)"  linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="estructuraCbr" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="Fecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraCbrFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            
                            <div></div>
                            <div><strong>Fechas importantes</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmento" label="Fecha de Construcción" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaConst" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="Fecha de Última Rehabilitación" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaRehab" />
                                </SingleFieldList>
                            </ArrayField>
                            <div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmento" label="Observaciones"linkType={false} >
                                <SingleFieldList>
                                    <TextField source="detallesEstructura" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="AuditorÍa">
                            <DateField source="createdAt" label="Fecha Creación" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdBy" label="Creado Por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha Actualización" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedBy" label="Actualizado Por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
});

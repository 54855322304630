import React, { useState } from 'react';
import { TextInput, NumberField, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField, TabbedForm, ListButton, EditButton,  FormTab } from 'react-admin';
import { GMapInput } from '../../../inputs/GMapInput';
import { PRField } from './../../../fields/PRField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';

import { ButtonCarousel } from './../../images/ButtonCarousel';



export const ElementoRedShow = withStyles(styles)(({ classes, record, ...props }) => {
    // const [segmento, setSegmento] = useState('');
    // const [tabla, setTabla] = useState('flexible_detalles');

    // const ValidarSegmento = (value) => {


    //     if (value) {

    //         var Datos = []

    //         let urlApi = urlAppjs + value;

    //         fetch(urlApi)
    //             .then((response) => {
    //                 console.log("REST");
    //                 return response.json()
    //             })
    //             .then((TablaEstructura) => {
    //                 console.log(TablaEstructura);
    //                 setSegmento(TablaEstructura["tipoSuperficie"].id);
    //                 console.log(TablaEstructura["tipoSuperficie"].id);
    //             })

    //         console.log("HOOK: " + segmento)

    //         if (segmento === "PFLEX") { setTabla("flexible_detalles") }
    //         if (segmento === "PRIGD") { setTabla("rigido_detalles") }
    //         if (segmento === "CLZ") { setTabla("adoquin_detalles") }
    //         if (segmento === "POTR") { setTabla("otro_detalles") }

    //     }

    // }

    //let elemento = "/tipo_elementos/COMR";
    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }
    return (
        <ShowController {...props} title="Información Elemento Red">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información de la Red" actions={<ButtonCarousel/>}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="IDENTIFICACIÓN">
                            <TextField label="Código" source="fullCodigo" formClassName={classes.grid_cont12} sortable={false}/>
                            <TextField label="Consecutivo" source="fullConsecutivo" formClassName={classes.grid_cont4_f} />
                            <ReferenceField label="Tipo de elemento" source="tipoElemento" reference="tipo_elementos" linkType={false} formClassName={classes.grid_cont4}>
                                <TextField source="nombre" fullWidth />
                            </ReferenceField>
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
                            <TextField source="margen" fullWidth formClassName={classes.grid_cont4} />
                            <Typography variant="b" component="b" fullWidth formClassName={classes.grid_cont12}>Localización</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />

                            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4_f}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <Typography variant="b" component="b">Coordenadas</Typography>
                            <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                            <PRField label="Abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                            <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            <GMapInput style={{width : "100%"}}
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {imagen} 
                            />
                            </FormTab>
                        <FormTab label="Características">
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="Entorno de la vía" source="entornoVia" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="Clase de iluminación" source="claseIluminacion" formClassName={classes.grid_cont4_s}/>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="Tipo de lampara" source="tipoLampara" fullWidth formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="Brazo Tipo" source="brazoTipo" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="Disposición de las luminarias" source="disposicionIluminaria" fullWidth formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="Valor de luminancia promedio  (cd/m²)" source="valorIluminancia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="Ángulo de inclinación del brazo (°)" source="anguloInclinacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="Largo (m)" source="largo"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="Altura (m)" source="altura"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="Empresa" source="empresa_.empresa" reference="servicios_publicos" linkType={false} fullWidth formClassName={classes.grid_cont12} />
                            }

                            {/* ---------ELEMENTO = SEMAFORO -------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <TextField label="Clase" source="entornoVia" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <TextField label="Clasificación de los soportes" source="claseIluminacion" fullWidth formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <div formClassName={classes.grid_cont4_s}></div>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <NumberField label="Largo del módulo luminoso (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <NumberField label="Ancho del módulo luminoso (m)" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <NumberField label="Altura (m)" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <span fullWidth formClassName={classes.grid_cont4}></span>
                            }
                            {/* --------- ELEMENTOS BÁSCULA DE PESAJE  ----------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <TextField label="Marca" source="entornoVia" fullWidth formClassName={classes.grid_cont6_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <NumberField label="Capacidad (t)" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont6_s} />

                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <NumberField label="Largo (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont6_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <NumberField label="Ancho (m)" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont6_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <ReferenceField label="Nivel de tráfico" source="nivelTrafico" reference="flexible_detalles" linkType={false}  fullWidth formClassName={classes.grid_cont6_f}>
                                    <TextField source="nivel_trafico" />
                                </ReferenceField>
                            }
                            {/* {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&

                                <ReferenceField label="Nivel de tráfico" source="nivelTrafico" reference={tabla} linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="nivel_trafico" fullWidth />
                                </ReferenceField>
                            } */}



                            {/* --------- ELEMENTOS RESTANTES  ----------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <TextField label="Material" source="entornoVia" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <TextField label="Sección" source="claseIluminacion" fullWidth formClassName={classes.grid_cont4} />

                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <span fullWidth formClassName={classes.grid_cont4}></span >
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <NumberField label="Largo/Diámetro (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <NumberField label="Base (m)" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <NumberField label="Altura (m)" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <TextField label="Empresa" source="empresa_.empresa" reference="servicios_publicos" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <span fullWidth formClassName={classes.grid_cont4}></span>
                            }


                        </FormTab>
                        <FormTab label="Detalles">

                            <TextField label="Condición del elemento" fullWidth source="estado" fullWidth formClassName={classes.grid_cont4_f} />
                            {controllerProps.record && controllerProps.record.estado != "Reemplazo" &&
                            <div formClassName={classes.grid_cont8}></div>
                            }
                            {controllerProps.record && controllerProps.record.estado === "Reemplazo" &&
                                <TextField label="Motivo de reemplazo" source="motivoReemplazo" fullWidth formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.estado === "Reemplazo" &&
                                <TextField 
                                label="Elemento que Reemplazo"
                                source="fullCodigoElemReem"
                                formClassName={classes.grid_cont4_s}
                            />
                            
                            }
                            <br/>
                            <TextField label="Estado" source="estados" fullWidth formClassName={classes.grid_cont4_f}/>
                            <br/>
                            <DateField label="Fecha de instalación" source="fechaInstalacion" fullWidth formClassName={classes.grid_cont4_f} />
                            <ReferenceField label="Proveedor" source="proveedor" reference="proveedors" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="Garantía (meses)" source="garantia" fullWidth fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont4_f} />
                            <TextField source="observaciones" formClassName={classes.grid_cont12} />
                           
                        </FormTab>
                        <FormTab label="AuditorÍa">
                            <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
});
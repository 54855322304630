import React, { Fragment, useState } from 'react';

import {
    List, Datagrid, TextField, EditButton,BooleanField, ShowButton, ReferenceField, Filter,
    ReferenceInput, SelectInput, NumberField, BulkDeleteWithConfirmButton, AutocompleteInput
} from 'react-admin';

import { PRField } from './../../../fields/PRField';

const FiltroDreanje = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="Unidad Funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="Tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <SelectInput
                fullWidth
                label="Estado"
                source="estado"
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
                choices={[
                    { id: '1', name: 'Activo' },
                    { id: '0', name: 'Inactivo' },
                ]}
                resettable
            />


</Filter>


    )
};
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
export const PeajesinventarioList = props => (
    <List title="Lista Inventario Peajes" {...props}  filters={<FiltroDreanje />} bulkActionButtons={<PostBulkActionButtons />} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid >

            <TextField label="Nombre" source="nombre" />
            <TextField label="Código" source="codigo"/>
            <ReferenceField label="Unidad Funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false}>                
                    <TextField source="fullCodigos" />                
            </ReferenceField>
            <PRField label="Progresiva (km)" source="abInicio"/>
            <NumberField label="Estaciones manuales" source="estacion_manual"/>
            <NumberField label="Estaciones automáticas" source="estacion_automatica"/>
            <NumberField label="Total estaciones" source="estacion"/>
            <BooleanField label="Estado" source="estado"/>
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
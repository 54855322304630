import React, {useState} from 'react';
import { List,NumberField,Datagrid, TextField,BooleanField, EditButton, ShowButton, AutocompleteInput, Filter, ReferenceInput, SelectInput } from 'react-admin';
import { PField } from './../../fields/PField';
import { PRField } from './../../fields/PRField';
import {  WithPermissions,BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const SegmentoFilter = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
       <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Tipo de Calzada" 
            source="tipoCalzada" 
            reference="tipo_calzadas" 
            alwaysOn 
            sort={{field : 'customOrder' , order : 'ASC'}}
        >
            <SelectInput optionText="nombre" />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Tipo de segmento" 
            source="tipoSegmento" 
            reference="tipo_segmentos" 
            sort={{field : 'customOrder' , order : 'ASC'}} 
            filter={{sistema: 'CLZ'}} alwaysOn
        >
            <SelectInput optionText="nombre" />
        </ReferenceInput>
        <ReferenceInput 
            label="Tipo Superficie" 
            source="tipoSuperficie" 
            reference="tipo_elementos" 
            sort={{field : 'customOrder' , order : 'ASC'}} 
            filter={{sistema: 'CLZ'}} 
            alwaysOn
            >
            <SelectInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
    )
};

const PostBulkActionButtons = props => {

    var segmento = localStorage.getItem("segmento");

    return(

    <WithPermissions
            render={({ permissions }) => (
                 "1" === segmento                 
        ? 
        <Fragment>
            <BulkDeleteWithConfirmButton {...props}/>
        </Fragment>
        : null
        )}
    />

    )};

    const PostActions =({basePath,
        currentSort,    
        exporter,    
        filterValues,   
        resource,    
        total})=>{
    var segmento = localStorage.getItem("segmento");
    return(
            <WithPermissions
            render={({ permissions }) => (
                "1" === segmento   ||    "2" === segmento           
        ? 
        <CardActions>
            <CreateButton basePath={basePath} />
            <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        </CardActions>
        

        :  <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
    />
        )}
        />
        
    )
};
// 
export const SegmentoList = props => (
    <List {...props} 
        title="Lista de Segmentos - Ramales"
        perPage={5} 
        sort={{ field: "updatedAt", order: 'DESC' }}  
        filters={<SegmentoFilter/>} 
        actions={<PostActions/>} 
        bulkActionButtons={<PostBulkActionButtons />} 
    >
        <Datagrid>                        
            <TextField label="Código" source="codigo" />     
            <PField label="PR inicio" source="pInicio"/>
            <PField label="PR fin" source="pFin"/>
            <PRField label=" Abscisa inicio" source="abInicio"/>
            <PRField label="Progresiva fin" source="abFin"/>
            <NumberField label="Longitud (km)"  source="fullResta" sortable={false}/>
            <TextField label="Tipo Calzada" source="calzadaNombre"  />
            <TextField label="Tipo Segmento" source="segmentoNombre"  />
            <TextField label="Tipo Superficie" source="superficieNombre" /> 
            <BooleanField  source="estado" sortable={false}/> 
                      
            <ShowButton />
            {
                localStorage.getItem("segmento") === "1" || localStorage.getItem("segmento") === "2" ? <EditButton /> : null
            }            
        </Datagrid>
    </List>
);
import React, {useState} from 'react';
import { List, SimpleForm ,Datagrid, TextField, DateField, Toolbar, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, CardActions, BulkDeleteWithConfirmButton, AutocompleteInput, CreateButton, SelectInput } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import VisualizacionModal from './visualizacionModal';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);  
const CargarInformacionFilter = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
       <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput label="Indicador" alwaysOn source="indicador" reference="parametros" sort={{ field: 'id', order: 'ASC' }}>
            <SelectInput optionText="codigoCompleto" />
        </ReferenceInput>
    </Filter>
    )
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function SimpleDialog(props) {
    const { onClose, open } = props;
    const handleClose = value => {
        onClose(value);
      };
    const CustomToolbar = ({ basePath, data,...props}) => (
    <Toolbar {...props} >
        <Button variant="secondary" id="generarInforme" onClick={handleClose} style={{backgroundColor: '#3f51b5', color:'#fff'}}>
            Cerrar
        </Button>       
    </Toolbar>
    );  
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    const [parametro, setParametro]=useState('');
    const ValidarParametro = (value)=>{
        setParametro(value)
    }
      return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" TransitionComponent={Transition} open={open}>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{textAlign: "right"}}>
              <CloseIcon />
            </IconButton>
            <DialogTitle id="simple-dialog-title">Visualizar Indicadores</DialogTitle>
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container spacing={24}>
                    <Grid item xs={3}>        
                        <ReferenceInput 
                        id="unidadFuncional"
                        label="Unidad Funcional" 
                        source="unidadFuncional"  
                        reference="unidad_funcionals" 
                        filterToQuery={searchText => ({ codigo: searchText })} 
                        sort={{field : 'codigo', order : 'asc'}}
                        validate={[ufSet]}
                        alwaysOn
                        fullWidth 
                        >
                            <SelectInput  options={{fullWidth : true}} optionText="codigo"  />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={3}>
                        <ReferenceInput 
                        label="Tramo" 
                        source="tramo" 
                        alwaysOn 
                        reference="tramos" 
                        filter={{ unidadFuncional: uf}}
                        validate={[tramoSet]}
                        filterToQuery={searchText => ({ codigo: searchText })} 
                        sort={{ 
                            field: 'codigo', 
                            order: 'ASC' ,
                        }}
                        >
                            <SelectInput  options={{fullWidth : true}} optionText="codigo"  />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={5}>
                        <ReferenceInput label="Indicador" source="indicador" reference="parametros" sort={{ field: 'id', order: 'ASC' }} fullWidth  validate={ValidarParametro}>
                            <SelectInput optionText="codigoCompleto" />
                        </ReferenceInput>
                    </Grid>
                    {uf && tramo && parametro &&
                    <Grid item xs={12}>
                        <VisualizacionModal ufuncional={uf.replace("/unidad_funcionals/", "")} tramo={tramo.replace("/tramos/", "")} indicador={parametro.replace("/parametros/", "")}/>
                    </Grid>
                    }
                </Grid>
            </SimpleForm>
        </Dialog>
      )
}
const PostEditActions = ({ basePath, data, resource }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return(
        <CardActions>
            {/* <LinkToMapa style={{paddingTop:"13px", marginRight:"20px"}}/> */}
            <CreateButton basePath={basePath} style={{paddingTop:"13px", marginRight:"20px"}}/>            
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <VisibilityIcon/> Visualización
            </Button>            
            <SimpleDialog open={open} onClose={handleClose} />
        </CardActions>
    );
};
export const CargarInformacionList = props =>(
    <List {...props} tittle="Lista de Carga de Información" perPage={25} sort={{ field: 'updatedAt', order: 'DESC' }}  filters={<CargarInformacionFilter/>} actions={<PostEditActions />} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid>                        
        <ReferenceField label="Indicador" source="indicador" reference="parametros" linkType={false}>
            <TextField  source="codigoCompleto" /> 
        </ReferenceField>
        <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false}>
           <TextField source="fullCodigos" />
        </ReferenceField>
        <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false}>
           <TextField source="fullCodigos" />
        </ReferenceField> 
        <PRField label="Abscisa Inicio" source="abiniFloat" />
        <PRField label="Abscisa Fin" source="abfinFloat" /> 
        <DateField label="Fecha de medición" source="fechaMedicion" />
        <TextField label="Medido Por" source="medidoPor" />
        <TextField label="Estado" source="estado" />
        <TextField label="Tipo de Medición" source="tipoMedicion" />
        <ShowButton />
        <EditButton /> 
    </Datagrid>
</List>
);
import React, {useState, Fragment} from 'react';
import { NumberInput,SelectInput,ReferenceInput, TextInput,AutocompleteInput, number, required, minLength,  maxLength, minValue,
    maxValue, REDUX_FORM_NAME, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import { change } from 'redux-form';
import { SimpleForm } from './../SimpleForm';
import { Edit } from './../Edit';
import Grid from '@material-ui/core/Grid';
import {Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import MunicipioArray from './../proveedores/municipioArray';

export const Servicio_publicoEdit = props => {
    const [departamentoss , setDepartamento]= useState('');

    const ValidarDepartamento = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(value) 
    }

    const Departamento = { departamento: departamentoss };
    return(
    <Edit  {...props} title="Editar Servicio Público" undoable={false}>
        <SimpleForm submitOnEnter={false} redirect="list" >            
            <Grid container spacing={24}>
            <Grid item xs={4}>
            <ReferenceInput
                            label="Proyecto"
                            source="proyecto"
                            reference="proyectos"
                            disabled
                            fullWidth
                            validate={[required(), ValidarDepartamento]}
                            filterToQuery={searchText => ({ nombre: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid> 
            <Grid item xs={4}>
                    <SelectInput label="Grupo" source="grupo" choices={[
                        { id: 'Acueducto', name: 'Acueducto' },
                        { id: 'Aseo', name: 'Aseo' },
                        { id: 'Energía', name: 'Energía' },
                        { id: 'Gas natural', name: 'Gas natural' },
                        { id: 'Otros', name: 'Otros' },
                        { id: 'Telecomunicaciones', name: 'Telecomunicaciones' },
                    ]} fullWidth validate={[required()]}/>
                </Grid>                
                <Grid item xs={4}></Grid>
                <Grid item xs={3}>
                <SelectInput label="Tipo de identificación" source="identificacion" fullWidth allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'CC' },
                            { id: 'NIT', name: 'NIT' },
                            { id: 'Otro', name: 'Otro' },
                            
                        ]}validate={[required()]} />  
                </Grid>
                <Grid item xs={3}>
                    <NumberInput label="Número de Identificación" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10),minValue(1),maxValue(9999999999)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Nombres" source="nombre" fullWidth validate={[ minLength(2), maxLength(100)]}/>
                </Grid>                
                <Grid item xs={6}>
                    <TextInput label="Apellidos"  source="apellido" fullWidth validate={[ minLength(2), maxLength(100)]}/> 
                </Grid>
                
                <Grid item xs={4}>
                    <TextInput label="Dirección" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                </Grid>             
                <Grid item xs={8}>
                <FormDataConsumer>
                    {
                        ({ formData, dispatch, ...rest }) => (
                            <Fragment>
                                <Grid container spacing={24}>
                                    <Grid item xs={6}>
                                        <ReferenceInput  
                                            id="departamento" 
                                            value="" 
                                            label="Provincia" 
                                            source="departamento" 
                                            reference="departamentos"
                                            fullWidth 
                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                            onChange ={value => dispatch(
                                                            change(REDUX_FORM_NAME, 'municipio', null)
                                                        )}
                                            validate={[required(), ValidarDepartamento]}
                                        >
                                            <AutocompleteInput options={{fullWidth: true}}  optionText="nombre" fullWidth />
                                        </ReferenceInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReferenceArrayInput 
                                            label="Distrito" 
                                            source="municipio" 
                                            reference="municipios" 
                                            validate={[required()]} 
                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                            filter={{
                                                departamento : departamentoss
                                            }}
                                        >
                                            <AutocompleteArrayInput options={{fullWidth: true}} optionText="nombre" fullWidth/>
                                        </ReferenceArrayInput>
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ) 
                    }
                    </FormDataConsumer>  
               
                </Grid>   
                <Grid item xs={6}>
                    <TextInput label="Teléfono 1"   source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]}/> 
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Teléfono 2"  source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput label="Correo electrónico"  source="correo" fullWidth validate={[minLength(2), maxLength(100)]}/>
                </Grid>                
                
                <Grid item xs={12}>
                    <TextInput source="observaciones" fullWidth validate={[minLength(2), maxLength(250)]}/>
                </Grid>
                
            </Grid>
        </SimpleForm>
    </Edit>
)};
import React , { useState }from 'react';
import { ReferenceField, BooleanField, TabbedShowLayout, Tab, TextField, DateField, NumberField, FormTab,TextInput
} from 'react-admin';
import { GMapInput } from '../../../inputs/GMapInput';
import Typography from '@material-ui/core/Typography';
import { Show } from './../../Show';
import { PRField } from './../../../fields/PRField';
import Divider from '@material-ui/core/Divider';
import TabbedForm from '../../TabbedForm';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';


export const PeajesinventarioShow = withStyles(styles)(({ classes, record, ...props }) =>{

  const [imagen , setImagen] =useState(true);
  const ValidarImagen =  (value) =>{
      // console.log(value + "Aqui est la imagen ")
      if(value !== null && value !== undefined && value !== 'undefined')
      {
          let lenghtObject = Object.keys(value);
          let valor = '';        
          for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
          setImagen(value) 
          // console.log(value + "Aqui est la imagen ")
      }
   }
  return (
  <Show title="Información Inventario Peaje" {...props}>
    <TabbedForm toolbar={false}>
      <FormTab label="Identificación">

        <b>Detalles</b>
        <span></span>
        <Divider fullWidth/>

        <TextField label="Nombre de  Peaje" source="nombre" fullWidth formClassName={classes.grid_cont3s} />
        <NumberField label="Código" source="codigo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}/>
        <BooleanField label="Estado" source="estado" fullWidth formClassName={classes.grid_cont3}/>
        
        <br/>

        <NumberField label="N° Estaciones Manuales" source="estacion_manual" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }}formClassName={classes.grid_cont3s}/>
        <NumberField label="N° Estaciones Automaticas" source="estacion_automatica" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}/>
        <NumberField label="Total estaciones" source="estacion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}/>

        <br/>
        <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
        <Typography variant="h3" style={{ width: '100%' }} component="h3">Localización</Typography>
          <span></span>
          <span></span>
          <Divider fullWidth formClassName={classes.grid_cont12} />
          <ReferenceField label="Unidad Funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <Typography variant="b" component="b">Coordenadas</Typography>
        <span></span>
        <span></span>
        <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
        <PRField label="Abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
        <NumberField label="Latitud" source="latitudInicial" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
        <NumberField label="Longitud" source="longitudInicial" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
        {/* <GMapInput style={{width : "100%"}}
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {imagen} 
                            /> */}
      </FormTab>
      <FormTab label="Auditoría">
        <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont3}/>
        <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont3}/>
        <br/>
        <DateField source="updatedAt" label="Fecha actualización"formClassName={classes.grid_cont3} />
        <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont3}/>
      </FormTab>
    </TabbedForm>
  </Show>
  )
});

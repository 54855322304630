import React from 'react';
import {
    TabbedForm, Datagrid, ShowController, ShowView, TextField, DateField, ReferenceField,
    FormTab, ArrayField, SingleFieldList, NumberField, BooleanField, Toolbar
} from 'react-admin';
import { PRField } from './../../../../fields/PRField';
import { GMapInput } from '../../../../inputs/GMapInput';

import { PField } from './../../../../fields/PField';
import { PercentageField } from './../../../../fields/PercentageField';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { ButtonCarousel } from './../../../images/ButtonCarousel';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoAdoquinShow = withStyles(styles)(({ classes, record, ...props }) => {

    return (
        <ShowController title="Información Segmento Adoquín" {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Segmento Adoquín" actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="Información Básica">
                            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <TextField label="Segmento" source="segmento_.codigo" fullWidth formClassName={classes.grid_cont3} />
                            <ReferenceField label="Abscisa Inicial" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abInicio" />
                            </ReferenceField>
                            <ReferenceField label="Abscisa Final" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abFin" />
                            </ReferenceField><NumberField label="Longitud (km)" source="segmento_.fullResta" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <ReferenceField label="PR Inicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4} >
                                <PField label="" source="pInicio" />
                            </ReferenceField>
                            <ReferenceField label="PR Fin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PField label="" source="pFin" />
                            </ReferenceField><span></span>

                            <b>Localización</b>
                            <Divider fullWidth style={{ marginTop: '10px' }} />

                            <TextField label="Departamento" source="segmento_.municipio_.departamento_.nombre" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="Municipio" source="segmento_.municipio_.nombre" fullWidth formClassName={classes.grid_cont4} />

                            <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="Latitud" source="segmento_.latitud" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <TextField label="Longitud" source="segmento_.longitud" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="Altura (m.s.n.m)" source="segmento_.alturaIni" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont3} />
                            <Typography variant="b" component="b">Coordenadas Finales</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="Latitud" source="segmento_.latitud2" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud" source="segmento_.longitud2" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="Altura (m.s.n.m)" source="segmento_.alturaFin" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont3} />

                            {/* <b>Características</b>

                            <Divider fullWidth />
                            <ReferenceField label="Tipo Calzada" source="segmento_.tipoCalzada" reference="tipo_calzadas" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo Segmento" source="segmento_.tipoSegmento" reference="tipo_segmentos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo de superficie" source="segmento_.tipoSuperficie" reference="tipo_elementos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <BooleanField  source="segmento_.estado" fullWidth formClassName={classes.grid_cont4}/> 
                            <span></span> */}

                            <GMapInput
                                defaultZoom={8}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="segmento_.ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={"/imagenes/senalesColombia/Elementos/segmento_inventaro/Adoquin.png"}
                            />

                        </FormTab>
                        <FormTab label="Detalles">
                            <ArrayField source="detallesSegmentos" label="Estado" linkType={false} fullWidth formClassName={classes.grid_cont3} >
                                <SingleFieldList>
                                    <TextField source="estadoDetalles" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.detallesSegmentos[0].estadoDetalles === "Inactivo" &&
                                <ArrayField source="detallesSegmentos" label="Razón" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="estadoRazon" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <ArrayField source="detallesSegmentos" label="¿Incluir este elemento a un plan de mantenimiento?" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <BooleanField source="elementoMantenimiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="detallesSegmentos" label="Fecha de Inicio Operación" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="dateInicioOperacion" />
                                </SingleFieldList>
                            </ArrayField>

                            <ArrayField source="detallesSegmentos" label="Proveedor" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="proveedor_.razonSocial" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="Garantía (Meses)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="garantiaMeses" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <Typography variant="b" component="b">Tráfico</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="detallesSegmentos" label="ESAL (Año)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="Nivel de Tráfico" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="nivelTrafico" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="detallesSegmentos" label="ESAL (Proyección 10 Años)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime10" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="ESAL (Proyección 20 Años)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime20" />
                                </SingleFieldList>
                            </ArrayField>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="detallesSegmentos" linkType={false}  label="Observaciones" formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesObservaciones" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="Geometría">
                            <br></br>
                            <Typography variant="b" component="b">Detalles de Calzada</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="geometriaSegmentos" label="Largo (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Ancho de la Calzada (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAncho" />
                                </SingleFieldList>
                            </ArrayField>

                            <Typography variant="b" component="b">Detalles de Carriles</Typography>

                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="geometriaSegmentos" label="Número de Carriles" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaNumCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Ancho por Carril (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoCarril" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="Tipo de Berma" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmentos" label="Condición de Berma" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="geometriaCondicionBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmentos" label="Ancho de Berma (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <NumberField source="geometriaAnchoBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br />
                            <ArrayField source="geometriaSegmentos" label="Área de Carriles (m²)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="sumaAreaCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Área verdadera Carriles (m²)" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="areaCarrilesVerdadera" />
                                </SingleFieldList>
                            </ArrayField>

                            <Typography variant="b" component="b">Propiedades geométricas</Typography>

                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="geometriaSegmentos" label="Pendiente Longitudinal" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Pendiente Longitudinal Máxima" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Pendiente Longitudinal Mínima" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="Pendiente Transversal" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaTransversal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Radio Mínimo (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Radio Máximo (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="Radio de Curvatura (°/km)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioCurvatura" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Velocidad de Diseño (km/h)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaVelDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="Con Separador" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaSeparador" />
                                </SingleFieldList>
                            </ArrayField>

                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaSeparador === "Si" &&
                                <ArrayField source="geometriaSegmentos" label="Ancho Separador (m)" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <NumberField source="geometriaSeparadorAncho" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br />
                            <ArrayField source="geometriaSegmentos" label="Condiciones Geométricas" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaCondicionesGeometrica" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Alineamiento" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaAlineamiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Tipo de Terreno" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoTerreno" />
                                </SingleFieldList>
                            </ArrayField>

                            <Typography variant="b" component="b">Dimensiones de la losa típica</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />

                            <ArrayField source="geometriaSegmentos" label="Largo (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAdoLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Ancho (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAdoAncho" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="Alto (m)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAdoAlto" style={{ width: '30% !important' }} />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" linkType={false} label="Observaciones" formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesGeometria"  />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="Estructura">
                            <br></br>
                            <Typography variant="b" component="b">Información</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="tablaSegmentos" style={{ width: '100%' }} label=" " className="inventariosSegmentos" fullWidth formClassName={classes.grid_cont12}>
                                <Datagrid>
                                    <NumberField source="estructuraEspesor" label="Espesor (mm)" />
                                    <NumberField source="estructuraModulo" label="Módulo (MPa)" />
                                    <NumberField source="estructuraDs" label="Desviación Estándar (Std)" />
                                    <TextField label="Material" source="material_.material" />
                                    <TextField label="Especificación" source="material_.especificacion" />
                                    <TextField label="Granulometría" source="granulometria_.granulometria" />
                                </Datagrid>
                            </ArrayField>
                            <NumberField label="Espesor Total (mm)" source="espensorTotal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <br></br>
                            <Typography variant="b" component="b">Capacidad estructural</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="EstructuraSegmentos" label="Número Estructural de Diseño" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="Fecha" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <ArrayField source="EstructuraSegmentos" label="Número Estructural Efectivo" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumero" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="Fecha" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraNumeroFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <ArrayField source="EstructuraSegmentos" label="CBR (%)" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <PercentageField source="estructuraCbr" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="Fecha" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraCbrFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <Typography variant="b" component="b">Fechas importantes</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="EstructuraSegmentos" label="Fecha de Construcción" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaConst" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="Fecha de Última Rehabilitación" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaRehab" />
                                </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField label="Observaciones" source="EstructuraSegmentos" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <TextField source="detallesEstructura" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="AuditorÍa">
                            <DateField source="createdAt" label="Fecha Creación" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdBy" label="Creado Por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha Actualización" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedBy" label="Actualizado Por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
});
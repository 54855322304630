import React, { useState, Fragment } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, FormDataConsumer, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import config from '../../../config/config';
import { REDUX_FORM_NAME } from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { change } from 'redux-form';
import EditElementosMantenimientoParticular from './../localHelpers/EditElementosMantenimientoParticular';

import EditElements from './../EditElements';
let urlAppjs = config.UrlServer;

export const PoliticasMantenimientoParticularEdit = withStyles(styles)(({ classes, ...props }) => {

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    const [getSistema, setSistema] = useState();
    const regSistema = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';
        // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        // console.log("sistema->", valor)
        setSistema(value);
    }

    const [getTipoElemento, setTipoElemento] = useState();
    const regTipoElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElemento(valor)
    }

    const [getUnidadFuncional, setUnidadFuncional] = useState();
    const validarUnidadFuncional = (value) => {
        setUnidadFuncional(value);
    }
    const [getTramo, setTramo] = useState();
    const validarTramo = (value) => {
        setTramo(value);
    }

    const [getAbscisaInicial, setAbscisaInicial] = useState(0);
    const validarAbscisaInicial = (value) => {
        if(value) {
            var val = value.split("K")[1].split("+")
            val=val[0] +"."+val[1];
            val=parseFloat(val);
            setAbscisaInicial(val);
        }
    }

    const [getAbscisaFinal, setAbscisaFinal] = useState(0);
    const validarAbscisaFinal = (value) => {
        if(value) {
            var val = value.split("K")[1].split("+")
            val=val[0] +"."+val[1];
            val=parseFloat(val);
            setAbscisaFinal(val);
        }
    }

    return (
        <Edit  {...props} title="Editar política de Mantenimiento Particular" redirect="list">
            <TabbedForm 
                redirect="list" 
                //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE MANTENIMIENTO ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={6}>
                            <TextInput label="Nombre de la Política" source="nombre" validate={[required()]} fullWidth />
                        </Grid>
                        <FormDataConsumer>
                        {
                            ({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                        <Grid item xs={3}>
                                        <ReferenceInput
                                            label="Sistema"
                                            source="sistema"
                                            reference="sistemas"
                                            disabled
                                            filter={{
                                                visibleOnPolicies: 1
                                            }}
                                            validate={[required(), regSistema]}
                                            onChange={value => dispatch(
                                                change(REDUX_FORM_NAME, 'tipoElemento', null)
                                            )}
                                            fullWidth
                                        >
                                            <SelectInput optionText="nombre" fullWidth />
                                        </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <ReferenceInput
                                            label="Elemento"
                                            source="tipoElemento"
                                            reference="tipo_elementos"
                                            disabled
                                            onChange={regTipoElemento}
                                            filter={{
                                                visibleOnPolicies: 1,
                                                sistema : getSistema
                                            }}
                                            validate={[required()]}
                                            fullWidth
                                        >
                                            <SelectInput optionText="nombre" fullWidth />
                                        </ReferenceInput>
                                        </Grid>
                                </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {
                            ({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="Unidad Funcional"
                                                source="unidadFuncional"
                                                reference="unidad_funcionals"
                                                disabled
                                                onChange={value => dispatch(
                                                    change(REDUX_FORM_NAME, 'tramo', null)
                                                )}
                                                validate={[required(), validarUnidadFuncional]}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true, disabled: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="Tramo"
                                                source="tramo"
                                                reference="tramos"
                                                disabled
                                                validate={[required(), validarTramo]}
                                                filter={{
                                                    unidadFuncional: getUnidadFuncional
                                                }}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true, disabled: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="Abscisa Inicial" source="abInic" disabled fullWidth validate={[validarAbscisaInicial]} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="Abscisa Final" source="abFin" disabled fullWidth validate={[validarAbscisaFinal]} />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                <EditElementosMantenimientoParticular 
                                    parentProps={formData}
                                    tramo={formData.tramo} 
                                    tipoElemento={formData.tipoElemento} 
                                    abInicio={formData.abInic} 
                                    abFin={formData.abFin}
                                 />
                            )}
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <div className={classes.fieldCol12}>
                                
                            </div>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    )

});

import React from 'react';
import { EditButton } from 'react-admin';

class EditButtonPims extends React.Component{

    render() {
        return(
            localStorage.getItem(this.props.modulo) === "1" || localStorage.getItem(this.props.modulo) === "2" ? <EditButton /> : null
        )    
    }
}
export default EditButtonPims;
import React, { Component } from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Logo from './logo.png';


/**
 * TODO: mostrar aca el proyecto, etc, seleccionado
 */

export const Filtro =(props) =>{    
    
    return "";
}

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    media: {
    width: '20%',
    borderRadius: '0',
    //fontFamily: "object-fit: none",
    //marginRight: '-1
  },
    spacer: {
        flex: 1,
    },
    noshow:{display: 'none'}
};

const PimsAppBar = 
    withStyles(styles)(
        ({ classes, ...props }) => (
            <AppBar {...props}>
                <Typography
                    variant="title"
                   
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
                /> 
               <div>{props.proyecto != null ? props.proyecto.codigo+' :: '+props.proyecto.nombre : ( window.localStorage.codigoProyecto != undefined) ? window.localStorage.codigoProyecto +' :: '+window.localStorage.nombreProyecto : ''}</div>     
               <div className={classes.noshow} id="idGlobalProyecto">{props.proyecto != null ? props.proyecto.id : ''}</div>          
                <span className={classes.spacer} />
                <Avatar src={Logo} className={classes.media} id="img-logo"/>                               
            </AppBar>
        )
    );

const mapStateToProps = 
    state => 
        ({ 
        proyecto: state.proyecto, 
        ruta: state.ruta,
        unidadFuncional: state.unidadFuncional,
    });

export default connect(mapStateToProps, {})(PimsAppBar);


import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField,SelectField,NumberField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


export const ModeloCostosPersList = props => (
    <List {...props} title="Reparaciones menores"  sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid >
            <NumberField label="Variable A" source="variableAFlex" />
            <NumberField label="Variable B" source="variableBFlex" />
            <NumberField label="Variable X" source="variableXFlex" />
            <NumberField label="Variable Y" source="variableYFlex" />                                        
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
import React from 'react';
import { CardActions,EditButton,ListButton,TabbedShowLayout, Tab, TextField, DateField, WithPermissions, NumberField
} from 'react-admin';
import { Show } from './../Show';
import { PField } from './../../fields/PField';
import { PRField } from './../../fields/PRField';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};
const PostActions =({basePath,data,
})=>{
var unifuncionales = localStorage.getItem("unifuncionales");
return(
        <WithPermissions
        render={({ permissions }) => (
            "1" === unifuncionales   ||    "2" === unifuncionales           
    ? 
    <CardActions style={cardActionStyle}>
        
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
        {/* Add your custom actions
        <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
    

    :  <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} record={data} />
        </CardActions>
    )}
    />
    
)
};

export const UnidadFuncionalShow = props => (
    <Show title="Información Unidad Funcional" actions={<PostActions/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Información básica">          */}
                <TextField label="Código Unidad Funcional" source="fullCode" />    
                <span></span>  
                <span></span>         
                <PRField label="Progresiva inicio" source="abInicio"/>
                <PRField label="Progresiva fin" source="abFin"/> 
                <NumberField label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>   
                <PField label="PR inicio" source="pInicio"/>
                <PField label="PR fin" source="pFin"/>       
            </Tab>
            <Tab label="AuditorÍa">
            <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <span></span>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <span></span>    
            </Tab>
        </TabbedShowLayout>
    </Show>
);
import React , {useState} from 'react';
import { required, TextInput, SelectInput,ReferenceInput,Query} from 'react-admin';
import { Edit } from './../Edit';
import { PRInput } from './../../inputs/PRInput';
import { PInput } from './../../inputs/PInput';
import Grid from '@material-ui/core/Grid';
import {prValidationInicio,prValidationFin,validarLogicaAbsFinEdit , ValorMaximoAbsEditInicio, ValorMaximoAbsEditFin,  ValorMaximoPrEditInicio, ValorMaximoPrEditFin, validarLogicaPrFinEdit} from './../validacionesInputs'
import { SimpleForm } from './../SimpleForm';
import { withStyles } from '@material-ui/core';
import config from '../../config/config';
import {SaveButton, Toolbar } from 'react-admin';
let urlAppjs = config.UrlServer;


const styles = {
    ocultar: {
        display:"none",
    },

};


export const UnidadFuncionalEdit  =withStyles(styles)(({ classes, ...props }) => {
    const [rutass , setRutas]= useState('');
    const [prValidador, setPrValidador] = useState('');
    const [AbValidador, setAbValidador] = useState('0');
    const [contador, setContador] =useState(0);
    const [abInicio, setAbInicio] =useState();
    const [abFin, setAbFin] =useState();
    const [separadore, setSeparador] =useState();
    const [idUnidade, setIdUnidad] =useState();
/*VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/

    const ValidarRutas = (value)  => { 
        let urlUnidad = window.location.hash;
        setIdUnidad(urlUnidad.split("%2Funidad_funcionals%2F"))
        let lenghtObject = Object.keys(value);
        let valor = '';   
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                  
        setRutas(value) 
        let separador = value.split('/');
        setSeparador(separador[2]);     
    }
        
    const toolbarStyles = {
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
    
    
    const SimpleFormActions = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton />
            {/* <BackButton onClick={ValidarAbsFin} /> */}
        </Toolbar>
    ));
    

/* FIN DE VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/

/*Validación para que los valores de PR y ABS no se soblepongan*/
const NoSobreponerAbs = () =>{
    // rutass  id de la rita contra la que se valida
    // abInicio valor de la abscisa inicial ingresada
    // abFin valor de la abscisa final ingresada
    // tipoConsulta valida si es editar o crear 
    let IdRuta = rutass.substr(7);        
    let abInicioA = document.getElementById('ingresoAbInicio');
    let abFinA = document.getElementById('ingresoAbFin'); 
    if(abInicioA && abFinA)
    {
        abInicioA = abInicioA.value
        abFinA = abFinA.value
        abInicioA = abInicioA.substr(1, abInicioA.lastIndexOf('+')-1)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
        abFinA = abFinA.substr(1, abFinA.lastIndexOf('+')-1)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
        fetch(urlAppjs+'/validar/rango/pr/abs/Editar/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/'+idUnidade[1])
        .then(function(response) {
            
            return response.text();
        })
        .then(function(myJson) {  
            setAbValidador(myJson)   
            // setContador(1)
            if(AbValidador === '1'){
        
                return 'Rango no válido'
            }                
        })
    }        
    if(AbValidador === '1'){
    
        return 'Rango no válido'
    }
}
const MansajeValidadorAb = () =>{
    if(AbValidador === '1'){
    
        return 'Rango no válido'
    }
}

const NoSobreponerPr = () =>{
    // rutass  id de la rita contra la que se valida
    // abInicio valor de la abscisa inicial ingresada
    // abFin valor de la abscisa final ingresada
    // tipoConsulta valida si es editar o crear 
    let IdRuta = rutass.substr(7);      
    let abInicioA = document.getElementById('ingresoPrInicio');
    let abFinA = document.getElementById('ingresoPrFin'); 

    abInicioA = abInicioA.value
    abFinA = abFinA.value
    abInicioA = abInicioA.substr(2, abInicioA.lastIndexOf('+')-2)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
    abFinA = abFinA.substr(2, abFinA.lastIndexOf('+')-2)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
    fetch(urlAppjs+'/validar/rango/pr/Editar/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/'+idUnidade[1])
    .then(function(response) {
        
        return response.text();
    })
    .then(function(myJson) {  
        setPrValidador(myJson)   
        // setContador(1)
        if(AbValidador === '1'){
    
            return 'Rango no válido'
        }                
    })
    if(prValidador === '1'){
    
        return 'Rango no válido'
    }
}

const MansajeValidadorPb = () =>{
    if(prValidador === '1'){
    
        return 'Rango de Pr no válido'
    }
}
return(
    <Edit title="Editar Unidad Funcional" {...props} >
        <SimpleForm submitOnEnter={false} actions={<SimpleFormActions/>} redirect="list" >            
            <Grid container spacing={24}>
                <Grid item xs={6}>               
                    {/* <ValidarAbs/>   */}
                    <TextInput label="Código" source="CODIGAAS" style={{display : 'none'}} id="codigo" defaultValue={AbValidador} fullWidth disabled />
                    <TextInput label="Código" source="CODIGAASA" style={{display : 'none'}} id="codigo" defaultValue={prValidador}  fullWidth disabled />
                    {/* <ValidarAbsFin/>    */}
                    <ReferenceInput label="Ruta"  source="ruta" disabled reference="rutas" fullWidth  validate={[required(), ValidarRutas]}>
                        <SelectInput optionText="fullCode" id="IdRuta" />
                     </ReferenceInput>             
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="Ruta" source="codigo" id="codigo" fullWidth disabled />
                </Grid>                                          
                <Grid item xs={6}>
                    <PRInput label="Progresiva inicio" id="ingresoAbInicio" source="abInicio" onChange={NoSobreponerAbs} fullWidth  validate={[ValorMaximoAbsEditInicio, required(),MansajeValidadorAb]}   />
                </Grid>
                <Grid item xs={6}>
                    <PRInput label="Progresiva fin" id="ingresoAbFin" source="abFin" onChange={NoSobreponerAbs}  validate={[ValorMaximoAbsEditFin,validarLogicaAbsFinEdit, required(),MansajeValidadorAb]} fullWidth/>
                </Grid>
                {rutass &&             
                    <Grid item xs={6}>
                        <PInput label="PR inicio" source="pInicio"  id="ingresoPrInicio" onChange={NoSobreponerPr} fullWidth validate={[prValidationInicio,required(),  ValorMaximoPrEditInicio,MansajeValidadorPb]}/>
                    </Grid> 
                }
                {rutass &&     
                    <Grid item xs={6}>
                        <PInput label="PR fin" source="pFin"   id="ingresoPrFin" onChange={NoSobreponerPr} fullWidth validate={[prValidationFin,required(), ValorMaximoPrEditFin, validarLogicaPrFinEdit,MansajeValidadorPb]}/>
                    </Grid>
                }
               
                {rutass &&                           
                    <Query type="GET_ONE" resource="rutas" payload={{ id: rutass}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    <Grid item xs={3}>
                                        <TextInput  id="IdprInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.PInicio} source="pInicios" fullWidth />
                                        <TextInput id="IdprFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.PFin} source="pFins" fullWidth />
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                }  
            </Grid>
        </SimpleForm>
    </Edit>
    )
    });
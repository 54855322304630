import React from 'react';
import {Query, CardActions, TextInput,Loading,Error,ListButton, EditButton } from 'react-admin';
import Grid from '@material-ui/core/Grid';
const CoordenadasProyecto = () => (
    <Query type='GET_ONE' resource='proyectos' payload={{ id: window.localStorage.getItem('id_project2') }}>
        {({ data, loading, error }) => {
            // if (loading) { return <Loading />; }
            if (data) {  
            return (
                <Grid>
                    <TextInput 
                        source="latitudd"
                        label="Latitud mínima"
                        id="latMin"
                        defaultValue={data.latitud1}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <TextInput 
                        source="latitud1d"
                        label="Latitud máxima"
                        id="latMax"
                        defaultValue={data.latitud2}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <TextInput 
                        source="longitudd"
                        label="longitud mínima"
                        id="lngMin"
                        defaultValue={data.longitud1}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <TextInput 
                        source="longitud2d"
                        label="Longitud máxima"
                        id="lngMax"
                        defaultValue={data.longitud2}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    /> 
                    <TextInput 
                        source="altitudd"
                        label="Altitud"
                        id="alturaIni"
                        defaultValue={data.alturaIni}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    /> 
                    <TextInput 
                        source="altitudFinald"
                        label="Altitud Final"
                        id="alturaFin"
                        defaultValue={data.alturaFin}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                </Grid>
            )}else{
                return (<div></div>)
            }
        }}
    </Query>
);



export default CoordenadasProyecto;


import React, {useState} from 'react';
import { SimpleForm,required,TextInput,SelectInput ,maxLength,BooleanInput,NumberInput,ReferenceInput,AutocompleteInput,minValue} from 'react-admin';
import { Edit } from './../../Edit';
import { withStyles, Grid , Button} from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import {ComponenteDeterioroPers} from '../componentes/ComponeteDeterioroPers';
import {Entero} from '../../validacionesInputs'

export const DeterioroPersEdit = withStyles(styles)(({ classes, ...props }) => {
    const [tipoElemento , setTipoElemento] = useState();
    const ValidarTipoElemento = (value) =>{
        setTipoElemento(value)
    }
    return (
        <Edit {...props} undoable={false} title='Editar Deterioro PERS' >
            <SimpleForm redirect="list" >
            <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <SelectInput id='tipoElemento' label="Tipo de reparación" source='tipoSuperficie' choices={[
                            {id:'PFLEX', name:'Flexible'},
                            { id:'PRIGD', name:'Rígido'}
                        ]} validate={[required(),ValidarTipoElemento]} disabled fullWidth/>
                    </Grid>            
                    <Grid item xs={6}>   
                        <NumberInput label='Código ASTM' source="codigoAstm" validate={[required(), minValue(0),Entero]} fullWidth/>             
                    </Grid>
                    <Grid item xs={6}>   
                        <SelectInput label="Nivel de serveridad" source='nivelSeveridad' choices={[
                            {id:1, name:1},
                            {id:3, name:3},
                        ]} validate={[required()]} fullWidth/>             
                    </Grid>
                    <Grid item xs={4}>   
                        <BooleanInput source="estado" fullWidth/>           
                    </Grid>
                    <Grid item xs={4}>   
                        <TextInput source="nombre" validate={[required(),maxLength(40)]} fullWidth/>           
                    </Grid>
                    <Grid item xs={4}>   
                        <SelectInput label="Unidad" source='unidad' choices={[
                            {id:'m2', name:'m2'},
                            { id:'m', name:'m'},
                            { id:'losa', name:'losa'},
                        ]} validate={[required()]} fullWidth/>           
                    </Grid>   
                    <Grid item xs={6}>
                        <ReferenceInput
                            label="Tipo de reparaciones severidad media"
                            source="tipoReparacionMedia"
                            reference="reparacion_localizadas"
                            filter={{tipoReparacion : tipoElemento}}
                            allowEmpty
                        >
                            <AutocompleteInput   options={{ fullWidth: true }} optionText="nombre" onClick={ValidarTipoElemento} />
                        </ReferenceInput>   
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput
                            label="Tipo de reparaciones severidad alta"
                            source="tipoReparacionAlta"
                            reference="reparacion_localizadas"
                            filter={{tipoReparacion : tipoElemento}}
                            allowEmpty
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                        </ReferenceInput>   
                    </Grid>                                                                                                  
                </Grid>     
            </SimpleForm>
        </Edit>
    )
});
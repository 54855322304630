import React, { useState } from 'react';

import {
    TextInput, DateInput, SelectInput, NumberInput, ReferenceInput, TabbedForm, AutocompleteInput, maxValue,
    FormTab,  required, minValue, minLength, maxLength, BooleanInput, SimpleFormIterator,
    ArrayInput
} from 'react-admin';
import { PRInput } from './../../../inputs/PRInput';
import Typography from '@material-ui/core/Typography';
import { PimsCreate } from './../../Create';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { latMininima, lngMininima, abValidationInicio, abValidationFin,validarLogicaAbsFinCreate, isFechaInvalida
} from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import { ImagenesInventario } from '../../images/ImagenesInventario';

export const OtrosElementosCreate = withStyles(styles)(({ classes, ...props }) => {


    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');

    // VALIDAR SI EL SEGMENTO YA FUE SELECCIONADO
    // const ValidarSegmento =(value) =>{         

    //     let lenghtObject = Object.keys(value);
    //     let valor = '';
    //     for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
    //     setSegmento(valor)

    // } 

    //ASIGNAR ELEMENTO
    const [elemento , setElemento]= useState('');
    const ValidarElemento =(value) =>{                      
    setElemento(value); 
    }
    

    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum2Decimales = (value) => {
        if (value !== undefined || value !== null) {
            if (value !== 0) {
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if (!pattern.test(value)) { return "El valor ingresado solo puede tener 2 decimales"; }
                }
            }
        }
    }

    //DECIMAL
    const decimal = (value) => {
        if (value % 1 == 0) {
            return "El valor ingresado debe ser decimal";
        }
    }

    //ASIGNAR EL ESTADO
    const [tipoEstado, setTipoEstado] = useState('');
    const [opciones, setOpcion] = useState();
    const asignarEstado = (value) => {
        let lenghtObject2 = Object.keys(value);
        let valor2 = '';
        for (let index = 0; index < (lenghtObject2.length - 1); index++) { valor2 += value[index]; }
        setTipoEstado(valor2)

    }

    //VALIDAR NUMERO ENTERO
    const valEntero = (value) => {
        if (value !== undefined || value !== null) {
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }
        }
    }

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }
    const Unidades = { unidadFuncional: unidads };
    const Tramoss = { tramo: tramos };

    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        setSegmento(value)

    }


    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const validarEnteroNT =(value) =>{
        if(value){
          if (!/^([0-9]+)*$/.test(value)){ return "Debe ser un número entero"; }    
        }
      }
      const validacionmaxfechaact=(value)=>{
        if(value){
            let fecha=new Date(value+"T00:00:00");
            let fechaact=new Date();
            if(fecha>fechaact || !fecha){
               return "La fecha no puede ser mayor a la actual"
            }
        } 
      }

/*OPCIONES PARA TIPO DE IMÁGENES*/
   const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
   const OpcionTipoImagen = (value) =>{    
        setDefaultTipoImagen([
            {
                tipoImagen : '/tipo_images/1',
            },
            {
                tipoImagen : '/tipo_images/7',
            },
            {
                tipoImagen : '/tipo_images/8',
            },
            {
                tipoImagen : '/tipo_images/9',
            },
        ])
   }
      
    return (
        <PimsCreate {...props} title="Crear Otro Elemento">
            <TabbedForm redirect="list" >
                {/* ------ ELEMENTOS OTROS ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                    <CoordenadasProyecto/>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput 
                                label="Elemento"
                                source="tipoElemento" 
                                reference="tipo_elementos" 
                                fullWidth 
                                filter={{ puntos: 1, sistema: 'OTR' }} 
                                sort={{ field: 'nombre', order: 'ASC' }} 
                                validate={[required(), ValidarElemento,OpcionTipoImagen]}
                            >
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput source="margen" label="Margen" choices={[
                                { id: 'Derecha', name: 'Derecha' },
                                { id: 'Izquierda', name: 'Izquierda' },
                                { id: 'Centro', name: 'Centro' },
                            ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="b" component="b">Localización</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider fullWidth />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput
                                        label="Unidad Funcional"
                                        source="unidadFuncional"
                                        reference="unidad_funcionals"
                                        filterToQuery={searchText => ({ codigo: searchText })}
                                        sort={{ field: 'codigo', order: 'asc' }}
                                        validate={[required(), ValidarUnidad]}
                                        fullWidth
                                    >
                                        <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                    </ReferenceInput>
                                </Grid>

                                {unidads &&
                                    <Grid item xs={4}>
                                        <ReferenceInput
                                            label="Tramo"
                                            source="tramo"
                                            alwaysOn
                                            reference="tramos"
                                            filter={{ unidadFuncional: unidads }}
                                            filterToQuery={searchText => ({ codigo: searchText })}
                                            sort={{ field: 'codigo', order: 'ASC', }}
                                            validate={[required(), ValidarTramo]}
                                            formClassName={classes.grid_cont4}
                                        >
                                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                        </ReferenceInput>
                                    </Grid>
                                }
                                {tramos &&
                                    <Grid item xs={4}>
                                        <ReferenceInput
                                            label="Segmento"
                                            source="segmento"
                                            reference="segmentos"
                                            sort={{ field: 'codigo', order: 'ASC' }}
                                            filter={{ tramo: tramos }}
                                            filterToQuery={searchText => ({ codigo: searchText })}
                                            validate={[required(), ValidarSegmento]}
                                            formClassName={classes.grid_cont4}
                                        >

                                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                        </ReferenceInput>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {segmentos && <AbsSegmento segmento={segmentos} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas inicales</Typography>
                                <Divider fullWidth formClassName={classes.grid_cont_div} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                                <Divider fullWidth formClassName={classes.grid_cont_div} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, required(), validarLogicaAbsFinCreate]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[required(), LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[required(), lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="Características">
                    {elemento == '/tipo_elementos/IDES' &&
                    <SelectInput source="interseccion" label="Clase de intersección" choices={[
                        { id: 'Deprime intersecta', name: 'Deprime intersecta' },
                        { id: 'Deprime vía', name: 'Deprime vía' },
                        { id: 'Eleva intersecta', name: 'Eleva intersecta' },
                    ]} formClassName={classes.first_field} validate={[required()]} fullWidth />
                }
                   {elemento == '/tipo_elementos/INIV' &&
                    <SelectInput source="interseccion" label="Clase de intersección" choices={[
                        { id: 'Glorieta', name: 'Glorieta' },
                        { id: 'Con pare', name: 'Con pare' },
                        { id: 'Semaforizada', name: 'Semaforizada' },
                    ]} formClassName={classes.first_field} validate={[required()]} fullWidth />
                }
                    <SelectInput source="material" label="Material" choices={[
                        { id: 'Adoquín ', name: 'Adoquín' },
                        { id: 'Afirmado', name: 'Afirmado' },
                        { id: 'Concreto', name: 'Concreto' },
                        { id: 'Flexible', name: 'Flexible' },
                        { id: 'Otro', name: 'Otro' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="flujo_peatonal" label="Flujo peatonal" choices={[
                        { id: 'Alto', name: 'Alto' },
                        { id: 'Medio', name: 'Medio' },
                        { id: 'Bajo', name: 'Bajo' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="clase_via_intersecta" label="Clase de vía intersecta" choices={[
                        { id: 'Urbana', name: 'Urbana' },
                        { id: 'Rural', name: 'Rural' },
                    ]} formClassName={classes.first_field} validate={[required()]} fullWidth />
                    <SelectInput source="tipo_via_intersecta" label="Tipo de vía intersecta" choices={[
                        { id: 'Primaria', name: 'Primaria' },
                        { id: 'Secundaria', name: 'Secundaria' },
                        { id: 'Terciaria', name: 'Terciaria' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="reductores_velocidad" label="Reductores de velocidad vía intersecta" choices={[
                        { id: 'Si', name: 'Si' },
                        { id: 'No', name: 'No' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    {/* DES NIVEL */}
                    {elemento === "/tipo_elementos/IDES" &&
                        <div className={classes.subtittle}><strong>Características del paso a desnivel </strong></div>
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <Divider fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <NumberInput label="Largo (m)" source="largo" validate={[required(), minValue(0), valNum2Decimales]} formClassName={classes.first_field} fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <NumberInput label="No. Carriles" source="carriles" validate={[required(), minValue(0), valEntero]} formClassName={classes.last_field} fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <SelectInput label="Sentido" source="sentido" choices={[
                            { id: 'Ascendente', name: 'Ascendente' },
                            { id: 'Descendente', name: 'Descendente' },
                            { id: 'Doble', name: 'Doble' },
                        ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    }

                </FormTab>
                {/* ------ DETALLES ---------- */}
                <FormTab label="Detalles" >
                    <Divider fullWidth />
                    <SelectInput source="estado" label="Condición del elemento" choices={[
                            { id: 'Nuevo', name: 'Nuevo' },
                            { id: 'Existente', name: 'Existente' },
                            { id: 'Reemplazo', name: 'Reemplazo' },
                        ]} 
                        formClassName={classes.grid_cont4_f} 
                        validate={[required()]} onChange={asignarEstado} fullWidth 
                    />
                    {tipoEstado === "Reemplazo" &&
                        <SelectInput source="motivo" label="Motivo del reemplazo" choices={[
                            { id: 'Instalación', name: 'Instalación' },
                            { id: 'Reemplazo programado', name: 'Reemplazo programado' },
                            { id: 'Accidente', name: 'Accidente' },
                            { id: 'Vandalismo', name: 'Vandalismo' },
                            { id: 'Otro', name: 'Otro' },
                        ]} formClassName={classes.grid_cont4_s} validate={[required()]} fullWidth />
                    }
                    {tipoEstado === "Reemplazo" && 
                    <ReferenceInput
                        label="Elemento que Reemplazo"
                        source="elementoReemplazo_id"
                        reference="otros_elementos"
                        filter={{
                            tipoElemento : elemento,
                            status : 1,
                            // unidadFuncional: unidads,
                            // tramo: tramos,
                            // segmento : segmentos
                        }}
                        fullWidth
                        validate={[required()]}
                        formClassName={classes.grid_cont4_s}
                >
                    <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigo" />
                </ReferenceInput>
                    
                    }
                      <br/>
                <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    <div></div>
                    <DateInput 
                        label="Fecha de instalación" 
                        source="fecha_instalacion" 
                        fullWidth 
                        formClassName={classes.grid_cont4_f} 
                        validate={validacionmaxfechaact, isFechaInvalida}
                    />
                    <ReferenceInput
                        label="Proveedor"
                        source="proveedor"
                        reference="proveedors"
                        filter={{proyecto: props.proyecto, suministros: 1, sistema: 'OTR'}}
                        sort={{ field: 'razonSocial', order: 'ASC' }}
                        filterToQuery={searchText => ({ razonSocial: searchText })}
                        validate={[required()]}
                        fullWidth
                        formClassName={classes.grid_cont4_s}
                    >
                        <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
                    </ReferenceInput>
                    <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]} formClassName={classes.grid_cont4_s}/>
                    <br/>
                    <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont12s} />
                    
                    <div></div>
                    <TextInput source="observaciones" label="Observaciones" validate={[required(), minLength(0), maxLength(200)]} fullWidth />
                    </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
});
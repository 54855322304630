import React, { useState } from 'react';
import {
    NumberInput, 
    ReferenceInput, 
    ArrayInput, 
    SimpleFormIterator,
    SelectInput,
     FormDataConsumer, 
     DateInput,
    TextInput, 
    BooleanInput, 
    maxLength, 
    Query,
    AutocompleteInput
} from 'react-admin';
import { PimsCreate } from './../Create';
import TabbedForm from '../TabbedForm'
import { PRInput } from '../../inputs/PRInput';
import { styles } from './../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { latMininima, lngMininima, abValidationInicio, abValidationFin, validarUnDecimal, Entero, 
          validarDosDecimales,validarAB, isFechaInvalida
        } from '../validacionesInputs';
import {
    required,
    FormTab,
    maxValue
} from 'react-admin';
import { minValue } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import { ImagenesInventario } from '../images/ImagenesInventario';


const defensachoices =
    [
        { id:  '1', name: 'Terminal de inicio abatida y esviada y Terminal final abatida' },
        { id:  '2', name: 'Terminal de inicio abatida y esviada y Terminal final abatida y esviada' },
        { id:  '3', name: 'Terminal de inicio abatida y esviada y Terminal final cola de pez' },
        { id:  '4', name: 'Terminal de inicio abatida y esviada y Terminal final enterrada en talud' },
        { id:  '5', name: 'Terminal de inicio abatida y Terminal final abatida' },
        { id:  '6', name: 'Terminal de inicio abatida y Terminal final abatida y esviada' },
        { id:  '7', name: 'Terminal de inicio abatida y Terminal final cola de pez' },
        { id:  '8', name: 'Terminal de inicio abatida y Terminal final enterrada en talud' },
        { id:  '9', name: 'Terminal de inicio cola de pez y Terminal final abatida' },
        { id: '10', name: 'Terminal de inicio cola de pez y Terminal final abatida y esviada' },
        { id: '11', name: 'Terminal de inicio cola de pez y Terminal final cola de pez' },
        { id: '12', name: 'Terminal de inicio cola de pez y Terminal final enterrada en talud' },
        { id: '13', name: 'Terminal de inicio enterrada en talud y Terminal final abatida' },
        { id: '14', name: 'Terminal de inicio enterrada en talud y Terminal final abatida y esviada' },
        { id: '15', name: 'Terminal de inicio enterrada en talud y Terminal final cola de pez' },
        { id: '16', name: 'Terminal de inicio enterrada en talud y Terminal final enterrada en talud' },
    ]
const optionRendererDefensa = choice => `${choice.name}`;

const tipoElemento = { sistema: 'SEG' };


export const SeguridadCreate = withStyles(styles)(({ classes, ...props }) => {

    const [elemento, setElemento] = useState('-');
    const ValidarElemento = (value) => {
        setElemento(value)
    }

    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');
    const [segmentos, setSegmento] = useState('');

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value);
    }
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value);
    }

    const ValidarSegmento = (value) => {
        setSegmento(value)
    }
    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const [estados, setEstado] = useState('');

    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)
    }

/*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = (value) =>{
        if(value !== '/tipo_elementos/CAPT' && value !== '/tipo_elementos/TPVH' && value !== '/tipo_elementos/CERC' && value !== '/tipo_elementos/BORD' ){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }else if(value === '/tipo_elementos/CERC'){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },            
            ])
        }else{
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }       
    }

    return (
        <PimsCreate {...props} title="Crear Sistemas de Contención Vehicular" >
            <TabbedForm 
                redirect="list" 
            >
                <FormTab label="Identificación">
                    <CoordenadasProyecto />
                    <ReferenceInput 
                        label="Elemento" 
                        source="tipoElemento" 
                        reference="tipo_elementos" 
                        validate={[required(), ValidarElemento,OpcionTipoImagen]} 
                        filter={{ 
                            sistema: 'SEG' 
                        }} 
                        sort={{ 
                            field: 'nombre', 
                            order: 'ASC'
                        }} 
                        fullWidth 
                        formClassName={classes.grid_cont6s} 
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    <SelectInput 
                        fullWidth 
                        label="Margen" 
                        source="margen" 
                        allowEmpty 
                        emptyValue="" 
                        validate={[required()]} 
                        choices={[
                            { id: 'derecha', name: 'Derecha' },
                            { id: 'izquierda', name: 'Izquierda' },
                            { id: 'centro', name: 'Centro' },
                        ]} fullWidth formClassName={classes.grid_cont5} 
                    />

                    <Query type="GET_ONE" resource="tipo_elementos" payload={{ id: elemento}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                <TextInput 
                                    defaultValue={data.nombre} 
                                    source="tipoElementoDesc" 
                                    fullWidth validate={[required()]}
                                    style={{display:'none'}} 
                                />)
                            }
                            return <div></div>;                                                
                        }}
                    </Query> 
                    {/* ---------- LOCALIZACIÓN ------------------ */}
                    <Typography variant="h3" component="h3" className={classes.coord}>Localización</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont_div} />
                    <ReferenceInput 
                        label="Unidad Funcional" 
                        source="unidadFuncional"  
                        reference="unidad_funcionals" 
                        filterToQuery={searchText => ({ codigo: searchText })} 
                        sort={{field : 'codigo', order : 'asc'}}
                        validate={[required(), ValidarUnidad]}
                        formClassName={classes.grid_cont4s}
                        fullWidth
                    >
                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                    </ReferenceInput>
                                           
                    {unidads &&
                            <ReferenceInput 
                                label="Tramo" 
                                source="tramo" 
                                alwaysOn 
                                reference="tramos" 
                                filter={{ unidadFuncional:unidads}}
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{ field: 'codigo', order: 'ASC' ,}}
                                validate={[required(), ValidarTramo]}
                                formClassName={classes.grid_cont4}
                                fullWidth
                            >
                                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                            </ReferenceInput>
                    }
                    {tramos &&
                            <ReferenceInput 
                                label="Segmento" 
                                source="segmento" 
                                reference="segmentos" 
                                sort={{ field: 'codigo', order: 'ASC' }}
                                filter={{tramo : tramos}}
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                validate={[required(), ValidarSegmento]}
                                formClassName={classes.grid_cont4}
                                fullWidth
                            >
                            
                                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                            </ReferenceInput>
                    }
                    {segmentos &&
                        <div>
                            <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas inicales</Typography>
                            <Divider fullWidth style={{marginTop : '10px'}} />
                        </div>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required(), validarAB]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                    <div>
                        <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                        <Divider fullWidth style={{marginTop : '10px'}} />
                    </div>
                    }
                    {/* validarLogicaAbsFinCreate */}
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, required(), validarAB]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[required(), LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[required(), lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {segmentos &&
                         <AbsSegmento segmento={segmentos}/>
                    }
                </FormTab>
                <FormTab label="Características">
                    {elemento === "/tipo_elementos/BRRC" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                        { id: 'Concreto ', name: 'Concreto ' },
                                        { id: 'Malla ', name: 'Malla ' },
                                        { id: 'Metalica ', name: 'Metálica ' },
                                        { id: 'Plastica ', name: 'Plástica ' },
                                        { id: 'Polisombra ', name: 'Polisombra ' },
                                        { id: 'Prefabricado ', name: 'Prefabricado ' }
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="Cantidad de elementos" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="No. Captafaros" 
                                    source="cant_capta" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarDosDecimales]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Material" 
                                    source="material" 
                                    allowEmpty emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                        { id: 'Concreto ', name: 'Concreto ' },
                                        { id: 'Malla ', name: 'Malla ' },
                                        { id: 'Metalica ', name: 'Metálica ' },
                                        { id: 'Plastica ', name: 'Plástica ' },
                                        { id: 'Polisombra ', name: 'Polisombra ' },
                                        { id: 'Prefabricado ', name: 'Prefabricado ' },
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="Cantidad de elementos" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="No. Captafaros" 
                                    source="cant_capta" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                        { id: 'Concreto ', name: 'Concreto ' },
                                        { id: 'Malla ', name: 'Malla ' },
                                        { id: 'Metalica ', name: 'Metálica ' },
                                        { id: 'Plastica ', name: 'Plástica ' },
                                        { id: 'Polisombra ', name: 'Polisombra ' },
                                        { id: 'Prefabricado ', name: 'Prefabricado ' },
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="Cantidad de elementos" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="No. Captafaros" 
                                    source="cant_capta" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                 />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]} choices={[
                                    { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                    { id: 'Concreto ', name: 'Concreto ' },
                                    { id: 'Malla ', name: 'Malla ' },
                                    { id: 'Metalica ', name: 'Metálica ' },
                                    { id: 'Plastica ', name: 'Plástica ' },
                                    { id: 'Polisombra ', name: 'Polisombra ' },
                                    { id: 'Prefabricado ', name: 'Prefabricado ' },

                                ]} 
                                formClassName={classes.grid_cont4Rigth}
                            />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <NumberInput 
                                    label="Cantidad de elementos" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&        
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Material" 
                                    source="material" 
                                    allowEmpty emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                        { id: 'Concreto ', name: 'Concreto ' },
                                        { id: 'Malla ', name: 'Malla ' },
                                        { id: 'Metalica ', name: 'Metálica ' },
                                        { id: 'Plastica ', name: 'Plástica ' },
                                        { id: 'Polisombra ', name: 'Polisombra ' },
                                        { id: 'Prefabricado ', name: 'Prefabricado ' },

                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="Cantidad de elementos" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                    { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                    { id: 'Concreto ', name: 'Concreto ' },
                                    { id: 'Malla ', name: 'Malla ' },
                                    { id: 'Metalica ', name: 'Metálica ' },
                                    { id: 'Plastica ', name: 'Plástica ' },
                                    { id: 'Polisombra ', name: 'Polisombra ' },
                                    { id: 'Prefabricado ', name: 'Prefabricado ' },

                                    ]} 
                                    formClassName={classes.grid_cont6Right}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="No. Captafaros" 
                                    source="captafaros" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont6Right}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                            
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
            
                                <SelectInput fullWidth label="Material" source="material" allowEmpty emptyValue="" validate={[required()]} choices={[
                                    { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                    { id: 'Concreto ', name: 'Concreto ' },
                                    { id: 'Malla ', name: 'Malla ' },
                                    { id: 'Metalica ', name: 'Metálica ' },
                                    { id: 'Plastica ', name: 'Plástica ' },
                                    { id: 'Polisombra ', name: 'Polisombra ' },
                                    { id: 'Prefabricado ', name: 'Prefabricado ' },

                                ]} formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Tipo de perfil" 
                                    source="perfil" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Perfil en C', name: 'Perfil en C' },
                                        { id: 'Perfil en U', name: 'Perfil en U' },
                                        { id: 'Perfil W - doble onda', name: 'Perfil W - doble onda' },
                                        { id: 'Simple', name: 'Simple' },
                                ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Tipo de defensa" 
                                    source="defensa" 
                                    choices={defensachoices} 
                                    optionText={optionRendererDefensa} 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="No. Postes" 
                                    source="postes" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="No. Captafaros" 
                                    source="captafaros" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="No. Unidades" 
                                    source="unidades" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="No. Terminales" 
                                    source="terminales" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="No. Tornillos" 
                                    source="tornillos" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                            
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} choices={[
                                        { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                        { id: 'Concreto ', name: 'Concreto ' },
                                        { id: 'Malla ', name: 'Malla ' },
                                        { id: 'Metalica ', name: 'Metálica ' },
                                        { id: 'Plastica ', name: 'Plástica ' },
                                        { id: 'Polisombra ', name: 'Polisombra ' },
                                        { id: 'Prefabricado ', name: 'Prefabricado ' },

                                    ]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Tipo de perfil" 
                                    source="perfil" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Perfil en C', name: 'Perfil en C' },
                                        { id: 'Perfil en U', name: 'Perfil en U' },
                                        { id: 'Perfil W - doble onda', name: 'Perfil W - doble onda' },
                                        { id: 'Simple', name: 'Simple' },
                                    ]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <NumberInput 
                                    label="Largo (m)" 
                                    source="ca_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont3s}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <NumberInput 
                                    label="Ancho (m)" 
                                    source="ca_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="ca_altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                               <NumberInput 
                               label="No. Captafaros" 
                               source="cant_capta" 
                               fullWidth 
                               validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                               formClassName={classes.grid_cont6Right}
                               />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                               <NumberInput 
                               label="Calibre (mm)" 
                               source="calibre_mm" 
                               fullWidth 
                               validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                               formClassName={classes.grid_cont6Right}
                               />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Barrera jersey plastica ', name: 'Barrera jersey plástica ' },
                                        { id: 'Concreto ', name: 'Concreto ' },
                                        { id: 'Malla ', name: 'Malla ' },
                                        { id: 'Metalica ', name: 'Metálica ' },
                                        { id: 'Plastica ', name: 'Plástica ' },
                                        { id: 'Polisombra ', name: 'Polisombra ' },
                                        { id: 'Prefabricado ', name: 'Prefabricado ' },

                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Tipo de tope" 
                                    source="tope" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: 'Horizontal', name: 'Horizontal' },
                                        { id: 'Vertical', name: 'Vertical' },
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <SelectInput 
                                    fullWidth 
                                    label="Sección" 
                                    source="seccion" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                    { id: 'Circular', name: 'Circular' },
                                    { id: 'Rectangular', name: 'Rectangular' },
                                ]} 
                                formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="Diámetro/Base (m)" 
                                    source="diametro" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarDosDecimales]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="Altura (m)" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarDosDecimales]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="Cantidad de topes" 
                                    source="topes" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="Longitud del tramo (m)" 
                                    source="tramos" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }

                </FormTab>
                <FormTab label="Detalles" >
                            <SelectInput label="Condición del elemento" source="condicionElemento" choices={[
                                { id: "Existente", name: 'Existente' },
                                { id: "Reemplazo", name: 'Reemplazo' },
                                { id: "Nuevo", name: 'Nuevo' },
                            ]} fullWidth validate={[required()]} 
                            onChange={ValidarEstado}
                            formClassName={classes.grid_cont4_f}
                            />
                            {estados === "Reemplazo" &&
                                <SelectInput fullWidth source="motivoReemplazo" allowEmpty emptyValue="" fullWidth choices={[
                                    { id: 'Accidente', name: 'Accidente' },
                                    { id: 'Instalacion', name: 'Instalación' },
                                    { id: 'Reemplazo programado', name: 'Reemplazo programado' },
                                    { id: 'Vandalismo', name: 'Vandalismo' },
                                    { id: 'Otro', name: 'Otro' }
                                ]} 
                                formClassName={classes.grid_cont4_s}
                                />

                            }
                            {estados === "Reemplazo" && 
                                // <ElementosCreados 
                                //     tabla="seguridads" 
                                //     tipo="create" 
                                //     elementos={elemento}
                                //     fullWidth 
                                //     formClassName={classes.grid_cont4Rigth} 
                                // />

                                <ReferenceInput
                                    label="Elemento que reemplazo"
                                    source="elementoReemplazo_id"
                                    reference="seguridads"
                                    filter={{
                                        tipoElemento : elemento,
                                        status : 1,
                                        unidadFuncional: unidads,
                                        tramo: tramos,
                                        segmento : segmentos
                                    }}
                                    filterToQuery={searchText => ({ fullCodigo: searchText })} 
                                    fullWidth
                                    formClassName={classes.grid_cont4_s}
                                >
                                    <AutocompleteInput options={{fullWidth: true}} optionText="smartCode" />
                                </ReferenceInput>
                            }
                            <br></br>
                            <SelectInput label="Estado" source="estado" choices={[
                                { id: "Activo", name: 'Activo' },
                                { id: "Inactivo", name: 'Inactivo' },
                            ]} fullWidth validate={[required()]} 
                            formClassName={classes.grid_cont4_f}
                            />
                            <br></br>
                            <DateInput 
                                label="Fecha de instalación" 
                                source="fechaInstalacion" 
                                fullWidth 
                                validate={[required(), isFechaInvalida]} 
                                formClassName={classes.grid_cont3_f}
                                />
                            <FormDataConsumer formClassName={classes.grid_cont6_s}>
                                {
                                    ({ formData, ...rest }) => (
                                        
                                        <ReferenceInput 
                                            label="Proveedor" 
                                            source="proveedor" 
                                            reference="proveedors" 
                                            filter={{ 
                                                proyecto: props.proyecto, 
                                                suministros: 1, sistema: 'SEG' 
                                            }}
                                            sort={{ field: 'razonSocial', order: 'ASC' }}
                                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                                            validate={[required()]}
                                        >
                                            <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                                        </ReferenceInput>
                                    )
                                }
                            </FormDataConsumer>
                            <FormDataConsumer formClassName={classes.grid_cont3_s}>
                                {
                                    ({ formData, ...rest }) => (
                                        <NumberInput 
                                        label="Garantía (meses)" 
                                        source="garantia" 
                                        fullWidth 
                                        validate={[required(), Entero, minValue(0)]} 
                                    />
                                    )
                                }
                            </FormDataConsumer>
                            <BooleanInput 
                                label="¿Incluir este elemento a un plan de mantenimiento?" 
                                    source="mantenimiento" 
                                    fullWidth
                                    formClassName={classes.grid_cont12}
                                    />
                            <TextInput 
                                source="observaciones" 
                                fullWidth validate={[required(), maxLength(200, "Maximo 200 caracteres.")]} 
                                formClassName={classes.grid_cont12}
                                />
                </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
});
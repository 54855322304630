import React , {useState} from 'react';
import {  Toolbar,CardActions,NumberField, TextInput,ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, TabbedForm , ListButton,EditButton,  FormTab } from 'react-admin';
import { GMapInput } from '../../../inputs/GMapInput';
import { PRField } from './../../../fields/PRField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import  {styles}  from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../../images/ButtonCarousel';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data}) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath}/>
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar>
));

export const ElementoSenalizacionShow = withStyles(styles)(({ classes, record, ...props }) => {

    const [imagen , setImagen] =useState(true);
     const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== '')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }
    
    return(
    <ShowController {...props} >
        {controllerProps =>
            <ShowView {...props} {...controllerProps} title="Información Elemento Señal" actions={<ButtonCarousel />}>
                <TabbedForm toolbar={false}>
                    <FormTab label="IDENTIFICACIÓN">    
                        <TextField label="Código" source="fullCodigo" fullWidth formClassName={classes.grid_cont4s} />
                        <ReferenceField label="Elemento" source="tipoElementoSenal" reference="tipo_elemento_senals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                            <TextField  source="nombre"  />
                        </ReferenceField>
                        <br/>
                        {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&
                            <TextInput source="senal_.url" validate={ValidarImagen} formClassName={classes.ocultar}/>
                        }
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&
                            <TextInput source="tipoSenal_.url" validate={ValidarImagen} formClassName={classes.ocultar}/>
                        }
                        <TextField source="margen"  fullWidth formClassName={classes.grid_cont4s}/>       
                            {/* <ImageField source="tipoSenal_.url" src="url"  /> */}
                            {/* <ImageField source="senal_.url" validate={ValidarImagen}/> */}
                            
                        <ReferenceField label="Tipo de elemento" source="tipoSenal" reference="senals" linkType={false}fullWidth formClassName={classes.grid_cont4} >
                            <TextField  source="nombre"  />
                        </ReferenceField>
                        <div></div>
                        {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                                
                            <ReferenceField label="Código" source="senal" reference="senals" linkType={false} fullWidth formClassName={classes.grid_cont4s}>    
                                <TextField source="ids" />       
                            </ReferenceField>                      
                        }
                        {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                                
                            <ReferenceField label="Modelo" source="senal" reference="senals" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                                <TextField source="nombre" />       
                            </ReferenceField>                      
                        }
                        <div></div>
                        <Typography variant="h3" component="h3">Localización</Typography>    
                        <span></span>
                        <Divider fullWidth formClassName={classes.grid_cont12}/> 
                        <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField>
                        <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        
                        {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&
                            <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                        }
                        {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&
                            <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                        }      
                        {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&   
                            <PRField label="Progresiva Inicial" source="abInicio"fullWidth formClassName={classes.grid_cont4s} />
                        } 
                        {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&   
                            <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                        }
                         {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&   
                            <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/> 
                        }
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&                        
                            <PRField label="Progresiva Inicial" source="abInicio" fullWidth formClassName={classes.grid_cont4s} />                   
                        }                          
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&                        
                            <PRField label="Progresiva Final" source="abFin" fullWidth formClassName={classes.grid_cont4} />                   
                        }   
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&                                                    
                            <NumberField label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>                    
                        }
                        {controllerProps.record && 
                            <Typography variant="b" component="b">Coordenadas iniciales</Typography>
                        }
                        {controllerProps.record && 
                            <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                        }    
                        {controllerProps.record && 
                            <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        }
                        {controllerProps.record && 
                            <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/> 
                        }     
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&
                            <Typography variant="b" component="b">Coordenadas finales</Typography>
                        }
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&
                            <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                        }    
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&
                            <NumberField label="Latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        }
                        {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&
                            <NumberField label="Longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/> 
                        }   
  
                        <span></span>
                            <GMapInput
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {imagen} 
                            />
                        </FormTab>
                <FormTab label="CARACTERÍSTICAS">

                    {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR'  &&          
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&  
                                
                        <TextField source="material"  fullWidth formClassName={classes.grid_cont3}/> 
                                                      
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                                
                        <TextField label="Tipo de papel" source="tipoPapel"  fullWidth formClassName={classes.grid_cont3}/>                       
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <TextField label="Tipo de lámina" source="tipoLamina"  fullWidth formClassName={classes.grid_cont3}/>                       
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                                
                        <br/>                      
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <TextField label="Forma del tablero" source="tipoTablero"  fullWidth formClassName={classes.grid_cont3}/>                      
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <NumberField label="Dimensiones del tablero" source="dimensionTablero"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                      
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <TextField label="Tipo de soporte" source="tipoSoporte"  fullWidth formClassName={classes.grid_cont3}/>                    
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <br/>                    
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <NumberField label="Velocidad asociada a la dimensión" source="velocidadDimension"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                     
                    }             
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <NumberField label="Alto (m)" source="altura"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                     
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <NumberField  label="Valor límite" source="valorLimite"   fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                   
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <br/>                     
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR'  &&                        
                        controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                        controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                        controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                       
                        <TextField label="Color Principal" source="color"   fullWidth formClassName={classes.grid_cont3}/>                   
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <NumberField label="Retro-reflectividad color principal (cd/lux/m²)" source="retroflectividadInicial"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                   
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <br/>                    
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/DEM'  &&                        
                        
                        <br/>                    
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                        
                        <TextField label="Color secundario" source="colorSecundario" fullWidth formClassName={classes.grid_cont3}/>                   
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SV'  &&         
                     controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                     controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                     controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&   controllerProps.record.colorSecundario  !== 'No aplica' &&                 
                        
                        <NumberField label="Retro-reflectividad color secundario (cd/lux/m²)" source="retroflectividadFinal" source="retroflectividadInicial"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                   
                    }

                    {/*-----------------------------------------------------------------------------------*/}
                    
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SH' &&               
                        controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                        controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                        controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                       
                        <NumberField label="Número de tachas" source="numeroTachas"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />                 
                    }  
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/SH' &&               
                        
                       <br/>               
                    }  
                     

                    {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&   controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR' &&                
                        controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                        controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                        controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                       
                        <NumberField label="Longitud(m)"  source="longitud"   fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                   
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV'  &&   controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR' &&                
                        controllerProps.record.tipoSenal !== '/tipo_senals/SMV'  &&               
                        controllerProps.record.tipoSenal !== '/tipo_senals/BYC'  &&  
                        controllerProps.record.tipoSenal !== '/tipo_senals/SOS'  &&                          
                       
                        <NumberField label="Ancho(m)"  source="ancho"   fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                  
                    }
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/DEM' &&               
                        
                        <NumberField label="Área (m²)"  source="area"   fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>                 
                    }
                            
                    
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/OTR' &&               
                        
                        <NumberField label="Cantidad" source="cantidad" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />                 
                    } 
                    {controllerProps.record && controllerProps.record.tipoElementoSenal  === '/tipo_elemento_senals/OTR' &&               
                        
                        <NumberField label="Espaciamiento promedio (m)"  source="espaciamientoPromedio" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />                 
                    }            
                    <span></span>
                    
                </FormTab>
                
                <FormTab label="Detalles">

                    <TextField label="Condición del elemento" source="estado"  fullWidth formClassName={classes.grid_cont4} />
                    
                    {controllerProps.record && controllerProps.record.estado === "Reemplazo"   &&                        
                        <TextField label="Motivo del reemplazo" source="fullMotivoReemplazo"  fullWidth formClassName={classes.grid_cont4}/>                         
                    }
                    {controllerProps.record && controllerProps.record.estado === "Reemplazo"   &&                        
                        <TextField label="Elemento que reemplaza" source="fullCodigo"  fullWidth formClassName={classes.grid_cont4}/>                          
                    }
                    <br/>
                    <TextField label="Estado" source="estados" fullWidth formClassName={classes.grid_cont4}/>
                    <br/>
                    <DateField source="fechaInstalacion"  fullWidth formClassName={classes.grid_cont3}/>
                    <ReferenceField label="Proveedor" source="proveedor" reference="proveedors" linkType={false}  fullWidth formClassName={classes.grid_cont3}>    
                        <TextField source="razonSocial" />       
                    </ReferenceField>
                    <NumberField label="Garantía" source="garantia"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                    <br/>
                    <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento"  fullWidth formClassName={classes.grid_cont4}/>
                    <br/>
                    <TextField source="observaciones" fullWidth formClassName={classes.grid_cont4} />
                </FormTab>
                <FormTab label="AuditorÍa">
                    <DateField source="createdAt" label="Fecha creación"  fullWidth formClassName={classes.grid_cont4}/> 
                        <TextField source="createdBy" label="Creado por"  fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>               
                        <DateField source="updatedAt" label="Fecha actualización"  fullWidth formClassName={classes.grid_cont4}/>
                        <TextField source="updatedBy" label="Actualizado por"  fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>    
                </FormTab>
                </TabbedForm>
            </ShowView>
        }
    </ShowController>
)});

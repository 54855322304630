import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
  DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
  ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer
} from 'react-admin';
import { PimsCreate } from './../Create';
import { styles } from './../../EstilosGrid';
import { abValidationInicio,validarAB, abValidationFin, validarDosDecimales , isFechaInvalida
} from '../../../src/resources/validacionesInputs';
import { latMininima, lngMininima,Entero } from '../validacionesInputs';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../inputs/PRInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import Reparaciones from './campos/reparaciones';
import config from '../../config/config';
import { ImagenesInventario } from '../images/ImagenesInventario';
let urlAppjs = config.UrlServer;

export const PuenteCreate = withStyles(styles)(({ classes, ...props }) => {

  const styles = {
    ocultar: { display: "none" },

  };
  let defecto = 0;
  const [segmentos, setSegmento] = useState('');
  const [unidads, setUnidad] = useState('');

  const ValidarUnidad = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const Unidades = { unidadFuncional: unidads };

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [estados, setEstado] = useState('');
  const ValidarEstado = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setEstado(valor)

  }
  const [variante, setVariante] = useState('');
  const ValidarVariante = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setVariante(valor)

  }

  const [tipo, setTipo] = useState('');
  const ValidarTipo = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipo(valor)

  }
  const [tipoa, setTipoa] = useState('');
  const ValidarTipoa = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoa(valor)

  }

  const Tramoss = { tramo: tramos };

  const [elemento, setElemento] = useState('');
  const [opciones, setOpcion] = useState('');
  const ValidarElemento = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setElemento(valor)

    fetch(urlAppjs + '/elementoDrenaje/')
      .then((response) => {
        console.log("REST");
        return response.json()
      })
      .then((response) => {
        setOpcion(response);
        console.log(response)
      })

  }
  // const validarDerecha=(value)=>{
  //   let resultado =[];
  //   for(let j=0; j<largo; j++){
  //       let valor = document.getElementById('1'+j);
  //       if(valor && valor.value!=""){
  //           valor.style.textAlign="right"
  //           resultado.push(valor.value);
  //       }
  //   }
  // }

  /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');

  const ValidarSegmento = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(valor)
    let idSegmento = valor;
    idSegmento = idSegmento.split('/');
    let redirect = "elemento_drenajes"
    let urlser = window.location.hostname;
    let urlAppjs = "https://dynatest.soluciones.software/#/" + redirect;

    switch (urlser) {
      case 'localhost':
        urlAppjs = "http://localhost:3000/#/" + redirect;
        break;
      case 'pims.soluciones.software':
        urlAppjs = "https://pims.soluciones.software/#/" + redirect;
    }
    fetch(urlAppjs + '/segmentos/' + idSegmento[2] + '.json')
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        let comprobacion = myJson["ubicacionMapa"]
        if (comprobacion !== undefined) {
          let comprobar = myJson["ubicacionMapa"][0]
          if (comprobar !== undefined) {
            let latMin = myJson["ubicacionMapa"][0]['lat'];
            let lngMin = myJson["ubicacionMapa"][0]['lng'];
            let latMax = myJson["ubicacionMapa"][1]['lat'];
            let lngMax = myJson["ubicacionMapa"][1]['lng'];
            setLatMin(latMin);
            setLngMin(lngMin);
            setLatMax(latMax);
            setLngMax(lngMax);

          }
        }
      })

  }
  const LngIni = (value) => {
    if (lngMax !== '') {
      if (parseFloat(value) > parseFloat(lngMin) || parseFloat(value) < parseFloat(lngMax)) {
        return "El rango debe estar entre " + lngMin + " y " + lngMax;
      }
    }
  }
  const LatIni = (value) => {
    if (latMax !== '') {
      if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {
        return "El rango debe estar entre " + latMin + " y " + latMax;
      }
    }
  }
  /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

  /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  const choices1 = [
    { _id: '01', full_name: '01' },
    { _id: '02', full_name: '02' },
    { _id: '00', full_name: '00' },
  ];
  const choices = [
    { _id: '01', full_name: 'Circular' },
    { _id: '02', full_name: 'Rectangular' },
    { _id: '00', full_name: 'Otra' },
  ];
  const optionRenderer = choice => `${choice.id}`;
  const choises2 = [
    { id: '01 ', name: ' 01 ' },
    { id: '02 ', name: ' 02 ' },
    { id: '03 ', name: ' 03 ' },
    { id: '04 ', name: ' 04 ' },
    { id: '05 ', name: ' 05 ' },
    { id: '00 ', name: ' 00 ' },
    { id: 'N/A ', name: ' N/A ' },
  ]
  const choises3 = [
    { id: '04 ', name: ' Apoyo fijo ' },
    { id: '01 ', name: ' Balancín ' },
    { id: '05 ', name: ' Basculante ' },
    { id: '02 ', name: ' Rodillo ' },
    { id: '03 ', name: ' Placa en neopreno ' },
    { id: 'N/A ', name: ' No aplica ' },
    { id: '00 ', name: ' Otros ' },
  ];
  const  choises4=[
    { id: '2000', name: '2000' },
    { id: '2001', name: '2001' },
    { id: '2002', name: '2002' },
    { id: '2003', name: '2003' },
    { id: '2004', name: '2004' },
    { id: '2005', name: '2005' },
    { id: '2006', name: '2006' },
    { id: '2007', name: '2007' },
    { id: '2008', name: '2008' },
    { id: '2009', name: '2009' },
    { id: '2010', name: '2010' },
    { id: '2011', name: '2011' },
    { id: '2012', name: '2012' },
    { id: '2013', name: '2013' },
    { id: '2014', name: '2014' },
    { id: '2015', name: '2015' },
    { id: '2016', name: '2016' },
    { id: '2017', name: '2017' },
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
    { id: '2024', name: '2024' },
    { id: '2025', name: '2025' },
    { id: '2026', name: '2026' },
    { id: '2027', name: '2027' },
    { id: '2028', name: '2028' },
    { id: '2029', name: '2029' },
    { id: '2030', name: '2030' }
];
  //Validacion de longitud y ancho del tablero
  //Total --->
  const [totaltablero, setTotaltablero] = useState('');


  const Longitu = (value) => {
    let valAncho = 0;
    valAncho = document.getElementById('1').value;
    if (valAncho != null && value != null) {
      //console.log(parseFloat(value)*100 + "<-->" + parseFloat(valAncho));
      // valAncho = valAncho.replace('.', ',');
      // value = value.replace('.', ',');
    }
    //console.log(parseFloat(value)*100 + "<-->" + parseFloat(valAncho));
    setTotaltablero(((parseFloat(value) * 100) * (parseFloat(valAncho) * 100)) / 10000);
    // console.log(totaltablero);
  }
  const validarEnteroNT = (value) => {
    if (!/^([0-9]+)*$/.test(value)) {
      return "Debe ser un número entero";
    }
  }

/*OPCIONES PARA TIPO DE IMÁGENES*/
  const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
  const OpcionTipoImagen = (value) =>{    
       setDefaultTipoImagen([
           {
               tipoImagen : '/tipo_images/1',
           },
           {
               tipoImagen : '/tipo_images/7',
           },
           {
               tipoImagen : '/tipo_images/8',
           },
           {
               tipoImagen : '/tipo_images/9',
           },
           {
               tipoImagen : '/tipo_images/5',
           },
           {
               tipoImagen : '/tipo_images/6',
           }
       ])
  }


  return (

    <PimsCreate title="Crear Elemento Puentes" {...props}>
      <TabbedForm redirect="list">
        <FormTab label="Identificación">
          <CoordenadasProyecto/>
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <ReferenceInput label="Elemento" source="elementos" reference="tipo_elementos" fullWidth filter={{ id: '/tipo_elementos/PNTE' }} validate={[required(),OpcionTipoImagen]}>
                <SelectInput optionText="nombre" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <SelectInput source="margen" label="Margen" validate={[required()]} fullWidth choices={[
                { id: 'Derecha', name: 'Derecha' },
                { id: 'Izquierda', name: 'Izquierda' },
                { id: 'Centro', name: 'Centro' },
              ]} />
            </Grid>
            <Grid item xs={6}>
              <SelectInput label="Tipo de Puente" source="tipo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                { id: 'Concreto', name: 'Concreto' },
                { id: 'Metálicos o mixtos', name: 'Metálicos o mixtos' },

              ]} />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="carriles" label="Número de carriles" validate={[required(), minValue(1), maxValue(10)]} fullWidth />
            </Grid>
            {/* ------- LOCALIZACIÓN --------- */}
            <Grid item xs={12}>
              <h3 style={{ marginBottom: 0 }}>Localización</h3>
              <hr></hr>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="Unidad Funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
            </Grid>

            {unidads &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="Tramo"
                  source="tramo"
                  alwaysOn
                  reference="tramos"
                  filter={{ unidadFuncional: unidads }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  sort={{ field: 'codigo', order: 'ASC', }}
                  validate={[required(), ValidarTramo]}
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
              </Grid>
            }
            {tramos &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="Segmento"
                  source="segmento"
                  reference="segmentos"
                  sort={{ field: 'codigo', order: 'ASC' }}
                  filter={{ tramo: tramos }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  onChange={ValidarSegmento}
                  validate={[required()]}
                  formClassName={classes.grid_cont4}
                >

                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                {/* <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbinicio" id="IdAbInicio" />
                </ReferenceInput>
                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbfin" id="IdAbFin" />
                </ReferenceInput> */}
              </Grid>
            }

          </Grid>
          {segmentos && <AbsSegmento segmento={segmentos} />}
          {segmentos &&
            <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas inicales</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />
            </Grid>
          </Grid>
          }
          {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4s} />}
          {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos &&
              <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                <Divider fullWidth style={{ marginTop: '10px' }} />
              </Grid>
            </Grid>
          }
          {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin,validarAB,required()]} formClassName={classes.grid_cont4s} />}
          {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[required(), latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont4} />}
          {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[required(),  lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4} />}
          {segmentos &&
          
            <ArrayInput label="" source="ubicaciones" style={{
              display:
                "none"
            }}
              defaultValue={[
                {
                  lat: latitudMinimaArray,
                  lng: longitudMinimaArray
                },
                {
                  lat: latitudMaximaArray,
                  lng: longitudMaximaArray
                }
              ]}
            >
              <SimpleFormIterator disableAdd disableRemove >
                <NumberInput label="Latitud" source="lat" fullWidth />
                <DInput label="Longitud" source="lng" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          }
        </FormTab>
        {/* ......:::.... ADMINISTRATIVOS ......:::....*/}
        <FormTab label="Datos Administrativos">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="administrativos" defaultValue={[
            {
              years: null, area: null, norma_diseno: null, disenador: null, info_di: null, yearsb: null, areab: null, norma_disenob: null, disenadorb: null, info_dib: null, fecha: null, direccion_abscisado: null, estacion_conteo: null, inspección: null, inspector: null, numinspeccion: null, propietario: null, admon_vial: null, proyectista: null, coe_sismo: null, paso_cauce: null, exis_variante: null, long_km: null, estado_op: null, vehi_di: null, distri_carga: null, capacidad: null, luz_critica: null, fac_clas: null, fac_cort: null, momento: null, car_rue: null, observacioness: null,
            }
          ]}>
            <SimpleFormIterator disableAdd disableRemove>
              <Typography variant="h3" component="h3">Detalles Constructivos</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <DateInput label="Año" source="years" validate={[required(), isFechaInvalida]} fullWidth formClassName={classes.grid_cont3s} />
              <DInput label="Área (m²)" source="area" id="area1" className="align-number" fullWidth validate={[required()]} formClassName={classes.grid_cont3} />
              <TextInput label="Norma de diseño" source="norma_diseno" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="Diseñador" source="disenador" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="Informe de Diseño" source="info_di" fullWidth validate={[required(), minLength(1), maxLength(200)]} />
              <Typography variant="h3" component="h3" fullWidth>Detalles de Refuerzo o Rehabilitación</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <DateInput label="Año" source="yearsb" validate={[required(), isFechaInvalida]} fullWidth formClassName={classes.grid_cont3s} />
              <DInput label="Área (m²)" source="areab" fullWidth className="align-number" id="area2" validate={[required()]} formClassName={classes.grid_cont3} />
              <TextInput label="Norma de diseño" source="norma_disenob" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="Diseñador" source="disenadorb" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="Informe de Diseño" source="info_dib" fullWidth validate={[required(), minLength(1), maxLength(200)]} />

              <Typography variant="h3" component="h3">Detalles de Inspección</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <DateInput label="Fecha de Recolección de Datos" source="fecha" fullWidth validate={[required(), isFechaInvalida]} formClassName={classes.grid_cont3s} />
              <SelectInput label="Dirección de abscisado" source="direccion_abscisado" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                { id: 'N', name: 'N' },
                { id: 'S', name: 'S' },
                { id: 'E', name: 'E' },
                { id: 'O', name: 'O' },
              ]} formClassName={classes.grid_cont4} />
              <ReferenceInput label="Estación de Conteo" source="estacion_conteo" reference="estacion_conteos" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                <SelectInput optionText="nombre" />
              </ReferenceInput>
              <TextInput label="Inspector" source="inspector" fullWidth validate={[required(), minLength(1), maxLength(30)]} formClassName={classes.grid_cont3s} />
              <NumberInput label="Requisitos de Inspección" source="inspeccion" fullWidth validate={[required(),Entero, minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Número de Secciones de Inspección" source="numinspeccion" fullWidth validate={[required(),Entero, minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont4} />

              <Typography variant="h3" component="h3">Miembros Interesados</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <TextInput label="Propietario" source="propietario" fullWidth validate={[required(), minLength(1), maxLength(20)]} formClassName={classes.grid_cont3s} />
              <TextInput label="Administración Vial" source="admon_vial" fullWidth validate={[required(), minLength(1), maxLength(20)]} formClassName={classes.grid_cont4} />
              <TextInput label="Proyectista" source="proyectista" fullWidth validate={[required(), minLength(1), maxLength(30)]} formClassName={classes.grid_cont4} />

              <Typography variant="h3" component="h3">Características Operacionales</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <NumberInput label="Coeficiente de aceleración sísmica (Aa)" source="coe_sismo" fullWidth validate={[required(),validarDosDecimales, minValue(0), maxValue(1)]} formClassName={classes.grid_cont4s} />
              <SelectInput label="Paso por el cauce" source="paso_cauce" fullWidth validate={[required()]} choices={[
                { id: 'S', name: 'S' },
                { id: 'N', name: 'N' }
              ]} formClassName={classes.grid_cont4} />
              <SelectInput label="Estado" source="estado_op" fullWidth validate={[required()]} choices={[
                { id: 'B', name: 'B' },
                { id: 'R', name: 'R' },
                { id: 'M', name: 'M' },
              ]} formClassName={classes.grid_cont3} />

              <SelectInput label="Existe variante" source="exis_variante" fullWidth validate={[required()]} choices={[
                { id: 'S', name: 'S' },
                { id: 'N', name: 'N' }
              ]} formClassName={classes.grid_cont4s} onChange={ValidarVariante} />

              {variante === "S" && (
                <DEInput label="Longitud variante (km)" source="long_km" fullWidth className="align-number" validate={[required(), minValue(0), maxValue(9999999999)]} formClassName={classes.grid_cont3} />
              )
              }

              <Typography variant="h3" component="h3" fullWidth>Capacidad de carga para tránsito  legal</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <TextInput label="Vehículo de diseño" source="vehi_di" fullWidth validate={[required(), minLength(0), maxLength(10)]} formClassName={classes.grid_cont4s} />
              <TextInput label="Clase de Distribución de Carga" source="distri_carga" fullWidth validate={[required(), minLength(1), maxLength(20)]} formClassName={classes.grid_cont4} />
              <DInput label="Capacidad de Carga (t)" source="capacidad" className="align-number" fullWidth id="area3" validate={[required(), minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont3} />
              <DInput label="Longitud de luz Crítica (m)" source="luz_critica" fullWidth id="area4" className="align-number" validate={[required(), minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont4s} />
              <DInput label="Factor de Clasificación" source="fac_clas" fullWidth id="area5" className="align-number" validate={[required(), minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont4} />

              <Typography variant="h3" component="h3" fullWidth>Capacidad de Carga para Transportes especiales</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <DInput label="Fuerza Cortante (t)" source="fac_cort" className="align-number" fullWidth id="area6" validate={[required(), minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont4s} />
              <DInput label="Momento (t.m)" source="momento" className="align-number" fullWidth id="area7" validate={[required(), minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont4} />
              <DInput label="Línea de Carga por Rueda (t)" className="align-number" source="car_rue" fullWidth id="area8" validate={[required(), minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont3} />
              <TextInput label="Oservaciones" source="observacioness" fullWidth validate={[required(), minLength(1), maxLength(200)]}/>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Geometría">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="geometria" defaultValue={[{
            terraplen: null, curva: null, esviajamiento: null, luces: null, luz_menor: null, luz_mayor: null, long_total: null, ancho_tab: null, area_tab: totaltablero, ancho_sep: null, ancho_anden_der: null, ancho_anden_izq: null, ancho_calzada: null, ancho_bordillo: null, alt_pilas: null, long_pilas: null, alt_estribos: null, long_estribos: null,
          }]}>
            <SimpleFormIterator disableAdd disableRemove>
              <Typography variant="h3" component="h3">General</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <SelectInput label="Puente en terraplén" source="terraplen" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                { id: 'S', name: 'S' },
                { id: 'N', name: 'N' },
              ]} formClassName={classes.grid_cont4s} />
              <SelectInput label="Puente en Curvatura/Tangente" source="curva" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                { id: 'C', name: 'C' },
                { id: 'T', name: 'T' },
              ]} formClassName={classes.grid_cont4} />
              <NumberInput label="Esviajamiento (gra)" source="esviajamiento" className="align-number" allowEmpty emptyValue=""  validate={[required(),validarDosDecimales, minValue(-90), maxValue(90)]} fullWidth formClassName={classes.grid_cont4} />
              <Typography variant="h3" component="h3">Superestructura</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <NumberInput label="Número de luces" fullWidth source="luces" allowEmpty emptyValue="" validate={[required(),Entero, minValue(0), maxValue(20)]} formClassName={classes.grid_cont4s} />
              <span></span>
              <NumberInput label="Longitud luz menor (m)" source="luz_menor" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(100)]} formClassName={classes.grid_cont4s} />
              <NumberInput label="Longitud luz mayor (m)" source="luz_mayor" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(1000)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Longitud total (m)" source="long_total" className="align-number" fullWidth allowEmpty emptyValue="" validate={[Longitu,validarDosDecimales, required(), minValue(1), maxValue(5000)]} formClassName={classes.grid_cont4} defaultValue="2" />
              <NumberInput label="Ancho de tablero (m)" id='1' source="ancho_tab" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(200)]} formClassName={classes.grid_cont4show} />
              <NumberInput id="3" disabled locales="fr-FR" label="Área del tablero (m²)" className="align-number" source="area_tab" fullWidth formClassName={classes.grid_cont4} />
              <NumberInput label="Ancho del separador (m)" source="ancho_sep" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(), minValue(0), maxValue(100)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Ancho del andén izquierdo (m)" source="ancho_anden_izq" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(100)]} formClassName={classes.grid_cont4s} />
              <NumberInput label="Ancho del andén derecho (m)" source="ancho_anden_der" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(100)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Ancho de calzada (m)" source="ancho_calzada" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(100)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Ancho entre bordillos (m)" source="ancho_bordillo" className="align-number" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(100)]} formClassName={classes.grid_cont4s} />
              <span></span>
              <Typography variant="h3" component="h3">Subestructura</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <NumberInput label="Altura de pilas (m)" source="alt_pilas" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(500)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Longitud de apoyo en pilas (m)" source="long_pilas" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(10)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Altura de estribos (m)" source="alt_estribos" fullWidth allowEmpty emptyValue="" validate={[required(), minValue(0),validarDosDecimales, maxValue(500)]} formClassName={classes.grid_cont4} />
              <NumberInput label="Longitud de apoyo en estribos (m)" source="long_estribos" fullWidth allowEmpty emptyValue="" validate={[required(),validarDosDecimales, minValue(0), maxValue(10)]} formClassName={classes.grid_cont4} />

            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        {/* -------------------------SUPERESTRUCTURA --------------------------------------- */}
        <FormTab label="Superestructura">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="superestructura" defaultValue={[
            {
              dise_tipo: null, material: null, cod_transversarl: null, cod_long: null, dise_tipoa: null, materiala: null, cod_transversarla: null, cod_longa: null, car_max: null, velo_max: null, otra: null, cod_baranda: null, cod_expansion: null,
            }
          ]}>
            <SimpleFormIterator disableRemove disableAdd>
            <ReferenceInput disabled label="Código" source="superficie" reference="superficies" fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}>
                <SelectInput optionText="ids"  />
              </ReferenceInput>
              <ReferenceInput label="Tipo de superficie" source="superficie" reference="superficies" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                <SelectInput optionText="descripcion" />
              </ReferenceInput>
              <Typography variant="h3" component="h3">Principal</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <SelectInput label="Diseño tipo" source="dise_tipo" emptyValue="" validate={[required()]} fullWidth choices={[
                { id: 'S', name: 'S' },
                { id: 'N', name: 'N' },
              ]} formClassName={classes.grid_cont4} onChange={ValidarTipo} />
              {tipo === "S" && (
                <SelectInput label="Material" source="material" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                  { id: 'Acero', name: 'Acero' },
                  { id: 'Concreto', name: 'Concreto' },
                  { id: 'Madera', name: 'Madera' },
                  { id: 'Mixto', name: 'Mixto' },
                  { id: 'Otro', name: 'Otro' },
                ]} formClassName={classes.grid_cont4} />
              )}
              {tipo === "S" && (
                <ReferenceInput label="Código" disabled source="transversarl" reference="transversarls" fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )}
              {tipo === "S" && (
                <ReferenceInput label="Tipo de estructuración transversal" source="transversarl" reference="transversarls" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>)}
              {tipo === "S" && (
                <ReferenceInput label="Código" source="longitud" reference="longituds" disabled fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )}
              {tipo === "S" && (
                <ReferenceInput label="Tipo de estructuración longitudinal" source="longitud" reference="longituds" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="decripcion" />
                </ReferenceInput>
              )}
              <Typography variant="h3" component="h3">Secundario</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <SelectInput label="Diseño tipo" source="dise_tipoa" emptyValue="" validate={[required()]} fullWidth choices={[
                { id: 'S', name: 'S' },
                { id: 'N', name: 'N' },
              ]} formClassName={classes.grid_cont4} onChange={ValidarTipoa} />
              {tipoa === "S" && (
                <SelectInput label="Material" source="materiala" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                  { id: 'Acero', name: 'Acero' },
                  { id: 'Concreto', name: 'Concreto' },
                  { id: 'Madera', name: 'Madera' },
                  { id: 'Mixto', name: 'Mixto' },
                  { id: 'Otro', name: 'Otro' },

                ]} formClassName={classes.grid_cont4} />
              )
              }
              {tipoa === "S" && (
                <ReferenceInput 
                  label="Código" 
                  source="transversarla" 
                  reference="transversarlas" 
                  disabled 
                  fullWidth
                  className="align-number" 
                  formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}
                >
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )
              }
              {tipoa === "S" && (
                <ReferenceInput label="Tipo de estructuración transversal" source="transversarla" reference="transversarlas" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>
              )
              }
              {tipoa === "S" && (
                <ReferenceInput label="Código" source="longa" reference="longas" emptyValue="" disabled fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )
              }
              {tipoa === "S" && (
                <ReferenceInput label="Tipo de estructuración longitudinal" source="longa" reference="longas" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>
              )
              }
              <Typography variant="h3" component="h3">Señales</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <TextInput label="Carga máxima" source="car_max" validate={[required(), minLength(1), maxLength(10)]} fullWidth formClassName={classes.grid_cont4} />
              <NumberInput label="Velocidad máxima (km/h)" source="velo_max" fullWidth validate={[required(),Entero, minValue(0)]} formClassName={classes.grid_cont4} />
              <TextInput label="Otra" source="otra" validate={[required(), minLength(1), maxLength(10)]} fullWidth formClassName={classes.grid_cont3} />
              <Typography variant="h3" component="h3">Detalles</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <ReferenceInput label="Código" source="baranda" reference="barandas" fullWidth disabled allowEmpty emptyValue="" validate={[required()]} fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}>
                <SelectInput optionText="ides" />
              </ReferenceInput>
              <ReferenceInput label="Detalles de baranda" source="baranda" reference="barandas" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                <SelectInput optionText="descripcion" />
              </ReferenceInput>
              <ReferenceInput label="Código" source="expantion" reference="expantions" fullWidth disabled allowEmpty emptyValue="" validate={[required()]} fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}>
                <SelectInput optionText="ids" />
              </ReferenceInput>
              <ReferenceInput label="Junta de expansión" source="expantion" reference="expantions" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                <SelectInput optionText="descripcion" fullWidth />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Subestructura">
                         <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="subestructura" defaultValue={[
                           {
                             tipoestribos: null, tipo_cimen: null,  estri: null,  tippilas: null, forma_pila: null, cimen_pila: null, estrisa: null,  apoyo_fijo: null, apoyo_movil: null,  pila_movil: null, pila_pila: null,pila_vigas: null,movil_vigas: null,
                           }
                         ]}>
                        <SimpleFormIterator disableAdd disableRemove>
                            <Typography variant="h3" component="h3">Estribos</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceInput label="Tipo" source="tipoestribos" reference="tipoestribos" validate={[required()]} fullWidth formClassName={classes.grid_cont3s} >
                                <SelectInput optionText="descripcion" />
                            </ReferenceInput>
                            <ReferenceInput label="Código" source="estri" reference="estris" fullWidth disable className="align-number" formClassName={[classes.grid_cont4, classes.textToRight,classes.left]}>
                            <SelectInput optionText="ids" disabled emptyValue=""  />
                            </ReferenceInput>
                             <ReferenceInput label="Material" source="estri" reference="estris" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont3} >
                                <SelectInput optionText="descripcion" fullWidth />
                            </ReferenceInput>
                            <SelectInput label="Tipo de cimentación" source="tipo_cimen" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                                { id: '01 ', name: ' Profunda ' },
                                { id: '02 ', name: ' Superficial ' },
                            ]} formClassName={classes.grid_cont3} />
                           <Typography variant="h3" component="h3">Pilas</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceInput label="Código" source="tippilas" reference="tippilas" disabled fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight,classes.toLeft]}>
                            <SelectInput optionText="ids"  />
                            </ReferenceInput>
                            <ReferenceInput label="Tipo" source="tippilas" reference="tippilas" fullWidth formClassName={classes.grid_cont3}>
                                <SelectInput optionText="descripcion" fullWidth />
                            </ReferenceInput>
                            <SelectInput label="Código" source="forma_pila"  optionText="full_name" optionValue="_id" allowEmpty emptyValue="" disabled fullWidth choices={choices1}  className="align-number" formClassName={[classes.grid_cont4, classes.textToRight,classes.toLeft]}/>
                            <SelectInput label="Forma" source="forma_pila" fullWidth choices={choices} optionText="full_name" optionValue="_id" formClassName={classes.grid_cont3} />
                            <SelectInput label="Tipo de cimentación" source="cimen_pila" fullWidth choices={[
                                { id: '01 ', name: ' Profunda ' },
                                { id: '02 ', name: ' Superficial ' },
                            ]} formClassName={classes.grid_cont3} />
                            <ReferenceInput label="Código" source="estrisa" reference="estris" disabled emptyValue=""  fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight,classes.toLeft]}>
                            <SelectInput  optionText="ids"  />
                            </ReferenceInput>
                            <ReferenceInput label="Material" source="estrisa" reference="estris" allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont3}>
                                <SelectInput optionText="descripcion" />
                            </ReferenceInput>
                    <Typography variant="h3" component="h3">Apoyos</Typography>
                    <span></span><span></span>
                   <Divider fullWidth formClassName={classes.grid_cont12} />
                    <SelectInput label="Código" source="apoyo_fijo" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]} />
                    <SelectInput label="Apoyos fijos sobre estribos" source="apoyo_fijo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4}/>
                     <SelectInput label="Código" source="pila_movil" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}/>
                    <SelectInput label="Apoyos móviles sobre estribos" source="pila_movil" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4}/>
                    <SelectInput label="Código" source="pila_pila" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2}className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]} />
                    <SelectInput label="Apoyos fijos en pilas" source="pila_pila" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4}/>
                    <SelectInput label="Código" source="apoyo_movil" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}/>
                    <SelectInput label="Apoyos móviles en pilas" source="apoyo_movil" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4}/>
                    <SelectInput label="Código" source="pila_vigas" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}/>
                    <SelectInput label="Apoyos fijos en vigas" source="pila_vigas" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4}/>
                    <SelectInput label="Código" source="movil_vigas" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.referenceToleft ]}/>
                    <SelectInput label="Apoyos móviles en vigas" source="movil_vigas" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4}/>
                    </SimpleFormIterator>
                    </ArrayInput>
                    </FormTab>
<FormTab label="Reparaciones">
{/* <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="reparaciones" defaultValue={[
                           {}
]}>
  <SimpleFormIterator disableAdd disableRemove> */}
<Reparaciones/> 
{/* </SimpleFormIterator>
</ArrayInput>                            */}
</FormTab>
 {/* DETALLES */}
 <FormTab label="Detalles">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="detalles" defaultValue={[
            {
              estado: null,
              mot_reemplazo: null,
              elemnto_remplazo: null,
              incluir: null,
              observaciones: null,
              estados: null
            }
          ]}>
            <SimpleFormIterator disableAdd disableRemove >

              <SelectInput label="Condición del elemento" source="estado" choices={[
                { id: "Existente", name: "Existente" },
                { id: "Reemplazo", name: "Reemplazo" },
                { id: "Nuevo", name: "Nuevo" },
              ]} validate={[required()]} onChange={ValidarEstado} fullWidth formClassName={classes.grid_cont3s} allowEmpty emptyValue=""/>


              {estados === "Reemplazo" &&
                <SelectInput label="Motivo de reemplazo" source="mot_remplazo" choices={[
                  { id: "Accidente", name: 'Accidente' },
                  { id: "Reemplazo programado", name: 'Reemplazo programado' },
                  { id: "Vandalismo", name: 'Vandalismo' },
                  { id: "Otro", name: 'Otro' },
                ]} validate={[required()]} allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
              }

              {estados === "Reemplazo" &&
                <SelectInput label="Elemento que reemplazo" source="elemnto_remplazo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                  { id: 'CONT', name: 'Estructuras Contención' },
                  { id: 'PTON', name: 'Pontones' },
                  { id: 'TNEL', name: 'Túneles' },

                ]} allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
              }
              {estados === "Reemplazo" && elemento === "CONT" &&
                <SelectInput label="Elemento que reemplazo" source="elemnto_remplazo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                  { id: 'PTS', name: 'Puentes' },
                  { id: 'PTON', name: 'Pontones' },
                  { id: 'TNEL', name: 'Túneles' },
                ]} allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
              }
              {estados === "Reemplazo" && elemento === "PTON" &&
                <SelectInput label="Elemento que reemplazo" source="elemnto_remplazo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                  { id: 'PTS', name: 'Puentes' },
                  { id: 'CONT', name: 'Estructuras Contención' },
                  { id: 'TNEL', name: 'Túneles' },

                ]} allowEmpty emptyValue="" className={classes.ocultar} fullWidth formClassName={classes.grid_cont3} />
              }
              {estados === "Reemplazo" && elemento === "TNEL" &&
                <SelectInput label="Elemento que reemplazo" source="elemnto_remplazo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                  { id: 'PTS', name: 'Puentes' },
                  { id: 'CONT', name: 'Estructuras Contención' },
                  { id: 'PTON', name: 'Pontones' },

                ]} allowEmpty emptyValue="" className={classes.ocultar} fullWidth formClassName={classes.grid_cont3} />

              }
              <br/>
              <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
              <br></br>
              
                  <DateInput label="Fecha de instalación" source="fecha" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont3_f} />
              
                  <ReferenceInput
                    label="Proveedor"
                    source="proveedor"
                    reference="proveedors"
                    filter={{ sistema: 'PTS' }}
                    sort={{ field: 'razonSocial', order: 'PTS' }}
                    filterToQuery={searchText => ({ razonSocial: searchText })}
                    validate={[required()]}
                    fullWidth formClassName={classes.grid_cont4}  
                  >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
                  </ReferenceInput>
              
                  <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]} formClassName={classes.grid_cont4} />
              
              <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="incluir" allowEmpty emptyValue="" fullWidth />


              <TextInput label="Observaciones" source="observaciones" validate={[required(), minLength(1), maxLength(200)]} fullWidth allowEmpty emptyValue=""/>

            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="FOTOGRAFÍAS">
          <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
        </FormTab>
      </TabbedForm>
    </PimsCreate>
  )
}); 

import React,{useState} from 'react'
import {MapWithAMarker} from './../../inputs/GMapInventario' 
import Grid from '@material-ui/core/Grid';
import { List, SimpleForm, Query ,TextInput,SelectInput,ReferenceInput,AutocompleteInput,FormDataConsumer,CheckboxGroupInput,ArrayInput,NumberInput,SimpleFormIterator} from 'react-admin';
import {PRInput} from './../../inputs/PRInput';


export const MapaGenral = props => {


// Selección de tabla
    const [tablas, setTabla] = useState('elemento_alrededors');
    const [index, setIndex] = useState('elemento_alrededors');
    const SeleccionTabla =(value)=>{
        if(value !== undefined){
            let cantidad = value.length
            setIndex(cantidad)            
        }
         setTabla(value)  
         
    }
    
//Fin Selección tabla

//Valores de las propiedades del Mapa
    const [ubicaciones, setUbicaciones] = useState();


    const [unidadFuncional, setUnidadFuncional] = useState();
    
    const [conteo, setConteo] = useState(0);
    const UnidadFuncional = (value) =>{
         setUnidadFuncional(value)
        //  setTabla('elemento_senalizacion_perus')
         if(value !== undefined)
         setConteo(1)
    }
 
    const [tramos, setTramo] = useState();
    const Tramo = (value) =>{
         setTramo(value)
         if(value !== undefined)
         setConteo(1)
    }
 
    const [segmentos, setSegmento] = useState();
    const Segmento = (value) =>{
         setSegmento(value)
         if(value !== undefined)
         setConteo(1)
    }
 
     const [abInicio, setAbInicio] = useState();
    const AbInicio = (value)  => {
        
        if(value !== null && value !== '' && value !== undefined){
             let valor=  value;
            //  valor =valor.value;
             let valor1 = valor.split('K')            
             valor1 =  valor1[1].split('+')            
             valor = valor1[0] +'.'+ valor1[1]
             setAbInicio(valor)    
             setTramo(tramos)
             setabInicioA('abInicio[gt]')                      
             
        }                
     }
 
    const [abFin, setAbFin] = useState(0);
    const AbFin = (value) =>{
         if(value !== null && value !== ''){
             let valor=   document.getElementById('abFin');
             valor =valor.value;
             let valor1 = valor.split('K')
             valor1 =  valor1[1].split('+')
             valor = valor1[0] +'.'+ valor1[1]
             setAbFin(valor)             
         }       
    }


    const [tipoElementoSenal , setTipoElementoSenal] = useState()
    const TipoElementoSenal = (value) =>{
        setTipoElementoSenal(value)
    }

    const [tipoSenal , setTipoSenal] = useState()
    const TipoSenal = (value) =>{
        setTipoSenal(value)
    }
    // const abInicioA ='abInicio[gt]';
    // const abFinA ='abInicio[lt]';
    const [abInicioA, setabInicioA] = useState('abInicio[gt]');
 //    , tipoElemento : tipoElemento
    const [tipoElementos, setTipoElementos] = useState([]);

     const payload = {
        pagination: {page: 1, perPage : 1000000},
        sort: { field: 'lat', order: 'ASC' },         
        filter : { 'properties[0]':'tipoElemento' , 
                    'properties[1]':'latitudInicial',
                    'properties[2]':'longitudInicial',
                    'properties[3]':'abInicio',
                    'properties[4]':'unidadFuncional',
                    'properties[5]':'tramo',
                    'properties[6]':'segmento',
                    'properties[7]':'codigo',
                    'properties[8]':'senal',
                    'properties[9]':'tipoElementoSenal',
                    'properties[10]':'tipoSenal',
                    'properties[11]':'src',
                    unidadFuncional : unidadFuncional,
                    tramo : tramos,
                    segmento:segmentos

                }
     };

     const payloadSenal = {
        pagination: {page: 1, perPage : 1000000},
        sort: { field: 'lat', order: 'ASC' },         
        filter : { 
                    'properties[1]':'latitudInicial',
                    'properties[2]':'longitudInicial',
                    'properties[3]':'abInicio',
                    'properties[4]':'unidadFuncional',
                    'properties[5]':'tramo',
                    'properties[6]':'segmento',
                    'properties[7]':'codigo',
                    'properties[8]':'senal',
                    'properties[9]':'tipoElementoSenal',
                    'properties[10]':'tipoSenal',
                    unidadFuncional : unidadFuncional,
                    tramo : tramos,
                    segmento:segmentos,
                    tipoElementoSenal : tipoElementoSenal,
                    tipoSenal : tipoSenal

                }
     };
     let coordenadasProyec =[]; 
     let center =[]; 
    
    return(
        <List {...props} basePath={false} record={false} path={false} title='Mapa general' actions={false} perPage={false} pagination={false}>
            <SimpleForm toolbar={false}>
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput label="Unidad funcional" source="unidadFuncional"  fullWidth filterToQuery={searchText => ({ codigo: searchText })} reference="unidad_funcionals" validate={[UnidadFuncional]} filter={{'properties[0]' : 'id','properties[1]' : 'fullCodigos'}} sort={{field : 'codigo' , order:'ASC'}}>
                            <AutocompleteInput  fullWidth options={{fullWidth : true}} optionText="fullCodigos"  />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <ReferenceInput label="Tramo" source="tramo"  reference="tramos" validate={[Tramo]} fullWidth filterToQuery={searchText => ({ codigo: searchText })} sort={{field : 'codigo' , order:'ASC'}} filter={{unidadFuncional :unidadFuncional,'properties[0]' : 'id','properties[1]' : 'fullCodigos'}}>
                            <AutocompleteInput  optionText="fullCodigos" options={{fullWidth : true}} />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <ReferenceInput label="Segmento" source="segmento"   reference="segmentos" fullWidth filterToQuery={searchText => ({ codigo: searchText })}  validate={[Segmento]} sort={{field : 'codigo' , order:'ASC'}} filter={{tramo : tramos,'properties[0]' : 'id','properties[1]' : 'fullCodigos'}}>
                            <AutocompleteInput  optionText="codifullCodigosgo" options={{fullWidth : true}} />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} style={{borderBottom: '1px solid black'}}>
                                <CheckboxGroupInput  source='seguridads' 
                                    validate={[SeleccionTabla]}
                                    // onClick={eventoCeck}
                                    choices={[
                                        {id:'seguridads' , name:'Sistema de contención'},
                                    ]}
                                />                                                                
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) =>(formData.seguridads != undefined &&                                        
                                        <FormDataConsumer>
                                        {
                                            ({ formData, ...rest }) =>(formData.seguridads[0] !== undefined &&                                        
                                                <ReferenceInput label="Tipo de Elemento" source="tipoElementoSeguridad"   reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{sistema : 'SEG'}} sort={{field : 'codigo' , order:'ASC'}} >
                                                    <AutocompleteInput  optionText="nombre" options={{fullWidth : true}} />
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={12} style={{borderBottom: '1px solid black'}}>
                                <CheckboxGroupInput  source='elemento_alrededors' 
                                    validate={[SeleccionTabla]}
                                    choices={[                               
                                        {id:'elemento_alrededors' , name:'Alrededores'}
                                    ]}
                                />
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) =>(formData.elemento_alrededors !== undefined &&                                        
                                        <FormDataConsumer>
                                        {
                                            ({ formData, ...rest }) =>(formData.elemento_alrededors[0] !== undefined &&                                        
                                                <ReferenceInput label="Tipo de Elemento" source="tipoElementoAlrededor"  reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{sistema : 'ALR'}} sort={{field : 'codigo' , order:'ASC'}} >
                                                    <AutocompleteInput  optionText="nombre" options={{fullWidth : true}} />
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>       
                            <Grid item xs={12} style={{borderBottom: '1px solid black'}}>                 
                                <CheckboxGroupInput  source='elemento_drenajes' 
                                    validate={[SeleccionTabla]}
                                    choices={[                               
                                        {id:'elemento_drenajes' , name:'Drenajes'}
                                    ]}
                                />
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) =>(formData.elemento_drenajes !== undefined &&                                        
                                        <FormDataConsumer>
                                        {
                                            ({ formData, ...rest }) =>(formData.elemento_drenajes[0] !== undefined &&                                        
                                                <ReferenceInput label="Tipo de Elemento" source="tipoElementoDrenaje"  reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{sistema : 'DRE'}} sort={{field : 'codigo' , order:'ASC'}} >
                                                    <AutocompleteInput  optionText="nombre" options={{fullWidth : true}} />
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={12} style={{borderBottom: '1px solid black'}}>
                                <CheckboxGroupInput  source='elemento_senalizacions' 
                                    validate={[SeleccionTabla]}
                                    choices={[                               
                                        {id:'elemento_senalizacions' , name:'Señalización'}
                                    ]}
                                />
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) =>(formData.elemento_senalizacions !== undefined &&                                        
                                        <FormDataConsumer>
                                        {
                                            ({ formData, ...rest }) =>(formData.elemento_senalizacions !== undefined &&                                        
                                                <ReferenceInput label="Tipo de Señal" source="tipoElementoSenal"  reference="tipo_elemento_senals" fullWidth 
                                                    filterToQuery={searchText => ({ codigo: searchText })}  
                                                    sort={{field : 'codigo' , order:'ASC'}} 
                                                    validate={TipoElementoSenal}
                                                >
                                                    <AutocompleteInput  optionText="nombre" options={{fullWidth : true}} />
                                                </ReferenceInput>
                                            )
                                        }
                                        </FormDataConsumer>
                                    )
                                }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) =>(formData.tipoElementoSenal !== undefined &&                                      
                                        <ReferenceInput label="Tipo Elemento" source="tipoSenal"  reference="tipo_senals" fullWidth
                                            filterToQuery={searchText => ({ codigo: searchText })}  
                                            sort={{field : 'codigo' , order:'ASC'}} 
                                            filter={{ tipoElementoSenal : formData.tipoElementoSenal }}
                                            validate={TipoSenal}
                                        >
                                            <AutocompleteInput  optionText="nombre" options={{fullWidth : true}} />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) =>(formData.tipoElementoSenal !== undefined &&  formData.tipoElementoSenal === '/tipo_elemento_senals/SV' &&                                        
                                        <ReferenceInput label="Tipo Modelo" source="tipoSenalVertical"  reference="senals" fullWidth filterToQuery={searchText => ({ codigo: searchText })}  sort={{field : 'codigo' , order:'ASC'}} filter={{ tipoSenal : formData.tipoSenal }}>
                                            <AutocompleteInput  optionText="nombre" options={{fullWidth : true}} />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={12} style={{borderBottom: '1px solid black'}}>  
                                <CheckboxGroupInput  source='elemento_contencions' 
                                    validate={[SeleccionTabla]}
                                    choices={[                               
                                        {id:'elemento_contencions' , name:'Contención'}
                                    ]}
                                />
                            </Grid>
                            <CheckboxGroupInput  source='elemento_pontons' 
                                validate={[SeleccionTabla]}
                                choices={[                               
                                    {id:'elemento_pontons' , name:'Pontón'}
                                ]}
                            />
                            <CheckboxGroupInput  source='puentes' 
                                validate={[SeleccionTabla]}
                                choices={[                               
                                    {id:'puentes' , name:'Puentes'}
                                ]}
                            />
                            <CheckboxGroupInput  source='otros_elementos' 
                                validate={[SeleccionTabla]}
                                choices={[                               
                                    {id:'otros_elementos' , name:'Otros elementos'}
                                ]}
                            />
                            <CheckboxGroupInput  source='elemento_reds' 
                                validate={[SeleccionTabla]}
                                choices={[                               
                                    {id:'elemento_reds' , name:'Redes'}
                                ]}
                            />
                            <CheckboxGroupInput  source='tuneles' 
                                validate={[SeleccionTabla]}
                                choices={[
                                    // {id:'seguridads' , name:'Sistema de contención'},
                                    {id:'tuneles' , name:'Tuneles'}
                                ]}
                            />
                            <CheckboxGroupInput  source='flexible_detalles' 
                                validate={[SeleccionTabla]}
                                choices={[
                                    // {id:'seguridads' , name:'Sistema de contención'},
                                    {id:'flexible_detalles' , name:'Semgnto Fexible'}
                                ]}
                            />
                            <CheckboxGroupInput  source='rigido_detalles' 
                                validate={[SeleccionTabla]}
                                choices={[
                                    // {id:'seguridads' , name:'Sistema de contención'},
                                    {id:'rigido_detalles' , name:'Segmento Rígido'}
                                ]}
                            />
                            <CheckboxGroupInput  source='adoquin_detalles' 
                                validate={[SeleccionTabla]}
                                choices={[
                                    // {id:'seguridads' , name:'Sistema de contención'},
                                    {id:'adoquin_detalles' , name:'Segmento Adoquín'}
                                ]}
                            />
                            <CheckboxGroupInput  source='otro_detalles' 
                                validate={[SeleccionTabla]}
                                choices={[
                                    // {id:'seguridads' , name:'Sistema de contención'},
                                    {id:'otro_detalles' , name:'Segmento Otro'}
                                ]}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) =>( 
                            <MapWithAMarker
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDnpZTyYCu_WV19ztwMGY90hvOQ-nEcLXs&v=3.exp&libraries=geometry, rawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `800px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                defaultCenter={{lat : 4.56 , lng : -74}}              
                                info={formData.informacionGeneral}                                  
                            />
                            )
                        }
                    </FormDataConsumer>
                    </Grid>
                    
                    
                    {/* ----------------- COORDENADAS DEL PROYECTO 'CENTER' ----------------- */}
                    
                        <Query type='GET_ONE' resource='proyectos' payload={{ id: window.localStorage.getItem('id_project2') }}>
                            {({ data, loading, error }) => {
                                  
                                if(data)
                                {                                       
                                    return(                                                                                                
                                        data.ubicaciones.map(items =>{
                                            center={
                                                lat : items['lat'],
                                                lng : items['lng']
                                            }
                                            coordenadasProyec.push(center)
                                        })                                                                                                                                              
                                    )
                                }
                                return <div></div>

                            }}
                        </Query>
                    {/* ----------------- FIN COORDENADAS DEL PROYECTO 'CENTER' ----------------- */}
                    
                    <Grid item xs={12}>
                    <FormDataConsumer>
                    {
                        ({ formData, ...rest }) =>(formData.table != null && formData.table !== undefined &&
                            <NumberInput source='cantidad' defaultValue={formData.table.length}/>
                        )
                    }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) =>(
                                <Grid>
                                    <Query type="GET_LIST" resource='seguridads' payload={payload} >
                                        {({ data, loading, error }) => { 
                                            let infoA = []; 
                                            let queryA = [];                                   
                                            let informacionGeneral = queryA;
                                            if(data !== null && formData.seguridads !== undefined)
                                            {

                                                data.map(item =>{                                                      
                                                    if(formData.seguridads[0] === 'seguridads'){
                                                        // if( item.ubicacionMapa !== null && item.ubicacionMapa !== undefined){
                                                            
                                                            infoA = {
                                                                ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                icon : item.tipoElemento_.src,
                                                                elemento : 'Seguridad',
                                                                codigo : item.codigo,
                                                                abInicio : item.abInicio,
                                                                tipoElemento: item.tipoElemento_.nombre,
                                                                unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                tramo : item.tramo_.fullCodigos,
                                                                segmento : item.segmento_.fullCodigos,                                                                                                                                 
                                                            }
                                                            
                                                        // }
                                                    }else{
                                                        infoA = {
                                                            ubicaciones : {lat: 4.55 , lng :  -74},
                                                            icon : '/imagenes/senalesColombia/defecto',
                                                            elemento : 'seguridads'
                                                        }
                                                        
                                                    }
                                                    queryA.push(infoA);
                                                })
                                                
                                                
                                                
                                            }

                                            return(
                                                <Query type="GET_LIST" resource='elemento_alrededors' payload={payload} >
                                                {({ data, loading, error }) => { 
                                                    if(formData.elemento_alrededors !== undefined)
                                                    {
                                                        data.map(item =>{    
                                                            if(formData.elemento_alrededors[0] === 'elemento_alrededors'){
                                                                if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                infoA = {
                                                                    ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                 
                                                                    icon : item.tipoElemento_.src,
                                                                    elemento : 'Alrededores',
                                                                    codigo : item.codigo,
                                                                    abInicio : item.abInicio,
                                                                    tipoElemento: item.tipoElemento_.nombre,
                                                                    unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                    tramo : item.tramo_.fullCodigos,
                                                                    segmento : item.segmento_.fullCodigos,  
                                                                }
                                                                }
                                                            }else{
                                                                infoA = {
                                                                    ubicaciones : {lat: 4.58 , lng :  -74},
                                                                    icon : '/imagenes/senalesColombia/defecto',
                                                                    elemento : 'elemento_alrededors'
                                                                }
                                                            }
                                                            queryA.push(infoA);
                                                        })
                                                    }
                                                    return(
                                                        <Query type="GET_LIST" resource='elemento_drenajes' payload={payload} >
                                                        {({ data, loading, error }) => { 
                                                            if(formData.elemento_drenajes !== undefined)
                                                            {
                                                                data.map(item =>{ 
                                                                    if(formData.elemento_drenajes[0] === 'elemento_drenajes'){
                                                                        if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                            infoA = {
                                                                                ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                icon : item.tipoElemento_.src,
                                                                                elemento : 'Drenajes',
                                                                                codigo : item.codigo,
                                                                                abInicio : item.abInicio,
                                                                                tipoElemento: item.tipoElemento_.nombre,
                                                                                unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                tramo : item.tramo_.fullCodigos,
                                                                                segmento : item.segmento_.fullCodigos, 
                                                                            }
                                                                        }                                                                        
                                                                    }else{
                                                                        infoA = {
                                                                            ubicaciones : {lat: 4.60 , lng :  -74},
                                                                            icon : '/imagenes/senalesColombia/defecto',
                                                                            elemento : 'elemento_drenajes'
                                                                        }
                                                                    }
                                                                    queryA.push(infoA);
                                                                })
                                                            }
                                                            return(
                                                                <Query type="GET_LIST" resource='elemento_senalizacions' payload={payloadSenal} >
                                                                {({ data, loading, error }) => { 
                                                                    if(formData.elemento_senalizacions !== undefined)
                                                                    {
                                                                        data.map(item =>{ 
                                                                            if(formData.elemento_senalizacions[0] === 'elemento_senalizacions'){
                                                                                if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                                    if(item.tipoElementoSenal_.originId === 'SV'){
                                                                                        infoA = {
                                                                                            ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                            icon : item.senal_.url,
                                                                                            elemento : item.senal_.nombre,
                                                                                            codigo : item.codigo,
                                                                                            abInicio : item.abInicio,
                                                                                            tipoElemento: item.senal_.nombre,
                                                                                            unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                            tramo : item.tramo_.fullCodigos,
                                                                                            segmento : item.segmento_.fullCodigos, 
                                                                                        }
                                                                                        queryA.push(infoA);
                                                                                    }else{
                                                                                        infoA = {
                                                                                            ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                            icon : item.tipoSenal_.url,
                                                                                            elemento : item.tipoSenal_.nombre,
                                                                                            codigo : item.codigo,
                                                                                            abInicio : item.abInicio,
                                                                                            tipoElemento: item.tipoSenal_.nombre,
                                                                                            unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                            tramo : item.tramo_.fullCodigos,
                                                                                            segmento : item.segmento_.fullCodigos, 
                                                                                        }
                                                                                        queryA.push(infoA);
                                                                                    }      
                                                                                                                                                                  
                                                                                }                                                                        
                                                                            }else{
                                                                                infoA = {
                                                                                    ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                    icon : '/imagenes/senalesColombia/defecto',
                                                                                    elemento : 'elemento_senalizacions'
                                                                                }
                                                                            }
                                                                            
                                                                        })
                                                                    }
                                                                    return(
                                                                    <Query type="GET_LIST" resource='tuneles' payload={payload} >
                                                                    {({ data, loading, error }) => { 
                                                                        if(formData.tuneles !== undefined)
                                                                        {
                                                                            data.map(item =>{ 
                                                                                if(formData.tuneles[0] === 'tuneles'){
                                                                                    if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                                        infoA = {
                                                                                            ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                            icon : 'imagenes/senalesColombia/Elementos/tunel/TNEL.png',
                                                                                            elemento : 'Señal',
                                                                                            codigo : item.codigo,
                                                                                            abInicio : item.abInicio,
                                                                                            tipoElemento: 'Tunel',
                                                                                            unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                            tramo : item.tramo_.fullCodigos,
                                                                                            segmento : item.segmento_.fullCodigos, 
                                                                                        }
                                                                                    }                                                                        
                                                                                }else{
                                                                                    infoA = {
                                                                                        ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                        icon : '/imagenes/senalesColombia/defecto',
                                                                                        elemento : 'tuneles'
                                                                                    }
                                                                                }
                                                                                queryA.push(infoA);
                                                                            })
                                                                        }
                                                                        return(<Query type="GET_LIST" resource='elemento_contencions' payload={payload} >
                                                                        {({ data, loading, error }) => { 
                                                                            if(formData.elemento_contencions !== undefined)
                                                                            {
                                                                                data.map(item =>{ 
                                                                                    if(formData.elemento_contencions[0] === 'elemento_contencions'){
                                                                                        if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                                            infoA = {
                                                                                                ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                                icon : item.tipoElemento_.src,
                                                                                                elemento : 'Señal',
                                                                                                codigo : item.codigo,
                                                                                                abInicio : item.abInicio,
                                                                                                tipoElemento: 'Tunel',
                                                                                                unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                tramo : item.tramo_.fullCodigos,
                                                                                                segmento : item.segmento_.fullCodigos, 
                                                                                            }
                                                                                        }                                                                        
                                                                                    }else{
                                                                                        infoA = {
                                                                                            ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                            icon : '/imagenes/senalesColombia/defecto',
                                                                                            elemento : 'elemento_contencions'
                                                                                        }
                                                                                    }
                                                                                    queryA.push(infoA);
                                                                                })
                                                                            }
                                                                            return(<Query type="GET_LIST" resource='elemento_pontons' payload={payload} >
                                                                                {({ data, loading, error }) => { 
                                                                                if(formData.elemento_pontons !== undefined)
                                                                                {
                                                                                    data.map(item =>{ 
                                                                                        if(formData.elemento_pontons[0] === 'elemento_pontons'){
                                                                                            if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                                                infoA = {
                                                                                                    ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                                    icon : 'imagenes/senalesColombia/Elementos/ponton/PTON.png',
                                                                                                    elemento : 'Pontón',
                                                                                                    codigo : item.codigo,
                                                                                                    abInicio : item.abInicio,
                                                                                                    tipoElemento: 'Pontón',
                                                                                                    unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                    tramo : item.tramo_.fullCodigos,
                                                                                                    segmento : item.segmento_.fullCodigos, 
                                                                                                }
                                                                                            }                                                                        
                                                                                        }else{
                                                                                            infoA = {
                                                                                                ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                icon : '/imagenes/senalesColombia/defecto',
                                                                                                elemento : 'elemento_pontons'
                                                                                            }
                                                                                        }
                                                                                        queryA.push(infoA);
                                                                                    })
                                                                                }
                                                                                return(<Query type="GET_LIST" resource='puentes' payload={payload} >
                                                                                        {({ data, loading, error }) => { 
                                                                                        if(formData.puentes !== undefined)
                                                                                        {
                                                                                            data.map(item =>{ 
                                                                                                if(formData.puentes[0] === 'puentes'){
                                                                                                    if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                                                        infoA = {
                                                                                                            ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                                            icon : item.src,
                                                                                                            elemento : 'Puente',
                                                                                                            codigo : item.codigo,
                                                                                                            abInicio : item.abInicio,
                                                                                                            tipoElemento: 'Puente',
                                                                                                            // unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                            tramo : item.tramo_.fullCodigos,
                                                                                                            segmento : item.segmento_.fullCodigos, 
                                                                                                        }
                                                                                                    }                                                                        
                                                                                                }else{
                                                                                                    infoA = {
                                                                                                        ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                        icon : '/imagenes/senalesColombia/defecto',
                                                                                                        elemento : 'puentes'
                                                                                                    }
                                                                                                }
                                                                                                queryA.push(infoA);
                                                                                            })
                                                                                        }
                                                                                        return(<Query type="GET_LIST" resource='otros_elementos' payload={payload} >
                                                                                                {({ data, loading, error }) => { 
                                                                                                if(formData.otros_elementos !== undefined)
                                                                                                {
                                                                                                    data.map(item =>{ 
                                                                                                        if(formData.otros_elementos[0] === 'otros_elementos'){
                                                                                                            if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                                                                infoA = {
                                                                                                                    ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                                                    icon : item.tipoElemento_.src,
                                                                                                                    elemento : item.tipoElemento_.nombre,
                                                                                                                    codigo : item.codigo,
                                                                                                                    abInicio : item.abInicio,
                                                                                                                    tipoElemento: 'Otros Elementos',
                                                                                                                    // unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                                    tramo : item.tramo_.fullCodigos,
                                                                                                                    segmento : item.segmento_.fullCodigos, 
                                                                                                                }
                                                                                                            }                                                                        
                                                                                                        }else{
                                                                                                            infoA = {
                                                                                                                ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                                icon : '/imagenes/senalesColombia/defecto',
                                                                                                                elemento : 'otros_elementos'
                                                                                                            }
                                                                                                        }
                                                                                                        queryA.push(infoA);
                                                                                                    })
                                                                                                }
                                                                                                return(<Query type="GET_LIST" resource='elemento_reds' payload={payload} >
                                                                                                        {({ data, loading, error }) => { 
                                                                                                        if(formData.elemento_reds !== undefined)
                                                                                                        {
                                                                                                            data.map(item =>{ 
                                                                                                                if(formData.elemento_reds[0] === 'elemento_reds'){
                                                                                                                    if( item.latitudInicial !== null && item.latitudInicial !== undefined){
                                                                                                                        infoA = {
                                                                                                                            ubicaciones : {lat : item.latitudInicial , lng: item.longitudInicial},                                                                
                                                                                                                            icon : item.tipoElemento_.src,
                                                                                                                            elemento : item.tipoElemento_.nombre,
                                                                                                                            codigo : item.codigo,
                                                                                                                            abInicio : item.abInicio,
                                                                                                                            tipoElemento: 'Redes',
                                                                                                                            // unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                                            tramo : item.tramo_.fullCodigos,
                                                                                                                            segmento : item.segmento_.fullCodigos, 
                                                                                                                        }
                                                                                                                    }                                                                        
                                                                                                                }else{
                                                                                                                    infoA = {
                                                                                                                        ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                                        icon : '/imagenes/senalesColombia/defecto',
                                                                                                                        elemento : 'elemento_reds'
                                                                                                                    }
                                                                                                                }
                                                                                                                queryA.push(infoA);
                                                                                                            })
                                                                                                        }
                                                                                                        return(<Query type="GET_LIST" resource='flexible_detalles' payload={payload} >
                                                                                                                {({ data, loading, error }) => { 
                                                                                                                if(formData.flexible_detalles !== undefined)
                                                                                                                {
                                                                                                                    data.map(item =>{ 
                                                                                                                        
                                                                                                                        if(formData.flexible_detalles[0] !== undefined){
                                                                                                                            
                                                                                                                            if( item.segmento_.latitud !== null && item.segmento_.longitud !== undefined){
                                                                                                                                
                                                                                                                                infoA = {
                                                                                                                                    ubicaciones : {lat : item.segmento_.latitud , lng: item.segmento_.longitud},                                                                
                                                                                                                                    icon : "/imagenes/senalesColombia/Elementos/segmento_inventaro/Flexible.png",
                                                                                                                                    elemento : 'Segmento flexible',
                                                                                                                                    codigo : item.segmento_.fullCodigos,
                                                                                                                                    abInicio : item.segmento_.abInicioA,
                                                                                                                                    tipoElemento: 'SegmentoFlexible',
                                                                                                                                    // unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                                                    tramo : item.tramo_.fullCodigos,
                                                                                                                                    segmento : item.segmento_.fullCodigos, 
                                                                                                                                }
                                                                                                                            }                                                                        
                                                                                                                        }else{
                                                                                                                            infoA = {
                                                                                                                                ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                                                icon : '/imagenes/senalesColombia/defecto',
                                                                                                                                elemento : 'flexible_detalles'
                                                                                                                            }
                                                                                                                        }
                                                                                                                        queryA.push(infoA);
                                                                                                                    })
                                                                                                                }
                                                                                                                return(<Query type="GET_LIST" resource='rigido_detalles' payload={payload} >
                                                                                                                        {({ data, loading, error }) => { 
                                                                                                                        if(formData.rigido_detalles !== undefined)
                                                                                                                        {
                                                                                                                            data.map(item =>{ 
                                                                                                                                
                                                                                                                                if(formData.rigido_detalles[0] !== undefined){
                                                                                                                                    
                                                                                                                                    if( item.segmento_.latitud !== null && item.segmento_.longitud !== undefined){
                                                                                                                                        
                                                                                                                                        infoA = {
                                                                                                                                            ubicaciones : {lat : item.segmento_.latitud , lng: item.segmento_.longitud},                                                                
                                                                                                                                            icon : "/imagenes/senalesColombia/Elementos/segmento_inventaro/Rigido.png",
                                                                                                                                            elemento : 'Segmento Rígido',
                                                                                                                                            codigo : item.segmento_.fullCodigos,
                                                                                                                                            abInicio : item.segmento_.abInicioA,
                                                                                                                                            tipoElemento: 'SegmentoFlexible',
                                                                                                                                            // unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                                                            tramo : item.tramo_.fullCodigos,
                                                                                                                                            segmento : item.segmento_.fullCodigos, 
                                                                                                                                        }
                                                                                                                                    }                                                                        
                                                                                                                                }else{
                                                                                                                                    infoA = {
                                                                                                                                        ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                                                        icon : '/imagenes/senalesColombia/defecto',
                                                                                                                                        elemento : 'rigido_detalles'
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                queryA.push(infoA);
                                                                                                                            })
                                                                                                                        }
                                                                                                                        return(<Query type="GET_LIST" resource='adoquin_detalles' payload={payload} >
                                                                                                                        {({ data, loading, error }) => { 
                                                                                                                        if(formData.adoquin_detalles !== undefined)
                                                                                                                        {
                                                                                                                            data.map(item =>{ 
                                                                                                                                
                                                                                                                                if(formData.adoquin_detalles[0] !== undefined){
                                                                                                                                    
                                                                                                                                    if( item.segmento_.latitud !== null && item.segmento_.longitud !== undefined){
                                                                                                                                        
                                                                                                                                        infoA = {
                                                                                                                                            ubicaciones : {lat : item.segmento_.latitud , lng: item.segmento_.longitud},                                                                
                                                                                                                                            icon : "/imagenes/senalesColombia/Elementos/segmento_inventaro/Adoquin.png",
                                                                                                                                            elemento : 'Segmento Adoquin',
                                                                                                                                            codigo : item.segmento_.fullCodigos,
                                                                                                                                            abInicio : item.segmento_.abInicioA,
                                                                                                                                            tipoElemento: 'SegmentoFlexible',
                                                                                                                                            // unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                                                            tramo : item.tramo_.fullCodigos,
                                                                                                                                            segmento : item.segmento_.fullCodigos, 
                                                                                                                                        }
                                                                                                                                    }                                                                        
                                                                                                                                }else{
                                                                                                                                    infoA = {
                                                                                                                                        ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                                                        icon : '/imagenes/senalesColombia/defecto',
                                                                                                                                        elemento : 'adoquin_detalles'
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                queryA.push(infoA);
                                                                                                                            })
                                                                                                                        }
                                                                                                                        return(<Query type="GET_LIST" resource='otro_detalles' payload={payload} >
                                                                                                                                {({ data, loading, error }) => { 
                                                                                                                                if(formData.otro_detalles !== undefined)
                                                                                                                                {
                                                                                                                                    data.map(item =>{ 
                                                                                                                                        
                                                                                                                                        if(formData.otro_detalles[0] !== undefined){
                                                                                                                                            
                                                                                                                                            if( item.segmento_.latitud !== null && item.segmento_.longitud !== undefined){
                                                                                                                                                
                                                                                                                                                infoA = {
                                                                                                                                                    ubicaciones : {lat : item.segmento_.latitud , lng: item.segmento_.longitud},                                                                
                                                                                                                                                    icon : "/imagenes/senalesColombia/Elementos/segmento_inventaro/Otros.png",
                                                                                                                                                    elemento : 'Segmento Otro',
                                                                                                                                                    codigo : item.segmento_.fullCodigos,
                                                                                                                                                    abInicio : item.segmento_.abInicioA,
                                                                                                                                                    tipoElemento: 'SegmentoFlexible',
                                                                                                                                                    // unidadFuncional : item.unidadFuncional_.fullCodigos,
                                                                                                                                                    tramo : item.tramo_.fullCodigos,
                                                                                                                                                    segmento : item.segmento_.fullCodigos, 
                                                                                                                                                }
                                                                                                                                            }                                                                        
                                                                                                                                        }else{
                                                                                                                                            infoA = {
                                                                                                                                                ubicaciones : {lat: 4.60 , lng :  -74},
                                                                                                                                                icon : '/imagenes/senalesColombia/defecto',
                                                                                                                                                elemento : 'otro_detalles'
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        queryA.push(infoA);
                                                                                                                                    })
                                                                                                                                }
                                                                                                                                return(<ArrayInput source='informacionGeneral' style={{display :'none'}} defaultValue={informacionGeneral}>
                                                                                                                                        <SimpleFormIterator>
                                                                                                                                            <TextInput source='elemento'/>
                                                                                                                                            <TextInput source='icon'/>
                                                                                                                                        </SimpleFormIterator>
                                                                                                                                    </ArrayInput>)
                                                                                                                                }}                                                                    
                                                                                                                            </Query>)
                                                                                                                                }}                                                                    
                                                                                                                    </Query>)
                                                                                                                        }}                                                                    
                                                                                                                    </Query>)
                                                                                                                }}                                                                    
                                                                                                            </Query>)
                                                                                                        }}                                                                    
                                                                                                    </Query>)
                                                                                                }}                                                                    
                                                                                            </Query>)
                                                                                        }}                                                                    
                                                                                    </Query>)
                                                                                }}                                                                    
                                                                            </Query>)
                                                                            }}                                                                    
                                                                        </Query>)
                                                                        }}                                                                    
                                                                    </Query>)
                                                                    }}                                                                    
                                                                </Query>)
                                                            }}
                                                            
                                                        </Query>)
                                                    }}
                                                </Query>      
                                            )                                 
                                        }}
                                    </Query>
                                </Grid>
                            )
                        }
                    </FormDataConsumer>
                    </Grid>
                    
                </Grid>
            </SimpleForm>
        </List>
)};



  
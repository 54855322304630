import React from 'react';
import { TextField, ReferenceField} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class AsociarServicios extends React.Component{
    
    componentDidMount(){
        console.log("propiedades")
        console.log(this.props)
    }
    render(){
        let valores=[];
        let tiposervicio=[];
        valores=this.props.servicios;
        if(valores){
            valores.map((valor)=>{
                tiposervicio.push(valor.tipoServicio);
            })
        }
        return(        
            <Table  aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="right">Directorio</TableCell>
                        <TableCell align="right">Grupo / Sistema</TableCell>
                        <TableCell align="right">Elemento</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {valores.map((row, i)=>(
                        <TableRow>
                            <TableCell align="right">{row.serviciosDirectorio}</TableCell>
                            {tiposervicio[i]===1 &&
                            <TableCell align="right">{row.serviciosGrupoEmergencias}</TableCell>
                            }
                            {tiposervicio[i]===2 &&
                            <TableCell align="right">{row.serviciosSistemaProveedores_.nombre}</TableCell>
                            }
                            {tiposervicio[i]===3 &&
                            <TableCell align="right">{row.serviciosGrupoServicios}</TableCell>
                            }
                            {tiposervicio[i]===1 &&
                            <TableCell align="right">{row.serviciosElementoEmergencias_.empresa}</TableCell>
                            }
                            {tiposervicio[i]===2 &&
                            <TableCell align="right">{row.serviciosElementoProveedores_.razonSocial}</TableCell>
                            }
                            {tiposervicio[i]===3 &&
                            <TableCell align="right">{row.serviciosElementoServicios_.empresa}</TableCell>
                            }
                        </TableRow>
                    ))}        
                </TableBody>
            </Table>
        )
    }
};
export default AsociarServicios;
import React, { useState, Fragment } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, FormDataConsumer, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { PimsActionsWithoutImage } from './../../../helpers/PimsActionsWithoutImage';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import AddElementosMantenimientoParticular from './../localHelpers/AddElementosMantenimientoParticular';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import config from '../../../config/config';
let urlAppjs = config.UrlFront;

export const PoliticasMantenimientoParticularCreate = withStyles(styles)(({ classes, ...props }) => {

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    const [getSistema, setSistema] = useState();
    const regSistema = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';
        // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        // console.log("sistema->", valor)
        setSistema(value);
    }

    const [getTipoElemento, setTipoElemento] = useState();
    const regTipoElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElemento(valor)
    }

    const [getUnidadFuncional, setUnidadFuncional] = useState();
    const validarUnidadFuncional = (value) => {
        setUnidadFuncional(value);
    }
    const [getTramo, setTramo] = useState(null);
    const validarTramo = (value) => {
        setTramo(value);
    }

    const [getAbscisaInicial, setAbscisaInicial] = useState(0);
    const validarAbscisaInicial = (value) => {
        if(value) {
            var val = value.split("K")[1].split("+")
            val=val[0] +"."+val[1];
            val=parseFloat(val);
            setAbscisaInicial(val);
        }
    }

    const [getAbscisaFinal, setAbscisaFinal] = useState(0);
    const validarAbscisaFinal = (value) => {
        if(value) {
            var val = value.split("K")[1].split("+")
            val=val[0] +"."+val[1];
            val=parseFloat(val);
            setAbscisaFinal(val);
        }
    }

    

    return (
        <PimsCreate  {...props} title="Crear Política de Mantenimiento Particular" actions={<PimsActionsWithoutImage />} >
            <TabbedForm 
                redirect="list" 
                //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE MANTENIMIENTO ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={6}>
                            <TextInput label="Nombre de la Política" source="nombre" validate={[required()]} fullWidth />
                        </Grid>
                        <FormDataConsumer>
                        {
                            ({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                    <Grid item xs={3}>
                                        <ReferenceInput
                                            label="Sistema"
                                            source="sistema"
                                            reference="sistemas"
                                            filter={{
                                                visibleOnPolicies: 1
                                            }}
                                            validate={[required(), regSistema]}
                                            onChange={value => dispatch(
                                                change(REDUX_FORM_NAME, 'tipoElemento', null)
                                            )}
                                            fullWidth
                                        >
                                            <SelectInput optionText="nombre" fullWidth />
                                        </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                        <ReferenceInput
                                            label="Elemento"
                                            source="tipoElemento"
                                            reference="tipo_elementos"
                                            onChange={regTipoElemento}
                                            filter={{
                                                visibleOnPolicies: 1,
                                                sistema : getSistema
                                            }}
                                            validate={[required()]}
                                            fullWidth
                                        >
                                            <SelectInput optionText="nombre" fullWidth />
                                        </ReferenceInput>
                                        </Grid>
                                </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {
                            ({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                id="departamento"
                                                // value="" 
                                                label="Unidad Funcional"
                                                source="unidadFuncional"
                                                reference="unidad_funcionals"
                                                onChange={value => dispatch(
                                                    change(REDUX_FORM_NAME, 'tramo', null)
                                                )}
                                                validate={[required(), validarUnidadFuncional]}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="Tramo"
                                                source="tramo"
                                                reference="tramos"
                                                validate={[validarTramo]}
                                                filter={{
                                                    unidadFuncional: getUnidadFuncional
                                                }}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="Abscisa Inicial" source="abInic" fullWidth validate={[validarAbscisaInicial]} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="Abscisa Final" source="abFin" fullWidth validate={[validarAbscisaFinal]} />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            {getTipoElemento && getUnidadFuncional &&
                                <AddElementosMantenimientoParticular 
                                    tramo={getTramo} 
                                    tipoElemento={getTipoElemento} 
                                    abInicio={getAbscisaInicial} 
                                    abFin={getAbscisaFinal}
                                 />
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <div className={classes.fieldCol12}>
                                
                            </div>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

});

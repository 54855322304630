import React,{ useState } from 'react';
import { required,minLength,maxLength,CardActions,ListButton,minValue,maxValue,NumberInput,TabbedForm,FormTab,ShowButton,  ReferenceInput, SelectInput, } from 'react-admin';
import { Edit } from './../../Edit';
import ViewHistorico from './ViewHistorico/historico';
import Grid from '@material-ui/core/Grid';
import { styles } from './../../../EstilosGrid';

import {
    Toolbar,
    SaveButton,

   
} from 'react-admin';
import { withStyles } from '@material-ui/core';
export const PeajesmensualEdit = withStyles(styles)(({ classes, ...props }) => {
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};
const [peajes, setPeaje] = useState('');
  const ValidarPeaje = (value) => {

    //let lenghtObject = Object.keys(value);
   // let valor = '';

    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setPeaje(value)

  }
  const [sentidos, setSentido] = useState('');
  const ValidarSentido = (value) => {

    //let lenghtObject = Object.keys(value);
    //let valor = '';

    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    //console.log(valor.toLowerCase());
    setSentido(value)

  }
  const [años, setAño] = useState('');
  const validarAño = (value) => {

    //let lenghtObject = Object.keys(value);
    //let valor = '';

    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setAño(value)

  }
const TramoTitle = ({ record }) => {
    return <span>Tramo {record ? `${record.fullCode}` : ''}</span>;
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        
    </Toolbar>
));
const filtro = [];
const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};
const PostActions = ({basePath,data}) =>(
<CardActions style={cardActionStyle}>
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
        <ShowButton basePath={basePath} label="Histórico" record={data} />
    </CardActions> 
);
        return(
    <Edit title="Editar Tránsito Histórico" {...props}  redirect="list" actions={<PostActions/>} toolbar={<CustomToolbar />}>
       <TabbedForm  redirect="list">
            <FormTab label="Identificación">
                <Grid container spacing={24}>
                  <Grid item xs={4}>
                  <ReferenceInput label="Peaje" disabled source="inventario_peajes" id="peajes_id" reference="inventariopeajes" filter={{ Peajes: peajes }} fullWidth validate={required(), ValidarPeaje}>                
                        <SelectInput optionText="nombre" />                
                      </ReferenceInput>
                  </Grid>  
                  {peajes &&
                   <Grid item xs={4}>
                    <SelectInput label="Sentido" disabled source="sentido"  choices={[
                                    { id: 'Ascendente', name: 'Ascendente' },
                                    { id: 'Descendente', name: 'Descendente' } 
                                ]} fullWidth validate={required(),ValidarSentido} />
                  </Grid>
}   
                  <Grid item xs={4}>
                  <span></span>
                  </Grid>
                  <Grid item xs={4}>
                  <SelectInput label="Año" source="ano"  disabled choices={[
                                    { id: '2000', name: '2000' },
                                    { id: '2001', name: '2001' },
                                    { id: '2002', name: '2002' },
                                    { id: '2003', name: '2003' },
                                    { id: '2004', name: '2004' },
                                    { id: '2005', name: '2005' },
                                    { id: '2006', name: '2006' },
                                    { id: '2007', name: '2007' },
                                    { id: '2008', name: '2008' },
                                    { id: '2009', name: '2009' },
                                    { id: '2010', name: '2010' },
                                    { id: '2011', name: '2011' },
                                    { id: '2012', name: '2012' },
                                    { id: '2013', name: '2013' },
                                    { id: '2014', name: '2014' },
                                    { id: '2015', name: '2015' },
                                    { id: '2016', name: '2016' },
                                    { id: '2017', name: '2017' },
                                    { id: '2018', name: '2018' },
                                    { id: '2019', name: '2019' },
                                    { id: '2020', name: '2020' },
                                    { id: '2021', name: '2021' },
                                    { id: '2022', name: '2022' },
                                    { id: '2023', name: '2023' },
                                    { id: '2024', name: '2024' },
                                    { id: '2025', name: '2025' },
                                    { id: '2026', name: '2026' },
                                    { id: '2027', name: '2027' },
                                    { id: '2028', name: '2028' },
                                    { id: '2029', name: '2029' },
                                    { id: '2030', name: '2030' }
                                ]} fullWidth validate={required(),validarAño}/>
                  </Grid>
                  {años &&       
                  <Grid item xs={4}>
                    <SelectInput
                        label="Mes"
                        source="mes"
                        choices={[
                            { id: '1', name: 'Enero' },
                            { id: '2', name: 'Febrero' },
                            { id: '3', name: 'Marzo' },
                            { id: '4', name: 'Abril' },
                            { id: '5', name: 'Mayo' },
                            { id: '6', name: 'Junio' },
                            { id: '7', name: 'Julio' },
                            { id: '8', name: 'Agosto' },
                            { id: '9', name: 'Septiembre' },
                            { id: '91', name: 'Octubre' },
                            { id: '92', name: 'Noviembre' },
                            { id: '93', name: 'Diciembre' }                                    
                        ]} fullWidth validate={required()}
                  disabled  />      
                  </Grid>       
}
                <Grid item xs={12}>
                <span></span>     
                <span></span>   
                <b>Volumen de Vehículos por categoría</b>
                <hr></hr>
                <span></span>
                <span></span>
                </Grid>
                </Grid>
                <Grid item xs={4}>
                  <NumberInput label="Categoría I" source="categoria_a" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="Categoría II" source="categoria_b" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="Categoría III" source="categoria_c" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="Categoría IV" source="categoria_d" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="Categoría V" source="categoria_e" fullWidth validate={[required(),  minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  
                  
            </FormTab>
            <FormTab label="Histórico">
            <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <ViewHistorico  sentido={sentidos} peaje={peajes} ano={años} tipo="tipo" filtro={filtro}/> }
                </WithProps>
            </FormTab>
            
      </TabbedForm>
    </Edit>
)
});
const WithProps = ({children,...props}) => children(props);
import React from 'react';
import { TextField, NumberField,BooleanField,SelectField, DateField} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import config from '../../../config/config';
let urlAppjs = config.UrlServer;

class TablaReparacion extends React.Component{

    constructor(props){
        super(props)
        this.state = { TablaEstructura: [] }
        this.dateFormatter = this.dateFormatter.bind(this);
       
      
    }

    componentWillMount() {
        var urlApi = urlAppjs+"/"+this.props.tabla+"/"+this.props.id;
        
        fetch(urlApi)
          .then((response) => {
            return response.json()
          })
          .then((TablaEstructura) => {
            this.setState({ TablaEstructura: TablaEstructura })
          })
      }

      dateFormatter(DataRep) {
          let dateHelper = "";

          if (DataRep['fecha1'] != undefined) {
            dateHelper    = DataRep['fecha1'].split("-");
            DataRep['fecha1'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha2'] != undefined) {
            dateHelper    = DataRep['fecha2'].split("-");
            DataRep['fecha2'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha3'] != undefined) {
            dateHelper    = DataRep['fecha3'].split("-");
            DataRep['fecha3'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }    

          if (DataRep['fecha14'] != undefined) {
            dateHelper    = DataRep['fecha14'].split("-");
            DataRep['fecha14'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha15'] != undefined) {
            dateHelper    = DataRep['fecha15'].split("-");
            DataRep['fecha15'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha16'] != undefined) {
            dateHelper    = DataRep['fecha16'].split("-");
            DataRep['fecha16'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha17'] != undefined) {
            dateHelper    = DataRep['fecha17'].split("-");
            DataRep['fecha17'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha18'] != undefined) {
            dateHelper    = DataRep['fecha18'].split("-");
            DataRep['fecha18'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha19'] != undefined) {
            dateHelper    = DataRep['fecha19'].split("-");
            DataRep['fecha19'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha10'] != undefined) {
            dateHelper    = DataRep['fecha10'].split("-");
            DataRep['fecha10'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          if (DataRep['fecha21'] != undefined) {
            dateHelper    = DataRep['fecha21'].split("-");
            DataRep['fecha21'] = dateHelper[2] + "/" + dateHelper[1] + "/" + dateHelper[0];
          }

          return DataRep
      }

      render() {
        let DataRep = this.state.TablaEstructura;
        if (DataRep != "") {
          DataRep = this.dateFormatter(DataRep);
          return (
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Table  aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Componente</TableCell>
                      <TableCell align="right">Aplica</TableCell>
                      <TableCell align="right">Fecha reparación</TableCell>
                      <TableCell align="right">Tipo</TableCell>
                      <TableCell align="right">Cantidad</TableCell>
                      <TableCell align="right">Año</TableCell>
                      <TableCell align="right">Costo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow >
                      <TableCell component="th"> Superficie del pontón </TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica1']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha1']}</TableCell>
                      <TableCell align="right">{DataRep['text1']}</TableCell>
                      <TableCell align="right">{DataRep['cant1']}</TableCell>
                      <TableCell align="right">{DataRep['sel13']}</TableCell>
                      <TableCell align="right">{DataRep['precio1']}</TableCell>
                    </TableRow>
                    <TableRow >
                      <TableCell component="th">Andenes / Bordillos </TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica2']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha2']}</TableCell>
                      <TableCell align="right">{DataRep['text2']}</TableCell>
                      <TableCell align="right">{DataRep['cant2']}</TableCell>
                      <TableCell align="right">{DataRep['sel2']}</TableCell>
                      <TableCell align="right">{DataRep['precio2']}</TableCell>       
                    </TableRow>
                    <TableRow >
                      <TableCell component="th">Barandas</TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica3']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha3']}</TableCell>
                      <TableCell align="right">{DataRep['text3']}</TableCell>
                      <TableCell align="right">{DataRep['cant3']}</TableCell>
                      <TableCell align="right">{DataRep['sel3']}</TableCell>
                      <TableCell align="right">{DataRep['precio3']}</TableCell>       
                    </TableRow>
                    <TableRow >
                      <TableCell component="th"> Conos /Taludes </TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica14']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha14']}</TableCell>
                      <TableCell align="right">{DataRep['text14']}</TableCell>
                      <TableCell align="right">{DataRep['cant14']}</TableCell>
                      <TableCell align="right">{DataRep['sel14']}</TableCell>
                      <TableCell align="right">{DataRep['precio14']}</TableCell>             
                    </TableRow>
                    <TableRow >
                      <TableCell component="th"> Elementos de armadura</TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica15']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha15']}</TableCell>
                      <TableCell align="right">{DataRep['text15']}</TableCell>
                      <TableCell align="right">{DataRep['cant15']}</TableCell>
                      <TableCell align="right">{DataRep['sel15']}</TableCell>
                      <TableCell align="right">{DataRep['precio15']}</TableCell>           
                    </TableRow>
                    <TableRow >
                      <TableCell component="th"> Cauce</TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica16']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha16']}</TableCell>
                      <TableCell align="right">{DataRep['text16']}</TableCell>
                      <TableCell align="right">{DataRep['cant16']}</TableCell>
                      <TableCell align="right">{DataRep['sel16']}</TableCell>
                      <TableCell align="right">{DataRep['precio16']}</TableCell>           
                    </TableRow>
                    <TableRow >
                      <TableCell component="th">Losa superior</TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica17']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha17']}</TableCell>
                      <TableCell align="right">{DataRep['text17']}</TableCell>
                      <TableCell align="right">{DataRep['cant17']}</TableCell>
                      <TableCell align="right">{DataRep['sel17']}</TableCell>
                      <TableCell align="right">{DataRep['precio17']}</TableCell>    
                    </TableRow>
                    <TableRow >
                      <TableCell component="th">Losa inferior </TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica18']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha18']}</TableCell>
                      <TableCell align="right">{DataRep['text18']}</TableCell>
                      <TableCell align="right">{DataRep['cant18']}</TableCell>
                      <TableCell align="right">{DataRep['sel18']}</TableCell>
                      <TableCell align="right">{DataRep['precio18']}</TableCell>             
                    </TableRow>
                    <TableRow >
                      <TableCell component="th"> Muros </TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica19']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha19']}</TableCell>
                      <TableCell align="right">{DataRep['text19']}</TableCell>
                      <TableCell align="right">{DataRep['cant19']}</TableCell>
                      <TableCell align="right">{DataRep['sel19']}</TableCell>
                      <TableCell align="right">{DataRep['precio19']}</TableCell>            
                    </TableRow>
                    <TableRow >
                      <TableCell component="th"> Otros elementos</TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica10']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha10']}</TableCell>
                      <TableCell align="right">{DataRep['text10']}</TableCell>
                      <TableCell align="right">{DataRep['cant10']}</TableCell>
                      <TableCell align="right">{DataRep['sel10']}</TableCell>
                      <TableCell align="right">{DataRep['precio10']}</TableCell>             
                    </TableRow>
                    <TableRow >
                      <TableCell component="th"> Pontón en general</TableCell>              
                      <TableCell align="right"><Switch checked={DataRep['aplica21']} color="primary" /></TableCell>
                      <TableCell align="right">{DataRep['fecha21']}</TableCell>
                      <TableCell align="right">{DataRep['text21']}</TableCell>
                      <TableCell align="right">{DataRep['cant21']}</TableCell>
                      <TableCell align="right">{DataRep['sel21']}</TableCell>
                      <TableCell align="right">{DataRep['precio21']}</TableCell>          
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>   
          )
        } else {           
          return (
            <p className="text-center">Cargando Tabla...</p>            
          )
        }

      }
}

export default TablaReparacion;
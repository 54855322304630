import React from 'react';
import { TabbedShowLayout,Tab, TextField, DateField,NumberField,  BooleanField,SelectField} from 'react-admin';
import { Show } from './../../Show';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


export const DeterioroPersShow = props => (
    <Show  {...props}  title='Deterioro PERS' bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>            
             <Tab  label="Rparación PERS">   
             <SelectField label="Tipo de reparación" source='tipoSuperficie' choices={[
                {id:'PFLEX', name:'Flexible'},
                { id:'PRIGD', name:'Rígido'}
            ]}/>
            
            <NumberField label='Código ASTM' source="codigoAstm"/>
            <SelectField label="Nivel de serveridad" source='nivelSeveridad' choices={[
                {id:1, name:1},
                {id:2, name:2},
                {id:3, name:3},
            ]} />  
            
            <BooleanField label="Estado" source="estado" />
            <TextField label="Nombre" source="nombre" />
            <TextField label="Unidad" source="unidad" />        
            <TextField label="Tipo de reparaciones severidad media" source="tipoReparacionMedia_.nombre" />
            <TextField label="Tipo de reparaciones severidad alta" source="tipoReparacionAlta_.nombre" /> 
            </Tab> 
            <Tab  label="Auditoría">   
                <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <div></div>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <div></div>    
            </Tab> 
        </TabbedShowLayout>
    </Show>
);
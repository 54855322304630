import React,{ useState, useMemo } from 'react';
import { TabbedShowLayout, Tab,RadioButtonGroupInput,SelectField,CheckboxGroupInput ,SelectInput, TextField, DateField, ReferenceField, FormTab, TabbedForm
 } from 'react-admin';
import ViewHistorico from './ViewHistorico/historico';
import ViewCategoria from './categoria/categoria';
import { Show } from './../../Show';
import Divider from '@material-ui/core/Divider';
import { PRField } from './../../../fields/PRField';
import { withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../../EstilosGrid';
import Grid from '@material-ui/core/Grid';

export const PeajesmensualShow = withStyles(styles)(({ classes, record, ...props }) => {

//     const [peajes, setPeaje] = useState('');
//   const peaje = (value) => {

//     //let lenghtObject = Object.keys(value);
//    // let valor = '';

//     //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
//     let valor2 = value.split("/inventariopeajes/");
//     setPeaje(valor2[1])

//   }
//   const [sentidos, setSentido] = useState('');
//   const ValidarSentido = (value) => {

//     //let lenghtObject = Object.keys(value);
//     //let valor = '';

//     //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
//     //console.log(valor.toLowerCase());
//     setSentido(value)

//   }
//   const [años, setAño] = useState('');
//   const validarAño = (value) => {

//     //let lenghtObject = Object.keys(value);
//     //let valor = '';

//     //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
//     setAño(value)

//   }
const choices=[
    { id: '2000', name: '2000' },
    { id: '2001', name: '2001' },
    { id: '2002', name: '2002' },
    { id: '2003', name: '2003' },
    { id: '2004', name: '2004' },
    { id: '2005', name: '2005' },
    { id: '2006', name: '2006' },
    { id: '2007', name: '2007' },
    { id: '2008', name: '2008' },
    { id: '2009', name: '2009' },
    { id: '2010', name: '2010' },
    { id: '2011', name: '2011' },
    { id: '2012', name: '2012' },
    { id: '2013', name: '2013' },
    { id: '2014', name: '2014' },
    { id: '2015', name: '2015' },
    { id: '2016', name: '2016' },
    { id: '2017', name: '2017' },
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
    { id: '2024', name: '2024' },
    { id: '2025', name: '2025' },
    { id: '2026', name: '2026' },
    { id: '2027', name: '2027' },
    { id: '2028', name: '2028' },
    { id: '2029', name: '2029' },
    { id: '2030', name: '2030' }
]

// const muestra_oculta = (id)=>{
//     if (document.getElementById(id)){
//     let lol = document.getElementById("año");
//     var el = document.getElementById(id);
//     if(lol.value !== ""){
//     el.style.display = (el.style.display == 'flex') ? 'flex' : 'flex';
//     // console.log(lol)
//     }
// }
//     }
const [ano1, setAno1] = useState('');
const validarAño1 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
   
    setAno1(valor)


}
const [ano2, setAno2] = useState('');
const validarAño2 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    
    setAno2(valor)


}

const [categoria1, setCategoria1] = useState('');
const validarCategoria1 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    
    setCategoria1(valor)


}
const [categoria2, setCategoria2] = useState('');
const validarCategoria2 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    
    setCategoria2(valor)


}
const [categoria3, setCategoria3] = useState('');
const validarCategoria3 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    
    setCategoria3(valor)


}
const [categoria4, setCategoria4] = useState('');
const validarCategoria4 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    
    setCategoria4(valor)


}
const [categoria5, setCategoria5] = useState('');
const validarCategoria5 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    
    setCategoria5(valor)


}

const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState();

const filtarCategorias = () => {
    
};

/**useMemo(() => {
    filtarCategorias();
}, [categoriasSeleccionadas])**/
const categorias = [
    { id: 'categoria_a', name: 'Categoría I' },
    { id: 'categoria_b', name: 'Categoría II' },
    { id: 'categoria_c', name: 'Categoría III' },
    { id: 'categoria_d', name: 'Categoría IV' },
    { id: 'categoria_e', name: 'Categoría V' },
];
const changeCategorias = (event, checked) => {
    setCategoriasSeleccionadas(checked);
}
const [años, setAño] = useState('');
  const validarAño = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';
    let categoria = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    if(valor === "año" ){
        categoria = "año";
    }
    else if(valor === "categoria"){
        categoria = 'categoria';
    }
    else{
        categoria = false
    }
    setAño(categoria)
    console.log(valor);
  }
  
const filtro = [];
    return (
        <Show title="Información Tránsito Histórico" {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="Información básica">

                    <ReferenceField label="Peaje" source="inventario_peajes" reference="mensualviews" linkType={false} fullWidth formClassName={classes.grid_cont3s}>
                        <TextField source="nombre" />
                    </ReferenceField>
                    
                    <ReferenceField label="Código" source="inventario_peajes" reference="inventariopeajes" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3s}>
                        <TextField source="codigo" />
                    </ReferenceField>
                    <TextField label="Sentido" source="sentido" fullWidth formClassName={classes.grid_cont3} />
                    
                    <br/>
                    <ReferenceField label="Unidad Funcional" source="inventario_peajes" reference="inventariopeajes" linkType={false} formClassName={classes.grid_cont3}>                
                    <TextField source="unidad_funcional_.fullCodigos" />                
            </ReferenceField>   
                    <ReferenceField label="Tramo" source="inventario_peajes" reference="inventariopeajes" linkType={false} formClassName={classes.grid_cont3}>                
                    <TextField source="tramo_.fullCodigos" />                
                    </ReferenceField>
                    <ReferenceField label="Segmento" source="inventario_peajes" reference="inventariopeajes" linkType={false} formClassName={classes.grid_cont3}>                
                    <TextField source="segmento_.fullCodigos" />                
                    </ReferenceField>
                    <br/>
                    <ReferenceField label="Abscisa" source="inventario_peajes" reference="inventariopeajes" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                        <PRField source="abInicio" />
                    </ReferenceField>

                    <TextField label="Año" source="ano" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3s} />
         
                    <SelectField
                        label="Mes"
                        source="mes"
                        choices={[
                            { id: '1', name: 'Enero' },
                            { id: '2', name: 'Febrero' },
                            { id: '3', name: 'Marzo' },
                            { id: '4', name: 'Abril' },
                            { id: '5', name: 'Mayo' },
                            { id: '6', name: 'Junio' },
                            { id: '7', name: 'Julio' },
                            { id: '8', name: 'Agosto' },
                            { id: '9', name: 'Septiembre' },
                            { id: '91', name: 'Octubre' },
                            { id: '92', name: 'Noviembre' },
                            { id: '93', name: 'Diciembre' }                                    
                        ]} formClassName={classes.grid_cont3s}
                    />      
                    <br/>
                    <b>Volumen de Vehículos por categoría</b>
                    <Divider fullWidth />
                    <br/>
                    <TextField label="Categoría I:" source="categoria_a" fullWidth style={{ display: "flex", justifyContent: "start",marginLeft: "20vh",marginTop: "-25px" }}/>
                    <br/>
                    <TextField label="Categoría II:" source="categoria_b" fullWidth style={{ display: "flex", justifyContent: "start", marginLeft: "20vh",marginTop: "-25px" }}/>
                    <br/>
                    <TextField label="Categoría III:" source="categoria_c" fullWidth style={{ display: "flex", justifyContent: "start", marginLeft: "20vh",marginTop: "-25px" }}/>
                    <br/>
                    <TextField label="Categoría IV:" source="categoria_d" fullWidth style={{ display: "flex", justifyContent: "start", marginLeft: "20vh",marginTop: "-25px" }}/>
                    <br/>
                    <TextField label="Categoría V:" source="categoria_e" fullWidth style={{ display: "flex", justifyContent: "start", marginLeft: "20vh",marginTop: "-25px" }}/>
        
                </FormTab>
                <FormTab label="Información anual">
            <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <ViewHistorico record={record}  sentido={record.sentido} peaje={record.inventario_peajes_.id} ano={record.ano} tipo="tipo" filtro={filtro}/> }
                </WithProps>                
            </FormTab>
                <FormTab label="Histórico">
                <RadioButtonGroupInput label=" " source="category"  onChange={validarAño} choices={[
                    {id: 'año', name: 'Por año consolidado' },
                    { id: 'categoria', name: 'Por categoría' },
                 ]} style={{marginTop:"50px"}} />
                 <Typography variant="h3" component="h3" className={classes.coord}>Años de evaluación</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />
                 {años === "año" &&
                 <SelectInput label="Desde" source="ano1" choices={choices} onChange={validarAño1} formClassName={classes.grid_cont3s}/>
                    }
                    {años === "año" &&
                 <SelectInput label ="Hasta" source="ano2" choices={choices} onChange={validarAño2} formClassName={classes.grid_cont3s}/>
                    }
                    {años === "año" && ano1 && ano2 &&
                    <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <ViewHistorico record={record} ano1={ano1}  ano2={ano2} filtro={años}/> }
                </WithProps> 
}
                    {años === "categoria" &&
                 <SelectInput label="Desde" source="ano1" choices={choices} onChange={validarAño1} formClassName={classes.grid_cont3s}/>
                    }
                    {años === "categoria" &&
                 <SelectInput label ="Hasta" source="ano2" choices={choices} onChange={validarAño2} formClassName={classes.grid_cont3s}/>
                    }
{años === "categoria" &&  <CheckboxGroupInput label=" " source="categorias" choices={categorias} optionText="name" onChange={changeCategorias} />
    //     <RadioButtonGroupInput label=" " source="categoria1"  onChange={validarCategoria1} choices={[
    //         { id: 'categoria_a', name: 'Categoria I' },
    //     ]}/>
    // }   
    // {años === "categoria" && 
    //     <RadioButtonGroupInput label=" " source="categoria2"  onChange={validarCategoria2} choices={[
    //         { id: 'categoria_b', name: 'Categoria II' },
    //     ]}/>
    // }   
    // {años === "categoria" && 
    //     <RadioButtonGroupInput label=" " source="categoria3"  onChange={validarCategoria3} choices={[
    //         { id: 'categoria_c', name: 'Categoria III' },
    //     ]}/>
    // }   
    // {años === "categoria" && 
    //     <RadioButtonGroupInput label=" " source="categoria4"  onChange={validarCategoria4} choices={[
    //         { id: 'categoria_d', name: 'Categoria IV' },
    //     ]}/>
    // }   
    // {años === "categoria" && 
    //     <RadioButtonGroupInput label=" " source="categoria5"  onChange={validarCategoria5} choices={[
    //         { id: 'categoria_e', name: 'Categoria V' },
    //     ]}/>
    }   

    {años ==="categoria"   && categoriasSeleccionadas &&
        <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
            <ViewCategoria ano1={ano1}  ano2={ano2} categoriasSeleccionadas={categoriasSeleccionadas} tipo="mostrar" /> }
        </WithProps>
        //  <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
        //         <ViewCategoria record={record} categoria1={categoria1} categoria2={categoria2} categoria3={categoria3} categoria4={categoria4} categoria5={categoria5} ano1={ano1}  ano2={ano2} filtro={años} tipo="mostrar" /> }
        //         </WithProps> 
}
            </FormTab>
                <FormTab label="Auditoría">
                    <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont3s}/>
                    <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont3}/>    
                    <br/>
                    <DateField source="updatedAt" label="Fecha actualización" formClassName={classes.grid_cont3s}/>
                    <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont3}/>
                </FormTab>
                
            </TabbedForm>
        </Show>
    )
});
const WithProps = ({children,...props}) => children(props);

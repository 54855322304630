import React from 'react';
import { TabbedForm,FormTab, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { Edit } from './../Edit';

import Grid from '@material-ui/core/Grid';


import {
    Toolbar,
    SaveButton,

} from 'react-admin';
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        
    </Toolbar>
));

export const TransitoEdit = props => (
    <Edit  {...props}  redirect="list" toolbar={<CustomToolbar />}>
      <TabbedForm>
            <FormTab label="Identificación">
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                    <ReferenceInput label="Provincia" source="municipio_.departamento" reference="departamentos" >
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                       
                    </Grid>
                    <Grid item xs={4}>
                    <ReferenceInput label="Distrito" source="municipio" reference="municipios" >
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                    </Grid>                    
                    <Grid item xs={4}>
                        <TextInput label="Codigo Identificación" source="identificacion" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="Nombre" source="nombre" fullWidth/>
                    </Grid>
                    <Grid item xs={4}>
                   <ReferenceInput label="Rutas" source="ruta" reference="rutas" >
                        <SelectInput  optionText="codigo" fullWidth />
                    </ReferenceInput>
                    </Grid>
                      <Grid item xs={4}>
                    <ReferenceInput label="Unidad Funcional" source="unidadfuncional" reference="unidad_funcionals" >
                        <SelectInput  optionText="codigo" fullWidth />
                    </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                    <ReferenceInput label="Tramos" source="tramo" reference="tramos" >
                        <SelectInput  optionText="codigo" fullWidth />
                    </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                    <ReferenceInput label="Segmentos" source="tiposegmento" reference="tipo_segmentos" >
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                    </Grid> 
                </Grid>
            </FormTab>
      </TabbedForm>
    </Edit>
);
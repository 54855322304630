import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { goBack } from 'react-router-redux';
import CancelIcon from '@material-ui/icons/Cancel';
class BackButton extends Component {
    handleClick = () => {
        this.props.goBack();
    };

    render() {
        return <Button variant="contained" color="primary" style={{backgroundColor : '#e36c6c' , mArginLeft : '30px'}} onClick={this.handleClick}>
                    <CancelIcon style={{width: '0.8em' , paddingRight:'8px'}}/>
                    Cancelar
            </Button>;
    }
}

export default connect(null, {
    goBack,
})(BackButton);
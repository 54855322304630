import React ,{useState}from 'react';
import { CardActions, Toolbar, TabbedForm, ShowController, ShowView,TextField, 
    DateField,ReferenceField, BooleanField, TextInput,FormTab,NumberField,
    ListButton,EditButton 
} from 'react-admin';
import { PRField } from './../../../fields/PRField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../../EstilosGrid';
import { GMapField } from '../../../fields/GMapField';
import { ButtonCarousel } from './../../images/ButtonCarousel';


export const ElementoContencionShow  = withStyles(styles)(({ classes, record, ...props }) => {
    const [imagen , setImagen] =useState(true);
    const [senalId , setSenalId] =useState(0);
     const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }
    //let elemento = "/tipo_elementos/COMR";
    return(
        <ShowController {...props} title="Información Elemento Contención">
        {controllerProps =>
            <ShowView {...props} {...controllerProps} title="Información Elemento Contención"  actions={<ButtonCarousel />}>
                <TabbedForm toolbar={false}>                
                    <FormTab label="Identificación">
                        <TextField label="Código" source="fullCodigo" fullWidth formClassName={classes.grid_cont6_f}/>
                         <br/>
                         <TextInput source="tipoElemento_.src" validate={ValidarImagen} formClassName={classes.ocultar}/>
                        <ReferenceField label="Tipo de elemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont6_f}>
                            <TextField  source="nombre"  />
                        </ReferenceField> 
                        <br/>
                        <TextField source="margen"fullWidth formClassName={classes.grid_cont4_f} /> 
                        <TextField source="tipo" fullWidth formClassName={classes.grid_cont4_s}/> 
                        <br/>
                        <TextField source="funcionalidad" fullWidth formClassName={classes.grid_cont4_f}/> 


                        <Typography variant="b" component="b">Localización</Typography> 
                        <Divider fullWidth style={{width:'300%',}} /> 
                        
                        
                        <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4_f}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField>
                        <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField>       
                        <br/>

                        <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                        <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                        
                        
                        <PRField label="Abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4_f}/>
                        <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/> 
                        <br/>


                        <Typography variant="b" component="b">Coordenadas Finales</Typography>
                        <Divider fullWidth style={{width:'300%' , marginTop :'10px'}} /> 
                        
                        

                        <PRField label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth formClassName={classes.grid_cont4_f}/>
                        <NumberField label="Latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <NumberField label="Longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <GMapField   fullWidth formClassName={classes.grid_cont12}
                            defaultZoom={12}                                    
                            defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                            source="ubicacionMapa"
                            multipleMarkers="true"
                            googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                            icon= {imagen} 
                         /> 
                    </FormTab>

                    <FormTab label="Características">                       
                        <NumberField label="Largo (m)"  source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                        <NumberField label="Ancho (m)"  source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <NumberField label="Altura (m)"  source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <br/>
                        <BooleanField label="Estudios de estabilidad del sitio"  source="estudioEstabilidad" fullWidth formClassName={classes.grid_cont12}/>
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <TextField   source="consultor" fullWidth formClassName={classes.grid_cont4_f}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <DateField label="Fecha del estudio"  source="fechaEstudio" fullWidth formClassName={classes.grid_cont4_s} />                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <br/>                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <DateField label="Código de estudio"  source="codigoEstudio" fullWidth formClassName={classes.grid_cont4_f}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <DateField label="Nombre del estudio"  source="nombreEstudio "fullWidth formClassName={classes.grid_cont4_s} />                          
                        }
                    </FormTab>
                    <FormTab label="Detalles">                    
                        <TextField label="Condición del elemento" fullWidth source="estado" fullWidth formClassName={classes.grid_cont4_f}/>                                                  
                        {controllerProps.record && controllerProps.record.estado  === "Reemplazo" &&
                            <TextField source="motivoReemplazo" fullWidth formClassName={classes.grid_cont4_s}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estado  === "Reemplazo" &&
                            
                            <ReferenceField 
                                label="Elemento que reemplazo" 
                                source="elementoReemplazo" 
                                reference="elemento_contencions" 
                                linkType={false} 
                                fullWidth 
                                formClassName={classes.grid_cont4_s}
                            >
                                <TextField  source="fullCodigo"  />
                            </ReferenceField> 
                        }
                        <br/>
                        <TextField label="Estado" source="estados" fullWidth formClassName={classes.grid_cont4_s}/>
                        <br/>
                        <DateField  label="Fecha de inspeccion" source="fechaInstalacion" fullWidth formClassName={classes.grid_cont4_f}/>                          
                        <TextField  label="Proveedor" source="proveedor_.razonSocial" fullWidth formClassName={classes.grid_cont4_s}/>                          
                        <NumberField  label="Garantía (meses)" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/> 
                        <br/>                         
                        <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont4_f}/>
                        <br/>
                        <TextField  source="observaciones" fullWidth formClassName={classes.grid_cont12}/>    
                        
                    </FormTab>
                    <FormTab label="AuditorÍa">
                        <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4}/> 
                        <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>               
                        <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4}/>
                        <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>    
                    </FormTab>                
                </TabbedForm>
            </ShowView>
        }
    </ShowController>
    )
});
import React, { useState } from 'react';
import { List, Datagrid,Toolbar,SelectInput,BooleanInput, ReferenceInput, TextField,SimpleForm, EditButton, CardActions,RadioButtonGroupInput,ShowButton} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import config from '../../../../config/config';
let urlAppjs = config.UrlServer;
class ViewCategoria extends React.Component{


    constructor(props){
        super(props)
        this.state = {mensualview: [],record: [],tipo: "",value: '',titulos:[],ano: [],anios: [] };
        this.leerMeses = this.leerMeses.bind(this);
        this.sumarMeses = this.sumarMeses.bind(this);
        this.sumarCategoria= this.sumarCategoria.bind(this);
        // this.array = {
        //     valoresx1 : 0,valoresx2 : 0,valoresx3 : 0,valoresx4 : 0, maximo: 0,minimo: 0,maximo1: 0,minimo1: 0,
        // }
        // this.validatenum = this.validatenum.bind(this);
    }
    componentDidMount(){
      this.setState({
        tipo: this.props.tipo,
        record: this.props.record,
      });
        // fetch(urlAppjs+"/mensualviews.json?inventio_peajes="+this.props.peaje+"&sentido="+this.props.sentido+"&ano="+this.props.ano)
         
        
      fetch(urlAppjs+"/tablacategory/"+this.props.ano1+"/"+this.props.ano2)
      .then((response) => {
        return response.json()
    })
    .then((mensualview) => {
        this.setState({ mensualview: mensualview.resultado })
    })
        // fetch (urlAppjs+"/tablahistorico/"+this.props.ano+"/"+valor2[1]+"/"+this.props.sentido)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((mensualview) => {
        //     this.setState({ mensualview: mensualview.resultado })
        // })
  
}
  leerMeses (data, anio) {
    if (data.ano === anio) {
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    return Object.keys(data).map((key, i) => {
      if(keysValidation.includes(key)) {
        return <TableCell key={i} align="right" fullWidth style={{textAlign:'right'}}>{data[key] ? data[key] : " "}</TableCell>
      }})
    }
  };

  sumarCategoria (data, anio) {
    if (data.ano === anio) {
    let sumaCategorias = 0;
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    Object.keys(data).map((key, i) => {
      if(keysValidation.includes(key)) {
        sumaCategorias += data[key] ? parseInt(data[key]) : 0;
      }})
      return <TableCell key='total' align="right" fullWidth style={{textAlign:'right'}}>{sumaCategorias}</TableCell>
    }
  };

  sumarMeses (data, anio) {
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];
    let sumaMeses = [];
    // var dataAnio = JSON.parse( JSON.stringify( data ) );
    var dataAnio = data;
    let dataAnios = [];
    if(dataAnio){
      
       dataAnios = dataAnio.filter(registro => registro.ano === anio)
    }
    let sumaMes = 0;
    let aSumar = [];

    if(typeof dataAnios !== 'undefined'){
      dataAnios.map(registro => {
      let vec = [];
      Object.keys(registro).map((key, i) => {
        if(keysValidation.includes(key)) {
          vec.push(registro[key] ? parseInt(registro[key]) : 0);
        }
      });
      aSumar.push(vec);
    });
  }
    console.log(aSumar);
    let sumsArr = [];
    for (let i = 0; i < aSumar[0].length; i += 1) {
      sumsArr.push(aSumar.map((val) => val[i])
        .reduce((acc, curr) => (acc+curr), 0)
      );
    }
    let sumTotal = 0
    sumsArr.forEach(element => {
      sumTotal += element;
    });
    sumsArr.push(sumTotal);
    return sumsArr.map(result =>
    <TableCell key='total' align="right" fullWidth style={{textAlign:'right'}}>{result ? result : ""}</TableCell>
    )
  }

    render(){
        const {
          tipo,
          mensualview,
          record,
          titulos,
      } = this.state;
      const { categoriasSeleccionadas } = this.props;
      const dataFiltrada = mensualview.filter(report =>
        categoriasSeleccionadas.includes(report.categoria)
        )
        console.log(dataFiltrada);
        const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
        var hash = {};
        for(let i = 0; i < dataFiltrada.length; i++) {
          try {
            let suma = 0;
            let val2 = 0;
            if((dataFiltrada[i].ano === dataFiltrada[i+1].ano) && (dataFiltrada[i].categoria === dataFiltrada[i+1].categoria) ) {
              Object.keys(dataFiltrada[i]).map((key, j) => {
                if(keysValidation.includes(key)) {
                  dataFiltrada[i][key] = dataFiltrada[i][key] ? parseInt(dataFiltrada[i][key], 10) : 0;
                  dataFiltrada[i+1][key] = dataFiltrada[i+1][key] ? parseInt(dataFiltrada[i+1][key], 10) : 0;
                  suma = dataFiltrada[i][key] + dataFiltrada[i+1][key]
                  dataFiltrada[i][key] = suma;
              }})
              suma = 0;
              delete dataFiltrada[i+1];
            }
          }catch (err) {

          }
        }
        console.log(dataFiltrada)
      // console.log(record);
      Array.prototype.unique=function(a){
        return function(){return this.filter(a)}}(function(a,b,c){return c.indexOf(a,b+1)<0
      });
      let aniosRF = [];
      dataFiltrada.map((reporte) => {
        aniosRF.push(reporte.ano)
      })
      
      aniosRF = aniosRF.unique();
    
    let categorias = [];

      let categoria_a = '';
      let categoria_b = '';
      let categoria_c = '';
      let categoria_d = '';
      let categoria_e = '';
      let año = [];
        // if (mensualview && typeof mensualview === 'object' && mensualview.length > 0){
        // mensualview.map((data,i)=>{
        //    data.ano  

    // categorias.map((data,res)=>{
    
    //      switch(mensual.categoria) {
    //     case 'categoria_a':
    //       mensual.categoria = 'Categoria I';
    //       break;
    //     case 'categoria_b':
    //       mensual.categoria = 'Categoria II';
    //       break;
    //     case 'categoria_c':
    //       mensual.categoria = 'Categoria III';
    //       break;
    //     case 'categoria_d':
    //       mensual.categoria = 'Categoria IV';
    //       break;
    //     case 'categoria_e':
    //       mensual.categoria = 'Categoria V';
    //       break;
    //   }
    //     })
    //   })
          
    //  }
    //  dataFiltrada.filter((mensual) => {
    //   switch(mensual.categoria) {
    //     case 'categoria_a':
    //       mensual.categoria = 'Cat.I';
    //       break;
    //     case 'categoria_b':
    //       mensual.categoria = 'Cat.II';
    //       break;
    //     case 'categoria_c':
    //       mensual.categoria = 'Cat.III';
    //       break;
    //     case 'categoria_d':
    //       mensual.categoria = 'Cat.IV';
    //       break;
    //     case 'categoria_e':
    //       mensual.categoria = 'Cat.V';
    //       break;
    //   }
    // });
    if (dataFiltrada) {
      dataFiltrada.map((mensual) => {
      switch(mensual.categoria) {
        case 'categoria_a':
          mensual.nombreCategoria = 'Cat.I';
          break;
        case 'categoria_b':
          mensual.nombreCategoria = 'Cat.II';
          break;
        case 'categoria_c':
          mensual.nombreCategoria = 'Cat.III';
          break;
        case 'categoria_d':
          mensual.nombreCategoria = 'Cat.IV';
          break;
        case 'categoria_e':
          mensual.nombreCategoria = 'Cat.V';
          break;
      }
    });
  }
    
        // const total=()=>{
        //     console.log('cualquier cosa')
        // let  a = [];
        // let b= [];
        // let  c = [];
        // let d= [];
        // let  e = [];
        // let total=[]
        // let categoria_a=document.getElementById(categoria_a)
        // let categoria_b=document.getElementById(categoria_b)
        // let categoria_c=document.getElementById(categoria_c)
        // let categoria_d=document.getElementById(categoria_d)
        // let categoria_e=document.getElementById(categoria_e)
        // if(categoria_a && categoria_b && categoria_c && categoria_d && categoria_e && categoria_a.value && categoria_b.value  && categoria_c.value && categoria_d.value && categoria_e.value){
        //     a.push(categoria_a.value)
        //     b.push(categoria_b.value)
        //     c.push(categoria_c.value)
        //     d.push(categoria_d.value)
        //     e.push(categoria_e.value)
        // }
        
        // if(a && b && c && d && e ){
        //     let t=0;
        //     let j=0;
        //     for(let i=0; i<5; i++){
        //         //$x=($a2-$a1)/($b1-$b2);
        //         //$y=($b1*$x)+$a1;
        //     let j=i-1; //j=1 i=2
        //         if(j<0){
        //             j=4;
        //         }
        //         t = (a[i] + b[i] + c[i] +d[i] + e[i]);

        //         total[i] = 0;
        //     }
        // }
        //         }
                
    // console.log(mensualview);
    if(tipo==='mostrar'){
    return(
        <React.Fragment>

<Table aria-label="simple table" fullWidth  style={{width: "100%"}}>

                         
                <TableHead>
              <TableRow style={{color:'#fff'}} >
                  <TableCell></TableCell>
                  <TableCell align="right" component="th" >Enero  </TableCell>
                  <TableCell align="right" component="th" >Febrero  </TableCell>
                  <TableCell align="right" component="th" >Marzo  </TableCell>
                  <TableCell align="right" component="th" >Abril  </TableCell>
                  <TableCell align="right" component="th" >Mayo  </TableCell>
                  <TableCell align="right" component="th" >Junio  </TableCell>
                  <TableCell align="right" component="th" >Julio  </TableCell>
                  <TableCell align="right" component="th" >Agosto  </TableCell>
                  <TableCell align="right" component="th" >Septiembre </TableCell>
                  <TableCell align="right" component="th" >octubre </TableCell>
                  <TableCell align="right" component="th" >Noviembre  </TableCell>
                  <TableCell align="right" component="th" >Diciembre  </TableCell>
                  <TableCell align="right" component="th" >Total  </TableCell>
                  <br></br>
                {/* {mensualview.map((data,i)=>(
                    
                <TableCell align="right" component="th" > {data.ano}</TableCell>
                                                     
                ))
                } */}
              </TableRow>
            </TableHead>
            
            <TableBody>
            
              {aniosRF.map(anio => (
                <>
              <TableRow>
                <TableCell key={anio} align="right" component="th" fullWidth>
                  <h3 style={{marginBottom: 0}}>{anio}</h3><br/>    
                </TableCell>
              </TableRow>
              {dataFiltrada.map((data, i) => (
                <>
                        {anio === data.ano ?
                        (<TableRow >   
                        <TableCell align="right" component="th" fullWidth>
                        <strong style={{marginBottom: 0}}>{data.nombreCategoria}</strong><br/>    
                        </TableCell>
                        
                        {this.leerMeses(data, anio)}
                        {this.sumarCategoria(data, anio)}
                        {/* <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span> {data.enero ? data.enero : " "} </span></TableCell>
                         <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span> {data.febrero ? data.febrero : " "} </span></TableCell>  
                       <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.marzo ? data.marzo : " " }</span> </TableCell>  
                       <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.abril ? data.abril : " "}</span></TableCell>  
                        <TableCell align="right"fullWidth  style={{textAlign:'right'}}> <span >{data.mayo ? data.mayo : " "}</span></TableCell> 
                        <TableCell align="right"fullWidth  style={{textAlign:'right'}}> <span >{data.junio ? data.junio : " "}</span></TableCell> 
                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.julio ? data.julio : " "}</span></TableCell> 
                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.agosto ? data.agosto : " "}</span></TableCell>              
                       <TabeleCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.septiembre ? data.septiembre : " "}</span></TableCell> 
                       <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.octubre ? data.octubre : " "}</span></TableCell> 
                       <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.noviembre ? data.noviembre : " "}</span></TableCell> 
                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{data.diciembre ? data.diciembre : " "}</span></TableCell>  */}
                 
                    {/* <TableCell align="right" > <span ></span></TableCell>  */}
                   
                    </TableRow>) : null
                    }
                    </>
                    )
                    
                    )}
                    <TableRow>
                    <TableCell key={anio} align="right" component="th" fullWidth>
                      <h3 style={{marginBottom: 0}}>TOTAL</h3><br/>    
                    </TableCell>
                    {this.sumarMeses(dataFiltrada, anio)}
                    </TableRow>
                    </>))}
                    </TableBody>
                          
                        </Table>
        </React.Fragment>
    )
            }
            else{

              return(
                <React.Fragment>
                </React.Fragment>
            )

            }
     
            }
           
                
          }




export default ViewCategoria;
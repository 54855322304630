import React, {useState} from 'react';
import { TextInput, ReferenceInput, SelectInput, AutocompletetInput,FormDataConsumer, NumberInput, AutocompleteInput, 
         SimpleFormIterator, ArrayInput, Query} from 'react-admin';
import { Edit } from './../Edit';
import { PInput } from './../../inputs/PInput';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { SimpleForm } from './../SimpleForm';
import {
    required,
    minLength,
    maxLength,
} from 'react-admin';
import  {abValidationInicio,prValidationInicio,abValidationFin, validarLogicaPrFinEdit,prValidationFin, validarLogicaPrFinCreate, ValorMaximoPrCreate, Altitud, AbsRamal,ValorMaximoPrEditInicio,ValorMaximoPrEditFin,
         validarLogicaAbsFinCreate, 
         latMininima,
         lngMininima,
         validarPrEntreSegmento,
         validarAbsEntreSegmento,
        }  from './../validacionesInputs';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const styles = {
    ocultar: {
        display:"none",
        
    },
    coord :{marginTop : '50px'},

};
export const SegmentoEdit = withStyles(styles)(({ classes, ...props }) =>{

    const [tramoss , setTramos]= useState('');
    const [idUnidade, setIdUnidad] =useState();
    const [segmentoId , setSegmentoId]= useState('');

    const [proyectos, setProyecto] = useState( window.localStorage.getItem('id_project2'));
    const [departamentoss , setDepartamento]= useState('');

        const ValidarDepartamento = (value)  => {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setDepartamento(value) 
        }


    const Departamento = { departamento: departamentoss };

    const [prValidador, setPrValidador] = useState('');
    const [AbValidador, setAbValidador] = useState('');

/*VALIDAR  QUE LOS SEGMENTOS NO SE SOBREPISEN*/
    const ValidarTramo = (value)  => {      
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }  
        setTramos(value)          
        let urlUnidad = window.location.hash;
        setIdUnidad(urlUnidad.split("%2Fsegmentos%2F")) 
    }

/* FIN DE VALIDAR  QUE LOS SEGMENTOS SE SOBREPISEN*/
    const validarPr =(value) =>{            
        if(value)
        {   if(document.getElementById('ingresoPrInicio')){
                value = document.getElementById('ingresoPrInicio').value;               
                let sperador1 = value.split('PR');
                let separador2 = sperador1[1].split('+');
                let cadena = separador2[0] + '.' + separador2[1]                                
                if(parseFloat(cadena) < parseFloat(prValidador)){
                    return 'El Pr ingresado debe ser mayor a ' +   prValidador
                } 
            }                
        }
    };
    const validarAbs =(value) =>{            
        if(value)
        {                   
            let sperador1 = value.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena = separador2[0] + '.' + separador2[1]                
            if(parseFloat(cadena) < parseFloat(AbValidador)){
                return 'La Abscisa inicial debe ser mayor a ' +   AbValidador
            }   
        }
    };
    const [latMax , setLatMax]= useState('');
    const LatMax =(value) =>{            
        setLatMax(value) 
    }

    const [lngMax , setLngMax]= useState('');
    const LngMax =(value) =>{
        setLngMax(value) 
    }

    const [lngMin , setLngMin]= useState('');
    const LngMin =(value) =>{     
        setLngMin(value) 
    }

    const [latMin , setLatMin]= useState('');
    const LatMin =(value) =>{           
        setLatMin(value) 
    }
/*CAMPOS ESPECIALES PARA ORDENMIENTO*/
    const[segmentoCodigo, setSegmentoCodigo] = useState('');
    const SegmentoCodigo = (value) =>{
        setSegmentoCodigo(value)
    }
    const[calzadaCodigo, setCalzadaCodigo] = useState('');
    const CalzadaCodigo = (value) =>{
        setCalzadaCodigo(value)
    }
    const[superficieCodigo, setSuperficieCodigo] = useState('');
    const SuperficieCodigo = (value) =>{
        setSuperficieCodigo(value)
    }

    const [tipoElemento, setTipoElemento] = useState(false);
    const TIpoElemento = (value) =>{
        setTipoElemento(value)
    }
/* FIN DE CAMPOS ESPECIALES PARA ORDENMIENTO*/


/* ABSCISAS DEL SEGMENTO PARA SELECCIONAR */
const[abInicio ,setAbInicio] = useState('');
const[abFin ,setAbFin] = useState('');

const AbscisasInicial = () =>{
    let values = document.getElementById('absSgm');
    
    if(values !== null && values !== '' && values !== undefined){
        let absSegIni = document.getElementById('absSegIni');
        let absSegFin = document.getElementById('absSegFin');
        let valor=values.value;
        
        if(absSegIni && valor !== undefined && valor !== null && valor !== ''){
            setAbInicio(absSegIni.value);
            setAbFin(absSegFin.value);                
                     
            valor = valor.split('K');                
            valor = valor[1].split('+');                
            valor = valor[0] + '.' + valor[1];
            
            if(parseFloat(valor) < parseFloat(abInicio) || parseFloat(valor) > parseFloat(abFin)){
                
                return 'Error el rago debe estar entre ' + abInicio + ' y ' + abFin;
            }    
                    
        }
    }        
}

    const Titulo = () => {
        if(tipoElemento=== 'R')
        {
            return 'Editar Ramal'
        }else if(tipoElemento=== 'SGM'){
            return 'Editar Segmento'
        }else{
            return 'Editar'
        }
    }


    /*VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/
    const NoSobreponerAbs = () =>{
            
        // unidad  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdnidadFuncional = tramoss.substr(8);        
        let abInicioA = document.getElementById('ingresoAbInicio');
        let abFinA = document.getElementById('ingresoAbFin'); 
        if(abInicioA && abFinA)
        {
            abInicioA = abInicioA.value
            abFinA = abFinA.value
            abInicioA = abInicioA.substr(1, abInicioA.lastIndexOf('+')-1)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
            abFinA = abFinA.substr(1, abFinA.lastIndexOf('+')-1)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
            fetch(urlAppjs+'/validar/Segmentos/abs/Editar/SMG/'+IdnidadFuncional+'/'+abInicioA +'/'+ abFinA+'/'+idUnidade[1])
            .then(function(response) {
                
                return response.text();
            })
            .then(function(myJson) {  
                setAbValidador(myJson)   
                // setContador(1)
                if(AbValidador === '1'){
            
                    return 'Rango no válido'
                }                
            })
        }        
        if(AbValidador === '1'){
        
            return 'Rango no válido'
        }
    }

    const MansajeValidadorAb = () =>{
        if(AbValidador === '1'){
        
            return 'Rango no válido'
        }
    }

    const NoSobreponerPr = () =>{
        // unidad  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdRuta = tramoss.substr(8);      
        let abInicioA = document.getElementById('ingresoPrInicio');
        let abFinA = document.getElementById('ingresoPrFin'); 

        abInicioA = abInicioA.value
        abFinA = abFinA.value
        abInicioA = abInicioA.substr(2, abInicioA.lastIndexOf('+')-2)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
        abFinA = abFinA.substr(2, abFinA.lastIndexOf('+')-2)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
        fetch(urlAppjs+'/validar/rango/Segmentos/pr/Editar/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/'+idUnidade[1])
        .then(function(response) {
            
            return response.text();
        })
        .then(function(myJson) {  
            setPrValidador(myJson)   
            // setContador(1)
            if(AbValidador === '1'){
        
                return 'Rango no válido'
            }                
        })
        if(prValidador === '1'){
        
            return 'Rango no válido'
        }
    }

    const MansajeValidadorPb = () =>{
        if(prValidador === '1'){
        
            return 'Rango de Pr no válido'
        }
    }
/* FIN DE VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/


    return(
    <Edit  title={<Titulo/>} {...props}>
        <SimpleForm submitOnEnter={false} redirect="list" >            
            <Grid container spacing={24}>
                <Grid item xs={12}>    
                    <SelectInput label="Tipo" source="tipoElemento" disabled  fullWidth choices={[
                        {id: 'R' , name : ' Ramal'},
                        {id: 'SGM' , name : 'Segmento'}
                    ]} validate={[required(), TIpoElemento]}/>                     
                </Grid>
            </Grid>
            {tipoElemento === 'R' &&
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <ReferenceInput
                            id="segmentoId"
                            label="Segmento"
                            source="segmentoId"
                            reference="segmentos"
                            sort={{ field: 'codigo', order: 'ASC' }}
                            filterToQuery={searchText => ({ codigo: searchText })}
                            validate={[ required()]}
                        >
                            <AutocompleteInput  id="segmentoId" options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>                 
                    </Grid>        
                                 
                    {/* <TextInput label="Segmento" style={{display : 'none'}} source="segmentoId" defaultValue={segmentoId} fullWidth validate={[required(), minLength(2), maxLength(4)]}/> */}
                    <Grid item xs={6}>
                        <TextInput label="Código" source="codigo" id="codigo" fullWidth validate={[required(), minLength(2)]}/>
                    </Grid>
                    <Grid item xs={6}  >
                        <TextInput label="Identificación del ramal" source="identificacionRamal"  fullWidth validate={[maxLength(20)]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <PRInput label="Abscisa del segmento" id="absSgm" source="absSgm" fullWidth validate={[AbscisasInicial]}/>
                    </Grid>
                    
                    {tramoss &&                      
                        <Query type="GET_ONE" resource="segmentos" payload={{ id: tramoss}} >
                            {({ data, loading, error }) => {                                                
                                if( data){               
                                    return (
                                        <Grid item xs={4} style={{display : 'none'}}>                                                                                
                                            <TextInput label="Abscisa inicial del segmento"  id="absSegIni" defaultValue={data.abInicioA} source="absSegIni" fullWidth validate={[required()]}/>
                                            <TextInput label='Abscisa final del segmento'    id="absSegFin" defaultValue={data.abFinA} fullWidth source='absSegFin' />
                                            <TextInput label="Tramo" disabled style={{display : 'none'}} defaultValue={data.tramo} source="tramo" fullWidth validate={[required()]}/>
                                            {/* <TextInput label='Código RR'  defaultValue={data.codigo}  fullWidth source='codigoR' />                                            */}
                                        </Grid>
                                    )
                                }
                            if(loading){return <div></div>}                                                
                            }}
                        </Query>                            
                     }           
                    <Grid item xs={6}>                                        
                        <SelectInput label='Lado'  fullWidth source='lado' choices={[
                            {id: 'Izquierdo' , name: "Izquierdo"},
                            {id: 'Derecho' , name : 'Dereho'}
                        ]}/>
                    </Grid>

                    <FormDataConsumer>
                        {({ formData, ...rest }) => ( tramoss &&
                        <Grid item xs={6}>
                            <PRInput label="Progresiva inicial" id="ingresoAbInicio" source="abInicio" fullWidth validate={[required()]}/>
                        </Grid>
                            )
                        }
                    </FormDataConsumer>  
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>(tramoss &&
                        <Grid item xs={6}>
                            <PRInput label="Progresiva final" id="ingresoAbFin" source="abFin" fullWidth validate={[required(), AbsRamal]}/>
                        </Grid> 
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                    {({ formData, ...rest }) => (tramoss &&
                            <Grid item xs={6}>
                                 <PInput label="PR Inicio" source="pInicio"  id="ingresoPrInicio" fullWidth validate={[required()]}/>
                            </Grid>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>(tramoss &&
                            <Grid item xs={6}>
                                 <PInput label="PR Fin" source="pFin"  id="ingresoPrFin" fullWidth validate={[required()]}/>
                            </Grid>
                        )
                    }
                </FormDataConsumer>
                <Grid item xs={12}>
                <b>Localización</b>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>                   
                <Grid item xs={6}>
                    <ReferenceInput 
                        label="Provincia" 
                        source="segmentoId" 
                        reference="segmentos"  
                        disabled 
                        fullWidth validate={[required(), ValidarTramo]}
                        filterToQuery={searchText => ({ nombre: searchText })} 
                        >
                        <SelectInput optionText="departamentos" />
                    </ReferenceInput>  
                </Grid>
                <Grid item xs={6}>
                    <ReferenceInput 
                        label="Distrito" 
                        source="segmentoId" 
                        reference="segmentos" 
                        validate={[required(), ValidarTramo]}
                        disabled 
                        fullWidth 
                        >
                        <SelectInput optionText="municipios" />
                    </ReferenceInput>  
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="b" component="b" >Coordenadas iniciales</Typography>
                    <Divider style={{marginTop : '10px'}}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="LongitudInicial"  label="Latitud" source="latitud"  fullWidth validate={[required(), LatMin, latMininima]} />                   
                </Grid>
                <Grid item xs={4}>
                 <NumberInput id="LatitudInicial"  label="Longitud" source="longitud"  fullWidth validate={[required(), LngMin, lngMininima]}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="altura"  label="Altura (m.s.n.m)" source="alturaIni" validate={[Altitud]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="b" component="b">Coordenadas Finales</Typography>
                    <Divider style={{marginTop : '10px'}}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="longitud2"  label="Latitud" source="latitud2"  fullWidth validate={[required(), LatMax, latMininima]} />                    
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="longitud1"  label="Longitud" source="longitud2"  fullWidth validate={[required(), LngMax, lngMininima]}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="altura2"  label="Altura (m.s.n.m)" source="alturaFin"  validate={[Altitud]}  fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={8}>             
                    <ArrayInput label="" source="ubicaciones" className="estilos_array"  fullWidth className={classes.ocultar} 
                        defaultValue={[
                            {
                                lat:  latMin,
                                lng: lngMin
                            },
                            {
                                lat: latMax,
                                lng: lngMax
                            }
                        ]}
                    >
                        <SimpleFormIterator disableAdd disableRemove fullWidth  >                        
                            <NumberInput label="Latitud" source="lat" fullWidth style={{width : '95%'}} validate={[required()]}/>                            
                            <NumberInput label="Longitud" source="lng" fullWidth style={{width : '95%' , marginLeft : '20px'}} validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                    
                </Grid>
                <Grid item xs={12}>
                <b>Características</b>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Tipo Calzada" 
                        source="tipoCalzada" 
                        reference="tipo_calzadas" 
                        fullWidth 
                        validate={[required(),CalzadaCodigo]}
                        sort={{field : 'customOrder' , order : 'ASC'}} 
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                </Grid>
                {calzadaCodigo &&                                    
                    <Query type="GET_ONE" resource="tipo_calzadas" payload={{ id: calzadaCodigo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput  style={{display:'none'}} label="Nombre calzada" defaultValue={data.nombre} source="calzadaCodigo" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>     
                    // style={{display:'none'}}                       
                } 
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Tipo Segmento" 
                        source="tipoSegmento" 
                        reference="tipo_segmentos" 
                        fullWidth 
                        validate={[required(),SegmentoCodigo]}
                        sort={{field : 'customOrder' , order : 'ASC'}} 
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                </Grid>
                {segmentoCodigo &&                                    
                    <Query type="GET_ONE" resource="tipo_segmentos" payload={{ id: segmentoCodigo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput style={{display:'none'}} defaultValue={data.nombre} source="segmentoCodigo" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Tipo Superficie" 
                        source="tipoSuperficie" 
                        reference="tipo_elementos" 
                        filter={{sistema: 'CLZ'}} 
                        fullWidth validate={[required(), SuperficieCodigo]}
                        sort={{field : 'customOrder' , order : 'ASC'}} 
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                </Grid> 
                {superficieCodigo &&                                    
                    <Query type="GET_ONE" resource="tipo_elementos" payload={{ id: superficieCodigo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput style={{display:'none'}}  defaultValue={data.nombre} source="superficieCodigo" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                
                {proyectos &&                        
                    <Query type="GET_ONE" resource="proyectos" payload={{ id: proyectos}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    // style={{display : 'none'}}
                                    <Grid item xs={3}>
                                        <TextInput  id="lngMin" style={{display : 'none'}}  defaultValue={data.longitud1} source="longituds" fullWidth />
                                        <TextInput  id="latMin" style={{display : 'none'}}  defaultValue={data.latitud1}  source="latituds" fullWidth />
                                        <TextInput  id="lngMax" style={{display : 'none'}}  defaultValue={data.longitud2} source="longituds2" fullWidth />
                                        <TextInput  id="latMax" style={{display : 'none'}}  defaultValue={data.latitud2}  source="latituds2" fullWidth />   
                                        <TextInput  id="alturaIni" style={{display : 'none'}}  defaultValue={data.alturaIni}  source="altutaInis" fullWidth /> 
                                        <TextInput  id="alturaFin" style={{display : 'none'}}  defaultValue={data.alturaFin}  source="altutaFins" fullWidth />                          
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                {/* <Grid item xs={12}>
                    <BooleanInput source="estado" defaultValue={true} fullWidth/>
                </Grid> */}
                </Grid>
            }
            {tipoElemento === 'SGM' &&
                <Grid container spacing={24}>
                     <Grid item xs={6}>
                    <ReferenceInput label="Tramo" source="tramo"  reference="tramos" fullWidth validate={[required() , ValidarTramo]}>
                        <SelectInput optionText="fullCode" />
                    </ReferenceInput>                
                </Grid>     
                <TextInput label="Código" source="CODIGAAS"  id="codigo" style={{display : 'none'}} defaultValue={AbValidador}  fullWidth disabled />
                <TextInput label="Código" source="PrValidador"  id="codigo" style={{display : 'none'}} defaultValue={prValidador} fullWidth disabled />          
                <Grid item xs={6}>
                    <TextInput label="Código" source="codigoRR" id="codigo"  fullWidth validate={[required(), minLength(2), maxLength(4)]}/>
                </Grid>
                <FormDataConsumer>
                        {({ formData, ...rest }) => ( tramoss &&
                        <Grid item xs={6}>
                            <PRInput label="Abscisa inicial" id="ingresoAbInicio" source="abInicio" fullWidth onChange={NoSobreponerAbs} validate={[abValidationInicio,required(),MansajeValidadorAb]}/>
                        </Grid>
                            )
                        }
                    </FormDataConsumer>  
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>(tramoss &&
                        <Grid item xs={6}>
                            <PRInput label="Abscisa final" id="ingresoAbFin" source="abFin" fullWidth onChange={NoSobreponerAbs} validate={[abValidationFin,required(),validarLogicaAbsFinCreate, validarAbsEntreSegmento,MansajeValidadorAb]}/>
                        </Grid> 
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                    {({ formData, ...rest }) => (tramoss &&
                            <Grid item xs={6}>
                                 <PInput label="PR Inicio" source="pInicio"  id="ingresoPrInicio" fullWidth onChange={NoSobreponerPr} validate={[prValidationInicio,required(),ValorMaximoPrEditInicio,MansajeValidadorPb]}/>
                            </Grid>
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>(tramoss &&
                            <Grid item xs={6}>
                                 <PInput label="PR Fin" source="pFin"  id="ingresoPrFin" fullWidth onChange={NoSobreponerPr} validate={[prValidationFin,required(),ValorMaximoPrEditFin,validarLogicaPrFinEdit, validarPrEntreSegmento,MansajeValidadorPb]}/>
                            </Grid>
                        )
                    }
                </FormDataConsumer>
                <Grid item xs={12}>
                <b>Localización</b>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>                   
                <Grid item xs={6}>
                    <ReferenceInput label="Departamento" source="municipio" reference="municipios" filterToQuery={searchText => ({ nombre: searchText })} sort={{field:"nombre", order:"ASC"}} validate={[required(), ValidarDepartamento]}>
                        <AutocompleteInput  optionText="departamento_.nombre"  options={{fullWidth : true}} />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                    <ReferenceInput label="Municipio" source="municipio" reference="municipios" filterToQuery={searchText => ({ nombre: searchText })} sort={{field:"nombre", order:"ASC"}}  validate={[required()]} filter={Departamento}>
                        <AutocompleteInput  optionText="nombre" options={{fullWidth : true}} />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="b" component="b" >Coordenadas iniciales</Typography>
                    <Divider style={{marginTop : '10px'}}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="LongitudInicial"  label="Latitud" source="latitud"  fullWidth validate={[required(), LatMin, latMininima]} />                   
                </Grid>
                <Grid item xs={4}>
                 <NumberInput id="LatitudInicial"  label="Longitud" source="longitud"  fullWidth validate={[required(), LngMin, lngMininima]}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="altura"  label="Altura (m.s.n.m)" source="alturaIni" validate={[Altitud]} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="b" component="b">Coordenadas Finales</Typography>
                    <Divider style={{marginTop : '10px'}}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="longitud2"  label="Latitud" source="latitud2"  fullWidth validate={[required(), LatMax, latMininima]} />                    
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="longitud1"  label="Longitud" source="longitud2"  fullWidth validate={[required(), LngMax, lngMininima]}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberInput id="altura2"  label="Altura (m.s.n.m)" source="alturaFin"  validate={[Altitud]}  fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={8}>             
                    <ArrayInput label="" source="ubicaciones" className="estilos_array"  fullWidth className={classes.ocultar} 
                        defaultValue={[
                            {
                                lat:  latMin,
                                lng: lngMin
                            },
                            {
                                lat: latMax,
                                lng: lngMax
                            }
                        ]}
                    >
                        <SimpleFormIterator disableAdd disableRemove fullWidth  >                        
                            <NumberInput label="Latitud" source="lat" fullWidth style={{width : '95%'}} validate={[required()]}/>                            
                            <NumberInput label="Longitud" source="lng" fullWidth style={{width : '95%' , marginLeft : '20px'}} validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                    
                </Grid>
                <Grid item xs={12}>
                <b>Características</b>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Tipo Calzada" 
                        source="tipoCalzada" 
                        reference="tipo_calzadas" 
                        fullWidth 
                        validate={[required(),CalzadaCodigo]}
                        sort={{field : 'customOrder' , order : 'ASC'}} 
                        >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                </Grid>
                {                                    
                    <Query type="GET_ONE" resource="tipo_calzadas" payload={{ id: calzadaCodigo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput  style={{display:'none'}} label="Nombre calzada" defaultValue={data.nombre} source="calzadaCodigo" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>     
                    // style={{display:'none'}}                       
                } 
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Tipo Segmento" 
                        source="tipoSegmento" 
                        reference="tipo_segmentos" 
                        fullWidth validate={[required(),SegmentoCodigo]}
                        sort={{field : 'customOrder' , order : 'ASC'}} 
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                </Grid>
                {                                    
                    <Query type="GET_ONE" resource="tipo_segmentos" payload={{ id: segmentoCodigo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput style={{display:'none'}} defaultValue={data.nombre} source="segmentoCodigo" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="Tipo Superficie" 
                        source="tipoSuperficie" 
                        reference="tipo_elementos" 
                        filter={{sistema: 'CLZ'}} 
                        fullWidth 
                        validate={[required(), SuperficieCodigo]}
                        sort={{field : 'customOrder' , order : 'ASC'}} 
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                </Grid> 
                {                                    
                    <Query type="GET_ONE" resource="tipo_elementos" payload={{ id: superficieCodigo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput style={{display:'none'}}  defaultValue={data.nombre} source="superficieCodigo" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                {                                    
                    <Query type="GET_ONE" resource="rutas" payload={{ id: tramoss}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    <Grid item xs={3}>
                                        <TextInput  id="IdprInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.PInicio} source="pInicios" fullWidth />
                                        <TextInput id="IdprFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.PFin} source="pFins" fullWidth />
                                        <TextInput  id="IdAbInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.abInicios} source="pAbI" fullWidth />
                                        <TextInput id="IdAbFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.abFins} source="pAbF" fullWidth />
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                }
                {                        
                    <Query type="GET_ONE" resource="proyectos" payload={{ id: proyectos}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    // style={{display : 'none'}}
                                    <Grid item xs={3}>
                                        <TextInput  id="lngMin" style={{display : 'none'}}  defaultValue={data.longitud1} source="longituds" fullWidth />
                                        <TextInput  id="latMin" style={{display : 'none'}}  defaultValue={data.latitud1}  source="latituds" fullWidth />
                                        <TextInput  id="lngMax" style={{display : 'none'}}  defaultValue={data.longitud2} source="longituds2" fullWidth />
                                        <TextInput  id="latMax" style={{display : 'none'}}  defaultValue={data.latitud2}  source="latituds2" fullWidth />   
                                        <TextInput  id="alturaIni" style={{display : 'none'}}  defaultValue={data.alturaIni}  source="altutaInis" fullWidth /> 
                                        <TextInput  id="alturaFin" style={{display : 'none'}}  defaultValue={data.alturaFin}  source="altutaFins" fullWidth />                          
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                {/* <Grid item xs={12}>
                    <BooleanInput source="estado" defaultValue={true} fullWidth/>
                </Grid> */}
                </Grid>
            }
        </SimpleForm>
    </Edit>
    )
    }
    
);
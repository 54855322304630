import React, { useState } from 'react';
import {
    TextInput, DateInput, SelectInput, NumberInput, ReferenceInput, TabbedForm, AutocompleteInput,ImageInput,ImageField,
    Toolbar, FormTab, SaveButton, required, minValue, minLength, maxLength, BooleanInput, SimpleFormIterator,
    ArrayInput, maxValue
} from 'react-admin';
import { PRInput } from './../../../inputs/PRInput';
import Typography from '@material-ui/core/Typography';
import { PimsCreate } from './../../Create';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { latMininima, lngMininima, abValidationInicio, abValidationFin, validacionmaxfechaact , isFechaInvalida
} from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import { ButtonCarousel } from './../../images/ButtonCarouselPeru';


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

export const OtrosElementosEdit = withStyles(styles)(({ classes, ...props }) => {

    const [elemento, setElemento] = useState(' ');
    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');


    const asignarEstado = (value) => {
        setTipoEstado(value)
    }
    //ASIGNAR ELEMENTO
    const asignarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setElemento(value)
    }

    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum2Decimales = (value) => {
        if (value !== undefined || value !== null) {
            if (value !== 0) {
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if (!pattern.test(value)) { return "El valor ingresado solo puede tener 2 decimales"; }
                }
            }
        }
    }

    //DECIMAL
    const decimal = (value) => {
        if (value % 1 == 0) {
            return "El valor ingresado debe ser decimal";
        }
    }

    //ASIGNAR EL ESTADO
    const [tipoEstado, setTipoEstado] = useState('');

    //VALIDAR NUMERO ENTERO
    const valEntero = (value) => {
        if (value !== undefined || value !== null) {
            if (value % 1 !== 0) { return "El valor ingresado debe ser entero"; }
        }
    }

    const ValidarUnidad = (value) => {
        setUnidad(value)
    }
    const ValidarTramo = (value) => {
        setTramo(value)
    }
    const Unidades = { unidadFuncional: unidads };
    const Tramoss = { tramo: tramos };
    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        setSegmento(value)

    }

    /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const validarEnteroNT =(value) =>{
        if(value){
          if (!/^([0-9]+)*$/.test(value)){ return "Debe ser un número entero"; }    
        }
      }

      const validarLogicaAbsFinCreate=(value)=>{

        if(value !== undefined)
        {
            
            let prInicio = document.getElementById('ingresoAbInicio').value;
            prInicio = prInicio.split('K')
            if(prInicio[1] !== undefined && typeof(value)==="string")
            {
                prInicio = prInicio[1].split('+')
                value = value.split('K')
                value = value[1].split('+')
            
                if(parseFloat(value[0]) < parseFloat(prInicio[0]))
                {   
                    return 'Abscisa fin debe ser mayor a Abscisa inicio' 
                }
                if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) === parseFloat(prInicio[1]))
                {
                    return 'Abscisa fin debe ser mayor a Abscisa inicio' 
                }
                if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) < parseFloat(prInicio[1]))
                {
                    return 'Abscisa fin debe ser mayor a Abscisa inicio' 
                }
            }
            
        }
    }

/*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }
    return (
        <Edit title="Editar Otro Elemento" {...props} undoable={false}>
            <TabbedForm redirect="list" >
                {/* ------ ELEMENTOS OTROS ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                    <Grid container spacing={24}>
                        <CoordenadasProyecto/>
                        {segmentos &&
                            <AbsSegmento segmento={segmentos} />
                        }
                        <Grid item xs={4}>
                            <ReferenceInput disabled source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ puntos: 1, sistema: 'OTR' }} sort={{ field: 'nombre', order: 'ASC' }} validate={[required(), asignarElemento]}>
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput source="margen" label="Margen" choices={[
                                { id: 'Derecha', name: 'Derecha' },
                                { id: 'Izquierda', name: 'Izquierda' },
                                { id: 'Centro', name: 'Centro' },
                            ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="b" component="b">Localización</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider fullWidth />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput
                                        label="Unidad Funcional"
                                        source="unidadFuncional"
                                        reference="unidad_funcionals"
                                        filterToQuery={searchText => ({ codigo: searchText })}
                                        sort={{ field: 'codigo', order: 'asc' }}
                                        validate={[required(), ValidarUnidad]}
                                        disabled
                                        fullWidth
                                    >
                                        <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="codigo" />
                                    </ReferenceInput>
                                </Grid>

                                {unidads &&
                                    <Grid item xs={4}>
                                        <ReferenceInput
                                            label="Tramo"
                                            source="tramo"
                                            alwaysOn
                                            reference="tramos"
                                            filter={{ unidadFuncional: unidads }}
                                            filterToQuery={searchText => ({ codigo: searchText })}
                                            sort={{ field: 'codigo', order: 'ASC', }}
                                            validate={[required(), ValidarTramo]}
                                            formClassName={classes.grid_cont4}
                                            disabled
                                        >
                                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="codigo" />
                                        </ReferenceInput>
                                    </Grid>
                                }
                                {tramos &&
                                    <Grid item xs={4}>
                                        <ReferenceInput
                                            label="Segmento"
                                            source="segmento"
                                            reference="segmentos"
                                            sort={{ field: 'codigo', order: 'ASC' }}
                                            filter={{ tramo: tramos }}
                                            filterToQuery={searchText => ({ codigo: searchText })}
                                            validate={[required(), ValidarSegmento]}
                                            formClassName={classes.grid_cont4}
                                            disabled
                                        >

                                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="codigo" />
                                        </ReferenceInput>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas inicales</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont_div} />
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas finales</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont_div} />
                        </Grid>
                    }
                    {segmentos && <PRInput label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarLogicaAbsFinCreate, required()]} formClassName={classes.grid_cont4s} />}
                    {segmentos && <NumberInput label="Latitud" source="latitudFinal" fullWidth validate={[required(), LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont4} />}
                    {segmentos && <NumberInput label="Longitud" source="longitudFinal" fullWidth validate={[required(), lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont4} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="Características">
                    <SelectInput source="interseccion" label="Clase de intersección" choices={[
                        { id: 'Deprime intersecta', name: 'Deprime intersecta' },
                        { id: 'Deprime vía', name: 'Deprime vía' },
                        { id: 'Eleva intersecta', name: 'Eleva intersecta' },
                        { id: 'Eleva vía', name: 'Eleva vía' },
                    ]} formClassName={classes.first_field} validate={[required()]} fullWidth />
                    <SelectInput source="material" label="Material" choices={[
                        { id: 'Adoquín ', name: 'Adoquín' },
                        { id: 'Afirmado', name: 'Afirmado' },
                        { id: 'Concreto', name: 'Concreto' },
                        { id: 'Flexible', name: 'Flexible' },
                        { id: 'Otro', name: 'Otro' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="flujo_peatonal" label="Flujo peatonal" choices={[
                        { id: 'Alto', name: 'Alto' },
                        { id: 'Medio', name: 'Medio' },
                        { id: 'Bajo', name: 'Bajo' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="clase_via_intersecta" label="Clase de vía intersecta" choices={[
                        { id: 'Urbana', name: 'Urbana' },
                        { id: 'Rural', name: 'Rural' },
                    ]} formClassName={classes.first_field} validate={[required()]} fullWidth />
                    <SelectInput source="tipo_via_intersecta" label="Tipo de vía intersecta" choices={[
                        { id: 'Primaria', name: 'Primaria' },
                        { id: 'Secundaria', name: 'Secundaria' },
                        { id: 'Terciaria', name: 'Terciaria' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="reductores_velocidad" label="Reductores de velocidad vía intersecta" choices={[
                        { id: 'Si', name: 'Si' },
                        { id: 'No', name: 'No' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    {/* DES NIVEL */}
                    {elemento === "/tipo_elementos/IDES" &&
                        <div className={classes.subtittle}><strong>Características del paso a desnivel </strong></div>
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <Divider fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <NumberInput label="Largo (m)" source="largo" validate={[required(), minValue(0), valNum2Decimales]} formClassName={classes.first_field} fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <NumberInput label="No. Carriles" source="carriles" validate={[required(), minValue(0), valEntero]} formClassName={classes.last_field} fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <SelectInput label="Sentido" source="sentido" choices={[
                            { id: 'Ascendente', name: 'Ascendente' },
                            { id: 'Descendente', name: 'Descendente' },
                            { id: 'Doble', name: 'Doble' },
                        ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    }

                </FormTab>
                {/* ------ DETALLES ---------- */}
                <FormTab label="Detalles" >
                    <Divider fullWidth />
                    <SelectInput source="estado" label="Condición del elemento" choices={[
                        { id: 'Nuevo', name: 'Nuevo' },
                        { id: 'Existente', name: 'Existente' },
                        { id: 'Reemplazo', name: 'Reemplazo' },
                    ]} formClassName={classes.grid_cont4_f} 
                        validate={[required(), asignarEstado]} fullWidth 
                    />
                    {tipoEstado === "Reemplazo" &&
                        <SelectInput source="motivo" label="Motivo del reemplazo" choices={[
                            { id: 'Instalación', name: 'Instalación' },
                            { id: 'Reemplazo programado', name: 'Reemplazo programado' },
                            { id: 'Accidente', name: 'Accidente' },
                            { id: 'Vandalismo', name: 'Vandalismo' },
                            { id: 'Otro', name: 'Otro' },
                        ]} formClassName={classes.grid_cont4_s} 
                        validate={[required()]} fullWidth />
                    }
                    {tipoEstado === "Reemplazo" && 
                        <ReferenceInput
                            label="Elemento que Reemplazo"
                            source="elementoReemplazo_id"
                            reference="otros_elementos"
                            filter={{
                                tipoElemento : elemento,
                                status : 1,
                                // unidadFuncional: unidads,
                                // tramo: tramos,
                                // segmento : segmentos
                            }}
                            fullWidth
                            formClassName={classes.grid_cont4}
                        >
                            <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigo" />
                        </ReferenceInput>
                    }
                      <br/>
                     <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    <div></div>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <DateInput label="Fecha de instalación" source="fecha_instalacion" fullWidth formClassName={classes.grid_cont4_f} validate={validacionmaxfechaact, isFechaInvalida}/>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="Proveedor"
                                source="proveedor"
                                reference="proveedors"
                                filter={{ proyecto: props.proyecto, suministros: 1, sistema: 'OTR' }}
                                sort={{ field: 'razonSocial', order: 'ASC' }}
                                filterToQuery={searchText => ({ razonSocial: searchText })}
                                validate={[required()]}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]} formClassName={classes.grid_cont4_s} />
                        </Grid>
                    </Grid><Grid item xs={6}>
                        <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth />
                    </Grid>
                    <div></div>
                    <TextInput source="observaciones" label="Observaciones" validate={[required(), minLength(0), maxLength(200)]} fullWidth formClassName={classes.first_field2} />                    
                </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                    <Grid container spacing={24}>                         
                        <Grid item xs={8}>  
                            <ArrayInput label="" source="images" validate={ImagenesEdit} >
                                <SimpleFormIterator disableAdd disableRemove >
                                    <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                        <ImageField source="src" title="title" />
                                    </ImageInput>                           
                                    <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                        <SelectInput  optionText="nombre"  />
                                    </ReferenceInput>                          
                                </SimpleFormIterator>
                            </ArrayInput>                            
                        </Grid>     
                        <Grid item xs={4}>  
                            <ButtonCarousel ImagenesOpen={imagenesOpen}   />
                        </Grid>                                                    
                    </Grid>                   
                </FormTab>  
            </TabbedForm>
        </Edit>
    )
});
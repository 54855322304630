import React from 'react';
import { TabbedShowLayout,Tab, TextField, DateField,  NumberField,SelectField} from 'react-admin';
import { Show } from './../../Show';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


export const ReparacionPersShow = props => (
    <Show  {...props}  title='Reparación PERS' bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>            
             <Tab  label="Rparación PERS">   
                <SelectField label="Tipo de reparación" source='tipoReparacion' choices={[
                    {id:'PFLEX', name:'Flexible'},
                    { id:'PRIGD', name:'Rígido'}
                ]}/>
                <div></div><div></div>
                <TextField label="Nombre" source="nombre" />
                <TextField label="Unidad" source="unidad" />        
                <NumberField label="Costo" source="costo" /> 
                <TextField  source="observaciones" /> 
            </Tab> 
            <Tab  label="Auditoría">   
                <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <div></div>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <div></div>    
            </Tab> 
        </TabbedShowLayout>
    </Show>
);
import React from 'react';
import { NumberField, BooleanField, required, minLength, maxLength, minValue, maxValue, TabbedShowLayout, 
    Tab, TextField, DateField, ReferenceField, TabbedForm, FormTab
} from 'react-admin';
import { Show } from './../../Show';
import { FField } from './../../../fields/FField';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';


export const PeajescolajeShow = withStyles(styles)(({ classes, record, ...props }) => {

    return (
    <Show title="Cola de Peaje" {...props}>
        <TabbedForm>
            <FormTab label="Información básica">
                 <DateField label="Fecha medición" source="date" fullWidth formClassName={classes.grid_cont3s}/>
                <NumberField source="consecutivo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                <br/>
                <ReferenceField label="Peaje" source="inventario_peajes" reference="inventariopeajes" fullWidth formClassName={classes.grid_cont3s}>
                    <TextField source="nombre" />
                </ReferenceField>
                <TextField label="Sentido" source="sentido" fullWidth formClassName={classes.grid_cont3} />
                <br/>
                <FField label="Hora Inicio" source="hora_inicio" id="ingresohoraI" fullWidth  formClassName={classes.grid_cont3s}/>
                <FField label="Hora Final" source="hora_final" id="ingresofin" fullWidth  formClassName={classes.grid_cont3}/>
                <br/>
                <NumberField label="Estación Activa" source="estacion_activa" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3s}/>
                <NumberField label="Número Carril" source="numero_carril" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                <br/>
                <BooleanField label="Indicador" source="indicador" fullWidth formClassName={classes.grid_cont3s}/>
            </FormTab>
            <FormTab label="Auditoria">
                <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont3s}/>
                <TextField source="createdBy" label="Creado por"formClassName={classes.grid_cont3}/>
                <br/>
                <DateField source="updatedAt" label="Fecha actualización" formClassName={classes.grid_cont3s}/>
                <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont3}/>
            </FormTab>
        </TabbedForm>
    </Show>
    )
});
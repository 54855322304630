import React from 'react';
import { Admin, Resource } from 'react-admin';
/**
 * Hydra 
 */
import { fetchHydra as baseFetchHydra } from '@api-platform/admin';
import hydraClient from './hydra/hydraClient';
// IMAGEN
import addUploadFeature from './addUploadFeature';
/**
 * Temporales
 */ 
import {  EditGuesser, ShowGuesser } from 'react-admin';
/**
 * Seguridad
 */ 
import PimsAuthProvider from './PimsAuthProvider';
/**
 * Dashboard
 */
import PimsDashboard from './PimsDashboard';
/**
 * Reducers
 */
import proyecto from './reducers/proyectoReducer';
/** 
 * Layout
 */
import PimsLayout from './PimsLayout';

/**
 * Recursos
 * 
 */
import {EstacionInventarioList} from './resources/estacion_conteo/inventario/list';
import {EstacionInventarioCreate} from './resources/estacion_conteo/inventario/create';
import {EstacionInventarioShow} from './resources/estacion_conteo/inventario/show';
import {EstacionInventarioEdit} from './resources/estacion_conteo/inventario/edit';

import {CalificacionList} from './resources/calificacion/list';
import {CalificacionCreate} from './resources/calificacion/create';
import {CalificacionShow} from './resources/calificacion/show';
import {CalificacionEdit} from './resources/calificacion/edit';

import {CalificacionPontonList} from './resources/calificacion_ponton/list';
import {CalificacionPontonCreate} from './resources/calificacion_ponton/create';
import {CalificacionPontonShow} from './resources/calificacion_ponton/show';
import {CalificacionPontonEdit} from './resources/calificacion_ponton/edit';

import {TasasList} from './resources/estacion_conteo/tasas/list';
import {TasasCreate} from './resources/estacion_conteo/tasas/create';
import {TasasShow} from './resources/estacion_conteo/tasas/show';
import {TasasEdit} from './resources/estacion_conteo/tasas/edit';

import {PuenteList} from './resources/puentes/list';
import {PuenteCreate} from './resources/puentes/create';
import {PuenteShow} from './resources/puentes/show';
import {PuenteEdit} from './resources/puentes/edit';

import {TunelList} from './resources/tuneles/list';
import {TunelCreate} from './resources/tuneles/create';
import {TunelShow} from './resources/tuneles/show';
import {TunelEdit} from './resources/tuneles/edit';

import {SeguridadList} from './resources/seguridad/list';
import {SeguridadCreate} from './resources/seguridad/create';
import {SeguridadShow} from './resources/seguridad/show';
import {SeguridadEdit} from './resources/seguridad/edit';

import {EjesList} from './resources/estacion_conteo/ejes/list';
import {EjesCreate} from './resources/estacion_conteo/ejes/create';
import {EjesShow} from './resources/estacion_conteo/ejes/show';
import {EjesEdit} from './resources/estacion_conteo/ejes/edit';
  
import {PeajesinventarioList} from './resources/peajes/inventario/list';
import {PeajesinventarioCreate} from './resources/peajes/inventario/create';
import {PeajesinventarioShow} from './resources/peajes/inventario/show';
import {PeajesinventarioEdit} from './resources/peajes/inventario/edit';

import {PeajescolajeList} from './resources/peajes/colaje/list';
import {PeajescolajeCreate} from './resources/peajes/colaje/create';
import {PeajescolajeShow} from './resources/peajes/colaje/show';
import {PeajescolajeEdit} from './resources/peajes/colaje/edit';

import {Peajesmensualist} from './resources/peajes/mensual/list';
import {PeajesmensualCreate} from './resources/peajes/mensual/create';
import {PeajesmensualShow} from './resources/peajes/mensual/show';
import {PeajesmensualEdit} from './resources/peajes/mensual/edit';

import {TransitoList} from './resources/transito/list';
import {TransitoShow} from './resources/transito/show';
import {TransitoEdit} from './resources/transito/edit';
import {TransitoCreate} from './resources/transito/create';

import {AtributosList} from './resources/atributo/list';
import {AtributosShow} from './resources/atributo/show';
import {AtributosCreate} from './resources/atributo/create';
import {AtributosEdit} from './resources/atributo/edit';

import {UsuarioList} from './resources/usuario/list';
import {UsuarioShow} from './resources/usuario/show';
import {UsuarioCreate} from './resources/usuario/create';
import {UsuarioEdit} from './resources/usuario/edit';


import {ProyectoList} from './resources/proyectos/list';
import {ProyectoShow} from './resources/proyectos/show';
import {ProyectoCreate} from './resources/proyectos/create';
import {ProyectoEdit} from './resources/proyectos/edit';

import {Contacto_emergenciaList} from './resources/contactos_emergencia/list';
import {Contacto_emergenciaShow} from './resources/contactos_emergencia/show';
import {Contacto_emergenciaCreate} from './resources/contactos_emergencia/create';
import {Contacto_emergenciaEdit} from './resources/contactos_emergencia/edit';

import {Servicio_publicoList} from './resources/servicios_publicos/list';
import {Servicio_publicoShow} from './resources/servicios_publicos/show';
import {Servicio_publicoCreate} from './resources/servicios_publicos/create';
import {Servicio_publicoEdit} from './resources/servicios_publicos/edit';

import {RutaList} from './resources/rutas/list';
import {RutaShow} from './resources/rutas/show';
import {RutaCreate} from './resources/rutas/create';
import {RutaEdit} from './resources/rutas/edit';

import {UnidadFuncionalList} from './resources/unidades_funcionales/list';
import {UnidadFuncionalCreate} from './resources/unidades_funcionales/create';
import {UnidadFuncionalEdit} from './resources/unidades_funcionales/edit';
import {UnidadFuncionalShow} from './resources/unidades_funcionales/show';

import {TramoList} from './resources/tramos/list';
import {TramoCreate} from './resources/tramos/create';
import {TramoEdit} from './resources/tramos/edit';
import {TramoShow} from './resources/tramos/show';

import {SegmentoList} from './resources/segmentos/list';
import {SegmentoCreate} from './resources/segmentos/create';
import {SegmentoEdit} from './resources/segmentos/edit';
import {SegmentoShow} from './resources/segmentos/show';

import {ProveedorList} from './resources/proveedores/list';
import {ProveedorCreate} from './resources/proveedores/create';
import {ProveedorEdit} from './resources/proveedores/edit';
import {ProveedorShow} from './resources/proveedores/show';


import {ElementoAlrededorList} from './resources/elementos/alrededor/list';
import {ElementoAlrededorCreate} from './resources/elementos/alrededor/create';
import {ElementoAlrededorEdit} from './resources/elementos/alrededor/edit';
import {ElementoAlrededorShow} from './resources/elementos/alrededor/show';

import {ElementoCalzadaList} from './resources/elementos/calzada/list';
import {ElementoCalzadaEdit} from './resources/elementos/calzada/edit';

import {ElementoDrenajeList} from './resources/elementos/drenaje/list';
import {ElementoDrenajeCreate} from './resources/elementos/drenaje/create';
import {ElementoDrenajeEdit} from './resources/elementos/drenaje/edit';
import {ElementoDrenajeShow} from './resources/elementos/drenaje/show';

import {ElementoContencionCreate} from './resources/elementos/contencion/create';
import {ElementoContencionList} from './resources/elementos/contencion/list';
import {ElementoContencionEdit} from './resources/elementos/contencion/edit';
import {ElementoContencionShow} from './resources/elementos/contencion/show';

import {ElementoInstalacionList} from './resources/elementos/instalacion/list';
import {ElementoInstalacionCreate} from './resources/elementos/instalacion/create';

import {ElementoOtroList} from './resources/elementos/otro/list';
import {ElementoOtroCreate} from './resources/elementos/otro/create';

import {ElementoPuenteList} from './resources/elementos/puente/list';
import {ElementoPuenteCreate} from './resources/elementos/puente/create';

import {ElementoRedList} from './resources/elementos/red/list';
import {ElementoRedCreate} from './resources/elementos/red/create';
import {ElementoRedEdit} from './resources/elementos/red/edit';
import {ElementoRedShow} from './resources/elementos/red/show';

import {ElementoSeguridadList} from './resources/elementos/seguridad/list';
import {ElementoSeguridadCreate} from './resources/elementos/seguridad/create';

import {ElementoSenalizacionList} from './resources/elementos/senalizacion/list';
import {ElementoSenalizacionCreate} from './resources/elementos/senalizacion/create';
import {ElementoSenalizacionEdit} from './resources/elementos/senalizacion/edit';
import {ElementoSenalizacionShow} from './resources/elementos/senalizacion/show';

import {ElementoTraficoList} from './resources/elementos/trafico/list';
import {ElementoTraficoCreate} from './resources/elementos/trafico/create';

import {ElementoPontonList} from './resources/elementos/ponton/list';
import {ElementoPontonCreate} from './resources/elementos/ponton/create';
import {ElementoPontonEdit} from './resources/elementos/ponton/edit';
import {ElementoPontonShow} from './resources/elementos/ponton/show';

import {ExportPERSList} from './resources/exportacion_pers/list';
import {ExportacionPersCreate} from './resources/exportacion_pers/create';

import {DisponibilidadList } from './resources/disponibilidad_via/list';
import {DisponibilidadCreate } from './resources/disponibilidad_via/create';
import {DisponibilidadEdit } from './resources/disponibilidad_via/edit';
import {DisponibilidadShow } from './resources/disponibilidad_via/show';

/**
 * Plan_mantenimiento DEPRECATED
 */
// import { PlanMantenimientoList } from './resources/mantenimientos/list';
// import { PlanMantenimientoCreate } from './resources/mantenimientos/create';
// import { PlanMantenimientoEdit } from './resources/mantenimientos/edit';
// import { PlanMantenimientoShow } from './resources/mantenimientos/show';
/**
 * Plan_mantenimiento_detalles
 */
// import { PlanMantenimientoDetallesList } from './resources/mantenimientos/planes/list';
// import { PlanMantenimientoDetallesCreate } from './resources/mantenimientos/planes/create';
// import { PlanMantenimientoDetallesEdit } from './resources/mantenimientos/planes/edit';
// import { PlanMantenimientoDetallesShow } from './resources/mantenimientos/planes/show';

/**
 * Politicas de mantenimiento
 */
import { PoliticasMantenimientoList } from './resources/mantenimientos/politicas/list';
import { PoliticasMantenimientoCreate } from './resources/mantenimientos/politicas/create';
import { PoliticasMantenimientoEdit } from './resources/mantenimientos/politicas/edit';
import { PoliticasMantenimientoShow } from './resources/mantenimientos/politicas/show';

/**
 * Politicas de mantenimiento Particulares 
 */
import { PoliticasMantenimientoParticularList } from './resources/mantenimientos/politicas_especiales/list';
import { PoliticasMantenimientoParticularCreate } from './resources/mantenimientos/politicas_especiales/create';
import { PoliticasMantenimientoParticularEdit } from './resources/mantenimientos/politicas_especiales/edit';
import { PoliticasMantenimientoParticularShow } from './resources/mantenimientos/politicas_especiales/show';

/**
 *  FlujoCaja
 */
import { FlujoCajaList } from './resources/mantenimientos/flujo_caja/list';
import { FlujoCajaCreate } from './resources/mantenimientos/flujo_caja/create';
import { FlujoCajaEdit } from './resources/mantenimientos/flujo_caja/edit';
import { FlujoCajaShow } from './resources/mantenimientos/flujo_caja/show';


/**
 * Sagas
 */
import errorSaga from './sagas/errorSaga';
/**
 * Idioma
 */
import spanishMessages from 'ra-language-spanish';

/**
 * INVENTARIO
 */

/* FLEXIBLE */
import {ElementoFlexibleList} from './resources/inventarios/segmentos/flexible/list';
import {ElementoFlexibleCreate} from './resources/inventarios/segmentos/flexible/create';
import {ElementoFlexibleShow} from './resources/inventarios/segmentos/flexible/show';
import {ElementoFlexibleEdit} from './resources/inventarios/segmentos/flexible/edit';

/* RIGIDO */
import {ElementoRigidoList} from './resources/inventarios/segmentos/rigido/list';
import {ElementoRigidoCreate} from './resources/inventarios/segmentos/rigido/create';
import {ElementoRigidoShow} from './resources/inventarios/segmentos/rigido/show';
import {ElementoRigidoEdit} from './resources/inventarios/segmentos/rigido/edit';

/* ADOQUIN */
import {ElementoAdoquinList} from './resources/inventarios/segmentos/adoquin/list';
import {ElementoAdoquinCreate} from './resources/inventarios/segmentos/adoquin/create';
import {ElementoAdoquinShow} from './resources/inventarios/segmentos/adoquin/show';
import {ElementoAdoquinEdit} from './resources/inventarios/segmentos/adoquin/edit';

/* OTRO */
import {ElementoOtroSegList} from './resources/inventarios/segmentos/otro/list';
import {ElementoOtroSegCreate} from './resources/inventarios/segmentos/otro/create';
import {ElementoOtroSegShow} from './resources/inventarios/segmentos/otro/show';
import {ElementoOtroSegEdit} from './resources/inventarios/segmentos/otro/edit';

/* DETERIOROS */
import { ElementoDeterioroList } from './resources/indicadores/deterioros/list';
import { ElementoDeterioroCreate } from './resources/indicadores/deterioros/create';
import { ElementoDeterioroEdit } from './resources/indicadores/deterioros/edit';
import { ElementoDeterioroShow } from './resources/indicadores/deterioros/show';

/* OTROS ELEMENTOS */
import { OtrosElementosList } from './resources/inventarios/otros_elementos/list';
import { OtrosElementosCreate } from './resources/inventarios/otros_elementos/create';
import { OtrosElementosEdit } from './resources/inventarios/otros_elementos/edit';
import { OtrosElementosShow } from './resources/inventarios/otros_elementos/show';

/* MATERIALES */
import { MaterialesList } from './resources/inventarios/materiales/especificacion/list';
import { MaterialesCreate } from './resources/inventarios/materiales/especificacion/create';
import { MaterialesEdit } from './resources/inventarios/materiales/especificacion/edit';
import { MaterialesShow } from './resources/inventarios/materiales/especificacion/show';

/* GRANULOMETRIA */
import { GranulometriaList } from './resources/inventarios/materiales/granulometria/list';
import { GranulometriaCreate } from './resources/inventarios/materiales/granulometria/create';
import { GranulometriaEdit } from './resources/inventarios/materiales/granulometria/edit';
import { GranulometriaShow } from './resources/inventarios/materiales/granulometria/show';

/**
 * DATOS BASICOS
 */

 /* MEDIDO POR */
 import { MedidoPorList } from './resources/indicadores/datos_basicos/medido_por/list';
 import { MedidoPorCreate } from './resources/indicadores/datos_basicos/medido_por/create';
 import { MedidoPorEdit } from './resources/indicadores/datos_basicos/medido_por/edit';

 /* ESTADO */
 import { EstadoList } from './resources/indicadores/datos_basicos/estado/list';
 import { EstadoCreate } from './resources/indicadores/datos_basicos/estado/create';
 import { EstadoEdit } from './resources/indicadores/datos_basicos/estado/edit';

 /* TIPO DE DOMINIO */
 import { TipoDominioList } from './resources/indicadores/datos_basicos/tipo_dominio/list';
 import { TipoDominioEdit } from './resources/indicadores/datos_basicos/tipo_dominio/edit';
 import { TipoDominioCreate } from './resources/indicadores/datos_basicos/tipo_dominio/create';

 /* FISURA */
 import { FisuraList } from './resources/indicadores/datos_basicos/fisura/list';
 import { FisuraCreate } from './resources/indicadores/datos_basicos/fisura/create';
 import { FisuraEdit } from './resources/indicadores/datos_basicos/fisura/edit'; 

 /* CALIFICACIÓN */
 import { CalificacionIndicadorList } from './resources/indicadores/datos_basicos/calificacion/list';
 import { CalificacionIndicadorCreate } from './resources/indicadores/datos_basicos/calificacion/create';
 import { CalificacionIndicadorEdit } from './resources/indicadores/datos_basicos/calificacion/edit';

 /* EVALUACIÓN */
 import { EvaluacionList } from './resources/indicadores/datos_basicos/evaluacion/list';
 import { EvaluacionCreate } from './resources/indicadores/datos_basicos/evaluacion/create';
 import { EvaluacionEdit } from './resources/indicadores/datos_basicos/evaluacion/edit';

 /* NIVELES */
 import { NivelesList } from './resources/indicadores/datos_basicos/niveles/list';
 import { NivelesCreate } from './resources/indicadores/datos_basicos/niveles/create';
 import { NivelesEdit } from './resources/indicadores/datos_basicos/niveles/edit'; 

/* CALIFICACIÓN DE PROVEEDORES */
import { ProveedorCalificacionList } from './resources/proveedor_calificacion/list';
import { ProveedorCalificacionCreate } from './resources/proveedor_calificacion/create';

/* EVALUACIONES */
import { EvaluacionSVList } from './resources/indicadores/evaluacion/seguridad_vial/list';
import { EvaluacionSVCreate } from './resources/indicadores/evaluacion/seguridad_vial/create';
import { EvaluacionSVShow } from './resources/indicadores/evaluacion/seguridad_vial/show';
import { EvaluacionSVEdit } from './resources/indicadores/evaluacion/seguridad_vial/edit';

/* SEÑALES DE PERU */
import {ElementoSenalizacionPeruList} from './resources/elementos/senalizacion_peru/list';
import {ElementoSenalizacionPeruCreate} from './resources/elementos/senalizacion_peru/create';
import {ElementoSenalizacionPeruEdit} from './resources/elementos/senalizacion_peru/edit';
import {ElementoSenalizacionPeruShow} from './resources/elementos/senalizacion_peru/show';

// /* MAPA PERU */
// import {MapaGenralPeru} from './resources/Mapa/MapaList';

/* MAPA GENERAL */
import {MapaGenral} from './resources/Mapa/MapaList';
 
/* PREGUNTAS */
import { PreguntasList } from './resources/indicadores/datos_basicos/preguntas/list';
import { PreguntasEdit } from './resources/indicadores/datos_basicos/preguntas/edit';

/* COLORES */
import { ColorCreate } from './resources/indicadores/datos_basicos/colores/create';
import { ColorList } from './resources/indicadores/datos_basicos/colores/list';
import { ColorEdit } from './resources/indicadores/datos_basicos/colores/edit';
import { ColorShow } from './resources/indicadores/datos_basicos/colores/show';

/* PARAMETROS DE EVALUACIÓN*/
import {ParametrosList} from './resources/parametros_evaluacion/list';
import {ParametrosCreate} from './resources/parametros_evaluacion/create';
import {ParametrosEdit} from './resources/parametros_evaluacion/edit';
import {ParametrosShow} from './resources/parametros_evaluacion/show';

/*Carga de Información Indicadores Colombia*/
import {CargarInformacionList} from './resources/indicadores_4G/cargar_informacion/list'
import {CargarInformacionCreate} from './resources/indicadores_4G/cargar_informacion/create'
import {CargarInformacionEdit} from './resources/indicadores_4G/cargar_informacion/edit'
import {CargarInformacionShow} from './resources/indicadores_4G/cargar_informacion/show'

    
/*DETERIOR  O PERS*/
import {ReparacionPersCreate} from './resources/deterioro_pers/reparacion_pers/create'
import {ReparacionPersEdit} from './resources/deterioro_pers/reparacion_pers/edit'
import {ReparacionPersList} from './resources/deterioro_pers/reparacion_pers/list'
import {ReparacionPersShow} from './resources/deterioro_pers/reparacion_pers/show'

import {DeterioroPersCreate} from './resources/deterioro_pers/deterioro_pers/create'
import {DeterioroPersEdit} from './resources/deterioro_pers/deterioro_pers/edit'
import {DeterioroPersList} from './resources/deterioro_pers/deterioro_pers/list'
import {DeterioroPersShow} from './resources/deterioro_pers/deterioro_pers/show'

import {ModeloCostosPersCreate} from './resources/deterioro_pers/modelo_costos/create'
import {ModeloCostosPersEdit} from './resources/deterioro_pers/modelo_costos/edit'
import {ModeloCostosPersList} from './resources/deterioro_pers/modelo_costos/list'
import {ModeloCostosPersShow} from './resources/deterioro_pers/modelo_costos/show' 



/*Trayectorias*/
import {TrayectoriasList} from './resources/trayectorias/list';
import {TrayectoriasCreate} from './resources/trayectorias/create';
import {TrayectoriasEdit} from './resources/trayectorias/edit';
import {TrayectoriasShow} from './resources/trayectorias/show';
/*PARAMENTROS DE ANALISIS*/
import {ParametrosAnalisisList} from './resources/parametros_analisis/list';
import {ParametrosAnalisisCreate} from './resources/parametros_analisis/create';
import {ParametrosAnalisisEdit} from './resources/parametros_analisis/edit';
import {ParametrosAnalisisShow} from './resources/parametros_analisis/show';
/* LOGIN PAGE*/
import LoginPage from './LoginPage';
import config from './config/config';
let urlAppjs = config.UrlServer;


const messages = {'es': spanishMessages,};
const i18nProvider = locale => messages[locale];
const entrypoint = urlAppjs;//process.env.apiURL
// const headers = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {...options,headers: new Headers({'Authorization': `Bearer ${window.localStorage.getItem('token')}` }),});

const dataProvider = hydraClient(entrypoint,fetchHydra);

//IMAGEN
const uploadCapableDataProvider = addUploadFeature(dataProvider);
const authProvider = new PimsAuthProvider({url: entrypoint});


document.addEventListener("mousewheel", function(event){
  if(document.activeElement.type === "number"){
      document.activeElement.blur();
  }
});

document.addEventListener("keydown", function(event2){  
  if(document.activeElement.type === "number"){
    if (event2.keyCode === 38 || event2.keyCode === 40) {
      event2.preventDefault();
    }
  }
})




const App = () => 
  <Admin 

    // dataProvider={dataProvider} 
    
    dataProvider={uploadCapableDataProvider} 
    authProvider={authProvider.handleEvent}
    // authProvider={PimsAuthProvider}
    appLayout={PimsLayout}
    dashboard={PimsDashboard}
    i18nProvider={i18nProvider} locale="es"
    customReducers={{proyecto}}
    customSagas={[ errorSaga ]}
    loginPage = { LoginPage } 
    > 
      <Resource name="estacion_conteos" list={EstacionInventarioList} show={EstacionInventarioShow} edit={EstacionInventarioEdit} create={EstacionInventarioCreate}/>
      <Resource name="calificacions" list={CalificacionList} show={CalificacionShow} edit={CalificacionEdit} create={CalificacionCreate}/>
      <Resource name="calificacion_pontons" list={CalificacionPontonList} show={CalificacionPontonShow} edit={CalificacionPontonEdit} create={CalificacionPontonCreate}/>
      <Resource name="puentes" list={PuenteList} show={PuenteShow} edit={PuenteEdit} create={PuenteCreate}/>
      <Resource name="tuneles" list={TunelList} show={TunelShow} edit={TunelEdit} create={TunelCreate}/>
      <Resource name="seguridads" list={SeguridadList} show={SeguridadShow} edit={SeguridadEdit} create={SeguridadCreate}/>
      <Resource name="tasas" list={TasasList} show={TasasShow} edit={TasasEdit} create={TasasCreate}/>
      <Resource name="ejes" list={EjesList} show={EjesShow} edit={EjesEdit} create={EjesCreate}/>
      <Resource name="colajes" list={PeajescolajeList} show={PeajescolajeShow} edit={PeajescolajeEdit} create={PeajescolajeCreate}/>
      <Resource name="inventariopeajes" list={PeajesinventarioList} show={PeajesinventarioShow} edit={PeajesinventarioEdit} create={PeajesinventarioCreate}/>
      <Resource name="mensuals" list={Peajesmensualist} show={PeajesmensualShow} edit={PeajesmensualEdit} create={PeajesmensualCreate}/>
      <Resource name="transitos"  list={TransitoList} show={TransitoShow} edit={TransitoEdit} create={TransitoCreate}/>
      <Resource name="atributos"             list={ AtributosList} edit={AtributosEdit} show={AtributosShow} create={AtributosCreate}/>
      <Resource name="contacto_emergencias"         list={Contacto_emergenciaList} edit={Contacto_emergenciaEdit} show={Contacto_emergenciaShow} create={Contacto_emergenciaCreate}/>      
      <Resource name="servicios_publicos"         list={Servicio_publicoList} edit={Servicio_publicoEdit} show={Servicio_publicoShow} create={Servicio_publicoCreate}/>
      <Resource name="proyectos"         list={ProyectoList} edit={ProyectoEdit} show={ProyectoShow} create={ProyectoCreate}/>
      <Resource name="rutas"             list={RutaList} edit={RutaEdit} show={RutaShow} create={RutaCreate}/>            
      <Resource name="unidad_funcionals" list={UnidadFuncionalList} edit={UnidadFuncionalEdit} show={UnidadFuncionalShow} create={UnidadFuncionalCreate}/>      
      <Resource name="tramos"            list={TramoList} edit={TramoEdit} show={TramoShow} create={TramoCreate}/>
      <Resource name="segmentos"         list={SegmentoList} edit={SegmentoEdit} show={SegmentoShow} create={SegmentoCreate}/>      
      <Resource name="usuarios"          list={UsuarioList} edit={UsuarioEdit}   show={UsuarioShow} create={UsuarioCreate}/>
      <Resource name="proveedors"        list={ProveedorList} edit={ProveedorEdit} show={ProveedorShow} create={ProveedorCreate}/>
      <Resource name="elemento_calzadas"      list={ElementoCalzadaList} edit={ElementoCalzadaEdit} show={ShowGuesser}/>
      <Resource name="elemento_seguridads"    list={ElementoSeguridadList} edit={EditGuesser} show={ShowGuesser} create={ElementoSeguridadCreate}/>
      <Resource name="elemento_senalizacions" list={ElementoSenalizacionList} edit={ElementoSenalizacionEdit} show={ElementoSenalizacionShow} create={ElementoSenalizacionCreate}/>
      <Resource name="elemento_alrededors"    list={ElementoAlrededorList} edit={ElementoAlrededorEdit} show={ElementoAlrededorShow} create={ElementoAlrededorCreate}/>
      <Resource name="elemento_puentes"       list={ElementoPuenteList} edit={EditGuesser} show={ShowGuesser} create={ElementoPuenteCreate}/>
      <Resource name="elemento_otros"         list={ElementoOtroList} edit={EditGuesser} show={ShowGuesser} create={ElementoOtroCreate}/>
      <Resource name="elemento_instalacions"  list={ElementoInstalacionList} edit={EditGuesser} show={ShowGuesser} create={ElementoInstalacionCreate}/>
      <Resource name="elemento_reds"          list={ElementoRedList} edit={ElementoRedEdit} show={ElementoRedShow} create={ElementoRedCreate}/>
      <Resource name="elemento_traficos"      list={ElementoTraficoList} edit={EditGuesser} show={ShowGuesser} create={ElementoTraficoCreate}/>
      <Resource name="elemento_drenajes"      list={ElementoDrenajeList} edit={ElementoDrenajeEdit} show={ElementoDrenajeShow} create={ElementoDrenajeCreate}/>
      <Resource name="elemento_contencions"   list={ElementoContencionList}  edit={ElementoContencionEdit} show={ElementoContencionShow} create={ElementoContencionCreate}/> 
      <Resource name="elemento_pontons"       list={ElementoPontonList}  edit={ElementoPontonEdit} show={ElementoPontonShow} create={ElementoPontonCreate}/>
      <Resource name="tipo_calzadas"/>
      <Resource name="tipo_segmentos"/>      
      <Resource name="sistemas"/>
      <Resource name="tipo_elementos"/>
      <Resource name="departamentos"/>
      <Resource name="indicativos"/>
      <Resource name="municipios"/>
      <Resource name="material_estructuras"/>
      <Resource name="tipo_deterioros"/>
      <Resource name="variables"/>
      <Resource name="listado_variables"/>

      {/** Politicas Mantenimiento */}
      <Resource name="politicas_mantenimientos"  list={PoliticasMantenimientoList} create={PoliticasMantenimientoCreate} show={PoliticasMantenimientoShow} edit={PoliticasMantenimientoEdit}/>
      {/** Politicas Mantenimiento Particular */}
      <Resource name="politicas_mantenimiento_particulars"  list={PoliticasMantenimientoParticularList} create={PoliticasMantenimientoParticularCreate} show={PoliticasMantenimientoParticularShow} edit={PoliticasMantenimientoParticularEdit}/>
     
      {/** Flujo Caja */}
      <Resource name="flujo_cajas"  list={FlujoCajaList} create={FlujoCajaCreate} show={FlujoCajaShow} edit={FlujoCajaEdit}/>
     
      <Resource name="indicadors" />
      <Resource name="valor_indicadors" />
      <Resource name="tipo_senals" />
      <Resource name="tipo_elemento_senals" />
      <Resource name="senals" />
      <Resource name="fotografias" />
      <Resource name="licencias" />
      <Resource name="flexible_detalles"  list={ElementoFlexibleList} create={ElementoFlexibleCreate} show={ElementoFlexibleShow} edit={ElementoFlexibleEdit}/>
      <Resource name="rigido_detalles"  list={ElementoRigidoList} create={ElementoRigidoCreate} show={ElementoRigidoShow} edit={ElementoRigidoEdit}/>
      <Resource name="adoquin_detalles"  list={ElementoAdoquinList} create={ElementoAdoquinCreate} show={ElementoAdoquinShow} edit={ElementoAdoquinEdit}/>
      <Resource name="otro_detalles"  list={ElementoOtroSegList} create={ElementoOtroSegCreate} show={ElementoOtroSegShow} edit={ElementoOtroSegEdit}/>
      <Resource name="deterioro_detalles"  list={ElementoDeterioroList} create={ElementoDeterioroCreate} edit={ElementoDeterioroEdit} show={ElementoDeterioroShow} />      
      <Resource name="pers_exports"  list={ExportPERSList} />
      <Resource name="otros_elementos"  list={OtrosElementosList} create={OtrosElementosCreate} edit={OtrosElementosEdit} show={OtrosElementosShow} />            
      <Resource name="materials" list={MaterialesList} create={MaterialesCreate} edit={MaterialesEdit} show={MaterialesShow} /> 
      <Resource name="granulometrias"  list={GranulometriaList} create={GranulometriaCreate} edit={GranulometriaEdit} show={GranulometriaShow} /> 
      <Resource name="medido_pors"  list={MedidoPorList} create={MedidoPorCreate} edit={MedidoPorEdit} /> 
      <Resource name="estado_indicadors"  list={EstadoList} create={EstadoCreate} edit={EstadoEdit} /> 
      <Resource name="tipo_dominios" list={TipoDominioList} create={TipoDominioCreate} edit={TipoDominioEdit} />       
      <Resource name="fisura_indicadors" list={FisuraList} create={FisuraCreate} edit={FisuraEdit}  />       
      <Resource name="calificacion_indicadors" list={CalificacionIndicadorList} create={CalificacionIndicadorCreate} edit={CalificacionIndicadorEdit} />       
      <Resource name="evaluacion_indicadors" list={EvaluacionList} create={EvaluacionCreate} edit={EvaluacionEdit}  />             
      <Resource name="niveles_indicadors"  list={NivelesList} create={NivelesCreate} edit={NivelesEdit} />    
      <Resource name="obicacion_senals" />   
      <Resource name="calificacion_proveedors" create={ProveedorCalificacionCreate}/>  
      <Resource name="evaluacion_seguridads" list={EvaluacionSVList} create={EvaluacionSVCreate} edit={EvaluacionSVEdit} show={EvaluacionSVShow} />       
      <Resource name="preguntas" list={PreguntasList} edit={PreguntasEdit}/>
      <Resource name="colores" list={ColorList} create={ColorCreate} edit={ColorEdit} show={ColorShow}/>
      <Resource name="disponibilidad_vias" list={DisponibilidadList} create={DisponibilidadCreate} edit={DisponibilidadEdit} show={DisponibilidadShow}/>

      {/* PARAMETROS DE EVALUACIÓN*/}
      <Resource name="parametros_grupos" list={ParametrosList} create={ParametrosCreate} edit={ParametrosEdit} show={ParametrosShow}/>
      <Resource name="parametros"/>
      {/*CARGA DE INFORMACIÓN INDICADORES COLOMBIA */}
      <Resource name="indicadores_cargar_informacions" list={CargarInformacionList} create={CargarInformacionCreate} show={CargarInformacionShow} edit={CargarInformacionEdit}/>
      {/* ---------------- SEÑALES DE PERÚ  --------------------- */}
      <Resource name="dimension_senal_perus" />
      <Resource name="elemento_senalizacion_perus"  list={ElementoSenalizacionPeruList} edit={ElementoSenalizacionPeruEdit} show={ElementoSenalizacionPeruShow} create={ElementoSenalizacionPeruCreate} />
      <Resource name="tipo_elemento_senal_perus" />
      <Resource name="tipo_senal_perus" />
      <Resource name="senal_vertical_perus" />
      <Resource name="elementos" list={MapaGenral} />
      <Resource name="ubicacion_segmentos" />
      <Resource name="tipo_images"/>
      
      {/*-------------------------Elementos de Mantenimiento (deprecated)---------------------- */}
      <Resource name="tipo_plans" />
      <Resource name="entidad_elementos" />
      {/*-------------------------Politicas de Mantenimiento---------------------- */}
      <Resource name="unidad_medidas" />

      <Resource name="politicas_elementos" />
      <Resource name="politicas_elemento_cambios" />
      <Resource name="politicas_elemento_periodicos" />
      <Resource name="politicas_elemento_rehabilitacions" />
      <Resource name="politicas_nombres" />

      {/*-------------------------Politicas de Mantenimiento particular---------------------- */}      
      <Resource name="politicas_elemento_particulars" />
      <Resource name="politicas_elemento_cambio_particulars" />
      <Resource name="politicas_elemento_periodico_particulars" />
      <Resource name="politicas_elemento_rehabilitacion_particulars" />
      <Resource name="politicas_particulares_items" />
      <Resource name="politicas_nombres" />
      {/*----Elementos de puentes ..::..::.. Superestructura----------------------- */}
      <Resource name="superestructuras"/>
      <Resource name="superficies"/>
      <Resource name="transversarls"/>
      <Resource name="longituds"/>
      <Resource name="transversarlas"/>
      <Resource name="longas"/>
      <Resource name="barandas"/>
      <Resource name="expantions"/>
       {/*----Elementos de puentes ..::..::.. Subestructura----------------------- */}
       <Resource name="subestructuras"/>
       <Resource name="tipoestribos"/>
       <Resource name="estris"/>
       <Resource name="tippilas"/>

      {/*------------------- Valicadiconesde codigos --------------------------*/}
        <Resource name="codigoRuta"/>
      {/*------------------- HISTÓRICO   --------------------------*/}
        <Resource name="mensualviews"/>        
        <Resource name="validar_rango_pr_abs"/>

      {/* -----------------------DETERIORO PERS-------------------------------- */}
        <Resource name="reparacion_localizadas" create={ReparacionPersCreate}  list={ReparacionPersList}  edit={ReparacionPersEdit}  show={ReparacionPersShow}/>
        <Resource name="tipo_deterioro_pers"    create={DeterioroPersCreate}  list={DeterioroPersList}  edit={DeterioroPersEdit}  show={DeterioroPersShow}/>
        <Resource name="modelo_costos_pers"    list={ModeloCostosPersList}  create={ModeloCostosPersCreate} edit={ModeloCostosPersEdit} show={ModeloCostosPersShow}/>

        {/*------------------- TRAYECTORIAS   --------------------------*/}
        <Resource name="trayectorias"           list={TrayectoriasList} edit={TrayectoriasEdit} show={TrayectoriasShow} create={TrayectoriasCreate} />
         {/*------------------- PARAMETROS DE ANALISIS  --------------------------*/}
         <Resource name="parametros_analises"   list={ParametrosAnalisisList} edit={ParametrosAnalisisEdit} show={ParametrosAnalisisShow} create={ParametrosAnalisisCreate} />
         <Resource name="image_drenajes"/>
         <Resource name="image_bases"/>
  </Admin>;
  
export default App;



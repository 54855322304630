import React from 'react';
import {Filter,List, Datagrid, TextField,ReferenceField, EditButton, ShowButton,SelectInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const FiltroSeguridad= (props) => (
    <Filter {...props}>
         <SelectInput label="Estado" source="estados" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]}  fullWidth alwaysOn
            />
    </Filter>
);
          const data = new FormData();
        //   data.append('unidadSave', unidadSave);
        //   data.append('yearSave', yearSave);
  
//           fetch(urlAppjs +'/generarPERS/', {
//               method: 'POST',
//               body: data
//           })
//           .then(res=> res.json())
//           .then(datos => {
//               if (datos == "Sin datos") {
//                   alert("No se encontraron datos.");
//               } else {
//                   const URL = urlAppjs +"/Pers/"+datos
//                   window.location.href = URL;
//                   setTimeout(function(){ window.location.reload(); }, 3000);
//               }
//           });
        
// const UrlField = ({ record, source }) => 
//         <a href={urlAppjs+"/"+record[source]} target="_blank" style={{textDecoration:"none"}}>
//             <Button variant="outlined" color="primary">Mostrar</Button>
//         </a>;

export const PuenteList = props => (
    <List title="Lista Puentes"{...props}  sort={{ field: 'id', order: 'DESC' }} filters={<FiltroSeguridad/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
        <TextField label="Código Puentes" source="fullCodigo"  sortable/>
            {/* <ReferenceField label="Unidad Funcional" source="unidad_funcional_.fullCode" reference="unidad_funcionals" linkType={false} > */}
               <TextField label="Unidad funcional" source="unidad_funcional_.fullCode" fullWidth sortable/>
            
                        <TextField source="tipo" allowEmpty emptyValue="" fullWidth sortable/>

                     <TextField source="margen"   fullWidth choices={[
                    ]}/>
                    <TextField label="Estado" source="detalles[0].estados" linkType={false} fullWidth sortable />
                    {/* <UrlField label="MOSTRAR" source="puentes"/> */}
                       <ShowButton />
            <EditButton />  
               
        </Datagrid>
    </List>
);
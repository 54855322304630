import React, { useState, useEffect } from 'react';
import {
    TextField, DateField, NumberField, ReferenceField, ListButton, EditButton,
    SelectInput, BooleanField, ShowController, ShowView, TabbedForm, FormTab, CardActions
} from 'react-admin';
import { withStyles} from '@material-ui/core';
import TablaReparacion from './TablaReparacion';
import { GMapField } from '../../../fields/GMapField';
import { PRField } from '../../../fields/PRField';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
import { ButtonCarousel } from './../../images/ButtonCarousel';
let urlAppjs = config.UrlServer;


export const ElementoPontonShow = withStyles(styles)(({ classes, record, ...props }) => {

    const cardActionStyle = {
        zIndex: 2,
        display: 'inline-block',
        float: 'right',
    };
    const [IdElemento_pontons, setIdElemento_pontons] = useState(0);
    const [imagen , setImagen] =useState(true);
     const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }

    useEffect(() => {
        var url = document.location.hash;
        var auxId = url.split("elemento_pontons%2F");
        var id = auxId[1].split("/");
        setIdElemento_pontons(id[0])
    });
    const PostShowActions = ({ basePath, data }) => (
        <CardActions style={cardActionStyle}>
            <EditButton basePath={basePath} record={data} />
            {/* Add your custom actions */}
            <ListButton basePath={basePath} />
        </CardActions>
    );
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps}
                    title="Información Elemento Pontones"
                    actions={<ButtonCarousel />}
                >
                    <TabbedForm >
                        <FormTab label="IDENTIFICACIÓN">
                            <TextField label="Código" source="fullCodigo" sortable={false} />
                            <span></span>
                            
       
                            <TextField label="Margen" source="margen"  formClassName={classes.grid_cont4_f}/>
                            <TextField label="Tipo" source="tipoPonton" formClassName={classes.grid_cont4_s} />
                            <NumberField label="Número de carriles" source="numeroCarriles" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <h3 style={{ marginBottom: 0 }}>Localización</h3>
                            <hr style={{ width: "100%" }} />
                            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth f formClassName={classes.grid_cont4_f}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth  formClassName={classes.grid_cont4_s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth  formClassName={classes.grid_cont4_s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            
                            <PRField label="Abscisa Inicio" source="abInicio" fullWidth  formClassName={classes.grid_cont4_f} />
                            <PRField label="Abscisa Fin" source="abFin" fullWidth  formClassName={classes.grid_cont4_s} />
                            <NumberField label="Longitud(km)" source="fullResta" fullWidth fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4_s} />
                            <h3 style={{ marginBottom: 0 }}>Coordenadas Iniciales</h3>
                            <hr style={{ width: "100%" }} />
                            <NumberField label="Latitud" source="latitudInicial" fullWidth fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4_f} />
                            <NumberField label="Longitud" source="longitudInicial" fullWidth fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4_s} />
                            <span></span>

                            <h3 style={{ marginBottom: 0 }}>Coordenadas Finales</h3>
                            <hr style={{ width: "100%" }} />
                            <NumberField label="Latitud" source="latitudFinal" fullWidth fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            <NumberField label="Longitud" source="longitudFinal" fullWidth fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            <span></span>

                            <GMapField
                                defaultZoom={12}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                style={{ width: "100%" }}
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={'imagenes/senalesColombia/Elementos/ponton/PTON.png' }
                            />
                        </FormTab >
                        <FormTab label="Datos Administrativos">
                            <h3 style={{ marginBottom: 0 }}>Detalles Constructivos</h3>
                            <hr style={{ width: "100%" }} />
                            <DateField label="Año" source="ano" formClassName={classes.grid_cont3_f} />
                            <NumberField label="Área (m2)" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
                            <TextField label="Norma de diseño" source="norma" formClassName={classes.grid_cont3_s} />
                            <TextField label="Diseñador" source="disenador" formClassName={classes.grid_cont3_s} />
                            <br/>
                            <TextField label="Informe de Diseño" source="info_di" />

                            <h3 style={{ marginBottom: 0 }}>Detalles de Refuerzo o Rehabilitación</h3>
                            <hr style={{ width: "100%" }} />

                            <DateField label="Año" source="anoa" formClassName={classes.grid_cont3_f} />
                            <NumberField label="Área (m2)" source="areaa" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s}/>
                            <TextField label="Norma de diseño" source="normaa" formClassName={classes.grid_cont3_s}/>
                            <TextField label="Diseñador" source="disenadora" formClassName={classes.grid_cont3_s}/>
                            <br/>
                            <TextField label="Informe de Diseño" source="info_dia" />
                            <br/>
                            <TextField label="Observaciones" source="obser_dia" />

                            <h3 style={{ marginBottom: 0 }}>Detalles de Inspección</h3>
                            <hr style={{ width: "100%" }} />

                            <DateField label="Fecha Recolección Datos" source="anob" formClassName={classes.grid_cont4_f}/>
                            <TextField label="Dirección de abscisado" source="sentido" formClassName={classes.grid_cont4_s}/>
                            <TextField label="Estación de Conteo" source="estacion_conteo_.nombre" formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <TextField label="Inspector" source="inspector" formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Requisitos de Inspección" source="inspeccion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="Número de Secciones de Inspección" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} source="numinspeccion" formClassName={classes.grid_cont4_s}/>

                            <h3 style={{ marginBottom: 0 }}>Miembros Interesados</h3>
                            <hr style={{ width: "100%" }} />

                            <TextField label="Propietario" source="propietario" formClassName={classes.grid_cont4_f}/>
                            <TextField label="Administración Vial" source="admon_vial" formClassName={classes.grid_cont4_s}/>
                            <TextField label="Proyectista" source="proyectista" formClassName={classes.grid_cont4_s}/>

                            <h3 style={{ marginBottom: 0 }}>Características operacionales</h3>
                            <hr style={{ width: "100%" }} />

                            <NumberField label="Coeficiente de aceleración sísmica (Aa)" source="coeficiente" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            <TextField label="Paso por el cauce" source="cauce" formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <TextField label="Existe variante" source="variante" formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Longitud variante (km)" source="long_variante" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <TextField label="Estado" source="esta" formClassName={classes.grid_cont4_s}/>

                            <h3 style={{ marginBottom: 0 }}>Capacidad de carga para tránsito legal</h3>
                            <hr style={{ width: "100%" }} />
                            
                            <TextField label="Vehículo de diseño" source="vehi_dise" formClassName={classes.grid_cont4_f}/>
                            <TextField label="Clase distribución de carga" source="distri_carga" formClassName={classes.grid_cont4_s}/>
                            <NumberField  label="Capacidad de carga (t)" source="capa_carga" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <NumberField label="Longitud de luz crítica(m)" source="luz_critica" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Factor de clasificación" source="fac_clasi" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            
                            <h3 style={{ marginBottom: 0 }}>Capacidad de carga para transportes especiales</h3>
                            <hr style={{ width: "100%" }} />

                            <NumberField label="Fuerza cortante (t)" source="fue_cor" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Momento (t.m)" source="momento" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="Línea de carga por rueda(t)" source="linea_carga" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <TextField label="Observaciones" source="obs_1" />

                        </FormTab >
                        <FormTab label="Geometría">
                            <TextField label="Pontón en terraplén" source="pon_terra" formClassName={classes.grid_cont4_f}/>
                            <TextField label="Pontón en Curvatura/Tangente" source="pon_c" formClassName={classes.grid_cont4_s}/>
                            <div></div>
                            <h3 style={{ marginBottom: 0 }}>Detalles de estructura</h3>
                            <hr style={{ width: "100%" }} />
                            <NumberField label="Longitud (m)" source="long_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Ancho losa superior (m)" source="ancho_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="Espesor losa superior (m)" source="espe_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <NumberField label="Altura inferior (m)" source="altu_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Ancho losa inferior (m)" source="ancho_3"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="Espesor losa inferior (m)" source="espe_3"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <NumberField label="Profundidad del relleno (m)" source="pro_3"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="Espesor de muros (m)" source="espe_4" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="Separación entre muros (m)" source="sepa_4"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>

                        </FormTab >
                        <FormTab label="Reparaciones">
                            <TablaReparacion tabla="elemento_pontons" id={IdElemento_pontons} />
                        </FormTab>
                        <FormTab label="Detalles">
                            <TextField label="Condición del elemento" source="estado" formClassName={classes.grid_cont4_f}/>
                            <TextField label="Motivo reemplazo" source="motivoReemplazo" formClassName={classes.grid_cont4_s}/>
                            <TextField label="Elemento que reemplaza" source="fullCodigoElemReem" fullWidth formClassName={classes.grid_cont4_s} />
                            <TextField label="Estado" source="estados" fullWidth formClassName={classes.grid_cont4_f}/>
                            <br/>
                            <DateField label="Fecha de instalación" source="fecha" formClassName={classes.grid_cont4_f}/>
                            <ReferenceField
                                label="Proveedor"
                                source="proveedor"
                                reference="proveedors"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont4_s}
                            >
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="Garantía (meses)" source="garantia"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <BooleanField label="¿Incluir este elemento a un plan mantenimiento?" source="mantenimiento" />
                            <br/>
                            <TextField source="observaciones" label="Observaciones" />

                        </FormTab>
                        <FormTab label="Auditoría">
                            <DateField source="createdAt" label="Fecha creación" />
                            <TextField source="createdBy" label="Creado por" />
                            <DateField source="updatedAt" label="Fecha actualización" />
                            <TextField source="updatedBy" label="Actualizado por" />

                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
});

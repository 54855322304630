import React from 'react';
import { List,NumberField,Datagrid, TextField, DateField, BooleanField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, SelectInput, BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import { FField } from './../../fields/FField';

const DisponibilidadFilter = (props) =>(
    <Filter {...props}>
        <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" alwaysOn sort={{field : 'codigo' , order : 'ASC'}}>
            <SelectInput optionText="codigo" /> 
        </ReferenceInput>
        <SelectInput label="Tipo" sort={{ field: 'id', order: 'DESC' }}  allowEmpty source="tipoEvento" emptyValue="" choices={[
                        { id: "Incidente", name: "Incidente"},
                        { id: "Accidente", name: "Accidente"},
                        { id: "Cierre", name: "Cierre"}
         ]}  alwaysOn sort={{field : 'id' , order : 'ASC'}}/>
        <SelectInput label="Causa" sort={{ field: 'id', order: 'DESC' }}  allowEmpty source="causa" emptyValue="" choices={[
                        { id: "Accidente", name: "Accidente"},
                        { id: "Derrumbre", name: "Derrumbre"},
                        { id: "Trabajos en vía", name: "Trabajos en vía"},
                        { id: "Manifestación", name: "Manifestación"},
                        { id: "Orden Público", name: "Orden Público"},
                        { id: "Otro", name: "Otro"}
         ]}  alwaysOn/>

        <SelectInput label="Año" source="fecha"  choices={[
                        { id: '2000', name: '2000' },
                        { id: '2001', name: '2001' },
                        { id: '2002', name: '2002' },
                        { id: '2003', name: '2003' },
                        { id: '2004', name: '2004' },
                        { id: '2005', name: '2005' },
                        { id: '2006', name: '2006' },
                        { id: '2007', name: '2007' },
                        { id: '2008', name: '2008' },
                        { id: '2009', name: '2009' },
                        { id: '2010', name: '2010' },
                        { id: '2011', name: '2011' },
                        { id: '2012', name: '2012' },
                        { id: '2013', name: '2013' },
                        { id: '2014', name: '2014' },
                        { id: '2015', name: '2015' },
                        { id: '2016', name: '2016' },
                        { id: '2017', name: '2017' },
                        { id: '2018', name: '2018' },
                        { id: '2019', name: '2019' },
                        { id: '2020', name: '2020' },
                        { id: '2021', name: '2021' },
                        { id: '2022', name: '2022' },
                        { id: '2023', name: '2023' },
                        { id: '2024', name: '2024' },
                        { id: '2025', name: '2025' },
                        { id: '2026', name: '2026' },
                        { id: '2027', name: '2027' },
                        { id: '2028', name: '2028' },
                        { id: '2029', name: '2029' },
                        { id: '2030', name: '2030' }
        ]} alwaysOn/>
                 
        <SelectInput label="Mes" source="fechaSenal"  choices={[
                        { id: '-01-', name: 'Enero' },
                        { id: '-02-', name: 'Febrero' },
                        { id: '-03-', name: 'Marzo' },
                        { id: '-04-', name: 'Abril' },
                        { id: '-05-', name: 'Mayo' },
                        { id: '-06-', name: 'Junio' },
                        { id: '-07-', name: 'Julio' },
                        { id: '-08-', name: 'Agosto' },
                        { id: '-09-', name: 'Septiembre' },
                        { id: '-10-', name: 'Octubre' },
                        { id: '-11-', name: 'Noviembre' },
                        { id: '-12-', name: 'Diciembre' }                                    
        ]} alwaysOn/>

    </Filter>
    );
    const PostBulkActionButtons = props => (
        <Fragment>
            <BulkDeleteWithConfirmButton {...props}/>
        </Fragment>
    );    
    //
export const DisponibilidadList = props =>(
    <List {...props} perPage={25} sort={{ field: 'updatedAt', order: 'DESC' }}  filters={<DisponibilidadFilter/>} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid>                        
        <TextField label="Consecutivo" source="consecutivo" />     
        <TextField label="Unidad Funcional" source="unidadcodigo" />     
        <TextField label="Tipo" source="tipoEvento" />     
        <TextField label="Causa" source="causa" />  
        <TextField label="Año" source="yearact" />  
        <TextField label="Mes" source="mesact" />  
        <FField label="Hora Reporte" source="horareport"/>
        <FField label="Hora Finalización" source="horafinalizacion"/>
        <FField label="Tiempo de Respuesta" source="tiempSenal"/>
        <ShowButton />
        <EditButton /> 
    </Datagrid>
</List>
);

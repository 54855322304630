import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField, ReferenceField, NumberField
} from 'react-admin';
import { Show } from './../Show';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const UsuarioShow = props => (
    <Show title="Información Usuario"  {...props}   bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="Información básica">
                <TextField label="Nombres" source="nombres"/>
                <TextField label="Apellidos" source="apellidos"/>
                <div></div>
                <TextField label="Tipo Documento" source="tipo_documento"/>
                <NumberField  label="Identificación" source="identificacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
                <div></div>                
                <ReferenceField label="Rol" source="admin" reference="atributos" linkType={false} >
                    <TextField source="adminNombre" />
                </ReferenceField>
                <div></div>
                <div></div>
                <TextField label="Correo electrónico" style={{width : '300%'}} source="username"/>
                <div></div><div></div><div></div>
                <Typography className=" Probando "variant="h3" component="h3" style={{color : '#4d4d91', textAlign:'center', marginBottom : '30px'}}>
                    ACCESOS DEL USUARIO EN LA APLICACIÓN
                </Typography>
                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{textAlign:'center', marginBottom : '30px'}}>
                    ADMINISTRACIÓN
                </Typography>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                <ReferenceField label="Tipo de rol" source="admin" reference="atributos" linkType={false} >
                    <TextField source="tipoRol" />
                </ReferenceField>
                <ReferenceField label="Usuarios" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiusuario" />
                </ReferenceField>
                <ReferenceField label="Perfiles" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiperfiles" />
                </ReferenceField>
                <ReferenceField label="Asignar Clave" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiasigclaves" />
                </ReferenceField>
                <div></div>
                <div></div>


                <div></div>
                <Typography  variant="h3" component="h3" style={{textAlign:'center', marginBottom : '30px'}}>
                    PROYECTOS
                </Typography>
                <div></div>
                
                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Proyectos
                </Typography>
                <div></div>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                <ReferenceField label="Proyectos" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiproyecto" />
                </ReferenceField>
                <ReferenceField label="Rutas" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indirutas" />
                </ReferenceField>
                <ReferenceField label="Unidades funcionales" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiunifuncionales" />
                </ReferenceField>
                <ReferenceField label="Tramos" source="admin" reference="atributos" linkType={false} >
                    <TextField source="inditramos" />
                </ReferenceField>
                <ReferenceField label="Segmentos" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indisegmento" />
                </ReferenceField>
                <div></div>
                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Contactos 
                </Typography>
                <div></div>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                <ReferenceField label="Contactos de Emergencia"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indicontemergencia" />
                </ReferenceField>
                <ReferenceField label="Proveedores" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiproveedores" />
                </ReferenceField>
                <ReferenceField label="Servicios Públicos" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiserpublicos" />
                </ReferenceField>

                

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Configuraciones - Materiales
                </Typography>
                <div></div><div></div>
                <Divider style={{width : '300%'}}/>
                <div></div><div></div>
                <ReferenceField label="Materiales"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indimateriales" />
                </ReferenceField>              
                <ReferenceField label="Granulometría"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiganulometria" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Configuraciones - Indicadores
                </Typography>
                <div></div><div></div>

                <ReferenceField label="Parámetros de Evaluación" source="admin" reference="atributos" linkType={false} >
                    <TextField source="parametrosEvaluacionText" />
                </ReferenceField>
                <ReferenceField label="Diagrama Colorimétrico" source="admin" reference="atributos" linkType={false} >
                    <TextField source="diagramaColorimetricoText" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Configuraciones - Deterioro PERS
                </Typography>
                <div></div><div></div>
                <ReferenceField label="Reparaciones" source="admin" reference="atributos" linkType={false} >
                    <TextField source="reparacionesText" />
                </ReferenceField>
                <ReferenceField label="Deterioros" source="admin" reference="atributos" linkType={false} >
                    <TextField source="deteriorosText" />
                </ReferenceField>
                <div></div>


                <div></div>
                <Typography  variant="h3" component="h3" style={{textAlign:'center', marginTop:'20px', marginBottom : '30px'}}>
                    ELEMENTOS INVENTARIO
                </Typography>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Segmentos (Calzada)
                </Typography>
                <div></div>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                <ReferenceField label="Flexible"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiflexible" />
                </ReferenceField>
                <ReferenceField label="Rígido" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indirigido" />
                </ReferenceField>
                <ReferenceField label="Adoquín" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiadoquin" />
                </ReferenceField>
                <ReferenceField label="Otro" source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiotro" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Inventarios 
                </Typography>
                <div></div>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                
                <ReferenceField label="Sistemas de Contención"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiseguridad" />
                </ReferenceField>
                <ReferenceField label="Drenaje"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indidrenaje" />
                </ReferenceField>
                <ReferenceField label="Señalización"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indisenalizacion" />
                </ReferenceField>
                <ReferenceField label="Alrededores"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indialrededores" />
                </ReferenceField>
                <ReferenceField label="Otros Elementos"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiotroelemento" />
                </ReferenceField>
                <ReferenceField label="Redes"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiredes" />
                </ReferenceField>
                <br /><br /><br />

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Puentes y estructuras especiales
                </Typography>
                <div></div><div></div>
                <Divider style={{width : '300%'}}/>
                <div></div><div></div>

                <ReferenceField label="Puentes"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indipuentes" />
                </ReferenceField>
                <ReferenceField label="Contención"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indicontencion" />
                </ReferenceField>
                
                <ReferenceField label="Pontón"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiponton" />
                </ReferenceField>
                <ReferenceField label="Túneles"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="inditunel" />
                </ReferenceField>
                <br /><br /><br />


                <Typography  variant="h3" component="h3" style={{textAlign:'center', marginTop:'20px', marginBottom : '30px'}}>
                    TRÁNSITO
                </Typography>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Peajes
                </Typography>
                <div></div>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                <ReferenceField label="Inventario"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiinventario" />
                </ReferenceField>
                <ReferenceField label="Tránsito Histório"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="inditransitomensual" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Estación de Conteo
                </Typography>
                <div></div>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                <ReferenceField label="Inventario"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indiinventarioa" />
                </ReferenceField>
                <div></div>
                <div></div>

                <div></div>
                <Typography  variant="h3" component="h3" style={{textAlign:'center', marginTop:'20px', marginBottom : '30px'}}>
                    INDICADORES
                </Typography>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Indicadores 
                </Typography>
                <div></div>
                <div></div>
                <Divider/>
                <Divider/>
                <Divider/>
                <ReferenceField label="Datos Básicos"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indidatosbasicos" />
                </ReferenceField>
                <ReferenceField label="Deterioro"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indicoladepeaje" />
                </ReferenceField>
                <ReferenceField label="Calificación Puentes"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indicalificacionpuentes" />
                </ReferenceField>
                <ReferenceField label="Calificación Pontones"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indicalificacionpontones" />
                </ReferenceField>
                <ReferenceField label="Disponibilidad de Vías"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indDisponibilidadVia" />
                </ReferenceField>
                <ReferenceField label="Cola de Peaje"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="indicoladepeaje" />
                </ReferenceField>


                <div></div>
                <Typography  variant="h3" component="h3" style={{textAlign:'center', marginTop:'20px', marginBottom : '30px'}}>
                    GESTIÓN
                </Typography>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Programación
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="Parámetros de Planeación"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="parametrosAnalisisText" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Pavimento PERS
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="Alternativa de Intervención"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="alternativaIntervencionText" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{width : '300%'}}>
                    Otros Sistemas
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="Políticas Generales"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="politicaGeneralText" />
                </ReferenceField>
                <ReferenceField label="Políticas Particulares"  source="admin" reference="atributos" linkType={false} >
                    <TextField source="politicaParticularText" />
                </ReferenceField>
                <div></div>









            </Tab>
            <Tab label="Auditoría">
                <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <div></div>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <div></div>   
            </Tab>
        </TabbedShowLayout>
    </Show>
);
import React, { useState } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, FormDataConsumer , ArrayInput, ReferenceArrayInput, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PimsElementoActions } from './../../../helpers/PimsElementoActions';
import { CurrencyInput } from './../../../inputs/CurrencyInput'
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import config from '../../../config/config';

import EditElements from './../EditElements';
let urlAppjs = config.UrlServer;

export const PoliticasMantenimientoEdit = withStyles(styles)(({ classes, ...props }) => {

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    // --------------acordeon-------------------------------------------------
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    // ---------------------------------------------------------------------

    const [sistema, setSistema] = useState();
    const [elemento, setElemento] = useState();
    const [payload, setPayload] = useState({});
    const regSistema = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        if (valor.split("/")[2]) {
            valor = valor.split("/")[2]
        }
        setSistema(valor);
        setPayload({
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'nombre', order: 'ASC' },
            filter: { sistema: "/sistemas/" + valor }
        })
        setElemento("tipo_elementos");
    }

    
    const [fetched, setFetch] = useState(false);
    

    const [validations, setValidations] = useState();

    return (
        <Edit  {...props} title="Editar política de Mantenimiento" redirect="list">
            <TabbedForm 
                redirect="list" 
                //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE MANTENIMIENTO ------ */}
                <FormTab label="IDENTIFICACIÓN" redirect="list" >
                    <TextInput
                        label="tipo"
                        source="tipo"
                        validate={[required()]}
                        defaultValue="normal"
                        formClassName={classes.ocultar}
                        disabled
                    />
                    <Grid container spacing={24}>
                        <Grid item xs={8}>
                        <ReferenceInput
                                label="Nombre de la Política"
                                source="nombre"
                                reference="politicas_nombres"
                                disabled
                                fullWidth
                            >
                                <SelectInput optionText="nombre" fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="Sistema"
                                source="sistema"
                                reference="sistemas"
                                onChange={regSistema}
                                validate={[required()]}
                                fullWidth
                                disabled
                            >
                                <SelectInput optionText="nombre" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <div className={classes.fieldCol12}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    // EditElements2("politicasElementos", formData.politicasElementos, formData.id)
                                    <EditElements politica={formData.id} sistema={formData.sistema}/>
                                }
                            </FormDataConsumer>
                            </div>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    )

});

import React from 'react';
import currencyFormatter from './../format/CurrencyFormat';

import { TextInput } from 'react-admin';


export const CurrencyInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: currencyFormatter,
        }}
    />
);


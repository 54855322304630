import React, { useState, useEffect } from 'react';
import {
  TabbedShowLayout, Tab, TextField, DateField, NumberField, BooleanField, ReferenceField, FormTab, TabbedForm,
  ShowController
} from 'react-admin';
import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
import { PRField } from './../../fields/PRField';
import { DField } from './../../fields/DField';
import Divider from '@material-ui/core/Divider';
import TablaTuneles from './TablaTuneles';
import { GMapField } from '../../fields/GMapField';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../EstilosGrid';
import { ButtonCarousel } from './../images/ButtonCarousel';

export const TunelShow = withStyles(styles)(({ classes, record, ...props }) => {

  const [IdTuneles, setIdTuneles] = useState(0);

  useEffect(() => {
    var url = document.location.hash;
    var auxId = url.split("tuneles%2F");
    var id = auxId[1].split("/");
    setIdTuneles(id[0])
  });

  return (
    <ShowController {...props} title="Información Elemento Red">
      {controllerProps =>
        <Show  {...props} title="Información Elemento Túneles" actions={<ButtonCarousel/>}>
          <TabbedForm toolbar={false} >
            <FormTab label="Identificación">
              <TextField source="elementos" label="Elementos" formClassName={classes.grid_cont4_f} />
              <TextField label="Sentido" source="sentido" formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="Clasificación" source="clasificacion" formClassName={classes.grid_cont4_f} />
              <TextField label="Según cobertura máxima" source="cobertura" formClassName={classes.grid_cont4_s} />
              <NumberField label="Carriles" source="carriles" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <strong >Localización</strong>
              <Divider fullWidth />

              <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4_f}>
                <TextField source="fullCodigos" />
              </ReferenceField>
              <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>
                <TextField source="fullCodigos" />
              </ReferenceField>
              <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>
                <TextField source="fullCodigos" />
              </ReferenceField>
              <br />
              <PRField label="Abscisa" source="abInicio" fullWidth formClassName={classes.grid_cont4_f} />
              <PRField label="Abscisa" source="abFin" fullWidth formClassName={classes.grid_cont4_s} />
              <NumberField label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />

              <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
              <Divider fullWidth />

              <NumberField label="Latitud" source="latitud_ini" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="Longitud" source="longitud_ini" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <Typography variant="b" component="b">Coordenadas Finales</Typography>
              <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />


              <NumberField label="Latitud" source="latitud_fin" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="Longitud" source="longitud_fin" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <GMapField
                fullWidth
                defaultZoom={12}
                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                source="ubicacionMapa"
                multipleMarkers="true"
                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                icon={'imagenes/senalesColombia/Elementos/tunel/TNEL.png'}
              />
            </FormTab>

            <FormTab label="Datos Administrativos">
              <strong>Detalles constructivos</strong>
              <Divider fullWidth />
              <DateField label="Año" source="ano" formClassName={classes.grid_cont3_f} />
              <NumberField label="Área (m²)" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
              <TextField label="Norma de diseño" source="diseno" formClassName={classes.grid_cont3_s} />
              <TextField label="Diseñador" source="disenador" formClassName={classes.grid_cont3_s} />
              <br />
              <TextField label="Informe de diseño" source="informe" formClassName={classes.grid_cont12} />

              <strong>Detalles de refuerzo o rehabilitación</strong>
              <Divider fullWidth />
              <DateField label="Año" source="anoa" formClassName={classes.grid_cont3_f} />
              <NumberField label="Área (m²)" source="areaa" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
              <TextField label="Norma de diseño" source="disenoa" formClassName={classes.grid_cont3_s} />
              <TextField label="Diseñador" source="disenadora" formClassName={classes.grid_cont3_s} />
              <br />
              <TextField label="Informe de diseño" source="informea" formClassName={classes.grid_cont12} />
              <br />
              <TextField label="Observaciones" source="observacionesa" formClassName={classes.grid_cont12} />

              <strong>Estudio Hidrogeológico</strong>
              <Divider fullWidth />
              <DateField label="Fecha del estudio" source="anob" formClassName={classes.grid_cont4_f} />
              <TextField label="Consultor" source="consultor" formClassName={classes.grid_cont4_s} />
              <TextField label="Código del estudio" source="estudio" formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="Nombre del estudio" source="nom_estudio" formClassName={classes.grid_cont12} />

              <strong >Estudio de proyección del tráfico</strong>
              <Divider fullWidth />
              <DateField label="Fecha del estudio" source="anoc" formClassName={classes.grid_cont4_f} />
              <TextField label="Consultor" source="consultora" formClassName={classes.grid_cont4_s} />
              <TextField label="Código del estudio" source="estudioa" formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="Nombre del estudio" source="nom_estudioa" formClassName={classes.grid_cont12} />

              <strong >Miembros Interesados</strong>
              <Divider fullWidth />
              <TextField label="Propietario" source="propietario" formClassName={classes.grid_cont4_f} />
              <TextField label="Administración vial" source="vial" formClassName={classes.grid_cont4_s} />
              <TextField label="Proyectista" source="proyectista" formClassName={classes.grid_cont4_s} />
              <strong >Características operacionales</strong>
              <Divider fullWidth />
              <NumberField label="Opacidad de diseño (x10¯³ m¯¹)" source="opacidad" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="Concentración CO de diseño (ppm)" source="concentracion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="Existe variante" source="existe" formClassName={classes.grid_cont4_f} />
              <NumberField label="Longitud variante (km)" source="long_km" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <TextField label="Estado" source="estadoa" formClassName={classes.grid_cont4_s} />
              <TextField label="Observaciones" source="observacioness" formClassName={classes.grid_cont12} />
            </FormTab>
            <FormTab label="Geometría">
              <strong>Diseño en planta</strong>
              <Divider fullWidth />
              <NumberField label="Radio mínimo de curvatura (m)" source="radio_min" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="Radio máximo de curvatura (m)" source="radio_max" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <NumberField label="Ancho por carril (m)" source="ancho_carril" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <strong>Diseño en perfil</strong>
              <Divider fullWidth />
              <NumberField label="Pendiente longitudinal (%)" source="pend_longi" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="Radio mínimo curvas verticales (m)" source="radio_min_curva" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <NumberField label="Radio máximo curvas verticales (m)" source="radio_max_curva" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <NumberField label="Longitud del túnel (m)" source="long_tunel" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="Gálibo (m)" source="galibo"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} formClassName={classes.grid_cont4_s} />
              <NumberField label="Ancho del andén (m)" source="ancho_anden" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <NumberField label="Ancho berma (m)" source="ancho_berma" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />

              <br />
              <strong>Secciones transversales de servicio</strong>
              <Divider fullWidth />

              <TablaTuneles tabla="tuneles" id={IdTuneles} />

              <strong>Información complementaria</strong>
              <Divider fullWidth />
              <TextField label="Sistema de soporte" source="soporte" formClassName={classes.grid_cont3_s} />
              <TextField label="Código" source="codigo_soporte" formClassName={classes.grid_cont3_s} />
              <TextField label="Revestimiento Defintiivo" source="descSoporte" formClassName={classes.grid_cont3_s} />
              <TextField label="Tipología de los portales" source="tipologia" formClassName={classes.grid_cont3_s} />
            </FormTab>
            <FormTab label="Detalles">
              <TextField fullWidth label="Condión del elemento" source="estado" formClassName={classes.grid_cont4_f} />
              {controllerProps.record && controllerProps.record.estado === "Reemplazo" &&
                <TextField fullWidth label="Motivo de reemplazo" source="mot_remplazo" formClassName={classes.grid_cont4_s} />
              }
              {controllerProps.record && controllerProps.record.estado === "Reemplazo" &&
                <ReferenceField
                  label="Elemento que Reemplazo"
                  source="elemnto_remplazo"
                  reference="tuneles"
                  linkType={false}
                  fullWidth
                  formClassName={classes.grid_cont4_s}
                >
                  <TextField source="fullCodea" />
                </ReferenceField>
              }
              <br/><br/>
                <TextField label="Estado" source="estados" formClassName={classes.grid_cont4_f} />
              <br />
              <DateField label="Fecha de instalación" source="fecha" formClassName={classes.grid_cont4_f} />
              <ReferenceField
                label="Proveedor"
                source="proveedor"
                reference="proveedors"
                linkType={false}
                fullWidth
                formClassName={classes.grid_cont4_s}
              >
                <TextField source="razonSocial" />
              </ReferenceField>
              <NumberField label="Garantía (meses)" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} formClassName={classes.grid_cont4_s} />
              <br />
              <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="incluir" formClassName={classes.grid_cont4_f} />
              <br />
              <TextField source="observaciones" fullWidth formClassName={classes.grid_cont12} />


            </FormTab>
            <FormTab label="Auditoria">
              <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont4_f}/>
              <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont4_s}/>
              <br/>
              <DateField source="updatedAt" label="Fecha actualización"formClassName={classes.grid_cont4_f} />
              <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont4_s}/>
            </FormTab>
          </TabbedForm>
        </Show>
      }
    </ShowController>

  )
}
);
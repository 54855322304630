import React, { useState } from 'react';
import {
    FormTab, BooleanInput, TextInput, ArrayInput, SimpleFormIterator, ReferenceInput, SelectInput,
    FormDataConsumer,  NumberInput, AutocompleteInput, DateInput,ImageInput,ImageField
} from 'react-admin'
import { PRInput } from '../../../inputs/PRInput';
import { abValidationInicioEdit,  validarDecimales3, validarDecimales4, validarDosDecimales, isFechaInvalida
} from './../../../helpers/validacionesInputs';
import { PimsElementoActions } from './../PimsElementoActions';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../Edit';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import {
    required,
    maxLength,
    minValue,
    maxValue

} from 'react-admin';
import { TabbedForm } from './../../TabbedForm';
import { ButtonCarousel } from './../../images/ButtonCarouselPeru';
import {  latMininima, lngMininima, validacionmaxfechaact } from '../../validacionesInputs';

const tipoElemento = { sistema: 'RDS' };

export const ElementoRedEdit = withStyles(styles)(({ classes, record, ...props }) => {
    const [segmentos, setSegmento] = useState('');

    const ValidarSegmento = (value) => {
        setSegmento(value)

    }
    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    const [tramos, setTramo] = useState('');
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }
    const [elemento, setElemento] = useState('');
    const ValidarElemento = (value) => {
        setElemento(value);
        console.log(value);
    }

/*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }


    return (
        <Edit title="Editar Elemento Red" actions={<PimsElementoActions />} {...props} >
            <TabbedForm redirect="list">
                <FormTab label="IDENTIFICACIÓN">
                    <CoordenadasProyecto/>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <ReferenceInput 
                                source="tipoElemento" 
                                disabled 
                                reference="tipo_elementos"
                                validate={[required(), ValidarElemento]} 
                                filter={tipoElemento} 
                                fullWidth 
                                formClassName={classes.grid_cont12}
                            >
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                            <TextInput source="tipoElemento" className={classes.ocultar} id="elementos" reference="tipo_elementos" validate={[required()]} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput label="Margen" source="margen" allowEmpty emptyValue="" validate={[required()]} choices={[
                                { id: 'Derecha', name: 'Derecha' },
                                { id: 'Izquierda', name: 'Izquierda' },
                                { id: 'Centro', name: 'Centro' },
                            ]} fullWidth formClassName={classes.grid_cont12} />
                        </Grid>
                    </Grid>
                    {/* ------- LOCALIZACIÓN ----- */}
                    <Typography variant="strong" component="strong">Localización</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont12} />

                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="Unidad Funcional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>

                        {unidads &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="Tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    formClassName={classes.grid_cont4}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {tramos &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="Segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    formClassName={classes.grid_cont4}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                    </Grid>
                    {segmentos && <AbsSegmento segmento={segmentos} />}
 

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>

                    <PRInput label="Abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicioEdit, required()]} formClassName={classes.grid_cont4s} />
                    <NumberInput label="Latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />
                    <NumberInput label="Longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(),lngMininima,LongitudMinimaArray]} formClassName={classes.grid_cont4} />

                    <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                        defaultValue={[
                            {
                                lat: latitudMinimaArray,
                                lng: longitudMinimaArray
                            }
                        ]}
                    >
                        <SimpleFormIterator disableAdd disableRemove >
                            <NumberInput label="Latitud" source="lat" fullWidth />
                            <NumberInput label="Longitud" source="lng" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>


                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="Características">
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento === "/tipo_elementos/LUM" &&
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <SelectInput label="Entorno de la vía" source="entornoVia" allowEmpty emptyValue="" choices={[
                                        { id: "Claro", name: "Claro" },
                                        { id: "Oscuro", name: "Oscuro" },
                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="Clase de iluminación" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                                        { id: "M1", name: "M1" },
                                        { id: "M2", name: "M2" },
                                        { id: "M3", name: "M3" },
                                        { id: "M4", name: "M4" },
                                        { id: "M5", name: "M5" },

                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="Tipo de lampara" source="tipoLampara" allowEmpty emptyValue="" choices={[
                                        { id: "Descarga", name: "Descarga" },
                                        { id: "Incandescentes", name: "Incandescentes" },
                                        { id: "Vapor de Mercurio a alta presión ", name: "Vapor de Mercurio a alta presión " },
                                        { id: "Vapor de sodio a alta presión", name: "Vapor de sodio a alta presión" },
                                        { id: "Vapor de sodio a baja presión", name: "Vapor de sodio a baja presión" },
                                    { id: "Otro", name: "Otro" },
                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="Brazo Tipo" source="brazoTipo" allowEmpty emptyValue="" choices={[
                                        { id: "A", name: "A" },
                                        { id: "B", name: "B" },
                                        { id: "C", name: "C" },
                                        { id: "D", name: "D" },
                                        { id: "E", name: "E" },
                                    ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="Disposición de las luminarias" source="disposicionIluminaria" allowEmpty emptyValue="" choices={[
                                        { id: "Bilateral pareada", name: "Bilateral pareada" },
                                        { id: "Central con doble brazo", name: "Central con doble brazo" },
                                        { id: "Combinación brazos dobles y tresbolill", name: "Combinación brazos dobles y tresbolillo" },
                                        { id: "Unilateral en calzadas diferenciadas", name: "Unilateral en calzadas diferenciadas " },
                                        { id: "Unilateral por el lado exterior", name: "Unilateral por el lado exterior " },

                                    ]} validate={[required()]} fullWidth  formClassName={classes.grid_cont4_s}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Valor de luminancia promedio  (cd/m²)" source="valorIluminancia" validate={[required(), minValue(0, "Debe ser al menos 0"), maxValue(1000000)]} fullWidth formClassName={classes.grid_cont4_s}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Ángulo de inclinación del brazo (°)" source="anguloInclinacion" validate={[required(), minValue(0, "Debe ser al menos 0"), maxValue(90)]} fullWidth formClassName={classes.grid_cont4_f}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Largo (m)" source="largo" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Altura (m)" source="altura" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReferenceInput
                                        label="Empresa"
                                        source="empresa"
                                        reference="servicios_publicos"
                                        sort={{ field: 'empresa', order: 'ASC' }}
                                        filterToQuery={searchText => ({ empresa: searchText })}
                                        validate={[required()]}
                                        formClassName={classes.grid_cont8_s}
                                    >

                                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>

                    {/* ------- ELEMENTO = SEMÁFORO ------ */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento === "/tipo_elementos/SEM" &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <SelectInput label="Clase" source="entornoVia" allowEmpty emptyValue="" choices={[
                                        { id: "Semáforos especiales", name: "Semáforos especiales" },
                                        { id: "Semáforos para el control del tránsito de vehículos ", name: "Semáforos para el control del tránsito de vehículos " },
                                        { id: "Semáforos para pasos peatonales", name: "Semáforos para pasos peatonales" },
                                        { id: "Semáforos sonoros", name: "Semáforos sonoros" },
                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput label="Clasificación de los soportes" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                                        { id: "Postes", name: "Postes" },
                                        { id: "Cables de Suspención", name: "Cables de Suspención" },
                                        { id: "Estructuras tipo Pórtico", name: "Estructuras tipo Pórtico" },
                                        { id: "Ménsulas Cortas", name: "Ménsulas Cortas" },
                                        { id: "Ménsulas Largas Sujetas a Postes Laterales", name: "Ménsulas Largas Sujetas a Postes Laterales" },
                                        { id: "Postes y Pedestales en Islas", name: "Postes y Pedestales en Islas" },
            

                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Largo del módulo luminoso (m)" source="largo" validate={[required(), validarDecimales3, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Ancho del módulo luminoso (m)" source="ancho" validate={[required(), validarDecimales4, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Altura (m)" source="altura" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth/>
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>

                    {/* ------- ELEMENTO = BÁCULA DE PESAJE ------ */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento === "/tipo_elementos/BDP" &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <TextInput label="Marca" source="entornoVia" validate={[required(), maxLength(30)]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput label="Capacidad (t)" source="altura" validate={[required(), maxLength(30), minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput label="Largo (m)" source="largo" validate={[required(), validarDecimales3, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput label="Ancho (m)" source="ancho" validate={[required(), validarDecimales4, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <ReferenceInput
                                        label="Nivel de tráfico"
                                        source="nivelTrafico"
                                        reference="flexible_detalles"
                                        sort={{ field: 'nivel_trafico', order: 'ASC' }}
                                        fullWidth
                                        //filterToQuery={searchText => ({ nivel_trafico: searchText })}
                                        validate={[required()]}
                                    >
                                        <SelectInput 
                                            //options={{ fullWidth: true }} 
                                            optionText="nivel_trafico" 
                                        />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>


                    {/* ------- ELEMENTOS RESTANTES ------ */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento !== "/tipo_elementos/SEM" && formData.tipoElemento !== "/tipo_elementos/LUM" && formData.tipoElemento !== "/tipo_elementos/BDP" &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <SelectInput label="Material" source="entornoVia" allowEmpty emptyValue="" choices={[
                                        { id: "Concreto", name: "Concreto" },
                                        { id: "Mampostería", name: "Mampostería" },
                                        { id: "Prefabricado", name: "Prefabricado" },
                                        { id: "Otro", name: "Otro" },

                                    ]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput label="Sección" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                                        { id: "Circular", name: "Circular" },
                                        { id: "Cuadrada", name: "Cuadrada" },
                                        { id: "Hexagonal", name: "Hexagonal" },
                                        { id: "Perfil H", name: "Perfil H" },
                                        { id: "Rectangular", name: "Rectangular" },
                                        { id: "Otro", name: "Otro" },

                                    ]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Largo/Diámetro (m)" source="largo" validate={[validarDecimales3, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Base (m)" source="ancho" validate={[validarDecimales4, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="Altura (m)" source="altura" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReferenceInput
                                        label="Empresa"
                                        source="empresa"
                                        reference="servicios_publicos"
                                        sort={{ field: 'empresa', order: 'ASC' }}
                                        filterToQuery={searchText => ({ empresa: searchText })}
                                        validate={[required()]}
                                        formClassName={classes.grid_cont4}
                                    >

                                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                                    </ReferenceInput>

                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>
                </FormTab>
                {/* ------------ DETALLES ------------- */}
                <FormTab label="Detalles">
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <SelectInput label="Condición del elemento" source="estado" allowEmpty emptyValue="" choices={[
                                { id: "Existente", name: 'Existente' },
                                { id: "Reemplazo", name: 'Reemplazo' },
                                { id: "Nuevo", name: 'Nuevo' },
                            ]} validate={[required()]} fullWidth />
                        </Grid>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData !== null && formData.estado === "Reemplazo" &&
                                    <Grid item xs={4}>
                                        <SelectInput label="Motivo reemplazo" source="motivoReemplazo" choices={[
                                            { id: "Instalación", name: 'Instalación' },
                                            { id: "Reemplazo programado", name: 'Reemplazo programado' },
                                            { id: "Accidente", name: 'Accidente' },
                                            { id: "Vandalismo", name: 'Vandalismo' },
                                            { id: "Otro", name: 'Otro' },
                                        ]} validate={[required()]} 
                                        fullWidth />
                                    </Grid>
                                )

                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData !== null && formData.estado === "Reemplazo" &&
                                    <Grid item xs={4}>
                                        {/* <ElementosCreados tabla="elemento_reds" tipo="create" fullWidth formClassName={classes.grid_cont4s} /> */}
                                        <ReferenceInput
                                        label="Elemento que reemplazo"
                                        source="elementoReemplazo_id"
                                        reference="elemento_reds"
                                        filter={{
                                            tipoElemento : elemento,
                                            status : 1,
                                            // unidadFuncional: unidads,
                                            // tramo: tramos,
                                            // segmento : segmentos
                                        }}
                                        filterToQuery={searchText => ({ fullCodigo: searchText })} 
                                        fullWidth
                                    >
                                        <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigo" />
                                    </ReferenceInput>
                                    </Grid>
                                )}
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData !== null && formData.estado != "Reemplazo" &&
                                    <Grid item xs={8}>
                                    </Grid>
                                )}
                        </FormDataConsumer>
                        <Grid item xs={4}>
                        <SelectInput label="Estado" source="estados" allowEmpty emptyValue="" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <DateInput label="Fecha de instalación" source="fechaInstalacion" fullWidth validate={validacionmaxfechaact, isFechaInvalida}/>
                        </Grid>
                        <Grid item xs={4}>
                        <ReferenceInput 
                            label="Proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{ proyecto: props.proyecto, suministros: 1, sistema: 'RDS' }}
                            sort={{ field: 'razonSocial', order: 'ASC' }}
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                            validate={[required()]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                        </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="Garantía (meses)" source="garantia" fullWidth validate={[required(), minValue(0, "Debe ser al menos 0")]} />
                        </Grid>
                        <Grid item xs={6}>
                            <BooleanInput label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput source="observaciones" validate={[required()]} fullWidth />
                        </Grid>

                    </Grid>
                </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                <Grid container spacing={24}>                         
                    <Grid item xs={8}>  
                        <ArrayInput label="" source="images" validate={ImagenesEdit} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>                           
                                <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                    <SelectInput  optionText="nombre"  />
                                </ReferenceInput>                          
                            </SimpleFormIterator>
                        </ArrayInput>                            
                    </Grid>     
                    <Grid item xs={4}>  
                        <ButtonCarousel ImagenesOpen={imagenesOpen}   />
                    </Grid>                                                    
                </Grid>                   
            </FormTab>
            </TabbedForm>
        </Edit>
    )
});
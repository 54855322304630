import React, { Fragment } from 'react';
import {
    List, Datagrid, BooleanField, EditButton, ShowButton, Filter, ReferenceField,
    SelectInput, ReferenceInput, BulkDeleteWithConfirmButton, TextField
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ListIcon from '@material-ui/icons/List';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

//--------LIST LINE ACTIONS
const ListarPlan = (props) => (
    <Button
        {...props}
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/plan_mantenimiento_detalles',
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ planMantenimientos: props.record.id }),
            }),
        }}
    >
        <ListIcon />
    </Button>
)


//-----FIN DEL LIST ACTIONS

const ConfirmarBorrar = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
    </Fragment>
);

const Filtro = (props) => (
    <Filter {...props}>
       <ReferenceInput
            label="Política"
            source="nombre"
            reference="politicas_nombres"
            fullWidth
            alwaysOn
        >
            <SelectInput optionText="nombre" fullWidth />
        </ReferenceInput>
        <ReferenceInput
            label="Sistema"
            source="sistema"
            reference="sistemas"
            filter={{
                visibleOnPolicies : 1
            }}
            fullWidth
            alwaysOn
        >
            <SelectInput optionText="nombre" fullWidth />
        </ReferenceInput>
    </Filter>
);
export const PoliticasMantenimientoList = props => (
    <List {...props}
        sort={{ field: 'id', order: 'DESC' }}
        title="Lista Políticas de Mantenimiento"
        filters={<Filtro />}
        bulkActionButtons={<ConfirmarBorrar />}
    >
        <Datagrid>
            <ReferenceField
                label="Nombre de la Política"
                source="nombre"
                reference="politicas_nombres"
                linkType={false}
                fullWidth
            >
                <TextField label="" source="nombre" />
            </ReferenceField>
            <ReferenceField
                label="Sistema"
                source="sistema"
                reference="sistemas"
                linkType={false}
                fullWidth
            >
                <TextField label="" source="nombre" />
            </ReferenceField>
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
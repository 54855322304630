import React from 'react';
import { TextInput, required, minLength, maxLength } from 'react-admin';
import config from '../../config/config';
import { func } from 'prop-types';
let urlAppjs = config.UrlServer;
/**
 * Atributos requeridos
 * id
 * label
 * source
 * reference
 * operation
 * validate
 */

class UniqueMaterial extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fetch: [],
            error: false,
            tipo: "",
            oldValue : null,
            reference : "materials",
            id : this.props.source
        }
        
        this.unique  = this.unique.bind(this);
        
    }
    componentWillMount() {
        // this.setState({ tipo: this.props.tipo })
        fetch(urlAppjs + "/"+this.state.reference+".json?pagination=false",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.state.fetch = response;
            });
        }
        
        unique() {
            if (document.getElementById(this.props.id)) {
                
                var texto = document.getElementById(this.props.id).value;
                console.log(texto)
                if (this.state.oldValue == null) {
                    this.state.oldValue = texto;
                }
                var find = this.state.fetch.find(data => data[this.props.source].toLowerCase().trim() == texto.toLowerCase().trim());
                switch (this.props.operation) {
                    case "edit":
                        if (typeof find === 'object' && find[this.props.source].toLowerCase().trim() != this.state.oldValue.toLowerCase().trim()) {
                            this.state.error = true;
                        } else {
                            this.state.error = false;
                        }
                    break;
                    
                    default:
                        if (typeof find === 'object') {
                            this.state.error = true;
                        } else {
                            this.state.error = false;
                        }
                        break;
                }
                    
            }
            if (this.state.error == true) {
                return "El dato digitado ya se encuentra en uso.";
            }
        }
     
         
    render() {
        const {tipo} = this.state;
        return (
            <TextInput
                id={this.props.source}
                label={this.props.label}
                source={this.props.source}
                style={{width:"21rem"}}
                  validate={[
                    this.unique,
                    required(), 
                    // minLength(2), 
                    // maxLength(10)
                ]}
                formClassName={this.props.formClassName}
            />
        )
        }
}

export default UniqueMaterial;

import React, {useState, Fragment} from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput, NumberField, 
    ArrayField, SingleFieldList,  BulkDeleteWithConfirmButton, DateField,SelectInput, ReferenceField
} from 'react-admin';

const RutaFilter= (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <SelectInput      source="detallesSegmentos.estadoDetalles"  label="Estado" alwaysOn sort={{field : 'name' , order :'ASC'}} choices={[
                                { id: 'Activo', name: 'Activo' },
                                { id: 'Inactivo', name: 'Inactivo' },                    
                            ]} />  
    </Filter>
    )
};

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const ElementoOtroSegList = props => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} title="Lista Segmentos Otros" filters={<RutaFilter/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>    
            <TextField label="Código" source="fullCodigo" sortable />  
            <TextField label="Proveedor" source="detallesSegmentos[0].proveedor_.razonSocial" sortable /> 
            <NumberField label="ESAL (Año)" source="detallesSegmentos[0].esalTime" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
            <NumberField source="detallesSegmentos[0].esalTime10"  label="ESAL (Proyección 10 Años)" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} />
            <TextField source="detallesSegmentos[0].nivelTrafico" label="Nivel de Tráfico" linkType={false} />
            <DateField source="detallesSegmentos[0].dateInicioOperacion" label="Fecha de Inicio Operación" linkType={false}/>
            <TextField source="detallesSegmentos[0].estadoDetalles"  label="Estado" sortable/>                    
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
import React from 'react';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';

import RutaList from './../rutas/list';

const styles = {
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
};

const LinkToRuta = ({ classes, record, translate }) => (
    <Button
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/calificacion_proveedors/create',
            // search: stringify({
            //     page: 1,
            //     perPage: 25,
            //     sort: 'id',
            //     order: 'DESC',
            //     // filter: JSON.stringify({ proyecto: record.id }),
            // }),
        }}
        className={classes.link}
    >
 
        {translate( 'Calificar proveedor' )}
    </Button>
);

const enhance = compose(
    withStyles(styles),
    translate
);
export default enhance(LinkToRuta);
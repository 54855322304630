import React, { useState, Fragment  } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, AutocompleteInput,
         SelectInput, BulkDeleteWithConfirmButton  } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';


export const ElementoRedList = props => {

    const FiltroRed =(props) => {
        const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        return(
        <Filter {...props}>
            <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
            <ReferenceInput 
                label="Elemento" 
                source="tipoElemento"
                alwaysOn 
                reference="tipo_elementos"
                filter={{sistema: "RDS"}}
                sort={{ field: 'nombre', order: 'ASC' }}
                filterToQuery={searchText => ({ nombre: searchText })} 
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
            </ReferenceInput>
            <SelectInput source="estado" choices={[
                { id: "Existente", name: 'Existente' },
                { id: "Reemplazo", name: 'Reemplazo' },
                { id: "Nuevo", name: 'Nuevo' },                                     
                ]}   alwaysOn
            />
             <SelectInput label="Estado" source="estados" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]}  fullWidth alwaysOn
            />
        </Filter>
        )
};
    const ConfirmarBorrar = (props) => (
        <Fragment>
            <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
        </Fragment>
    );

return (
    <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        title="Lista Elementos Redes" filters={<FiltroRed/>}
        bulkActionButtons={<ConfirmarBorrar />}
    >
        <Datagrid>
            <TextField label="Código" source="fullCodigo" sortable={true}/>
            <ReferenceField label="Elemento" source="tipoElemento"  reference="tipo_elementos" linkType={false}><TextField source="nombre"  /></ReferenceField>            
            <PRField label="Abscisa" source="abInicio"/>
            <TextField  source="margen"/>
            <TextField label="Estado" source="estados" sortable/>
            <ShowButton />
            <EditButton />                           
        </Datagrid>
    </List>
)};
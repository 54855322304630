import React, { useState } from 'react';
import {
    Toolbar, CardActions, Tab, NumberField, ShowController, ShowView, TextField, DateField,
    ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, ArrayField, FormDataConsumer,
    SingleFieldList, FormTab, RichTextField, ReferenceArrayField, Datagrid
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { PRField } from './../../../fields/PRField';
import ShowElementsParticular from './../ShowElementsParticular';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
let urlAppjs = config.UrlServer;


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const PoliticasMantenimientoParticularShow = withStyles(styles)(({ classes, record, ...props }) => {

    return (
        <ShowController {...props} title="Información Política de Mantenimiento">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Política de Mantenimiento Particular" actions={<PostShowActions />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="INFORMACION">
                            <TextField 
                                label="Nombre de la Política" 
                                source="nombre" 
                                fullWidth 
                                formClassName={classes.grid_cont3}
                            />   
                            <ReferenceField
                                label="Sistema"
                                source="sistema"
                                reference="sistemas"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>

                            <br/>

                            <ReferenceField
                                label="Unidad Funcional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                formClassName={classes.grid_cont3}
                                fullWidth
                            >
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField
                                label="Tramo"
                                source="tramo"
                                reference="tramos"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            
                            {controllerProps.record && controllerProps.abInic &&
                                <PRField 
                                    label="Abscisa Inicial" 
                                    source="abInic" 
                                    fullWidth 
                                    formClassName={classes.grid_cont3}
                                />

                            }
                            {controllerProps.record && !controllerProps.abInic &&
                                <Typography component="h4" formClassName={classes.grid_cont3}>
                                    Sin Abscisa Inicial Definida.
                                </Typography>
                                // <span formClassName={classes.grid_cont3}></span>

                            }
                            {controllerProps.record && controllerProps.abFin &&
                                <PRField 
                                    label="Abscisa Final" 
                                    source="abFin" 
                                    fullWidth 
                                    formClassName={classes.grid_cont3}
                                />
                            }
                            {controllerProps.record && !controllerProps.abFin &&
                            <Typography component="h4" formClassName={classes.grid_cont3}>
                                Sin Abscisa Final Definida.
                            </Typography>
                                // <span formClassName={classes.grid_cont3}>Sin Abscisa Final Definida</span>

                            }


                            {controllerProps.record &&
                                <ShowElementsParticular 
                                    politica={controllerProps.record.id} 
                                />
                            }
                        </FormTab>
                        <FormTab label="AuditorÍa">
                            <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
});
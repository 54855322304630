import React from 'react';
import { BulkDeleteWithConfirmButton ,ReferenceField,NumberField,DateField,Filter,TextField,List, Datagrid, EditButton, ShowButton,required } from 'react-admin';

import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

const InvetarioFilter = (props) => (
    <Filter {...props}>
        {/* <ReferenceInput label="Segmento" source="estacion_conteo" reference="estacion_conteos" alwaysOn>
                <SelectInput optionText="segmento_.codigo"  />
         </ReferenceInput> */}
    </Filter>
);


export const EjesList = props => (
    <List title="Lista Ejes Equivalentes" {...props}  sort={{ field: 'id', order: 'DESC' }} filters={<InvetarioFilter/>}  bulkActionButtons={<PostBulkActionButtons />} >
         <Datagrid >
         <ReferenceField label="Estación de conteo" source="estacion_conteo" reference="estacion_conteos" fullWidth validate={[required()]} linkType={false}>
                <TextField source="nombre"  />
             </ReferenceField>
             <ReferenceField label="Unidad Funcional" source="estacion_conteo" reference="estacion_conteos" linkType={false}>
                        <TextField source="unidadFuncional_.codigo" />
             </ReferenceField> 
             <ReferenceField label="Segmento" source="estacion_conteo" reference="estacion_conteos" fullWidth validate={[required()]} linkType={false}>
                <TextField source="segmento_.codigo"  />
             </ReferenceField>
             <NumberField label="TPD" source="tpd"/>
             <NumberField label="ESAL" source="esal"/>
             <TextField label="Consultor" source="consultor"  />
             <TextField label="Informe" source="informe"  />
             <DateField label="Fecha medición" source="date" />  
            <ShowButton/>
            <EditButton />
        </Datagrid>
    </List>
)
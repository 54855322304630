import React, { useState } from 'react';
import {
    Toolbar, CardActions, NumberField, ShowController, ShowView, TextField, DateField, ReferenceField,
    BooleanField, TabbedForm, ListButton, EditButton, FormTab, FunctionField, TextInput
} from 'react-admin';
import { GMapField } from '../../../fields/GMapField';
import { PRField } from './../../../fields/PRField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../../images/ButtonCarousel';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoDrenajeShow = withStyles(styles)(({ classes, ...props }) => {

    // const [leftNumber, setLeftNumber] = useState();
    // const alignLeft = () => {
    //     let width = "";

    //     if (document.getElementById("longitud")) {
    //         width = document.getElementById("longitud").parentNode.parentNode.childNodes[0].childNodes[0].offsetWidth;

    //         return ;
    //     }
    // }
    // setLeftNumber(alignLeft);
    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
    }

    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="Información Elemento Drenaje" actions={<ButtonCarousel />} >
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="IDENTIFICACIÓN">
                            <TextField label="Código" source="fullCodigoConsecutivo" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="Consecutivo" source="fullConsecutivo" fullWidth formClassName={classes.grid_cont4} />
                            <br/>
                            <ReferenceField label="Tipo de Elemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            <TextField source="margen" fullWidth formClassName={classes.grid_cont4} />
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/CUNT" &&
                                <TextField label="Tipo de Cuneta" source="tipoCuneta" fullWidth formClassName={classes.grid_cont4} />
                            }
                            
                            <Typography variant="h3" component="h3">Localización</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            {/* <PField label="PR Inicio" source="pInicio" />
                        <PField label="PR Fin"  source="pFin" /> */}
                            <PRField label="Abscisa Inicio" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
                            <PRField label="Abscisa Fin" source="abFin" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField id="longitud" label="Longitud (km)" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />


                            <Typography variant="b" component="b">Coordenadas Iniciales</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />

                            <NumberField label="Latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <span></span>


                            <Typography variant="b" component="b">Coordenadas Finales</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />

                            <NumberField label="Latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="Longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <GMapField style={{ width: "100%" }}
                                defaultZoom={12}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />

                        </FormTab >

                        <FormTab label="Características">
                            <TextField source="material" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="Sección" source="seccion" fullWidth formClassName={classes.grid_cont3} />
                            <br />
                            <NumberField label="Largo (m)" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <NumberField label="Ancho/Diámetro (m)" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            {controllerProps.record && controllerProps.record.seccion !== "Circular" &&
                                <NumberField label="Altura (m)" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            <br />
                            <NumberField label="Número de Tramos" source="numeroTramos" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <NumberField label="Sección Hidráulica (m²)" source="seccionHidraulica" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <br />
                            <FunctionField  
                                label="Pendiente Transversal°" 
                                source="pendienteTransversal"
                                render={record => `${record.pendienteTransversal}%`} 
                                fullWidth 
                                style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                                formClassName={classes.grid_cont3} 
                            />
                            <FunctionField 
                                label="Pendiente Longitudinal°" 
                                source="pendienteLongitudinal"
                                render={record => `${record.pendienteLongitudinal}%`}  
                                fullWidth 
                                style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                                formClassName={classes.grid_cont3} 
                            />
                            <span></span>
                        </FormTab>
                        <FormTab label="Detalles">
                            <TextField label="Condición del elemento" source="estado" formClassName={classes.grid_cont3} />
                            {controllerProps.record && controllerProps.record.estado === "Reemplazo" &&
                                <TextField label="Motivo del Reemplazo" source="motivoReemplazo" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.estado === "Reemplazo" &&
                                <ReferenceField
                                    label="Elemento que Reemplazo"
                                    source="elementoReemplazo_id"
                                    reference="elemento_drenajes"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            }
                            <br/>
                             <TextField label="Estado" source="estados"  formClassName={classes.grid_cont3} />
                            {controllerProps.record && controllerProps.record.estados === "Inactivo" &&
                                <TextField
                                    source="estadoRazon" 
                                    label="Razón" 
                                    formClassName={classes.grid_cont3} 
                                />
                            }
                            <br />
                            <DateField label="Fecha de Instalación" source="fechaInstalacion" formClassName={classes.grid_cont3} />
                            <ReferenceField label="Proveedor" source="proveedor" reference="proveedors" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="Garantía (meses)" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <br />
                            <BooleanField label="¿Incluir este elemento a un plan de mantenimiento?" source="mantenimiento" fullWidth formClassName={classes.grid_cont12} />
                            <TextField source="observaciones" formClassName={classes.grid_cont12} />
                        </FormTab>
                        <FormTab label="Auditoría">
                            <DateField source="createdAt" label="Fecha Creación" fullWidth formClassName={classes.grid_cont3} />
                            <TextField source="createdBy" label="Creado Por" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha Actualización" fullWidth formClassName={classes.grid_cont3} />
                            <TextField source="updatedBy" label="Actualizado Por" fullWidth formClassName={classes.grid_cont6} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
});
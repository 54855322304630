/* VALIDAR QUE LAS COORDENADAS MÁXIMAS DEL PROYECTO SEAN MAYORES A LAS COORDENADAS MÍNIMAS*/


export const CoordenadasMaximasLat =(value) =>{
    
    if(value !== undefined)
    {  
        let latitudIni = document.getElementById('LatitudInicial');

        if(latitudIni){
            latitudIni = latitudIni.value
            if(parseFloat(value) <= parseFloat(latitudIni)){   
                return 'La latitud máxima debe ser mayor a la latitud mínima'         
            } 
        }                                
    }
}

export const CoordenadasMaximasLng =(value) =>{
    
    if(value !== undefined)
    {  
        let LngIni = document.getElementById('LongitudInicial');

        if(LngIni){
            LngIni = LngIni.value            
            if(parseFloat(value) <= parseFloat(LngIni)){   
                return 'La longitud máxima debe ser mayor a la longitud mínima'         
            } 
        }                                
    }
}

// ------ VALIDACIÓN DE PR -------

export const prValidationInicio =() =>{
    
    /*VALORES PR INICIO*/
    let PrInicioBase = document.getElementById('IdprInicio');
    if(PrInicioBase){
        PrInicioBase = PrInicioBase.value;
        let ingresoPrInicio = document.getElementById('ingresoPrInicio').value;
        let separadorInicio = ingresoPrInicio.split("+");
        let separadorInicio1 = separadorInicio[0].split("PR");
        let sePrInicioBase = PrInicioBase.split(".");
        let PrFinBase = document.getElementById('IdprFin').value;
        let sePrFinBase = PrFinBase.split(".");

        /*VALIDACIONES PR INICIO*/
        if(parseInt(separadorInicio1[1] ) < parseInt(PrInicioBase) || parseInt(separadorInicio1[1] )  > parseInt(PrFinBase) )
        {
            return "Error el rango debe estar entre " + PrInicioBase +" y " + PrFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(PrInicioBase))
        {
            if(parseInt(separadorInicio[1]) < parseInt(sePrInicioBase[1]))

            return "Error el rango debe estar entre " + PrInicioBase +" y " + PrFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(sePrFinBase[0]))
        {
            if(parseInt(separadorInicio[1]) > parseInt(sePrFinBase[1]))

            return "Error el rango debe estar entre " + PrInicioBase +" y " + PrFinBase;
        }
    }
    
}

export const prValidationFin =() =>{
    
    /*VALORES ABS FIN */
    let PrFinBase = document.getElementById('IdprFin');
    if(PrFinBase)
    {
        PrFinBase = PrFinBase.value;
        let ingresoPrFin = document.getElementById('ingresoPrFin').value;
        let separadorFin = ingresoPrFin.split("+");
        let separadorFin1 = separadorFin[0].split("PR");
        let sePrFinBase = PrFinBase.split(".");
        let PrInicioBase = document.getElementById('IdprInicio').value;
        let sePrInicioBase = PrInicioBase.split(".");
        /*VALIDACIONES PRFIN*/
        if(parseInt(separadorFin1[1] ) > parseInt(PrFinBase) || parseInt(separadorFin1[1] )  < parseInt(PrInicioBase) )
        {
            return "Error el rango debe estar entre " + PrInicioBase +" y " + PrFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(PrFinBase))
        {
            if(parseInt(separadorFin[1]) > parseInt(sePrFinBase[1]))
        
                return "Error el rango debe estar entre " + PrInicioBase +" y " + PrFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(sePrInicioBase[0]))
        {
            if(parseInt(separadorFin[1]) < parseInt(sePrInicioBase[1]))
        
                return "Error el rango debe estar entre " + PrInicioBase +" y " + PrFinBase;
        }
    }    
}


//------ VALIDACIÓN LONGITUD Y LATITUD ----
export const validarDecimales =(value) =>{
    
    if(value !== undefined){
        if (!/^[+-]?([0-9]+([.][0-9]+))?$/.test(value)) {
             return "El valor ingresado debe ser decimal";
            }      
    } 
}


export const validarDecimales2 =(value) =>{
    if(value !== undefined){
        if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(value)) { return "El valor ingresado debe ser decimal"; }      
    } 
}
export const validateDecima =(value) =>{
    
    if(value < -90.000){
        if (!/^[+-]?([0-9]+([.][0-9]+))?$/.test(value)) {
             return "El valor ingresado debe mayor a -90";
            }      
    } 
}

export const validatLatitud =(value) =>{
    if(value !== undefined){

        // let separador1 =value.split('.');
        // console.log(separador1[1])
        if(value < -90.000){
            return "El valor mínimo debe ser -90"
        }
        if(value > 90.000){
            return "El valor máximo debe ser 90"
        }
        
    }
}


export const validarLongitud =(value) =>{
    if(value !== undefined){
    // let separador1 =value.split(',');
    //     console.log(separador1[1])
        if(value < -180){
            return "El valor mínimo debe ser -180"
        }
        if(value > 180){
            return "El valor máximo debe ser 180"
        }
    } 
}

export const validarDecimales3 =(value) =>{

    if(value !== undefined){
        if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(value)) { return "El valor ingresado debe ser decimal"; }      
    } 
}

export const validarDecimales4 =(value) =>{
    if(value !== undefined){
        if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(value)) { return "El valor ingresado debe ser decimal"; }      
    } 
}

export const validarDecimales5 =() =>{
    let latitudFin = document.getElementById('latitudFin').value;
    if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(latitudFin))
    {
      return "El valor ingresado en las coordenadas debe ser decimal"
    }
}

// ------ VALIDACIÓN DE ABSCISAS -------
export const validarAB =() => {
    var ingresoAbInicio = document.getElementById("ingresoAbInicio");
    var ingresoAbFin = document.getElementById("ingresoAbFin");
    if (ingresoAbInicio && ingresoAbFin) {

        let Abinc = ingresoAbInicio.value.split("+");
        Abinc[0] = Abinc[0].replace("K", "");
        ingresoAbInicio = Abinc[0] + "." + Abinc[1];

        let Abfin = ingresoAbFin.value.split("+");
        Abfin[0] = Abfin[0].replace("K", "");
        ingresoAbFin = Abfin[0] + "." + Abfin[1];

        if (ingresoAbInicio > ingresoAbFin) {
            return "La Abscisa Inicial no puede ser mayor que la final";
        }
    }
}
export const abValidationInicio =() =>{
    
    /*VALORES ABS INICIO*/
    let AbInicioBase = document.getElementById('IdAbInicio');
    if(AbInicioBase)
    {
       
        AbInicioBase = AbInicioBase.value
        let ingresoAbInicio = document.getElementById('ingresoAbInicio').value;
        let separadorInicio = ingresoAbInicio.split("+");
        let separadorInicio1 = separadorInicio[0].split("K");
        let seAbInicioBase = AbInicioBase.split(".");
        let AbFinBase = document.getElementById('IdAbFin').value;
        let seAbFinBase = AbFinBase.split(".");
        /*VALIDACIONES PR INICIO*/
        if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
        {
            
            return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
        {
            if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))

            return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
        {
            
            if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))

            return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
        }
    }    

}

export const abValidationFin =() =>{
    /*VALORES AB FIN */
    let AbFinBase = document.getElementById('IdAbFin');
    let ingresoAbFin = document.getElementById('ingresoAbFin');
    if(AbFinBase && ingresoAbFin)
    {
        
        AbFinBase = AbFinBase.value;
        ingresoAbFin = ingresoAbFin.value;
        let separadorFin = ingresoAbFin.split("+");
        let separadorFin1 = separadorFin[0].split("K");
        let seAbFinBase = AbFinBase.split(".");
        let AbInicioBase = document.getElementById('IdAbInicio').value;
        let seAbInicioBase = AbInicioBase.split(".");
        /*VALIDACIONES AB FIN*/
        if(parseInt(separadorFin1[1] ) > parseInt(AbFinBase) || parseInt(separadorFin1[1] ) < parseInt(AbInicioBase ) )
        {
            return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(AbFinBase))
        {
            if(parseInt(separadorFin[1]) > parseInt(seAbFinBase[1]))
        
                return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(seAbInicioBase[0]))
        {
            if(parseInt(separadorFin[1]) < parseInt(seAbInicioBase[1]))
        
                return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
        }
    }
    
}


//VALIDAR QUE LOS TAMAÑOS DE LOS PR NO SEAN IGUALES
export const validarLogicaPrFinCreate=(value)=>{
    if(value !== undefined){
        
        let prInicio = document.getElementById('ingresoPrInicio').value;
        prInicio = prInicio.split('PR')
        if(prInicio[1] !== undefined)
        {
            prInicio = prInicio[1].split('+')
              
            value = value.split('PR')
            value = value[1].split('+')
        
            if(parseInt(value[0]) < parseInt(prInicio[0]))
            {   
                return 'PR fin debe ser mayor a PR inicio' 
            }
            if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) === parseInt(prInicio[1]))
            {
                return 'PR fin debe ser mayor a PR inicio' 
            }
            if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) < parseInt(prInicio[1]))
            {
                return 'PR fin debe ser mayor a PR inicio' 
            }
           
        }

        
        
    }
}

export const ValorMaximoPrCreate =(value) =>{
    if(value !== undefined)
    {
        value = value.split("PR")
        if(value[1] !== undefined)
        {
            value=value[1].split("+")
            if(value[0] > 999)
            {
                return 'El valor ingresado no puede ser mayor a PR999+999'
            }
        }
    }
}


export const validarLogicaPrFinEdit=()=>{
    let prInicio = document.getElementById('ingresoPrInicio').value;
    prInicio = prInicio.split('PR')
    if(prInicio[1] !== undefined)
    {
        prInicio = prInicio[1].split('+')
        let prFin = document.getElementById('ingresoPrFin').value;
        prFin = prFin.split('PR')
        prFin = prFin[1].split('+')

        if(parseInt(prFin[0]) < parseInt(prInicio[0]))
        {   
            return 'PR fin debe ser mayor a PR inicio' 
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1]))
        {
            return 'PR fin debe ser mayor a PR inicio' 
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1]))
        {
            return 'PR fin debe ser mayor a PR inicio' 
        }
    }
}

export const ValorMaximoPrEditInicio =() =>{
    
    let value = document.getElementById('ingresoPrInicio').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return 'El valor ingresado no puede ser mayor a PR999+999'
        }
    }
} 

export const ValorMaximoPrEditFin =() =>{
    
    let value = document.getElementById('ingresoPrFin').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return 'El valor ingresado no puede ser mayor a PR999+999'
        }
    }
} 

//Validaciones logicas de las ABscisas
export const validarLogicaAbsFinCreate=(value)=>{

    if(value !== undefined  && typeof value==="string")
    {
        
        let prInicio = document.getElementById('ingresoAbInicio').value;
        let value = document.getElementById('ingresoAbFin').value;
        prInicio = prInicio.split('K')
        if(prInicio[1] !== undefined)
        {
            prInicio = prInicio[1].split('+')
              
            value = value.split('K')
            if(value[1]){
                value = value[1].split('+')
            
                if(parseFloat(value[0]) < parseFloat(prInicio[0]))
                {   
                    return 'Abscisa fin debe ser mayor a Abscisa inicio' 
                }
                if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) === parseFloat(prInicio[1]))
                {
                    return 'Abscisa fin debe ser mayor a Abscisa inicio' 
                }
                if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) < parseFloat(prInicio[1]))
                {
                    return 'Abscisa fin debe ser mayor a Abscisa inicio' 
                }
            }
        }
        
    }
}

export const validarLogicaAbsFinEdit=()=>{

    let prInicio = document.getElementById('ingresoAbInicio').value;
    prInicio = prInicio.split('K')
    if(prInicio[1] !== undefined)
    {
        prInicio = prInicio[1].split('+')
    
        let prFin = document.getElementById('ingresoAbFin').value;
        prFin = prFin.split('K')
        prFin = prFin[1].split('+')

        if(parseInt(prFin[0]) < parseInt(prInicio[0]))
        {   
            return 'Abscisa fin debe ser mayor a Abscisa inicio' 
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1]))
        {
            return 'Abscisa fin debe ser mayor a Abscisa inicio' 
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1]))
        {
            return 'Abscisa fin debe ser mayor a Abscisa inicio' 
        }

    }
    
    
}
export const ValorMaximoAbsEditInicio =() =>{
    
    let value = document.getElementById('ingresoAbInicio').value;
    value = value.split("K")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 9999)
        {
            return 'El valor ingresado no puede ser mayor a K999+999'
        }
    }
    
} 

export const ValorMaximoAbsEditFin =() =>{
    
    let value = document.getElementById('ingresoAbFin').value;
    value = value.split("K")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
       
        if(value[0] > 9999)
        {
            return 'El valor ingresado no puede ser mayor a K999+999'
        }
    }
} 

export const ValorMaximoAbsCreate =(value) =>{
    if(value !== undefined)
    {
        value = value.split("K")
        if(value[1] !== undefined)
        {
            value=value[1].split("+")
            if(value[0] > 999)
            {
                return 'El valor ingresado no puede ser mayor a PR999+999'
            }
        }
    }
}

 
/*VALIDACION GEOREFERENCIA SEGMENTOS */
export const latMininima =(value) =>{
    let latMin = document.getElementById('latMin');
    let latMax = document.getElementById('latMax');
    if(latMax){

        latMin = latMin.value;
        latMax = latMax.value;
        if(parseFloat(latMin) < parseFloat(latMax))
        {
            if(parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)){
                return "El rango debe estar entre " + latMin +" y " + latMax;
            }
        }else if(parseFloat(latMax) < parseFloat(latMin)){
            if(parseFloat(value) < parseFloat(latMax) || parseFloat(value) > parseFloat(latMin)){
                return "El rango debe estar entre " + latMax  +" y " + latMin;
            }
        }      
    }
} 

export const lngMininima =(value) =>{
    
    
    /*VALORES ABS FIN */
    let lngMin = document.getElementById('lngMin');
    let lngMax = document.getElementById('lngMax');
    if(lngMax){
        lngMin = lngMin.value;
        lngMax = lngMax.value;
        if(parseFloat(lngMin) <  parseFloat(lngMax)){
            if(parseFloat(value) <  parseFloat(lngMin) || parseFloat(value) >  parseFloat(lngMax))
            {   //console.log('Entrada1' + lngMin + lngMax)
                return "El rango debe estar entre " + lngMin +" y " + lngMax;
            }
        }else if(parseFloat(lngMax) < parseFloat(lngMin)){
            if(parseFloat(value) <  parseFloat(lngMax) || parseFloat(value) >  parseFloat(lngMin))
            {
                //console.log('Entrada2' + lngMin + lngMax)
                return "El rango debe estar entre " +  lngMax +" y " + lngMin;
            }
        }       
    }
}

export const Altitud =(value) =>{
    let alturaMin = document.getElementById('alturaIni');
    let alturaMax = document.getElementById('alturaFin');
    if(alturaMax){
        alturaMin = alturaMin.value;
        alturaMax = alturaMax.value;
        if(parseFloat(value) < parseFloat(alturaMin) || parseFloat(value) > parseFloat(alturaMax)){
            return "El rango debe estar entre " + alturaMin +" y " + alturaMax;
        }
    }
}


export const latMininimaEdit =(value) =>{
    
    /*VALORES ABS FIN */

    let latMin = document.getElementById('latMin');
    let latMax = document.getElementById('latMax');

    if(latMax){
        latMin = latMin.value;
        latMax = latMax.value;
        if(parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax))
        {
         
            return "El rango debe estar entre " + latMin +" y " + latMax;
        }
        
    }
}


export const lngMininimaEdit =(value) =>{
    
    /*VALORES ABS FIN */

    let lngMin = document.getElementById('lngMin');
    let lngMax = document.getElementById('lngMax');

    if(lngMax){
        lngMin = lngMin.value;
        lngMax = lngMax.value;
        if(parseFloat(value) <  parseFloat(lngMax) || parseFloat(value) >  parseFloat(lngMin))
        {

            return "El rango debe estar entre " + lngMin +" y " + lngMax;
        }
    }
}


export const validarTresDecimales =(value) =>{
    if(value){
        if(value !== 0 && value  !== 'No aplica'){
            if (parseFloat(value)  % 1 !== 0) {
                let pattern = new RegExp("^[+-]?[0-9]+([.][0-9]{1,3})$");
                if(!pattern.test(value)){ 
                    //let patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    // if(!patterns.test(parseFloat(value) )){ 
                    // }                           
                    return "El valor ingresado solo puede tener hasta 3 decimales"; 
                }                            
            }                
        }
    }  
}

export const validarDosDecimales =(value) =>{
    if(value){
        if(value !== 0 && value  !== 'No aplica'){
            if (parseFloat(value)  % 1 !== 0) {
                let pattern = new RegExp("^[+-]?[0-9]+([.][0-9]{1,2})$");
                if(!pattern.test(value)){ 
                    //let patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    // if(!patterns.test(parseFloat(value) )){ 
                    // }                           
                    return "El valor ingresado solo puede tener hasta 2 decimales"; 
                }                            
            }                
        }
    }  
}

export const validarUnDecimal =(value) =>{

    if(value){
        if(value !== 0){
            if (value % 1 !== 0) {
                var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                
                if(!pattern.test(value)){ 
                    var patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!patterns.test(value)){ return "El valor ingresado solo puede tener 1 decimal"; }                           
                }            
            }                
        }
    }  
}

export const validarPorcentaje =(value) =>{
    value = value.split('%');
    if (!/^[+-]?([0-9]+)*$/.test(value[0]))
    {
      return "Debe ser un número entero";
    }
}

/* VALIDAR RANGOS DE PR ENTRE SEGMENTOS */

export const validarAbsEntreSegmento = (value) =>
{
    let prIngreso =document.getElementById('ingresoAbInicio');
    let valor =document.getElementById('ingresoAbFin');
    if(prIngreso)
    {
        prIngreso =prIngreso.value;
        prIngreso = prIngreso.split('K');
        if(prIngreso !== undefined || prIngreso !== null || prIngreso !=='')
        {
            prIngreso = prIngreso[1].split('+');
            prIngreso = prIngreso[0] +  prIngreso[1];

            valor = valor.value;
            valor= valor.split('K');
            valor= valor[1].split('+')
            valor = valor[0] +  valor[1];
            let resta = parseFloat(valor) - parseFloat(prIngreso);            
            if(resta > 1999)
            {
                return 'La distancia entre las Abscisas de ser menor a 1.999'
            }
            // else if(resta < 1000)
            // {
            //     return 'La distancia entre las Abscisas deber ser de al menos  de 1.000'
            // }
        }
    }
}


/*VALIDACIÓN DE ABSCISAS EN LOS RAMALES*/
export const AbsRamal = (value) =>
{
    let prIngreso =document.getElementById('ingresoAbInicio');
    let valor =document.getElementById('ingresoAbFin');
    if(prIngreso)
    {
        prIngreso =prIngreso.value;
        prIngreso = prIngreso.split('K');
        if(prIngreso !== undefined || prIngreso !== null || prIngreso !=='')
        {
            prIngreso = prIngreso[1].split('+');
            prIngreso = prIngreso[0] +  prIngreso[1];

            valor = valor.value;
            valor= valor.split('K');
            valor= valor[1].split('+')
            valor = valor[0] +  valor[1];
            let resta = parseFloat(valor) - parseFloat(prIngreso);            
            if(resta > 3000)
            {
                return 'La distancia entre las Abscisas de ser menor a 3.000'
            }
            // else if(resta < 1000)
            // {
            //     return 'La distancia entre las Abscisas deber ser de al menos  de 1.000'
            // }
        }
    }
}

export const validarPrEntreSegmento = () =>
{
  
        let AbsIngreso =document.getElementById('ingresoPrInicio');
        let valor = document.getElementById('ingresoPrFin');
        if(AbsIngreso)
        {
            AbsIngreso =AbsIngreso.value;
            AbsIngreso = AbsIngreso.split('PR');
            if(AbsIngreso === undefined || AbsIngreso === null || AbsIngreso ==='')
            {
                AbsIngreso = AbsIngreso[1].split('+');
                AbsIngreso=AbsIngreso[0] +  AbsIngreso[1];

                valor = valor.value;
                valor= valor.split('PR');
                valor= valor[1].split('+')
                valor = valor[0] +  valor[1];

                let resta = parseInt(valor) - parseInt(AbsIngreso);

                if(resta > 1999)
                {
                    return 'La distancia entre los Pr de ser menor a 1.999'
                }
            }            
        }        
}

export const validarSeisDecimales =(value) =>{

    if(value !== 0 && value !== 'No aplica'){
        if (value % 1 !== 0) {
            let pattern = new RegExp("^[0-9]+([.][0-9]{6})$");
            
            if(!pattern.test(value)){ 
                let patterns = new RegExp("^[0-9]+([.][,][0-9]{5})$");
                if(!patterns.test(value)){ return "El valor ingresado solo puede tener 2 decimales"; }                           
            }                            
        }                
    } 
}


export const Entero =(value) =>{
    if(value !== undefined && value !== null)
    {
        if (!/^([0-9]+)*$/.test(value))
        {
        return "Debe ser un número entero";
        } 
    }   
}


// /*--------------------VALIDAR QUE LA ALTURA FINAL SEA MAYOR  A LA ALTURA INICIAL*/

export const AlturaMayor =(value) =>{
    
    if(value !== undefined)
    {  
        let alturaIni = document.getElementById('alturaIni');

        if(alturaIni){
            alturaIni = alturaIni.value
            if(parseFloat(value) <= parseFloat(alturaIni)){   
                return 'La altitud máxima debe ser mayor a la altitud mínima'         
            } 
        }                                
    }
}

export const validacionmaxfechaact=(value)=>{
    if(value){
        let fecha=new Date(value+"T00:00:00");
        let fechaact=new Date();
        if(fecha>fechaact || !fecha){
           return "La fecha no puede ser mayor a la actual"
        }
    } 
  }

export const isFechaInvalida=(value)=>{
    if(value){

        let fechaSeleccionada = new Date(value).toLocaleString("en-US", {timeZone: "America/Bogota"});

        let dateActual = new Date();
        dateActual = dateActual.toLocaleString("en-US", {timeZone: "America/Bogota"});
        dateActual = dateActual.split(",")[0].split("/");

        let dateSeleccionada = new Date(value);
        dateSeleccionada = dateSeleccionada.toLocaleString("en-US", {timeZone: "America/Bogota"});
        dateSeleccionada = dateSeleccionada.split(",")[0].split("/");

        
        // console.log(
        //     "(año)", 
        //     parseInt(dateSeleccionada[2]) + ">" + parseInt(dateActual[2]),
        //     parseInt(dateSeleccionada[2]) > parseInt(dateActual[2])
        // )
        // console.log(
        //     "(mes)", 
        //     parseInt(dateSeleccionada[0]) +">"+ parseInt(dateActual[0]),
        //     (
        //         parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
        //         parseInt(dateSeleccionada[0]) > parseInt(dateActual[0])
        //     )
        // )
        // console.log(
        //     "(dia)", 
        //     parseInt(dateSeleccionada[0]) +"-"+parseInt(dateSeleccionada[1]) +">="+ dateActual[0] + "-" + parseInt(dateActual[1]),
        //     (
        //         parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
        //         parseInt(dateSeleccionada[0]) == parseInt(dateActual[0]) &&
        //         parseInt(dateSeleccionada[1]) >= parseInt(dateActual[1])
        //     )
        // )

        if(parseInt(fechaSeleccionada.split("/")[2]) < 1000) {
            return "fecha inválida."
        } 
        
        if (parseInt(dateSeleccionada[2]) > parseInt(dateActual[2])){
            return "La fecha indicada no puede ser futura."

        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) > parseInt(dateActual[0])
        ){
            return "La fecha indicada no puede ser futura."
            
        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) == parseInt(dateActual[0]) &&
            parseInt(dateSeleccionada[1]) >= parseInt(dateActual[1])
        ){
            return "La fecha indicada no puede ser futura."
                            
        }
    }
  }




import React, { Fragment,useState } from 'react';
import { List, Datagrid,SelectField, TextField,AutocompleteInput, EditButton, ShowButton, ReferenceField,NumberField,Filter,ReferenceInput, SelectInput} from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';


// const FiltrosElementos = (props) => (
//     <Filter {...props}>
//         <SelectInput source="tipo" label="Tipo de Material"choices={choises2} alwaysOn/>
//     </Filter>
// );

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const InvetarioFilter = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
 return(
    <Filter {...props}>
        <ReferenceInput
                id="unidadFuncional"
                label="Unidad Funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>
    </Filter>
);
 }

export const ParametrosAnalisisList = props => (
    <List {...props} sort={{ field:"updatedAt", order: 'DESC' }} filters={<InvetarioFilter/>} title="Lista Parámetros de planeación" bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>    
        <ReferenceField label="Unidad Funcional" source="unidad_funcional" reference="unidad_funcionals"  linkType={false} >
              <TextField source="fullCodigos" />
          </ReferenceField>
          <TextField source="plan" label="Plan de mantenimiento" fullWidth />  
          <NumberField label="Número de años de análisis" source="num_analisis"  />
            <ShowButton />
            <EditButton/>
        </Datagrid>
    </List>
);
import React, {useState, Fragment} from 'react';
import { SimpleForm, FormTab, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput, SimpleFormIterator, ArrayInput, Query, Loading, Error, Datagrid} from 'react-admin';
import { TabbedForm } from './../TabbedForm';
import { PimsCreate } from './../Create';
import { PInput } from './../../inputs/PInput';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
//import  {styles}  from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FInput } from './../../inputs/FInput';
import {
    required,

} from 'react-admin';
import  {abValidationInicio,
         ValorMaximoAbsCreate,
         latMininima,
         lngMininima,
         
        }  from './../validacionesInputs';
import {Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import config from '../../config/config';
let urlAppjs = config.UrlServer;
let urlAppjs2 = config.UrlFront;


const LinkCancelar = () =>{
    let redirect ="disponibilidad_vias"
   return  <a href={urlAppjs2 + redirect} class="cancelar" ><CancelIcon style={{width: '0.8em' , paddingRight:'8px'}}/>CANCELAR</a>;
}  

const CustomToolbar = ({ basePath, data,...props}) => (
    <Toolbar {...props} >
        <SaveButton />
        <LinkCancelar/>
    </Toolbar>
);
/*
const styles = {
    ocultar: {
        display:"none",
        
    },
    coord :{marginTop : '50px'},

};
*/
const styles = {
    ocultar: {display:"none",    },
    grid_cont2:{display: 'inline-block', width: '20%', marginLeft: '10px' },
    grid_cont3:{display: 'inline-block', width: '24%', marginLeft: '10px' },
    grid_cont4:{display: 'inline-block', width: '32%', marginLeft: '10px' },
    grid_cont6:{display: 'inline-block', width: '49%', marginLeft: '10px' },
    grid_cont12:{display: 'inline-block', width: '99%', marginLeft: '10px' },
    "& div:empty":{display:'none'}
};

export const DisponibilidadCreate = withStyles(styles)(({ classes, ...props }) => {
    const [estado, setEstado] = useState('');
    const [horasen, setHoras] = useState('');
    const [causa, setCausa] = useState('');
    const ValidarEvento=(value) =>{    
        //console.log(value);
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setEstado(valor);
        
    }
var today = new Date();
var horaact=today.getHours();
var minact=today.getMinutes();
if(horaact.toString().length<=1){
    horaact="0"+horaact;
}

if(minact.toString().length<=1){
    minact="0"+minact;
}
//console.log(horaact.length+" AND "+minact.length)
//console.log(typeof minact);
var hora=horaact+":"+minact;
const HoraSenal =(value) =>{     
    /*let aux = value.split(':');
    let hora2 = parseInt(aux[0]);
    let minut2 = parseInt(aux[1]);
    let horacomp2 = (hora2*60) + minut2;
    let hora1 = parseInt(horaact);
    let minut1 = parseInt(minact);
    let horacomp1 = (hora1*60) + minut1;
    let res1 = horacomp1 - horacomp2;
    let horasenal=parseInt(res1/60);
    let minutsenal=parseInt(res1%60);
    if(horasenal.toString().length<=1){
        horasenal="0"+horasenal;
    }
    
    if(minutsenal.toString().length<=1){
        minutsenal="0"+minutsenal;
    }
    */
    if(document.getElementById('fecha_senal') && document.getElementById('Fechareport') && document.getElementById('hora_senal') && document.getElementById('Horardeporte')){
    let fecha_senalizacion=document.getElementById('fecha_senal').value;
    let fecha_reporte=document.getElementById('Fechareport').value;
    let tiempo_senalizacion=document.getElementById('hora_senal').value;
    let tiempo_reporte=document.getElementById('Horardeporte').value;
    let tiemposplit=tiempo_reporte.split(':');
    let hora_reporte=tiemposplit[0];
    let minuto_reporte=tiemposplit[1];
    if(hora_reporte.toString().length<=1){
        hora_reporte="0"+hora_reporte;
    }
    tiempo_reporte=hora_reporte+":"+minuto_reporte;
    let fecha_inicial=fecha_reporte+"T"+tiempo_reporte;
    let fecha_final=fecha_senalizacion+"T"+tiempo_senalizacion;
    let resultado=RestarFechas(fecha_inicial, fecha_final);
    //console.log(resultado);
        setHoras(resultado);
    }
}
const [abValidaini, setAbValidaini] = useState('');
const [abValidafin, setAbValidafin] = useState('');
const [unidad , setUnidad]= useState('');
const [longitudafect, setLongitud] = useState('');
const [consect, setConsect] = useState('');
const [longcl, setLongcl] = useState('');
const [elementoaf, setElementoaf] = useState('');
const [volumenderr, setVolumenderr] = useState('');
const [condicionesesp, setCondicionesesp] = useState('');
const [tiempodespeje, setTiempodespeje] = useState('');
const [unidadfun, setUnidadfun] = useState('');
const [abingini, setAbingini] = useState('');
const [abingfin, setAbingfin] = useState('');
const [segmentosafectados, setSegmentosafectados]= useState('');
const [proyectos, setProyecto] = useState( window.localStorage.getItem('id_project2'));

const ValidarUnidad = (value)  => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; }                
    let aux=valor.split('/');
    setUnidad(aux[2]) 
}
const validarUnifun = (value) =>{
    let unidadactual=document.getElementById('ufuncional').value;
    let idunidadactual=unidadactual.split('/');
    if(value && idunidadactual[2]!=unidadfun){
            let sperador1 = value.split('/');
            //console.log(urlAppjs);
            //console.log(sperador1[2]);
            setUnidadfun(sperador1[2]);
            fetch(urlAppjs+ 'unidad_funcionals/' + sperador1[2])
            .then((response) => {
                //console.log("REST");
                return response.json();
            })
            .then((response) =>{
                setAbValidaini(response['abInicio']);
                setAbValidafin(response['abFin']);
            })
    }

}
const validarAbini =(value) =>{            
    //console.log("El valor es = "+value+"La abs = "+abValidaini);
    if(value && value.search("[\\K\\+]")!=-1)
    {   
        
        let sperador1 = value.split('K');
        let separador2 = sperador1[1].split('+');
        let cadena = separador2[0] + '.' + separador2[1]
        let absfin = document.getElementById('abfin').value;
        cadena=Number.parseFloat(cadena)
        if(cadena < abValidaini){
            return 'La Abscisa inicial debe estar entre '+abValidaini+' y '+abValidafin
        } 
        else if(cadena >abValidafin){
            return 'La Abscisa inicial debe estar entre '+abValidaini+' y '+abValidafin
        }
        else if(absfin && absfin.search("[\\K\\+]")!=-1)
            {
            let sperador1 = absfin.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena2 = separador2[0] + '.' + separador2[1];
            cadena2=Number.parseFloat(cadena2)
        

                if(cadena2<=cadena){
                    
                    return 'La Abscisa Inicial debe ser menor a la Abscisa Final';
                }
            }  
    }
};
const validarAbfin =(value) =>{            
    //console.log(abValidafin);
    if(value && value.search("[\\K\\+]")!=-1)
    {   
        
        let sperador1 = value.split('K');
        let separador2 = sperador1[1].split('+');
        let cadena = separador2[0] + '.' + separador2[1]
        let absini = document.getElementById('abin').value;
        cadena=Number.parseFloat(cadena)
        if(cadena > abValidafin){
            return 'La Abscisa Final debe estar entre '+abValidaini+" y "+abValidafin
        }  
        if(cadena < abValidaini){
            return 'La Abscisa Final debe estar entre '+abValidaini+" y "+abValidafin
        }
        if(absini && absini.search("[\\K\\+]")!=-1)
        {
        let sperador1 = absini.split('K');
        let separador2 = sperador1[1].split('+');
        let cadena2 = separador2[0] + '.' + separador2[1];
        cadena2=Number.parseFloat(cadena2)
            if(cadena2>=cadena){
                return 'La Abscisa Final debe ser mayor a la Abscisa inicial';
            }
        } 
    }
};
const validarLongitudafect=(value) =>{
    let abiniel=document.getElementById('abin')
    let abfinel=document.getElementById('abfin')
    if(abiniel && abfinel){
       let  abini=abiniel.value;
       let abfin=abfinel.value;
    if(abfin!="" && abini!=""){
    abini=abini.replace('K', '');
    abini=abini.replace('+', '.');
    abfin=abfin.replace('K', '');
    abfin=abfin.replace('+', '.');
    let res =(abfin - abini).toFixed(2)+"km";
    setLongitud(res);}
    }
}
const validarMaxl=(value)=>{
    let largo = value.length;
    if(largo>40){
        return "Máximo 40 caracteres";
    }
}
const obtenerdominio=()=>{
    return urlAppjs;
}
const consecutivo1=(value)=>{
    let valores=value.split('/');
    let ufunc=valores[2];
    fetch(urlAppjs+ 'disponibilidadViaCon/'+ufunc)
    .then((response)=>{
        return response.json();
    })
    .then((response)=>{
        setConsect(response[0]);
        // if(response[0]){
        // let numero =parseInt(response[0]);
        // numero=numero+1;
        //     if(numero<=9){setConsect("00"+numero);}
        //     else {setConsect("0"+numero);}
        // }
    })
        
}
const validarCausa=(value)=>{
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; }                
    setCausa(valor) 
}
const validarLongcl=(value)=>{
    if(!Number.isInteger(value) || value<0){
            return "El número debe ser  un entero positivo";
        }
    else if(value>300){
        setLongcl("No Cumple");
    }
    else if(value<=300){
        setLongcl("Cumple");
    }
}
const validarVolder=(value)=>{
    if(!Number.isInteger(value) || value<0){
        return "El Número Debe Ser  Un Entero Positivo";
    }
    else{
        setVolumenderr(value);
    }
}
const validarElemento=(value)=>{
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; } 
    setElementoaf(valor);
}
const validarCondicionesesp=(value)=>{
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; } 
    setCondicionesesp(valor)
    //console.log(valor);
}
const valorTiempodespeje=()=>{
   console.log("Volumen ->"+volumenderr+" cesp->"+condicionesesp);
    let aux="";
    if(condicionesesp==="Disposición de Nuevos Dispositivos de Contención" && volumenderr>2000){
        aux="1 semana";
    }
    else if(condicionesesp==="Mayores Dificultades" && volumenderr>2000){
        aux="1 mes";
    }
    else if(volumenderr>200 && volumenderr<=2000 && elementoaf==="Calzada"){
        aux="24";
    }
    else if(volumenderr>200 && volumenderr<=2000 && elementoaf==="Bermas"){
        aux="24";
    }
    else if(volumenderr<=200 && elementoaf==="Calzada"){
        aux="4";
    }
    else if(volumenderr<=200 && elementoaf==="Bermas"){
        aux="24";
    }
    else{
        aux="0";
    }
   setTiempodespeje(aux);
}
const validarTipoincidente=(value)=>{
    let largo = value.length;
    if(largo>40){
        return "Máximo 40 caracteres";
    }
} 
const validarTipovehiculootro=(value)=>{
    let largo = value.length;
    if(largo>20){
        return "Máximo 20 caracteres";
    }
}
const validarCodigocausa =(value)=>{
    if(value<90 || value>506){
        return "El código debe estar entre 090 y 506"
    }
}
const validarCausaProbable =(value)=>{
    let largo = value.length;
    if(largo>200){
        return "Máximo 200 caracteres";
    }
}
const validarClasePolicia=(value)=>{
    let largo = value.length;
    if(largo>20){
        return "Máximo 20 caracteres";
    }
}
const validarcantidadheridos =(value)=>{
    if(value<0 || value>100){
        return "La cantidad debe estar entre 0 y 100";
    }
}
const validartipoheridos =(value)=>{
    let largo = value.length;
    if(largo>20){
        return "Máximo 20 caracteres";
    }
}
const validargeneroheridos =(value)=>{
    let largo = value.length;
    if(largo>10){
        return "Máximo 10 caracteres";
    }
}
const validaredadheridos=(value)=>{
    if(value<0 || value>122){
        return "La Edad  debe estar entre 0 y 122";
    }
}
const validartipovehiculo=(value)=>{
    let largo = value.length;
    if(largo>15){
        return "Máximo 15 caracteres";
    }
}
const validarobservaciones=(value)=>{
    let largo = value.length;
    if(largo>200){
        return "Máximo 200 caracteres";
    }
}
const validarsegmentosafect=(value)=>{
    let abiniel=document.getElementById('abin')
    let abfinel=document.getElementById('abfin')
    if(abiniel && abfinel){
        let abini=abiniel.value;
        let abfin=abfinel.value;
    if(abfin!="" && abini!=""){
    abini=abini.replace('K', '');
    abini=abini.replace('+', '.');
    abfin=abfin.replace('K', '');
    abfin=abfin.replace('+', '.');
    //console.log("abini ="+abini+" abingini= "+abingini)    
    if(abini!=abingini || abfin!=abingfin || unidadfun != unidad){
        setAbingini(abini);
        setAbingfin(abfin);
        fetch( obtenerdominio()+"disponibilidadViaSeg/"+unidadfun+"/"+abini+"/"+abfin)
                .then((response)=>{
                    return response.json();
                })
                .then((response)=>{
                    let largo=response.codigoseg.length;
                    let valor ="";
                    for (let i=0; i<largo; i++){
                        valor=valor+response.codigoseg[i]+"; ";
                    }
                    if(valor===""){
                        valor="Ningun Segmento Afectado"
                    }
                    setSegmentosafectados(valor);
                })    
            }
    }
    }
}
/*const validarDirectorio=(value)=>{
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; } 
    setDirectorio(value);
}*/
const RestarFechas=(fecha1, fecha2)=>{
    let fecha_inicial=new Date(fecha1);
    let fecha_final=new Date(fecha2);
    let fecha_inicial_off=new Date( fecha_inicial.getTime() + Math.abs(fecha_inicial.getTimezoneOffset()*60000) );
    let fecha_final_off=new Date( fecha_final.getTime() + Math.abs(fecha_final.getTimezoneOffset()*60000) );
    let difference=fecha_final_off.getTime()-fecha_inicial_off.getTime();
    
    //let differencedays=difference/(1000*3600*24);
    //let differencehours=(difference/(1000*3600))%24;
    let differencehours2=(difference/(1000*3600));
    let differenceminutes=(difference/(1000*60))%60;
    let hora_resultado=Math.abs(parseInt(differencehours2));
    let minuto_resultado=Math.abs(parseInt(differenceminutes));
    if(hora_resultado.toString().length<=1){
        hora_resultado="0"+hora_resultado;
    }
    
    if(minuto_resultado.toString().length<=1){
        minuto_resultado="0"+minuto_resultado;
    }
    let resultado =hora_resultado+":"+minuto_resultado;

    return resultado
}
const timeFormatter= v =>{
    if(v && typeof v!= 'undefined'){
    let timeformat=v.split(':');
    let horaformat=timeformat[0];
    let minutoformat=timeformat[1];
    if(parseInt(horaformat)>24){
        horaformat=23;
    }
    if(horaformat.length<=1){
        horaformat="0"+horaformat;
    }
    if(parseInt(minutoformat)>60){
        minutoformat=59;
    }
    if(minutoformat.length<=1){
        minutoformat="0"+minutoformat;
    }
    let tiempofinal = horaformat+":"+minutoformat;
    return tiempofinal;
}
else{
    return v;
    }
}
    return(
        <PimsCreate  {...props} title="Crear de Disponibilidad de Vía">
            <TabbedForm submitOnEnter={false} redirect="list"> 
                <FormTab label="IDENTIFICACIÓN">
                    
                        
                            <TextInput label="Consecutivo" fullWidth source="consecutivo" disabled formClassName={classes.grid_cont3} validate={[required()]} defaultValue={consect}/>
                        
                        
                            <DateInput label="Fecha" fullWidth source="fecha" disabled validate={[required()]} defaultValue={today} formClassName={classes.grid_cont3} />
                        
                        
                            <SelectInput label="Tipo de Evento" fullWidth source="tipoEvento" choices={[
                                {id: 'Incidente', name: 'Incidente'},
                                {id: 'Accidente', name: 'Accidente'},
                                {id: 'Cierre', name: 'Cierre'}
                            ]} onChange={ValidarEvento} validate={[required()]} formClassName={classes.grid_cont3}/>
                        
                        {estado === "Cierre"  &&
                            
                            <SelectInput label="Tipo de Cierre"  fullWidth source="tipoCierre" choices={[
                                {id: 'Calzada', name: 'Calzada'},
                                {id: 'Carril', name: 'Carril'}
                            ]} formClassName={classes.grid_cont3} />
                            
                        }
                        
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12}>Localización</Typography>
                        
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                        
                        
                            <ReferenceInput label="Unidad Funcional" source="unidadFuncional" id="ufuncional" reference="unidad_funcionals" validate={[required(), ValidarUnidad, validarUnifun, consecutivo1, validarLongitudafect, validarsegmentosafect]} fullWidth formClassName={classes.grid_cont4}>
                                <SelectInput  optionText="codigo" fullWidth />
                            </ReferenceInput>
                        
                        
                        {unidad &&
                         <PRInput label="Progresiva Inicial"  source="ninguno" id="abin" fullWidth formClassName={classes.grid_cont4} validate={[required(),ValorMaximoAbsCreate, abValidationInicio, validarAbini, validarLongitudafect, validarsegmentosafect]}/>
                         }
                        
                         {unidad &&
                        <NumberInput source="abscisaIni" id="abinihid"  defaultValue={abingini} formClassName={classes.ocultar}/>
                        }
                        {unidad &&
                        <NumberInput source="abscisaFin" id="abfinhid"  defaultValue={abingfin} formClassName={classes.ocultar}/>
                        }
                        
                        {unidad &&
                         <PRInput label="Progresiva Final"  source="nada" id="abfin" fullWidth formClassName={classes.grid_cont4} validate={[required(),ValorMaximoAbsCreate, validarAbfin, validarLongitudafect, validarsegmentosafect]}/>
                         }
                        
                            <SelectInput label="Margen"  fullWidth source="margen" choices={[
                                {id: 'Izquierda', name: 'Izquierda'},
                                {id: 'Derecha', name: 'Derecha'}
                            ]} validate={[required()]} formClassName={classes.grid_cont6}/>
                        
                        
                         <TextInput label="Lugar"  source="lugar" fullWidth formClassName={classes.grid_cont4} validate={[required(), validarMaxl]} maxLength={40} formClassName={classes.grid_cont6}/>
                        
                        
                            <TextInput label="Longitud Afectada (km)" source="longitudAfect" fullWidth formClassName={classes.grid_cont3} disabled validate={[required()]} defaultValue={longitudafect}/>                        
                        
                        
                        {abingfin&&
                            /*<Query type="GET_ONE" resource="" payload={{ id: obtenerdominio()+"disponibilidadViaSeg/"+unidadfun+"/"+abingini+"/"+abingfin}} > 
                            {({ data, loading, error }) => {                                                
                               console.log(data)
                               if(data){
                                    return <TextInput label="Segmentos Afectados" disabled defaultValue={
                                        data.map(function(valor){
                                            return valor+" ;"
                                        })
                                    } source="segmentosAfect" fullWidth validate={[required()]}/>;
                                }else{
                                 return   <TextInput label="Segmentos Afectados" disabled defaultValue="No afecta ningún segmento" source="segmentosAfect" fullWidth validate={[required()]}/>;
                                }                                                
                            }}
                            </Query> */
                            <TextInput label="Segmentos Afectados" disabled defaultValue={segmentosafectados} source="segmentosAfect" fullWidth validate={[required()]} multiline formClassName={classes.grid_cont3}/>
                        }
                        {!unidad &&
                            <div formClassName={classes.grid_cont3}></div>
                        }
                                              
                            <NumberInput label="Latitud" source="latitud" fullWidth formClassName={classes.grid_cont3} validate={[required(), latMininima ]}/>                        
                        
                            <NumberInput label="Longitud" source="longitud" fullWidth formClassName={classes.grid_cont3} validate={[required(), lngMininima]}/>                        
                            
                            {                        
                    <Query type="GET_ONE" resource="proyectos" payload={{ id: proyectos}} formclassName={classes.ocultar}>
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    // 
                                    <Grid item xs={3} className={classes.ocultar}>
                                        <TextInput  id="lngMin" style={{display : 'none'}} defaultValue={data.longitud1} source="longituds" fullWidth />
                                        <TextInput id="latMin"  style={{display : 'none'}}   defaultValue={data.latitud1} source="latituds" fullWidth />
                                        <TextInput  id="lngMax" style={{display : 'none'}} defaultValue={data.longitud2} source="longituds2" fullWidth />
                                        <TextInput id="latMax"  style={{display : 'none'}}  defaultValue={data.latitud2} source="latituds2" fullWidth />                            
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                } 
                        
                    
                </FormTab>
                <FormTab label="DETALLES">
                    
                
                            <DateInput label="Fecha de Reporte" source="fechaReporte" fullWidth id="Fechareport" formClassName={classes.grid_cont4} validate={[required()]}/>
                        
                            <FInput label="Hora de Reporte" source="horaReporte" id="Horardeporte" fullWidth formClassName={classes.grid_cont4} validate={[required(), HoraSenal]} format={timeFormatter}/>
                        
                        
                            <DateInput label="Fecha de Señalización" source="fechaSenal" id="fecha_senal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} disabled defaultValue={today}/>
                        
                        
                            <FInput label="Hora de Señalización" source="horaSenal" id="hora_senal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} defaultValue={hora} disabled/>
                        
                            <FInput label="Tiempo de Señalización" source="tiempSenal" id="tiempsenal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} defaultValue={horasen} disabled />
                        
                        <FInput label="Hora Finalización" source="horaFinal" formClassName={classes.ocultar}  defaultValue={hora}/>
                        <div formClassName={classes.grid_cont4}></div>
                        
                            <SelectInput label="Fuente del Reporte"  fullWidth source="fuenteRep" choices={[
                                    {id: 'Llamada', name: 'Llamada'},
                                    {id: 'Email', name: 'Email'},
                                    {id:'Recorredor', name:'Recorredor'}
                                ]} formClassName={classes.grid_cont4} validate={[required()]}/>
                        
                        
                            <SelectInput label="Causa"  fullWidth source="causa" choices={[
                                    {id: 'Accidente', name: 'Accidente'},
                                    {id: 'Derrumbe', name: 'Derrumbe'},
                                    {id:'Trabajos en vía', name:'Trabajos en vía'},
                                    {id:'Manifestación', name:'Manifestación'},
                                    {id:'Orden público', name:'Orden público'},
                                    {id:'Otro', name:'Otro'}
                                ]} formClassName={classes.grid_cont4} validate={[required(), validarCausa]}/>
                        
                        
                            <SelectInput label="Elemento Afectado"  fullWidth source="elementoAfect" choices={[
                                    {id: 'Calzada', name: 'Calzada'},
                                    {id: 'Puente', name: 'Puente'},
                                    {id:'Túnel', name:'Túnel'},
                                    {id:'Bermas', name:'Bermas'}
                                ]} formClassName={classes.grid_cont4} validate={[required(), validarElemento]}/>
                        
                            <TextInput label="Observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                        
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12}>Asociar Servicios</Typography>
                        
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                            <Grid container spacing={24}>
                            <Grid item xs={12}>
                            <ArrayInput label="" source="servicios" fullWidth>
                                <SimpleFormIterator>
                                    <SelectInput label="Directorio"  fullWidth source="serviciosDirectorio" choices={[
                                        {id: 'Emergencias', name: 'Emergencias'},
                                        {id: 'Proveedores', name: 'Proveedores'},
                                        {id:'Servidores Públicos', name:'Servidores Públicos'}
                                    ]}  validate={[required()]} fullWidth/>
                                    <NumberInput source="tipoServicio" className={classes.ocultar} formClassName={classes.ocultar}/>
                                     
                                     {/* <FormDataConsumer className={classes.ocultar} formClassName={classes.ocultar}>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Emergencias' &&
                                            <NumberInput source={getSource("tipoServicio")}  parse={v => parseInt(v)} className={classes.ocultar} formClassName={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer className={classes.ocultar} formClassName={classes.ocultar}>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Proveedores' &&
                                            <NumberInput source={getSource("tipoServicio")}  parse={v => parseInt(v)} className={classes.ocultar} formClassName={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer className={classes.ocultar} formClassName={classes.ocultar}>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === 'Servidores Públicos' &&
                                            <NumberInput source={getSource("tipoServicio")}  className={classes.ocultar} formClassName={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer> */}
                                    <FormDataConsumer formClassName={classes.ocultar}>
                                                    {({formData, scopedFormData, ...rest}) => {
                                                    if(scopedFormData != null && scopedFormData.serviciosDirectorio === 'Emergencias'){
                                                        scopedFormData.tipoServicio=1;
                                                    }
                                                    else if(scopedFormData != null && scopedFormData.serviciosDirectorio === 'Proveedores'){
                                                        scopedFormData.tipoServicio=2;
                                                    }
                                                    else if(scopedFormData != null && scopedFormData.serviciosDirectorio === 'Servidores Públicos'){
                                                        scopedFormData.tipoServicio=3;
                                                    }
                                                }}
                                     </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Emergencias' &&
                                                <SelectInput label="Grupo" source={getSource('serviciosGrupoEmergencias')} fullWidth choices={[
                                                    { id: 'POB-Supervisión vial', name: 'POB-Supervisión vial' },
                                                    { id: 'POB-Administrativa', name: 'POB-Administrativa' },
                                                    { id: 'POB-Radio Operación', name: 'POB-Radio Operación' },
                                                    { id: 'POB-Grúas', name: 'POB-Grúas' },
                                                    { id: 'POB-Carro taller', name: 'POB-Carro taller' },
                                                    { id: 'POB-Ambulancia', name: 'POB-Ambulancia' },
                                                    { id: 'POB-Peajes', name: 'POB-Peajes' },
                                                    { id: 'Interventoría', name: 'Interventoría' },
                                                    { id: 'Servicios médicos', name: 'Servicios médicos' },
                                                    { id: 'Policía', name: 'Policía' },
                                                    { id: 'Ejército', name: 'Ejército' },
                                                    { id: 'Defensa civil', name: 'Defensa civil' },
                                                    { id: 'Bomberos', name: 'Bomberos' },
                                                    { id: 'Centro educativo', name: 'Centro educativo' },
                                                    { id: 'Alcaldía', name: 'Alcaldía' },
                                                    { id: 'Emisora radial', name: 'Emisora radial' },
                                                    { id: 'Talleres', name: 'Talleres' },
                                                    { id: 'Maquinaria', name: 'Maquinaria' },
                                                    { id: 'Otros', name: 'Otros' },
                                                ]}  validate={[required()]} formClassName={classes.grid_cont3}/>
                                            )
                                        }
                                    </FormDataConsumer>  
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === 'Proveedores' &&
                                            <ReferenceInput label="Sistema" source={getSource('serviciosSistemaProveedores')} reference="sistemas" validate={[required()]} formClassName={classes.grid_cont3}>
                                            <SelectInput  optionText="nombre" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer> 
                                    <FormDataConsumer >
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === 'Servidores Públicos' &&
                                            <SelectInput label="Grupo" source={getSource('serviciosGrupoServicios')} choices={[
                                                { id: 'Acueducto', name: 'Acueducto' },
                                                { id: 'Gas natural', name: 'Gas natural' },
                                                { id: 'Energía', name: 'Energía' },
                                                { id: 'Telecomunicaciones', name: 'Telecomunicaciones' },
                                                { id: 'Aseo', name: 'Aseo' },
                                                { id: 'Otros', name: 'Otros' },
                                            ]} fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                                            )
                                        }
                                    </FormDataConsumer> 
                                    <FormDataConsumer >
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosGrupoEmergencias && scopedFormData.serviciosDirectorio === 'Emergencias' &&
                                            <ReferenceInput label="Elemento" source={getSource('serviciosElementoEmergencias')} reference="contacto_emergencias" filter={{grupo: scopedFormData.serviciosGrupoEmergencias}} validate={[required()]} formClassName={classes.grid_cont3} >
                                                <SelectInput  optionText="empresa" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer >
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosSistemaProveedores && scopedFormData.serviciosDirectorio === 'Proveedores' &&
                                            <ReferenceInput label="Elemento" source={getSource('serviciosElementoProveedores')} reference="proveedors" filter={{sistema: scopedFormData.serviciosSistemaProveedores}} validate={[required()]} formClassName={classes.grid_cont3}>
                                                <SelectInput  optionText="razonSocial" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosGrupoServicios && scopedFormData.serviciosDirectorio === 'Servidores Públicos' &&
                                            <ReferenceInput label="Elemento" source={getSource('serviciosElementoServicios')} reference="servicios_publicos" filter={{grupo: scopedFormData.serviciosGrupoServicios}} validate={[required()]} formClassName={classes.grid_cont3}>
                                                <SelectInput  optionText="empresa" fullWidth />
                                            </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                            </Grid>
                            </Grid>
                </FormTab>
                <FormTab label="CARACTERÍSTICAS">
                {(estado==="Incidente" && causa==="Trabajos en vía") &&
                            <NumberInput label="Longitud de Cola (m)" source="longitudCola" fullWidth validate={[required(), validarLongcl]} formClassName={classes.grid_cont6}/>
                                    }
                {(estado==="Incidente" && causa==="Trabajos en vía") &&                                    
                            <TextInput label="Indicador O2" source="indicadorO2" fullWidth validate={[required()]} disabled defaultValue={longcl} formClassName={classes.grid_cont6}/>
                }
                {(estado==="Incidente" && causa==="Trabajos en vía") &&
                            <TextInput label="Observaciones" source="observacionesTrabajos" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                }
                {estado==="Incidente" && causa==="Derrumbe" &&
                            <NumberInput label="Volumen del Derrumbe (m3)" source="volumenDerrumbe" fullWidth validate={[required(), validarVolder, valorTiempodespeje]} formClassName={classes.grid_cont3}/>
                }                        
                {estado==="Incidente" && causa==="Derrumbe" &&
                            <TextInput label="Elemento Afectado" fullWidth validate={[required()]}  disabled defaultValue={elementoaf} formClassName={classes.grid_cont3}/>
                }
                {estado==="Incidente" && causa==="Derrumbe" &&  volumenderr>2000 &&
                        
                            <SelectInput label="Condiciones Especiales" fullWidth validate={[required(), validarCondicionesesp]} source="condicionesEsp" choices={[
                                {id:'Disposición de Nuevos Dispositivos de Contención', name:'Disposición de Nuevos Dispositivos de Contención'},
                                {id:'Mayores Dificultades', name:'Mayores Dificultades'}
                            ]} formClassName={classes.grid_cont3}/>        
                }
                {estado==="Incidente" && causa==="Derrumbe" &&                        
                            <TextInput label="Tiempo de Despeje Máximo (h)" fullWidth validate={[required()]} source="tiempoDespeje" disabled defaultValue={tiempodespeje} formClassName={classes.grid_cont3}/>
                }                        
                {estado==="Incidente" && causa==="Derrumbe" &&
                            <TextInput label="Observaciones" source="observacionesDerrumbe" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                    
                }
                {estado==="Incidente" && causa==="Otro" &&
                            <TextInput label="Tipo de Incidente" fullWidth validate={[required(), validarTipoincidente]} source="tipoIncidenteOtro" formClassName={classes.grid_cont6}/>
                }
                {estado==="Incidente" && causa==="Otro" &&
                            <TextInput label="Tipo de Vehículo" fullWidth validate={[required(), validarTipovehiculootro]} source="tipoVehiculoOtro" formClassName={classes.grid_cont6}/>
                }
                {estado==="Incidente" && causa==="Otro" &&
                            <TextInput label="Observaciones" source="observacionesOtro" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                }

                {estado==="Accidente" &&
                            <SelectInput label="Tipo de Accidente" fullWidth source="tipoAccidente" choices={[
                                {id:'Simple', name:'Simple'},
                                {id:'Heridos', name:'Heridos'},
                                {id:'Muertos', name:'Muertos'}
                            ]} validate={[required()]} formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12}>Juicio de la Policía</Typography>
                }
                {estado==="Accidente" &&
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                            <NumberInput label="Código Causa" source="codigoCausa" fullWidth validate={[required(), validarCodigocausa]} formClassName={classes.grid_cont4}/>
                }
                {estado==="Accidente" &&
                            <TextInput label="Causa Probable" source="causaProbable" validate={[required(), validarCausaProbable]} fullWidth formClassName={classes.grid_cont4}/>
                }
                {estado==="Accidente" &&
                            <TextInput label="Clase" source="clasePolicia" validate={[required(), validarClasePolicia]} fullWidth formClassName={classes.grid_cont4}/>
                }
                {estado==="Accidente" &&
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12}>Heridos</Typography>
                }
                {estado==="Accidente" &&
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                            <ArrayInput label="" source="heridos" fullWidth>
                                <SimpleFormIterator>
                                    <NumberInput label="Cantidad" source="cantidadHeridos" validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3}  fullWidth/>
                                    <TextInput label="Tipo" source="tipoHeridos" validate={[required(), validartipoheridos]} formClassName={classes.grid_cont3}  fullWidth/>
                                    <TextInput label="Género" source="generoHeridos" validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}  fullWidth/>
                                    <NumberInput label="Edad" source="edadHeridos" validate={[required(), validaredadheridos]} formClassName={classes.grid_cont2}  fullWidth/>
                                </SimpleFormIterator>
                            </ArrayInput>
                }
                {estado==="Accidente" &&
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12}>Víctimas Fatales</Typography>
                }
                {estado==="Accidente" &&
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                            <ArrayInput label="" source="victimas" fullWidth>
                                <SimpleFormIterator>
                                    <NumberInput label="Cantidad" source="cantidadVictimasf" validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3} fullWidth/>
                                    <TextInput label="Tipo" source="tipoVictimasf" validate={[required(), validartipoheridos]} formClassName={classes.grid_cont3} fullWidth />
                                    <TextInput label="Género" source="generoVictimasf" validate={[required(), validargeneroheridos]}  formClassName={classes.grid_cont3} fullWidth/>
                                    <NumberInput label="Edad" source="edadVictimasf" validate={[required()], validaredadheridos}  formClassName={classes.grid_cont2} fullWidth/>
                                </SimpleFormIterator>
                            </ArrayInput>
                }
                {estado==="Accidente" &&
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12}>Vehículos Implicados</Typography>
                }
                {estado==="Accidente" &&
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                }
                {estado==="Accidente" &&
                        <ArrayInput label="" source="vehiculos" fullWidth >
                            <SimpleFormIterator>
                                <NumberInput label="Cantidad" source="cantidadVehiculos" fullWidth validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Categoría" source="categoriaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Tipo" source="tipoVehiculos" fullWidth validate={[required(), validartipovehiculo]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Marca" source="marcaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2}/>
                                <TextInput label="Línea" source="lineaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Modelo" source="modeloVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Placa" source="placaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3}/>
                                <TextInput label="Color" source="colorVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                }
                {estado==="Accidente" &&
                            <TextInput label="Observaciones" source="observacionesAccidente" fullWidth formClassName={classes.grid_cont12} validate={[required(), validarobservaciones]} multiline/>
                }   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
});
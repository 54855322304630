import React from 'react';
import { TabbedShowLayout, TextField, Tab, DateField,NumberField,SelectField,ReferenceField,BooleanField} from 'react-admin';

import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Trayectorias from './view/tablaindicador';

export const TrayectoriasShow = withStyles(styles)(({ classes,record, ...props }) => {
  

    return(
        <Show title="Información Alternativas" {...props}>
            <TabbedShowLayout>
                <Tab label="Alternativas">
                <ReferenceField label="Unidad Funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
              <NumberField source="trafico" label="Limite de tránsito" fullWidth formClassName={classes.grid_cont4}/>
                <TextField label="Nombre de la alternativa" source="nombre" fullWidth formClassName={classes.grid_cont3}/>
                    <NumberField source="precio" label="Precio total ($/m²)"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} fullWidth  />  
                    <NumberField source="preciocar" label="Precio carpeta ($/m²)"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} fullWidth/>  
                    <NumberField label="Vida esperada (años)" source="vida"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} fullWidth  />
                    <NumberField label="Espesor removido (mm)" source="espesor"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} fullWidth  />
                    <br/><br/>
                    {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
                    {/* <div style={{display: 'flex', justifyContent: 'center', margin: '0px 10px', marginTop: '20px', width:'100%' }}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '-180px', marginBottom: '15px'   }}>Código Material</strong> 
             </div>
             <br/><br/>
                    <ReferenceField label="Espesor capa 1 (mm)" source="material" reference="materials" fullWidth linkType={false}  style={{ display: "flex", justifyContent: "flex-start", marginRight: "-100%",marginLeft:'25vh',marginTop: "-25px" }}>
                  <TextField source="material"/>
              </ReferenceField>
              <br/><br/>
              <ReferenceField label="Espesor capa 2 (mm)" source="material1" reference="materials" fullWidth linkType={false}   style={{ display: "flex", justifyContent: "flex-start", marginRight: "-100%",marginLeft:'25vh',marginTop: "-25px" }}>
                  <TextField source="material"/>
              </ReferenceField>
              <br/><br/>
              <ReferenceField label="Espesor capa 3 (mm)" source="material2" reference="materials" fullWidth linkType={false}   style={{ display: "flex", justifyContent: "flex-start", marginRight: "-100%",marginLeft:'25vh',marginTop: "-25px" }}>
                  <TextField source="material"/>
              </ReferenceField>
              <br/><br/>
              <ReferenceField label="Espesor capa 4 (mm)" source="material3" reference="materials" fullWidth linkType={false}   style={{ display: "flex", justifyContent: "flex-start", marginRight: "-100%",marginLeft:'25vh',marginTop: "-25px" }}>
                  <TextField source="material"/>
              </ReferenceField> */}
                     <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <Trayectorias tipo="mostrar" record={record} /> }
                </WithProps>  
                {/* <div style={{display: 'flex', justifyContent: 'center', margin: '0px 350px', marginTop: '20px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}></strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>Espesores</strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
             <strong style={{display: 'flex', alignItems: 'flex', marginRight: '150px', marginBottom: '15px',width:'1000px'}}>Código material </strong>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa1 (mm)</strong> 
            <NumberField label=" " source="espesorcap1"  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>
            </div>
            <ReferenceField label=" " source="material" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <SelectField source="material"/>
              </ReferenceField>
            
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa2 (mm)</strong> 
            <NumberField label=" " source="espesorcap2"  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>
            </div>
            <ReferenceField label=" " source="material1" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <TextField source="material"/>
              </ReferenceField>
            
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%' }}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa3 (mm)</strong> 
            <NumberField label=" " source="espesorcap3" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>
            </div>
            <ReferenceField label=" " source="material2" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <TextField source="material"/>
              </ReferenceField>
            
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px -50px', marginTop: '-15px',width:'100%'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '100px', marginBottom: '15px'}}>Espesor capa4 (mm)</strong> 
            <NumberField label=" " source="espesorcap4" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 40px'}}>
            </span>  
            </div>
            <ReferenceField label=" " source="material3" reference="materials" fullWidth style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px',width:'22%'}}>
                  <TextField source="material"/>
              </ReferenceField>
                   */}
                <br/><br/>
                    <BooleanField label="Rígido" source="rigido" />
                    <BooleanField label="Flexible" source="flexible"/>
                    <br/>
                    <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <Trayectorias record={record} tipo="tipo"/> }
                </WithProps> 
                    </Tab>
                    <Tab label="Auditoría">
                    <DateField source="createdAt" label="Fecha creación" formClassName={classes.grid_cont3s}/>
                    <TextField source="createdBy" label="Creado por" formClassName={classes.grid_cont3}/>    
                    <br/>
                    <DateField source="updatedAt" label="Fecha actualización" formClassName={classes.grid_cont3s}/>
                    <TextField source="updatedBy" label="Actualizado por" formClassName={classes.grid_cont3}/>
                </Tab>
            </TabbedShowLayout>
        </Show>
)});
const WithProps = ({ children, ...props }) => children(props);
import React, {useState} from 'react';
import {Filter,List, Datagrid, TextField, EditButton, ShowButton, BulkDeleteWithConfirmButton, ReferenceInput, 
    AutocompleteInput,SelectInput
} from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const Filtros= (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
       <ReferenceInput 
            id="unidadFuncional"
            label="Unidad Funcional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="Segmento" 
            source="segmento" 
            reference="segmentos" 
            alwaysOn 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="Tipo" 
            source="tipoElemento"
            reference="tipo_elementos" 
            filter={{sistema: "TUN"}} 
            sort={{field : 'name' , order :'ASC'}} 
            filterToQuery={searchText => ({ nombre: searchText })} 
            alwaysOn
            resettable
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
        </ReferenceInput>
          
        <SelectInput label="Estado" source="estados" choices={[
                            { id: "Activo", name: "Activo" },
                            { id: "Inactivo", name: "Inactivo" },
                        ]}  fullWidth alwaysOn
            />
            
    </Filter>
    )
};

export const TunelList = props => (
    <List title="Lista Tunel"{...props}  sort={{ field: 'id', order: 'DESC' }} filters={<Filtros/>} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
            <TextField label="Código" source="fullCodea"/>
            <TextField label="Tipo" source="clasificacion" />
            <TextField label="Margen" source="sentido"  />
            <TextField label="Estado" source="estados" sortable />
            <ShowButton />
            <EditButton />                 
        </Datagrid>
    </List>
);
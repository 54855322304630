import React from 'react';
import { WithPermissions,ListButton, Datagrid, ReferenceField,TabbedShowLayout, EditButton, Tab, TextField, 
    DateField, CardActions, BooleanField, ChipField, ArrayField,ReferenceArrayField, SingleFieldList, NumberField 
} from 'react-admin';
import { Show } from './../Show';
import { withStyles } from '@material-ui/core';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const styles ={
    ocultar:{display:"none"}, 
    
}
const PostActions =({basePath,data,
})=>{
var proveedores = localStorage.getItem("proveedores");
return(
        <WithPermissions
        render={({ permissions }) => (
            "1" === proveedores   ||    "2" === proveedores           
    ? 
    <CardActions style={cardActionStyle}>
        
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
        {/* Add your custom actions
        <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
    

    :  <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} record={data} />
        </CardActions>
    )}
    />
    
)
};



export const ProveedorShow = withStyles(styles)(({ classes, ...props }) => {

    return(
    <Show title="Información Proveedor" {...props} actions={<PostActions/>}>
        <TabbedShowLayout>
            <Tab label="Información básica">
                {/* <ReferenceField source="proyecto" reference="proyectos">
                    <TextField source="nombre" />
                </ReferenceField>  */}
                
                 <ReferenceField source="proyecto" reference="proyectos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <BooleanField source="mantenimiento"/>
                <BooleanField source="suministros"/>

                <ReferenceField source="sistema" reference="sistemas" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <span></span>   
                <span></span>  
                
                <TextField label="Tipo de Identificación" source="identificacion"/>
                <NumberField label="Número Identificación" source="numeroIdentificacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
                <TextField label="Empresa" source="razonSocial"/>
                {/* <TextField source="contacto"/>        */}
                <TextField label="Nombres" source="nombre"/>
                <TextField label="Apellidos" source="apellido"/>
                <span></span>
                                          
                <TextField label="Dirección" source="direccion"/>                
                
                <ReferenceField label="Provincia"  source="departamento" reference="departamentos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>

                <ReferenceArrayField label="Distrito" reference="municipios" source="municipio">
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
                </ReferenceArrayField>
                {/* <ReferenceField label="Indicativo"  source="departamento" reference="departamentos" linkType={false} >
                    <TextField source="indicativo" />
                </ReferenceField> */}
                <TextField label="Teléfono" source="telefono" />
                <TextField label="Teléfono 2" source="telefono2" />
                <br/>
                <TextField label="Correo electrónico" source="correo"/>
                <span></span>   
                <span></span>   
               
                <TextField label="Observaciones" source="observaciones"/>

            </Tab>
            <Tab label="CALIFICACIÓN">
             
                <ReferenceArrayField label="" reference="calificacion_proveedors" source="calificaciones">
                    <Datagrid sortable={false}>                        
                        <TextField label ="Calificación" source="calificacion" sortable={false}/>
                        <DateField  label="Fecha de calificación" source="fechaCalificacion" sortable={false}/>
                        <TextField label="Usuario" source="usuario_id" sortable={false}/>
                    </Datagrid>
                </ReferenceArrayField>
                <span></span>   
                <span></span>  
                <NumberField label="Promedio" source="promedioCal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
            </Tab>
            <Tab label="Auditoría">
                <DateField source="createdAt" label="Fecha creación"/> 
                <TextField source="createdBy" label="Creado por"/>
                <span></span>               
                <DateField source="updatedAt" label="Fecha actualización"/>
                <TextField source="updatedBy" label="Actualizado por"/>
                <span></span>   
            </Tab>
        </TabbedShowLayout>
    </Show>
)});